//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { OnboardingMenu, IOnboardingMenuArgs } from "./OnboardingTopMenu";

export interface IOnboardingTemplateArgs extends IOnboardingMenuArgs {
  children: any;
}

export const OnboardingTemplate = ({ children, ...args }: IOnboardingTemplateArgs) => (
  <div>
    {children}
    <OnboardingMenu {...args} />
  </div>
);
