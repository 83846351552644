//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Message, Modal } from "semantic-ui-react";
import { Region } from "../../api/lib";
import SelectProvider2 from "../deployment/SelectProvider2";
import { Provider as ApiProvider } from "../../api/platform/v1/platform";
import SelectRegion from "../deployment/SelectRegion";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { FormActionButtonCancel, FormActionButtonCreate } from "../../ui/lib";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { RenderGuard } from "../../util/RenderGuard";

interface ICloneBackupToDifferentRegionArgs {
  onClose: () => void;
  onClick: (regionId: string) => void;
}

const SelectRegionModal = ({ onClose, onClick }: ICloneBackupToDifferentRegionArgs) => {
  const organization = useGlobalStore((state) => state.organization);
  const { subscribeUrl, unsubscribe, refreshNow, loading, hasPermissionByUrl, eventSubscriptionManager, permissionCache } = useWithRefresh();
  const deployment = useDeploymentStore();
  const [provider, setProvider] = useState<ApiProvider | undefined>(deployment.provider.id === "aks" ? undefined : deployment.provider);
  const region = useDeploymentStore((state) => state.region);
  const [targetRegion, updateTargetRegion] = useState<Region>({});
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();

  return (
    <Modal open>
      <Modal.Header data-testid="SelectRegionModalHeader">Clone deployment</Modal.Header>
      <Modal.Content>
        <Message data-testid="SelectRegionModalMessage">
          Depending on availability the current cloud provider and deployment region are selected by default. A deployment can be cloned to a different provider
          and region as well.
        </Message>
        <SelectProvider2 selectedProvider={provider} organizationId={organization.id || ""} onProviderUpdated={setProvider} />
        <RenderGuard renderIf={provider !== undefined}>
          <SelectRegion
            eventSubscriptionManager={eventSubscriptionManager}
            subscribeUrl={subscribeUrl}
            unsubscribe={unsubscribe}
            refreshNow={refreshNow}
            loading={loading}
            hasPermissionByUrl={hasPermissionByUrl}
            permissionCache={permissionCache}
            organizationId={organization.id || ""}
            provider={provider}
            select_first={false}
            region={region}
            assignProvidedRegionFirst
            onRegionUpdated={function (region?: Region | undefined): void {
              updateTargetRegion(region || {});
            }}
            isPerpetualFreeTrialAvailable={isPerpetualFreeTrialAvailable}
          />
        </RenderGuard>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} testID="SelectRegionModalCancelAction" />
        <FormActionButtonCreate primary onClick={() => onClick(targetRegion.id || "")} testID="SelectRegionModalConfirmAction" />
      </Modal.Actions>
    </Modal>
  );
};

export default SelectRegionModal;
