//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Form, Modal } from "semantic-ui-react";
import {
  IDOptions as ApiIDOptions,
  AuditLog as ApiAuditLog,
  AuditLog_Destination as ApiAuditLogDestination,
  AuditLogTopic as ApiAuditLogTopic,
  Organization as ApiOrganization,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonSave, FormContentAction, FormContentActions, MainContent, Processing } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import { EditAuditLogView } from "./EditAuditLogViews";

interface IUpdateAuditLogViewArgs extends IUpdateAuditLogModalState, IUpdateAuditLogModalProps {
  organization: ApiOrganization;
  auditlog_id: string;
  active: boolean;
  handleDismissError: () => void;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onToggleCloud: () => void;
  onExcludedCloudTopicsChanged: (value: string[]) => void;
  onExcludedTopicsChanged: (index: number, value: string[]) => void;
  onNewDestinationCreated: (value: ApiAuditLogDestination) => void;
  onDeleteDestination: (index: number) => void;
  onDestinationUpdated: (index: number, value: ApiAuditLogDestination) => void;
  onUpdate: () => void;
}

const UpdateAuditLogView = ({ ...args }: IUpdateAuditLogViewArgs) => {
  const tier = args.organization.tier || {};
  const has_cloud_destination = !!tier.has_auditlog_destination_cloud;
  const has_https_destination = !!tier.has_auditlog_destination_https_post;
  const readonly = !!args.readonly;
  const canSave = !readonly && !_.isEmpty(args.name) && (args.cloud || !_.isEmpty(args.destinations));
  return (
    <Modal open onClose={args.onClose} size="large">
      <Modal.Header>{readonly ? "Inspect" : "Edit"} audit log</Modal.Header>
      <Modal.Content>
        <Processing active={args.processing} message="Saving audit log, please wait..." />
        <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
        <MainContent>
          <Form>
            <EditAuditLogView
              {...args}
              organization_id={args.organization.id || ""}
              has_cloud_destination={has_cloud_destination}
              has_https_destination={has_https_destination}
              show_price_warning={false}
              accept_price_increase={false}
              onToggleAccessPriceIncrease={() => {}}
            />
            <FormContentActions>
              {!readonly && (
                <FormContentAction>
                  <FormActionButtonSave primary disabled={!args.active || !canSave} onClick={args.onUpdate} />
                </FormContentAction>
              )}
              <FormContentAction>
                <FormActionButtonCancel onClick={args.onClose} />
              </FormContentAction>
            </FormContentActions>
          </Form>
        </MainContent>
      </Modal.Content>
    </Modal>
  );
};

interface IUpdateAuditLogModalProps {
  readonly?: boolean;
  auditlog_id: string;
  organization: ApiOrganization;
  all_topics: ApiAuditLogTopic[];
  onAuditLogUpdated: (auditLogId: string) => void;
  onClose: () => void;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

interface IUpdateAuditLogModalState {
  errorMessage?: string;
  processing: boolean;
  name: string;
  description: string;
  cloud: boolean;
  excluded_cloud_topics: string[];
  destinations: ApiAuditLogDestination[];
  refreshNeeded: boolean;
  prev_auditlog_id?: string;
}

// Component to update an existing audit log
class UpdateAuditLogModal extends Component<IUpdateAuditLogModalProps, IUpdateAuditLogModalState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
    cloud: true,
    excluded_cloud_topics: [],
    destinations: [],
    refreshNeeded: false,
    prev_auditlog_id: undefined,
  } as IUpdateAuditLogModalState;

  static getDerivedStateFromProps(props: IUpdateAuditLogModalProps, state: IUpdateAuditLogModalState) {
    if (props.auditlog_id !== state.prev_auditlog_id) {
      return {
        prev_auditlog_id: props.auditlog_id,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshAuditLog();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshAuditLog);
    }
  }

  reloadAuditLog = async () => {
    const req = {
      id: this.props.auditlog_id,
    } as ApiIDOptions;
    const auditlog = await apiClients.auditClient.GetAuditLog(req);
    const destinations = auditlog.destinations || [];
    const cloudDest = _.find(destinations, (x) => x.type == "cloud");
    this.setState({
      name: auditlog.name || "",
      description: auditlog.description || "",
      cloud: !!cloudDest,
      excluded_cloud_topics: (cloudDest || {}).excluded_topics || [],
      destinations: _.filter(destinations, (x) => x.type === "https-post"),
    });
  };

  refreshAuditLog = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLog);
  };

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };
  onToggleCloud = () => {
    this.setState((old) => {
      return { cloud: !old.cloud };
    });
  };
  onExcludedCloudTopicsChanged = (value: string[]) => {
    this.setState({ excluded_cloud_topics: value });
  };
  onExcludedTopicsChanged = (index: number, value: string[]) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      destinations[index].excluded_topics = value;
      return { destinations: destinations };
    });
  };
  onNewDestinationCreated = (value: ApiAuditLogDestination) => {
    this.setState((old) => {
      return { destinations: _.concat(old.destinations, value) };
    });
  };
  onDestinationUpdated = (index: number, value: ApiAuditLogDestination) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      const prevId = destinations[index].id;
      destinations[index] = value;
      destinations[index].id = prevId;
      return { destinations: destinations };
    });
  };
  onDeleteDestination = (index: number) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      _.pullAt(destinations, index);
      return { destinations: destinations };
    });
  };

  onUpdate = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const tier = this.props.organization.tier || {};
      const has_cloud_destination = !!tier.has_auditlog_destination_cloud;
      const destinations = _.cloneDeep(this.state.destinations);
      if (has_cloud_destination && this.state.cloud) {
        destinations.push({
          type: "cloud",
          excluded_topics: this.state.excluded_cloud_topics,
        });
      }
      const req = {
        id: this.props.auditlog_id,
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        destinations: destinations,
      } as ApiAuditLog;
      const result = await apiClients.auditClient.UpdateAuditLog(req);

      this.props.onAuditLogUpdated(result.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Audit log update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return <UpdateAuditLogView {...this.props} {...this.state} {...this} active={!this.state.processing} />;
  }
}

export default UpdateAuditLogModal;
