//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Grid, Icon, Message, Modal, Segment } from "semantic-ui-react";
import { PrivateEndpointService_AksPrivateEndpointConnectionStatus as ApiPrivateEndpointConnections } from "../../api/network/v1/network";
import { FormActionButtonCancel } from "../../ui/_buttons";
import { ContentKeyValuePairs, ContentKeyValuePair, ContentKV_Key, ContentKV_Value } from "../../ui/_style";

export interface IPrivateEndpointConnectionsViewArgs {
  connections: ApiPrivateEndpointConnections[];
}

const PrivateEndpointConnections = ({ connections }: IPrivateEndpointConnectionsViewArgs): React.ReactElement => {
  if (!connections.length)
    return (
      <Segment data-testid="noConnection">
        <Icon name="warning sign" /> No connections Established
      </Segment>
    );
  return (
    <>
      <Message data-testid="connectionInfo">
        Below you will find detailed information of all the connections made from Private Endpoint(s) in your subscriptions to the Private Endpoint Service near
        your ArangoGraph Insights Platform deployment.
      </Message>

      {connections.map((connection) => (
        <Segment data-testid="connectionCard" key={connection.id}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>
                      Name {connection.state === "failed" && <Icon name="warning circle" color="red" data-testid={connection.id} />}
                    </ContentKV_Key>
                    <ContentKV_Value>{connection.name}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
              <Grid.Column width={6}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>ID</ContentKV_Key>
                    <ContentKV_Value>{connection.id}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>Description</ContentKV_Key>
                    <ContentKV_Value>{connection.description}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
              <Grid.Column width={6}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>State</ContentKV_Key>
                    <ContentKV_Value>{connection.state}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ))}
    </>
  );
};

export default PrivateEndpointConnections;

interface IPrivateEndpointConnectionsModalView extends IPrivateEndpointConnectionsViewArgs {
  onClose: () => void;
}

const PrivateEndpointConnectionsModalView = ({ onClose, connections }: IPrivateEndpointConnectionsModalView) => {
  return (
    <Modal open onClose={onClose} data-testid="privateNetworkConnectionsModal">
      <Modal.Header>Active connections</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <PrivateEndpointConnections connections={connections} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export { PrivateEndpointConnectionsModalView };
