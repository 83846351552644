//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
// Ewout Prangsma
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Checkbox, Icon, Loader, Table } from "semantic-ui-react";
import { AuditLogArchive as ApiAuditLogArchive, AuditLogArchiveList as ApiAuditLogArchiveList, IDOptions as ApiIDOptions } from "../../api/lib";
import { Confirm, ConfirmInfo, ErrorMessage, ListActionDelete, LoaderBoxForTable as LoaderBox, Loading, Processing, TextLink } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import apiClients from "../../api/apiclients";
import { reportError } from "../../errors/reporting";
import { humanizeFileSize } from "../../util/FileSize";
import { useWithRefresh } from "../../util/WithRefreshContext";

interface IHeaderView {
  loading: boolean;
  hide_used_for: boolean;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        {!args.hide_used_for && <Table.HeaderCell>Used for</Table.HeaderCell>}
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
        <Table.HeaderCell>
          Actions
          <LoaderBox>
            <Loader size="mini" active={args.loading} inline />
          </LoaderBox>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface IRowViewArgs {
  item: ApiAuditLogArchive;
  is_selected: boolean;
  hide_project: boolean;
  hide_used_for: boolean;
  disable_select?: boolean;
  onClickDelete: () => void;
  onClickSelect: () => void;
}

const RowView = ({ ...args }: IRowViewArgs) => {
  const { hasPermissionByUrl } = useWithRefresh();
  const hasPermission = (p: Permission) => {
    return !!hasPermissionByUrl?.(args.item.url || "", ResourceType.AuditLog, p);
  };

  const has_auditlogarchive_get = hasPermission("audit.auditlogarchive.get");
  const has_auditlogarchive_delete = hasPermission("audit.auditlogarchive.delete");
  const is_for_deployment = !!args.item.deployment_id;
  const deployment_name = args.item.deployment_name || args.item.deployment_id || "?";
  const project_name = args.item.project_name || args.item.project_id || "?";
  const can_delete = !!args.item.can_delete && !args.item.is_deleted;
  const disable_select = !!args.disable_select;

  return (
    <Table.Row>
      <Table.Cell>
        {!disable_select && (
          <span>
            <Checkbox
              radio
              fitted={false}
              checked={args.is_selected}
              onClick={() => {
                if (!args.is_selected) {
                  args.onClickSelect();
                }
              }}
            />
            &nbsp;
          </span>
        )}
        <TextLink label={args.item.id} onClick={args.onClickSelect} disabled={!has_auditlogarchive_get} />
      </Table.Cell>
      {!args.hide_used_for && (
        <Table.Cell>
          {is_for_deployment && (
            <div>
              <Icon name="database" /> {deployment_name} {!args.hide_project && <span>(in project {project_name})</span>}
            </div>
          )}
          {!is_for_deployment && (
            <div>
              <Icon name="cloud" /> ArangoGraph Insights Platform
            </div>
          )}
        </Table.Cell>
      )}
      <Table.Cell>{humanizeFileSize(args.item.size_in_bytes || 0)}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">{has_auditlogarchive_delete && can_delete && <ListActionDelete onClick={args.onClickDelete} />}</div>
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiAuditLogArchive[];
  loading: boolean;
  selected_auditlogarchive_id?: string;
  disable_select?: boolean;
  hide_project: boolean;
  hide_used_for: boolean;
  onClickDelete: (id: string) => void;
  onClickSelect: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            is_selected={item.id == args.selected_auditlogarchive_id}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickSelect={() => args.onClickSelect(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No audit log archives inside this project</div>;

export interface IAuditLogArchiveListViewArgs {
  loading: boolean;
  auditLogArchives?: ApiAuditLogArchiveList;
  selected_auditlogarchive_id?: string;
  disable_select?: boolean;
  hide_project: boolean;
  hide_used_for: boolean;
  onClickDelete: (id: string) => void;
  onClickSelect: (id: string) => void;
}

export const AuditLogArchiveListView = ({ ...args }: IAuditLogArchiveListViewArgs) => {
  if (!args.auditLogArchives) {
    return <Loading />;
  }
  let items = args.auditLogArchives.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, "name")} loading={args.loading} />;
};

interface IAuditLogArchiveListProps {
  organization_id: string;
  auditLogArchives?: ApiAuditLogArchiveList;
  selected_auditlogarchive_id?: string;
  disable_select?: boolean;
  hide_project?: boolean;
  hide_used_for?: boolean;
  onRefreshList: () => void;
  onClickSelect: (id: string) => void;
  loading: boolean;
}

interface IAuditLogArchiveListState {
  errorMessage?: string;
  processingDelete: boolean;
  confirmInfo?: ConfirmInfo;
  editId?: string;
  viewId?: string;
}

// The component to show the audit logs inside an organization as a list.
class AuditLogArchiveList extends Component<IAuditLogArchiveListProps, IAuditLogArchiveListState> {
  state = {
    errorMessage: undefined,
    processingDelete: false,
    confirmInfo: undefined,
    viewId: undefined,
    prevOrganizationId: undefined,
    refreshNeeded: false,
  } as IAuditLogArchiveListState;

  onClickView = (id: string) => {
    this.setState({ viewId: id, editId: undefined });
  };
  onCloseView = () => {
    this.setState({ viewId: undefined });
  };

  onClickEdit = (id: string) => {
    this.setState({ editId: id, viewId: undefined });
  };
  onCloseEdit = () => {
    this.setState({ editId: undefined });
  };

  onClickDelete = async (id: string) => {
    const confirmInfo = {
      header: "Delete Audit Log Archive",
      content: `Are you sure you want to delete audit log archive with ID '${id}'?`,
      warning: "Deleting an audit log archive removes all audit events stored in the archive. This cannot be undone!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: async () => {
        try {
          this.setState({ errorMessage: undefined, processingDelete: true, confirmInfo: undefined });
          const idOptions = { id: id } as ApiIDOptions;
          await apiClients.auditClient.DeleteAuditLogArchive(idOptions);
          this.props.onRefreshList();
        } catch (e) {
          this.setState({ errorMessage: `Audit log archive deletion failed: ${e}` });
          reportError(e);
        }
        this.setState({ processingDelete: false });
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processingDelete} message="Deleting audit log archive, please wait..." />

        <AuditLogArchiveListView
          {...this.props}
          {...this.state}
          auditLogArchives={this.props.auditLogArchives}
          onClickDelete={this.onClickDelete}
          hide_project={!!this.props.hide_project}
          hide_used_for={!!this.props.hide_used_for}
        />
      </div>
    );
  }
}

export default AuditLogArchiveList;
