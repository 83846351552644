//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form, Grid, InputOnChangeData } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { PermissionList as ApiPermissionList, Role as ApiRole } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";

const CreatePermissionsView = ({ ...args }: ICreateRoleViewArgs) =>
  args.permissions && args.permissions.items ? (
    <Form.Dropdown
      label="Permissions"
      placeholder="Select Permissions"
      name="permissions"
      multiple
      scrolling
      value={args.selectedPermissions}
      onChange={args.updateFormDropdownChange}
      options={args.permissions.items.map((p) => {
        return { key: p, text: p, value: p };
      })}
    />
  ) : (
    <Form.Dropdown label="Permissions" placeholder="Select Permissions" name="permissions" loading={true} />
  );

interface ICreateRoleViewArgs extends ICreateRoleState, ICreateRoleProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
  createRole: () => void;
}

const CreateRoleView = ({ ...args }: ICreateRoleViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Creating role, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <Section>
          <SectionHeader title="General" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <Form.Input
                    autoFocus
                    required
                    label="Name"
                    placeholder='Name (e.g. "group-and-role-administrators")'
                    name="name"
                    value={args.name}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    label="Short description"
                    placeholder="Description"
                    name="description"
                    value={args.description}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader title="Permissions" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={16}>
                  <CreatePermissionsView {...args} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active} onClick={args.createRole} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.history.goBack} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the CreateRole component
interface ICreateRoleProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onNewRoleCreated: (groupId: string) => void;
}

// Interface decribing the state of the CreateRole component
interface ICreateRoleState {
  errorMessage?: string;
  processing: boolean;

  name: string;
  description: string;

  permissions?: ApiPermissionList;
  selectedPermissions: string[];
}

// Component to create a role
class CreateRole extends Component<ICreateRoleProps, ICreateRoleState> {
  state = {
    errorMessage: undefined,
    processing: false,

    name: "",
    description: "",

    permissions: undefined,
    selectedPermissions: [],
  } as ICreateRoleState;

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "permissions":
        const selectedPermissions = args.value as string[];
        this.setState({ selectedPermissions: selectedPermissions });
        break;
    }
  };

  createRole = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const role = {
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        permissions: this.state.selectedPermissions,
      } as ApiRole;
      const createdRole = await apiClients.iamClient.CreateRole(role);

      this.props.onNewRoleCreated(createdRole.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Role creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  reloadPermissions = async () => {
    const permissions = await apiClients.iamClient.ListPermissions();
    this.setState({ permissions: permissions });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadPermissions);
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Roles", Routes.dashboard_organization_rolesWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("role");
    this.props.topMenuInfo.setTitles(this.state.name || "Create role", "");
  };

  render() {
    return (
      <CreateRoleView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        updateFormDropdownChange={this.updateFormDropdownChange}
        createRole={this.createRole}
      />
    );
  }
}

export default withRefresh()(CreateRole);
