//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { Role as ApiRole, RoleList as ApiRoleList } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { Routes } from "../../routes";
import { Confirm, ConfirmInfo, ErrorMessage, ListActionDelete, ListActionEdit, ListActionView, Loading, Processing, TextLink } from "../../ui/lib";
import { TopMenuInfo } from "../TopMenuInfo";
import { HistoryHelper } from "../HistoryHelper";
import { ResourceType, Permission } from "../../util/PermissionCache";
import { reportError } from "../../errors/reporting";
import { History } from "history";
import { useWithRefresh } from "../../util/WithRefreshContext";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a role
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiRole;
  onClickView: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink
        label={args.item.name}
        onClick={args.onClickView}
        disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.Role, "iam.role.get")}
      />
    </Table.Cell>
    <Table.Cell>{args.item.description}</Table.Cell>
    <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.Role, "iam.role.get") && (
          <ListActionView onClick={args.onClickView} />
        )}
        {!args.item.is_predefined &&
          !args.item.is_deleted &&
          args.hasPermissionByUrl &&
          args.hasPermissionByUrl(args.item.url || "", ResourceType.Role, "iam.role.update") && (
            <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />
          )}
        {!args.item.is_predefined &&
          !args.item.is_deleted &&
          args.hasPermissionByUrl &&
          args.hasPermissionByUrl(args.item.url || "", ResourceType.Role, "iam.role.delete") && (
            <ListActionDelete disabled={!args.active} onClick={args.onClickDelete} />
          )}
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the group
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  items: ApiRole[];
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No role inside this organization</div>;

// Interface describing the role list view arguments
export interface IRoleListViewArgs {
  active: boolean;
  organization: ApiOrganization;
  roles?: ApiRoleList;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const RoleListView = ({ ...args }: IRoleListViewArgs) => {
  if (!args.roles) {
    return <Loading />;
  }
  if (!args.roles.items || args.roles.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.roles.items} />;
};

// Interface decribing the properties of the role list component
interface IRoleListProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onRoleSelected: (id: string) => void;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  subscribeUrl: ((callback: () => Promise<void>, url?: string) => Promise<string | void>) | undefined;
  unsubscribe: ((id?: string | void) => void) | undefined;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
  history: History;
}

// Interface decribing the state of the role list component
interface IRoleListState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  roles?: ApiRoleList;
}

// The component to show the roles inside an organization as a list.
class RoleList extends Component<IRoleListProps, IRoleListState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    roles: undefined,
  } as IRoleListState;

  reloadRoles = async () => {
    const listOptions = { context_id: this.props.organization.id } as ApiListOptions;
    const roles = await apiClients.iamClient.ListRoles(listOptions);
    this.setState({ roles: roles });
  };

  onClickView = (id: string) => {
    this.props.onRoleSelected(id);
  };

  onClickEdit = (id: string) => {
    HistoryHelper.push(
      this.props.history,
      Routes.dashboard_organization_role_editWithId(this.props.organization.id || "", id),
      this.props.topMenuInfo.getTitle()
    );
  };

  getRoleName = (id: string) => {
    const roles = this.state.roles;
    if (roles && roles.items) {
      const role = roles.items.find((r) => r.id == id);
      if (role) {
        return role.name;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const roleName = this.getRoleName(id);
    const confirmInfo = {
      header: "Delete Role",
      content: `Are you sure you want to delete role '${roleName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.iamClient.DeleteRole(idOptions);
      this.props.refreshNow && this.props.refreshNow(this.reloadRoles);
    } catch (e) {
      this.setState({ errorMessage: `Role deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };
  subscriptionId?: string | void;
  subscribeRoles = async () => {
    this.subscriptionId = await this.props.subscribeUrl?.(this.reloadRoles, `${this.props.organization.url}/Role/*`);
  };
  componentDidMount() {
    this.subscribeRoles();
  }
  componentWillUnmount(): void {
    if (this.subscriptionId) {
      this.props.unsubscribe?.(this.subscriptionId);
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting role, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <RoleListView
          {...this.props}
          {...this.state}
          active={!this.state.processing}
          onClickView={this.onClickView}
          onClickEdit={this.onClickEdit}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default RoleList;
