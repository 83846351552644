//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchIPAllowlists = () => {
  const { project } = useProjectDetailsContext();
  const fetchIPAllowlist = async () => {
    const listOptions = { context_id: project.id };
    return await apiClients.securityClient.ListIPAllowlists(listOptions);
  };
  return useQuery([project.id, "ipAllowlists"], fetchIPAllowlist);
};
