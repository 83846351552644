//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import _ from "lodash";

interface IWarningsArgs {
  warnings?: string[];
}

export const Warnings = (args: IWarningsArgs) => {
  const warnings = args.warnings || [];
  if (_.isEmpty(warnings)) {
    return <span></span>;
  }
  return (
    <Popup
      size="large"
      trigger={<Icon name="exclamation triangle" color="red" />}
      content={warnings.map((x) => (
        <div>{x}</div>
      ))}
    />
  );
};
