//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { Accordion } from "semantic-ui-react";
import AccordionTitleView from "./AccordionTitleView";
import { IAccordionItemViewArgs } from "./types";

const StyledAccodionContent = styled(Accordion.Content)`
  max-height: 200px !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    outline: 0px solid var(--gray-600);
  }
`;

const AccordionItem = (accordionItem: IAccordionItemViewArgs) => {
  const { active, content, testID } = accordionItem;
  return (
    <>
      <AccordionTitleView {...accordionItem} />
      {active && <StyledAccodionContent data-testid={`${testID}Content`}>{content}</StyledAccodionContent>}
    </>
  );
};

export default AccordionItem;
