//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React from "react";
import { Icon, Statistic } from "semantic-ui-react";
import { BackupList as ApiBackupList, BackupPolicyList as ApiBackupPolicyList } from "../../api/lib";
import { StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { humanizeFileSize } from "../../util/FileSize";

interface BackupSummaryViewArgs {
  isDeploymentPaused: boolean;
  totalBackupSizeBytes: number;
  backupUploadInProgress: boolean;
  backups?: ApiBackupList;
  lastGoodBackups?: ApiBackupList;
  backupPolicies?: ApiBackupPolicyList;
}

export const BackupSummaryTableView = ({ ...args }: BackupSummaryViewArgs) => {
  const lastGoodBackupItems = (args.lastGoodBackups || {}).items || [];
  const lastSafeBackup = _.first(lastGoodBackupItems.map((x) => x.created_at));
  const nextBackup = BackupSummary.getNextBackup(args.backupPolicies);
  const nextBackupValue = nextBackup ? (args.isDeploymentPaused ? "Paused" : moment(nextBackup).fromNow()) : "None";
  const totalCount = args.backups && args.backups.budget ? args.backups.budget.used : 0;
  const recentErrorCount = BackupSummary.getRecentErrorCount(args.backups);
  const formatNumber = (x: number | undefined, defaultString: string) => {
    if (!x) {
      return defaultString;
    }
    return _.toString(x);
  };
  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="5">
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="calendar check" className="primary-text" /> Last safe backup
            </Statistic.Label>
            <Statistic.Value>{lastSafeBackup ? moment(lastSafeBackup).fromNow() : "None"}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="calendar check" className="secondary-text" /> Next backup
            </Statistic.Label>
            <Statistic.Value>{nextBackupValue}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="hashtag" className="secondary-text" /> Total count
            </Statistic.Label>
            <Statistic.Value>{formatNumber(totalCount, "None")}</Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="circle" className="secondary-text" /> Total size
            </Statistic.Label>
            <Statistic.Value>
              {humanizeFileSize(args.totalBackupSizeBytes)}
              {args.backupUploadInProgress && <sup>*</sup>}
            </Statistic.Value>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Label>
              <Icon name="exclamation triangle" className="red-text" /> Recent Error
            </Statistic.Label>
            <Statistic.Value className={recentErrorCount > 0 ? "red-text" : ""}>{formatNumber(recentErrorCount, "None")}</Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

export class BackupSummary {
  static getTotalSize = (backups?: ApiBackupList): number => {
    let size = 0;
    if (backups && backups.items) {
      backups.items.forEach((b) => {
        if (b.status && b.status.size_bytes) {
          // Make sure we are adding numbers, not strings
          size += +b.status.size_bytes;
        }
      });
    }
    return size;
  };

  static getRecentErrorCount = (backups?: ApiBackupList): number => {
    let errorCount = 0;
    if (backups && backups.items) {
      const sortedItems = backups.items.sort((b1, b2) => {
        if (b1.created_at && b2.created_at) {
          return b1.created_at > b2.created_at ? -1 : 1;
        }
        return 0;
      });
      for (let i = 0; i < sortedItems.length; i++) {
        const b = sortedItems[0];
        if (b.status) {
          if (b.status.is_failed) {
            errorCount++;
          } else {
            break;
          }
        }
      }
    }
    return errorCount;
  };

  static getNextBackup = (backupPolicies?: ApiBackupPolicyList): Date | undefined => {
    if (backupPolicies && backupPolicies.items) {
      const nonPausedItems = backupPolicies.items.filter((b) => !b.is_paused);
      if (!_.isEmpty(nonPausedItems)) {
        const sortedItems = nonPausedItems.sort((bp1, bp2) => {
          if (bp1.status && bp1.status.next_backup && bp2.status && bp2.status.next_backup) {
            return bp1.status.next_backup > bp2.status.next_backup ? 1 : -1;
          }
          if (bp1.status && bp1.status.next_backup) {
            return 1;
          }
          if (bp2.status && bp2.status.next_backup) {
            return -1;
          }
          return 0;
        });
        if (sortedItems[0].status) {
          return sortedItems[0].status.next_backup;
        }
      }
    }
    return undefined;
  };
}
