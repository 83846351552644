//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import {
  Organization as ApiOrganization,
  OrganizationInvite as ApiOrganizationInvite,
  OrganizationInviteList as ApiOrganizationInviteList,
} from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import {
  Confirm,
  ConfirmInfo,
  ContentActionButtonNew,
  ErrorMessage,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { TopMenuInfo } from "../TopMenuInfo";
import { AcceptedOrganizationInviteListView } from "./AcceptedOrganizationInviteList";
import { PendingOrganizationInviteListView } from "./PendingOrganizationInviteList";
import { RejectedOrganizationInviteListView } from "./RejectedOrganizationInviteList";
import { concat } from "lodash";
import { Permission, ResourceType } from "../../util/PermissionCache";

// Interface describing the invites view arguments
interface IInvitesViewArgs extends IOrganizationInvitesProps {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  confirmInfo?: ConfirmInfo;
  organization: ApiOrganization;
  organizationInvites?: ApiOrganizationInviteList;
  onDelete: (id: string) => void;
  has_create_invite: boolean;
  onInviteCreate: () => void;
}

const InvitesView = ({ ...args }: IInvitesViewArgs) => {
  const is_org_deleted = !!args.organization.is_deleted;
  return (
    <div>
      <Confirm confirmInfo={args.confirmInfo} />
      <Processing active={args.processing} message="Deleting organization invite, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <div>
        <Section>
          <SectionHead>
            <SectionHeader
              title="Pending"
              help={
                <div>
                  <p className="para">Pending invites lists all invites for new members of the organization that have not yet been accepted or rejected.</p>
                </div>
              }
            />
            <SectionButtons>
              {args.has_create_invite && !is_org_deleted && (
                <ContentActionButtonNew
                  primary
                  content="Invite new member"
                  onClick={args.onInviteCreate}
                  disabled={!!args.organization.authentication_providers?.enable_sso}
                />
              )}
            </SectionButtons>
          </SectionHead>
          <SectionContent topAttached>
            <PendingOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader
            title="Accepted"
            help={
              <div>
                <p className="para">Accepted invites lists all invites for new members of the organization that have been accepted.</p>
              </div>
            }
          />
          <SectionContent topAttached>
            <AcceptedOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader
            title="Rejected"
            help={
              <div>
                <p className="para">Rejects invites lists all invites for new members of the organization that have been rejected.</p>
              </div>
            }
          />
          <SectionContent topAttached>
            <RejectedOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
      </div>
    </div>
  );
};

// Interface decribing the properties of the invites component
interface IOrganizationInvitesProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onOrganizationInviteDeleted: (id: string) => void;
  has_create_invite: boolean;
  loading: boolean;
  onInviteCreate: () => void;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  subscribeUrl: ((callback: () => Promise<void>, url?: string) => Promise<string | void>) | undefined;
  unsubscribe: ((id?: string | void) => void) | undefined;
}

// Interface decribing the state of the invites component
interface IOrganizationInvitesState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  organizationInvites?: ApiOrganizationInviteList;
}

// The Invides component shows on the left a menu, where you can select 'pending' 'accepted and ' rejected, on the right a list.
class OrganizationInvites extends Component<IOrganizationInvitesProps, IOrganizationInvitesState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    organizationInvites: undefined,
  } as IOrganizationInvitesState;

  reloadOrganizationInvites = async () => {
    const listOptions: ApiListOptions = {
      context_id: this.props.organization.id,
      page_size: 25,
      page: 0,
    };
    let prefix: ApiOrganizationInvite[] = [];
    let hasMore = true;
    do {
      const organizationInvites = await apiClients.resourceManagerClient.ListOrganizationInvites(listOptions);
      const suffix = organizationInvites.items || [];
      organizationInvites.items = concat(prefix, suffix);
      prefix = organizationInvites.items || [];
      hasMore = suffix.length >= (listOptions.page_size || 0);
      if (hasMore) {
        listOptions.page = (listOptions.page || 0) + 1;
      } else {
        this.setState({ organizationInvites: organizationInvites });
      }
    } while (hasMore);
  };

  getInviteName = (id: string) => {
    const organizationInvites = this.state.organizationInvites;
    if (organizationInvites && organizationInvites.items) {
      const organizationInvite = organizationInvites.items.find((i) => i.id == id);
      if (organizationInvite) {
        return organizationInvite.email;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const groupName = this.getInviteName(id);
    const confirmInfo = {
      header: "Delete Invite",
      content: `Are you sure you want to delete the invite for '${groupName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.resourceManagerClient.DeleteOrganizationInvite(idOptions);
      this.props.refreshNow?.(this.reloadOrganizationInvites);
      this.props.onOrganizationInviteDeleted(id);
    } catch (e) {
      this.setState({ errorMessage: `Organization Invite deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };
  subscriptionId?: string | void;
  subscribeOrganizationInvites = async () => {
    this.subscriptionId = await this.props.subscribeUrl?.(this.reloadOrganizationInvites, `${this.props.organization.url}/OrganizationInvites/*`);
  };
  componentDidMount() {
    this.subscribeOrganizationInvites();
  }

  componentWillUnmount(): void {
    if (this.subscriptionId) {
      this.props.unsubscribe?.(this.subscriptionId);
    }
  }
  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <InvitesView {...this.props} {...this.state} active={!this.state.processing} handleDismissError={this.handleDismissError} onDelete={this.onClickDelete} />
    );
  }
}

export default OrganizationInvites;
