//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Form, Grid, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { IPAllowlist as ApiIPAllowlist } from "../../api/lib";
import { Project as ApiProject } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import {
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Loading,
  MainContent,
  Processing,
  Section,
  SectionContent,
  Footnote,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CIDRRangeSamples from "./CIDRRangeSamples";

interface IUpdateIPAllowlistViewModalArgs extends IUpdateIPAllowlistModalState, IUpdateIPAllowlistModalProps {
  loading: boolean;
  active: boolean;
  handleDismissError: () => void;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onCIDRRangesChanged: (value: string) => void;
  updateIPAllowlist: () => void;
  onLockedChanged: () => void;
  onRemoteInspectionAllowedChanged: () => void;
  onCancel: () => void;
}

const UpdateIPAllowlistModalView = ({ ...args }: IUpdateIPAllowlistViewModalArgs) => (
  <Modal open onClose={args.onClose}>
    <Modal.Header>Edit IP allowlist</Modal.Header>
    <Modal.Content>
      {args.loading && <Loading />}
      <Processing active={args.processing} message="Updating IP allowlist, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      {!args.loading && (
        <MainContent>
          <Form>
            <Section>
              <SectionContent>
                <Grid>
                  <Grid.Row columns={16}>
                    <Grid.Column width={4}>
                      <Form.Input
                        autoFocus
                        required
                        label="Name"
                        placeholder="Name"
                        name="name"
                        value={args.name}
                        onChange={(e, d) => args.onNameChanged(d.value)}
                      />
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form.Input
                        label="Short description"
                        placeholder="Description"
                        name="description"
                        value={args.description}
                        onChange={(e, d) => args.onDescriptionChanged(d.value)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={16}>
                    <Grid.Column width={16}>
                      <Form.TextArea
                        required
                        label="Allowed CIDR ranges (1 per line)"
                        placeholder={`Line separated CIDR ranges`}
                        name="cidr_ranges"
                        value={args.cidr_ranges.join("\n")}
                        onChange={(e, d) => args.onCIDRRangesChanged(d.value as string)}
                        rows="10"
                      />
                      <CIDRRangeSamples />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="8">
                      <Form.Checkbox
                        label="Remote inspection allowed"
                        checked={args.remote_inspection_allowed}
                        onChange={args.onRemoteInspectionAllowedChanged}
                        toggle
                      />
                      <Footnote>When enabled, ArangoGraph Insights Platform is allowed to inspect connectivity status of this deployment.</Footnote>
                    </Grid.Column>
                    <Grid.Column width="8">
                      <Form.Checkbox label="Locked" checked={args.locked} onChange={args.onLockedChanged} toggle />
                      <Footnote>Locked IP allowlists cannot be deleted.</Footnote>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SectionContent>
            </Section>
            <FormContentActions>
              <FormContentAction>
                <FormActionButtonSave primary disabled={!args.active} onClick={args.updateIPAllowlist} />
              </FormContentAction>
              <FormContentAction>
                <FormActionButtonCancel onClick={args.onCancel} />
              </FormContentAction>
            </FormContentActions>
          </Form>
        </MainContent>
      )}
    </Modal.Content>
  </Modal>
);

// Interface decribing the properties of the UpdateIPAllowlist component
interface IUpdateIPAllowlistModalProps extends IWithRefreshProps {
  project: ApiProject;
  ipallowlistId: string;
  onIPAllowlistUpdated: (ipallowlistId: string) => void;
  onClose: () => void;
}

// Interface decribing the state of the UpdateIPAllowlist component
interface IUpdateIPAllowlistModalState {
  errorMessage?: string;
  processing: boolean;
  ipallowlist?: ApiIPAllowlist;
  name: string;
  description: string;
  cidr_ranges: string[];
  locked: boolean;
  remote_inspection_allowed: boolean;
}

// Component to update an IP allowlist
class UpdateIPAllowlistModal extends Component<IUpdateIPAllowlistModalProps, IUpdateIPAllowlistModalState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
    cidr_ranges: [],
    locked: false,
    remote_inspection_allowed: false,
  } as IUpdateIPAllowlistModalState;

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };
  onCIDRRangesChanged = (value: string) => {
    this.setState({ cidr_ranges: _.toString(value).split("\n") });
  };
  onLockedChanged = () => {
    this.setState((old) => {
      return { locked: !old.locked };
    });
  };
  onRemoteInspectionAllowedChanged = () => {
    this.setState((old) => {
      return { remote_inspection_allowed: !old.remote_inspection_allowed };
    });
  };

  updateIPAllowlist = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const list = {
        id: this.props.ipallowlistId,
        project_id: this.props.project.id,
        name: this.state.name,
        description: this.state.description,
        cidr_ranges: this.state.cidr_ranges,
        locked: this.state.locked,
        remote_inspection_allowed: this.state.remote_inspection_allowed,
      } as ApiIPAllowlist;
      const updatedList = await apiClients.securityClient.UpdateIPAllowlist(list);

      this.props.onIPAllowlistUpdated(updatedList.id || "");
    } catch (e) {
      this.setState({ errorMessage: `IP allowlist update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  reloadIPAllowlistInfo = async () => {
    const idOptions = { id: this.props.ipallowlistId } as ApiIDOptions;
    const list = await apiClients.securityClient.GetIPAllowlist(idOptions);
    if (!this.state.ipallowlist) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadIPAllowlistInfo, list.url);
      this.setState({
        name: list.name || "",
        description: list.description || "",
        cidr_ranges: list.cidr_ranges || [],
        locked: !!list.locked,
        remote_inspection_allowed: !!list.remote_inspection_allowed,
      });
    }
    this.setState({ ipallowlist: list });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadIPAllowlistInfo);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <UpdateIPAllowlistModalView
        {...this.props}
        {...this.state}
        {...this}
        loading={!this.state.ipallowlist}
        active={!this.state.processing}
        onCancel={this.props.onClose}
      />
    );
  }
}

export default withRefresh()(UpdateIPAllowlistModal);
