//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Form, Icon, Input, InputProps, Popup, TextArea, TextAreaProps } from "semantic-ui-react";
import { IPrincipalAdditionView } from "./../types";

const PrincipalAdditionFormView = ({ onAccountIDChange, onUsernamesChange, onRoleNamesChange, accountId, usernames, roleNames }: IPrincipalAdditionView) => {
  return (
    <Form>
      <Form.Field
        label={<label>Account ID</label>}
        required
        value={accountId}
        control={Input}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputProps) => {
          const { value } = data;
          onAccountIDChange && onAccountIDChange(value);
        }}
        autoFocus={true}
        data-testid="accountId"
        placeholder="Type your 12 digit Account ID"
        maxLength={12}
      />
      <Form.Field
        label={
          <label>
            Usernames{" "}
            <Popup
              trigger={<Icon name="question circle" />}
              content="Enter the usernames of the users you want to add. Add each username in a separate line."
            />
          </label>
        }
        control={TextArea}
        rows={3}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
          const { value = "" } = data;
          onUsernamesChange && onUsernamesChange(value.toString());
        }}
        value={usernames}
        data-testid="usernames"
        placeholder="Add each username in a new line"
      />
      <Form.Field
        label={
          <label>
            Role names{" "}
            <Popup
              trigger={<Icon name="question circle" />}
              content="Enter the role names of the roles you want to add. Add each role name in a separate line."
            />
          </label>
        }
        control={TextArea}
        rows={3}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
          const { value = "" } = data;
          onRoleNamesChange && onRoleNamesChange(value.toString());
        }}
        value={roleNames}
        data-testid="rolenames"
        placeholder="Add role names separated by comma"
      />
    </Form>
  );
};

export default PrincipalAdditionFormView;
