//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { FlexBox } from "../../ui/_flex";
import { StyledEmailSegment, StyledEmptyTransparentSegment, StyledLinkDangerButton } from "../../ui/_style";

interface INotificationEmailsArgs {
  emails: string[];
  onRemove?: (email: string) => void;
  showIcon?: boolean;
  emptyMessage?: string;
  loading?: boolean;
  highlightedEmail?: string;
}

const EmailListView = ({ emails, onRemove, showIcon, emptyMessage, loading, highlightedEmail = "" }: INotificationEmailsArgs) => {
  const [currentEmail, setCurrentEmail] = useState<string | undefined>(undefined);
  const [highlightEmail, setHighlightEmail] = useState<string | undefined>(undefined);

  const onRemoveEmail = (email: string) => {
    setCurrentEmail(email);
    onRemove && onRemove(email);
  };

  useEffect(() => {
    setHighlightEmail(highlightedEmail);
  }, [highlightedEmail]);

  if (!emails.length) {
    return (
      <StyledEmptyTransparentSegment padded={!emptyMessage && "very"} data-testid="emptyMessage">
        {emptyMessage || "No E-mail addresses are configured. Please add new addresses to start sending notifications"}
      </StyledEmptyTransparentSegment>
    );
  }

  return (
    <>
      {emails.map((email) => (
        <StyledEmailSegment key={email} color={highlightEmail === email ? "green" : undefined} data-testid="emailItem">
          <FlexBox justify="space-between">
            <span className="ellipsis" title={email}>
              {email}
            </span>
            {onRemove && (
              <StyledLinkDangerButton
                loading={loading && currentEmail === email}
                color="red"
                data-testid="removeButton"
                onClick={() => onRemoveEmail(email)}
                labelPosition="right"
                icon={showIcon ? "trash" : null}
                label={!showIcon ? "Remove" : null}
              />
            )}
          </FlexBox>
        </StyledEmailSegment>
      ))}
    </>
  );
};

export default EmailListView;
