//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Button, Icon, Message, Segment } from "semantic-ui-react";
import { ListOptions as ApiListOptions } from "../../../api/lib";
import { Loading, PagingButtons, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../../ui/lib";
import { AppLinkButton } from "../../../ui/_link";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { useNotebookPermissions } from "../useNotebookPermissions";
import CreateNotebookView from "./CreateNotebookView";
import MLView from "./MLView";
import NotebookTableView from "./NotebookList";
import { useFetchNotebooksList } from "./useFetchNotebooksList";

const DataScienceView = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const deployment = useDeploymentStore((state) => state.deployment);
  const { canCreateNotebook, canListNotebookModels, canGetMLServices } = useNotebookPermissions();
  const [pageOptions, updatePageOptions] = useState<Required<Omit<ApiListOptions, "context_id">>>({
    page: 0,
    page_size: 10,
  });
  const { data, isLoading: isLoadingNotebooks, refetch: refetchNotebooks, isError: isNotebooksListFetchError } = useFetchNotebooksList();
  const { notebooks = [], budget = {} } = data || {};
  const toast = useToast();

  const onNextPage = () => {
    updatePageOptions({
      ...pageOptions,
      page: pageOptions.page + 1,
    });
  };

  const onPreviousPage = () => {
    updatePageOptions({
      ...pageOptions,
      page: pageOptions.page - 1,
    });
  };

  const getBudget = () => {
    const { used = 0, available = 0 } = budget;
    return +used + +available;
  };

  useEffect(() => {
    if (isNotebooksListFetchError) {
      toast({
        title: "Failed to fetch notebooks",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isNotebooksListFetchError]);

  if (isLoadingNotebooks) return <Loading />;

  return (
    <>
      <Message warning>
        <Icon name="info circle" />
        Please note that this is a beta version of this feature and it’s currently free for all. Future releases may introduce breaking changes.
      </Message>

      {canGetMLServices && <MLView />}

      <Section>
        <CreateNotebookView onClose={onClose} onSuccess={refetchNotebooks} isOpen={isOpen} />
        <SectionHead>
          <SectionHeader
            title={<span>Notebooks</span>}
            children={
              <PagingButtons
                testId="pagingButtons"
                page={pageOptions.page}
                count={notebooks.length}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                size="mini"
                pageSize={pageOptions.page_size}
              />
            }
          />
          {canCreateNotebook && canListNotebookModels && (
            <SectionButtons>
              <Button
                data-testid="notebookCreateAction"
                content="Create Notebook"
                size="medium"
                labelPosition="right"
                icon="plus"
                primary
                disabled={!budget.available}
                onClick={onOpen}
              />
            </SectionButtons>
          )}
        </SectionHead>

        <SectionContent>
          <>
            {deployment.is_paused ? (
              <Message warning>
                <Icon name="info circle" color="red" /> This deployment is paused. Notebooks require the deployment to be running. To resume this deployment, go
                to the <AppLinkButton label={"Overview"} href="#overview" target="_self" /> tab and click on Resume deployment or contact support if the issue
                persists.
              </Message>
            ) : (
              <Message>
                To access your notebook, you will need to be signed into ArangoGraph as a user with the <code>notebook.notebook.execute</code> permission in
                this project (by default, Organization Owners have this permission). The endpoint will be accessible only when the Notebook is running.{" "}
                {!budget.unlimited && (
                  <>
                    Only <b>{getBudget()}</b> Notebooks are allowed per deployment.
                  </>
                )}
              </Message>
            )}

            {!isLoadingNotebooks && notebooks.length ? (
              <NotebookTableView />
            ) : (
              <Segment>No notebooks created. Please use the create button to create a new notebook.</Segment>
            )}
          </>
        </SectionContent>
      </Section>
    </>
  );
};

export default DataScienceView;
