//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import styled from "@emotion/styled";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import "whatwg-fetch"; // Ensure we have a window.fetch function
import HeroSection from "./landing-page-components/Hero";
import FullyManagedInfoSection from "./landing-page-components/FullyManagedInfoSection";
import RunAnywhere from "./landing-page-components/RunAnywhere";
import HighlySecure from "./landing-page-components/HighlySecure";
import ScaleView from "./landing-page-components/ScaleView";
import GetStartedView from "./landing-page-components/GettingStarted";
import { HomeTemplateView } from "./HomeTemplate";
import HighAvailability from "./landing-page-components/HighAvailability";

interface IHomeViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
  loginWithSSO?: (organizationId?: string) => void;
}

const HomeContent = styled("div")`
  font-size: 22px;
`;

const AnimatedContainer = styled("div")`
  &.slide-up-fade-in {
    animation: slideUpFadeIn 0.3s ease-out forwards;
  }

  @keyframes slideUpFadeIn {
    from {
      transform: translateY(10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const HomeView = ({ ...args }: IHomeViewArgs) => (
  <HomeTemplateView {...args} onClickSignup={args.onClickSignup}>
    <HomeContent>
      <AnimatedContainer className="slide-up-fade-in">
        <HeroSection {...args} />
      </AnimatedContainer>
      <AnimatedContainer className="slide-up-fade-in">
        <FullyManagedInfoSection />
      </AnimatedContainer>
      <AnimatedContainer className="slide-up-fade-in">
        <RunAnywhere />
      </AnimatedContainer>
      <AnimatedContainer className="slide-up-fade-in">
        <HighlySecure />
      </AnimatedContainer>
      <AnimatedContainer className="slide-up-fade-in">
        <ScaleView />
      </AnimatedContainer>
      <AnimatedContainer className="slide-up-fade-in">
        <HighAvailability />
      </AnimatedContainer>
      <GetStartedView onClickSignup={args.onClickSignup} />
    </HomeContent>
  </HomeTemplateView>
);

const Home = (props: RouteComponentProps & IWithSignupModalProps & { auth: Auth }) => {
  return (
    <HomeView
      isAuthenticated={props.auth.isAuthenticated()}
      isAuthenticating={props.auth.isAuthenticating()}
      onClickLogin={() => props.auth.login()}
      onSignup={props.auth.signup}
      onClickLogout={props.auth.logout}
      loginWithSSO={(organizationId?: string) => props.auth.login(organizationId)}
      tracking={props.tracking}
      onClickSignup={props.onClickSignup}
      signupModalOpen={props.signupModalOpen}
      onCloseSignupModal={props.onCloseSignupModal}
    />
  );
};

export default withSignupModal(Home);
