//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";

export interface WizardStep {
  id: string;
  title: string;
  position: number;
  menuLabel: string;
  component: () => React.ReactElement;
  ignoreValidation?: boolean;
}

interface IWizardHook {
  handleNext: () => void | undefined;
  handleBack: () => void | undefined;
  handleStepClick: (step: WizardStep) => void | undefined;
  getCurrentStep: () => WizardStep;
  getSteps: () => WizardStep[];
  isLastStep: () => boolean;
  isFirstStep: () => boolean;
}

const useWizard = (steps: WizardStep[]): IWizardHook => {
  const [currentStep, setCurrentStep] = useState<WizardStep>(steps[0]);

  const getNextPosition = (): number => {
    const { position } = currentStep;
    return position <= steps.length ? position + 1 : position;
  };

  const getPreviousPosition = (): number => {
    const { position } = currentStep;
    return position > 1 ? position - 1 : position;
  };

  const moveToStep = (steps: WizardStep[], targetPosition: number): void => {
    const nextStep = steps.find((step) => step.position === targetPosition);
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };

  const handleNext = (): void => {
    moveToStep(steps, getNextPosition());
  };

  const handleBack = (): void => {
    moveToStep(steps, getPreviousPosition());
  };

  const handleStepClick = (step: WizardStep): void => {
    if (!!step) {
      setCurrentStep(step);
    }
  };

  const getCurrentStep = (): WizardStep => {
    return currentStep;
  };

  const getSteps = (): WizardStep[] => {
    return steps;
  };

  const isLastStep = (): boolean => {
    const { position } = currentStep;
    return position === steps.length;
  };

  const isFirstStep = (): boolean => {
    const { position } = currentStep;
    return position === 1;
  };

  return {
    handleNext,
    handleBack,
    handleStepClick,
    getCurrentStep,
    getSteps,
    isLastStep,
    isFirstStep,
  };
};

export default useWizard;
