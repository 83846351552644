import styled from "@emotion/styled";
import { Button, Input } from "semantic-ui-react";

export const UseCaseCard = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--gray-200);
  border-radius: 6px;
  padding: 20px 30px;
  height: 100px;
  text-align: center;
  background-color: ${(props) => (props.isSelected ? "var(--green-600)" : "var(--gray-100)")};
  color: ${(props) => (props.isSelected ? "white" : "black")};
  :hover {
    background-color: ${(props) => (props.isSelected ? "var(--green-600)" : "white")};
    color: ${(props) => (props.isSelected ? "white" : "black")};
  }
`;
export const StyledInput = styled(Input)`
  width: 100%;
`;
export const SkipButton = styled(Button)`
  background: none !important;
  text-decoration: underline !important;
  border: none !important;
  color: var(--gray-950) !important;
  font-weight: 400 !important;
  margin-right: auto !important;
  padding: 0 !important;
  align-self: center !important;
  :hover {
    background: none !important;
    text-decoration: underline !important;
    border: none !important;
    color: var(--gray-700) !important;
  }
`;
