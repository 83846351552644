//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { TopMenuBar } from "../../../components/TopMenuBar";
import { useDocumentTitle } from "../../../components/useDocumentTitle";
import organizationIcon from "../../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/organization.svg";

import { useDashboardContext } from "../../DashboardContextProvider";
import EditableTitle from "../../title/EditableTitle";

export const OrganizationPolicyTopMenu = () => {
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  useDocumentTitle(`Organization: ${selectedOrganization.name} | Policy`);
  return (
    <TopMenuBar
      breadcrumbs={[]}
      icon={organizationIcon}
      title={<EditableTitle title={topMenuInfo.getTitle()} save={topMenuInfo.getSaveTitles()} subTitle={topMenuInfo.getSubTitle()} />}
    />
  );
};
