//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface ITextLink {
  onClick: () => void;
  label?: string;
  children?: any;
  disabled?: boolean;
}

export const StyledLink = styled("a")`
  cursor: pointer;
`;

const StyledDisabledLink = styled("a")`
  cursor: not-allowed;
`;

const BreadCrumbStyle = css`
  height: 20px;
  width: 79px;
  color: var(--gray-600);
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 20px;
`;

const StyledBreadCrumbLink = styled(StyledLink)`
  ${BreadCrumbStyle}
`;

const StyledBreadCrumbDisabledLink = styled(StyledDisabledLink)`
  ${BreadCrumbStyle}
`;

export const TextLink = ({ ...args }: ITextLink) =>
  args.disabled ? (
    <StyledDisabledLink className="text-link">
      {args.label}
      {args.children}
    </StyledDisabledLink>
  ) : (
    <StyledLink
      className="text-link"
      onClick={(e) => {
        args.onClick();
        e.stopPropagation();
      }}
    >
      {args.label}
      {args.children}
    </StyledLink>
  );

export const BreadCrumbTextLink = ({ ...args }: ITextLink) =>
  args.disabled ? (
    <StyledBreadCrumbDisabledLink>
      {args.label}
      {args.children}
    </StyledBreadCrumbDisabledLink>
  ) : (
    <StyledBreadCrumbLink
      onClick={(e) => {
        args.onClick();
        e.stopPropagation();
      }}
    >
      {args.label}
      {args.children}
    </StyledBreadCrumbLink>
  );
