//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";

export interface IBoxProps {
  backgroundColor?: string;
  color?: string;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
  align?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  className?: string;
  style?: React.CSSProperties;
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  alignContent?: "flex-start" | "flex-end" | "center" | "stretch" | "space-between" | "space-around";
  alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  grow?: number | string;
  shrink?: number | string;
  basis?: number | string;
  flex?: number | string;
  children: React.ReactNode | undefined;
  margin?: number | string;
  cursor?: string;
  width?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  lineheight?: number | string;
  id?: string;
  position?: "relative" | "absolute" | "fixed" | "sticky";
  overflow?: "visible" | "hidden" | "scroll" | "auto";
  overflowX?: "visible" | "hidden" | "scroll" | "auto";
  overflowY?: "visible" | "hidden" | "scroll" | "auto";
  padding?: number | string;
  height?: number | string;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  display?: "flex" | "none" | "inline-flex" | "grid";
  "data-testid"?: string;
  gap?: number | string;
  gridTemplateColumns?: string;
  fontSize?: number | string;
  fontWeight?: number | string;
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  textDecoration?: "none" | "underline" | "overline" | "line-through";
  border?: string;
  borderLeft?: string;
  borderRight?: string;
  borderTop?: string;
  borderBottom?: string;
  borderRadius?: number | string;
  textAlign?: "left" | "right" | "center" | "justify";
  onClick?: () => void;
  boxShadow?: string;
  zIndex?: string;
  opacity?: number;
}

const BoxView = React.forwardRef((props: IBoxProps, ref: React.Ref<HTMLDivElement> | null) => {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
});

export const StyledBox = styled(BoxView)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  min-height: ${(props) => props.minHeight};
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.alignContent};
  flex-grow: ${(props) => props.grow};
  flex-shrink: ${(props) => props.shrink};
  flex-basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  line-height: ${(props) => props.lineheight};
  position: ${(props) => props.position};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  gap: ${(props) => props.gap};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  text-decoration: ${(props) => props.textDecoration};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  z-index: ${(props) => props.zIndex};
  text-align: ${(props) => props.textAlign};
  box-shadow: ${(props) => props.boxShadow};
  z-index: ${(props) => props.zIndex};
  opacity: ${(props) => props.opacity};
`;

export { StyledBox as Box };
