//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchCPUSizes } from "./useFetchCPUSizes";
import { useFetchDeploymentSize } from "./useFetchDeploymentSize";
import { useFetchNodeSizes } from "./useFetchNodeSizes";

export const useSizeSummary = () => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  // Determine cpu size
  let cpuSizeId = "standard";
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const nodeSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId);
  if (nodeSize && nodeSize.cpu_size) {
    cpuSizeId = nodeSize.cpu_size;
  }
  const cpuSizes = useFetchCPUSizes({ regionId: values.regionId });
  const { data: deploymentSize, isFetching: isFetchingDeploymentSize } = useFetchDeploymentSize({
    modelId: values.modelId,
    nodeSizeId: values.nodeSizeId,
    numberOfNodes: values.numberOfNodes,
    regionId: values.regionId,
    customDiskSize: values.customDiskSize,
  });
  const cpuSize = cpuSizes.data?.items?.find((x) => x.id === cpuSizeId);
  return {
    cpuSize,
    deploymentSize,
    memory: deploymentSize?.total_memory_size,
    disk: deploymentSize?.total_disk_size,
    isFetching: isFetchingDeploymentSize,
  };
};
