export const PRIVATE_ENDPOINT_CREATION_GUIDEGUIDE =
  "https://docs.microsoft.com/en-us/azure/private-link/create-private-endpoint-portal#create-a-private-endpoint";

export const PRIVATE_NETWORK_OVERVIEW_URL = "https://docs.microsoft.com/en-us/azure/private-link/";
export const PRIVATE_NETWORK_SERVICE_PROVIDER_OVERVIEW = "https://docs.microsoft.com/en-us/azure/private-link/private-link-overview";
export const AZURE_PRIVATE_LINK_URL = "https://docs.microsoft.com/en-us/learn/modules/introduction-azure-private-link/";
export const PRIVATE_LINK_SERVICE_VIDEO = "https://youtu.be/AZ0iFcyPDkc";

export const WHAT_IS_PRIVATE_LINK = "https://docs.aws.amazon.com/vpc/latest/privatelink/what-is-privatelink.html";
export const AWS_CREATE_ENDPOINT_SERVICE = "https://docs.aws.amazon.com/vpc/latest/privatelink/create-endpoint-service.html#share-endpoint-service";

export const GCP_WHAT_IS_PRIVATE_LINK = "https://cloud.google.com/vpc/docs/private-service-connect";
export const GCP_CONFIGURE_ENDPOINT = "https://cloud.google.com/vpc/docs/configure-private-service-connect-services";
