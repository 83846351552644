import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import { Organization, OrganizationList } from "../../api/lib";
import { Routes } from "../../routes";
import { Box } from "../../ui/_box";
import { useDashboardContext } from "../DashboardContextProvider";
import { DashboardSidebar } from "./DashboardSidebar";
import { DeploymentSidebar } from "./DeploymentSidebar";
import { OrganizationSidebar } from "./OrganizationSidebar";
import { ProjectSidebar } from "./ProjectSidebar";
import { useCheckPermissionByUrl } from "./useCheckPermissionByUrl";

export const SidebarContent = () => {
  const { selectedOrganization, organizations, onOpenInternalDashboard, onClickSupportRequest } = useDashboardContext();
  if (!selectedOrganization) {
    return null;
  }
  return (
    <Box display="flex" height="100%">
      <SidebarLeft selectedOrganization={selectedOrganization} />
      <SidebarRight
        onClickSupportRequest={onClickSupportRequest}
        organizations={organizations}
        onOpenInternalDashboard={onOpenInternalDashboard}
        selectedOrganization={selectedOrganization}
      />
    </Box>
  );
};
const SidebarLeft = ({ selectedOrganization }: { selectedOrganization: Organization }) => {
  const location = useLocation();
  const isOrganization = location.pathname.includes("organization");
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const hasOrganizationGetPermission = checkOrgPermission("resourcemanager.organization.get");
  return (
    <Box width="64px" backgroundColor="black" padding="120px 0 0 0">
      <Popup
        hoverable
        position="right center"
        on="hover"
        trigger={
          <Link to="/dashboard">
            <Box fontSize="1.5em" color={!isOrganization ? "white" : "var(--gray-600)"} display="flex" align="center" justify="center" height="40px">
              <Icon name="home" />
            </Box>
          </Link>
        }
        content={"Dashboard"}
      />
      {hasOrganizationGetPermission && (
        <Popup
          hoverable
          position="right center"
          on="hover"
          trigger={
            <Link to={Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")}>
              <Box fontSize="1.5em" color={isOrganization ? "white" : "var(--gray-600)"} display="flex" align="center" justify="center" height="40px">
                <Icon name="users" />
              </Box>
            </Link>
          }
          content={"Organization"}
        />
      )}
    </Box>
  );
};
const SidebarRight = ({
  selectedOrganization,
  onOpenInternalDashboard,
  organizations,
  onClickSupportRequest,
}: {
  organizations?: OrganizationList;
  selectedOrganization: Organization;
  onOpenInternalDashboard: () => void;
  onClickSupportRequest: () => void;
}) => {
  const orgMatch = useRouteMatch(Routes.dashboard_organization);
  const deploymentMatch = useRouteMatch<{
    projectId: string;
    deploymentId: string;
  }>(Routes.dashboard_project_deployment);
  const projectMatch = useRouteMatch<{
    projectId: string;
  }>(Routes.dashboard_project);
  const orgId = selectedOrganization.id || "";
  if (orgMatch) {
    return (
      <OrganizationSidebar
        onClickSupportRequest={onClickSupportRequest}
        organizations={organizations}
        onOpenInternalDashboard={onOpenInternalDashboard}
        orgId={orgId}
      />
    );
  }
  if (projectMatch && !deploymentMatch) {
    return (
      <ProjectSidebar
        onClickSupportRequest={onClickSupportRequest}
        organizations={organizations}
        onOpenInternalDashboard={onOpenInternalDashboard}
        orgId={orgId}
        projectMatch={projectMatch}
      />
    );
  }
  if (deploymentMatch) {
    return (
      <DeploymentSidebar
        onClickSupportRequest={onClickSupportRequest}
        organizations={organizations}
        onOpenInternalDashboard={onOpenInternalDashboard}
        deploymentMatch={deploymentMatch}
      />
    );
  }
  return <DashboardSidebar onClickSupportRequest={onClickSupportRequest} organizations={organizations} onOpenInternalDashboard={onOpenInternalDashboard} />;
};
