//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import api from "./api/api";
import "./App.css";
import Auth from "./auth/Auth";
import { Routes } from "./routes";
import "./semantic/semantic.css";
import { Box } from "./ui/_box";
import { Loading } from "./ui/_loading";
import { PersistentState } from "./util/PersistentState";

const App = ({ auth }: { auth: Auth }) => {
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();
  const renewingSession = auth.renewingSession;

  useEffect(() => {
    PersistentState.doesURLNeedsRedirectionToItself(location) && PersistentState.enableURLRedirectionToItself(location);
    auth.renewSessionIfLoggedIn();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      //set token in api, so all calls are authenticated
      api.token = auth.getAccessToken();
    } else {
      const { pathname, hash = "", search = "" } = location;

      const startIndex = pathname.indexOf("/");
      if (startIndex != -1 && pathname.substr(startIndex).startsWith(Routes.dashboard)) {
        auth.setRedirectToRoute(pathname + hash + search);
      }
    }
  }, [isAuthenticated]);

  if (!isAuthenticated && renewingSession) {
    return (
      <Box backgroundColor="white" width="100%" position="relative" height="100vh" zIndex={"10000000"}>
        <Loading />
      </Box>
    );
  }

  if (!isAuthenticated) {
    //goto login-page if not already there
    if (!Routes.isUnauthenticated(location.pathname)) {
      return <Redirect to={Routes.home} />;
    }
  }

  return <div />;
};

export default App;
