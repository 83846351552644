//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { PrivateEndpointService_AksPrivateEndpointConnectionStatus } from "../../../api/network/v1/network";
import { pluralize } from "../../../api/_util";
import { FlexBox } from "../../../ui/_flex";
import { Section, SectionHeader } from "../../../ui/_section";
import { StyledStatsSegment } from "../../../ui/_style";
import PrincipalsSummaryModalView from "./PrincipalsSummaryModalView";
import AWSPrivateNetworkHelpView from "./PrivateNetworkHelpView";
import { AWSPrincipal, AWSSummary } from "../types";
import { AWServiceNameView } from "./ServiceNameView";
import { AWSPrivateEndpointConnectionsModalView } from "./ConnectionsView";
import { SummaryItem } from "../SummaryItemTemplate";
import { AWSAvailabilityZonesView } from "./AvailabilityZonesView";
import { CustomDNSModalView } from "../CustomDNSView";

const AWSPrivateNetworkSummaryView = ({ privateNetworkDetail, onAlternateDNSNamesChange, onSupportClick, onAWSPrincipalsChange }: AWSSummary) => {
  const { alternate_dns_names: dnsNames = [], status = {}, aws = {}, enable_private_dns: enablePrivateDNS } = privateNetworkDetail;
  const { needs_attention: isAttentionRequired = false, message = "", aws: awsStatus = {} } = status;

  const [showDNSModalView, toggleDNSModalView] = useState<boolean>(false);
  const [showAWSConnectionsModal, toggleAWSConnectionsModal] = useState<boolean>(false);
  const [showServiceNameView, toggleServiceNameView] = useState<boolean>(false);
  const [showAvailabilityZones, toggleAvailabilityZonesView] = useState<boolean>(false);
  const [showHelpView, toggleHelpView] = useState<boolean>(false);

  const { aws_principals: awsPrincipals = [] } = aws;

  const { service_name: serviceName, availability_zones: availabilityZones = [], private_endpoint_connections: awsConnections = [] } = awsStatus;
  const [showPrincipalsView, togglePrincipalsView] = useState<boolean>(false);

  const hasFailedConnections = (connections: PrivateEndpointService_AksPrivateEndpointConnectionStatus[]) =>
    !!connections.find((connection) => connection.state === "failed");

  return (
    <Section>
      {showDNSModalView && (
        <CustomDNSModalView
          alternateDNSNames={dnsNames}
          enablePrivateDNS={!!enablePrivateDNS}
          onClose={() => toggleDNSModalView(false)}
          onSave={onAlternateDNSNamesChange}
          isPrivateDNSEditable
          info={
            <>
              <div>A Private Endpoint will result in IP addresses in your VPC.</div>
              <div>We strongly recommend mapping those IP addresses to one or more private DNS names.</div>
              <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
            </>
          }
        />
      )}

      {showServiceNameView && <AWServiceNameView onClose={() => toggleServiceNameView(false)} initialValue={serviceName} />}

      {showAvailabilityZones && <AWSAvailabilityZonesView onClose={() => toggleAvailabilityZonesView(false)} availabilityZones={availabilityZones} />}

      {showAWSConnectionsModal && <AWSPrivateEndpointConnectionsModalView onClose={() => toggleAWSConnectionsModal(false)} connections={awsConnections} />}

      {showHelpView && <AWSPrivateNetworkHelpView onClose={() => toggleHelpView(false)} onSupportClick={onSupportClick} />}

      {showPrincipalsView && (
        <PrincipalsSummaryModalView
          awsPrincipals={awsPrincipals as AWSPrincipal[]}
          onSave={onAWSPrincipalsChange}
          onClose={() => {
            togglePrincipalsView(false);
          }}
          disableChange={!!awsConnections.length}
          statusMessage={<span>Principals cannot be modified once a connection has been established.</span>}
        />
      )}

      <SectionHeader
        testID="privateNetworkSummaryHeader"
        title={
          <span>
            Private Endpoint{" "}
            {isAttentionRequired && (
              <Popup
                trigger={<Icon name="warning circle" color="red" data-testid="networkErrorIcon" />}
                content={message}
                data-testid="awsSummaryHeaderErrorMessage"
              />
            )}
          </span>
        }
        actionableText="Help"
        onActionTextClick={() => toggleHelpView(true)}
      />

      <StyledStatsSegment>
        <FlexBox justify="space-between" margin="0 40px">
          <SummaryItem
            labelIcon="cloud"
            actionIcon={awsConnections.length > 0 ? "eye" : "edit"}
            mainLabel="AWS Principals"
            actionLabel={`${awsPrincipals.length ? awsPrincipals.length : "No"} ${pluralize("principal", awsPrincipals.length)} configured`}
            actionClassName="private-link-stat-item"
            handleClick={() => togglePrincipalsView(true)}
            testID="awsPrincipalsSummaryItem"
          />

          <SummaryItem
            labelIcon="server"
            actionIcon="edit"
            mainLabel="Custom DNS names"
            handleClick={() => toggleDNSModalView(true)}
            actionLabel={`${dnsNames.length ? dnsNames.length : "No"} custom DNS ${pluralize("name", dnsNames.length)} configured.`}
            actionClassName="private-link-stat-item"
            testID="alternateDNSNamesSummaryItem"
          />

          <SummaryItem
            labelIcon="paperclip"
            mainLabel="Service name"
            actionLabel={serviceName ? serviceName : "Creating...."}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleServiceNameView(true)}
            actionIcon="eye"
            noAction={!serviceName}
            testID="serviceNameSummaryItem"
          />

          <SummaryItem
            labelIcon="map marker alternate"
            mainLabel="Availability Zones"
            actionLabel={availabilityZones.length ? `${availabilityZones.length} zones available` : "No zones available"}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleAvailabilityZonesView(true)}
            actionIcon="eye"
            noAction={!availabilityZones.length}
            testID="availabilityZonesSummaryItem"
          />

          <SummaryItem
            labelIcon="linkify"
            mainLabel="Connections"
            actionLabel={`${awsConnections.length ? awsConnections.length : "No"} established ${pluralize("connection", awsConnections.length)}`}
            actionClassName="private-link-stat-item"
            actionIcon="eye"
            handleClick={() => toggleAWSConnectionsModal(true)}
            noAction={!awsConnections.length}
            hasError={hasFailedConnections(awsConnections)}
            errorMessage="Something went wrong in establishing a connection"
            testID="privateConnectionsSummaryItem"
          />
        </FlexBox>
      </StyledStatsSegment>
    </Section>
  );
};

export default AWSPrivateNetworkSummaryView;
