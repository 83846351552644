//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { FlexBox } from "../../../ui/_flex";
import { useDataloaderStore } from "./DataloaderStore";
import { EditView } from "./EditorView";
import FileUploadView from "./files/FileUploadView";
import { StyledMenuContent, MenuBar } from "./Styles";

export const DataControlCenter = () => {
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { editView } = getDeploymentDataloaderState();
  const [activeMenu, setActiveMenu] = useState(0);

  const handleSetFileUploadMenuActive = () => {
    setActiveMenu(0);
    updateDeploymentData({ editView: undefined });
  };

  useEffect(() => {
    if (!editView) return;
    setActiveMenu(1);
  }, [editView]);

  return (
    <>
      <FlexBox>
        <StyledMenuContent className="menu-content">
          <div className={`menu-content ${activeMenu === 0 ? "active" : ""}`} onClick={handleSetFileUploadMenuActive}>
            <FileUploadView />
          </div>
          <div className={`menu-content ${activeMenu === 1 ? "active" : ""}`} onClick={() => setActiveMenu(1)}>
            <EditView />
          </div>
        </StyledMenuContent>
        <MenuBar>
          <div className={`menu-item ${activeMenu === 0 ? "active" : ""}`} onClick={handleSetFileUploadMenuActive} data-testid="data-controlcenter-fileupload">
            <Icon name="file alternate outline" />
          </div>
          <div className={`menu-item ${activeMenu === 1 ? "active" : ""}`} onClick={() => setActiveMenu(1)} data-testid="data-controlcenter-edit">
            <Icon name="cloudsmith" />
          </div>
        </MenuBar>
      </FlexBox>
    </>
  );
};
