import { useIsFetching } from "@tanstack/react-query";
import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Routes } from "../../routes";
import { ContentActionButtonNew } from "../../ui/_buttons";
import { Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/_section";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import { HistoryHelper } from "../HistoryHelper";
import CACertificateList from "../cacertificate/CACertificateList";
import CreateCACertificateModal from "../cacertificate/CreateCACertificateModal";
import IAMProviderList from "../iamprovider/IAMProviderList";
import CreateIPAllowlistModal from "../ipallowlist/CreateIPAllowlistModal";
import IPAllowlistList from "../ipallowlist/IPAllowlistList";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { useProjectDetailsContext } from "./ProjectDetailsContextProvider";

export const ProjectSecurityView = () => {
  const {
    project,
    isProcessing,
    createCACertificate,
    createIPAllowlist,
    setCreateCACertificate,
    setCreateIPAllowlist,
    onError,
    refetchIPAllowlists,
    refetchCACertificates,
    refetchIAMProviders,
    cacertificates,
    ipallowlists,
  } = useProjectDetailsContext();
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const isFetching = useIsFetching();
  const { checkProjectPermission } = useCheckPermissionByUrl();
  const has_ipallowlist_create = checkProjectPermission("security.ipallowlist.create");
  const has_certificate_create = checkProjectPermission("crypto.cacertificate.create");

  const has_iamprovider_create = checkProjectPermission("security.iamprovider.create");
  const is_allowed_to_use_iamproviders = !!selectedOrganization.is_allowed_to_use_iamproviders;
  const history = useHistory();
  const location = useLocation();
  const is_project_deleted = project?.is_deleted || false;
  const { eventSubscriptionManager, permissionCache } = useWithRefresh();
  const iamproviderMatch = useRouteMatch<{
    iamproviderId: string;
  }>(Routes.dashboard_project_iamprovider_details);
  const onIAMProviderSelected = (id: string) => {
    history.push(Routes.dashboard_project_iamprovider_detailsWithId(project.id || "", id));
  };
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader
            title="IP allowlists"
            loading={!!isFetching}
            help={
              <div>
                <p className="para">IP allowlists grant access to deployments from specified IP addresses (or address ranges) only.</p>
                <p className="para">It is highly recommended to use an IP allowlist that only grants access from a small number of IP addresses.</p>
              </div>
            }
          />
          <SectionButtons>
            {has_ipallowlist_create && !is_project_deleted && (
              <ContentActionButtonNew primary disabled={isProcessing} content="New IP allowlist" onClick={() => setCreateIPAllowlist(true)} />
            )}
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          {createIPAllowlist && (
            <CreateIPAllowlistModal
              project={project}
              onNewIPAllowlistCreated={() => {
                setCreateIPAllowlist(false);
                refetchIPAllowlists();
              }}
              onClose={() => setCreateIPAllowlist(false)}
            />
          )}
          <IPAllowlistList
            onIPAllowlistDeleted={() => refetchIPAllowlists()}
            onRefreshList={refetchIPAllowlists}
            onError={onError}
            project={project}
            eventSubscriptionManager={eventSubscriptionManager}
            permissionCache={permissionCache}
            loading={false}
            ipallowlists={ipallowlists}
          />
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader
            title="Certificates"
            help={
              <div>
                <p className="para">Certificates are used in the encryption of network traffic.</p>
                <p className="para">It is recommended to use Well-known certificates, since that eases the access of a deployment in your browser.</p>
              </div>
            }
          />
          <SectionButtons>
            {has_certificate_create && !is_project_deleted && (
              <ContentActionButtonNew primary disabled={isProcessing} content="New certificate" onClick={() => setCreateCACertificate(true)} />
            )}
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          {createCACertificate && (
            <CreateCACertificateModal
              onNewCACertificateCreated={() => {
                setCreateCACertificate(false);
                refetchCACertificates();
              }}
              onClose={() => setCreateCACertificate(false)}
            />
          )}
          <CACertificateList
            project={project}
            cacertificates={cacertificates}
            eventSubscriptionManager={eventSubscriptionManager}
            permissionCache={permissionCache}
            loading={false}
            onCACertificateDefaultChanged={refetchCACertificates}
            onCACertificateDeleted={refetchCACertificates}
            onRefreshList={refetchCACertificates}
            onError={onError}
          />
        </SectionContent>
      </Section>
      {is_allowed_to_use_iamproviders && (
        <Section>
          <SectionHead>
            <SectionHeader title="IAM providers" />
            <SectionButtons>
              {has_iamprovider_create && !is_project_deleted && (
                <ContentActionButtonNew
                  primary
                  disabled={isProcessing}
                  content="New IAM provider"
                  onClick={() => {
                    HistoryHelper.push(history, Routes.dashboard_project_iamproviders_createWithId(project.id || ""), topMenuInfo.getTitle());
                  }}
                />
              )}
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            <IAMProviderList
              onIAMProviderDefaultChanged={() => refetchIAMProviders()}
              onIAMProviderDeleted={() => refetchIAMProviders()}
              onError={onError}
              topMenuInfo={topMenuInfo}
              organization={selectedOrganization}
              project={project}
              onIAMProviderSelected={onIAMProviderSelected}
              eventSubscriptionManager={eventSubscriptionManager}
              permissionCache={permissionCache}
              loading={false}
              history={history}
              location={location}
              match={iamproviderMatch as any}
            />
          </SectionContent>
        </Section>
      )}
    </div>
  );
};
