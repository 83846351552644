//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { IBoxProps } from "./_box";

const FlexView = React.forwardRef((props: IBoxProps, ref: React.Ref<HTMLDivElement> | null) => {
  return (
    <div className="flex-group" {...props} ref={ref}>
      {props.children}
    </div>
  );
});

const StyledFlex = styled(FlexView)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  display: flex;
  min-height: ${(props) => props.minHeight};
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.alignContent};
  flex-grow: ${(props) => props.grow};
  flex-shrink: ${(props) => props.shrink};
  flex-basis: ${(props) => props.basis};
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  line-height: ${(props) => props.lineheight};
  position: ${(props) => props.position};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  left: ${(props) => props.left};
  gap: ${(props) => props.gap};
`;

export { StyledFlex as FlexBox };
