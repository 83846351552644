//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState, ChangeEvent } from "react";
import { useDataloaderStore } from "../DataloaderStore";
import { Button, Form, Progress, InputOnChangeData, Input, Popup } from "semantic-ui-react";
import { Box } from "../../../../ui/_box";
import { FlexBox } from "../../../../ui/_flex";
import styled from "@emotion/styled";
import { ContentActionButtonNew } from "../../../../ui/_buttons";
import { UploadedFile } from "./UploadedFile";
import { useDeploymentStore } from "../../../../util/storage/DeploymentStore";
import { GraphContainer } from "../Styles";
import { ErrorMessage } from "../../../../ui/_errorMessage";
import { GraphContextProvider } from "../graph-modeller/GraphProvider";
import { Canvas } from "../graph-modeller/Canvas";
import { DataControlCenter } from "../DataControlCenter";
import { pluralize } from "../../../../api/_util";
import { PapaparseProvider } from "../files/FileParserProvider";
import useUploadGraphAndFiles from "../utils/useUploadGraphAndFiles";
import ParsedFileWithErrors from "./ParsedFileWithErrors";
import DatabasePicker from "../DatabasePicker";
import { getGraphNameErrors } from "../utils/namesValidations.utils";
import { useArangoClient } from "../hooks/useArangoClient";
import { usePreventReload } from "../../../../components/usePreventReload";

const StyledProgress = styled(Progress)`
  margin-bottom: 16px !important;
`;

const StyledProgressContainer = styled(FlexBox)`
  justify-content: center;
  align-self: center;
`;

const DataMigrationNotStarted = () => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const { status: deploymentStatus = {} } = useDeploymentStore().deployment;
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { migrationJob, name } = getDeploymentDataloaderState();

  const [graphNameErrors, setGraphNameErrors] = useState<string[]>(getGraphNameErrors(name));

  const hasGraphNameError = name !== "" && !!graphNameErrors.length;

  const handleChangeGraphName = (e: ChangeEvent, { value }: InputOnChangeData) => {
    updateDeploymentData({ name: value });
    setGraphNameErrors(getGraphNameErrors(value));
  };

  const handleDismissErrorMessage = () => updateDeploymentData({ errorMessage: null });

  useEffect(() => {
    handleDismissErrorMessage();
  }, []);

  if (migrationJob.status !== "not_started" || !deploymentStatus.endpoint) return <></>;

  return (
    <Box padding="0 18px 18px 18px" margin="-90px 0 0 0">
      <Box margin="12px 0">
        <h4 className="heading-4">1. Choose a Database or create a new one:</h4>
        <span>Select the database you want the data to be imported or create a new one in case you don’t have one yet.</span>
      </Box>
      <FlexBox direction="column">
        <div>
          <DatabasePicker />
        </div>
        <Box margin="24px 0 0 0">
          <Box margin="24px 0">
            <h4 className="heading-4">2. Choose a name for your new graph:</h4>
            <span>Enter a name for your new graph. The name must be unique for this Database</span>
          </Box>
          <Form error={hasGraphNameError}>
            <Form.Field label="Graph name" control={Input} width={3} error={hasGraphNameError}>
              <Popup
                open={hasGraphNameError && isInputFocused}
                position="right center"
                trigger={
                  <Input
                    placeholder="my_first_graph"
                    name="name"
                    value={name}
                    autoFocus
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    onChange={handleChangeGraphName}
                    error={hasGraphNameError}
                    icon={hasGraphNameError ? "info circle" : undefined}
                  />
                }
              >
                {graphNameErrors.map((nameError) => (
                  <p key={nameError}>{nameError}</p>
                ))}
              </Popup>
            </Form.Field>
          </Form>
        </Box>
      </FlexBox>
    </Box>
  );
};

const GraphModelerStatusView = () => {
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { nodes, edges, migrationJob, errorMessage } = getDeploymentDataloaderState();

  const handleDismissErrorMessage = () => updateDeploymentData({ errorMessage: null });

  useEffect(() => {
    handleDismissErrorMessage();
  }, []);

  if (migrationJob.status !== "graph_modeler") return <></>;

  return (
    <>
      <Box padding="12px 0 10px 0px">
        <span>
          Drag and drop your files and start modelling your graph by adding nodes and connecting them. Check out this{" "}
          <a className="text-link" target="_blank" href="https://docs.arangodb.com/stable/arangograph/data-loader/example/" rel="noreferrer">
            <b>example</b>
          </a>{" "}
          for a detailed description.
        </span>
        <ErrorMessage active={!!errorMessage} onDismiss={handleDismissErrorMessage} message={errorMessage} />
      </Box>
      <PapaparseProvider>
        <GraphContextProvider data={{ nodes, edges }} canvasRenderTargetID="graph-container-1">
          <GraphContainer>
            <Canvas id="graph-container-1" />
            <DataControlCenter />
          </GraphContainer>
        </GraphContextProvider>
      </PapaparseProvider>
    </>
  );
};

const ParsingValidationStatusView = () => {
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { uploadGraphAndFiles } = useUploadGraphAndFiles();
  const { migrationJob } = getDeploymentDataloaderState();
  const { fileBeingParsed, filesAlreadyParsed } = migrationJob;

  const hasSomeParsedFileWithErrors = filesAlreadyParsed?.some((parsedFileReport) => !!parsedFileReport.errors.length);

  const handleCancelImport = () =>
    updateDeploymentData({
      migrationJob: { status: "not_started", migrationCancelled: true },
    });

  const handleBackToImportHelper = () =>
    updateDeploymentData({
      errorMessage: null,
      migrationJob: { status: "graph_modeler" },
    });

  if (migrationJob.status !== "files_validation") return <></>;

  return (
    <>
      <StyledProgressContainer direction="column" width="65%" padding="32px" justify="center">
        {fileBeingParsed ? (
          <>
            <h2 className="heading-2">Files validation in progress, please wait</h2>
            <h3 className="heading-3">Validating: {fileBeingParsed.name}</h3>
            <Progress percent={fileBeingParsed.progress} color="green" progress />
            <FlexBox justify="center">
              <Button color="red" onClick={handleCancelImport} size="medium" disabled={!!migrationJob.migrationCancelled}>
                Cancel import
              </Button>
            </FlexBox>
          </>
        ) : (
          hasSomeParsedFileWithErrors && (
            <>
              <h2 className="heading-2">Some of the files have parsing errors:</h2>
              <p className="para">
                If you continue with the data import without addressing parsing errors, the rows with errors will be skipped and excluded from the import - it
                means documents from these rows will not be created in the target collection(s). Please proceed only if you are ok with skipping such rows,
                otherwise fix and upload the files again.
              </p>
              {filesAlreadyParsed?.map((parsedFileReport) => !!parsedFileReport.errors.length && <ParsedFileWithErrors parsedFileReport={parsedFileReport} />)}
              <FlexBox>
                <Box margin="32px auto 0 auto">
                  <Button onClick={handleBackToImportHelper}>Back to import helper</Button>
                  <Button onClick={uploadGraphAndFiles} primary>
                    Ignore the issues and continue import
                  </Button>
                </Box>
              </FlexBox>
            </>
          )
        )}
      </StyledProgressContainer>
    </>
  );
};

const GraphCreationStatusView = () => {
  const { nodes, edges, name: graphName, migrationJob } = useDataloaderStore().getDeploymentDataloaderState();
  const { graphBeingUploadedProgress } = migrationJob;

  if (migrationJob.status !== "graph_creation") return <></>;

  return (
    <>
      <StyledProgressContainer direction="column" width="65%" padding="32px" justify="center">
        <h2 className="heading-2">Graph {graphName} creation in progress, please wait</h2>
        <Progress percent={graphBeingUploadedProgress} color="green" size="tiny" />
        <FlexBox justify="space-between">
          <p className="para">
            Creating collections for {nodes.length} nodes and {edges.length} edges.
          </p>
        </FlexBox>
      </StyledProgressContainer>
    </>
  );
};

const FilesUploadStatusView = () => {
  const { setMigrationJob, getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { migrationJob, name, currentDatabase, nodes, edges } = getDeploymentDataloaderState();
  const { db } = currentDatabase;
  const { fileBeingUploaded, filesAlreadyUploaded = [], totalFilesToUpload } = migrationJob;
  const totalUploads = totalFilesToUpload || [...nodes, ...edges].length;

  const handleCancelImport = async () => {
    try {
      await db?.graph(name).drop(true);
      setMigrationJob({
        status: "not_started",
        migrationCancelled: true,
      });
    } catch {
      updateDeploymentData({ errorMessage: "Import could not be canceled." });
    }
  };

  if (migrationJob.status !== "files_upload") return <></>;

  const currentFile = filesAlreadyUploaded.length + 1 > totalUploads ? totalUploads : filesAlreadyUploaded.length + 1;
  const percent = ((filesAlreadyUploaded.length + 1) / totalUploads) * 100;
  return (
    <StyledProgressContainer direction="column" width="65%" height="400px" padding="32px">
      <FlexBox direction="column" height="100%" justify="space-around">
        <h2 className="heading-2">Filling with documents your brand new collections, please wait</h2>
        <div>
          <StyledProgress percent={percent} color="green" size="tiny" />
          <p className="para">
            Uploading file {currentFile} of {totalUploads}.
          </p>
        </div>
        <div>
          <p className="para">
            File name: <b>{fileBeingUploaded?.name}</b>
          </p>
          <p className="para">
            Collection name: <b>{fileBeingUploaded?.collectionName}</b>
          </p>
          <StyledProgress percent={fileBeingUploaded?.progress || 0} color="green" progress />
        </div>
      </FlexBox>
      <FlexBox justify="center">
        <Button color="red" onClick={handleCancelImport} size="medium" disabled={!!migrationJob.migrationCancelled}>
          Cancel import
        </Button>
      </FlexBox>
    </StyledProgressContainer>
  );
};

const FinishedMigrationStatusView = () => {
  const [graphURL, setGraphURL] = useState<URL | undefined>(undefined);
  const { status } = useDeploymentStore().deployment;
  const { resetStore, getDeploymentDataloaderState } = useDataloaderStore();
  const { migrationJob, nodes, edges, name: graphName, currentDatabase } = getDeploymentDataloaderState();

  const { filesAlreadyUploaded = [] } = migrationJob;

  const generateAndSetGraphURL = () => {
    if (!status?.endpoint) return;
    const databaseName = currentDatabase.dbInfo?.name;
    const url = new URL(status.endpoint);
    url.port = "";
    url.hash = "graphs";
    if (databaseName) url.pathname = `_db/${databaseName}`;
    setGraphURL(url);
  };

  const handleResetStore = () => {
    resetStore();
  };

  useEffect(() => {
    generateAndSetGraphURL();
  }, [currentDatabase.dbInfo, status?.endpoint]);

  const sortedUploadedFiles = filesAlreadyUploaded
    // First show elements without errors, then elements with errors and last elements where uploadError is not undefined
    .sort((a, b) => (!a.uploadError && !b.uploadError ? a.errors.length - b.errors.length : !a.uploadError || !b.uploadError ? -1 : 1))
    .map((file) => <UploadedFile key={file.collectionName} uploadedFileReport={file} />);

  if (migrationJob.status !== "finished") return <></>;

  return (
    <>
      <h2 className="heading-2">The data import finished with the following results:</h2>
      <FlexBox direction="column">
        <p className="para">
          Graph <b>{graphName}</b> with <b>{nodes.length}</b> {pluralize("node", nodes.length)} and <b>{edges.length}</b> {pluralize("edge", edges.length)} was
          created
        </p>
        <p className="para">The following collections were created and filled with the data in the provided files:</p>
        <ul className="unordered-list">{sortedUploadedFiles}</ul>
      </FlexBox>
      <Box margin="32px auto 0 auto">
        <Button onClick={handleResetStore}>Load new data</Button>
        <ContentActionButtonNew primary icon="external alternate" content="See your new graph" onClick={() => window.open(graphURL)} loading={!graphURL} />
      </Box>
    </>
  );
};

const FailedMigrationStatusView = () => {
  const { updateDeploymentData, getDeploymentDataloaderState } = useDataloaderStore();
  const { migrationJob } = getDeploymentDataloaderState();
  const handleBackToImportHelper = () => {
    updateDeploymentData({
      errorMessage: null,
      migrationJob: { status: "not_started" },
    });
  };

  if (migrationJob.status !== "error") return <></>;

  return (
    <>
      <h2 className="heading-2">Error, graph not created</h2>
      <FlexBox direction="column">There was an error while creating the graph, if the error persists, please contact support.</FlexBox>
      <Box margin="32px auto 0 auto">
        <Button onClick={handleBackToImportHelper} primary>
          Back to import helper
        </Button>
      </Box>
    </>
  );
};

const DataIngestionStatusView = () => {
  const { deployment } = useDeploymentStore();
  const { status } = deployment;
  const { getDeploymentDataloaderState, updateDeploymentData } = useDataloaderStore();
  const { migrationJob, errorMessage } = getDeploymentDataloaderState();
  const [hasSomeValidUploadReport, setHasSomeValidUploadReport] = useState<boolean>(true);
  const [hasSomeValidParseReport, setHasSomeValidParseReport] = useState<boolean>(true);

  const isFullReportValid = async (url: string) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  useArangoClient(status?.endpoint);

  const isNotStarted = migrationJob.status === "not_started";
  const isGraphBeingModeled = migrationJob.status === "graph_modeler";
  const hasError = migrationJob.status === "error";
  const isFinishedWithoutValidUploadReport = migrationJob.status === "finished" && !hasSomeValidUploadReport;
  const isFilesValidationWithoutFileBeingParsed = migrationJob.status === "files_validation" && !migrationJob.fileBeingParsed;
  const isFilesValidationWithoutValidParseReport = migrationJob.status === "files_validation" && !hasSomeValidParseReport;

  const isExitAllowed =
    isNotStarted ||
    isGraphBeingModeled ||
    hasError ||
    isFinishedWithoutValidUploadReport ||
    isFilesValidationWithoutFileBeingParsed ||
    isFilesValidationWithoutValidParseReport;

  usePreventReload({ dirty: !isExitAllowed });

  const handleDismissErrorMessage = () => {
    updateDeploymentData({ errorMessage: null });
  };

  useEffect(() => {
    handleDismissErrorMessage();

    const hasValidUploadReport = migrationJob.filesAlreadyUploaded?.some((file) => !!file.fullReportURL && isFullReportValid(file.fullReportURL));
    const hasValidParseReport = migrationJob.filesAlreadyParsed?.some((file) => !!file.parseReportURL && isFullReportValid(file.parseReportURL));

    setHasSomeValidUploadReport(!!hasValidUploadReport);
    setHasSomeValidParseReport(!!hasValidParseReport);
  }, []);

  return (
    <Box display="flex" direction="column" justify="center" margin="0">
      <ErrorMessage active={!!errorMessage} onDismiss={handleDismissErrorMessage} message={errorMessage} />
      <DataMigrationNotStarted />
      <GraphModelerStatusView />
      <ParsingValidationStatusView />
      <GraphCreationStatusView />
      <FilesUploadStatusView />
      <FinishedMigrationStatusView />
      <FailedMigrationStatusView />
    </Box>
  );
};

export default DataIngestionStatusView;
