//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "semantic-ui-react";
import { EditDetailsView } from "./BillingDetailsEditor";
import { BillingConfig } from "../../api/lib";
import { TextLink } from "../../ui/_textlink";
import { FlexBox } from "../../ui/_flex";
import { FormActionButtonSave } from "../../ui/_buttons";

interface IBillingDetailsModal {
  isOpen: boolean;
  config: BillingConfig;
  configSetter: Dispatch<SetStateAction<BillingConfig>>;
  onClickSave: () => void;
  disableSaveButton: boolean;
  onSkip: () => void;
}

const AddBillingAddressModal = ({ isOpen, config, configSetter, onClickSave, disableSaveButton, onSkip }: IBillingDetailsModal) => {
  return (
    <Modal open={isOpen}>
      <Modal.Header>Add your billing address</Modal.Header>
      <Modal.Content>
        <EditDetailsView config={config} configSetter={configSetter} />
      </Modal.Content>
      <Modal.Actions>
        <FlexBox justify="space-between" align="center">
          <TextLink onClick={onSkip} label="Skip process for now" />
          <FormActionButtonSave primary title="Next" icon="arrow right" onClick={onClickSave} disabled={disableSaveButton} />
        </FlexBox>
      </Modal.Actions>
    </Modal>
  );
};

export default AddBillingAddressModal;
