//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { PolicyList } from "../../policy/PolicyList";
import { useProjectDetailsContext } from "../ProjectDetailsContextProvider";

export const ProjectPolicyList = () => {
  const { project } = useProjectDetailsContext();
  const isProjectDeleted = project?.is_deleted || false;
  const history = useHistory();
  const onCreatePolicyClick = () => {
    if (!project.id) {
      return;
    }
    history.push(Routes.dashboard_project_policy_createWithId(project.id));
  };
  return (
    <PolicyList
      resourceUrl={project.url}
      onCreatePolicyClick={onCreatePolicyClick}
      isCreatePolicyDisabled={isProjectDeleted}
      tooltip={
        <Flex direction="column" gap="2">
          <Text>
            Policy defines which users are used allowed to perform certain operations on the project and resources within the project (deployments, IP
            allowlists and certificates).
          </Text>
          <Text>
            Note that once this policy grants a user access to a certain operation, the user has access to that operation on all resources in the entire
            project.
          </Text>
          <Text>It is recommended to grant access as close to the resource (where it is needed) as possible.</Text>
        </Flex>
      }
    />
  );
};
