//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";

export const useFetchDeployment = ({ deploymentId }: { deploymentId: string }) => {
  const fetchDeployment = async () => {
    const deployment = await apiClients.dataClient.GetDeployment({
      id: deploymentId,
    });

    return deployment;
  };
  return useQuery([deploymentId], fetchDeployment, {
    enabled: !!deploymentId,
  });
};
