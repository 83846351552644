//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { AvailableCredits_CreditCurrencyAmount as ApiAvailableCredits_CreditCurrencyAmount } from "../../api/lib";
import { Card, Header, Icon } from "semantic-ui-react";
import { Box } from "../../ui/_box";

interface ICreditViewArgs {
  vouchers: ApiAvailableCredits_CreditCurrencyAmount[] | undefined;
}

const VouchersView = ({ vouchers }: ICreditViewArgs) => {
  return (
    <>
      {vouchers?.map((credit) => {
        return (
          <Card>
            <Box data-testid="creditViewCard" position="relative" height="100%" minHeight="160px">
              <Box padding="20px 20px">
                <Header as="h3" data-testid="creditViewHeader">
                  Available Vouchers in {credit.currency_id?.toUpperCase()}
                </Header>
                <Header as="h2" data-testid="creditViewAmount">
                  {credit.amount} {credit.currency_id?.toUpperCase()}
                </Header>
              </Box>
              <Box
                data-testid="creditsInfoBox"
                position="absolute"
                width="100%"
                bottom="0"
                height="40px"
                backgroundColor="var(--gray-100)"
                lineheight="40px"
                padding="0 20px"
              >
                <Icon name="info circle" color="grey" />{" "}
                {credit.amount === 0 ? "No credits available for payments." : "This voucher amount will be adjusted against the invoice payment."}
              </Box>
            </Box>
          </Card>
        );
      })}
    </>
  );
};

export default VouchersView;
