//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchCreditBundles = () => {
  const { selectedOrganization } = useDashboardContext();
  const fetchCreditBundles = async () => {
    const request = {
      organization_id: selectedOrganization?.id || "",
      exclude_expired: false,
    };

    return await apiClients.creditBundleClient.ListCreditBundles(request);
  };
  return useQuery([selectedOrganization.id, "creditBundles"], fetchCreditBundles, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnMount: false,
  });
};
