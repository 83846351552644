//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Flex } from "@chakra-ui/react";
import React from "react";
import { DiskPerformanceRadioGroup } from "./DiskPerformanceRadioGroup";
import { ModelSelector } from "./ModelSelector";
import { NodeSizeSelectorTable } from "./NodeSizeSelectorTable";
import { NumberOfNodesInput } from "./NumberOfNodesInput";
import { SelectedModelMessage } from "./SelectedModelMessage";
import { TogglableSection } from "./TogglableSection";

export const SizingSection = ({ isDisabled }: { isDisabled?: boolean }) => {
  return (
    <TogglableSection title="Sizing">
      <Flex gap="4" direction="column">
        <Flex gap="4">
          <ModelSelector isDisabled={isDisabled} />
          <NumberOfNodesInput isDisabled={isDisabled} />
        </Flex>
        <SelectedModelMessage />
        <NodeSizeSelectorTable isDisabled={isDisabled} />
        <DiskPerformanceRadioGroup isDisabled={isDisabled} />
      </Flex>
    </TogglableSection>
  );
};
