//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import { Button, Label, Progress } from "semantic-ui-react";

interface IStyledDropzoneAreaProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
}

const getBorderColor = (props: IStyledDropzoneAreaProps) => {
  if (props.isDragAccept || props.isDragActive) {
    return "var(--gray-600)";
  }
  if (props.isDragReject) {
    return "var(--red-700)";
  }
  return "transparent";
};

const getBackgroundColor = (props: IStyledDropzoneAreaProps) => {
  if (props.isDragAccept || props.isDragActive) {
    return "var(--gray-200)";
  }
  if (props.isDragReject) {
    return "var(--red-100)";
  }
};

export const StyledDropzoneArea = styled.div`
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 999999;
  background-color: ${(props: IStyledDropzoneAreaProps) => getBackgroundColor(props)};
  border-color: ${(props: IStyledDropzoneAreaProps) => getBorderColor(props)};
  align-items: center;
  border-radius: 2px;
  border-width: 2px;
  border-style: dashed;
  outline: none;
  transition: border 0.2s ease-in-out;
`;

export const StyledFileDrop = styled.div`
  width: 100px;
  height: 56px;
  position: relative;
  transform: rotate(4deg);

  & i.file.icon {
    transform: rotate(-14deg);
    position: absolute;
    color: var(--green-600);
    z-index: 1;
    width: 40px;
    left: 10px;
  }

  & i.file.alternate.icon {
    transform: rotate(17deg);
    position: absolute;
    color: var(--green-500);
    width: 40px;
    left: 40px;
  }
`;

export const StyledFilesHolder = styled.div`
  & .file-icon {
    display: block !important;
    width: auto;
    height: auto !important;
    font-size: 2.5rem !important;
    margin: 0 !important;
    line-height: 1 !important;
    position: relative;
    color: var(--gray-600);
    top: 5px;
  }

  & .file-section {
    margin-left: 10px;
  }

  & .file-section .name {
    font-weight: 600;
    height: 22px;
    max-width: 220px;
    color: var(--gray-950);
  }

  & .file-section .size {
    font-size: 0.8rem;
    color: var(--gray-600);
    font-weight: 700;
  }

  & .file-size-info {
    font-size: 0.9rem;
    font-weight: normal;
  }

  & .file-actions {
    padding: 10px 20px 0 20px;
    font-size: 0.8rem;

    & .browse-file {
      font-weight: bold;
      border-bottom: 1px solid var(--green-600);
      display: inline;
    }
  }
`;

export const StyledFileItem = styled.div`
  background-color: var(--gray-50);
  margin: 6px;

  & .file-content {
    padding: 18px 20px;
    font-size: 0.8rem;

    & .file-data-header {
      max-width: 100px;
      font-weight: 700;
      text-transform: capitalise;
    }

    & .file-data-value {
      max-width: 100px;
    }
  }

  & .file-item {
    padding: 18px 20px;
    border-bottom: 1px solid var(--gray-200);
    background-color: white;

    & .file-item-icon {
      color: var(--gray-600);
    }
  }
`;
export const StyledWrongFileIcon = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 99999;
  & .close {
    position: absolute;
    left: 58%;
    border: 1px solid var(--red-500);
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 1.7 !important;
    border-radius: 0.2rem;
    bottom: -5px;
    background: white;
  }
`;

export const StyledDeleteActionButton = styled(Button)`
  margin: 0.25rem !important;
`;

export const StyledFileUploadArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const StyledErrorHolder = styled.div`
  position: fixed;
  padding: 20px;
  margin: 0 auto;
  z-index: 1000;
  width: 25vw;
  bottom: 24px;
`;

export const StyledActionMessage = styled.span`
  padding: 10px;
`;

export const StyledErrorLabel = styled(Label)`
  margin: 0.5rem 0rem !important;

  &:last-child {
    margin-bottom: 1rem !important;
  }
`;

export const StyledProgress = styled(Progress)`
  margin-top: 5px !important;
  margin-bottom: 0.6rem !important;
`;
