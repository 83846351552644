//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button } from "semantic-ui-react";
import { METRICS_VALUES, SERVERS_VALUES, SINGLE_SERVER } from "./useMetricsUsageApi";
import { METRICS, SERVERS, StyledButtonGroup } from "./MetricsView";

const MetricsSelector = ({
  loading,
  selectedServer,
  selectedMetric,
  setSelectedMetric,
}: {
  loading: boolean;
  selectedServer: SERVERS_VALUES | typeof SINGLE_SERVER;
  selectedMetric: METRICS_VALUES;
  setSelectedMetric: React.Dispatch<React.SetStateAction<METRICS_VALUES>>;
}) => (
  <StyledButtonGroup>
    <Button
      disabled={loading}
      loading={loading && selectedMetric === METRICS.CPU}
      onClick={() => setSelectedMetric(METRICS.CPU)}
      className={selectedMetric === METRICS.CPU ? "active" : ""}
    >
      CPU
    </Button>

    <Button
      disabled={loading}
      loading={loading && selectedMetric === METRICS.MEMORY}
      onClick={() => setSelectedMetric(METRICS.MEMORY)}
      className={selectedMetric === METRICS.MEMORY ? "active" : ""}
    >
      Memory
    </Button>

    {selectedServer !== SERVERS.COORDINATOR && (
      <Button
        disabled={loading}
        loading={loading && selectedMetric === METRICS.DISK}
        onClick={() => setSelectedMetric(METRICS.DISK)}
        className={selectedMetric === METRICS.DISK ? "active" : ""}
      >
        Disk
      </Button>
    )}
  </StyledButtonGroup>
);

export default MetricsSelector;
