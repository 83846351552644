//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Card, Loader, Segment, Tab, TabProps } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ImportDataInstructions as ApiImportDataInstructions } from "../../api/lib";
import { LoaderBox } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import ArangoimportInstructions from "./ArangoimportInstructionsView";
import ArangorestoreInstructions from "./ArangorestoreInstructionsView";
import { Box } from "../../ui/_box";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { DownloadClientToolsView } from "./DownloadClientToolsView";

export enum ArangoimportTabPage {
  ArangoImportJSON = 0,
  ArangoImportCSV = 1,
  ArangoImportTSV = 2,
}

interface IImportDataInstructionsProps {
  disabled?: boolean;
  tabPage?: ArangoimportTabPage;
  isPrivateEndpoint?: boolean;
  loading: IWithRefreshProps["loading"];
  refreshNow: IWithRefreshProps["refreshNow"];
  eventSubscriptionManager: IWithRefreshProps["eventSubscriptionManager"];
  permissionCache: IWithRefreshProps["permissionCache"];
}

interface IImportDataInstructionsState {
  modalOpen: boolean;
  activeTabIndex: number;
  instructions?: ApiImportDataInstructions;
  showDownloadClientToolsModal: boolean;
}

class ImportDataInstructions extends Component<IImportDataInstructionsProps, IImportDataInstructionsState> {
  state = {
    caCertificate: undefined,
    modalOpen: false,
    activeTabIndex: (this.props.tabPage as number) || 0,
    showDownloadClientToolsModal: false,
  } as IImportDataInstructionsState;

  reloadInstructions = async (deploymentId: string) => {
    const idOptions = { id: deploymentId } as ApiIDOptions;
    const instructions = await apiClients.dataClient.GetImportDataInstructions(idOptions);
    this.setState({
      instructions: instructions,
    });
  };

  componentDidMount() {
    const { id: deploymentId } = useDeploymentStore.getState().deployment;
    if (deploymentId) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadInstructions(deploymentId));
    }
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  onTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    this.setState({ activeTabIndex: data.activeIndex as number });
  };

  onOpenDownloadClientToolsModal = () => {
    this.setState({ showDownloadClientToolsModal: true });
  };
  onCloseDownloadClientToolsModal = () => {
    this.setState({ showDownloadClientToolsModal: false });
  };

  render() {
    const { id: deploymentId } = useDeploymentStore.getState().deployment;

    if (!deploymentId) {
      return <div />;
    }

    if (this.props.loading) {
      return (
        <LoaderBox>
          <Loader size="massive" active />
        </LoaderBox>
      );
    }

    const panes = [
      {
        menuItem: "From existing ArangoDB instance",
        render: () => <ArangorestoreInstructions instructions={this.state.instructions} onClickClientTools={this.onOpenDownloadClientToolsModal} />,
      },
      {
        menuItem: "JSON documents",
        render: () => (
          <ArangoimportInstructions
            {...this.props}
            deploymentId={deploymentId}
            instructions={this.state.instructions}
            view={ArangoimportTabPage.ArangoImportJSON}
            onClickClientTools={this.onOpenDownloadClientToolsModal}
          />
        ),
      },
      {
        menuItem: "CSV document",
        render: () => (
          <ArangoimportInstructions
            {...this.props}
            deploymentId={deploymentId}
            instructions={this.state.instructions}
            view={ArangoimportTabPage.ArangoImportCSV}
            onClickClientTools={this.onOpenDownloadClientToolsModal}
          />
        ),
      },
      {
        menuItem: "TSV document",
        render: () => (
          <ArangoimportInstructions
            {...this.props}
            deploymentId={deploymentId}
            instructions={this.state.instructions}
            view={ArangoimportTabPage.ArangoImportTSV}
            onClickClientTools={this.onOpenDownloadClientToolsModal}
          />
        ),
      },
    ];

    return (
      <Box>
        <DownloadClientToolsView showModal={this.state.showDownloadClientToolsModal} onClose={this.onCloseDownloadClientToolsModal} />
        <Segment style={{ overflow: "auto", maxHeight: "450px" }}>
          <Card.Content>
            <Card.Description>
              <Tab
                activeIndex={this.state.activeTabIndex}
                onTabChange={this.onTabChange}
                menu={{ secondary: true, pointing: true, stackable: true }}
                panes={panes}
              />
            </Card.Description>
          </Card.Content>
        </Segment>
      </Box>
    );
  }
}

export default withRefresh()(ImportDataInstructions);
