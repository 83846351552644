//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { DropdownProps, Form, Grid } from "semantic-ui-react";
import { useGraphEdgeHandlers } from "../../graph-modeller/GraphProvider";
import { NodeMappings } from "../../types";
import { useFileAndEdgeModifiers } from "../hooks/useFileAndEdgeModifiers";
import { useDataloaderStore } from "../../DataloaderStore";

export const EdgeTargetKeySelector = () => {
  const { edgeMappings } = useDataloaderStore().getDeploymentDataloaderState();
  const { getEdge } = useGraphEdgeHandlers();
  const { assignTargetPrimaryKeyInGlobalStore, getCurrentTargetNodeFromGlobalStore, getAssignedSourceTargetKeyFromGlobalStore } = useFileAndEdgeModifiers();

  const [targetNodeMap, setTargetNodeMap] = useState<NodeMappings | undefined>(getCurrentTargetNodeFromGlobalStore());
  const [targetFileID, setTargetFileID] = useState(getAssignedSourceTargetKeyFromGlobalStore());

  const { attachedFile, primaryKey } = targetNodeMap || {};
  const { name: targetFileName } = attachedFile || {};
  const edge = getEdge();

  const getTargetFieldOptions = () => {
    const correspondingEdge = edgeMappings[edge?.id || ""];
    if (isEmpty(correspondingEdge)) return [];

    const { attachedFile } = correspondingEdge;
    const { fields } = attachedFile;

    return Object.keys(fields).map((field) => ({
      key: field,
      text: field,
      value: field,
    }));
  };

  useEffect(() => {
    if (edge && !isEmpty(edge)) {
      setTargetNodeMap(getCurrentTargetNodeFromGlobalStore());
      setTargetFileID(getAssignedSourceTargetKeyFromGlobalStore() || "");
    }
  }, [edge]);

  return (
    <Grid columns={16}>
      <Grid.Column width={16}>
        <Form.Field>
          <p className="para">
            For destination file <b>{targetFileName}</b> with primary key <b className="mask-data">{primaryKey}</b>, select:
          </p>
        </Form.Field>
      </Grid.Column>
      <Grid.Column width={16}>
        <Form.Field>
          <label htmlFor="sourceIDSelector">Corresponding relation file header</label>
          <Form.Dropdown
            selection
            fluid
            placeholder="Select a column"
            name="sourceIDSelector"
            search
            className="mask-data"
            value={targetFileID}
            noResultsMessage="No columns found"
            options={getTargetFieldOptions() || []}
            onChange={(_: unknown, data: DropdownProps) => {
              const { value } = data;
              assignTargetPrimaryKeyInGlobalStore(String(value));
              setTargetFileID(String(value));
            }}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>
  );
};
