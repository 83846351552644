//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import moment from "moment";
import React, { Component } from "react";
import { Form, Grid, Header, List, Modal } from "semantic-ui-react";
import _ from "lodash";
import { FormActionButtonSave } from "./_buttons";
import parse from "rte-moment";
import styled from "@emotion/styled";

const StyledListItem = styled(List.Item)`
  border-bottom: 2px solid transparent;
  padding: 8px;
  &:hover {
    border-bottom: 2px solid var(--green-600);
    cursor: pointer;
  }
`;

interface IRelativeTime {
  title: string;
  from: string;
  to: string;
}

const relativeTimes = [
  { title: "Last 5 minutes", from: "now-5m", to: "now" },
  { title: "Last 15 minutes", from: "now-15m", to: "now" },
  { title: "Last 30 minutes", from: "now-30m", to: "now" },
  { title: "Last 1 hour", from: "now-1h", to: "now" },
  { title: "Last 3 hours", from: "now-3h", to: "now" },
  { title: "Last 6 hours", from: "now-6h", to: "now" },
  { title: "Last 12 hours", from: "now-12h", to: "now" },
  { title: "Last 1 day", from: "now-1d", to: "now" },
  { title: "Last 2 days", from: "now-2d", to: "now" },
  { title: "Last 7 days", from: "now-7d", to: "now" },
] as IRelativeTime[];

export const DateFormat = "YYYY-MM-DD hh:mm:ss";

// ParseDate parse value that can be either an explicit date or a relative date.
// Returns: Date or exception
export const ParseDate = (value: string) => {
  // Try relative date
  try {
    const mrel = parse(value);
    if (mrel.isValid()) {
      return mrel.toDate();
    }
  } catch (e) {
    // Ignore
  }
  // Try absolute date
  try {
    const mabs = moment(value, DateFormat);
    if (mabs.isValid()) {
      return mabs.toDate();
    }
  } catch (e) {
    // Ignore
  }
  throw Error(`'${value}' is not a valid date & time`);
};

const validateTS = (value: string) => {
  try {
    ParseDate(value);
    return undefined;
  } catch (e) {
    // Skip
  }
  return `Invalid value`;
};

const validateRange = (from: string, to: string) => {
  try {
    const fd = ParseDate(from);
    const td = ParseDate(to);
    if (moment(fd).isSameOrAfter(td)) {
      return "To must be after From";
    }
    return undefined;
  } catch (e) {
    // Skip
  }
  return `Invalid value`;
};

interface IRelativeTimeArgs extends IRelativeTime {
  onChange: (from: string, to: string) => void;
  onApply: () => void;
}

const RelativeTime = ({ ...args }: IRelativeTimeArgs) => (
  <StyledListItem
    onClick={() => {
      args.onChange(args.from, args.to);
      args.onApply();
    }}
  >
    {args.title}
  </StyledListItem>
);

interface IDateTimeViewArgs {
  from: string;
  to: string;
}

const DateTimeView = ({ ...args }: IDateTimeViewArgs) => {
  // Find matching relative time
  const relTime = relativeTimes.find((x) => x.from == args.from && x.to == args.to);
  if (!!relTime) {
    return <span>{relTime.title}</span>;
  }
  return (
    <span>
      {args.from} &hellip; {args.to}
    </span>
  );
};

interface IDateTimeInputViewArgs {
  from: string;
  to: string;
  edit_from: string;
  edit_to: string;
  editing: boolean;
  onEdit: () => void;
  onCloseEdit: () => void;
  onChangeFrom: (value: string) => void;
  onChangeTo: (value: string) => void;
  onChange: (from: string, to: string) => void;
  onApply: () => void;
}

const DateTimeInputView = ({ ...args }: IDateTimeInputViewArgs) => {
  const fromError = args.editing && validateTS(args.edit_from);
  let toError = args.editing && validateTS(args.edit_to);
  if (!toError && !fromError && args.editing) {
    toError = validateRange(args.edit_from, args.edit_to);
  }
  return (
    <Form.Field>
      <Modal open={args.editing} onClose={args.onCloseEdit}>
        <Modal.Header>Select date/time range</Modal.Header>
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <Header>Absolute time range</Header>
                  <Form.Input name="from" label="From" value={args.edit_from} error={fromError} onChange={(e, d) => args.onChangeFrom(d.value)} />
                  <Form.Input name="to" label="To" value={args.edit_to} error={toError} onChange={(e, d) => args.onChangeTo(d.value)} />
                </Grid.Column>
                <Grid.Column>
                  <Header>Relative time range</Header>
                  <List>
                    {relativeTimes.map((x, i) => (
                      <RelativeTime key={`rt${i}`} {...args} {...x} />
                    ))}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonSave title="Apply" onClick={args.onApply} />
        </Modal.Actions>
      </Modal>
      <span onClick={args.onEdit}>
        <DateTimeView {...args} />
      </span>
    </Form.Field>
  );
};

interface IDateTimeInputProps {
  from?: string;
  to?: string;
  onChange: (from: string, to: string) => void;
}

interface IDateTimeInputState {
  from: string;
  to: string;
  edit_from: string;
  edit_to: string;
  prev_from?: string;
  prev_to?: string;
  errorMessage?: string;
  editing: boolean;
}

export class DateTimeInput extends Component<IDateTimeInputProps, IDateTimeInputState> {
  state = {
    from: "now-1h",
    to: "now",
    errorMessage: undefined,
    editing: false,
  } as IDateTimeInputState;

  static getDerivedStateFromProps(props: IDateTimeInputProps, state: IDateTimeInputState) {
    if (!_.isEqual(props.from, state.prev_from) || !_.isEqual(props.to, state.prev_to)) {
      return {
        from: props.from || "now-1h",
        to: props.to || "now",
        prev_from: props.from,
        prev_to: props.to,
      };
    }
    return {};
  }

  onEdit = () => {
    this.setState((old) => {
      return {
        editing: true,
        edit_from: old.from,
        edit_to: old.to,
      };
    });
  };
  onCloseEdit = () => {
    this.setState({ editing: false });
  };

  onChangeFrom = (value: string) => {
    this.setState({ edit_from: value });
  };
  onChangeTo = (value: string) => {
    this.setState({ edit_to: value });
  };
  onChange = (from: string, to: string) => {
    this.setState({
      edit_from: from,
      edit_to: to,
    });
  };

  onApply = () => {
    this.setState(
      (old) => {
        return {
          from: old.edit_from,
          to: old.edit_to,
          editing: false,
        };
      },
      () => {
        this.props.onChange(this.state.from, this.state.to);
      }
    );
  };

  render() {
    return <DateTimeInputView {...this.state} {...this} />;
  }
}
