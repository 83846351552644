import { useFeature } from "flagged";
import React from "react";
import { OrganizationList } from "../../api/lib";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { OrganizationSelector } from "../OrganizationSelector";
import { SidebarHeading } from "./SidebarHeading";
import { SidebarLink } from "./SidebarLink";
import { SidebarRightWrap } from "./SidebarRightWrap";
import { useCheckPermissionByUrl } from "./useCheckPermissionByUrl";
import { Box } from "../../ui/_box";

export const OrganizationSidebar = ({
  onClickSupportRequest,
  organizations,
  onOpenInternalDashboard,
  orgId,
}: {
  onClickSupportRequest: () => void;
  organizations: OrganizationList | undefined;
  onOpenInternalDashboard: () => void;
  orgId: string;
}) => {
  const { selectedOrganization, debugMode, isFreeTier } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const hasRolesPermission = checkOrgPermission("iam.role.list");
  const hasPolicyPermission = checkOrgPermission("iam.policy.get");
  const hasAuditLogPermission = checkOrgPermission("audit.auditlog.list");
  const hasEventLogPermission = checkOrgPermission("resourcemanager.event.list");
  const hasAuditLogFeature = selectedOrganization?.tier?.has_auditlog_feature;
  const hasCreditsViewPermission = checkOrgPermission("credit.creditbundle.list");

  const isAuditLogEnabled = useFeature("auditlog");
  const showBillingLink = checkOrgPermission("billing.config.set");

  return (
    <SidebarRightWrap onClickSupportRequest={onClickSupportRequest} organizationsList={organizations} onOpenInternalDashboard={onOpenInternalDashboard}>
      <OrganizationSelector />
      <Box display="flex" direction="column" overflow="auto">
        <SidebarHeading>Organization</SidebarHeading>
        <SidebarLink to={{ pathname: Routes.dashboard_organization_overviewWithId(orgId) }} icon="map signs">
          Overview
        </SidebarLink>
        <SidebarLink
          show={isFreeTier ? showBillingLink : true}
          to={{ pathname: Routes.dashboard_organization_billingWithId(orgId) }}
          icon="credit card outline"
        >
          {isFreeTier ? "Unlock All Features" : "Billing"}
        </SidebarLink>
        <SidebarLink
          show={hasCreditsViewPermission}
          to={{
            pathname: Routes.dashboard_organization_creditsWithId(orgId),
          }}
          icon="credit card alternative"
        >
          Credits & Usage
        </SidebarLink>
        <SidebarHeading>People</SidebarHeading>
        <SidebarLink to={{ pathname: Routes.dashboard_organization_membersWithId(orgId) }} icon="user">
          Members
        </SidebarLink>
        <SidebarLink to={{ pathname: Routes.dashboard_organization_groupsWithId(orgId) }} icon="group">
          Groups
        </SidebarLink>
        <SidebarLink to={{ pathname: Routes.dashboard_organization_organizationInvitesWithId(orgId) }} icon="user plus">
          Invites
        </SidebarLink>
        <SidebarHeading>Access Control</SidebarHeading>
        <SidebarLink show={hasRolesPermission} to={{ pathname: Routes.dashboard_organization_rolesWithId(orgId) }} icon="tag">
          Roles
        </SidebarLink>

        <SidebarLink show={hasPolicyPermission} to={{ pathname: Routes.dashboard_organization_policyWithId(orgId) }} icon="file alternate outline">
          Policy
        </SidebarLink>
        <SidebarLink
          show={hasAuditLogPermission && hasAuditLogFeature && !!isAuditLogEnabled}
          to={{ pathname: Routes.dashboard_organization_auditlogsWithId(orgId) }}
          icon="clipboard list"
        >
          Audit Logs
        </SidebarLink>
        {/* todo: work on event logs */}
        <SidebarLink show={debugMode && hasEventLogPermission} to={{ pathname: Routes.dashboard_organization_eventsWithId(orgId) }} icon="bell">
          Event Logs
        </SidebarLink>
        <SidebarHeading>Delete/Lock</SidebarHeading>

        <SidebarLink
          to={{
            pathname: Routes.dashboard_organization_dangerzoneWithId(orgId),
          }}
          icon="trash"
          iconColor="red"
        >
          Delete/Lock
        </SidebarLink>
      </Box>
    </SidebarRightWrap>
  );
};
