//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { DeploymentMetrics_Timeseries } from "../../../api/lib";
import { uniq, flatMap } from "lodash";
import styled from "@emotion/styled";
import { Checkbox, CheckboxProps, Divider, Form, Icon, Input, InputOnChangeData, Message } from "semantic-ui-react";
import { FlexBox } from "../../../ui/_flex";
import { RenderGuard } from "../../../util/RenderGuard";

const StyledCheckbox = styled(Checkbox)`
  width: max-content;
  margin-bottom: 16px;
`;

const StyledBoldCheckBox = styled(Checkbox)`
  font-weight: bold;
`;

const StyledForm = styled(Form)`
  margin-bottom: 16px;
`;

const MAX_INITIAL_SERVER_IDS = 8;

const ServerIdsSelector = ({
  chartData,
  selectedServerIds,
  setSelectedServerIds,
}: {
  chartData: DeploymentMetrics_Timeseries[];
  selectedServerIds: string[];
  setSelectedServerIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const getServerIds = (inputData: DeploymentMetrics_Timeseries[]): string[] => uniq(flatMap(inputData, "server_id"));

  const serverIds = getServerIds(chartData);
  const initialServerIds = serverIds.slice(0, MAX_INITIAL_SERVER_IDS);

  const [searchString, setSearchString] = useState<string>("");

  const filteredServerIds = searchString === "" ? serverIds : serverIds.filter((id) => id.toLowerCase().includes(searchString.toLowerCase()));

  const handleSelectAllServerIds = () => setSelectedServerIds(serverIds);
  const handleDeselectAllServerIds = () => setSelectedServerIds([]);

  const handleSelectServerId = (checked: boolean, id: string) => {
    const newServerIds = checked ? [...selectedServerIds, id] : selectedServerIds.filter((serverId) => serverId !== id);

    setSelectedServerIds(newServerIds);
  };

  useEffect(() => {
    setSelectedServerIds(initialServerIds);
  }, [chartData]);

  if (!chartData.length)
    return (
      <FlexBox direction="column" overflowY="auto" width="250px" margin="14px 0 0 0">
        <Message info>No servers available</Message>
      </FlexBox>
    );

  return (
    <>
      <StyledForm>
        <FlexBox margin="16px 0 0 0">
          <Form.Field>
            <label>Server id</label>
            <Input
              icon
              value={searchString}
              placeholder="Search a server id"
              autoFocus
              onChange={(_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setSearchString(data.value)}
            >
              <Icon name="remove circle" onClick={() => setSearchString("")} link />
              <input style={{ width: "220px" }} />
            </Input>
          </Form.Field>
        </FlexBox>
      </StyledForm>

      <RenderGuard renderIf={searchString === ""}>
        <StyledBoldCheckBox
          label={selectedServerIds.length !== serverIds.length ? "Select all" : "Deselect all"}
          checked={selectedServerIds.length === serverIds.length}
          onChange={(_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => (data.checked ? handleSelectAllServerIds() : handleDeselectAllServerIds())}
        />

        <Divider />
      </RenderGuard>

      <FlexBox direction="column" overflowY="auto" height="230px" width="auto" margin={searchString === "" ? "0 0 16px 0" : "0 0 63px 0"} overflowX="hidden">
        {filteredServerIds
          .map((id) => (
            <StyledCheckbox
              key={id}
              label={id}
              checked={selectedServerIds.includes(id)}
              onChange={(_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => handleSelectServerId(!!data.checked, id)}
            />
          ))
          .sort()}
        {!filteredServerIds.length && <span>Server id not found</span>}
      </FlexBox>
    </>
  );
};

export default ServerIdsSelector;
