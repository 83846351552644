//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { useLocation } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Organization as ApiOrganization, OrganizationList as ApiOrganizationList } from "../api/resourcemanager/v1/resourcemanager";
import { Routes } from "../routes";
import { PersistentState } from "../util/PersistentState";
import { useGlobalStore } from "../util/storage/GobalStore";
import { useDashboardContext } from "./DashboardContextProvider";

interface IOrganizationSelectorViewArgs {
  organizations?: ApiOrganizationList;
  selectedOrganization?: ApiOrganization;
  onOrganizationSelected: (organizationId: string) => void;
}
const StyledDropdown = styled(Dropdown)`
  &&& {
    padding: 8px 16px;
    color: var(--gray-400);
    display: flex;
  }
  i {
    color: var(--gray-400);
  }
  .menu {
    z-index: 111;
  }
`;

const OrganizationSelectorView = ({ ...args }: IOrganizationSelectorViewArgs) => (
  <Dropdown.Menu>
    {args.organizations &&
      args.organizations.items &&
      args.organizations.items.map((org) => {
        return [
          <Dropdown.Item
            id={org.id}
            key={org.id}
            active={args.selectedOrganization && args.selectedOrganization.id == org.id}
            onClick={(e, d) => {
              args.onOrganizationSelected(org.id || "");
            }}
          >
            {org.name}
          </Dropdown.Item>,
        ];
      })}
  </Dropdown.Menu>
);

export interface IOrganizationSelectorArgs {
  organizations?: ApiOrganizationList;
  selectedOrganization?: ApiOrganization;
  onOrganizationSelected: (organizationId: string) => void;
}

//Note: the orgDropdownId is used in '..\index.css' to disable the :hover functionality
export const OrganizationSelector = () => {
  const { organizations, selectedOrganization } = useDashboardContext();
  const { setOrganization } = useGlobalStore();
  const location = useLocation();
  const onOrganizationSelected = (organizationId: string) => {
    if (organizations) {
      const org = organizations.items?.find((org) => org.id === organizationId);
      if (org) {
        PersistentState.saveSelectedOrganizationId(organizationId);
        setOrganization(org);
        // reload if already on /dashboard,
        // otherwise navigate to /dashboard
        if (location.pathname === Routes.dashboard) {
          window.location.reload();
        } else {
          window.location.href = Routes.dashboard;
        }
      }
    }
  };
  return (
    <StyledDropdown
      simple
      loading={!organizations}
      closeOnChange
      id="orgDropdownId"
      icon="sort"
      text={selectedOrganization ? selectedOrganization.name : undefined}
    >
      <OrganizationSelectorView onOrganizationSelected={onOrganizationSelected} organizations={organizations} selectedOrganization={selectedOrganization} />
    </StyledDropdown>
  );
};
