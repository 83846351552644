//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { DropdownProps, Dropdown } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import {
  ListRegionsRequest as ApiListRegionsRequest,
  Region as ApiRegion,
  Provider as ApiProvider,
  ListProvidersRequest as ApiListProvidersRequest,
} from "../../api/platform/v1/platform";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import styled from "@emotion/styled";
import { useFeature } from "flagged";

import awsLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/aws-logo.svg";
import azureLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/microsoft-azure-logo.svg";
import gcpLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/google-cloud-logo.svg";
import { ModelFree, ModelSingleServer } from "../../constants";

const StyledDropdown = styled(Dropdown)`
  .image {
    width: 32px !important;
    height: 32px !important;
  }
`;

const logoByProvider = {
  aws: awsLogo,
  aks: azureLogo,
  gcp: gcpLogo,
};

interface ISelectAnyRegionViewArgs extends ISelectAnyRegionState {
  inline?: boolean;
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

export const createRegionName = (r: ApiRegion) => {
  let regionName = r.location;

  if (r.prerelease) {
    regionName = `${regionName} (Preview)`;
  }

  return regionName;
};

export const createRegionImage = (r: ApiRegion, providers: ApiProvider[]) => {
  const provider = _.find(providers, (x) => x.id == r.provider_id) || {};
  return (logoByProvider as any)[provider.id || ""];
};

const SelectAnyRegionView = ({ ...args }: ISelectAnyRegionViewArgs) => {
  const has_regions = !_.isEmpty(args.regions);
  const providers = args.providers || [];
  const isMLServicesAvailable = useFeature("MLServices");

  return args.regions ? (
    <StyledDropdown
      placeholder="<Select a region>"
      name="region"
      inline={args.inline}
      value={args.selectedRegion && args.selectedRegion.location}
      defaultValue={args.selectedRegion && args.selectedRegion.location}
      onChange={args.updateFormDropdownChange}
      options={args.regions.map((r) => {
        return {
          key: r.id,
          text: r.ml_supported && isMLServicesAvailable ? `${createRegionName(r)} (ML Compatible)` : createRegionName(r),
          image: { src: createRegionImage(r, providers) },
          value: r.location,
          className: !!r.out_of_stock ? "red-text" : "",
          description: !!r.out_of_stock ? "Out of stock" : !!r.low_stock ? "Low on stock" : "",
        };
      })}
    />
  ) : (
    <StyledDropdown inline={args.inline} placeholder="<Select a region>" name="region" loading={!has_regions} disabled={!has_regions} />
  );
};

// Interface decribing the properties of the SelectRegion component
interface ISelectAnyRegionProps extends IWithRefreshProps {
  organizationId: string;
  region?: ApiRegion;
  defaultRegionId?: string;
  select_first?: boolean;
  inline?: boolean;
  isFreeDeployment: boolean | undefined;
  isPerpetualFreeTrialAvailable?: boolean;
  onRegionUpdated: (region?: ApiRegion, provider?: ApiProvider) => void;
}

// Interface decribing the state of the SelectRegion component
interface ISelectAnyRegionState {
  providers?: ApiProvider[];
  regions?: ApiRegion[];
  selectedRegion?: ApiRegion;
}

// Component to select a region of any provider
class SelectAnyRegion extends Component<ISelectAnyRegionProps, ISelectAnyRegionState> {
  state = {
    regions: undefined,
    selectedRegion: undefined,
  } as ISelectAnyRegionState;

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "region":
        const regions = this.state.regions;
        const providers = this.state.providers || [];
        const selectedRegion = regions && regions.find((r) => r.location == args.value);
        const selectedProvider = !!selectedRegion ? providers.find((x) => x.id == selectedRegion.provider_id) : undefined;
        this.setState({ selectedRegion: selectedRegion });
        this.props.onRegionUpdated(selectedRegion, selectedProvider);
        break;
    }
  };

  refreshRegions = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadRegions);
  };

  reloadRegions = async () => {
    this.setState({
      providers: undefined,
      regions: undefined,
      selectedRegion: undefined,
    });

    // First load all providers
    const listReq = { organization_id: this.props.organizationId } as ApiListProvidersRequest;
    const providersList = await apiClients.platformClient.ListProviders(listReq);
    const providers = providersList.items || [];
    this.setState({ providers: providers });

    // Now list all regions
    const allRegionsPromise = providers.map((x) => this.loadRegionsForProvider(x));
    const allRegions = _.flatten(await Promise.all(allRegionsPromise));

    // Update state
    this.setState(
      (old) => {
        const update = { regions: allRegions } as ISelectAnyRegionState;
        if (!old.selectedRegion && !!this.props.defaultRegionId) {
          update.selectedRegion = _.find(allRegions, (x) => x.id == this.props.defaultRegionId);
        } else if (!old.selectedRegion && !!this.props.select_first) {
          update.selectedRegion = _.first(allRegions);
        }
        return update;
      },
      () => {
        if (!!this.props.defaultRegionId || !!this.props.select_first) {
          this.props.onRegionUpdated(this.state.selectedRegion);
        }
      }
    );
  };

  loadRegionsForProvider = async (provider: ApiProvider) => {
    let listReq = {
      provider_id: provider.id,
      organization_id: this.props.organizationId,
    } as ApiListRegionsRequest;

    if (this.props.isPerpetualFreeTrialAvailable) {
      listReq = {
        ...listReq,
        model_id: this.props.isFreeDeployment && this.props.isPerpetualFreeTrialAvailable ? ModelFree : ModelSingleServer,
      };
    }

    const regions = await apiClients.platformClient.ListRegions(listReq);
    // Filter out unavailable regions
    const availableRegions = regions && regions.items ? regions.items.filter((x) => x.available) : undefined;
    return _.orderBy(availableRegions || [], "location");
  };

  componentDidMount() {
    this.refreshRegions();
  }

  render() {
    return <SelectAnyRegionView {...this.props} {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectAnyRegion);
