//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import _ from "lodash";

interface IValueArgs {
  value?: any;
  pre?: boolean;
}

export const Value = ({ ...args }: IValueArgs) => {
  const value = !_.isNumber(args.value) && _.isEmpty(args.value) ? "-" : args.value;
  if (!!args.pre)
    return (
      <div>
        <pre>{value}</pre>
      </div>
    );
  return <div>{value}</div>;
};
