//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Form, Input, InputOnChangeData, Popup } from "semantic-ui-react";
import { useGraphNodeHandlers } from "../../graph-modeller/GraphProvider";
import { useDataloaderStore } from "../../DataloaderStore";
import { getCollectionNameErrors } from "../../utils/namesValidations.utils";
import { StyledForm } from "../../graph-modeller/Styles";
import { v4 } from "uuid";

export const NodeLabel = () => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const { getNode, updateNode } = useGraphNodeHandlers();
  const { nodeMappings } = useDataloaderStore().getDeploymentDataloaderState();
  const node = getNode() || {};

  const [label, setLabel] = useState(node.label || "");
  const [labelErrors, setLabelErrors] = useState<string[]>(getCollectionNameErrors(node.label || ""));

  const hasLabelError = label !== "" && !!labelErrors.length;

  useEffect(() => {
    setLabel(node.label || "");
  }, [node]);

  return (
    <StyledForm error={hasLabelError}>
      <Form.Field control={Input} label="Node label" error={hasLabelError}>
        <Popup
          open={hasLabelError && isInputFocused}
          position="bottom right"
          trigger={
            <Input
              data-testid="node-label-input"
              fluid
              placeholder="Enter a label for the selected Node"
              value={label}
              autoFocus
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                const { value } = data;
                setLabel(value);
                setLabelErrors(getCollectionNameErrors(value));
                updateNode(
                  {
                    ...node,
                    label: value,
                  },
                  nodeMappings
                );
              }}
              error={hasLabelError}
              icon={hasLabelError ? "info circle" : undefined}
            />
          }
        >
          {labelErrors.map((nameError) => (
            <p key={v4()}>{nameError}</p>
          ))}
        </Popup>
      </Form.Field>
    </StyledForm>
  );
};
