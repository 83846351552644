//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component, useEffect } from "react";
import { Modal, Button, Form, InputOnChangeData } from "semantic-ui-react";

export class ConfirmInfo {
  public header?: string;
  public content?: string | React.ReactNode;
  public warning?: string | React.ReactNode;
  public confirm?: string;
  public invertPositiveNegative?: boolean;
  public onConfirm?: () => void;
  public onDenied?: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  disableConfirmationOnEnter?: boolean;
}

interface IConfirmViewArgs extends IConfirmProps, IConfirmState {
  updateConfirmString: (newValue: string) => void;
  clearConfirmString: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  allowConfirmationOnEnter?: boolean;
  loadingConfirmation?: boolean;
}

export const ConfirmView = ({ ...args }: IConfirmViewArgs) => {
  const { confirmInfo = {}, confirmEnabled } = args;
  const { disableConfirmationOnEnter, onConfirm } = confirmInfo;
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && confirmEnabled && !disableConfirmationOnEnter) {
        onConfirm && onConfirm();
      }
    };

    if (!disableConfirmationOnEnter && confirmEnabled) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [disableConfirmationOnEnter, confirmEnabled]);

  if (args.confirmInfo) {
    return (
      <Modal defaultOpen={true} closeOnEscape={false} closeOnDimmerClick={false} onUnmount={args.clearConfirmString}>
        <Modal.Header content={args.confirmInfo.header || "Please confirm"} />
        <Modal.Content>
          {args.confirmInfo.content || "Are you sure?"}
          {args.confirmInfo.warning && (
            <div>
              <br />
              <b>Notice: {args.confirmInfo.warning}</b>
            </div>
          )}
          {args.confirmInfo.confirm && (
            <div>
              <br />
              <Form.Input
                autoFocus
                autoComplete="off"
                required
                label={`Enter '${args.confirmInfo.confirm}' to confirm `}
                placeholder={args.confirmInfo.confirm}
                name="confirm"
                value={args.confirmString}
                onChange={(e: any, id: InputOnChangeData) => {
                  args.updateConfirmString(id.value);
                }}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={args.confirmInfo.onDenied}
            negative={!args.confirmInfo.invertPositiveNegative}
            positive={args.confirmInfo.invertPositiveNegative}
            content={args.confirmInfo.cancelButtonLabel || "No"}
          />
          <Button
            onClick={args.confirmInfo.onConfirm}
            loading={args.loadingConfirmation}
            disabled={!args.confirmEnabled}
            negative={args.confirmInfo.invertPositiveNegative}
            positive={!args.confirmInfo.invertPositiveNegative}
            labelPosition="right"
            icon="checkmark"
            content={args.confirmInfo.confirmButtonLabel || "Yes"}
          />
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

interface IConfirmProps {
  confirmInfo?: ConfirmInfo;
  loadingConfirmation?: boolean;
}

interface IConfirmState {
  confirmString?: string;
  confirmEnabled: boolean;
}

export class Confirm extends Component<IConfirmProps, IConfirmState> {
  state = {
    confirmEnabled: this.props.confirmInfo && !this.props.confirmInfo.confirm,
  } as IConfirmState;

  updateConfirmString = (newValue: string) => {
    let confirmEnabled = false;
    if (this.props.confirmInfo && this.props.confirmInfo.confirm) {
      confirmEnabled = this.props.confirmInfo.confirm == newValue;
    }
    this.setState({
      confirmString: newValue,
      confirmEnabled: confirmEnabled,
    });
  };

  clearConfirmString = () => {
    this.setState({ confirmString: undefined });
  };

  static getDerivedStateFromProps(props: IConfirmProps, state: IConfirmState) {
    const confirmInfo = props.confirmInfo || {};
    const confirmEnabled = !confirmInfo.confirm || confirmInfo.confirm == state.confirmString;
    if (confirmEnabled != state.confirmEnabled) {
      return {
        confirmEnabled: confirmEnabled,
      };
    }
    // No state update necessary
    return null;
  }

  render() {
    return <ConfirmView {...this.props} {...this.state} updateConfirmString={this.updateConfirmString} clearConfirmString={this.clearConfirmString} />;
  }
}
