//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDisableTopMenu } from "../../../components/useDisableTopMenu";
import { Routes } from "../../../routes";
import { useDashboardContext } from "../../DashboardContextProvider";
import { CreateRoleBindingSection } from "../../policy/CreateRoleBindingSection";
import { PolicyContextProvider } from "../../policy/PolicyContextProvider";
import { CreateOrganizationRoleBindingsTopMenu } from "./CreateOrganizationRoleBindingsTopMenu";

export const CreateOrganizationRoleBindings = () => {
  useDisableTopMenu();
  const history = useHistory();
  const { selectedOrganization } = useDashboardContext();
  return (
    <PolicyContextProvider resourceUrl={selectedOrganization.url}>
      <Grid templateRows={"auto minmax(0, 1fr)"} overflow="hidden" height="100vh">
        <CreateOrganizationRoleBindingsTopMenu />
        <CreateRoleBindingSection
          onSuccess={() => history.push(Routes.dashboard_organization_policyWithId(selectedOrganization.id || ""))}
          onCancel={() => history.push(Routes.dashboard_organization_policyWithId(selectedOrganization.id || ""))}
        />
      </Grid>
    </PolicyContextProvider>
  );
};
