import * as React from "react";
import { IWithRefreshProps, withRefresh } from "./WithRefresh";

const WithRefreshContext = React.createContext<IWithRefreshProps>({} as IWithRefreshProps);

export const WithRefreshContextProvider = withRefresh()((
  props: IWithRefreshProps & {
    children: React.ReactNode;
  }
) => {
  const { children, loading, eventSubscriptionManager, permissionCache, ...rest } = props;
  return (
    <WithRefreshContext.Provider
      value={{
        eventSubscriptionManager,
        permissionCache,
        loading,
        ...rest,
      }}
    >
      {children}
    </WithRefreshContext.Provider>
  );
});

export const useWithRefresh = () => React.useContext(WithRefreshContext);
