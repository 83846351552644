//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../../api/apiclients";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";

export const useFetchNotebooksList = () => {
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "" } = deployment;

  const fetchNotebooks = async () => {
    const request = {
      deployment_id: deploymentId,
    };
    const response = await apiClients.notebookClient.ListNotebooks(request);
    const { items, budget } = response;

    return { notebooks: items, budget };
  };

  return useQuery([deploymentId, "notebooks"], fetchNotebooks, {
    refetchInterval: 10 * 1000, // 10 seconds
    enabled: !!deploymentId,
  });
};
