//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import * as Sentry from "@sentry/react";
import { isUnauthorized } from "../api/lib";

// reportError sends error information to Sentry.
export const reportError = (error: any) => {
  try {
    if (isUnauthorized(error)) {
      // Do not capture unautorized errors
      return;
    }
    const id = Sentry.captureException(error);
    if (window.DEBUG) {
      console.log(`Captured exception '${error}' with id: ${id}`);
    }
  } catch {
    // Ignore
  }
};
