//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Websocket from "react-websocket";
import { EventSubscriptionManager } from "../util/EventSubscriptionManager";
import Onboarding from "./routes/Onboarding";
import { PermissionCache } from "../util/PermissionCache";
import Auth from "../auth/Auth";
import { ITracking } from "../tracking/api";

// Interface describing the properties for the Onboarding bootstrap view
interface IOnboardingBootstrapViewArgs extends RouteComponentProps {
  onClickLogout: () => void;
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
  onWebsocketRef: (x: any) => void;
  auth: Auth;
  tracking: ITracking;
}

const OnboardingBootstrapView = ({ ...args }: IOnboardingBootstrapViewArgs) => (
  <div>
    <Websocket url={args.eventSubscriptionManager.webSocketURL()} onMessage={args.eventSubscriptionManager.handleServerEvent} ref={args.onWebsocketRef} />
    <Onboarding {...args} loading={false} />
  </div>
);

// Interface decribing the properties of the Onboarding bootstrap component
interface IOnboardingBootstrapProps extends RouteComponentProps {
  onClickLogout: () => void;
  permissionCache: PermissionCache;
  auth: Auth;
  tracking: ITracking;
}

// Interface decribing the state of the Onboarding bootstrap component
interface IOnboardingBootstrapState {
  eventSubscriptionManager: EventSubscriptionManager;
}

class OnboardingBootstrap extends Component<IOnboardingBootstrapProps, IOnboardingBootstrapState> {
  state = {
    eventSubscriptionManager: new EventSubscriptionManager(this),
  } as IOnboardingBootstrapState;

  refWebSocket: any;

  getWebSocket = (): any => {
    return this.refWebSocket;
  };

  setWebSocket = (x: any): any => {
    this.refWebSocket = x;
  };

  componentWillUnmount() {
    this.state.eventSubscriptionManager.stopTimer();
    this.state.eventSubscriptionManager.closeWSConnection();
  }

  render() {
    return <OnboardingBootstrapView {...this.props} {...this.state} onWebsocketRef={this.setWebSocket} />;
  }
}

// Notice that here withRefresh() is not used, this is because the Onboarding bootstrap class creates the
// eventSubscriptionManager which is used as input property for all (child) components.
// This component is not using ReactTimeout, instead it's using the eventSubscriptionManager created here.
export default OnboardingBootstrap;
