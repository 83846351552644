//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Header } from "semantic-ui-react";
import { StyledTransparentSegment } from "../../../ui/_style";

const AWSAboutPrivateNetwork = () => {
  return (
    <>
      <Header data-testid="aboutPrivateNetworkHeader">What is AWS PrivateLink?</Header>
      <StyledTransparentSegment className="line-height-1-5" data-testid="aboutPrivateNetworkHeaderAnswer">
        AWS PrivateLink is a highly available, scalable technology that enables you to privately connect your VPC to services as if they were in your VPC. You
        do not need to use an internet gateway, NAT device, public IP address, AWS Direct Connect connection, or AWS Site-to-Site VPN connection to allow
        communication with the service from your private subnets. Therefore, you control the specific API endpoints, sites, and services that are reachable from
        your VPC.
      </StyledTransparentSegment>
      <Header data-testid="aboutPrivateNetworkHeaderSecondary">What is Amazon VPC?</Header>
      <StyledTransparentSegment className="line-height-1-5" data-testid="aboutPrivateNetworkHeaderSecondAnswer">
        Amazon Virtual Private Cloud (Amazon VPC) enables you to launch AWS resources into a virtual network that you've defined. This virtual network closely
        resembles a traditional network that you'd operate in your own data center, with the benefits of using the scalable infrastructure of AWS.
      </StyledTransparentSegment>
      <Header data-testid="aboutPrivateNetworkHeaderQ2">How to configure a Private Endpoint?</Header>
      <StyledTransparentSegment className="line-height-1-5" data-testid="aboutPrivateNetworkHeaderQ2Answer">
        <p className="line-height-1-5">To configure this, you will need to provide the AWS principals related to your VPC.</p>
        <p className="line-height-1-5">
          The ArangoGraph Insights Platform will configure a Private Endpoint Service that will automatically connect to Private Endpoints that are created in
          those principals.
        </p>
        <p className="line-height-1-5">
          After creation of the Private Endpoint Service, you will receive the Service name which you can use to connect to the Private Endpoint.
        </p>
      </StyledTransparentSegment>
    </>
  );
};

export default AWSAboutPrivateNetwork;
