//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useFileAndEdgeModifiers } from "../edit-panel/hooks/useFileAndEdgeModifiers";
import { useGraphEdgeHandlers, useGraphState } from "./GraphProvider";
import { EdgeManipulator } from "./Styles";

export const EdgeManipulatorMenu = () => {
  const { showEdgeManipulator, position } = useGraphState();

  const { deleteEdge } = useGraphEdgeHandlers();

  const { removeEdgeFileMappingsFromGlobalStore } = useFileAndEdgeModifiers();

  if (!showEdgeManipulator) return null;

  return (
    <EdgeManipulator {...position}>
      <div
        role="button"
        onClick={() => {
          deleteEdge();
          removeEdgeFileMappingsFromGlobalStore();
        }}
      >
        <Popup position="bottom center" trigger={<Icon name="trash" className="edge-action delete-edge" />} content="Delete this edge" basic />
      </div>
    </EdgeManipulator>
  );
};
