//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form, Grid, InputOnChangeData, Checkbox } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { Group as ApiGroup, GroupMembersRequest as ApiGroupMembersRequest, User as ApiUser } from "../../api/iam/v1/iam";
import { MemberList as ApiMemberList, Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";

const CreateMembersView = ({ ...args }: ICreateGroupViewArgs) =>
  args.users ? (
    <Form.Dropdown
      label="Members"
      placeholder="Select Members"
      name="members"
      multiple
      scrolling
      value={args.selectedMembers}
      onChange={args.updateFormDropdownChange}
      options={args.users.map((u) => {
        return { key: u.id, text: u.email, value: u.id };
      })}
    />
  ) : (
    <Form.Dropdown label="Members" placeholder="Select Members" name="permissions" loading={true} />
  );

interface ICreateGroupViewArgs extends ICreateGroupState, ICreateGroupProps {
  active: boolean;
  handleDismissError: () => void;
  setIsDefault: (value: boolean) => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
  createGroup: () => void;
}

const CreateGroupView = ({ ...args }: ICreateGroupViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Creating group, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <Section>
          <SectionHeader title="General" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <Form.Input
                    required
                    autoFocus
                    label="Name"
                    placeholder='Name (e.g. "administrators")'
                    name="name"
                    value={args.name}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    label="Short description"
                    placeholder="Description"
                    name="description"
                    value={args.description}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row columns={16}>
                <Checkbox
                  toggle
                  label={<label>Assign new users to this group</label>}
                  checked={args.is_default}
                  onChange={(e, v) => {
                    args.setIsDefault(!!v.checked);
                  }}
                />
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader title="Members" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <CreateMembersView {...args} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active} onClick={args.createGroup} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.history.goBack} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the CreateGroup component
interface ICreateGroupProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onNewGroupCreated: (groupId: string) => void;
}

// Interface decribing the state of the CreateGroup component
interface ICreateGroupState {
  errorMessage?: string;
  processing: boolean;

  name: string;
  description: string;
  is_default: boolean;

  organizationMembers?: ApiMemberList;
  users?: Array<ApiUser>;
  selectedMembers: string[];
}

// Component to create a group
class CreateGroup extends Component<ICreateGroupProps, ICreateGroupState> {
  state = {
    errorMessage: undefined,
    processing: false,

    name: "",
    description: "",
    is_default: false,

    organizationMembers: undefined,
    users: undefined,
    selectedMembers: [],
  } as ICreateGroupState;

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  setIsDefault = (value: boolean) => {
    this.setState({ is_default: value });
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "members":
        const selectedMembers = args.value as string[];
        this.setState({ selectedMembers: selectedMembers });
        break;
    }
  };

  createGroup = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const group = {
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        is_default: this.state.is_default,
      } as ApiGroup;
      const createdGroup = await apiClients.iamClient.CreateGroup(group);

      if (this.state.selectedMembers.length > 0) {
        const groupMembersRequest = {
          group_id: createdGroup.id,
          user_ids: this.state.selectedMembers,
        } as ApiGroupMembersRequest;
        await apiClients.iamClient.AddGroupMembers(groupMembersRequest);
      }

      this.props.onNewGroupCreated(createdGroup.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Group creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  reloadOrganizationMembers = async () => {
    const listOptions = { context_id: this.props.organization.id } as ApiListOptions;
    const organizationMembers = await apiClients.resourceManagerClient.ListOrganizationMembers(listOptions);
    this.setState({ organizationMembers: organizationMembers });

    const users = new Array<ApiUser>();

    if (organizationMembers.items) {
      for (let index = 0; index < organizationMembers.items.length; index++) {
        const member = organizationMembers.items[index];

        try {
          const idOptions = { id: member.user_id } as ApiIDOptions;
          const user = await apiClients.iamClient.GetUser(idOptions);
          users.push(user);
        } catch {
          //could not resolve, use id for now
          const user = { id: member.user_id, email: member.user_id } as ApiUser;
          users.push(user);
        }
      }
    }

    this.setState({ users: users });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationMembers);
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Groups", Routes.dashboard_organization_groupsWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("group");
    this.props.topMenuInfo.setTitles(this.state.name || "New group", "");
  };

  render() {
    return (
      <CreateGroupView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        updateFormDropdownChange={this.updateFormDropdownChange}
        createGroup={this.createGroup}
        setIsDefault={this.setIsDefault}
      />
    );
  }
}

export default withRefresh()(CreateGroup);
