//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Form, Header } from "semantic-ui-react";
import { EdgeLabel } from "./EdgeLabel";
import { EdgeFileSelector } from "./EdgeFileSelector";
import { EdgeFileMetadata } from "./EdgeFileMetadata";
import { EdgeSourceKeySelector } from "./EdgeSourceKeySelector";
import { EdgeTargetKeySelector } from "./EdgeTargetKeySelector";
import { useDataloaderStore } from "../../DataloaderStore";

export const EdgeEditView = () => {
  const { editView } = useDataloaderStore().getDeploymentDataloaderState();

  if (editView !== "EDGE") return null;

  return (
    <>
      <Header>Edit Edge</Header>
      <Divider hidden />
      <Form>
        <EdgeLabel />
        <EdgeFileSelector />
        <EdgeSourceKeySelector />
        <EdgeTargetKeySelector />
        <Header sub>File headers</Header>
        <EdgeFileMetadata />
      </Form>
    </>
  );
};
