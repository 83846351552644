//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { PrivateEndpointService, PrivateEndpointService_AksPrivateEndpointConnectionStatus } from "../../api/network/v1/network";
import { pluralize } from "../../api/_util";
import { FlexBox } from "../../ui/_flex";
import { AppLinkButton } from "../../ui/_link";
import { Section, SectionHeader } from "../../ui/_section";
import { StyledStatsSegment } from "../../ui/_style";
import { AliasModalView } from "./AliasView";
import { AlternateDNSNamesModalView } from "./AlternateDNSNamesView";
import { PrivateEndpointConnectionsModalView } from "./ConnectionsView";
import { AKSPrivateNetworkHelpView } from "./PrivateNetworkHelpView";
import { SubscriptionIDModalView } from "./SubscriptionIDView";
import { SummaryItem } from "./SummaryItemTemplate";

interface IPrivateNetworkSummary {
  privateNetworkDetail: PrivateEndpointService;
  onSubscriptionIDChange: (subscriptionIDs: string[]) => Promise<{ error: unknown } | undefined>;
  onAlternateDNSNamesChange: (alternateDNSNames: string[]) => Promise<{ error: unknown } | undefined>;
  onSupportClick?: () => void;
}

const AKSPrivateNetworkSummaryView = ({ privateNetworkDetail, onSubscriptionIDChange, onAlternateDNSNamesChange, onSupportClick }: IPrivateNetworkSummary) => {
  const { alternate_dns_names: dnsNames = [], aks = {}, status = {} } = privateNetworkDetail;
  const { client_subscription_ids: clientSubscriptionId = [] } = aks;
  const { aks: connections = {}, needs_attention: isAttentionRequired = false, message = "" } = status;
  const { alias, private_endpoint_connections: privateConnections = [] } = connections;
  const [showDNSModalView, toggleDNSModalView] = useState<boolean>(false);
  const [showSubscriptionModalView, toggleSubscriptionModalView] = useState<boolean>(false);
  const [showConnectionsModal, toggleConnectionsModal] = useState<boolean>(false);
  const [showAliasView, toggleAliasView] = useState<boolean>(false);
  const [showHelpView, toggleHelpView] = useState<boolean>(false);

  const hasFailedConnections = (connections: PrivateEndpointService_AksPrivateEndpointConnectionStatus[]) =>
    !!connections.find((connection) => connection.state === "failed");

  return (
    <Section>
      {showDNSModalView && (
        <AlternateDNSNamesModalView
          alternateDNSNames={dnsNames}
          onClose={() => toggleDNSModalView(false)}
          onSave={onAlternateDNSNamesChange}
          info={
            <>
              <div>A Private Endpoint will result in an IP addresses in your VNet.</div>
              <div>We strongly recommend mapping that IP address to one or more private DNS names.</div>
              <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
            </>
          }
        />
      )}

      {showSubscriptionModalView && (
        <SubscriptionIDModalView
          subscriptionIDs={clientSubscriptionId}
          onClose={() => toggleSubscriptionModalView(false)}
          onSave={onSubscriptionIDChange}
          disableChange={!!privateConnections.length}
          statusMessage={
            <span>
              For security reasons, subscription ID's cannot be changed, once a connection has been established.
              <br />
              If you want to change subscription ID's anyway, please <AppLinkButton label="Request help" handleClick={onSupportClick} />.
            </span>
          }
        />
      )}

      {showAliasView && <AliasModalView onClose={() => toggleAliasView(false)} initialValue={alias} />}

      {showConnectionsModal && <PrivateEndpointConnectionsModalView onClose={() => toggleConnectionsModal(false)} connections={privateConnections} />}

      {showHelpView && <AKSPrivateNetworkHelpView onClose={() => toggleHelpView(false)} onSupportClick={onSupportClick} />}

      <SectionHeader
        data-testid="privateNetworkSummaryHeader"
        title={
          <span>
            Private Endpoint{" "}
            {isAttentionRequired && <Popup trigger={<Icon name="warning circle" color="red" data-testid="networkErrorIcon" />} content={message} />}
          </span>
        }
        actionableText="Help"
        onActionTextClick={() => toggleHelpView(true)}
      />

      <StyledStatsSegment>
        <FlexBox justify="space-between" margin="0 40px">
          <SummaryItem
            labelIcon="cloud"
            actionIcon="edit"
            mainLabel="Azure"
            actionLabel={`${clientSubscriptionId.length ? clientSubscriptionId.length : "No"} ${pluralize(
              "subscription",
              clientSubscriptionId.length
            )} configured`}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleSubscriptionModalView(true)}
            testID="subscriptionIDSummaryItem"
          />

          <SummaryItem
            labelIcon="server"
            actionIcon="edit"
            mainLabel="Alternate DNS names"
            handleClick={() => toggleDNSModalView(true)}
            actionLabel={`${dnsNames.length ? dnsNames.length : "No"} alternate DNS ${pluralize("name", dnsNames.length)} configured`}
            actionClassName="private-link-stat-item"
            testID="alternateDNSNamesSummaryItem"
          />

          <SummaryItem
            labelIcon="paperclip"
            mainLabel="Azure alias"
            actionLabel={alias ? "Available" : "Not available"}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleAliasView(true)}
            actionIcon="eye"
            noAction={!alias}
            testID="azureAliasSummaryItem"
          />

          <SummaryItem
            labelIcon="linkify"
            mainLabel="Connections"
            actionLabel={`${privateConnections.length ? privateConnections.length : "No"} established ${pluralize("connection", privateConnections.length)}`}
            actionClassName="private-link-stat-item"
            actionIcon="eye"
            handleClick={() => toggleConnectionsModal(true)}
            noAction={!privateConnections.length}
            hasError={hasFailedConnections(privateConnections)}
            errorMessage="Something went wrong in establishing a connection"
            testID="privateConnectionsSummaryItem"
          />
        </FlexBox>
      </StyledStatsSegment>
    </Section>
  );
};

export default AKSPrivateNetworkSummaryView;
