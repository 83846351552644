//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { useState } from "react";
import { Checkbox, CheckboxProps, Loader, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ErrorMessage } from "../../ui/_errorMessage";
import { FlexBox } from "../../ui/_flex";
import apiClients from "../../api/apiclients";
import { Deployment as ApiDeployment, UpdateDeploymentScheduledRootPasswordRotationRequest } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Timestamp } from "../../api/googleTypes";

const StyledSettingCaption = styled.div`
  font-size: 0.8rem;
  padding: 5px 0 0 0;
  font-weight: normal !important;
  width: 88%;
`;

// this is a workaround because of the dimmer class
const StyledLoader = styled(Loader)`
  top: 8px !important;
  &:after {
    border-color: var(--green-300) transparent transparent !important;
  }
  &:before {
    border: 0.2em solid var(--green-600) !important;
  }
`;

interface IPasswordSettingsModalViewArgs {
  deploymentID: string;
  isPasswordRotationScheduleEnabled?: boolean;
  deployment: ApiDeployment;
  isPlatformAuthenticationEnabled?: boolean;
  onClose: () => void;
  onRootPasswordRotationStateUpdate?: (isRotationEnabled: boolean) => void;
  onPlatformAuthentication?: (isPlatformAuthEnabled: boolean) => void;
}
export type IPasswordDeploymentPageArgs = {
  passwordLastRotatedDate?: Timestamp;
};
export interface IPasswordSettingsModalViewExternalArgs
  extends IPasswordDeploymentPageArgs,
    Pick<IPasswordSettingsModalViewArgs, "isPasswordRotationScheduleEnabled"> {
  onOpenPasswordSettingsModal?: () => void;
}

export type IPasswordSettingArgForOverviewComponent = Pick<IPasswordSettingsModalViewExternalArgs, "isPasswordRotationScheduleEnabled"> &
  IPasswordDeploymentPageArgs;

const PasswordSettingModalView = ({
  deploymentID,
  isPasswordRotationScheduleEnabled,
  isPlatformAuthenticationEnabled,
  onPlatformAuthentication,
  deployment,
  onClose,
  onRootPasswordRotationStateUpdate,
}: IPasswordSettingsModalViewArgs) => {
  const [isRotationEnabled, updatePasswordRotationState] = useState(isPasswordRotationScheduleEnabled);
  const [error, updateError] = useState<string | null>(null);
  const [loading, updateLoading] = useState(false);
  const [isPlatformAuthEnabled, updateIsPlatformAuthEnabled] = useState(isPlatformAuthenticationEnabled);

  const onConfirm = async () => {
    try {
      updateLoading(true);
      const req: UpdateDeploymentScheduledRootPasswordRotationRequest = {
        deployment_id: deploymentID,
        enabled: isRotationEnabled,
      };
      await apiClients.dataClient.UpdateDeploymentScheduledRootPasswordRotation(req);
      onRootPasswordRotationStateUpdate && onRootPasswordRotationStateUpdate(!!isRotationEnabled);
      await apiClients.dataClient.UpdateDeployment({
        ...deployment,
        is_platform_authentication_enabled: isPlatformAuthEnabled,
      });
      onPlatformAuthentication && onPlatformAuthentication(!!isPlatformAuthEnabled);
      await updateLoading(false);
      await updateError(null);
      !error && onClose();
    } catch (err) {
      updateError(err);
      updateLoading(false);
      reportError(err);
    }
  };

  return (
    <Modal open size="tiny" data-testid="passwordSettingsModal">
      <Modal.Header tabIndex={0} data-testid="passwordSettingsHeader">
        <FlexBox justify="space-between">
          <span>Password Settings</span> {loading && <StyledLoader size="small" active={loading} inline data-testid="passwordSettingsModalLoader" />}
        </FlexBox>
      </Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} message={error} testID="passwordSettingsModalErrorMessage" />
        <Message>
          <FlexBox justify="space-between" width="95%" padding="5px 0">
            <span data-testid="passwordSettingsModalOptInMessage">
              <b data-testid="passwordSettingsModalCheckboxMessage">Automatic password rotation for the root user</b>
              <StyledSettingCaption data-testid="passwordSettingsModalOptInCaption">
                {isRotationEnabled ? (
                  <>
                    The password for the root user will be rotated every <b>3 months</b>. You will be notified by email of the rotation.{" "}
                    <div>Disabling this will not rotate the root password at all</div>
                  </>
                ) : (
                  "Enabling this will automatically rotate the password for the root user every 3 months"
                )}
              </StyledSettingCaption>
            </span>
            <Checkbox
              data-testid="passwordSettingsModalOptInCheckbox"
              checked={isRotationEnabled}
              disabled={loading}
              tabIndex={2}
              toggle
              onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                updatePasswordRotationState(!!data.checked);
              }}
            />
          </FlexBox>
        </Message>
        <Message>
          <FlexBox justify="space-between" width="95%" padding="5px 0">
            <span data-testid="autoLoginMessage">
              <b data-testid="autoLoginCheckboxMessage">Auto login to database UI</b>
              <StyledSettingCaption data-testid="autoLoginCaption">
                {isPlatformAuthEnabled ? (
                  <>Disabling this will require you to enter username and password to access the database UI</>
                ) : (
                  <>Enabling this will allow you to automatically log in to the database UI with your ArangoGraph platform user</>
                )}
              </StyledSettingCaption>
            </span>
            <Checkbox
              data-testid="autoLoginCheckbox"
              checked={isPlatformAuthEnabled}
              disabled={loading}
              tabIndex={2}
              toggle
              onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                updateIsPlatformAuthEnabled(!!data.checked);
              }}
            />
          </FlexBox>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel tabIndex={4} onClick={onClose} title="Close" data-testid="passwordSettingsModalCloseAction" disabled={loading} />
        <FormActionButtonSave tabIndex={3} title="Save" icon="check" primary onClick={onConfirm} loading={loading} testID="passwordSettingsModalSaveAction" />
      </Modal.Actions>
    </Modal>
  );
};
export default PasswordSettingModalView;
