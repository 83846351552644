//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Header, Icon } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { humanizeFileSize } from "../../../../util/FileSize";
import { StyledFileItem } from "./Styles";
import FileMetadataView from "./FileMetadataView";
import { Loading } from "../../../../ui/_loading";
import { isEmpty } from "lodash";
import { useDataloaderStore } from "../DataloaderStore";
import { useFileParsingHandlers } from "./FileParserProvider";
import { v4 } from "uuid";

const FileItem = () => {
  const { updateDeploymentData, getDeploymentDataloaderState } = useDataloaderStore();
  const { files } = getDeploymentDataloaderState();
  const { isFileBeingParsed } = useFileParsingHandlers();
  const [filesToExpand, setFileIDToExpand] = useState<string[]>([]);

  const onFileClick = (fileId: string) => {
    if (filesToExpand.includes(fileId)) {
      setFileIDToExpand(filesToExpand.filter((file) => file !== fileId));
    } else {
      setFileIDToExpand([...filesToExpand, fileId]);
    }
  };

  useEffect(() => {
    updateDeploymentData({ errorMessage: null });
  }, []);

  return (
    <>
      {files.map((droppedFile) => {
        const {
          id = v4(),
          fileSize = "0",
          name,
          file: { size = 0 },
          uploadError,
          fields,
          errors,
        } = droppedFile;

        const humanizedFileSize = parseInt(fileSize) < 1000 ? `${fileSize} bytes` : humanizeFileSize(parseInt(fileSize));
        const isBeingParsed = isFileBeingParsed(id);
        const isReadyForImport = !!size && !isBeingParsed;

        return (
          <StyledFileItem key={id}>
            <div
              className="cursor-pointer"
              onClick={() => {
                onFileClick(id);
              }}
            >
              <FlexBox justify="space-between" direction="row" className={`file-item ${isReadyForImport ? "" : "opacity-60"}`}>
                <FlexBox justify="flex-start">
                  {!isBeingParsed && errors?.length ? (
                    <Icon name="warning circle" className="file-icon" color="red" />
                  ) : (
                    <Icon name="file alternate outline" className="file-icon" />
                  )}

                  <div className="file-section">
                    <div className="name ellipsis" title={name}>
                      {name}
                    </div>

                    {size ? (
                      <div className="size">{humanizedFileSize}</div>
                    ) : uploadError ? (
                      <div>{uploadError}</div>
                    ) : (
                      <div className="size">
                        <Icon name="info circle" color="red" />
                        Please re-upload this file.
                      </div>
                    )}
                  </div>
                </FlexBox>

                <Icon className="file-item-icon" name={`chevron ${filesToExpand.includes(id) ? "up" : "down"}`} />
              </FlexBox>
            </div>

            <div className={`file-content ${filesToExpand.includes(id) ? "display-block" : "display-none"}`}>
              {isBeingParsed ? (
                <Loading message="File is being read and parsed. Please wait..." />
              ) : (
                <>
                  {!isEmpty(fields) && <Header sub>File headers</Header>}
                  <FileMetadataView file={droppedFile} />
                </>
              )}
            </div>
          </StyledFileItem>
        );
      })}
    </>
  );
};

export default FileItem;
