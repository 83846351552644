//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import apiClients from "../../api/apiclients";
import {
  ListDeploymentNotificationsRequest as ApiListDeploymentNotificationsRequest,
  MarkNotificationRequest as ApiMarkNotificationRequest,
  Notification,
  Notification_ReadAt,
} from "../../api/notification/v1/notification";
import { ErrorMessage } from "../../ui/_errorMessage";
import { Deployment, Deployment_NotificationSettings } from "../../api/lib";
import { first, isEmpty } from "lodash";
import { reportError } from "../../errors/reporting";
import NotificationSettings from "./NotificationSettings";
import NotificationListView from "./NotificationListView";
import { Divider } from "semantic-ui-react";

interface INotiicationViewArgs {
  deployment: Deployment;
  onClickSupportRequest: () => void;
}

const NotificationView = ({ deployment, onClickSupportRequest }: INotiicationViewArgs) => {
  const PAGE_SIZE = 10;
  const { notification_settings: settings = {}, id: deploymentId } = deployment;
  const { email_addresses: emailAddresses = [] } = settings;

  const [error, setError] = useState<unknown>(null);
  const [page, setPage] = useState<number>(0);
  const [showEmailAdditionView, setShowEmailAdditionView] = useState<boolean>(false);
  const [notificationList, setNotificationList] = useState<Notification[]>([]);
  const [activeNotification, setActiveNotification] = useState<Notification | null>(null);
  const [notificationContent, setNotificationContent] = useState<string>("");
  const [existingEmailAddresses, setExistingEmailAddresses] = useState<string[]>(emailAddresses);
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({
    areNotificationsLoading: false,
    areSettingsLoading: false,
  });
  const [highlightedEmail, setHighlightedEmail] = useState<string>("");
  const [recipients, setRecipients] = useState<string[]>([]);

  const getNotifications = async () => {
    const req: ApiListDeploymentNotificationsRequest = {
      deployment_id: deploymentId,
      options: { page, page_size: PAGE_SIZE },
    };

    try {
      const response = await apiClients.notificationClient.ListDeploymentNotifications(req);
      const { items = [] } = response;
      setNotificationList(items);
    } catch (e) {
      setError(e);
      reportError(e);
    }
  };

  const setNotificationDetails = (notificationItem: Notification) => {
    const { content = [], recipients = [] } = notificationItem || {};
    const CONTENT_TYPE = "text/html";
    const contentType = first(content.filter((c) => c.content_type === CONTENT_TYPE));
    if (contentType) {
      setNotificationContent(contentType.content || "");
      setRecipients(recipients);
    }
  };

  const markNotificationAsRead = async (id: string) => {
    const req: ApiMarkNotificationRequest = {
      notification_id: id,
    };

    if (id) {
      try {
        await apiClients.notificationClient.MarkNotificationAsRead(req);
        const { read_at } = activeNotification || {};

        if (isEmpty(read_at)) {
          const readAt: Notification_ReadAt = {
            read_at: new Date(),
          };
          setNotificationList(
            notificationList.map((notification) => {
              if (notification.id === id) {
                return { ...notification, read_at: readAt };
              }
              return notification;
            })
          );
        }
      } catch (e) {
        setError(e);
        reportError(e);
      }
    }
  };

  const saveDeploymentSettings = async (settings: Deployment_NotificationSettings) => {
    setLoadingStates({ ...loadingStates, areSettingsLoading: true });

    const { UpdateDeployment } = apiClients.dataClient;
    const req: Deployment = {
      ...deployment,
      notification_settings: settings,
    };
    try {
      const response = await UpdateDeployment(req);
      const { notification_settings } = response;
      const { email_addresses: newEmailAddresses = [] } = notification_settings || {};
      setExistingEmailAddresses(newEmailAddresses);
      setShowEmailAdditionView(false);
    } catch (e) {
      setError(e);
      reportError(e);
    } finally {
      setLoadingStates({ ...loadingStates, areSettingsLoading: false });
    }
  };

  const handleRemoveEmail = (email: string) => {
    saveDeploymentSettings({ email_addresses: existingEmailAddresses.filter((e) => e !== email) });
  };

  useEffect(() => {
    setLoadingStates({
      areNotificationsLoading: false,
      areSettingsLoading: false,
    });
  }, [notificationList]);

  useEffect(() => {
    setLoadingStates({
      areNotificationsLoading: true,
      areSettingsLoading: false,
    });
    getNotifications();
  }, [page]);

  useEffect(() => {
    if (!activeNotification) {
      setRecipients([]);
      setNotificationContent("");
      return;
    } else {
      const { id, read_at: readAt } = activeNotification || {};
      isEmpty(readAt) && id && markNotificationAsRead(id);
    }
    setNotificationDetails(activeNotification);
  }, [activeNotification]);

  useEffect(() => {
    setExistingEmailAddresses(emailAddresses);
  }, []);

  const handleNotificationClick = (notification: Notification) => {
    setActiveNotification(notification);
  };

  const { areNotificationsLoading, areSettingsLoading } = loadingStates;

  return (
    <>
      <ErrorMessage message={error} active={!!error} />

      <NotificationSettings
        existingEmailAddresses={existingEmailAddresses}
        areSettingsLoading={areSettingsLoading}
        highlightedEmail={highlightedEmail}
        showEmailAdditionView={showEmailAdditionView}
        addEmailAddresses={() => setShowEmailAdditionView(true)}
        handleExistingEmailAddressAddition={(email: string) => {
          setHighlightedEmail(email);
          setTimeout(() => setHighlightedEmail(""), 1000);
        }}
        handleSavingEmailAddresses={(emailAddresses: string[]) => {
          const settings: Deployment_NotificationSettings = {
            email_addresses: [...existingEmailAddresses, ...emailAddresses],
          };
          saveDeploymentSettings(settings);
        }}
        cancelEmailAddressAddition={() => setShowEmailAdditionView(false)}
        handleRemoveEmail={handleRemoveEmail}
      />
      <Divider hidden />
      <NotificationListView
        onClickSupportRequest={onClickSupportRequest}
        notificationList={notificationList}
        areNotificationsLoading={areNotificationsLoading}
        activeNotification={activeNotification}
        notificationContent={notificationContent}
        recipients={recipients}
        pageSize={PAGE_SIZE}
        handlePageChange={(page) => {
          const { page: currentPage } = page;
          setPage(currentPage);
        }}
        handleNotificationClick={handleNotificationClick}
        handleNotificationDetailModalClose={() => {
          setActiveNotification(null);
        }}
      />
    </>
  );
};

export default NotificationView;
