import React from "react";
import { Box } from "../../ui/_box";

export const SidebarHeading = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box padding="0 8px" height="36px" display="flex" align="center" fontWeight="700" fontSize="12px" textTransform="uppercase" color="var(--green-600)">
      {children}
    </Box>
  );
};
