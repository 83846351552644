import moment from "moment";

export const useDataAggregator = () => {
  const getAggregatedSumByDate = <T>(
    data: T[] | undefined,
    dateKey: keyof T,
    aggregatingProperty: keyof T
  ): {
    [key: string]: T;
  } => {
    if (!data) {
      return {};
    }

    return data.reduce(
      (
        aggregatedData: any,
        item: {
          [P in keyof T]: T[P];
        } extends { [key: string]: any }
          ? T
          : never
      ) => {
        const dateFromSource = item[dateKey] || "";

        if (!item[dateKey]) {
          return aggregatedData;
        }

        const itemDateInStandardFormat = moment(dateFromSource).utc().format("YYYY-MM-DD");

        if (!aggregatedData[itemDateInStandardFormat]) {
          aggregatedData[itemDateInStandardFormat] = {};
        }

        const existingItem = aggregatedData[itemDateInStandardFormat];

        if (existingItem) {
          const currentValue = item[aggregatingProperty] || 0;
          if (existingItem[aggregatingProperty]) {
            existingItem[aggregatingProperty] += currentValue;
          } else {
            existingItem[aggregatingProperty] = currentValue;
            existingItem[dateKey] = item[dateKey];
          }
        } else {
          aggregatedData[itemDateInStandardFormat] = {
            [aggregatingProperty]: item[aggregatingProperty],
            [dateKey]: item[dateKey],
          };
        }

        return aggregatedData;
      },
      {}
    );
  };

  return {
    getAggregatedSumByDate,
  };
};
