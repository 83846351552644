//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { InputControl, PageSpinner, TextareaControl } from "@arangodb/ui";
import { Grid } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";

const EditableTitleForm = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <Grid gap="4" templateColumns="1fr">
      <PageSpinner isLoading={isSubmitting} />

      <InputControl
        isRequired
        label="Title"
        name="title"
        inputProps={{
          placeholder: "Title",
        }}
      />

      <TextareaControl name="subTitle" label="Subtitle" inputProps={{ placeholder: "Description" }} />
    </Grid>
  );
};

export { EditableTitleForm };
