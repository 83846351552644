//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { numberFormat } from "humanize";
import _ from "lodash";

export const formatPrice = (price: number, curSign: string, unit: string, rounding: boolean = true) => {
  if (price == 0) {
    return "Free";
  }
  const sep = _.isEmpty(unit) ? "" : "/";
  if (!rounding || (price > 0 && price < 0.01)) {
    return `${numberFormat(price, 6)} ${curSign}${sep}${unit}`;
  }
  return `${numberFormat(price)} ${curSign}${sep}${unit}`;
};

export const formatRoundedPrice = (price: number, curSign: string, unit: string) => formatPrice(price, curSign, unit, true);

export const formatUnroundedPrice = (price: number, curSign: string, unit: string) => formatPrice(price, curSign, unit, false);
