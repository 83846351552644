//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Grid, Segment } from "semantic-ui-react";
import {
  IsBillingConfigValid,
  Address as ApiAddress,
  BillingConfig as ApiBillingConfig,
  IDOptions as ApiIDOptions,
  SetBillingConfigRequest as ApiSetBillingConfigRequest,
} from "../../api/lib";
import {
  CommonKeyValuePair,
  CommonKeyValuePairs,
  CommonKV_Key,
  CommonKV_Value,
  ContentActionButtonEdit,
  ErrorMessage,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import BillingDetailsEditor from "./BillingDetailsEditor";
import { getName as getCountryName } from "country-list";
import { isVatPossible } from "./vat";
import { usaStates } from "typed-usa-states";
import { reportError } from "../../errors/reporting";
import BillingWizard from "./BillingWizard";
import { useDashboardContext } from "../DashboardContextProvider";
import { useWithRefresh } from "../../util/WithRefreshContext";

interface IAddressArgs {
  address: ApiAddress;
  is_us: boolean;
}

const AddressView = ({ ...args }: IAddressArgs) => {
  const addressLines = args.address.address || [];
  const us_state = args.is_us ? usaStates.find((x) => x.abbreviation == args.address.state) : undefined;
  return (
    <div>
      {addressLines.map((l, i) => (
        <div key={`address${i}`}>{l}</div>
      ))}
      <div>
        {args.address.zipcode} {args.address.city}
      </div>
      <div>{us_state ? us_state.name : args.address.state}</div>
    </div>
  );
};

interface IDetailsViewArgs {
  config: ApiBillingConfig;
  is_vat_possible: boolean;
}

const DetailsView = ({ ...args }: IDetailsViewArgs) => {
  const address = args.config.address || {};
  const email_addresses = args.config.email_addresses || [];
  const is_empty = _.isEmpty(address) && _.isEmpty(args.config.vat_number) && _.isEmpty(email_addresses);

  if (is_empty) {
    return <div>No billing details yet</div>;
  }

  const is_us = address.country_code == "US";

  return (
    <Grid width="16">
      <Grid.Column width="3">
        <CommonKeyValuePairs>
          <CommonKeyValuePair>
            <CommonKV_Key>Company name</CommonKV_Key>
            <CommonKV_Value>
              <CommonKV_Value>{args.config.company_legal_name}</CommonKV_Value>
            </CommonKV_Value>
          </CommonKeyValuePair>
        </CommonKeyValuePairs>
      </Grid.Column>
      <Grid.Column width="5">
        <CommonKeyValuePairs>
          <CommonKeyValuePair>
            <CommonKV_Key>Billing Address</CommonKV_Key>
            <CommonKV_Value>
              <AddressView address={address} is_us={is_us} />
            </CommonKV_Value>
          </CommonKeyValuePair>
        </CommonKeyValuePairs>
      </Grid.Column>
      <Grid.Column width="5">
        <CommonKeyValuePairs>
          <CommonKeyValuePair>
            <CommonKV_Key>Country</CommonKV_Key>
            <CommonKV_Value>{address.country_code ? getCountryName(address.country_code) : ""}</CommonKV_Value>
          </CommonKeyValuePair>
        </CommonKeyValuePairs>
        <CommonKeyValuePairs>
          {!is_us && (
            <CommonKeyValuePair>
              <CommonKV_Key>VAT code</CommonKV_Key>
              <CommonKV_Value>
                {args.is_vat_possible && <div>{args.config.vat_number}</div>}
                {!args.is_vat_possible && <div>VAT is only applicable to EU countries</div>}
              </CommonKV_Value>
            </CommonKeyValuePair>
          )}
          {is_us && (
            <CommonKeyValuePair>
              <CommonKV_Key>US sales tax number</CommonKV_Key>
              <CommonKV_Value>
                <div>{args.config.us_tax_number}</div>
              </CommonKV_Value>
            </CommonKeyValuePair>
          )}
        </CommonKeyValuePairs>
      </Grid.Column>
      <Grid.Column width="3">
        <CommonKeyValuePairs>
          <CommonKeyValuePair>
            <CommonKV_Key>Billing Email Addresses</CommonKV_Key>
            <CommonKV_Value>
              {email_addresses.map((x, i) => (
                <div key={`eaddr${i}`}>{x}</div>
              ))}
            </CommonKV_Value>
          </CommonKeyValuePair>
        </CommonKeyValuePairs>
      </Grid.Column>
    </Grid>
  );
};

interface IBillingDetailsAPI {
  GetBillingConfig: (req: ApiIDOptions) => Promise<ApiBillingConfig>;
  SetBillingConfig: (req: ApiSetBillingConfigRequest) => Promise<void>;
}

// Interface decribing the properties of the billing details component
interface IBillingDetailsProps {
  api: IBillingDetailsAPI;
  isEditAllowed: boolean;
  onBillingConfigChanged: (isValid: boolean) => void;
  isPaymentMethodListAllowed: boolean;
  isPaymentMethodCreateAllowed: boolean;
}

const BillingDetails = ({ ...props }: IBillingDetailsProps) => {
  const { selectedOrganization } = useDashboardContext();
  const [saving, setSaving] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [config, setConfig] = useState<ApiBillingConfig | undefined>(undefined);
  const { refreshNow } = useWithRefresh();
  const reloadBillingConfig = async () => {
    const idOptions = { id: selectedOrganization.id } as ApiIDOptions;
    const getConfig = await props.api.GetBillingConfig(idOptions);
    setConfig(getConfig);
    props.onBillingConfigChanged(IsBillingConfigValid(getConfig));
  };

  const refreshBillingConfig = () => {
    refreshNow?.(reloadBillingConfig);
  };

  useEffect(() => {
    refreshBillingConfig();
  }, []);

  const onEdit = () => {
    setEditing(true);
    setErrorMessage(undefined);
  };

  const onSave = async (newConfig: ApiBillingConfig) => {
    setSaving(true);
    setErrorMessage(undefined);

    try {
      const req = {
        organization_id: selectedOrganization.id,
        config: newConfig,
      } as ApiSetBillingConfigRequest;
      await props.api.SetBillingConfig(req);
      setEditing(false);
      setSaving(false);
      setConfig(newConfig);

      props.onBillingConfigChanged(IsBillingConfigValid(newConfig));
    } catch (e) {
      setEditing(false);
      setSaving(false);
      setErrorMessage(e);
      reportError(e);
    }
  };

  const onCancel = () => {
    setEditing(false);
    setErrorMessage(undefined);
  };

  const has_config = !!config;
  const vat_possible = isVatPossible(config || {});

  const isPaymentMethodsAdditionAllowed = props.isPaymentMethodListAllowed && props.isPaymentMethodCreateAllowed;

  return (
    <Section>
      <ErrorMessage active={!!errorMessage} message={errorMessage} onDismiss={() => setErrorMessage(undefined)} />
      <BillingWizard
        isBillingDetailsEditAllowed={props.isEditAllowed}
        isPaymentMethodsAdditionAllowed={isPaymentMethodsAdditionAllowed}
        onSave={onSave}
        config={config || {}}
        organization={selectedOrganization}
        refreshNow={refreshNow}
      />
      {editing && <BillingDetailsEditor {...props} config={config || {}} onSave={onSave} onCancel={onCancel} />}
      <SectionHead>
        <SectionHeader title="Billing Details" />
        {props.isEditAllowed && (
          <SectionButtons>
            <ContentActionButtonEdit primary disabled={!has_config || editing || saving} onClick={onEdit} />
          </SectionButtons>
        )}
      </SectionHead>
      <SectionContent>
        <Segment>
          <DetailsView {...props} config={config || {}} is_vat_possible={vat_possible} />
        </Segment>
      </SectionContent>
    </Section>
  );
};

export default BillingDetails;
