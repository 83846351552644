//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import styled from "@emotion/styled";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Icon, Grid, Segment } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "./HomeTemplate";
import apiClients from "../api/apiclients";
import { PlanList as ApiPlanList, ListPlansRequest as ApiListPlansRequest } from "../api/lib";
import { ErrorMessage, Loading, Section, SectionHeader, SectionContent } from "../ui/lib";
import DocumentTitle from "react-document-title";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { reportError } from "../errors/reporting";
import { Box } from "../ui/_box";

const Description = styled("div")`
  min-height: 10em;
`;

const ResponseTimes = styled("div")`
  .fact-block {
    padding-bottom: 24px;
  }
`;

function formatTime(value: number): string {
  if (value == 0) {
    return "Best effort";
  }
  if (value > 60) {
    return `${value / 60} hr`;
  } else {
    return `${value} min`;
  }
}

interface ISupportPlansViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
  plans?: ApiPlanList;
  errorMessage?: string;
}

const SupportPlansView = ({ ...args }: ISupportPlansViewArgs) => {
  const has_plans = !!args.plans;
  const plans = (args.plans || {}).items || [];

  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
              <h1 className="heading-1">Support plans</h1>
            </Grid.Column>
            {false && (
              <Grid.Column width="8" only="computer tablet">
                <TransparentLogo1 src={cloud} />
                <TransparentLogo2 src={cloud} />
                {/*<!-- FullLogo src={cloud} />*/}
              </Grid.Column>
            )}
          </GridRowIntro>
        </Grid>

        <ErrorMessage active={!!args.errorMessage} message={args.errorMessage} />
        {!has_plans && <Loading message="Loading support plans..." />}

        {has_plans && (
          <div>
            <Grid stackable>
              <Grid.Row columns="3" stretched>
                {plans.map((x, i) => {
                  const first_response_times = x.first_response_times || {};
                  const is_unavailable = !!x.is_unavailable;
                  return (
                    <Grid.Column key={`plan${i}`}>
                      <Segment>
                        <Section>
                          <SectionHeader title={x.name || ""} />
                          <SectionContent>
                            <Description>
                              <p className="para">{x.description}</p>
                            </Description>
                            {!is_unavailable && (
                              <div>
                                <h3 className="heading-3">Response times</h3>
                                <ResponseTimes>
                                  <div className="facts">
                                    <div className="fact-block">
                                      <div className="fact__key">Critical issues</div>
                                      <div className="fact__value">{formatTime(first_response_times.critical || 0)}</div>
                                    </div>
                                    <div className="fact-block">
                                      <div className="fact__key">High priority issues</div>
                                      <div className="fact__value">{formatTime(first_response_times.high || 0)}</div>
                                    </div>
                                    <div className="fact-block">
                                      <div className="fact__key">Normal priority issues</div>
                                      <div className="fact__value">{formatTime(first_response_times.normal || 0)}</div>
                                    </div>
                                    <div className="fact-block">
                                      <div className="fact__key">Low priority issues</div>
                                      <div className="fact__value">{formatTime(first_response_times.low || 0)}</div>
                                    </div>
                                  </div>
                                </ResponseTimes>
                              </div>
                            )}
                          </SectionContent>
                        </Section>
                      </Segment>
                    </Grid.Column>
                  );
                })}
                <Grid.Column>
                  <Segment>
                    <Section>
                      <SectionHeader title="Premium" />
                      <SectionContent>
                        <Description>
                          <p className="para">Premium support allows for even faster response times.</p>
                          <p className="para">Decide together with our staff what level of support you need for your deployments.</p>
                        </Description>
                        <a className="ui primary icon right labeled button" target="_blank" href="https://arangodb.com/contact" rel="noreferrer">
                          Contact us
                          <Icon name="phone" />
                        </a>
                      </SectionContent>
                    </Section>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Box>
    </HomeTemplateView>
  );
};

interface ISupportPlansProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

interface ISupportPlansState {
  plans?: ApiPlanList;
  errorMessage?: string;
}

// SupportPlans shows the available support plans
class SupportPlans extends Component<ISupportPlansProps, ISupportPlansState> {
  state = {
    plans: undefined,
    errorMessage: undefined,
  } as ISupportPlansState;

  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  reloadPlans = async () => {
    const req = {} as ApiListPlansRequest;
    const list = await apiClients.supportClient.ListPlans(req);
    this.setState({
      plans: list,
      errorMessage: undefined,
    });
  };

  refreshPlans = () => {
    try {
      this.reloadPlans();
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    }
  };

  componentDidMount() {
    this.refreshPlans();
  }

  render() {
    return (
      <DocumentTitle title="Support plans">
        <SupportPlansView
          {...this.props}
          {...this.state}
          isAuthenticated={this.props.auth.isAuthenticated()}
          onClickLogin={this.login}
          onSignup={this.signup}
          onClickLogout={this.logout}
        />
      </DocumentTitle>
    );
  }
}

export default withSignupModal(SupportPlans);
