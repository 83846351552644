//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { DropdownProps, Form, Grid, Icon, InputOnChangeData, Modal, TextAreaProps } from "semantic-ui-react";
import { BillingConfig as ApiBillingConfig } from "../../api/lib";
import { FormActionButtonCancel, FormActionButtonSave, InlineDropdown } from "../../ui/lib";
import { getData } from "country-list";
import { isVatPossible, isVatNumberValid } from "./vat";
import { usaStates } from "typed-usa-states";

const countryOptions = getData().map((entry) => ({
  key: entry.code,
  text: entry.name,
  value: entry.code,
  flag: entry.code.toLowerCase(),
}));

const usaStateOptions = usaStates.map((entry) => ({
  key: entry.abbreviation,
  text: entry.name,
  value: entry.abbreviation,
}));

const StretchedTextArea = styled(Form.TextArea)`
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
  textarea {
    flex-grow: 1;
  }
`;

const AttachedFooterRow = styled(Grid.Row)`
  padding-top: 0 !important;
`;

interface IEditDetailsViewArgs {
  config: ApiBillingConfig;
  configSetter: (config: ApiBillingConfig) => void;
}

export const EditDetailsView = ({ config, configSetter }: IEditDetailsViewArgs) => {
  const address = config.address || {};
  const { company_legal_name: companyLegalName } = config;
  const addressLines = address.address || [];
  const email_addresses = config.email_addresses || [];
  const is_us = address.country_code == "US";
  const isVatAPossibleValue = isVatPossible(config);
  const isVatValid = isVatNumberValid(config, config.vat_number);

  const onAddressLinesChange = (e: any, args: TextAreaProps) => {
    const value = args.value as string;
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    newConfig.address.address = value.split("\n");

    configSetter(newConfig);
  };

  const onCityChange = (e: any, args: InputOnChangeData) => {
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    newConfig.address.city = args.value;

    configSetter(newConfig);
  };

  const onZipcodeChange = (e: any, args: InputOnChangeData) => {
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    newConfig.address.zipcode = args.value;

    configSetter(newConfig);
  };

  const onStateChange = (e: any, args: InputOnChangeData) => {
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    newConfig.address.state = args.value;

    configSetter(newConfig);
  };

  const onUSStateChange = (e: object, args: DropdownProps) => {
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    const newStateCode = args.value as string;
    newConfig.address.state = newStateCode;

    configSetter(newConfig);
  };

  const onCountryChange = (e: object, args: DropdownProps) => {
    const newConfig = _.clone(config || {});
    newConfig.address = _.clone(newConfig.address || {});
    const prevCountryCode = newConfig.address.country_code;
    const newCountryCode = args.value as string;
    newConfig.address.country_code = newCountryCode;

    if (prevCountryCode != newCountryCode) {
      newConfig.vat_number = undefined;
    }

    configSetter(newConfig);
  };

  const onVATNumberChange = (e: any, args: InputOnChangeData) => {
    const newConfig = _.clone(config || {});
    newConfig.vat_number = args.value;

    configSetter(newConfig);
  };

  const onUSTaxNumberChange = (e: any, args: InputOnChangeData) => {
    const newConfig = _.clone(config || {});
    newConfig.us_tax_number = args.value;

    configSetter(newConfig);
  };

  const onEmailAddressesChange = (e: any, args: TextAreaProps) => {
    const value = args.value as string;
    const newConfig = _.clone(config || {});
    newConfig.email_addresses = value.split("\n");

    configSetter(newConfig);
  };

  const onCompanyLegalNameChange = (e: any, args: InputOnChangeData) => {
    const updatedState = { ...config, company_legal_name: args.value };

    configSetter(updatedState);
  };

  return (
    <Form>
      <Grid width="16">
        <Grid.Row>
          <Grid.Column width="4">
            <h3 className="heading-3">Name</h3>
            <Form.Input
              required
              label="Company Name"
              placeholder={`Company Name`}
              name="Company Name"
              value={companyLegalName}
              onChange={onCompanyLegalNameChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <h3 className="heading-3">Address</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="5" stretched>
            <StretchedTextArea
              autoFocus
              required
              label="Address"
              placeholder={`Address line 1, ...`}
              name="address_lines"
              value={addressLines.join("\n")}
              onChange={onAddressLinesChange}
              rows="2"
            />
          </Grid.Column>
          <Grid.Column width="4">
            <Form.Input required label="City" placeholder={`City (e.g. "New York")`} name="city" value={address.city} onChange={onCityChange} />
            <Form.Field required>
              <label>Country</label>
              <InlineDropdown
                required
                selection
                fluid
                search
                label="Country"
                placeholder="Select your country"
                value={address.country_code}
                options={countryOptions}
                onChange={onCountryChange}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="4">
            {!is_us && <Form.Input label="State" placeholder={`State`} name="state" value={address.state} onChange={onStateChange} />}
            {is_us && (
              <Form.Field required>
                <label>State</label>
                <InlineDropdown
                  required
                  selection
                  fluid
                  search
                  label="State"
                  placeholder="Select your state"
                  value={address.state}
                  options={usaStateOptions}
                  onChange={onUSStateChange}
                />
              </Form.Field>
            )}

            {!is_us && (
              <Form.Input
                label="EU VAT identification number"
                placeholder={`VAT number`}
                name="vat_number"
                value={isVatAPossibleValue ? config.vat_number : ""}
                onChange={onVATNumberChange}
                error={!isVatValid}
                disabled={!isVatAPossibleValue}
              />
            )}
            {is_us && (
              <Form.Input
                label="US sales tax identification number"
                placeholder={`Sales tax number`}
                name="sales_tax_number"
                value={config.us_tax_number}
                onChange={onUSTaxNumberChange}
              />
            )}
          </Grid.Column>
          <Grid.Column width="3">
            <Form.Input label="ZIP code" placeholder={`ZIP code`} name="zipcode" value={address.zipcode} onChange={onZipcodeChange} />
          </Grid.Column>
        </Grid.Row>
        <AttachedFooterRow>
          <Grid.Column width="16">
            <div>Changes to the address only appear on future invoices.</div>
            {!isVatAPossibleValue && !is_us && <div>EU VAT does not apply. Your organization will receive VAT-free invoice.</div>}
          </Grid.Column>
        </AttachedFooterRow>
        <Grid.Row>
          <Grid.Column width="16">
            <h3 className="heading-3">Notifications</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="8">
            <Form.TextArea
              label="Invoices automatically emailed to"
              placeholder={`billing@example.com`}
              name="email_addresses"
              value={email_addresses.join("\n")}
              onChange={onEmailAddressesChange}
              rows="5"
            />
          </Grid.Column>
        </Grid.Row>
        <AttachedFooterRow>
          <Grid.Column width="8">
            <div>One email address per line</div>
          </Grid.Column>
        </AttachedFooterRow>
      </Grid>
    </Form>
  );
};
interface IBillingDetailsEditorProps {
  config: ApiBillingConfig;
  onSave: (newConfig: ApiBillingConfig) => void;
  onCancel: () => void;
}
const BillingDetailsEditor = ({ config: configProp, onCancel, onSave }: IBillingDetailsEditorProps) => {
  const [config, setConfig] = useState<ApiBillingConfig>(configProp);
  const vat_possible = isVatPossible(config);
  const vat_valid = isVatNumberValid(config, config.vat_number);

  const canSave = !vat_possible || vat_valid;

  return (
    <Modal open={true} size="large">
      <Modal.Header>
        <Icon name="euro" />
        Billing details
      </Modal.Header>
      <Modal.Content>
        <EditDetailsView config={config} configSetter={setConfig} />
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onCancel} />
        <FormActionButtonSave onClick={() => onSave(config)} disabled={!canSave} primary />
      </Modal.Actions>
    </Modal>
  );
};

export default BillingDetailsEditor;
