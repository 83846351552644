//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

export interface IIconWithTooltipArgs {
  tooltip: any;
  name?: SemanticICONS;
  color?: SemanticCOLORS;
}

export const IconWithTooltip = ({ ...args }: IIconWithTooltipArgs) => {
  return <Popup content={args.tooltip} trigger={<Icon name={args.name || "info circle"} color={args.color} />} />;
};
