//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";

export const useFetchRegion = (regionId: string | undefined) => {
  const fetchRegion = async () => {
    const region = await apiClients.platformClient.GetRegion({ id: regionId });
    return region;
  };
  return useQuery([regionId], fetchRegion, {
    refetchOnWindowFocus: false,
    enabled: !!regionId,
  });
};
