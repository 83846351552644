//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { Progress } from "semantic-ui-react";
import { Deployment } from "../../api/lib";
import { Box } from "../../ui/_box";
import { FlexBox } from "../../ui/_flex";

export const BootstrappingModal = ({ deployment }: { deployment?: Deployment }) => {
  if (!deployment || !deployment.status) {
    return null;
  }
  const createdAt = deployment.created_at;
  const createdText = createdAt ? moment(deployment.created_at).fromNow() : "";
  const serverList = (deployment.status || {}).servers || [];
  const totalLength = serverList.length;
  const okList = serverList.filter((server) => {
    return server.ok;
  });
  const okListLength = okList.length;
  const progress = totalLength === 0 ? 0 : Math.round((okListLength / totalLength) * 100);

  return (
    <Box margin="44px 0 0 0" display="flex" direction="column" alignItems="center" justify="center" height="calc(100vh - 240px)" padding="0 100px">
      <h2 className="heading-2">Deployment creation in progress, please wait.</h2>
      <Progress percent={progress} size="tiny" color="green" />
      <FlexBox justify="space-between">
        {totalLength > 0 && (
          <div>
            {okListLength} out of {totalLength} servers ready
          </div>
        )}
        {createdText && <Box margin="0 0 0 auto">Created {createdText}</Box>}
      </FlexBox>
    </Box>
  );
};
