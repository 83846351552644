//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useEffect, useState, Dispatch, SetStateAction } from "react";
import _ from "lodash";
import { Organization, ProviderList, PreparePaymentMethodRequest, Provider, PreparedPaymentMethod, PaymentMethodList } from "../../api/lib";
import apiClients from "../../api/apiclients";
import { IWithRefreshProps } from "../../util/WithRefresh";

interface IUsePaymentInfo {
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  organization: Organization;
  refreshNow: IWithRefreshProps["refreshNow"];
}

const usePaymentInfo = ({ organization, refreshNow, setErrorMessage }: IUsePaymentInfo) => {
  const [providersList, setProvidersList] = useState<ProviderList>();
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [provider, setProvider] = useState<Provider | undefined>(undefined);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState<boolean>(true);
  const [preparedPaymentMethod, setPreparedPaymentMethod] = useState<PreparedPaymentMethod | undefined>(undefined);
  const [paymentMethodsList, setPaymentMethodsList] = useState<PaymentMethodList | undefined>(undefined);

  const onProviderChange = async (id: string) => {
    const provider = _.find((providersList || {}).items, { id: id });

    if (provider) {
      setLoadingPaymentMethods(true);
      try {
        const req = {
          provider_id: id,
          organization_id: organization.id,
        } as PreparePaymentMethodRequest;
        const prepReq = await apiClients.billingClient.PreparePaymentMethod(req);

        setProviderId(id);
        setProvider(provider);
        setPreparedPaymentMethod(prepReq);
      } catch (e) {
        setErrorMessage(e);
      } finally {
        setLoadingPaymentMethods(false);
      }
    } else {
      setProviderId(id);
      setProvider(undefined);
      setPreparedPaymentMethod(undefined);
    }
  };

  const reloadPaymentProviders = async () => {
    try {
      const req = { organization_id: organization.id };
      const providersList = await apiClients.billingClient.ListPaymentProviders(req);
      setProvidersList(providersList);
    } catch (e) {
      setErrorMessage(e);
    }
  };

  const reloadPaymentMethods = async () => {
    setLoadingPaymentMethods(true);
    try {
      const req = { organization_id: organization.id };
      const paymentMethods = await apiClients.billingClient.ListPaymentMethods(req);
      setPaymentMethodsList(paymentMethods);
    } catch (e) {
      setErrorMessage(e);
    } finally {
      setLoadingPaymentMethods(false);
    }
  };

  useEffect(() => {
    const { items = [] } = providersList || {};
    if (items.length === 1) {
      onProviderChange(items[0].id || "");
    }
  }, [providersList]);

  const refreshPaymentMethods = () => {
    refreshNow && refreshNow(reloadPaymentMethods);
  };

  const refreshPaymentProviders = () => {
    refreshNow && refreshNow(reloadPaymentProviders);
  };

  useEffect(() => {
    refreshPaymentProviders();
    refreshPaymentMethods();
  }, [organization, organization.id]);

  return { providersList, providerId, provider, loadingPaymentMethods, preparedPaymentMethod, paymentMethodsList, refreshPaymentMethods, onProviderChange };
};

export default usePaymentInfo;
