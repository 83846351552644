//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Flex, Icon, Spinner, Td, Text } from "@chakra-ui/react";
import React from "react";
import { MdAccountCircle, MdGroups } from "react-icons/md";
import { isUser, useFetchMemberInfo } from "./useFetchMemberInfo";

export const TableMemberCell = ({ memberId }: { memberId: string }) => {
  const { data, status } = useFetchMemberInfo({ memberId });
  if (status === "loading") {
    return (
      <Td>
        <Spinner size="xs" />
      </Td>
    );
  }
  return (
    <Td>
      <Flex alignItems="center" gap="2">
        <Icon boxSize="5" as={isUser(memberId) ? MdAccountCircle : MdGroups} />
        <Text>{data?.name || memberId}</Text>
      </Flex>
    </Td>
  );
};
