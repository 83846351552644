//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, InputOnChangeData, Popup, Grid } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser } from "../../api/iam/v1/iam";
import {
  Loading,
  ContentSegment,
  Processing,
  ErrorMessage,
  PhoneNumber,
  OnboardingLogo,
  OnboardingName,
  OnboardingDescription,
  OnboardingFootNote,
  CenteredContentForm,
  OnboardingButton,
  TextLink,
  LeftAligned,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import DocumentTitle from "react-document-title";

import homeImage from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { ITracking } from "../../tracking/api";
import { reportError } from "../../errors/reporting";

interface IEnterPhoneNumberViewArgs extends IEnterPhoneNumberState {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  user?: ApiUser;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateUser: () => void;
}

const EnterPhoneNumberView = ({ ...args }: IEnterPhoneNumberViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Updating mobile phone number, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <OnboardingLogo>
      <img style={{ width: "100%", height: "100%" }} src={homeImage} alt="logo" />
    </OnboardingLogo>
    <OnboardingName>To get started, please supply a mobile phone number.</OnboardingName>
    <OnboardingDescription>
      You can always change the your number later.
      <Popup trigger={<TextLink label="Why do we ask for one?" onClick={() => {}} />}>
        <p className="para">We use your phone number to verify that you are an human being with serious interest in ArangoGraph.</p>
        <p className="para">We will not use this phone number for any other purpose without your explicit consent.</p>
      </Popup>
    </OnboardingDescription>
    {args.user ? (
      <CenteredContentForm>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={5} />
            <Grid.Column width={6}>
              <LeftAligned>
                <Form.Input
                  autoFocus
                  required
                  label="Mobile phone number"
                  size="big"
                  control={PhoneNumber}
                  placeholder="Mobile phone number"
                  name="mobile_phone"
                  value={args.mobile_phone}
                  onChange={args.updateFormInputChange}
                />
              </LeftAligned>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <OnboardingButton>
          <Form.Button primary size="big" disabled={!args.active || !args.mobile_phone} content="Save" onClick={args.updateUser} />
        </OnboardingButton>
      </CenteredContentForm>
    ) : (
      <Loading />
    )}
    <OnboardingFootNote>If you do not have a mobile phone number, use "Request Help" in the top of this page to contact us.</OnboardingFootNote>
  </ContentSegment>
);

// Interface decribing the properties of the EnterPhoneNumber component
interface IEnterPhoneNumberProps extends IWithRefreshProps, RouteComponentProps {
  tracking: ITracking;
  onPhoneNumberUpdated: (userId: string) => void;
}

// Interface decribing the state of the EnterPhoneNumber component
interface IEnterPhoneNumberState {
  user?: ApiUser;
  errorMessage?: string;
  processing: boolean;
  mobile_phone: string;
}

// Component to update phone number of the active user.
class EnterPhoneNumber extends Component<IEnterPhoneNumberProps, IEnterPhoneNumberState> {
  state = {
    errorMessage: undefined,
    processing: false,
    user: undefined,
    mobile_phone: "",
  } as IEnterPhoneNumberState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
      mobile_phone: user.mobile_phone || "",
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "mobile_phone":
        this.setState({ mobile_phone: args.value });
        break;
    }
  };

  updateUser = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const user = this.state.user;
      if (user) {
        user.mobile_phone = this.state.mobile_phone;

        //                console.info(user);
        const updatedUser = await apiClients.iamClient.UpdateUser(user);
        //                console.info(updatedUser);

        this.props.tracking.trackPhoneNumberEntered();
        this.props.onPhoneNumberUpdated(updatedUser.id || "");
      }
    } catch (e) {
      this.setState({ errorMessage: `User update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
  }

  render() {
    return (
      <DocumentTitle title="Onboarding: Enter mobile phone number">
        <EnterPhoneNumberView
          {...this.state}
          active={!this.state.processing}
          handleDismissError={this.handleDismissError}
          updateFormInputChange={this.updateFormInputChange}
          updateUser={this.updateUser}
        />
      </DocumentTitle>
    );
  }
}

export default withRefresh()(EnterPhoneNumber);
