//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import { TextLink } from "../../ui/_textlink";
import { Routes } from "../../routes";
interface IGettingStartedModal {
  isOpen: boolean;
  onClickStart: () => void;
  onSkip: () => void;
}

const GetStartedModal = ({ isOpen, onClickStart, onSkip }: IGettingStartedModal) => {
  return (
    <Modal open={isOpen}>
      <Modal.Header>Unlock all features!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          Welcome to the Billing Wizard! Follow these steps to complete the registration process for your company and set up a payment method. Once you've added
          your payment method, you'll immediately have access to all features. We're here to guide you through the entire process. Let's get started!
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FlexBox align="center" justify="space-between">
          <div>
            <TextLink onClick={onSkip} label="Skip for now" />

            <Button onClick={() => window.location.replace(Routes.dashboard_pricing)}>See all ArangoGraph packages</Button>
          </div>
          <Button primary onClick={onClickStart}>
            Get started!
          </Button>
        </FlexBox>
      </Modal.Actions>
    </Modal>
  );
};

export default GetStartedModal;
