//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Checkbox, Loader, Table } from "semantic-ui-react";
import {
  AuditLog as ApiAuditLog,
  AuditLog_Destination as ApiAuditLogDestination,
  AuditLogList as ApiAuditLogList,
  AuditLogTopic as ApiAuditLogTopic,
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  SetDefaultAuditLogRequest as ApiSetDefaultAuditLogRequest,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import {
  Confirm,
  ConfirmInfo,
  ErrorMessage,
  ListActionDelete,
  ListActionEdit,
  ListActionSetDefault,
  ListActionResetDefault,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  TextLink,
} from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import apiClients from "../../api/apiclients";
import UpdateAuditLogModal from "./UpdateAuditLogModal";
import { DestinationView } from "./AuditLogDestinationViews";
import { useWithRefresh } from "../../util/WithRefreshContext";

interface IHeaderView {
  loading: boolean;
  disable_default?: boolean;
}

const HeaderView = ({ ...args }: IHeaderView) => {
  const disable_default = !!args.disable_default;
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Destinations</Table.HeaderCell>
        {!disable_default && <Table.HeaderCell>Default</Table.HeaderCell>}
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
        <Table.HeaderCell>
          Actions
          <LoaderBox>
            <Loader size="mini" active={args.loading} inline />
          </LoaderBox>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface IDestinationsViewArgs {
  destinations?: ApiAuditLogDestination[];
}

const DestinationsView = ({ ...args }: IDestinationsViewArgs) => {
  const destinations = args.destinations || [];
  if (_.isEmpty(destinations)) return <span>-</span>;
  return (
    <div>
      {destinations.map((d) => (
        <div>
          <DestinationView destination={d} />{" "}
        </div>
      ))}
    </div>
  );
};

interface IRowViewArgs {
  active: boolean;
  item: ApiAuditLog;
  is_selected: boolean;
  disable_edit?: boolean;
  disable_delete?: boolean;
  disable_default?: boolean;
  disable_select?: boolean;
  onClickEdit: () => void;
  onClickSetDefault: () => void;
  onClickResetDefault: () => void;
  onClickDelete: () => void;
  onClickSelect: () => void;
  onClickDeselect: () => void;
}

const RowView = ({ ...args }: IRowViewArgs) => {
  const { hasPermissionByUrl } = useWithRefresh();
  const hasPermission = (p: Permission) => {
    return !!hasPermissionByUrl?.(args.item.url || "", ResourceType.AuditLog, p);
  };

  const has_auditlog_update = hasPermission("audit.auditlog.update");
  const has_auditlog_delete = hasPermission("audit.auditlog.delete");
  const has_auditlog_set_default = hasPermission("audit.auditlog.set-default");

  const disable_edit = !!args.disable_edit;
  const disable_delete = !!args.disable_delete;
  const disable_default = !!args.disable_default;
  const disable_select = !!args.disable_select;

  return (
    <Table.Row>
      <Table.Cell>
        {!disable_select && (
          <span>
            <Checkbox
              radio
              fitted={false}
              checked={args.is_selected}
              onClick={() => {
                if (!args.is_selected) {
                  args.onClickSelect();
                } else {
                  args.onClickDeselect();
                }
              }}
            />
            &nbsp;
          </span>
        )}
        {!disable_select && <TextLink label={args.item.name} onClick={args.onClickSelect} />}
        {disable_select && <span>{args.item.name}</span>}
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>
        <DestinationsView destinations={args.item.destinations} />
      </Table.Cell>
      {!disable_default && <Table.Cell>{args.item.is_default ? "Yes" : "No"}</Table.Cell>}
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {has_auditlog_update && !disable_edit && !args.item.is_deleted && <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />}
          {has_auditlog_set_default && !disable_default && !args.item.is_default && !args.item.is_deleted && (
            <ListActionSetDefault disabled={!args.active} onClick={args.onClickSetDefault} />
          )}
          {has_auditlog_set_default && !disable_default && !!args.item.is_default && !args.item.is_deleted && (
            <ListActionResetDefault disabled={!args.active} onClick={args.onClickResetDefault} />
          )}
          {has_auditlog_delete && !disable_delete && <ListActionDelete disabled={!args.active} onClick={args.onClickDelete} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  active: boolean;
  items: ApiAuditLog[];
  loading: boolean;
  selected_auditlog_id?: string;
  disable_edit?: boolean;
  disable_delete?: boolean;
  disable_default?: boolean;
  disable_select?: boolean;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickResetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickSelect: (id: string) => void;
  onClickDeselect: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            is_selected={item.id == args.selected_auditlog_id}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickSetDefault={() => args.onClickSetDefault(item.id || "")}
            onClickResetDefault={() => args.onClickResetDefault(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
            onClickDeselect={() => args.onClickDeselect(item.id || "")}
            onClickSelect={() => args.onClickSelect(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IOwnerViewArgs {
  ownerType: string;
}

const EmptyView = ({ ...args }: IOwnerViewArgs) => <div>No audit logs inside this {args.ownerType}</div>;

const NoSelectionView = () => <div>No audit log has been selected for this project.</div>;

export interface IAuditLogListViewArgs {
  ownerType: string;
  active: boolean;
  loading: boolean;
  auditLogs?: ApiAuditLogList;
  selected_auditlog_id?: string;
  only_selected?: boolean;
  disable_edit?: boolean;
  disable_delete?: boolean;
  disable_default?: boolean;
  disable_select?: boolean;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickResetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickSelect: (id: string) => void;
  onClickDeselect: (id: string) => void;
}

export const AuditLogListView = ({ ...args }: IAuditLogListViewArgs) => {
  if (!args.auditLogs) {
    return <Loading />;
  }
  let items = args.auditLogs.items || [];
  const only_selected = !!args.only_selected;
  if (only_selected) {
    items = _.filter(items, (x) => x.id == args.selected_auditlog_id);
  }
  if (_.isEmpty(items)) {
    if (only_selected) {
      return <NoSelectionView />;
    } else {
      return <EmptyView {...args} />;
    }
  }
  return <ListView {...args} items={_.orderBy(items, "name")} loading={args.loading} />;
};

interface IAuditLogListProps {
  disable_edit?: boolean;
  disable_delete?: boolean;
  disable_default?: boolean;
  disable_select?: boolean;
  only_selected?: boolean;
  ownerType: string;
  selected_auditlog_id?: string;
  organization: ApiOrganization;
  auditLogs?: ApiAuditLogList;
  all_topics: ApiAuditLogTopic[];
  onRefreshList: () => void;
  onClickSelect: (id: string) => void;
  onClickDeselect: (id: string) => void;
  loading: boolean;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

interface IAuditLogListState {
  errorMessage?: string;
  processingDelete: boolean;
  processingDefault: boolean;
  confirmInfo?: ConfirmInfo;
  editId?: string;
}

// The component to show the audit logs inside an organization as a list.
class AuditLogList extends Component<IAuditLogListProps, IAuditLogListState> {
  state = {
    errorMessage: undefined,
    auditLogs: undefined,
    processingDelete: false,
    processingDefault: false,
    confirmInfo: undefined,
    editId: undefined,
    prevOrganizationId: undefined,
    refreshNeeded: false,
  } as IAuditLogListState;

  onClickEdit = (id: string) => {
    this.setState({ editId: id });
  };
  onCloseEdit = () => {
    this.setState({ editId: undefined });
  };

  getAuditLogName = (id: string) => {
    const auditLogs = this.props.auditLogs || {};
    const auditLogItems = auditLogs.items || [];
    const auditLog = auditLogItems.find((c) => c.id == id);
    if (auditLog) {
      return auditLog.name;
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const auditLogName = this.getAuditLogName(id);
    const confirmInfo = {
      header: "Delete Audit Log",
      content: `Are you sure you want to delete audit log '${auditLogName}'?`,
      warning: "Deleting an audit log will also remove all collected audit events. This cannot be undone!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: async () => {
        try {
          this.setState({ errorMessage: undefined, processingDelete: true, confirmInfo: undefined });
          const idOptions = { id: id } as ApiIDOptions;
          await apiClients.auditClient.DeleteAuditLog(idOptions);
          this.props.onRefreshList();
        } catch (e) {
          this.setState({ errorMessage: `Audit log deletion failed: ${e}` });
          reportError(e);
        }
        this.setState({ processingDelete: false });
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickSetDefault = async (id: string) => {
    const auditLogs = this.props.auditLogs || {};
    const auditLogItems = auditLogs.items || [];
    const auditLog = auditLogItems.find((c) => c.id == id);
    if (auditLog) {
      const auditLogName = this.getAuditLogName(id);
      const confirmInfo = {
        header: "Set Audit Log as Default",
        content: `Are you sure you want to mark audit log '${auditLogName}' as the default audit log?`,
        onConfirm: async () => {
          try {
            this.setState({ errorMessage: undefined, processingDefault: true, confirmInfo: undefined });
            const req = {
              organization_id: this.props.organization.id,
              auditlog_id: id,
            } as ApiSetDefaultAuditLogRequest;
            await apiClients.auditClient.SetDefaultAuditLog(req);
            this.props.onRefreshList();
          } catch (e) {
            this.setState({ errorMessage: `Marking audit log as default failed: ${e}` });
            reportError(e);
          }
          this.setState({ confirmInfo: undefined, processingDefault: false });
        },
        onDenied: () => this.setState({ confirmInfo: undefined }),
      } as ConfirmInfo;

      this.setState({ confirmInfo: confirmInfo });
    }
  };

  onClickResetDefault = async (id: string) => {
    const auditLogs = this.props.auditLogs || {};
    const auditLogItems = auditLogs.items || [];
    const auditLog = auditLogItems.find((c) => c.id == id);
    if (auditLog) {
      const auditLogName = this.getAuditLogName(id);
      const confirmInfo = {
        header: "Reset Default Audit Log",
        content: `Are you sure you no longer want to use audit log '${auditLogName}' as the default audit log?`,
        onConfirm: async () => {
          try {
            this.setState({ errorMessage: undefined, processingDefault: true, confirmInfo: undefined });
            const req = {
              organization_id: this.props.organization.id,
              auditlog_id: "",
            } as ApiSetDefaultAuditLogRequest;
            await apiClients.auditClient.SetDefaultAuditLog(req);
            this.props.onRefreshList();
          } catch (e) {
            this.setState({ errorMessage: `Marking audit log as non-default failed: ${e}` });
            reportError(e);
          }
          this.setState({ confirmInfo: undefined, processingDefault: false });
        },
        onDenied: () => this.setState({ confirmInfo: undefined }),
      } as ConfirmInfo;

      this.setState({ confirmInfo: confirmInfo });
    }
  };

  onAuditLogUpdated = () => {
    this.onCloseEdit();
    this.props.onRefreshList();
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const showEdit = !!this.state.editId;

    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processingDelete} message="Deleting audit log, please wait..." />
        <Processing active={this.state.processingDefault} message="Changing default audit log, please wait..." />

        {showEdit && (
          <UpdateAuditLogModal
            {...this.props}
            auditlog_id={this.state.editId || ""}
            all_topics={this.props.all_topics}
            onAuditLogUpdated={() => {
              this.onCloseEdit();
              this.props.onRefreshList();
            }}
            onClose={this.onCloseEdit}
          />
        )}

        <AuditLogListView
          {...this.props}
          {...this.state}
          auditLogs={this.props.auditLogs}
          active={!this.state.processingDelete && !this.state.processingDefault}
          onClickEdit={this.onClickEdit}
          onClickSetDefault={this.onClickSetDefault}
          onClickResetDefault={this.onClickResetDefault}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default AuditLogList;
