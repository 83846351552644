//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { PureComponent } from "react";
import { CodeProps } from "react-markdown/lib/ast-to-react";
import CustomCodeBlock from "./CustomCodeBlock";

interface ICodeBlockState {
  copied: boolean;
}

class CodeBlock extends PureComponent<CodeProps, ICodeBlockState> {
  state = {
    copied: false,
  } as ICodeBlockState;

  onCopy = () => {
    this.setState({ copied: true }, () => {
      const setTimeout = window.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState({ copied: false });
        }, 3000);
    });
  };

  render() {
    const match = /language-(\w+)/.exec(this.props.className || "") || [];
    const content = String(this.props.children).replace(/\n$/, "");
    return <CustomCodeBlock value={content} language={match[1]} />;
  }
}

export default CodeBlock;
