//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { CheckIcon, CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import PriceDetailsModal from "./PriceDetailsModal";
import { useFetchTermsAndConditions } from "./useFetchTermsAndConditions";

export const TermsAndConditionsModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const ref = React.useRef(null);
  const [copied, setCopied] = React.useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const exportPDF = () => {
    import("jspdf").then(({ jsPDF }) => {
      if (!ref.current) {
        return;
      }
      const pdf = new jsPDF();
      const el = ref.current as HTMLElement;
      pdf.setFontSize(10);
      const PAGE_CHAR_SIZE = 5500;
      const iterations = Math.floor(el.innerHTML.length / PAGE_CHAR_SIZE);
      for (let i = 0; i <= iterations; i++) {
        const start = i * PAGE_CHAR_SIZE;
        const end = start + PAGE_CHAR_SIZE - 1;
        pdf.text(el.innerText.substring(start, end), 10, 20, { maxWidth: 190 });
        if (end < el.innerHTML.length) {
          pdf.addPage();
        }
      }
      pdf.save("terms-and-conditions.pdf");
    });
  };
  const { data: termsAndConditions } = useFetchTermsAndConditions();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Terms & conditions</Text>
          <Text fontSize="xs" fontWeight="500" color="gray.800">
            Created on {moment(termsAndConditions?.created_at).format("MMM Do YYYY")}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" maxHeight="70vh" overflow="auto" ref={ref} gap="2">
            <ReactMarkdown>{termsAndConditions?.content || ""}</ReactMarkdown>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap="2">
            <Button leftIcon={<DownloadIcon />} onClick={exportPDF}>
              Export PDF
            </Button>
            <Button
              leftIcon={copied ? <CheckIcon /> : <CopyIcon />}
              colorScheme="green"
              onClick={() => {
                navigator.clipboard.writeText(termsAndConditions?.content || "");
                setCopied(true);
              }}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PriceDetailsModal;
