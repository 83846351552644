//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Divider, Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { textToStrings } from "../../api/_util";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ErrorMessage } from "../../ui/_errorMessage";
import { IWizardContext, WizardContext } from "../wizard/Wizard";

export interface ISubscriptionIDViewArgs {
  onChange?: (subscriptionID: string[]) => void;
  onUpdateFieldValidity?: (state: boolean) => void;
  disableChange?: boolean;
  statusMessage?: string | React.ReactNode;
  subscriptionIDs: string[];
}

const SubscriptionIDView = ({ onChange, subscriptionIDs = [], onUpdateFieldValidity, disableChange = false, statusMessage }: ISubscriptionIDViewArgs) => {
  const [subscriptionIDValues, setSubscriptionIDValues] = useState<string[]>(subscriptionIDs);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);

  const context = useContext<IWizardContext>(WizardContext);
  const isValidID = (id: string): boolean => {
    const regex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/);
    return regex.test(id.toLowerCase());
  };

  const isFormValid = (values: string[]): boolean => !isEmpty(values) && !values.find((subscriptionID) => !subscriptionID || !isValidID(subscriptionID));

  useEffect(() => {
    const { existingState } = context;
    const { data = undefined } = existingState || {};
    setSubscriptionIDValues(data || subscriptionIDs);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (disableChange) return;

    const { value } = e.target;
    const subscriptionID = textToStrings(value);
    setSubscriptionIDValues(subscriptionID);
    onChange && onChange(subscriptionID);
  };

  const handleBlur = () => {
    const isValid = isFormValid(subscriptionIDValues);
    onUpdateFieldValidity && onUpdateFieldValidity(isValid);
    setIsValueValid(isValid);

    const { updateWizardState } = context;
    updateWizardState &&
      updateWizardState({
        isValid,
        data: subscriptionIDValues.filter((subscriptionID) => !!subscriptionID),
      });
  };

  return (
    <>
      <Message data-testid="subscriptionIDInfo" warning={disableChange}>
        {!disableChange ? (
          "Please provide the ID's of the Azure subscriptions from which you want to connect to this deployment over a Private Endpoint."
        ) : (
          <span>
            <Grid>
              <Grid.Column width="1" verticalAlign="middle" textAlign="center">
                <Icon name="warning circle" />
              </Grid.Column>
              <Grid.Column width="15">{statusMessage}</Grid.Column>
            </Grid>
          </span>
        )}
      </Message>
      <Divider hidden />
      <Form>
        <Form.TextArea
          error={!isValueValid}
          data-testid="subscriptionIDField"
          label="Azure Subscription IDs (1 per line)"
          placeholder="Enter Azure Subscription IDs"
          onChange={handleChange}
          value={subscriptionIDValues.join("\n")}
          required
          onBlur={handleBlur}
        />

        <Form.TextArea
          data-testid="subscriptionIDExample"
          label="Example for subscription ID addition"
          value={["sample-id-value-1", "sample-id-value-2"].join("\n")}
        />
      </Form>
    </>
  );
};

export default SubscriptionIDView;

interface ISubscriptionIDModalViewArgs extends ISubscriptionIDViewArgs {
  onClose: () => void;
  onSave: (subscriptionIDs: string[]) => Promise<{ error: unknown } | undefined>;
}

const SubscriptionIDModalView = ({ onClose, onChange, onSave, subscriptionIDs, disableChange, statusMessage }: ISubscriptionIDModalViewArgs) => {
  const [subscriptionIDValues, setSubscriptionIDValues] = useState<string[]>(subscriptionIDs);
  const [isValid, updateFieldState] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(undefined);

  const handleChange = (ids: string[]): void => {
    setSubscriptionIDValues(ids);
    onChange && onChange(ids);
  };

  const handleSave = async () => {
    setLoading(true);
    const { error } = (await onSave(subscriptionIDValues)) || {};
    setLoading(false);
    !!error && setError(error);
    if (!error && !loading) onClose();
  };

  return (
    <Modal open onClose={onClose} data-testid="privateNetworkSubscriptionIDViewModal">
      <Modal.Header>Azure Subscription IDs</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />
          <SubscriptionIDView
            onChange={handleChange}
            subscriptionIDs={subscriptionIDValues}
            onUpdateFieldValidity={(state: boolean) => {
              updateFieldState(state);
            }}
            disableChange={disableChange}
            statusMessage={statusMessage}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} />
        <FormActionButtonSave primary disabled={!isValid || disableChange} loading={loading} onClick={handleSave} icon="save" />
      </Modal.Actions>
    </Modal>
  );
};

export { SubscriptionIDModalView };
