//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchIAMProviders = () => {
  const { project } = useProjectDetailsContext();
  const fetchIAMProviders = async () => {
    const listOptions = { context_id: project.id };
    const iamProviders = await apiClients.securityClient.ListIAMProviders(listOptions);
    return iamProviders;
  };
  return useQuery([project.id, "iamProviders"], fetchIAMProviders);
};
