//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useGraphNodeHandlers, useGraphState } from "./GraphProvider";
import { NodeManipulator } from "./Styles";
import { useFileAndNodeModifiers } from "../edit-panel/hooks/useFileAndNodeModifiers";

export const NodeManipulatorMenu = () => {
  const { showNodeManipulator, canvasZoom, position } = useGraphState();
  const { addConnectedNode, deleteNode } = useGraphNodeHandlers();
  const { removeNodeMappingsFromGlobalStore } = useFileAndNodeModifiers();

  if (!showNodeManipulator) return null;

  return (
    <NodeManipulator {...position} {...canvasZoom}>
      <div role="button" onClick={addConnectedNode}>
        <Popup trigger={<Icon name="plus" className="node-action add-node" />} content="Add a new node with an edge" basic />
      </div>
      <div
        role="button"
        onClick={() => {
          deleteNode();
          removeNodeMappingsFromGlobalStore();
        }}
      >
        <Popup position="bottom center" trigger={<Icon name="trash" className="node-action delete-node" />} content="Delete this node" basic />
      </div>
    </NodeManipulator>
  );
};
