//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { create } from "zustand";
import { Node, Edge } from "vis-network";
import {
  DataloaderStore,
  EdgeMappings,
  FileObject,
  NodeMappings,
  NodeExtraValues,
  EdgeExtraValues,
  MigrationJob,
  DataloaderActions,
  DeploymentDataloaderState,
} from "./types";
import { FullItem, OptId } from "vis-data/declarations/data-interface";
import { persist } from "zustand/middleware";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { v4 } from "uuid";
import { nodeNotReadyForImportExtraOptions } from "./graph-modeller/graphOptions";

export const initialState: DeploymentDataloaderState = {
  currentDatabase: { db: undefined, collections: [], graphs: [], dbInfo: undefined },
  databasesList: [],
  name: "",
  files: [],
  nodes: [{ id: v4(), ...nodeNotReadyForImportExtraOptions }],
  edges: [],
  node: null,
  edge: null,
  nodeMappings: {},
  edgeMappings: {},
  editView: undefined,
  graphMode: undefined,
  errorMessage: null,
  migrationJob: { status: "not_started" },
};

export const useDataloaderStore = create<DataloaderStore & DataloaderActions>()(
  persist(
    (set, get) => ({
      deploymentsDataloaders: {},

      // Helper function to update the deploymentsDataloaders object
      updateDeploymentData: (data: Partial<DeploymentDataloaderState>) =>
        set((state) => ({
          ...state,
          deploymentsDataloaders: {
            ...state.deploymentsDataloaders,
            [useDeploymentStore.getState().deployment.id || ""]: {
              ...state.deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""],
              ...data,
            },
          },
        })),
      getDeploymentDataloaderState: () => {
        const state = get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""];
        if (!state) {
          get().updateDeploymentData(initialState);
          return get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""];
        } else {
          return state;
        }
      },
      getMigrationJob: () => get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""].migrationJob,
      getActiveNode: () => get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""].node,
      getActiveEdge: () => get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""].edge,
      setActiveNode: (node: Node | null) => get().updateDeploymentData({ node }),
      setActiveEdge: (edge: Edge | null) => get().updateDeploymentData({ edge }),
      updateFiles: (files: FileObject[]) => get().updateDeploymentData({ files }),
      updateNodeMappings: (nodeMappings: { [id: string]: NodeMappings }) => get().updateDeploymentData({ nodeMappings }),
      updateEdgeMappings: (edgeMappings: { [id: string]: EdgeMappings }) => get().updateDeploymentData({ edgeMappings }),
      setEditView: (editView: "NODE" | "EDGE" | undefined) => get().updateDeploymentData({ editView }),
      setGraphMode: (graphMode: "ADD_EDGE" | undefined) => get().updateDeploymentData({ graphMode }),
      setNodes: (nodes: FullItem<Partial<Record<"id", OptId> & NodeExtraValues>, "id">[]) => get().updateDeploymentData({ nodes }),
      setEdges: (edges: FullItem<Partial<Record<"id", OptId> & EdgeExtraValues>, "id">[]) => get().updateDeploymentData({ edges }),
      setMigrationJob: (newMigrationJob: Partial<MigrationJob>) =>
        get().updateDeploymentData({
          migrationJob: {
            ...get().deploymentsDataloaders[useDeploymentStore.getState().deployment.id || ""].migrationJob,
            ...newMigrationJob,
          },
        }),
      setName: (name: string) => get().updateDeploymentData({ name }),
      resetStore: () => get().updateDeploymentData(initialState),
    }),
    {
      name: "dataloaderState",
    }
  )
);
