//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Modal, Table, Button } from "semantic-ui-react";
import { v4 } from "uuid";
import { ServiceStatus } from "../../../api/ml/v1/ml";

interface IMLServicesModalView {
  close: () => void;
  services: ServiceStatus[];
}

const MLServicesModalView = ({ close, services }: IMLServicesModalView) => {
  return (
    <Modal open onClose={close}>
      <Modal.Header>Services</Modal.Header>
      <Modal.Content>
        <Table striped>
          <Table.Header>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Available</Table.HeaderCell>
            <Table.HeaderCell>Failing</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {services.map(({ type, available, failed }) => (
              <Table.Row key={v4()}>
                <Table.Cell>{type || "-"}</Table.Cell>
                <Table.Cell>{available ? "Yes" : "No"}</Table.Cell>
                <Table.Cell>{failed ? "Yes" : "-"}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MLServicesModalView;
