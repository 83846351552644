//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { useState } from "react";
import { Accordion } from "semantic-ui-react";
import AccordionItem from "./AccordionItem";
import { IAccordionItemViewArgs, IAccordionViewArgs } from "./types";

const StyledAccordion = styled(Accordion)`
  border: 1px solid var(--gray-200) !important;
  box-shadow: none !important;
`;

const AccordionView = ({ accordionConfig = [] }: IAccordionViewArgs) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(-1);
      return;
    }
    setActiveIndex(index);
  };

  if (!accordionConfig.length || !accordionConfig) {
    return null;
  }

  return (
    <StyledAccordion styled data-testid="accordion">
      {accordionConfig.map((config: IAccordionItemViewArgs) => {
        return <AccordionItem key={config.index} active={activeIndex === config.index} {...config} onClick={handleClick} testID={`accordion${config.index}`} />;
      })}
    </StyledAccordion>
  );
};

export default AccordionView;
