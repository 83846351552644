//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions, Project as ApiProject, ProjectList as ApiProjectList } from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectProjectViewArgs extends ISelectProjectState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

const SelectProjectView = ({ ...args }: ISelectProjectViewArgs) =>
  args.projects && args.projects.items ? (
    <Form.Dropdown
      required
      fluid
      selection
      label="Project"
      placeholder="Select Project"
      value={args.selectedProject && args.selectedProject.id}
      onChange={args.updateFormDropdownChange}
      options={args.projects.items.map((i) => {
        return { key: i.id, text: i.name, value: i.id };
      })}
    />
  ) : (
    <Form.Dropdown required fluid selection label="Project" placeholder="Select Project" loading={true} />
  );

interface ISelectProjectProps extends IWithRefreshProps, RouteComponentProps {
  organizationId: string;
  project?: ApiProject;
  select_first?: boolean;
  onProjectUpdated: (projectCount: number, project?: ApiProject) => void;
}

interface ISelectProjectState {
  projects?: ApiProjectList;
  selectedProject?: ApiProject;
}

// Component to select a project
class SelectProject extends Component<ISelectProjectProps, ISelectProjectState> {
  state = {
    providers: undefined,
    selectedProvider: this.props.project,
  } as ISelectProjectState;

  onProjectChange = (e: any, args: DropdownProps) => {
    const projectList = this.state.projects || {};
    const projects = projectList.items || [];
    const selectedProject = projects.find((p) => p.id == args.value);
    this.setState({ selectedProject: selectedProject });
    this.props.onProjectUpdated(projects.length, selectedProject);
  };

  reloadProjects = async () => {
    const req = {
      context_id: this.props.organizationId,
    } as ApiListOptions;
    const list = await apiClients.resourceManagerClient.ListProjects(req);
    const projects = list.items || [];
    this.setState(
      (old) => {
        const update = { projects: list } as ISelectProjectState;
        if (!old.selectedProject && !!this.props.select_first) {
          update.selectedProject = _.first(projects);
        }
        return update;
      },
      () => {
        if (!!this.props.select_first) {
          this.props.onProjectUpdated(projects.length, this.state.selectedProject);
        }
      }
    );
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadProjects);
  }

  render() {
    return <SelectProjectView {...this.state} updateFormDropdownChange={this.onProjectChange} />;
  }
}

export default withRefresh()(SelectProject);
