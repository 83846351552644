//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useEffect, useState } from "react";
import { Database } from "arangojs";
import api from "../../../../api/api";
import { useDataloaderStore } from "../DataloaderStore";
import { useQuery } from "@tanstack/react-query";
import { useDeploymentStore } from "../../../../util/storage/DeploymentStore";

const REFETCH_INTERVAL = 10000;

const getDB = ({ url, databaseName }: { url: string; databaseName: string }) =>
  new Database({
    url,
    databaseName,
    agentOptions: { withCredentials: false },
    auth: {
      token: api.token,
    },
  });

const useArangoClient = (endpoint: string | undefined) => {
  const [isNewDatabaseLoading, setIsNewDatabaseLoading] = useState<boolean>(false);
  const [errorDuringDatabaseCreation, setErrorDuringDatabaseCreation] = useState<string | undefined>(undefined);
  const { id: deploymentId = "" } = useDeploymentStore().deployment;
  const { updateDeploymentData, deploymentsDataloaders } = useDataloaderStore();
  const { currentDatabase } = deploymentsDataloaders[deploymentId];

  const dismissErrorMessage = () => setErrorDuringDatabaseCreation(undefined);

  const setCurrentDatabase = async (selectedDatabase: string) => {
    if (!endpoint) return;

    const url = new URL(endpoint);
    url.port = "";

    const newDb = getDB({
      url: url.toString(),
      databaseName: selectedDatabase,
    });

    try {
      const dbResult = await newDb.get();
      updateDeploymentData({ currentDatabase: { db: !!dbResult ? newDb : undefined, dbInfo: dbResult, collections: [], graphs: [] } });
    } catch {
      updateDeploymentData({ currentDatabase: { db: undefined, dbInfo: undefined, collections: [], graphs: [] } });
    }
  };

  const getGraphsAndCollections = async () => {
    if (!endpoint) return null;
    const { currentDatabase } = useDataloaderStore.getState().getDeploymentDataloaderState();
    const { db } = currentDatabase;
    if (!db) return updateDeploymentData({ currentDatabase: { db: undefined, dbInfo: currentDatabase.dbInfo, collections: [], graphs: [] } });

    try {
      const dbInfo = await db.get();
      const collections = await db.listCollections();
      const graphs = await db.listGraphs();
      const graphsNames = graphs.map((graph) => graph.name);

      updateDeploymentData({ currentDatabase: { db, collections, graphs: graphsNames, dbInfo } });
    } catch {
      updateDeploymentData({ currentDatabase: { db: undefined, collections: [], graphs: [], dbInfo: undefined } });
    }
    return null;
  };

  const setDatabasesList = async () => {
    if (!endpoint) return null;

    const url = new URL(endpoint);
    url.port = "";

    const db = new Database({
      url: url.toString(),
      agentOptions: { withCredentials: false },
      auth: {
        token: api.token,
      },
    });

    try {
      const databasesNames = (await db.listDatabases()).filter((dbName) => dbName !== "_system");
      updateDeploymentData({ databasesList: databasesNames });
    } catch {
      updateDeploymentData({ databasesList: [] });
    }
    return null;
  };

  const createNewDatabase = async (name: string) => {
    if (!endpoint) return { errorMessage: "Endpoint not available" };
    setIsNewDatabaseLoading(true);
    setErrorDuringDatabaseCreation(undefined);

    const url = new URL(endpoint);
    url.port = "";

    try {
      const newDatabase = new Database({
        url: url.toString(),
        agentOptions: { withCredentials: false },
        auth: {
          token: api.token,
        },
      });

      const newDb = await newDatabase.createDatabase(name, {
        users: [{ username: "root" }],
      });

      const dbInfo = await newDb.get();

      updateDeploymentData({ currentDatabase: { db: newDb, collections: [], graphs: [], dbInfo } });
      return { errorMessage: undefined };
    } catch (e) {
      updateDeploymentData({ currentDatabase: { db: undefined, collections: [], graphs: [], dbInfo: undefined } });
      setErrorDuringDatabaseCreation(e.message);
      return { errorMessage: e.message };
    } finally {
      setDatabasesList();
      setIsNewDatabaseLoading(false);
    }
  };

  useEffect(() => {
    if (currentDatabase.dbInfo?.name) setCurrentDatabase(currentDatabase.dbInfo?.name);
  }, []);

  useQuery({
    queryKey: [deploymentId, "databases"],
    queryFn: setDatabasesList,
    enabled: !!endpoint,
    refetchInterval: REFETCH_INTERVAL,
  });

  useQuery({
    queryKey: [deploymentId, "graphs_collections"],
    queryFn: getGraphsAndCollections,
    enabled: !!endpoint,
    refetchInterval: REFETCH_INTERVAL,
  });

  return {
    isNewDatabaseLoading,
    errorDuringDatabaseCreation,
    dismissErrorMessage,
    setCurrentDatabase,
    setDatabasesList,
    createNewDatabase,
  };
};

export { useArangoClient, getDB };
