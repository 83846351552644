//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Parser, parse, unparse } from "papaparse";
import { FileObject, UploadedFileReport, NodeExtraValues, EdgeExtraValues, NodeMappings, EdgeMappings, DocumentMetadataError, IAnyObject } from "../types";
import { useDataloaderStore } from "../DataloaderStore";
import { CollectionMetadata, CollectionType } from "arangojs/collection";
import { FullItem, OptId } from "vis-data/declarations/data-interface";
import { DocumentMetadata } from "arangojs/documents";
import { Database } from "arangojs";
import { delay } from "lodash";

const MAX_ERRORS_LENGTH = 10;
const MAX_CHUNK_MB_IN_BYTES = 1024 * 1024;

type EdgeStoredExtraValues = {
  id?: OptId;
  label?: string;
  from?: string;
  to?: string;
};

type ProcessAndUploadChunkOptions = {
  collections: CollectionMetadata[];
  collectionName: string;
  nodes: FullItem<Partial<Record<"id", OptId> & NodeExtraValues>, "id">[];
  nodeMappings: { [id: string]: NodeMappings };
  edgeMappings: { [id: string]: EdgeMappings };
  edges: FullItem<Partial<Record<"id", OptId> & EdgeExtraValues>, "id">[];
  db?: Database;
};

type ProcessEdgeOptions = {
  selectedFields: string[];
  sourceNodeLabel: string;
  sourceNodePrimaryKey: string;
  targetNodeLabel: string;
  targetNodePrimaryKey: string;
};

type ProcessNodeOptions = {
  primaryKey: string;
  selectedFields: string[];
};

type NodeMappingsWithId = NodeMappings & { id: string };
type EdgeMappingsWithId = EdgeMappings & { id: string };

export const getType = (collectionType: CollectionType | undefined) => {
  if (!collectionType) return undefined;
  if (collectionType === CollectionType.DOCUMENT_COLLECTION) return "Document";
  if (collectionType === CollectionType.EDGE_COLLECTION) return "Edge";
};

const filterObjectByPropertyNames = (object: IAnyObject, propertyNames: string[]) =>
  propertyNames.reduce((filteredObject, propertyName) => {
    if (object.hasOwnProperty(propertyName)) {
      filteredObject[propertyName] = object[propertyName];
    }
    return filteredObject;
  }, {} as IAnyObject);

const uploadChunk = async (chunk: any[], options: ProcessAndUploadChunkOptions) => {
  const { collectionName, db } = options;
  const errorResults: DocumentMetadataError[] = [];

  try {
    const results = (await db?.collection(collectionName).saveAll(chunk, { returnNew: true })) as (DocumentMetadata & DocumentMetadataError)[];

    results.forEach((result, index) => {
      if (result.error) {
        errorResults.push({ ...result, processedDocument: chunk[index] });
      }
    });
  } catch (e) {
    const hasErrorMessage = e.errorMessage && typeof e.errorMessage === "string";
    chunk.forEach((document) => {
      errorResults.push({
        processedDocument: document,
        error: true,
        errorMessage: hasErrorMessage ? e.errorMessage : `Failed to push to collection ${collectionName}`,
        errorNum: e.errorNum,
      });
    });
  }

  return errorResults;
};

const processNodeDocument = (document: IAnyObject, nodeOptions: ProcessNodeOptions) => {
  const { selectedFields, primaryKey } = nodeOptions;
  const processedDocument = { ...filterObjectByPropertyNames(document, selectedFields), _key: `${document[primaryKey]}` };

  return processedDocument;
};

const processEdgeDocument = (document: IAnyObject, edgeOptions: ProcessEdgeOptions) => {
  const { selectedFields, sourceNodeLabel, sourceNodePrimaryKey, targetNodeLabel, targetNodePrimaryKey } = edgeOptions;
  const processedDocument = {
    ...filterObjectByPropertyNames(document, selectedFields),
    _from: `${sourceNodeLabel}/${document[sourceNodePrimaryKey]}`,
    _to: `${targetNodeLabel}/${document[targetNodePrimaryKey]}`,
  };

  return processedDocument;
};

const processNodeChunk = (chunk: any[], nodeOptions: ProcessNodeOptions) => chunk.map((document) => processNodeDocument(document, nodeOptions));
const processEdgeChunk = (chunk: any[], edgeOptions: ProcessEdgeOptions) => chunk.map((document) => processEdgeDocument(document, edgeOptions));

export const getNodeOptions = (options: ProcessAndUploadChunkOptions) => {
  const { collectionName, nodes, nodeMappings } = options;
  const { id: nodeId = "" } = nodes.find((node) => node.label === collectionName) || {};
  const { primaryKey = "", selectedFields = [] } = nodeMappings[nodeId] || {};

  return { primaryKey, selectedFields };
};

export const getEdgeOptions = (options: ProcessAndUploadChunkOptions) => {
  const { edges, collectionName, edgeMappings, nodes } = options;
  const correspondingEdge = edges.find((edge) => edge.label === collectionName) as
    | FullItem<Partial<Record<"id", OptId> & EdgeStoredExtraValues>, "id">
    | undefined;

  const { id: edgeId = "", from: sourceNodeId, to: targetNodeId } = correspondingEdge || {};

  const { sourceNodePrimaryKey = "", targetNodePrimaryKey = "", selectedFields = [] } = edgeMappings[edgeId] || {};

  const { label: sourceNodeLabel = "" } = nodes.find((node) => node.id === sourceNodeId) || {};
  const { label: targetNodeLabel = "" } = nodes.find((node) => node.id === targetNodeId) || {};

  return {
    selectedFields,
    sourceNodeLabel,
    sourceNodePrimaryKey,
    targetNodeLabel,
    targetNodePrimaryKey,
  };
};

const processAndUploadNodeChunk = async (chunk: any[], processOptions: ProcessNodeOptions, uploadOptions: ProcessAndUploadChunkOptions) => {
  const processedNode = processNodeChunk(chunk, processOptions);
  const size = new TextEncoder().encode(JSON.stringify(processedNode)).length * 2;

  const errorResults = await uploadChunk(processedNode, uploadOptions);

  return { errorResults, size };
};

const processAndUploadEdgeChunk = async (chunk: any[], processOptions: ProcessEdgeOptions, uploadOptions: ProcessAndUploadChunkOptions) => {
  const processedNode = processEdgeChunk(chunk, processOptions);
  const size = new TextEncoder().encode(JSON.stringify(processedNode)).length * 2;
  const errorResults = await uploadChunk(processedNode, uploadOptions);

  return { errorResults, size };
};

const updateFileUploadProgress = (file: FileObject, progress: number, collectionName: string) => {
  const { getDeploymentDataloaderState, setMigrationJob } = useDataloaderStore.getState();
  const { migrationJob } = getDeploymentDataloaderState();

  if (!migrationJob.migrationCancelled) {
    setMigrationJob({
      status: "files_upload",
      fileBeingUploaded: {
        name: file.name,
        collectionName: collectionName,
        progress,
      },
    });
  } else {
    setMigrationJob({
      status: "not_started",
      fileBeingUploaded: undefined,
    });
  }
};

const uploadFile = async (
  file: FileObject,
  collections: CollectionMetadata[],
  collectionName: string,
  updateProgress: (file: FileObject, progress: number, collectionName: string) => void
) => {
  const { nodeMappings, edgeMappings, nodes, edges, currentDatabase } = useDataloaderStore.getState().getDeploymentDataloaderState();
  const { db } = currentDatabase;
  const correspondingCollection = collections.find((collection) => collection.name === collectionName);
  const collectionType = correspondingCollection?.type;

  updateProgress(file, 0, collectionName);

  const errors: DocumentMetadataError[] = [];
  const options: ProcessAndUploadChunkOptions = { collectionName, collections, nodeMappings, edgeMappings, nodes, edges, db };
  const csvString = await file.file.text();
  const totalDocuments = Math.ceil(csvString.split("\n").length);

  let processedDocuments = 0;
  let chunk: any[] = [];
  let chunkSizeInBytes = 0;
  let totalProcessedDataBytes = 0;

  const processAndUploadChunk = async (
    results: any,
    parser: Parser,
    processAndUploadFn: () => Promise<{ errorResults: DocumentMetadataError[]; size: number }>
  ) => {
    const dataBytes = new TextEncoder().encode(JSON.stringify(results)).length * 2;
    chunkSizeInBytes += dataBytes;
    processedDocuments++;
    chunk.push(results);

    if (chunkSizeInBytes >= MAX_CHUNK_MB_IN_BYTES) {
      parser.pause();
      if (useDataloaderStore.getState().getMigrationJob()?.migrationCancelled) {
        parser.abort();
        return;
      }
      const { errorResults, size } = await processAndUploadFn();
      errors.push(...errorResults);
      totalProcessedDataBytes += size;

      chunk = [];
      chunkSizeInBytes = 0;
      const currentProgress = Math.floor((processedDocuments / totalDocuments) * 100);
      updateProgress(file, currentProgress, collectionName);

      parser.resume();
    }
  };

  let processing: Promise<void> = new Promise<void>(() => {});

  const processAndUploadData = async (processFn: () => Promise<{ errorResults: DocumentMetadataError[]; size: number }>) => {
    processing = new Promise<void>((resolve) => {
      parse<any>(csvString, {
        header: true,
        skipEmptyLines: true,
        step: async (results, parser) => {
          await processAndUploadChunk(results.data, parser, processFn);
        },

        complete: async () => {
          if (chunk.length > 0) {
            const { errorResults, size } = await processFn();
            totalProcessedDataBytes += size;
            errors.push(...errorResults);
          }

          updateProgress(file, 100, collectionName);
          delay(resolve, 200);
        },
      });
    });
  };

  if (collectionType === CollectionType.DOCUMENT_COLLECTION) {
    const nodeOptions: ProcessNodeOptions = getNodeOptions(options);
    await processAndUploadData(() => processAndUploadNodeChunk(chunk, nodeOptions, options));
  } else if (collectionType === CollectionType.EDGE_COLLECTION) {
    const edgeOptions: ProcessEdgeOptions = getEdgeOptions(options);
    await processAndUploadData(() => processAndUploadEdgeChunk(chunk, edgeOptions, options));
  }

  await processing;

  return { errors, successfulUploads: processedDocuments - errors.length, size: totalProcessedDataBytes };
};

const generateReportUrl = (errors: DocumentMetadataError[]) => {
  const errorProcessedElements = errors.map((error) => ({ ...error.processedDocument, errorMessage: error.errorMessage }));
  const csv = unparse(errorProcessedElements);
  const csvDataBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  return URL.createObjectURL(csvDataBlob);
};

const uploadFiles = async () => {
  const { setMigrationJob, getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { files, currentDatabase, nodeMappings, edgeMappings, nodes, edges } = getDeploymentDataloaderState();
  const { db } = currentDatabase;

  const nodeMappingsToFillCollections: NodeMappingsWithId[] = Object.keys(nodeMappings).flatMap((key) => ({ ...nodeMappings[key], id: key }));
  const edgeMappingsToFillCollections: EdgeMappingsWithId[] = Object.keys(edgeMappings).flatMap((key) => ({ ...edgeMappings[key], id: key }));

  const mappingsToFillCollections = [...nodeMappingsToFillCollections, ...edgeMappingsToFillCollections];
  const nodesAndEdges = [...nodes, ...edges];

  const collections = (await db?.listCollections()) || [];
  const uploadedFilesReports: UploadedFileReport[] = [];

  const generateFileReport = ({
    file,
    errors,
    collectionName,
    successfulUploads,
    collectionType,
    size,
  }: {
    file: FileObject;
    errors: DocumentMetadataError[];
    collectionName: string;
    successfulUploads: number;
    collectionType: CollectionType | undefined;
    size: number;
  }): UploadedFileReport => {
    return {
      name: file.name,
      collectionName,
      errors: errors.slice(0, MAX_ERRORS_LENGTH),
      fullReportURL: errors.length > MAX_ERRORS_LENGTH ? generateReportUrl(errors) : undefined,
      successfulUploads,
      collectionType,
      ignored: false,
      size,
    };
  };

  setMigrationJob({
    status: "files_upload",
    totalFilesToUpload: mappingsToFillCollections.length,
  });

  for (const mapping of mappingsToFillCollections) {
    if (!useDataloaderStore.getState().getMigrationJob()?.migrationCancelled) {
      const correspondingFile = files.find((file) => file.id === mapping.attachedFile.id);
      const correspondingCollection = nodesAndEdges.find((nodeOrEdge) => nodeOrEdge.id === mapping.id)?.label;

      if (!!correspondingFile && !!correspondingCollection) {
        const { errors, successfulUploads, size } = await uploadFile(correspondingFile, collections, correspondingCollection, updateFileUploadProgress);
        const collectionType = collections.find((collection) => collection.name === correspondingCollection)?.type;

        const uploadedFileReport = generateFileReport({
          file: correspondingFile,
          errors,
          collectionName: correspondingCollection,
          successfulUploads,
          collectionType,
          size,
        });

        uploadedFilesReports.push(uploadedFileReport);

        if (!useDataloaderStore.getState().getMigrationJob()?.migrationCancelled) {
          setMigrationJob({
            status: "files_upload",
            filesAlreadyUploaded: uploadedFilesReports,
          });
        }
      }
    }
  }

  if (!useDataloaderStore.getState().getMigrationJob()?.migrationCancelled) {
    setMigrationJob({ status: "files_upload", fileBeingUploaded: undefined });
  }
};

export { uploadFile, uploadFiles, generateReportUrl, MAX_ERRORS_LENGTH, delay };
