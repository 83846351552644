//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import { Form } from "semantic-ui-react";

export const EdgeManipulator = styled.div`
  position: absolute;
  left: ${(props: any) => props.left};
  top: ${(props: any) => props.top};
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 99999;

  & .edge-action {
    cursor: pointer;
    display: block;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 37px;
    margin: 10px;
    border: 2px solid var(--green-600);
    box-shadow: 0px 2px 8px -3px var(--green-600);

    &:hover {
      background-color: var(--gray-200);
    }
  }

  & .delete-edge {
    border-color: var(--red-700);
  }
`;

export const NodeManipulator = styled.div`
  position: absolute;
  left: ${(props: any) => props.left};
  top: ${(props: any) => props.top};
  z-index: 99999;
  transform: scale(${(props: any) => (props.scale < 2 ? 1.2 : 1.2)});

  & .node-action {
    cursor: pointer;
    display: block;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 37px;
    border: 2px solid var(--green-600);
    box-shadow: 0px 2px 8px -3px var(--green-600);
    margin: 10px;

    &:hover {
      background-color: var(--gray-200);
    }
  }

  & .delete-node {
    border-color: var(--red-700);
  }

  & .edit-node {
    transform: translate(20px, 0px);
  }
`;

export const GraphCanvas = styled.div`
  background-color: var(--gray-50);
  height: 100%;
  width: 100%;
  position: relative;

  & #${(props: { canvasRenderTargetID: string }) => props.canvasRenderTargetID} {
    height: calc(100vh - 500px);
  }
`;

export const GraphToolBar = styled.div`
  margin: 20px auto;
  width: 95%;
  padding: 10px;
  background: white;
  box-shadow: 0 2px 5px -2px var(--gray-400);
  border-radius: 3px;
`;

export const StyledGraphHint = styled.div`
  margin: 20px auto;
  width: 95%;
  padding: 20px 30px;
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px -2px var(--gray-400);
  border-radius: 3px;
  bottom: 0;
  margin: 20px auto;
  z-index: 999999;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledForm = styled(Form)`
  margin-bottom: 10px !important;
`;
