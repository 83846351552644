//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useCallback, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import styled from "@emotion/styled";

type NotificationTypes = "email" | "sms";

type Config = {
  ADD_ATTR?: string[] | undefined;
  ADD_DATA_URI_TAGS?: string[] | undefined;
  ADD_TAGS?: string[] | undefined;
  ADD_URI_SAFE_ATTR?: string[] | undefined;
  ALLOW_DATA_ATTR?: boolean | undefined;
  ALLOW_UNKNOWN_PROTOCOLS?: boolean | undefined;
  ALLOWED_ATTR?: string[] | undefined;
  ALLOWED_TAGS?: string[] | undefined;
  ALLOWED_URI_REGEXP?: RegExp | undefined;
  FORBID_ATTR?: string[] | undefined;
  FORBID_CONTENTS?: string[] | undefined;
  FORBID_TAGS?: string[] | undefined;
  FORCE_BODY?: boolean | undefined;
  IN_PLACE?: boolean | undefined;
  KEEP_CONTENT?: boolean | undefined;
  NAMESPACE?: string | undefined;
  PARSER_MEDIA_TYPE?: string | undefined;
  RETURN_DOM_FRAGMENT?: boolean | undefined;
  RETURN_DOM_IMPORT?: boolean | undefined;
  RETURN_DOM?: boolean | undefined;
  RETURN_TRUSTED_TYPE?: boolean | undefined;
  SAFE_FOR_TEMPLATES?: boolean | undefined;
  SANITIZE_DOM?: boolean | undefined;
  USE_PROFILES?:
    | false
    | {
        mathMl?: boolean | undefined;
        svg?: boolean | undefined;
        svgFilters?: boolean | undefined;
        html?: boolean | undefined;
      }
    | undefined;
  WHOLE_DOCUMENT?: boolean | undefined;
};

type ElementHandler = {
  nodeName: string;
  className: string;
};

type iHTMLRendererArgs = {
  type?: NotificationTypes;
  renderContent: string;
  config?: Config;
};

export const StyledNotificationFrame = styled("iframe")`
  width: 100%;
  height: 400px;
  border: 0;
`;

const HTMLRenderer = ({ renderContent, config, type }: iHTMLRendererArgs) => {
  const DEFAULT_CONFIG = {
    WHOLE_DOCUMENT: false,
    FORBID_TAGS: ["script"],
    FORBID_ATTR: ["on*"],
  };

  const { sanitize, isSupported, addHook, removeAllHooks } = DOMPurify;
  const [sanitizedHTML, setSanitizedHTML] = useState<string>("");

  const afterSanitizeAttributes = useCallback(
    (currentNode: Element) => {
      if (currentNode.nodeName.toLowerCase() === "a") {
        currentNode.setAttribute("target", "_blank");
      }
    },
    [renderContent]
  );

  const uponSanitizeElement = useCallback(
    (currentNode: Element) => {
      if (type === "email") {
        const ELEMENTS_TO_REMOVE: ElementHandler[] = [
          {
            nodeName: "a",
            className: "logo-link",
          },
          {
            nodeName: "a",
            className: "button",
          },
          {
            nodeName: "p",
            className: "contact-hint",
          },
          {
            nodeName: "div",
            className: "footer__content",
          },
        ];

        ELEMENTS_TO_REMOVE.forEach(({ nodeName, className }: ElementHandler) => {
          if (currentNode.nodeName.toLowerCase() === nodeName && currentNode.className.includes(className)) {
            currentNode.remove();
          }
        });

        // Special cases for the logo and contact hint
        if (currentNode.nodeName.toLowerCase() === "center" && currentNode) {
          const castedNode = currentNode as HTMLElement;
          castedNode.style.textAlign = "left";
        }

        // override background color of body to white
        if (currentNode.nodeName.toLowerCase() === "body") {
          const castedNode = currentNode as HTMLElement;
          castedNode.style.backgroundColor = "white";
        }

        if (currentNode.nodeName.toLowerCase() === "td" && currentNode) {
          const castedNode = currentNode as HTMLElement;
          castedNode.style.paddingLeft = "0";
        }
      }
    },
    [renderContent]
  );

  useEffect(() => {
    addHook("afterSanitizeAttributes", afterSanitizeAttributes);
    addHook("uponSanitizeElement", uponSanitizeElement);

    const content = sanitize(renderContent, { ...DEFAULT_CONFIG, ...config }).toString();
    setSanitizedHTML(content);

    return () => {
      removeAllHooks();
    };
  }, [renderContent]);

  if (!isSupported) {
    return <StyledNotificationFrame srcDoc={renderContent} title="unsupported-frame" sandbox="true allow-popups allow-popups-to-escape-sandbox" />;
  }

  return <StyledNotificationFrame srcDoc={sanitizedHTML} title="supported-frame" sandbox="true allow-popups allow-popups-to-escape-sandbox" />;
};

export default HTMLRenderer;
