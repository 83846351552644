//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, ButtonGroup, Popup } from "semantic-ui-react";
import { METRICS_VALUES, SERVERS_VALUES } from "./useMetricsUsageApi";
import { SERVERS, METRICS } from "./MetricsView";

const ServerSelector = ({
  loading,
  selectedServer,
  selectedMetric,
  setSelectedMetric,
  setSelectedServer,
}: {
  loading: boolean;
  selectedMetric: METRICS_VALUES;
  selectedServer: SERVERS_VALUES;
  setSelectedMetric: React.Dispatch<React.SetStateAction<METRICS_VALUES>>;
  setSelectedServer: React.Dispatch<React.SetStateAction<SERVERS_VALUES>>;
}) => {
  return (
    <ButtonGroup>
      <Popup
        position="top center"
        content="DB-Servers are the ones where the data is actually hosted. They host shards of data and using synchronous replication a DB-Server may either be leader or follower for a shard. Document operations are first applied on the leader and then synchronously replicated to all followers."
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedServer === SERVERS.DBSERVER}
            onClick={() => setSelectedServer(SERVERS.DBSERVER)}
            className={selectedServer === SERVERS.DBSERVER ? "active" : ""}
          >
            DB Server
          </Button>
        }
      />
      <Popup
        position="top center"
        content="Coordinators should be accessible from the outside. These are the ones the clients talk to. They coordinate cluster tasks like executing queries and running Foxx services. They know where the data is stored and optimize where to run user-supplied queries or parts thereof. Coordinators are stateless and can thus easily be shut down and restarted as needed."
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedServer === SERVERS.COORDINATOR}
            onClick={() => {
              if (selectedServer === SERVERS.DBSERVER && selectedMetric === METRICS.DISK) setSelectedMetric(METRICS.CPU);
              setSelectedServer(SERVERS.COORDINATOR);
            }}
            className={selectedServer === SERVERS.COORDINATOR ? "active" : ""}
          >
            Coordinator
          </Button>
        }
      />
    </ButtonGroup>
  );
};

export default ServerSelector;
