//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchDefaultDBVersion = () => {
  const { selectedOrganization } = useDashboardContext();
  const fetchDefaultVersion = async () => {
    const defaultVersion = await apiClients.dataClient.GetDefaultVersion();
    return defaultVersion;
  };
  return useQuery([selectedOrganization.id, "defaultVersion"], fetchDefaultVersion);
};
