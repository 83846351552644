//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
// Ewout Prangsma
//

import { filter, isEmpty, sortBy } from "lodash";
import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import {
  Deployment as ApiDeployment,
  DeploymentList as ApiDeploymentList,
  Organization as ApiOrganization,
  PrepaidDeployment as ApiPrepaidDeployment,
  PrepaidDeploymentList as ApiPrepaidDeploymentList,
  Project as ApiProject,
} from "../../api/lib";
import { Loading } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import { DeploymentCardView } from "./DeploymentCardView";
import PrepaidDeploymentCard from "../prepaid/PrepaidDeploymentCard";
import { IDataVolumeStats, getDataVolumeStatus } from "./DataVolumeView";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

// Interface describing the deployment list
interface ICardsView extends IWithRefreshProps {
  organization: ApiOrganization;
  project: ApiProject;
  deployments: ApiDeployment[];
  prepaidDeployments: ApiPrepaidDeployment[];
  deploymentDataVolumeStats: IDataVolumeStats[];
  loading: boolean;
  onClickView: (deploymentID: string) => void;
  onOpenDatabase: (endpoint: string) => void;
}

const CardsView = ({ ...args }: ICardsView) => {
  const itemsPerRow = window.innerWidth > 1280 ? 3 : window.innerWidth > 992 ? 2 : 1;
  const { onResumeDeployment } = useProjectDetailsContext();
  return (
    <Card.Group itemsPerRow={itemsPerRow}>
      {args.prepaidDeployments.map((item) => (
        <PrepaidDeploymentCard
          {...args}
          key={item.id}
          item={item}
          onViewDeployment={args.onClickView}
          isDiskNearingCapacity={args.deploymentDataVolumeStats.some((deployment) => deployment.id === item.id)}
        />
      ))}
      {args.deployments.map((item) => (
        <DeploymentCardView
          {...args}
          key={item.id}
          isDiskNearingCapacity={args.deploymentDataVolumeStats.some((deployment) => deployment.id === item.id)}
          item={item}
          onClickView={() => args.onClickView(item.id || "")}
          onOpenDatabase={() => args.onOpenDatabase((item.status && item.status.endpoint_default) || "")}
          onResumeDeployment={() => onResumeDeployment(item.id || "")}
        />
      ))}
    </Card.Group>
  );
};

const EmptyView = () => <div>No deployment inside this project</div>;

// Interface describing the deployment list view arguments
export interface IDeploymentListViewArgs extends IWithRefreshProps {
  loading: boolean;
  organization: ApiOrganization;
  project: ApiProject;
  deployments?: ApiDeploymentList;
  prepaidDeployments: ApiPrepaidDeploymentList | undefined;
  onClickView: (deploymentID: string) => void;
  onOpenDatabase: (endpoint: string) => void;
  onResumeDeployment: (deploymentID: string) => void;
}

const DeploymentListView = ({ ...args }: IDeploymentListViewArgs) => {
  const deploymentDataVolumeStats = [] as IDataVolumeStats[];
  if (!args.deployments) {
    return <Loading />;
  }
  const deployments = args.deployments.items || [];

  deployments.forEach((deployment) => {
    const stats = getDataVolumeStatus(deployment);
    if (stats.isDiskNearingCapacity) {
      deploymentDataVolumeStats.push({ ...stats, id: deployment.id });
    }
  });
  const prepaidDeployments = (args.prepaidDeployments || {}).items || [];
  const sortedDeployments = sortBy(deployments, ["name"]);
  const sortedPrepaidDeployments = sortBy(prepaidDeployments, ["name"]);
  const isNotAttached = (x: ApiPrepaidDeployment) => {
    const status = x.status || {};
    return isEmpty(status.deployment_id);
  };
  const filteredPrepaidDeployments = filter(sortedPrepaidDeployments, isNotAttached);
  if (isEmpty(deployments) && isEmpty(filteredPrepaidDeployments)) {
    return <EmptyView />;
  }

  return (
    <CardsView
      {...args}
      deployments={sortedDeployments}
      deploymentDataVolumeStats={deploymentDataVolumeStats}
      prepaidDeployments={filteredPrepaidDeployments}
      loading={args.loading}
    />
  );
};

// Interface decribing the properties of the deployment list component
interface IDeploymentListProps extends IWithRefreshProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  deployments: ApiDeploymentList | undefined;
  prepaidDeployments: ApiPrepaidDeploymentList | undefined;
  onDeploymentSelected: (id: string) => void;
  onResumeDeployment: (id: string) => void;
}

// The component to show the ca certificates inside a project as a list.
class DeploymentList extends Component<IDeploymentListProps> {
  onClickView = (deploymentID: string) => {
    this.props.onDeploymentSelected(deploymentID);
  };

  onResumeDeployment = (deploymentID: string) => {
    this.props.onResumeDeployment(deploymentID);
  };

  onOpenDatabase = (endpoint: string) => {
    window.open(endpoint);
  };

  render() {
    return (
      <DeploymentListView {...this.props} onClickView={this.onClickView} onOpenDatabase={this.onOpenDatabase} onResumeDeployment={this.onResumeDeployment} />
    );
  }
}

export default withRefresh()(DeploymentList);
