//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Popup, PopupProps } from "semantic-ui-react";
import { Routes } from "../../routes";
import { FlexBox } from "../../ui/_flex";

interface IRedirectToBillingPopover {
  organizationId: string;
  popupText?: string;
  popupPosition?: PopupProps["position"];
  children?: React.ReactNode;
  disabled?: boolean;
  content?: React.ReactNode;
}

const RedirectToBillingPopover = ({ ...props }: IRedirectToBillingPopover) => {
  const history = useHistory();
  const { popupText, organizationId, popupPosition = "bottom center", children, disabled } = props;
  const redirectToBilling = () => history.push(Routes.dashboard_organization_billingWithId(organizationId));
  const content = props.content ? (
    props.content
  ) : (
    <FlexBox direction="column" align="center">
      <p className="para">{popupText || "This feature requires a payment method."}</p>
      <p className="para">Upgrade your account to immediately unlock all features.</p>
      <Button primary content="Unlock all features" onClick={redirectToBilling} />
    </FlexBox>
  );
  return <Popup hoverable disabled={disabled} position={popupPosition} on="hover" size="large" trigger={<span>{children}</span>} content={content} />;
};

export default RedirectToBillingPopover;
