import React from "react";
import { Modal } from "semantic-ui-react";
import { Processing } from "../../ui/_processing";
import { ErrorMessage } from "../../ui/_errorMessage";
import { CreateView } from "./CreatePaymentMethod";
import { FlexBox } from "../../ui/_flex";
import { TextLink } from "../../ui/_textlink";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ProviderList, CreatePaymentMethodRequest, PreparedPaymentMethod, Provider } from "../../api/lib";

export interface IPaymentInfo {
  onClickSave: () => void;
}

interface IAddPaymentMethodModal {
  isOpen: boolean;
  loadingPaymentMethods: boolean;
  creatingPaymentMethods: boolean;
  errorMessage?: string;
  onDismissErrorMessage: () => void;
  providersList: ProviderList;
  onProviderChange: (id: string) => Promise<void>;
  onCreate: (req: CreatePaymentMethodRequest) => Promise<void>;
  onCanCreate: (canCreate: boolean) => void;
  onSaveCanceled: () => void;
  paymentInfoRef: (elem: IPaymentInfo) => IPaymentInfo;
  preparedPaymentMethod?: PreparedPaymentMethod;
  provider: Provider;
  providerId: string;
  onClickBack: () => void;
  onClickSave: () => void;
  disableSaveButton: boolean;
  onSkip: () => void;
}

const AddPaymentMethodModal = ({
  isOpen,
  loadingPaymentMethods,
  creatingPaymentMethods,
  errorMessage,
  onDismissErrorMessage,
  providersList,
  onProviderChange,
  onCreate,
  onCanCreate,
  onSaveCanceled,
  paymentInfoRef,
  preparedPaymentMethod,
  provider,
  providerId,
  onClickBack,
  onClickSave,
  disableSaveButton,
  onSkip,
}: IAddPaymentMethodModal) => {
  return (
    <Modal open={isOpen}>
      <Modal.Header>Add a payment method</Modal.Header>
      <Modal.Content>
        <Processing active={loadingPaymentMethods} message="Preparing payment method" />
        <Processing active={creatingPaymentMethods} message="Creating payment method" />
        <ErrorMessage message={errorMessage} active={!!errorMessage} onDismiss={onDismissErrorMessage} />
        {!loadingPaymentMethods && (
          <CreateView
            list={providersList}
            onProviderChange={onProviderChange}
            onCreate={onCreate}
            onCanCreate={onCanCreate}
            onSaveCanceled={onSaveCanceled}
            paymentInfoRef={paymentInfoRef}
            preparedPaymentMethod={preparedPaymentMethod}
            provider={provider}
            provider_id={providerId}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <FlexBox justify="space-between" align="center">
          <TextLink onClick={onSkip} label="Skip process for now" />
          <div>
            <FormActionButtonCancel labelPosition="left" title="Back" icon="arrow left" onClick={onClickBack} />
            <FormActionButtonSave onClick={onClickSave} disabled={disableSaveButton} primary data-bluesnap="submitButton" />
          </div>
        </FlexBox>
      </Modal.Actions>
    </Modal>
  );
};

export default AddPaymentMethodModal;
