//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import _ from "lodash";

export interface Timestamp extends Date {
  //TODO if needed
}

export interface Duration {
  //TODO
}

export const durationAsDays = (x: Duration): number => {
  const s = _.toNumber(_.toString(x).replace("s", ""));
  return moment.duration(s, "seconds").asDays();
};

export const daysToDuration = (x: number): Duration => {
  return `${x * 24}h`;
};

export const durationAsHours = (x: Duration): number => {
  const s = _.toNumber(_.toString(x).replace("s", ""));
  return moment.duration(s, "seconds").asHours();
};

export const hoursToDuration = (x: number): Duration => {
  return `${x}h`;
};
