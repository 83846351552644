//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { DeploymentMetrics_Timeseries } from "../../../api/lib";
import { flatMap, groupBy, forEach, sortBy } from "lodash";
import { FlexBox } from "../../../ui/_flex";
import { scaleOrdinal } from "d3-scale";
import { Timestamp } from "../../../api/googleTypes";
import { Box } from "../../../ui/_box";
import moment from "moment";
import { DATE_FORMAT } from "./useMetricsUsageApi";
import { NameType, Payload } from "recharts/types/component/DefaultTooltipContent";
import { Message } from "semantic-ui-react";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { RANGES } from "./MetricsView";

const customColorArray = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
interface TransformedDataEntry {
  timestamp: Timestamp;
  [serverId: string]: number | Date | null;
}

const MonitoringTooltip = ({ usageLabel, active, payload }: { usageLabel: string; active?: boolean; payload?: Array<Payload<number, NameType>> }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <Box overflowY="auto" height="300px">
      {payload
        .map((pointInfo) => {
          const { payload, name, value = 0 } = pointInfo;
          const { timestamp } = payload;

          return (
            <Box key={name} backgroundColor="white" padding={"10px"} borderRadius={"4px"} boxShadow="0 0 4px 0 var(--grey-100)">
              <div>
                <b>{name}</b>
              </div>
              <div>
                {`${usageLabel}`}: <b>{value.toFixed(2)}%</b>
              </div>
              <div>
                Local Date: <b>{moment(timestamp).format(DATE_FORMAT)}</b>
              </div>
              <div>
                UTC Date: <b>{moment(timestamp).utc().format(DATE_FORMAT)}</b>
              </div>
            </Box>
          );
        })
        .sort()}
    </Box>
  );
};

const MetricsChart = ({
  chartData,
  usageLabel,
  selectedServerIds,
  selectedRange,
}: {
  chartData: DeploymentMetrics_Timeseries[];
  usageLabel: string;
  selectedServerIds: string[];
  selectedRange: number;
}) => {
  const colorScale = scaleOrdinal().range(customColorArray);

  const transformData = (inputData: DeploymentMetrics_Timeseries[]): TransformedDataEntry[] => {
    const transformedData: TransformedDataEntry[] = [];

    // Iterate over each server data and flatten the samples array
    const flatSamples = flatMap(inputData, (serverData) =>
      serverData?.samples?.map((sample) => ({
        server_id: serverData.server_id,
        timestamp: sample.timestamp,
        value: sample.value,
      }))
    );

    const groupedData = groupBy(flatSamples, "timestamp");

    // Iterate over the grouped data and create the final transformed data
    forEach(groupedData, (samples, timestamp) => {
      const entry: TransformedDataEntry = { timestamp: new Date(timestamp) };

      // Iterate over each sample and populate the entry
      forEach(samples, (sample) => {
        entry[sample?.server_id || ""] = sample?.value || 0;
      });

      transformedData.push(entry);
    });
    const sortedTransformedData = sortBy(transformedData, "timestamp");

    return sortedTransformedData;
  };

  const dateFormatMap: { [key: number]: string } = {
    [RANGES.WEEKS_1]: "MMM DD, HH:mm",
    [RANGES.DAYS_1]: "DD, HH:mm",
    [RANGES.HOURS_1]: "HH:mm",
    [RANGES.MINUTES_10]: "HH:mm",
  };

  const dateFormat = dateFormatMap[selectedRange];

  if (!chartData.length) return <Message info>No metrics available</Message>;

  return (
    <FlexBox>
      <ResponsiveContainer minHeight={400}>
        <LineChart data={transformData(chartData)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" tickFormatter={(timestamp) => moment(timestamp).format(dateFormat)} />
          <YAxis
            domain={[0, 100]}
            label={{
              value: `${usageLabel} %`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
          />
          <Tooltip content={<MonitoringTooltip usageLabel={usageLabel} />} offset={-5} wrapperStyle={{ pointerEvents: "all" }} />
          <ReferenceLine y={90} stroke="var(--red-700)" strokeDasharray="3 3" label={{ position: "left", value: "90", fill: "var(--gray-800)" }} />
          {selectedServerIds.map((id) => (
            <Line key={id} dataKey={id} name={id} stroke={`${colorScale(id)}`} type="monotone" />
          ))}
          <Legend wrapperStyle={{ overflowX: "auto", whiteSpace: "nowrap" }} />
        </LineChart>
      </ResponsiveContainer>
    </FlexBox>
  );
};

export default MetricsChart;
