//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { GraphToolBar, StyledGraphHint } from "./Styles";
import { useDataloaderStore } from "../DataloaderStore";
import { useGraphNodeHandlers } from "./GraphProvider";
import { useStartImportConditions } from "./hooks/useStartImportConditions";
import { v4 } from "uuid";
import { Box } from "../../../../ui/_box";

const NodeConnectionHint = ({ onBack }: { onBack: () => void }) => {
  return (
    <StyledGraphHint>
      <FlexBox justify="space-between" align="center">
        <div>
          <Icon name="info circle" />
          Click on any node to self reference or drag to connect another node. You can click back to resume your schema designing.
        </div>
        <Button primary size="small" onClick={onBack}>
          Get back!
        </Button>
      </FlexBox>
    </StyledGraphHint>
  );
};

export const Toolbar = () => {
  const { getDeploymentDataloaderState } = useDataloaderStore();
  const { graphMode } = getDeploymentDataloaderState();

  const { getNodeDataset, stopNodeConnectionMode, startNodeConnectionMode, addNewNode } = useGraphNodeHandlers();
  const { fulfilledConditions } = useStartImportConditions();
  const [showToolbarHint, setToolbarHintState] = useState(false);

  const handleConnectNodes = () => {
    setToolbarHintState(true);
    startNodeConnectionMode({
      onDisable: () => setToolbarHintState(false),
    });
  };

  return (
    <>
      <GraphToolBar>
        <FlexBox justify="space-between" align="center">
          <FlexBox>
            <Button primary onClick={addNewNode} disabled={graphMode === "ADD_EDGE"}>
              Add node
            </Button>
            <Button basic disabled={!getNodeDataset().length || graphMode === "ADD_EDGE"} onClick={handleConnectNodes}>
              Connect node(s)
            </Button>
          </FlexBox>
          <FlexBox align="baseline">
            <Icon name={fulfilledConditions.length ? "warning sign" : "check"} className={fulfilledConditions.length ? "yellow" : "dark-green-text"} />
            {fulfilledConditions.length ? (
              <Dropdown
                placeholder="Select a database"
                name="database"
                text={`${fulfilledConditions.length} failed conditions`}
                value={`${fulfilledConditions.length} failed conditions`}
                selection
                labeled
              >
                <Dropdown.Menu>
                  {fulfilledConditions.map((condition) => (
                    <Dropdown.Item key={v4()}>{condition}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Box margin="0 16px 0 5px">
                <span>Ready for import</span>
              </Box>
            )}
          </FlexBox>
        </FlexBox>
      </GraphToolBar>
      {showToolbarHint && (
        <NodeConnectionHint
          onBack={() => {
            setToolbarHintState(false);
            stopNodeConnectionMode();
          }}
        />
      )}
    </>
  );
};
