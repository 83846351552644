import React from "react";
import { ITracking } from "./api";

const TrackingContext = React.createContext<{
  tracking: ITracking;
}>({} as any);

export const TrackingContextProvider = ({ tracking, children }: { tracking: ITracking; children: React.ReactNode }) => {
  return <TrackingContext.Provider value={{ tracking }}>{children}</TrackingContext.Provider>;
};

export const useTrackingContext = () => {
  return React.useContext(TrackingContext);
};
