//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import apiClients from "../../api/apiclients";
import { PrivateEndpointService as ApiPrivateEndpointService } from "../../api/network/v1/network";
import { reportError } from "../../errors/reporting";
import Wizard, { IWizardViewState } from "../wizard/Wizard";
import { WizardStep } from "../wizard/useWizard";
import AboutPrivateNetwork from "./AboutPrivateNetwork";
import AlternateDNSView from "./AlternateDNSNamesView";
import PrivateNetworkConfirmationInfoView from "./PrivateNetworkConfirmationInfoView";
import SubscriptionIDView from "./SubscriptionIDView";
import AWSAboutPrivateNetwork from "./aws/AboutAWSPrivateNetwork";
import PrincipalsView from "./aws/PrincipalsView";
import AWSPrivateNetworkConfirmationInfoView from "./aws/PrivateNetworkConfirmationInfoView";
import GCPAboutPrivateNetwork from "./gcp/AboutGCPPrivateNetwork";
import GCPPrivateNetworkConfirmationInfoView from "./gcp/GCPPrivateNetworkConfirmationInfoView";
import ProjectNamesView from "./gcp/ProjectsView";
import { CustomDNSView } from "./CustomDNSView";

interface IPrivateDashboardWizardProps {
  deploymentID: string | undefined;
  providerID: string | undefined;
  name: string | undefined;
  onConfirm: (finalState: ApiPrivateEndpointService) => void;
  onClose: () => void;
}

const getWizardSteps = ({ providerID }: { providerID: string | undefined }): WizardStep[] => {
  if (!providerID) return [];

  const wizardSteps: {
    [key: string]: WizardStep[];
  } = {
    aws: [
      {
        id: "privateNetworkInfo",
        title: "About Private endpoint",
        component: () => <AWSAboutPrivateNetwork />,
        position: 1,
        menuLabel: "About Private endpoint",
        ignoreValidation: true,
      },
      {
        id: "awsPrincipals",
        title: "AWS Principal(s)",
        component: () => <PrincipalsView principals={[]} />,
        position: 2,
        menuLabel: "Configure AWS Principal(s)",
        ignoreValidation: true,
      },
      {
        id: "alternateDNSNames",
        title: "Custom DNS names",
        component: () => (
          <CustomDNSView
            alternateDNSNames={[]}
            enablePrivateDNS
            isPrivateDNSEditable
            info={
              <>
                <div>A Private Endpoint will result in IP addresses in your VPC.</div>
                <div>We strongly recommend mapping those IP addresses to one or more private DNS names.</div>
                <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
              </>
            }
          />
        ),
        position: 3,
        menuLabel: "Custom DNS names",
        ignoreValidation: true,
      },
      {
        id: "confirmation",
        title: "Are you sure you want to confirm?",
        component: () => <AWSPrivateNetworkConfirmationInfoView />,
        position: 4,
        menuLabel: "Confirmation",
        ignoreValidation: true,
      },
    ],
    aks: [
      {
        id: "privateNetworkInfo",
        title: "About Private endpoint",
        component: () => <AboutPrivateNetwork />,
        position: 1,
        menuLabel: "About Private endpoint",
        ignoreValidation: true,
      },
      {
        id: "azureSubscriptionIDs",
        title: "Configure Azure subscription ID(s)",
        component: () => <SubscriptionIDView subscriptionIDs={[]} />,
        position: 2,
        menuLabel: "Configure Azure subscription",
      },
      {
        id: "alternateDNSNames",
        title: "Custom DNS names",
        component: () => (
          <AlternateDNSView
            alternateDNSNames={[]}
            info={
              <>
                <div>A Private Endpoint will result in an IP addresses in your VNet.</div>
                <div>We strongly recommend mapping that IP address to one or more private DNS names.</div>
                <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
              </>
            }
          />
        ),
        position: 3,
        menuLabel: "Custom DNS names",
      },
      {
        id: "confirmation",
        title: "Are you sure you want to confirm?",
        component: () => <PrivateNetworkConfirmationInfoView />,
        position: 4,
        menuLabel: "Confirmation",
        ignoreValidation: true,
      },
    ],
    gcp: [
      {
        id: "privateNetworkInfo",
        title: "About Private endpoint",
        component: () => <GCPAboutPrivateNetwork />,
        position: 1,
        menuLabel: "About Private endpoint",
        ignoreValidation: true,
      },
      {
        id: "gcpProjectIDs",
        title: "Configure Google Project name(s)",
        component: () => <ProjectNamesView projects={[]} />,
        position: 2,
        menuLabel: "Google Project name(s)",
      },
      {
        id: "alternateDNSNames",
        title: "Custom DNS names",
        component: () => (
          <CustomDNSView
            alternateDNSNames={[]}
            enablePrivateDNS
            isPrivateDNSEditable
            info={
              <>
                <div>A Private Endpoint will result in an IP addresses in your VPC.</div>
                <div>We strongly recommend mapping that IP address to one or more private DNS names.</div>
                <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
              </>
            }
          />
        ),
        ignoreValidation: true,
        position: 3,
        menuLabel: "Custom DNS names",
      },
      {
        id: "confirmation",
        title: "Are you sure you want to confirm?",
        component: () => <GCPPrivateNetworkConfirmationInfoView />,
        position: 4,
        menuLabel: "Confirmation",
        ignoreValidation: true,
      },
    ],
  };

  return wizardSteps[providerID];
};

const PrivateNetworkWizard = ({ onConfirm, onClose, deploymentID, providerID, name = "" }: IPrivateDashboardWizardProps) => {
  const steps = getWizardSteps({ providerID });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>("");

  const createPrivateNetworkEndpoint = async (req: ApiPrivateEndpointService) => {
    const { networkClient } = apiClients;
    try {
      setIsLoading(true);
      const response = await networkClient.CreatePrivateEndpointService(req);
      onConfirm && onConfirm(response);
    } catch (err) {
      setError(err);
      reportError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmation = (reqData: IWizardViewState) => {
    const { alternateDNSNames, azureSubscriptionIDs, awsPrincipals, gcpProjectIDs } = reqData;
    let req: ApiPrivateEndpointService = {
      name,
      deployment_id: deploymentID,
    };

    if (gcpProjectIDs) {
      req["gcp"] = {
        projects: gcpProjectIDs.data,
      };
    }
    if (azureSubscriptionIDs) {
      req["aks"] = {
        client_subscription_ids: azureSubscriptionIDs.data,
      };
    }

    if (awsPrincipals) {
      const { data } = awsPrincipals;
      req["aws"] = {
        aws_principals: data,
      };
    }

    // Private DNS is only enabled for AWS and GCP
    if (alternateDNSNames && (providerID === "aws" || providerID === "gcp")) {
      req["alternate_dns_names"] = alternateDNSNames.data.alternateDNSNames;
      req["enable_private_dns"] = alternateDNSNames.data.enablePrivateDNS;
    }

    if (alternateDNSNames && providerID === "aks") {
      req["alternate_dns_names"] = alternateDNSNames.data;
    }
    createPrivateNetworkEndpoint(req);
  };

  return <Wizard handleConfirm={handleConfirmation} stepHeader={"Configuration Steps"} steps={steps} onClose={onClose} loading={isLoading} error={error} />;
};

export default PrivateNetworkWizard;
