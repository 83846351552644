//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Form, Grid, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IPAllowlist as ApiIPAllowlist } from "../../api/lib";
import { Project as ApiProject } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import {
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  Processing,
  Section,
  SectionContent,
  Footnote,
} from "../../ui/lib";
import CIDRRangeSamples from "./CIDRRangeSamples";

interface ICreateIPAllowlistModalViewArgs extends ICreateIPAllowlistModalState, ICreateIPAllowlistModalProps {
  active: boolean;
  handleDismissError: () => void;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onCIDRRangesChanged: (value: string) => void;
  createIPAllowlist: () => void;
  onLockedChanged: () => void;
  onRemoteInspectionAllowedChanged: () => void;
  onCancel: () => void;
}

const CreateIPAllowlistModalView = ({ ...args }: ICreateIPAllowlistModalViewArgs) => (
  <Modal open onClose={args.onClose}>
    <Modal.Header>New IP allowlist</Modal.Header>
    <Modal.Content>
      <Processing active={args.processing} message="Creating IP allowlist, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <Form>
        <Section>
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <Form.Input
                    autoFocus
                    required
                    label="Name"
                    placeholder={`Name (e.g. "cloud-and-office-only")`}
                    name="name"
                    value={args.name}
                    onChange={(e, d) => args.onNameChanged(d.value)}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    label="Short description"
                    placeholder="Description"
                    name="description"
                    value={args.description}
                    onChange={(e, d) => args.onDescriptionChanged(d.value)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={16}>
                <Grid.Column width={16}>
                  <Form.TextArea
                    required
                    label="Allowed CIDR ranges (1 per line)"
                    placeholder={`Line separated CIDR ranges`}
                    name="cidr_ranges"
                    value={args.cidr_ranges.join("\n")}
                    onChange={(e, d) => args.onCIDRRangesChanged(d.value as string)}
                    rows="10"
                  />
                  <CIDRRangeSamples />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="8">
                  <Form.Checkbox
                    label="Remote inspection allowed"
                    checked={args.remote_inspection_allowed}
                    onChange={args.onRemoteInspectionAllowedChanged}
                    toggle
                  />
                  <Footnote>When enabled, ArangoGraph Insights Platform is allowed to inspect connectivity status of this deployment.</Footnote>
                </Grid.Column>
                <Grid.Column width="8">
                  <Form.Checkbox label="Locked" checked={args.locked} onChange={args.onLockedChanged} toggle />
                  <Footnote>Locked IP allowlists cannot be deleted.</Footnote>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active} onClick={args.createIPAllowlist} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.onCancel} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </Modal.Content>
  </Modal>
);

interface ICreateIPAllowlistModalProps {
  project: ApiProject;
  onNewIPAllowlistCreated: (ipAllowlistId: string) => void;
  onClose: () => void;
}

interface ICreateIPAllowlistModalState {
  errorMessage?: string;
  processing: boolean;
  name: string;
  description: string;
  cidr_ranges: string[];
  locked: boolean;
  remote_inspection_allowed: boolean;
}

// Component to create an IP allowlist
class CreateIPAllowlistModal extends Component<ICreateIPAllowlistModalProps, ICreateIPAllowlistModalState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
    cidr_ranges: [],
    locked: false,
    remote_inspection_allowed: false,
  } as ICreateIPAllowlistModalState;

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };
  onCIDRRangesChanged = (value: string) => {
    this.setState({ cidr_ranges: _.toString(value).split("\n") });
  };
  onLockedChanged = () => {
    this.setState((old) => {
      return { locked: !old.locked };
    });
  };
  onRemoteInspectionAllowedChanged = () => {
    this.setState((old) => {
      return { remote_inspection_allowed: !old.remote_inspection_allowed };
    });
  };

  createIPAllowlist = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const req = {
        project_id: this.props.project.id,
        name: this.state.name,
        description: this.state.description,
        cidr_ranges: this.state.cidr_ranges,
        locked: this.state.locked,
        remote_inspection_allowed: this.state.remote_inspection_allowed,
      } as ApiIPAllowlist;
      const createdIPAllowlist = await apiClients.securityClient.CreateIPAllowlist(req);

      this.props.onNewIPAllowlistCreated(createdIPAllowlist.id || "");
    } catch (e) {
      this.setState({ errorMessage: `IP allowlist creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return <CreateIPAllowlistModalView {...this.props} {...this.state} {...this} active={!this.state.processing} onCancel={this.props.onClose} />;
  }
}

export default CreateIPAllowlistModal;
