//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import _ from "lodash";
import React from "react";
import { Icon } from "semantic-ui-react";
import { MenuTitleText } from "../../ui/lib";
import EditableTitleModal from "./EditableTitleModal";

const EditableMenuTitleText = styled(MenuTitleText)`
  .edit-icon {
    opacity: 0.01;
    animation: edit-icon-disappear;
    animation-duration: 4s;
  }
  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
  @keyframes edit-icon-disappear {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.01;
    }
  }
`;

const EditableTitle = ({ title, subTitle, save }: { title: string; subTitle: string; save?: (title: string, subTitle: string) => Promise<void> }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const canEdit = !!save;

  return (
    <EditableMenuTitleText onClick={canEdit ? onOpen : () => {}}>
      {!!save && isOpen && <EditableTitleModal title={title} subTitle={subTitle} onClose={onClose} onSave={save} />}
      {title} {canEdit && <Icon name="pencil" size="tiny" className="edit-icon" />}
      {!_.isEmpty(subTitle) && <div className="subTitle">{subTitle}</div>}
    </EditableMenuTitleText>
  );
};

export default EditableTitle;
