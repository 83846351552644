//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { useDashboardContext } from "../../DashboardContextProvider";
import { PolicyList } from "../../policy/PolicyList";

export const OrganizationPolicyList = () => {
  const { selectedOrganization } = useDashboardContext();
  const history = useHistory();
  const onCreatePolicyClick = () => {
    if (!selectedOrganization.id) {
      return;
    }
    history.push(Routes.dashboard_organization_policy_createWithId(selectedOrganization.id));
  };
  const isOrganizationDeleted = !!selectedOrganization.is_deleted;
  return (
    <PolicyList
      resourceUrl={selectedOrganization.url}
      onCreatePolicyClick={onCreatePolicyClick}
      isCreatePolicyDisabled={isOrganizationDeleted}
      tooltip={
        <Flex direction="column" gap="2">
          <Text>
            Policy defines which users are allowed to perform certain operations on the organization and resources within the organization, such as projects and
            deployments.
          </Text>
          <Text>
            Note that once this policy grants a user access to a certain operation, the user has access to that operation on all resources in the entire
            organization.
          </Text>
          <Text>It is recommended to grant access as close to the resource (where it is needed) as possible.</Text>
        </Flex>
      }
    />
  );
};
