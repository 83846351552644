//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Divider, Icon, Loader, Popup, Segment, SemanticICONS, Table } from "semantic-ui-react";
import { User as ApiUser } from "../../api/iam/v1/iam";

export interface Member extends ApiUser {
  value?: string;
  unsaved?: boolean;
}

export interface IMemberListViewArgs {
  members: Member[];
  activeItem?: string;
  itemActionIcon?: SemanticICONS;
  processing?: boolean;
  onItemClick?: (member: Member) => void;
}

type ContextualMemberList = Partial<IMemberListViewArgs>;

export interface IAvailableMemberListViewArgs extends ContextualMemberList {
  availableUsers: ApiUser[];
  addMember: (member: Member) => void;
}
export interface IExistingMemberListViewArgs extends ContextualMemberList {
  users?: ApiUser[];
  selectedMembers?: Member[];
  removeMember?: (member: Member) => void;
}

export const MemberListView = ({ ...args }: IMemberListViewArgs) => {
  const { members, activeItem = "", itemActionIcon, onItemClick } = args;

  if (!members.length) return <Segment data-testid="noMembers">No members </Segment>;

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body data-testid="membersList">
        {members.map(
          (user: any) =>
            user.email && (
              <Table.Row disabled={activeItem === user.id} warning={user.unsaved} key={user.id}>
                <Table.Cell data-testid="memberEmail">
                  {user.email}{" "}
                  {user.unsaved && <Popup data-testid="infoPopup" trigger={<Icon name="asterisk" size="tiny" data-testid="unsavedIcon" />} content="Unsaved" />}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    data-testid="memberAction"
                    icon={itemActionIcon || "trash"}
                    loading={activeItem === user.id}
                    onClick={() => onItemClick && onItemClick(user)}
                    type={"button"}
                  ></Button>
                </Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};

const AvailableMemberListView = ({ ...args }: IAvailableMemberListViewArgs) => {
  const { availableUsers, addMember, processing } = args;
  let areChangesUnsaved = false;

  const members = availableUsers.map((user: Member) => {
    const member: Member = {
      id: user.id,
      email: user.email,
      value: user.id || "",
      unsaved: user.unsaved,
    };

    if (user.unsaved) {
      areChangesUnsaved = true;
    }

    return member;
  });

  return (
    <>
      <h4 className="heading-4" data-testid="availableMemberListHeader">
        List of members available to add in this group {processing && <Loader data-testid="loader" active={processing} inline size="tiny" />}
        {areChangesUnsaved && (
          <Popup data-testid="unsavedMemberState" trigger={<Icon name="asterisk" color="red" size="small" />} content="You have unsaved changes" />
        )}
      </h4>
      <Divider />
      <MemberListView members={members.filter((member) => member.email)} itemActionIcon="arrow left" onItemClick={addMember} processing={processing} />
    </>
  );
};

const ExistingMemberListView = ({ ...args }: IExistingMemberListViewArgs) => {
  const { selectedMembers = [], processing, removeMember } = args;
  const areChangesUnsaved = !!selectedMembers.some((member: Member) => member.unsaved);
  return (
    <>
      <h4 className="heading-4" data-testid="existingMemberListHeader">
        List of members in this group {processing && <Loader data-testid="loader" active={processing} inline size="tiny" />}
        {areChangesUnsaved && (
          <Popup data-testid="unsavedMemberState" trigger={<Icon name="asterisk" color="red" size="small" />} content="You have unsaved changes" />
        )}
      </h4>
      <Divider />
      <MemberListView
        members={selectedMembers.filter((member) => member.email)}
        itemActionIcon="arrow right"
        onItemClick={removeMember}
        processing={processing}
      />
    </>
  );
};

export { AvailableMemberListView, ExistingMemberListView };
export default MemberListView;
