//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RouteComponentProps } from "react-router-dom";
import { Button, Segment, Tab, TabProps } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { IDOptions as ApiIDOptions, User as ApiUser } from "../../api/lib";
import { OrganizationList as ApiOrganizationList } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  Confirm,
  ConfirmInfo,
  ContentActionButtonNew,
  ContentSegment,
  ErrorMessage,
  MainContent,
  OverviewTabHeader,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  TabHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import ApiKeysList from "../apikeys/ApiKeysList";
import { BudgetHelper } from "../BudgetHelper";
import { HistoryHelper } from "../HistoryHelper";
import OrganizationInvites from "../my-organization-invites/OrganizationInvites";
import OrganizationList from "../my-organizations/OrganizationList";
import { TopMenuInfo } from "../TopMenuInfo";
import UserDetails from "./UserDetails";

export enum UserMyAccountTabPage {
  Overview = 0,
  Organizations = 1,
  Invites = 2,
  ApiKeys = 3,
}

// API used by the UserDetails component
export interface IUserDetailsAPI {
  GetUser: () => Promise<ApiUser>;
  ResendUserMobilePhoneVerification?: (user: ApiUser) => Promise<void>;
  GetToken: () => string;
}

interface IUserMyAccountProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  tabPage?: UserMyAccountTabPage;
  loading: boolean;
  onClickLogout: () => void;
  onOrganizationInviteAccepted: (id: string) => void;
  onOrganizationInviteRejected: (id: string) => void;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  api: IUserDetailsAPI;
  showId: boolean;
  onClickVerifyCode?: () => void;
}

interface IUserMyAccountState {
  activeTabIndex: number;
  errorMessage?: string;
  processingDelete: boolean;
  processingOrganizationCreate: boolean;
  processingUserEdit: boolean;
  organizations?: ApiOrganizationList;
  confirmInfo?: ConfirmInfo;
  tokenCopied: boolean;
  creatingAPIKey: boolean;
}

class UserMyAccount extends Component<IUserMyAccountProps, IUserMyAccountState> {
  state = {
    activeTabIndex: (this.props.tabPage as number) || 0,
    errorMessage: undefined,
    organizations: undefined,
    processingDelete: false,
    processingOrganizationCreate: false,
    processingUserEdit: false,
    confirmInfo: undefined,
    tokenCopied: false,
    creatingAPIKey: false,
  } as IUserMyAccountState;

  componentDidMount() {
    this.updateTopMenu();
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadOrganizations, 60000);
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  getOrganizationName = (id: string) => {
    const organizations = this.state.organizations;
    if (organizations && organizations.items) {
      const organization = organizations.items.find((o) => o.id == id);
      if (organization) {
        return organization.name;
      }
    }
    return "";
  };

  reloadOrganizations = async () => {
    const listOptions = {} as ApiListOptions;
    const organizations = await apiClients.resourceManagerClient.ListOrganizations(listOptions);
    this.setState({ organizations: organizations });
  };

  onClickDelete = (id: string) => {
    const organizationName = this.getOrganizationName(id);
    const confirmInfo = {
      header: "Delete Organization",
      content: `Are you sure you want to delete organization '${organizationName}'?`,
      warning: "This implies deletion of the projects and deployments in this project and all data stored in the database!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processingDelete: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.resourceManagerClient.DeleteOrganization(idOptions);
      this.props.refreshNow && this.props.refreshNow(this.reloadOrganizations);
    } catch (e) {
      this.setState({ errorMessage: `Organization deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingDelete: false });
  };

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    this.props.topMenuInfo.clearBreadCrumb();
    this.props.topMenuInfo.setImageSource("user");
    this.props.topMenuInfo.setTitles("User", "My account");
  };

  onTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    this.setState({ activeTabIndex: data.activeIndex as number });
  };

  onShowTab = (tab: UserMyAccountTabPage) => {
    this.setState({ activeTabIndex: tab as number });
  };

  onCopiedToken = () => {
    this.setState({ tokenCopied: true }, () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ tokenCopied: false }), 2000));
  };

  onCreateAPIKey = () => {
    this.setState({ creatingAPIKey: true });
  };

  onCloseCreateAPIKey = () => {
    this.setState({ creatingAPIKey: false });
  };

  render() {
    const token = this.props.api.GetToken();
    const loading = this.props.loading;

    const panes = [
      {
        menuItem: OverviewTabHeader(),
        render: () => <UserDetails {...this.props} loading={false} />,
      },
      {
        menuItem: TabHeader({ key: "organizations", title: "Organizations", icon: "umbrella" }),
        render: () => (
          <Section>
            <SectionHead>
              <SectionHeader title="My Organizations" loading={loading} />
              <Confirm confirmInfo={this.state.confirmInfo} />
              <Processing active={this.state.processingOrganizationCreate} message="Creating organization, please wait..." />
              <SectionButtons>
                <ContentActionButtonNew
                  primary
                  content="New organization"
                  disabled={BudgetHelper.disableOrganizationCreate(this.state.organizations, this.state.processingOrganizationCreate)}
                  onClick={() => {
                    HistoryHelper.push(this.props.history, Routes.dashboard_user_organizations_create, this.props.topMenuInfo.getTitle());
                  }}
                />
              </SectionButtons>
            </SectionHead>
            <SectionContent>
              <OrganizationList
                {...this.props}
                organizations={this.state.organizations}
                loading={false}
                processing={this.state.processingDelete}
                onClickDeleteConfirmed={this.onClickDeleteConfirmed}
                onDeleteOrganization={this.onClickDelete}
              />
            </SectionContent>
          </Section>
        ),
      },
      {
        menuItem: TabHeader({ key: "invites", title: "Invites", icon: "user plus" }),
        render: () => <OrganizationInvites {...this.props} loading={false} />,
      },
      {
        menuItem: TabHeader({ key: "apikeys", title: "API Keys", icon: "key" }),
        render: () => (
          <div>
            <Section>
              <SectionHead>
                <SectionHeader title="API keys explained" />
              </SectionHead>
              <SectionContent>
                <Segment>
                  <p className="para">
                    API keys are authentication tokens intended to be used for scripting. They allow a script to authenticate on behalf of a user.
                  </p>
                  <p className="para">
                    An API key consists of a key and a secret. You need both to complete authentication. The secret is only shown at creation time. You have to
                    store it in a safe place.
                  </p>
                </Segment>
              </SectionContent>
            </Section>
            <Section>
              <SectionHead>
                <SectionHeader title="My API keys" loading={loading} />
                <Confirm confirmInfo={this.state.confirmInfo} />
                <SectionButtons>
                  {token && (
                    <CopyToClipboard text={token} onCopy={this.onCopiedToken}>
                      <Button icon={this.state.tokenCopied ? "checkmark" : "copy"} labelPosition="right" content="Copy token to clipboard" />
                    </CopyToClipboard>
                  )}
                  <ContentActionButtonNew primary content="New API key" onClick={this.onCreateAPIKey} />
                </SectionButtons>
              </SectionHead>
              <SectionContent>
                <ApiKeysList {...this.props} loading={false} creatingAPIKey={this.state.creatingAPIKey} onCloseCreateAPIKey={this.onCloseCreateAPIKey} />
              </SectionContent>
            </Section>
          </div>
        ),
      },
    ] as {
      menuItem?: any;
      render?: () => React.ReactNode;
    }[];

    return (
      <ContentSegment>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <MainContent>
          <Tab
            activeIndex={this.state.activeTabIndex}
            onTabChange={this.onTabChange}
            menu={{ secondary: true, pointing: true, stackable: true }}
            panes={panes}
          />
        </MainContent>
      </ContentSegment>
    );
  }
}

export default withRefresh()(UserMyAccount);
