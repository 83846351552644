//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { AppLinkButton } from "../../ui/_link";
import { ISummaryItem } from "./types";

const StyledSummaryItem = styled.div`
  color: var(--gray-900) !important;

  & .header {
    font-size: 1.2rem !important;
  }

  & .private-link-stat-item {
    color: var(--gray-600);
  }

  & .private-link-stat-item > i {
    top: -6px;
    margin-left: 5px;
  }

  & .main-label {
    display: inline-block;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.9rem !important;

    &:hover {
      color: var(--gray-950);
    }
  }

  .label-icon {
    margin-right: 12px !important;
    margin-top: -3px !important;
    font-size: 1.4rem !important;
  }
`;

export const SummaryItem = ({
  labelIcon,
  actionIcon,
  mainLabel,
  actionLabel = "",
  handleClick,
  actionClassName,
  noAction,
  hasError,
  errorMessage,
  testID,
}: ISummaryItem) => {
  return (
    <StyledSummaryItem data-testid={testID}>
      <Header size="large" data-testid={`${testID}Header`}>
        {hasError ? (
          <Popup trigger={<Icon data-testid="summaryErrorState" name="warning circle" className="label-icon" color="red" />} content={errorMessage} />
        ) : (
          <Icon name={labelIcon} className="label-icon" data-testid={`${testID}LabelIcon`} />
        )}
        {mainLabel}
      </Header>
      <AppLinkButton
        icon={!noAction ? actionIcon : undefined}
        label={actionLabel}
        className={`action ${actionClassName || ""}`}
        handleClick={!noAction ? handleClick : () => {}}
        noUnderline={noAction}
        testID="summaryItemAction"
      />
    </StyledSummaryItem>
  );
};
