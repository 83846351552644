//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import * as React from "react";
import ReactTimeout, { ReactTimeoutProps } from "react-timeout";
import { reportError } from "../errors/reporting";
import { EventSubscriptionManager } from "./EventSubscriptionManager";
import { RequestEventArgs } from "./Events";
import { Permission, PermissionCache, ResourceType } from "./PermissionCache";
export interface IWithRefreshProps extends IWithRefreshInjectedProps {
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
}

// Interface decribing the external properties of the withRefresh HOC (higher-order component)
export interface IWithRefreshExternalProps extends ReactTimeoutProps {
  eventSubscriptionManager: EventSubscriptionManager;
  permissionCache: PermissionCache;
}

// Interface decribing the properties of the withRefresh HOC (higher-order component)
export interface IWithRefreshInjectedProps extends ReactTimeoutProps {
  loading: boolean;
  error?: any;
  subscribe?: (callback: () => Promise<void>, args: RequestEventArgs) => Promise<string | void>;
  subscribeUrl?: (callback: () => Promise<void>, url?: string) => Promise<string | void>;
  unsubscribe?: (id?: string | void) => Promise<void>;
  refreshWithTimer?: (callback: () => Promise<void>, seconds: number) => Promise<void>;
  refreshNow?: (callback: () => Promise<void>) => Promise<void>;
  refreshNowAll?: () => void;
  clearError?: () => void;
  permissionCacheId?: number;
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  registerActivePermissionUrls?: (urls: string[]) => void;
}

// Interface decribing the state of the withRefresh HOC (higher-order component)
export interface IWithRefreshState {
  loading: boolean;
  error?: any;
  subscriptions: Array<string>;
  callbacks: Array<() => Promise<void>>;
  permissionCacheId: number;
  activePermissionUrls: Array<string>;
}

export interface IWithRefreshOptions {
  warning?: boolean;
  debug?: boolean;
}

export const withRefresh =
  ({ warning = true, debug = false }: IWithRefreshOptions = {}) =>
  <TOriginalProps extends {}>(
    WrappedComponent: React.ComponentClass<TOriginalProps & IWithRefreshInjectedProps> | React.FunctionComponent<TOriginalProps & IWithRefreshInjectedProps>
  ) => {
    type ResultProps = TOriginalProps &
      IWithRefreshExternalProps & {
        children?: React.ReactNode;
      };
    const result = class WithRefresh extends React.Component<ResultProps, IWithRefreshState> {
      state = {
        loading: false,
        error: undefined,
        subscriptions: new Array<string>(),
        callbacks: new Array<() => Promise<void>>(),
        permissionCacheId: this.props.permissionCache.getId(),
        activePermissionUrls: new Array<string>(),
      } as IWithRefreshState;
      // Define how your HOC is shown in ReactDevTools
      static displayName = `WithRefresh(${WrappedComponent.displayName || WrappedComponent.name})`;

      // Set when the component is going to unmount, so we can prevent additonal execution of the execute method.
      unmount = false;

      // Subscribe to the provided filter (URL of resource to request events for) and wait until the initial call has been executed.
      subscribeUrl = async (callback: () => Promise<void>, url?: string): Promise<string | void> => {
        if (debug && !url) {
          console.log(`Subscribing to URL without specifying url`);
        }

        const args = { resource_url: url } as RequestEventArgs;
        return this.subscribe(callback, args);
      };

      // Subscribe to the provided filter (request event args) and wait until the initial call has been executed.
      subscribe = async (callback: () => Promise<void>, args: RequestEventArgs): Promise<string | void> => {
        // Initially call the callback once.
        this.execute(callback, 0);

        // Store callback in collection
        this.state.callbacks.push(callback);

        // Return the result - subscribe ID
        return this.trySubscribe(callback, args, 0);
      };

      // Execute the callback every interval
      refreshWithTimer = async (callback: () => Promise<void>, ms: number): Promise<void> => {
        if (debug) {
          console.log(`Auto-refesh every ${ms} ms ...`);
        }

        // Store callback in collection
        this.state.callbacks.push(callback);

        // Initially call the callback once (and repeat after).
        return this.execute(callback, 0, ms);
      };

      refreshNow = (callback: () => Promise<void>): Promise<void> => {
        // Call the callback once now.
        return this.execute(callback, 0);
      };

      refreshNowAll = () => {
        this.state.callbacks.forEach((c) => {
          this.execute(c, 0);
        });
      };

      clearError = () => {
        this.setState({ error: undefined });
      };

      private trySubscribe = async (callback: () => Promise<void>, args: RequestEventArgs, retryCount: number): Promise<string | void> => {
        try {
          if (debug) {
            console.log(`Subscribing (${retryCount}) to ${JSON.stringify(args)} ...`);
          }
          const id = await this.props.eventSubscriptionManager.subscribe(args, () => {
            this.execute(callback, 0);
          });
          this.state.subscriptions.push(id);
          if (debug) {
            console.log(`Subscribed (${retryCount}) to ${JSON.stringify(args)} with id='${id}'`);
          }
          return id;
        } catch (e) {
          if (debug) {
            console.log(`Subscribing (${retryCount}) to ${JSON.stringify(args)} failed with exception: ${e}`);
          }
          if (retryCount < 5) {
            // Retry a few time only (depending on retryCount)
            this.props.setTimeout &&
              this.props.setTimeout(() => {
                this.trySubscribe(callback, args, retryCount + 1);
              }, this.retryPeriodInMs(retryCount));
          } else {
            // Fallback to execute every 10 seconds
            this.execute(callback, 0, 10000);
          }
        }
      };

      registerActivePermissionUrls = (urls: string[]) => {
        // if not present in activePermissionUrls, add it
        const newActivePermissionUrls = urls.filter((url) => !this.state.activePermissionUrls.includes(url));
        if (newActivePermissionUrls.length > 0) {
          this.setState({ activePermissionUrls: [...this.state.activePermissionUrls, ...newActivePermissionUrls] });
        }
      };
      hasPermissionByUrl = (url: string, type: ResourceType, permission: Permission): boolean => {
        if (!this.state.activePermissionUrls.includes(url)) {
          this.setState({ activePermissionUrls: [...this.state.activePermissionUrls, url] });
        }

        const cacheResult = this.props.permissionCache.checkPermission(url, permission);
        return cacheResult.getResult();
      };

      private execute = async (callback: () => Promise<void>, retryCount: number, everyMs?: number) => {
        if (this.unmount) {
          if (warning) {
            console.warn(`Executing (${retryCount}) [every ${everyMs} ms]  - refused: componented unmounted!`);
          }
          return;
        }

        if (debug) {
          console.log(`Executing (${retryCount}) [every ${everyMs} ms]  ...`);
        }

        try {
          this.setState({
            loading: true,
          });

          await callback();

          this.setState({
            loading: false,
            error: undefined,
          });

          if (everyMs) {
            // Re-execute after given time (as if it is the first call again)
            this.props.setTimeout &&
              this.props.setTimeout(() => {
                this.execute(callback, 0, everyMs);
              }, everyMs);
          }
        } catch (e) {
          if (warning || debug) {
            console.warn(`Executing callback (${retryCount}) failed with exception: ${e}`);
          }

          this.setState({
            loading: false,
            error: e,
          });

          // Report error
          if (retryCount == 2) {
            // After 2 failures, we report it
            reportError(e);
          }

          // Retry (depending on retryCount)
          this.props.setTimeout &&
            this.props.setTimeout(() => {
              this.execute(callback, retryCount + 1, everyMs);
            }, this.retryPeriodInMs(retryCount));
        }
      };

      private retryPeriodInMs = (retryCount: number): number => {
        return retryCount * 500 + 100;
      };

      componentDidCatch(error: any, errorInfo: any) {
        if (warning || debug) {
          console.warn(`__WithRefresh.componentDidCatch '${error}': ${errorInfo}`);
        }
      }

      componentDidMount() {
        this.refreshPermissions();
      }

      componentDidUpdate(prevProps: any, prevState: IWithRefreshState) {
        if (this.state.activePermissionUrls !== prevState.activePermissionUrls) {
          this.refreshPermissions();
        }
      }

      // Trigger an update of all permissions for the URL's we're interested in.
      async refreshPermissions() {
        const result = await this.props.permissionCache.updatePermissions(this.state.activePermissionUrls);
        if (result.getUpdated()) {
          // Update our state so the UI gets refreshed.
          this.setState({ permissionCacheId: result.getId() });
        }
      }
      unsubscribe = async (id?: string | void) => {
        if (!id) return;
        this.props.eventSubscriptionManager.unsubscribe(id);
      };
      componentWillUnmount() {
        this.unmount = true;
        this.state.subscriptions.forEach(async (id) => {
          if (debug) {
            console.log(`Unsubscribing from id='${id}' ...`);
          }
          try {
            await this.props.eventSubscriptionManager.unsubscribe(id);
            if (debug) {
              console.log(`Unsubscribed from id='${id}' ...`);
            }
          } catch (e) {
            if (warning || debug) {
              console.log(`Unsubscribing from id='${id}' failed with exception: ${e}`);
            }
          }
        });
      }

      render(): JSX.Element {
        // render the wrapped component, passing the props and state
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            subscribe={this.subscribe}
            subscribeUrl={this.subscribeUrl}
            refreshWithTimer={this.refreshWithTimer}
            refreshNow={this.refreshNow}
            refreshNowAll={this.refreshNowAll}
            clearError={this.clearError}
            hasPermissionByUrl={this.hasPermissionByUrl}
            registerActivePermissionUrls={this.registerActivePermissionUrls}
            unsubscribe={this.unsubscribe}
          />
        );
      }
    };

    return ReactTimeout(result);
  };
