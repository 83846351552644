//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import { checkVAT, countries } from "@accountable/jsvat";
import { BillingConfig as ApiBillingConfig } from "../../api/lib";

export const isVatPossible = (config: ApiBillingConfig) => {
  const address = config.address || {};
  const country_code = address.country_code || "";
  if (_.isEmpty(country_code)) {
    return false; // Don't if VAT is required know yet
  }
  const vatInfo = countries.find((x) => x.codes.includes(country_code));
  return !!vatInfo;
};

export const isVatNumberValid = (config: ApiBillingConfig, vat_number?: string) => {
  if (!vat_number) {
    return true;
  }
  const address = config.address || {};
  const country_code = address.country_code || "";
  const list = countries.filter((x) => x.codes.includes(country_code));
  const result = checkVAT(vat_number, list);
  return result.isValid;
};
