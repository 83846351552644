//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import styled from "@emotion/styled";
import React from "react";
import { Button, SemanticSIZES } from "semantic-ui-react";

interface ISimplePagingButtons {
  // Are both buttons disabled?
  disabled?: boolean;
  // Is there more data?
  hasNext?: boolean;
  // Page number; 0..
  page: number;
  // Size of button
  size?: SemanticSIZES;
  // Next page handler
  onNextPage?: () => void;
  // Previous page handler
  onPreviousPage?: () => void;
  testId?: string;
}

const StyledButtonOr = styled(Button.Or)`
  align-self: center;
`;

export const SimplePagingButtons = ({ ...args }: ISimplePagingButtons) => {
  const disabled = !!args.disabled;
  const hasNext = !!args.onNextPage && !!args.hasNext;
  const hasPrev = !!args.onPreviousPage && args.page > 0;
  if (!hasNext && !hasPrev) {
    return <span />;
  }
  return (
    <Button.Group size={args.size || "mini"} floated="right" data-testid={args.testId}>
      <Button icon="angle left" disabled={disabled || !hasPrev} onClick={args.onPreviousPage} size={args.size} />
      <StyledButtonOr text={`${args.page + 1}`} />
      <Button icon="angle right" disabled={disabled || !hasNext} onClick={args.onNextPage} size={args.size} />
    </Button.Group>
  );
};

interface IPagingButtons {
  // Are both buttons disabled?
  disabled?: boolean;
  // Page number; 0..
  page: number;
  // Number of items per page
  pageSize: number;
  // Size of button
  size?: SemanticSIZES;
  // Number of items on current page
  count: number;
  // Next page handler
  onNextPage?: () => void;
  // Previous page handler
  onPreviousPage?: () => void;
  testId?: string;
}

export const PagingButtons = ({ ...args }: IPagingButtons) => {
  const hasNext = args.count === args.pageSize;
  return <SimplePagingButtons {...args} hasNext={hasNext} />;
};
