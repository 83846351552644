//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Statistic } from "semantic-ui-react";
import { User as ApiUser } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentActionButton,
  ContentActionButtonEdit,
  ErrorMessage,
  Loading,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  StatisticsContentGroup,
  StyledStatsSegment,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { TopMenuInfo } from "../TopMenuInfo";

interface IOverviewViewArgs {
  user: ApiUser;
}

const OverviewView = ({ ...args }: IOverviewViewArgs) => (
  <StyledStatsSegment>
    <StatisticsContentGroup>
      <Statistic.Group widths={args.user.has_educational_status ? "5" : "4"}>
        <Statistic size="tiny">
          <Statistic.Label>Name</Statistic.Label>
          <Statistic.Value>{args.user.name || "-"}</Statistic.Value>
        </Statistic>
        <Statistic size="tiny">
          <Statistic.Label>{args.user.has_educational_status ? "University" : "Company"}</Statistic.Label>
          <Statistic.Value>{args.user.company_name || "-"}</Statistic.Value>
        </Statistic>
        {args.user.has_educational_status && (
          <Statistic size="tiny">
            <Statistic.Label>Role</Statistic.Label>
            <Statistic.Value className="capitalize">{args.user.educational_role || "-"}</Statistic.Value>
          </Statistic>
        )}
        <Statistic size="tiny">
          <Statistic.Label>Email address</Statistic.Label>
          <Statistic.Value>{args.user.email || "-"}</Statistic.Value>
        </Statistic>
        <Statistic size="tiny">
          <Statistic.Label>Created</Statistic.Label>
          <Statistic.Value>{args.user.created_at ? moment(args.user.created_at).fromNow() : "-"}</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    </StatisticsContentGroup>
  </StyledStatsSegment>
);

interface IMobilePhoneVerificationViewArgs {
  user: ApiUser;
}

const MobilePhoneVerificationView = ({ ...args }: IMobilePhoneVerificationViewArgs) => (
  <StyledStatsSegment>
    <StatisticsContentGroup>
      <Statistic.Group widths="2">
        <Statistic size="tiny">
          <Statistic.Label>Mobile phone</Statistic.Label>
          <Statistic.Value>{args.user.mobile_phone || "-"}</Statistic.Value>
        </Statistic>
        <Statistic size="tiny">
          <Statistic.Label>Status</Statistic.Label>
          <Statistic.Value>{args.user.mobile_phone ? (args.user.mobile_phone_verified ? "Verified" : "Not verified") : "N/A"}</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    </StatisticsContentGroup>
  </StyledStatsSegment>
);

// API used by the UserDetails component
export interface IUserDetailsAPI {
  GetUser: () => Promise<ApiUser>;
  ResendUserMobilePhoneVerification?: (user: ApiUser) => Promise<void>;
  GetToken: () => string;
}

// Interface decribing the properties of the user component
interface IUserDetailsProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onClickLogout: () => void;
  showId: boolean;
  onClickVerifyCode?: () => void;
  api: IUserDetailsAPI;
  loading: boolean;
}

// Interface decribing the state of the user component
interface IUserDetailsState {
  user?: ApiUser;
  processing: boolean;
  resendingVerificationCode: boolean;
  errorMessage?: string;
}

class UserDetails extends Component<IUserDetailsProps, IUserDetailsState> {
  state = {
    user: undefined,
    processing: false,
    resendingVerificationCode: false,
    errorMessage: undefined,
  } as IUserDetailsState;

  reloadUserInfo = async () => {
    const user = await this.props.api.GetUser();
    this.setState({ user: user });
  };

  onResendVerificationCode = async () => {
    const user = this.state.user;
    if (this.props.api.ResendUserMobilePhoneVerification && user) {
      try {
        this.setState({ resendingVerificationCode: true, errorMessage: undefined });
        await this.props.api.ResendUserMobilePhoneVerification(user);
      } catch (e) {
        this.setState({ errorMessage: `Resending verification code failed: ${e}` });
        reportError(e);
      }
      this.setState({ resendingVerificationCode: false });
    }
  };

  componentDidMount() {
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadUserInfo, 60000);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const has_user = !!this.state.user;
    const user = this.state.user || {};
    const onClickVerifyCode = this.props.onClickVerifyCode;
    const has_mobile_phone = !!user.mobile_phone;
    const mobile_phone_verified = !!user.mobile_phone_verified;
    const mobile_phone_needs_verification = !!user.mobile_phone_needs_verification;
    const loading = this.props.loading;

    return (
      <div>
        <Section>
          <SectionHead>
            <SectionHeader title="Overview" loading={loading} />
            <SectionButtons>
              <ContentActionButtonEdit
                primary
                onClick={() => {
                  HistoryHelper.push(this.props.history, Routes.dashboard_user_edit, this.props.topMenuInfo.getTitle());
                }}
              />
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            {has_user && <OverviewView {...this.props} {...this.state} user={user} />}
            {!has_user && <Loading />}
          </SectionContent>
        </Section>
        {has_mobile_phone && (
          <Section>
            <SectionHead>
              <SectionHeader title="Mobile phone verification" />
              <SectionButtons>
                {!mobile_phone_verified && mobile_phone_needs_verification && (
                  <ContentActionButton onClick={this.onResendVerificationCode} disabled={this.state.resendingVerificationCode}>
                    Resend verification code
                  </ContentActionButton>
                )}
                {!mobile_phone_verified && mobile_phone_needs_verification && onClickVerifyCode && (
                  <ContentActionButton primary onClick={onClickVerifyCode}>
                    Verify code
                  </ContentActionButton>
                )}
              </SectionButtons>
            </SectionHead>
            <SectionContent>
              <div>
                <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
                {has_user && <MobilePhoneVerificationView {...this.props} {...this.state} user={user} />}
              </div>
            </SectionContent>
          </Section>
        )}
      </div>
    );
  }
}

export default withRefresh()(UserDetails);
