//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Loader, Table } from "semantic-ui-react";
import { OrganizationInviteList as ApiOrganizationInviteList, OrganizationInvite as ApiOrganizationInvite } from "../../api/resourcemanager/v1/resourcemanager";
import moment from "moment";
import { Loading, LoaderBoxForTable as LoaderBox, ListActionDelete } from "../../ui/lib";
import { ResourceType, Permission } from "../../util/PermissionCache";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing a pending invite
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Created By</Table.HeaderCell>
      <Table.HeaderCell>Created For</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a pending invite
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  item: ApiOrganizationInvite;
  onDelete: (id: string) => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{args.item.created_by_name}</Table.Cell>
    <Table.Cell>{args.item.email}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        {args.hasPermissionByUrl &&
          args.hasPermissionByUrl(args.item.url || "", ResourceType.OrganizationInvite, "resourcemanager.organization-invite.delete") && (
            <ListActionDelete onClick={() => args.onDelete(args.item.id || "")} />
          )}
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the pending invites
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  items: ApiOrganizationInvite[];
  loading: boolean;
  onDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>{args.items.map((item) => !(item.accepted || item.rejected) && <RowView {...args} key={item.id} item={item} />)}</Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No organization invites pending</div>;

// Interface describing the pending invites view arguments
export interface IPendingOrganizationInviteListViewArgs {
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
  organizationInvites?: ApiOrganizationInviteList;
  loading: boolean;
  onDelete: (id: string) => void;
}

export const PendingOrganizationInviteListView = ({ ...args }: IPendingOrganizationInviteListViewArgs) => {
  if (!args.organizationInvites) {
    return <Loading />;
  }
  const pendingItems = (args.organizationInvites.items || []).filter((x) => !(x.accepted || x.rejected));
  if (!pendingItems.length) {
    return <EmptyView />;
  }
  return <ListView {...args} items={pendingItems} />;
};
