//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Divider, Form, Message, Modal } from "semantic-ui-react";
import { textToStrings } from "../../api/_util";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { ErrorMessage } from "../../ui/_errorMessage";
import { IWizardContext, WizardContext } from "../wizard/Wizard";
import { isValidDomain } from "../../util/domainValidation";

export interface IAlternateDNSNamesArgs {
  onChange?: (dnsNames: string[]) => void;
  onUpdateFieldValidity?: (state: boolean) => void;
  info: string | ReactNode;
  alternateDNSNames: string[];
}

const AlternateDNSView = ({ onChange, onUpdateFieldValidity, alternateDNSNames = [], info }: IAlternateDNSNamesArgs): React.ReactElement => {
  const [dnsNames, setDNSNames] = useState<string[]>(alternateDNSNames);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);

  const context = useContext<IWizardContext>(WizardContext);

  const isFormValid = (names: string[]) => {
    if (!isEmpty(names)) {
      return !names.find((dnsName) => !isValidDomain(dnsName));
    }
    return true;
  };

  useEffect(() => {
    const { existingState } = context;
    const { data = undefined } = existingState || {};
    setDNSNames(data || alternateDNSNames);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target;
    const names = textToStrings(value);
    setDNSNames(names);
    onChange && onChange(names);
  };

  const handleBlur = () => {
    const isValid = isFormValid(dnsNames);
    setIsValueValid(isValid);
    const { updateWizardState } = context;
    onUpdateFieldValidity && onUpdateFieldValidity(isValid);

    updateWizardState && updateWizardState({ isValid, data: dnsNames.filter((name) => !!name) });
  };

  return (
    <>
      <Message data-testid="alternateDNSInfo">{info}</Message>
      <Divider hidden />
      <Form>
        <Form.TextArea
          error={!isValueValid}
          data-testid="alternateDNSNamesField"
          label="Alternate DNS (1 per line)"
          placeholder="Enter Alternate DNS names"
          onChange={handleChange}
          value={dnsNames.join("\n")}
          onBlur={handleBlur}
        />
        <Form.TextArea data-testid="alternateDNSExample" label="Example for Alternate DNS names" value={["test.example.com", "test2.example.com"].join("\n")} />
      </Form>
    </>
  );
};

export default AlternateDNSView;

interface IAlternateDNSNamesModalViewArgs extends IAlternateDNSNamesArgs {
  onClose: () => void;
  onSave: (alternateDNSNames: string[]) => Promise<{ error: unknown } | undefined>;
}

const AlternateDNSNamesModalView = ({ onClose, onChange, onSave, alternateDNSNames, info }: IAlternateDNSNamesModalViewArgs) => {
  const [dnsNames, setDNSNames] = useState<string[]>(alternateDNSNames);
  const [isValid, updateFieldState] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  const handleChange = (dnsNames: string[]) => {
    setDNSNames(dnsNames);
    onChange && onChange(dnsNames);
  };
  const handleSave = async () => {
    setLoading(true);
    const { error } = (await onSave(dnsNames)) || {};
    setLoading(false);
    !!error && setError(error);
    if (!error && !loading) onClose();
  };

  return (
    <Modal open onClose={onClose} data-testid="privateNetworkDNSNamesModal">
      <Modal.Header>Alternate DNS names</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} data-testid="alternateDNSModalError" />
          <AlternateDNSView
            onChange={handleChange}
            alternateDNSNames={dnsNames}
            onUpdateFieldValidity={(state: boolean) => {
              updateFieldState(state);
            }}
            info={info}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} data-testid="alternateDNSModalCloseAction" />
        <FormActionButtonSave primary onClick={handleSave} icon="save" disabled={!isValid} loading={loading} data-testid="alternateDNSModalSaveAction" />
      </Modal.Actions>
    </Modal>
  );
};

export { AlternateDNSNamesModalView };
