//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Header } from "semantic-ui-react";
import { StyledTransparentSegment } from "../../../ui/_style";

const GCPAboutPrivateNetwork = () => {
  return (
    <>
      <Header data-testid="aboutPrivateNetworkHeader">What is GCP Private Service Connect?</Header>
      <StyledTransparentSegment className="line-height-1-5" data-testid="aboutPrivateNetworkHeaderAnswer">
        Private Service Connect allows private consumption of services across VPC networks that belong to different groups, teams, projects, or organizations.
        You can publish and consume services using IP addresses that you define and that are internal to your VPC network.
        <p className="para">You can use Private Service Connect to access Google APIs and services, or managed services in another VPC network.</p>
      </StyledTransparentSegment>
      <Header data-testid="aboutPrivateNetworkHeaderQ2">How to configure a Private Endpoint?</Header>
      <StyledTransparentSegment className="line-height-1-5" data-testid="aboutPrivateNetworkHeaderQ2Answer">
        <p className="line-height-1-5">To configure a Private Endpoint, you must provide the google project names.</p>
        <p className="line-height-1-5">
          The ArangoGraph Insights Platform will configure a Private Endpoint Service that will automatically connect to Private Endpoints that are created for
          those projects.
        </p>
        <p className="line-height-1-5">
          After creation of the Private Endpoint Service, you will receive a <b>service attachment</b> that you need during the creation of your Private
          Endpoint(s).
        </p>
      </StyledTransparentSegment>
    </>
  );
};

export default GCPAboutPrivateNetwork;
