//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useContext, useEffect, useState } from "react";
import { IWizardContext, WizardContext } from "../../wizard/Wizard";
import PrincipalsAdditionFormModalView from "./PrincipalAdditionFormModalView";
import PrincipalList from "./PrincipalList";
import EmptyPrincipalMessage from "./EmptyPrincipalMessage";
import PrincipalViewHeader from "./PrincipalViewHeader";
import { AWSPrincipal, IPrincipalsViewArgs } from "../types";

export const isPrincipalAccountIDValid = (accountId: string) => {
  return /^[0-9]{12}$/.test(accountId);
};

const PrincipalsView = ({ principals, disableChange = false, statusMessage, onChange }: IPrincipalsViewArgs) => {
  const [awsPrincipals, updatePrincipals] = useState<AWSPrincipal[]>(principals);
  const [index, updateIndex] = useState<number>(-1);
  const [open, setOpen] = useState(false);

  const context = useContext<IWizardContext>(WizardContext);

  useEffect(() => {
    const { existingState } = context;
    const { data = undefined } = existingState || {};
    updatePrincipals(data || principals);
  }, []);

  const updateWizardContext = (data: AWSPrincipal[]) => {
    const { updateWizardState } = context;
    updateWizardState && updateWizardState({ isValid: true, data });
  };

  const reset = () => {
    updateIndex(-1);
    setOpen(false);
  };

  const updateStateAndWizardAndHandleCallback = (newPrincipals: AWSPrincipal[]) => {
    updatePrincipals(newPrincipals);
    updateWizardContext(newPrincipals);
    onChange && onChange(newPrincipals);
    reset();
  };

  const addPrincipal = (data: AWSPrincipal) => {
    updateStateAndWizardAndHandleCallback([...awsPrincipals, data]);
  };

  const savePrincipal = (data: AWSPrincipal, index: number) => {
    const newPrincipals = [...awsPrincipals];
    newPrincipals[index] = data;
    updateStateAndWizardAndHandleCallback(newPrincipals);
  };

  const isEditMode = () => index !== -1;

  return (
    <span data-testid="principalsView">
      <PrincipalViewHeader disableChange={disableChange} onAddPrincipal={() => setOpen(true)} statusMessage={statusMessage} />

      {!awsPrincipals.length && <EmptyPrincipalMessage />}

      <PrincipalList
        awsPrincipals={awsPrincipals}
        onEdit={(index: number) => {
          updateIndex(index);
          setOpen(true);
          onChange && onChange(awsPrincipals);
        }}
        onDelete={(index: number) => {
          const newPrincipals = awsPrincipals.filter((principalToDelete, idx) => idx !== index) || [];
          updatePrincipals(newPrincipals);
          updateWizardContext(newPrincipals);
          onChange && onChange(newPrincipals);
        }}
        disableChange={disableChange}
      />

      {open && (
        <PrincipalsAdditionFormModalView
          isEditMode={isEditMode()}
          onCancel={reset}
          onAdd={addPrincipal}
          onSave={(data: AWSPrincipal) => {
            savePrincipal(data, index);
          }}
          principalToEdit={awsPrincipals[index]}
        />
      )}
    </span>
  );
};

export default PrincipalsView;
