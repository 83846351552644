//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React from "react";
import { Segment } from "semantic-ui-react";
import { Notification } from "../../api/notification/v1/notification";
import NotificationItem from "./NotificationItem";

interface iNotificationItemListArgs {
  onItemClick: (notification: Notification) => void;
  list: Notification[];
}
const NotificationItemList = ({ onItemClick, list = [] }: iNotificationItemListArgs) => {
  return (
    <div className="notification-list" data-testid="notificationList">
      {list.map((item: Notification) => {
        const { id = "", title = "", created_at = undefined, type = "", read_at } = item;
        return (
          <NotificationItem
            id={id}
            key={id}
            title={title}
            createdOn={created_at}
            type={type}
            onItemClick={() => onItemClick(item)}
            isRead={!isEmpty(read_at)}
          />
        );
      })}
      {!list.length && (
        <>
          <Segment>There are currently no notifications</Segment>
        </>
      )}
    </div>
  );
};

export default NotificationItemList;
