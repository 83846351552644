//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchDeploymentModels = () => {
  const { project } = useProjectDetailsContext();
  const { isExisting, data: deployment } = useFetchDeploymentForEdit();
  const fetchModels = async () => {
    let request = {
      project_id: project.id,
    } as { [key: string]: string | undefined };
    if (isExisting) {
      request.deployment_id = deployment?.id;
    }
    return await apiClients.dataClient.ListDeploymentModels(request);
  };
  return useQuery([project.id, isExisting ? deployment?.id : "", "deploymentModels"], fetchModels, {
    refetchOnWindowFocus: false,
  });
};
