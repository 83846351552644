import { useQuery } from "@tanstack/react-query";
import { History } from "history";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions, ListPrepaidDeploymentsRequest as ApiListPrepaidDeploymentsRequest, Organization } from "../../api/lib";
import { Routes } from "../../routes";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

const API_REFETCH_INTERVAL = 10000;

export const useSetupProjectData = () => {
  useUpdateTopMenu();
  const { checkProjectPermission } = useCheckPermissionByUrl();
  const canListDeployments = checkProjectPermission("data.deployment.list");
  const canListPrepaidDeployments = checkProjectPermission("prepaid.prepaiddeployment.list");
  const canListCACertificates = checkProjectPermission("crypto.cacertificate.list");
  const canListIPAllowlists = checkProjectPermission("security.ipallowlist.list");
  const canListIAMProviders = checkProjectPermission("security.iamprovider.list");
  const { project } = useGlobalStore();
  const projectId = project.id || "";
  const organizationId = project.organization_id || "";
  const { data: deployments, refetch: refetchDeployments } = useQuery({
    queryKey: [projectId, "deployments"],
    queryFn: () => fetchDeployments({ projectId }),
    enabled: canListDeployments,
    refetchInterval: API_REFETCH_INTERVAL,
  });
  const { data: prepaidDeployments, refetch: refetchPrepaidDeployments } = useQuery({
    queryKey: [projectId, organizationId, "prepaidDeployments"],
    queryFn: () => fetchPrepaidDeployments({ organizationId }),
    enabled: canListPrepaidDeployments,
    refetchInterval: API_REFETCH_INTERVAL,
  });
  const { data: cacertificates, refetch: refetchCACertificates } = useQuery({
    queryKey: [projectId, "cacertificates"],
    queryFn: () => fetchCACertificates({ projectId }),
    enabled: canListCACertificates,
    refetchInterval: API_REFETCH_INTERVAL,
  });
  const { data: ipallowlists, refetch: refetchIPAllowlists } = useQuery({
    queryKey: [projectId, "ipallowlists"],
    queryFn: () => fetchIPAllowlists({ projectId }),
    enabled: canListIPAllowlists,
    refetchInterval: API_REFETCH_INTERVAL,
  });
  const { data: iamproviders, refetch: refetchIAMProviders } = useQuery({
    queryKey: [projectId, "iamproviders"],
    queryFn: () => fetchIAMProviders({ projectId }),
    enabled: canListIAMProviders,
    refetchInterval: API_REFETCH_INTERVAL,
  });
  return {
    deployments,
    prepaidDeployments,
    cacertificates,
    ipallowlists,
    iamproviders,
    refetchDeployments,
    refetchPrepaidDeployments,
    refetchCACertificates,
    refetchIPAllowlists,
    refetchIAMProviders,
  };
};

const fetchDeployments = async ({ projectId }: { projectId: string }) => {
  const listOptions = { context_id: projectId } as ApiListOptions;
  const deployments = await apiClients.dataClient.ListDeployments(listOptions);
  return deployments;
};
const fetchIAMProviders = async ({ projectId }: { projectId: string }) => {
  const req = { context_id: projectId || "" } as ApiListOptions;
  const iamproviders = await apiClients.securityClient.ListIAMProviders(req);
  return iamproviders;
};
const fetchCACertificates = async ({ projectId }: { projectId: string }) => {
  const req = { context_id: projectId || "" } as ApiListOptions;
  const cacertificates = await apiClients.cryptoClient.ListCACertificates(req);
  return cacertificates;
};
const fetchIPAllowlists = async ({ projectId }: { projectId: string }) => {
  const req = { context_id: projectId || "" } as ApiListOptions;
  const ipallowlist = await apiClients.securityClient.ListIPAllowlists(req);
  return ipallowlist;
};
const fetchPrepaidDeployments = async ({ organizationId }: { organizationId: string }) => {
  const req = { organization_id: organizationId } as ApiListPrepaidDeploymentsRequest;
  const prepaidDeployments = await apiClients.prepaidClient.ListPrepaidDeployments(req);
  return prepaidDeployments;
};

const updateTopMenu = ({ topMenuInfo, selectedOrganization, history }: { topMenuInfo: TopMenuInfo; selectedOrganization: Organization; history: History }) => {
  topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(history));

  const breadCrumb = new Array<BreadCrumbItem>(
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(selectedOrganization.id || ""))
  );
  topMenuInfo.setBreadCrumbItems(breadCrumb);
  topMenuInfo.setImageSource("project");
};
const useUpdateTopMenu = () => {
  const location = useLocation();
  const history = useHistory();
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { project } = useGlobalStore();
  useEffect(() => {
    updateTopMenu({
      topMenuInfo,
      selectedOrganization,
      history,
    });
  }, [project, location, selectedOrganization, history]);
};
