//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import {
  AttachProjectToAuditLogRequest as ApiAttachProjectToAuditLogRequest,
  AuditLogArchiveList as ApiAuditLogArchiveList,
  ListAuditLogArchivesRequest as ApiListAuditLogArchivesRequest,
  AuditLogList as ApiAuditLogList,
  AuditLogTopic as ApiAuditLogTopic,
  IDOptions as ApiIDOptions,
  ListAuditLogsRequest as ApiListAuditLogsRequest,
  Project as ApiProject,
  Organization as ApiOrganization,
  isNotFound,
} from "../../api/lib";
import {
  Confirm,
  ConfirmInfo,
  ErrorMessage,
  ContentActionButtonCancel,
  ContentActionButtonEdit,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import AuditLogList from "./AuditLogList";
import AuditLogArchiveList from "./AuditLogArchiveList";
import AuditLogEventList from "./AuditLogEventList";
import { Routes } from "../../routes";

interface IProjectOrganizationAuditLogTabProps {
  project: ApiProject;
  organization: ApiOrganization;
  loading: boolean;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
}

interface IProjectAuditLogTabState {
  errorMessage?: string;
  auditLogs?: ApiAuditLogList;
  auditLogArchives?: ApiAuditLogArchiveList;
  selected_auditlog_id?: string;
  selected_auditlogarchive_id?: string;
  all_topics?: ApiAuditLogTopic[];
  edit_selection: boolean;
  prevProjectId?: string;
  refreshNeeded: boolean;
  processingSelect: boolean;
  confirmInfo?: ConfirmInfo;
}

// The component to select/show the audit logs for a project.
class ProjectAuditLogTab extends Component<IProjectOrganizationAuditLogTabProps, IProjectAuditLogTabState> {
  state = {
    errorMessage: undefined,
    auditLogs: undefined,
    auditLogArchives: undefined,
    prevProjectId: undefined,
    selected_auditlog_id: undefined,
    selected_auditlogarchive_id: undefined,
    edit_selection: false,
    refreshNeeded: false,
    processingSelect: false,
    confirmInfo: undefined,
  } as IProjectAuditLogTabState;

  static getDerivedStateFromProps(props: IProjectOrganizationAuditLogTabProps, state: IProjectAuditLogTabState) {
    if (props.project.id != state.prevProjectId) {
      return {
        prevProjectId: props.project.id,
        refreshNeeded: true,
        processing: false,
        selected_auditLog_id: undefined,
        selected_auditlogarchive_id: undefined,
      };
    }
    return {};
  }

  componentDidMount() {
    this.refreshAuditLogs();
    this.refreshAuditLogArchives();
    this.refreshAuditLogTopics();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshAuditLogs();
        this.refreshAuditLogArchives();
        this.refreshAuditLogTopics();
      });
    }
  }

  reloadAuditLogTopics = async () => {
    const list = await apiClients.auditClient.ListAuditLogTopics({});
    this.setState({ all_topics: list.items });
  };

  refreshAuditLogTopics = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogTopics);
  };

  reloadAuditLogs = async () => {
    const req = {
      organization_id: this.props.project.organization_id,
      include_deleted: false, // On project level we do not include deleted audit logs
    } as ApiListAuditLogsRequest;
    const list = await apiClients.auditClient.ListAuditLogs(req);
    this.setState({ auditLogs: list });

    try {
      const auditlog = await apiClients.auditClient.GetAuditLogAttachedToProject({ id: this.props.project.id });
      this.setState({ selected_auditlog_id: auditlog.id }, this.refreshAuditLogArchives);
    } catch (e) {
      if (isNotFound(e)) {
        this.setState({ selected_auditlog_id: undefined, selected_auditlogarchive_id: undefined });
      } else {
        throw e;
      }
    }
  };

  refreshAuditLogs = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogs);
  };

  reloadAuditLogArchives = async () => {
    if (!!this.state.selected_auditlog_id) {
      const req = {
        auditlog_id: this.state.selected_auditlog_id,
        project_id: this.props.project.id,
      } as ApiListAuditLogArchivesRequest;
      const list = await apiClients.auditClient.ListAuditLogArchives(req);
      this.setState({ auditLogArchives: list });
      const items = list.items || [];
      if (items.length === 1) {
        this.setState({ selected_auditlogarchive_id: items[0].id });
      }
    } else {
      this.setState({ auditLogArchives: undefined, selected_auditlogarchive_id: undefined });
    }
  };

  refreshAuditLogArchives = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchives);
  };

  getAuditLogName = (id: string) => {
    const auditLogs = this.state.auditLogs || {};
    const auditLogItems = auditLogs.items || [];
    const auditLog = auditLogItems.find((c) => c.id == id);
    if (auditLog) {
      return auditLog.name;
    }
    return "";
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onSelectAuditLog = async (id: string) => {
    const auditLogName = this.getAuditLogName(id);
    const confirmInfo = {
      header: "Select Audit Log",
      content: `Are you sure you want to use audit log '${auditLogName}' for all deployments in this project?`,
      onConfirm: async () => {
        try {
          this.setState({ processingSelect: true, selected_auditlogarchive_id: undefined });
          const req = {
            project_id: this.props.project.id,
            auditlog_id: id,
          } as ApiAttachProjectToAuditLogRequest;
          await apiClients.auditClient.AttachProjectToAuditLog(req);
          this.refreshAuditLogs();
        } catch (e) {
          this.setState({ errorMessage: `Failed to select audit log for this project: ${e}` });
        } finally {
          this.setState({ confirmInfo: undefined, processingSelect: false, edit_selection: false });
        }
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };
  onDeselectAuditLog = async () => {
    const confirmInfo = {
      header: "Unselect Audit Log",
      content: `Are you sure no longer want to capture audit events for all deployments in this project?`,
      onConfirm: async () => {
        try {
          this.setState({ processingSelect: true, selected_auditlogarchive_id: undefined });
          const req = {
            id: this.props.project.id,
          } as ApiIDOptions;
          await apiClients.auditClient.DetachProjectFromAuditLog(req);
          this.refreshAuditLogs();
        } catch (e) {
          this.setState({ errorMessage: `Failed to unselect audit log for this project: ${e}` });
        } finally {
          this.setState({ confirmInfo: undefined, processingSelect: false, edit_selection: false });
        }
      },
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onEditSelection = () => {
    this.setState({ edit_selection: true });
  };
  onCancelEditSelection = () => {
    this.setState({ edit_selection: false });
  };

  onSelectAuditLogArchive = (id: string) => {
    this.setState({ selected_auditlogarchive_id: id });
  };

  render() {
    const hasPermission = (p: Permission) => {
      const hasPermissionByUrl = this.props.hasPermissionByUrl;
      return !!(hasPermissionByUrl && hasPermissionByUrl(this.props.project.url || "", ResourceType.AuditLog, p));
    };
    const has_attach = hasPermission("audit.auditlogattachment.create");
    const has_detach = hasPermission("audit.auditlogattachment.delete");
    const can_select = has_attach && has_detach;
    const is_proj_deleted = !!this.props.project.is_deleted;
    const edit_selection = this.state.edit_selection;

    const allTopics = this.state.all_topics || [];
    const has_selected_auditlog = !!this.state.selected_auditlog_id;
    const has_selected_auditlogarchive = !!this.state.selected_auditlogarchive_id;
    const has_auditLogs = !_.isEmpty((this.state.auditLogs || {}).items);

    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processingSelect} message="Changing selected audit log, please wait..." />
        <Section>
          <SectionHead>
            <SectionHeader
              title="Audit log"
              help={
                <div>
                  <p className="para">
                    All audit log events resulting from the deployments in a project, are sent to all the destinations of the selected audit log.
                  </p>
                </div>
              }
            />
            <SectionButtons>
              {can_select && has_auditLogs && !is_proj_deleted && !edit_selection && <ContentActionButtonEdit primary onClick={this.onEditSelection} />}
              {can_select && has_auditLogs && !is_proj_deleted && edit_selection && <ContentActionButtonCancel onClick={this.onCancelEditSelection} />}
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            {!has_auditLogs && (
              <div>
                <p className="para">There are no audit logs in your organization.</p>
                <p className="para">
                  Go to{" "}
                  <a className="text-link" href={Routes.dashboard_organization_access_controlWithId(this.props.organization.id || "")}>
                    Organization - Access Control
                  </a>{" "}
                  to create an audit log.
                </p>
              </div>
            )}
            {!edit_selection && has_auditLogs && (
              <AuditLogList
                {...this.props}
                {...this.state}
                ownerType="project"
                all_topics={[]}
                disable_edit
                disable_default
                disable_delete
                disable_select
                only_selected
                onRefreshList={this.refreshAuditLogs}
                onClickDeselect={() => {}}
                onClickSelect={() => {}}
              />
            )}
            {edit_selection && has_auditLogs && (
              <AuditLogList
                {...this.props}
                {...this.state}
                ownerType="project"
                all_topics={[]}
                disable_edit
                disable_default
                disable_delete
                disable_select={!can_select}
                onRefreshList={this.refreshAuditLogs}
                onClickDeselect={this.onDeselectAuditLog}
                onClickSelect={this.onSelectAuditLog}
              />
            )}
          </SectionContent>
        </Section>
        {!edit_selection && has_selected_auditlog && (
          <Section>
            <SectionHead>
              <SectionHeader
                title="Audit log archives"
                help={
                  <div>
                    <p className="para">Audit log archives store audit events in the cloud.</p>
                    <p className="para">Each listed audit log archive is used for a specific deployment in this project.</p>
                    <p className="para">Audit log archives are created on demand.</p>
                  </div>
                }
              />
            </SectionHead>
            <SectionContent>
              <AuditLogArchiveList
                {...this.props}
                selected_auditlogarchive_id={this.state.selected_auditlogarchive_id}
                organization_id={this.props.organization.id || ""}
                auditLogArchives={this.state.auditLogArchives}
                onRefreshList={this.refreshAuditLogArchives}
                onClickSelect={this.onSelectAuditLogArchive}
                hide_project
              />
            </SectionContent>
          </Section>
        )}
        {!edit_selection && has_selected_auditlog && (
          <Section>
            <SectionHead>
              <SectionHeader
                title="Audit log events"
                help={
                  <div>
                    <p className="para">Audit log events are recorded, when an audit log has been created, for every action in the deployment.</p>
                    <p className="para">View these events, filtered by time and/or topic.</p>
                  </div>
                }
              />
            </SectionHead>
            <SectionContent>
              {!has_selected_auditlogarchive && <div>No audit log archive has been selected.</div>}
              {has_selected_auditlogarchive && (
                <AuditLogEventList
                  {...this.props}
                  auditLogId={this.state.selected_auditlog_id || ""}
                  auditLogArchiveId={this.state.selected_auditlogarchive_id || ""}
                  all_topics={allTopics}
                />
              )}
            </SectionContent>
          </Section>
        )}
      </div>
    );
  }
}

export default ProjectAuditLogTab;
