import { Deployment, Project } from "../../api/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";

export const useCheckPermissionByUrl = () => {
  const { hasPermissionByUrl } = useWithRefresh();
  const { selectedOrganization } = useDashboardContext();
  const checkOrgPermission = (permission: Permission) => {
    return !!hasPermissionByUrl?.(selectedOrganization?.url || "", ResourceType.Organization, permission);
  };
  const { deployment } = useDeploymentStore();
  const checkDeploymentPermission = (permission: Permission) => {
    return !!hasPermissionByUrl?.(deployment?.url || "", ResourceType.Deployment, permission);
  };
  const { project } = useGlobalStore();
  const checkProjectPermission = (permission: Permission) => {
    return !!hasPermissionByUrl?.(project.url || "", ResourceType.Project, permission);
  };
  const checkSpecificProjectPermission = (permission: Permission, currentProject: Project) => {
    return hasPermissionByUrl?.(currentProject.url || "", ResourceType.Project, permission);
  };
  const checkSpecificDeploymentPermission = (permission: Permission, currentDeployment: Deployment) => {
    return hasPermissionByUrl?.(currentDeployment.url || "", ResourceType.Deployment, permission);
  };

  return {
    checkOrgPermission,
    checkDeploymentPermission,
    checkProjectPermission,
    checkSpecificProjectPermission,
    checkSpecificDeploymentPermission,
    hasPermissionByUrl,
  };
};
