//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchNodeSizes } from "./useFetchNodeSizes";

export const useReinitializeNodeSize = () => {
  const { values, setFieldValue } = useFormikContext<NewDeploymentFormValues>();

  const { data: nodeSizes, isRefetching } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const { isExisting } = useFetchDeploymentForEdit();

  useEffect(() => {
    // nodeSizes will change when values.regionId, values.modelId changes
    if (isRefetching || !nodeSizes?.items?.length || isExisting) {
      return;
    }
    // if current selected nodeSizeId is not available in the new nodeSizes, set the first nodeSizeId
    const nodeSizeId = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.id || nodeSizes.items[0].id;
    if (nodeSizeId) {
      setFieldValue("nodeSizeId", nodeSizeId);
    }
  }, [values.regionId, values.modelId, isRefetching, nodeSizes]);
};
