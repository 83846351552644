//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import styled from "@emotion/styled";
import React from "react";
import { Form } from "semantic-ui-react";
import { AuditLogTopic as ApiAuditLogTopic } from "../../api/lib";

const TopicsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const TopicsLabel = styled("span")`
  white-space: nowrap !important;
`;

const InlineDropdown = styled(Form.Dropdown)`
  display: inline-block;
`;

interface IEditExcludedTopicsViewArgs {
  empty_text?: string;
  non_empty_text?: string;
  readonly?: boolean;
  disabled?: boolean;
  all_topics: ApiAuditLogTopic[];
  excluded_topics: string[];

  onExcludedTopicsChanged: (value: string[]) => void;
}

export const EditExcludedTopicsView = ({ ...args }: IEditExcludedTopicsViewArgs) => {
  const readonly = !!args.readonly;
  const hasTopics = !_.isEmpty(args.excluded_topics);
  if (readonly && !hasTopics) {
    return <span>None</span>;
  }
  return (
    <TopicsContainer>
      <TopicsLabel>{!hasTopics ? `${args.empty_text || "None"} ` : args.non_empty_text || ""}</TopicsLabel>
      <InlineDropdown
        disabled={args.disabled || readonly}
        inline
        scrolling
        name="excluded_topics"
        multiple
        value={args.excluded_topics}
        onChange={(e, d) => args.onExcludedTopicsChanged(d.value as string[])}
        options={args.all_topics.map((t) => {
          return { key: t.topic, text: t.topic, value: t.topic, icon: !!t.for_deployment ? "database" : "cloud" };
        })}
      />
    </TopicsContainer>
  );
};
