//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { RoleBinding } from "../../api/lib";
import { usePolicyContext } from "./usePolicyContext";

/**
 * This function groups the bindings by member_id
 * input: [ { id: "id", member_id: "memberId", role_id:"auditlog-archive-viewer" }, ...]
 * output: { "memberId": [ { id: "id", member_id: "memberId", role_id:"auditlog-archive-viewer" }, ...] }
 */

const getGroupedBindings = (bindings: Array<RoleBinding> | undefined) => {
  let newBindings =
    bindings?.reduce(
      (acc, binding) => {
        const member_id = binding.member_id;
        if (!member_id) {
          return acc;
        }
        if (!acc[member_id]) {
          acc[member_id] = [];
        }
        acc[member_id].push(binding);
        return acc;
      },
      {} as {
        [member_id: string]: Array<RoleBinding>;
      }
    ) || {};
  //sort  the bindings by role_id
  for (const member_id in newBindings) {
    newBindings[member_id].sort((a, b) => a.role_id?.localeCompare(b?.role_id!)!);
  }
  return newBindings;
};

export const useFetchPolicies = () => {
  const { resourceUrl } = usePolicyContext();
  const fetchPolicy = async () => {
    const policy = await apiClients.iamClient.GetPolicy({
      url: resourceUrl,
    });
    return policy;
  };
  return useQuery({
    queryKey: [resourceUrl, "policy"],
    queryFn: fetchPolicy,
    select: (data) => {
      const groupedBindings = getGroupedBindings(data.bindings);
      return { ...data, groupedBindings };
    },
    enabled: !!resourceUrl,
  });
};
