//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DropdownProps, Form, Grid, InputOnChangeData, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { PermissionList as ApiPermissionList, Role as ApiRole } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Loading,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";

const UpdatePermissionsView = ({ ...args }: IUpdateRoleViewArgs) =>
  args.permissions && args.permissions.items ? (
    <Form.Dropdown
      label="Permissions"
      placeholder="Select Permissions"
      name="permissions"
      multiple
      scrolling
      value={args.selectedPermissions}
      onChange={args.updateFormDropdownChange}
      options={args.permissions.items.map((p) => {
        return { key: p, text: p, value: p };
      })}
    />
  ) : (
    <Form.Dropdown label="Permissions" placeholder="Select Permissions" name="permissions" loading={true} />
  );

interface IUpdateRoleViewArgs extends IUpdateRoleState, IUpdateRoleProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
  updateRole: () => void;
}

const UpdateRoleView = ({ ...args }: IUpdateRoleViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Updating role, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <Section>
          <SectionHeader title="General" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <Form.Input autoFocus required label="Name" placeholder="Name" name="name" value={args.name} onChange={args.updateFormInputChange} />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    label="Short description"
                    placeholder="Description"
                    name="description"
                    value={args.description}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader title="Permissions" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={16}>
                  <UpdatePermissionsView {...args} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonSave primary disabled={!args.active} onClick={args.updateRole} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.history.goBack} />
          </FormContentAction>
          <Loader size="mini" active={args.loading} inline />
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the UpdateRole component
interface IUpdateRoleProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onRoleUpdated: (roleId: string) => void;
}

// Interface decribing the state of the updateRole component
interface IUpdateRoleState {
  errorMessage?: string;
  processing: boolean;

  roleId?: string;
  role?: ApiRole;

  name: string;
  description: string;

  permissions?: ApiPermissionList;
  selectedPermissions: string[];
}

// Component to update a role
class UpdateRole extends Component<IUpdateRoleProps, IUpdateRoleState> {
  state = {
    errorMessage: undefined,
    processing: false,

    roleId: undefined,
    role: undefined,

    name: "",
    description: "",

    permissions: undefined,
    selectedPermissions: [],
  } as IUpdateRoleState;

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "permissions":
        const selectedPermissions = args.value as string[];
        this.setState({ selectedPermissions: selectedPermissions });
        break;
    }
  };

  updateRole = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const role = {
        id: this.state.roleId,
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        permissions: this.state.selectedPermissions,
      } as ApiRole;
      const updatedRole = await apiClients.iamClient.UpdateRole(role);

      this.props.onRoleUpdated(updatedRole.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Role update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  reloadRoleInfo = async () => {
    const idOptions = { id: this.state.roleId } as ApiIDOptions;
    const role = await apiClients.iamClient.GetRole(idOptions);
    if (!this.state.role) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadRoleInfo, role.url);
      this.setState({ name: role.name || "", description: role.description || "", selectedPermissions: role.permissions || [] });
    }
    this.setState({ role: role });
  };

  reloadPermissions = async () => {
    const permissions = await apiClients.iamClient.ListPermissions();
    this.setState({ permissions: permissions });
  };

  componentDidMount() {
    const roleId = (this.props.match.params as any).roleId;
    this.setState(
      {
        roleId: roleId,
      },
      () => {
        //notice inside the reloadRoleInfo we use the roleId
        this.props.refreshNow && this.props.refreshNow(this.reloadRoleInfo);
      }
    );
    this.props.refreshNow && this.props.refreshNow(this.reloadPermissions);
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Roles", Routes.dashboard_organization_rolesWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("role");
    this.props.topMenuInfo.setTitles(this.state.name || "Update role", "");
  };

  render() {
    if (this.state.role) {
      return (
        <UpdateRoleView
          {...this.props}
          {...this.state}
          active={!this.state.processing}
          handleDismissError={this.handleDismissError}
          updateFormInputChange={this.updateFormInputChange}
          updateFormDropdownChange={this.updateFormDropdownChange}
          updateRole={this.updateRole}
        />
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(UpdateRole);
