//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";

import { useFetchDeploymentForEdit } from "./useFetchDeploymentForEdit";

export const useFetchDeploymentMigrationStatus = () => {
  const { data: deployment } = useFetchDeploymentForEdit();
  const fetchMigrationStatus = async () => {
    if (!deployment?.id) {
      return;
    }
    try {
      const response = await apiClients.replicationClient.GetDeploymentMigration({ id: deployment.id });
      return response;
    } catch (error) {
      // If the migration status is not found, we assume the migration is completed/doesn't exist
      return {
        status: {
          phase: "completed",
          message: error.message,
          target_deployment_id: "",
        },
      };
    }
  };

  return useQuery([deployment?.id, "migrationStatus"], fetchMigrationStatus, {
    enabled: !!deployment?.id,
  });
};
