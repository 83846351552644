import React from "react";
import { DangerZoneItem } from "../../ui/lib";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { useProjectDetailsContext } from "./ProjectDetailsContextProvider";

export const ProjectDangerZoneView = () => {
  const { onClickDelete, onLockProject, onUnlockProject, isProcessing, project } = useProjectDetailsContext();
  const locked = !!project?.locked;
  const { checkProjectPermission } = useCheckPermissionByUrl();
  const has_project_update = checkProjectPermission("resourcemanager.project.update");
  const has_project_delete = checkProjectPermission("resourcemanager.project.delete");
  return (
    <div>
      <p className="para">Find listed all critical actions concerning your project.</p>
      {!locked && (
        <DangerZoneItem buttonIcon="lock" buttonText="Lock project..." onClick={onLockProject} disabled={isProcessing || !has_project_update}>
          <strong>Lock</strong> this project. A locked project cannot be deleted without unlocking first.
        </DangerZoneItem>
      )}
      {locked && (
        <DangerZoneItem buttonIcon="unlock" buttonText="Unlock project..." onClick={onUnlockProject} disabled={isProcessing || !has_project_update}>
          <strong>Unlock</strong> this project. You must unlock before the project can be deleted.
        </DangerZoneItem>
      )}
      <DangerZoneItem
        buttonIcon="trash"
        buttonColor="red"
        buttonText="Delete project..."
        onClick={onClickDelete}
        disabled={isProcessing || locked || !has_project_delete}
      >
        <strong>Delete</strong> this project and all deployments (and other resources) in it.
      </DangerZoneItem>
    </div>
  );
};
