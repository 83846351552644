import { useFeature } from "flagged";
import React from "react";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import AuditLogNotSupportedTab from "../auditlog/AuditLogNotSupportedTab";
import OrganizationAuditLogTab from "../auditlog/OrganizationAuditLogTab";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { Redirect } from "react-router-dom";
import { Routes } from "../../routes";

export const OrganizationAuditLogsWrap = () => {
  const isAuditLogEnabled = useFeature("auditlog");
  const { selectedOrganization } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const has_auditlog_feature = selectedOrganization?.tier?.has_auditlog_feature;
  const has_auditlog_list = !!checkOrgPermission("audit.auditlog.list");
  const { refreshNow, hasPermissionByUrl } = useWithRefresh();

  if (isAuditLogEnabled && !!has_auditlog_feature && has_auditlog_list) {
    return <OrganizationAuditLogTab organization={selectedOrganization} hasPermissionByUrl={hasPermissionByUrl} refreshNow={refreshNow} loading={false} />;
  }
  if (isAuditLogEnabled && !has_auditlog_feature) {
    return <AuditLogNotSupportedTab />;
  }
  return <Redirect to={Routes.dashboard_organization_overviewWithId(selectedOrganization?.id || "")} />;
};
