//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { createContext, useEffect, useState } from "react";
import { Header, Icon, Label, Modal, Segment } from "semantic-ui-react";
import { FormActionButton, FormActionButtonCreate } from "../../ui/_buttons";
import { ErrorMessage } from "../../ui/_errorMessage";
import { FlexBox } from "../../ui/_flex";
import { Loading } from "../../ui/_loading";
import useWizard, { WizardStep } from "./useWizard";

const StyledMenuBar = styled.div`
  min-width: 300px;
  background-color: var(--gray-900);
  min-height: 600px;
`;

const StyledMenuItem = styled(Label)`
  height: 30px;
  line-height: 30px;
  cursor: default;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 1rem !important;
  color: var(--gray-600) !important;
  display: block !important;
  padding: 26px !important;
  font-weight: normal;
`;

const StyledModal = styled(Modal)`
  position: relative !important;
  width: 100%;

  & .page-header {
    width: 100%;
    padding: 30px 20px 0px 30px !important;
  }

  & .wizard-component {
    padding: 30px !important;
    max-height: 75% !important;
  }

  & .modal-actions {
    position: absolute !important;
    width: 100%;
    bottom: 30px !important;
    padding: 0 30px 0 8px !important;
  }
`;

const StyledContentHolder = styled.div`
  width: 100% !important;
  position: relative;
`;

export type UpdateWizardStateArgs = {
  isValid: boolean;
  data: any;
  isCompleted?: boolean;
};

export interface IWizardViewState {
  [id: string]: UpdateWizardStateArgs;
}
interface IWizardViewArgs {
  stepHeader: string;
  steps: WizardStep[];
  handleConfirm: (state: IWizardViewState) => void;
  onStepChange?: (step: WizardStep) => void;
  onClose?: () => void;
  loading?: boolean;
  error?: unknown;
}

export interface IWizardContext {
  isComponentStateValid?: (isValid: boolean) => void;
  updateWizardState?: (state: UpdateWizardStateArgs) => void;
  currentStepID?: string;
  existingState?: any;
}

export const WizardContext = createContext<IWizardContext>({});

const Wizard = ({ handleConfirm, stepHeader, steps, onClose, loading, error }: IWizardViewArgs) => {
  const [isNextStepAllowed, updateNextStepState] = useState<boolean>(false);
  const [wizardState, setWizardState] = useState<IWizardViewState>({});
  const [errorObj, setErrorObj] = useState<unknown>(error);

  useEffect(() => {
    setErrorObj(error);
  }, [error]);

  const { handleBack, handleNext, getCurrentStep, getSteps, isLastStep, isFirstStep } = useWizard(steps.sort((a, b) => a.position - b.position));

  const { component: Step, title, position, ignoreValidation, id } = getCurrentStep();

  const getIcon = (step: WizardStep, currentStep: number): React.ReactNode => {
    const { position } = step;

    if (position < currentStep) {
      return <Icon name="check circle outline" color="green" />;
    } else if (position === currentStep) {
      return <Icon name="arrow circle right" className="color-white" />;
    }
    return <Icon name="circle" />;
  };

  const updateWizardState = ({ isValid, data }: UpdateWizardStateArgs): void => {
    const { id } = getCurrentStep();
    if (!!data) {
      setWizardState({ ...wizardState, [id]: { isValid, data } });
    }
    updateNextStepState(isValid);
  };

  const handleConfirmation = (): void => {
    handleConfirm(wizardState);
  };

  if (!steps.length) {
    return null;
  }

  return (
    <StyledModal open size="large">
      <FlexBox width="100%">
        <StyledMenuBar>
          <Header as="h4" textAlign="left" className="color-white page-header">
            {stepHeader}
          </Header>
          {getSteps().map((step) => (
            <StyledMenuItem key={step.id} data-testid="wizardStep">
              {getIcon(step, position)}
              <span className={step.position === position ? "color-white strong" : ""}>{step.menuLabel}</span>
            </StyledMenuItem>
          ))}
        </StyledMenuBar>

        <StyledContentHolder>
          {!!errorObj && !loading && (
            <>
              <Header size="large" className="page-header" data-testid="errorHeader">
                <FlexBox width="100%" justify="space-between">
                  Something went wrong!
                  <Icon name="close" size="small" onClick={onClose} className="pointer" />
                </FlexBox>
              </Header>
              <Segment className="boxshadow-none border-none">
                <ErrorMessage active={!!errorObj} message={errorObj} />
                <div data-testid="errorHelp">You can go back and fix the step or close and try again</div>
              </Segment>
              <Modal.Actions className="modal-actions">
                <FlexBox direction="row" justify="space-between" width="100%">
                  <FormActionButton
                    onClick={() => {
                      handleBack();
                      setErrorObj(undefined);
                    }}
                    icon="chevron left"
                    labelPosition="left"
                    disabled={getCurrentStep().position === 1}
                    title="Back"
                  />
                  <FormActionButton onClick={onClose} title="Close" icon="cancel" />
                </FlexBox>
              </Modal.Actions>
            </>
          )}

          {loading && <Loading message="Loading up rockets and processing changes" className="height-full" />}

          {!errorObj && !loading && (
            <>
              <Header size="large" className="page-header" data-testid="wizardStepHeader">
                <FlexBox width="100%" justify="space-between">
                  {title}
                  <Icon name="close" size="small" onClick={onClose} className="pointer" />
                </FlexBox>
              </Header>
              <Modal.Content scrolling className="wizard-component">
                <WizardContext.Provider value={{ updateWizardState, existingState: wizardState[id] }}>
                  <Step />
                </WizardContext.Provider>
              </Modal.Content>
              <Modal.Actions className="modal-actions">
                <FlexBox direction="row" justify="space-between" width="100%">
                  {isFirstStep() ? (
                    <FormActionButton onClick={onClose} icon="cancel" labelPosition="left" title="Cancel" />
                  ) : (
                    <FormActionButton onClick={handleBack} icon="chevron left" labelPosition="left" disabled={getCurrentStep().position === 1} title="Back" />
                  )}
                  <FormActionButtonCreate
                    icon="chevron right"
                    primary
                    onClick={isLastStep() ? handleConfirmation : handleNext}
                    disabled={!ignoreValidation && !isNextStepAllowed}
                    title={isLastStep() ? "Confirm Settings" : "Next"}
                  />
                </FlexBox>
              </Modal.Actions>
            </>
          )}
        </StyledContentHolder>
      </FlexBox>
    </StyledModal>
  );
};

export default Wizard;
