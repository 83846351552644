//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { APIKey as ApiAPIKey, APIKeyList as ApiAPIKeyList, IDOptions as ApiIDOptions, ListOptions as ApiListOptions, User as ApiUser } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Confirm, ConfirmInfo, ErrorMessage, ListActionDelete, ListActionRevoke, LoaderBoxForTable as LoaderBox, Loading } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CreateApiKey from "../apikeys/CreateApiKey";
import { IUserDetailsAPI } from "../user/UserDetails";

interface IApiKeysListViewArgs extends IWithRefreshProps, RouteComponentProps {
  keys?: ApiAPIKeyList;
  loading: boolean;
  onClickRevoke: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const ApiKeysListView = ({ ...args }: IApiKeysListViewArgs) => {
  if (!args.keys) {
    return <Loading />;
  }
  if (!args.keys.items || args.keys.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.keys.items} loading={args.loading} />;
};

// Interface describing the api keys list
interface IListView {
  items: ApiAPIKey[];
  loading: boolean;
  onClickRevoke: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView
          {...args}
          key={item.id}
          item={item}
          onClickDelete={() => args.onClickDelete(item.id || "")}
          onClickRevoke={() => args.onClickRevoke(item.id || "")}
        />
      ))}
    </Table.Body>
  </Table>
);

// Interface describing an organization
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Key ID</Table.HeaderCell>
      <Table.HeaderCell>Organization</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Expires</Table.HeaderCell>
      <Table.HeaderCell>Revoked</Table.HeaderCell>
      <Table.HeaderCell>ReadOnly</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

interface IRowView {
  item: ApiAPIKey;
  onClickRevoke: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell className="mask-data">{args.item.id}</Table.Cell>
    <Table.Cell>{args.item.organization_id && args.item.organization_id.length != 0 ? args.item.organization_id : "-"}</Table.Cell>
    <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{args.item.is_expired ? "Expired" : args.item.expires_at ? moment(args.item.expires_at).fromNow() : "-"}</Table.Cell>
    <Table.Cell>{args.item.is_revoked ? "Yes" : "No"}</Table.Cell>
    <Table.Cell>{args.item.is_readonly ? "Yes" : "No"}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        <ListActionRevoke tooltip="Revoke API Key" onClick={args.onClickRevoke} />
        <ListActionDelete tooltip="Delete API Key" onClick={args.onClickDelete} />
      </div>
    </Table.Cell>
  </Table.Row>
);

const EmptyView = () => <div>No api keys available</div>;

interface IApiKeysListProps extends IWithRefreshProps, RouteComponentProps {
  api: IUserDetailsAPI;
  creatingAPIKey: boolean;
  onCloseCreateAPIKey: () => void;
}

interface IApiKeysListState {
  apiKeys?: ApiAPIKeyList;
  errorMessage?: string;
  processing: boolean;
  user?: ApiUser;
  confirmInfo?: ConfirmInfo;
}

class ApiKeysList extends Component<IApiKeysListProps, IApiKeysListState> {
  state = {
    errorMessage: undefined,
    apiKeys: undefined,
    processing: false,
    user: undefined,
    confirmInfo: undefined,
  } as IApiKeysListState;

  componentDidMount() {
    this.refreshApiKeys();
  }

  reloadApiKeys = async () => {
    const user = await this.props.api.GetUser();
    const req = { context_id: user.id } as ApiListOptions;
    const keys = await apiClients.iamClient.ListAPIKeys(req);
    this.setState({ apiKeys: keys });
  };

  refreshApiKeys = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadApiKeys);
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onClickRevoke = async (id: string) => {
    const confirmInfo = {
      header: "Revoke API Key",
      content: `Are you sure you want to revoke API key with id '${id}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickRevokeConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickRevokeConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const req = { id: id } as ApiIDOptions;
      await apiClients.iamClient.RevokeAPIKey(req);
      this.refreshApiKeys();
    } catch (e) {
      this.setState({ errorMessage: `Revoking API key failed: ${e}` });
      reportError(e);
    } finally {
      this.setState({ processing: false });
    }
  };

  onClickDelete = async (id: string) => {
    const confirmInfo = {
      header: "Delete API Key",
      content: `Are you sure you want to delete API key with id '${id}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.iamClient.DeleteAPIKey(idOptions);
      this.refreshApiKeys();
    } catch (e) {
      this.setState({ errorMessage: `Deleting API key failed: ${e}` });
      reportError(e);
    } finally {
      this.setState({ processing: false });
    }
  };

  onCloseCreateAPIKey = () => {
    this.refreshApiKeys();
    this.props.onCloseCreateAPIKey();
  };

  render() {
    return (
      <div>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <Confirm confirmInfo={this.state.confirmInfo} />
        {this.props.creatingAPIKey && <CreateApiKey {...this.props} onClose={this.onCloseCreateAPIKey} />}
        <ApiKeysListView
          {...this.props}
          keys={this.state.apiKeys}
          loading={this.state.processing}
          onClickRevoke={this.onClickRevoke}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default withRefresh()(ApiKeysList);
