//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Form } from "semantic-ui-react";
import styled from "@emotion/styled";

const exampleCIDRRanges = [
  "1.2.3.4/32   # Everything after # or // is a comment",
  "88.11.0.0/16",
  "0.0.0.0/0",
  //"2001:db8::/48",
  //"0::0/0",
];

const StyledDiv = styled("div")`
  .field textarea {
    background-color: inherit;
  }
`;

const CIDRRangeSamples = () => (
  <StyledDiv>
    <Form.TextArea label="Example CIDR ranges" readOnly value={exampleCIDRRanges.join("\n")} rows={exampleCIDRRanges.length}></Form.TextArea>
  </StyledDiv>
);

export default CIDRRangeSamples;
