//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Timestamp } from "../../../api/googleTypes";
import { FlexBox } from "../../../ui/_flex";
import { RenderGuard } from "../../../util/RenderGuard";
import { DeploymentMetrics_Timeseries } from "../../../api/lib";
import { useMetricsUsageAPI, DATE_FORMAT } from "./useMetricsUsageApi";
import ServerSelector from "./ServerSelector";
import TimeFrameSelector from "./TimeFrameSelector";
import MetricsSelector from "./MetricsSelector";
import ServerIdsSelector from "./ServersIdsSelector";
import MetricsChart from "./MetricsChart";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { METRICS, RANGES, SERVERS } from "./MetricsView";

type SERVERS_METRICS_MAP = "Dbservercpu" | "Dbservermemory" | "Dbserverdisk" | "Coordinatorcpu" | "Coordinatormemory" | "Coordinatordisk";

const MultipleServerMetrics = ({
  loading,
  setError,
  setLoading,
}: {
  loading: boolean;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { id: deploymentId = "" } = useDeploymentStore().deployment;
  const [selectedServerIds, setSelectedServerIds] = useState<string[]>([]);
  const [selectedMetric, setSelectedMetric] = useState(METRICS.CPU);
  const [selectedServer, setSelectedServer] = useState(SERVERS.DBSERVER);
  const [startDate, setStartDate] = useState<Timestamp>(new Date(moment().subtract(10, "minutes").format(DATE_FORMAT)));
  const [selectedRange, setSelectedRange] = useState<number>(RANGES.MINUTES_10);

  const [dbserverCPUMetrics, setDbserverCPUMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [dbserverMemoryMetrics, setDbserverMemoryMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [dbserverDiskMetrics, setDbserverDiskMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [coordinatorCPUMetrics, setCoordinatorCPUMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [coordinatorMemoryMetrics, setCoordinatorMemoryMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);

  const { getDeploymentUsageMetrics } = useMetricsUsageAPI();

  const getMetrics = async (
    getter: () => Promise<{
      data?: DeploymentMetrics_Timeseries[];
      error?: undefined;
    }>,
    setter: React.Dispatch<React.SetStateAction<DeploymentMetrics_Timeseries[]>>
  ) => {
    const { data, error } = await getter();

    if (error) return setError(error);
    if (data) return setter(data);
  };

  const getAllMetrics = async () => {
    setError("");
    setLoading(true);

    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SERVERS.DBSERVER, metric: METRICS.CPU }), setDbserverCPUMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SERVERS.DBSERVER, metric: METRICS.MEMORY }), setDbserverMemoryMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SERVERS.DBSERVER, metric: METRICS.DISK }), setDbserverDiskMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SERVERS.COORDINATOR, metric: METRICS.CPU }), setCoordinatorCPUMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SERVERS.COORDINATOR, metric: METRICS.MEMORY }), setCoordinatorMemoryMetrics);

    setLoading(false);
  };

  const serversMetricsMap: { [key in SERVERS_METRICS_MAP]: { chartData: DeploymentMetrics_Timeseries[]; usageLabel: string } } = {
    Dbservercpu: { chartData: dbserverCPUMetrics, usageLabel: "CPU Usage" },
    Dbservermemory: { chartData: dbserverMemoryMetrics, usageLabel: "Memory Usage" },
    Dbserverdisk: { chartData: dbserverDiskMetrics, usageLabel: "Disk Usage" },
    Coordinatorcpu: { chartData: coordinatorCPUMetrics, usageLabel: "CPU Usage" },
    Coordinatormemory: { chartData: coordinatorMemoryMetrics, usageLabel: "Memory Usage" },
    Coordinatordisk: { chartData: [], usageLabel: "" },
  };

  const selectedChart: SERVERS_METRICS_MAP = `${selectedServer}${selectedMetric}`;
  const chartData = serversMetricsMap[selectedChart].chartData;
  const usageLabel = serversMetricsMap[selectedChart].usageLabel;

  useEffect(() => {
    if (deploymentId) {
      getAllMetrics();
    }
  }, [deploymentId, startDate]);

  return (
    <>
      <FlexBox direction="column" margin="0 36px 0 0">
        <ServerSelector
          loading={loading}
          selectedMetric={selectedMetric}
          selectedServer={selectedServer}
          setSelectedMetric={setSelectedMetric}
          setSelectedServer={setSelectedServer}
        />
        <RenderGuard renderIf={!loading}>
          <ServerIdsSelector chartData={chartData} selectedServerIds={selectedServerIds} setSelectedServerIds={setSelectedServerIds} />
        </RenderGuard>
        <RenderGuard renderIf={!loading && !!chartData.length}>
          <p className="para">
            <b>
              {selectedServerIds.length} out of {chartData.length} servers shown
            </b>
          </p>
        </RenderGuard>
      </FlexBox>
      <FlexBox direction="column" width="100%">
        <FlexBox justify="space-between">
          <MetricsSelector loading={loading} selectedServer={selectedServer} selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} />
          <TimeFrameSelector setStartDate={setStartDate} loading={loading} onChangeRange={setSelectedRange} selectedRange={selectedRange} />
        </FlexBox>
        <RenderGuard renderIf={!loading}>
          <MetricsChart chartData={chartData} usageLabel={usageLabel} selectedServerIds={selectedServerIds} selectedRange={selectedRange} />
        </RenderGuard>
      </FlexBox>
    </>
  );
};

export default MultipleServerMetrics;
