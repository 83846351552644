//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Loader, Popup, Statistic } from "semantic-ui-react";
import { Organization as ApiOrganization } from "../../api/lib";
import { Routes } from "../../routes";
import { ClickableStatistic, StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { useDashboardContext } from "../DashboardContextProvider";
import { InviteCollaboratorsButton } from "../dashboard/InviteCollaboratorsButton";
interface ISummaryViewArgs {
  hasProjectCount: boolean;
  projectCount: number;
  organization: ApiOrganization;
  onShowProjects: () => void;
}

const SummaryView = ({ onShowProjects, projectCount, hasProjectCount }: ISummaryViewArgs) => {
  const { selectedOrganization } = useDashboardContext();
  const shortCreatedAt = selectedOrganization.created_at ? moment(selectedOrganization.created_at).format("l") : "?";
  const longCreatedAt = selectedOrganization.created_at ? moment(selectedOrganization.created_at).format("lll") : "?";
  const widths = window.innerWidth > 1280 ? 3 : 2;
  const tier = selectedOrganization.tier || {};
  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths={widths}>
          <ClickableStatistic size="small" onClick={onShowProjects}>
            <Statistic.Label>{(projectCount || 0) == 1 ? "Project" : "Projects"}</Statistic.Label>
            <Statistic.Value>
              {hasProjectCount ? projectCount : ""}
              {!hasProjectCount && <Loader active inline size="tiny" />}
            </Statistic.Value>
          </ClickableStatistic>
          <Statistic size="small">
            <Statistic.Label>Tier</Statistic.Label>
            <Statistic.Value>{tier.name || "?"}</Statistic.Value>
          </Statistic>
          <Statistic size="small">
            <Statistic.Label>Created</Statistic.Label>
            <Statistic.Value>
              <Popup trigger={<span>{shortCreatedAt}</span>} content={longCreatedAt} />
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

const OrganizationSummary = () => {
  const history = useHistory();
  const { selectedOrganization, projects } = useDashboardContext();
  const onShowProjects = () => {
    history.push(Routes.dashboard_organization_projectsWithId(selectedOrganization.id || ""));
  };

  const projectCount = projects?.items ? projects?.items?.length : 0;
  return (
    <>
      <InviteCollaboratorsButton />
      <SummaryView onShowProjects={onShowProjects} organization={selectedOrganization} hasProjectCount={!!projects} projectCount={projectCount} />
    </>
  );
};
export default OrganizationSummary;
