//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions, Project as ApiProject, IPAllowlist as ApiIPAllowlist, IPAllowlistList as ApiIPAllowlistList } from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectIPAllowlistViewArgs extends ISelectIPAllowlistState {
  updateFormDropdownChange: (e: any, data: DropdownProps) => void;
}

const SelectIPAllowlistView = ({ ...args }: ISelectIPAllowlistViewArgs) =>
  !args.ipallowlists ? (
    <Form.Dropdown required fluid selection label="IP allowlist" name="ipallowlist" loading={true} />
  ) : (
    <Form.Dropdown
      fluid
      className="ip-allowlist-dropdown"
      selection
      label="IP allowlist"
      placeholder="Select IP allowlist"
      name="ipallowlist"
      value={args.selectedIPAllowlist && args.selectedIPAllowlist.name}
      onChange={args.updateFormDropdownChange}
      search
      noResultsMessage="No IP allowlists found."
      clearable
      options={
        args.ipallowlists.items &&
        args.ipallowlists.items.map((c) => {
          return { key: c.id, text: c.name, value: c.name };
        })
      }
    />
  );

// Interface decribing the properties of the SelectIPAllowlist component
interface ISelectIPAllowlistProps extends IWithRefreshProps {
  project: ApiProject;
  ipallowlist?: ApiIPAllowlist;
  onIPAllowlistUpdated: (ipallowlist?: ApiIPAllowlist) => void;
}

// Interface decribing the state of the SelectIPAllowlist component
interface ISelectIPAllowlistState {
  ipallowlists?: ApiIPAllowlistList;
  selectedIPAllowlist?: ApiIPAllowlist;
}

// Component to select an IP allowlist
class SelectIPAllowlist extends Component<ISelectIPAllowlistProps, ISelectIPAllowlistState> {
  state = {
    ipallowlists: undefined,
    selectedIPAllowlist: this.props.ipallowlist,
  } as ISelectIPAllowlistState;

  updateFormDropdownChange = (e: any, data: DropdownProps) => {
    switch (data.name) {
      case "ipallowlist":
        const ipallowlists = this.state.ipallowlists;
        const selectedIPAllowlist = ipallowlists && ipallowlists.items && ipallowlists.items.find((c) => c.name == data.value);
        this.setState({ selectedIPAllowlist: selectedIPAllowlist });
        this.props.onIPAllowlistUpdated(selectedIPAllowlist);
        break;
    }
  };

  reloadIPAllowlists = async () => {
    const listOptions = { context_id: this.props.project.id } as ApiListOptions;
    const lists = await apiClients.securityClient.ListIPAllowlists(listOptions);
    this.setState({ ipallowlists: lists });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadIPAllowlists);
  }

  render() {
    return <SelectIPAllowlistView {...this.props} {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectIPAllowlist);
