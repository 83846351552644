//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { Routes } from "../../routes";
import { NewDeploymentFormValues } from "../newDeployment/newDeploymentForm.types";
import { useFetchNodeSizes } from "../newDeployment/useFetchNodeSizes";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { useFetchDeploymentForEdit } from "./useFetchDeploymentForEdit";

export const UpgradeConfirmationModal = ({ onClose, status }: { onClose: () => void; status: "none" | "ask" | "confirmed" }) => {
  const history = useHistory();
  const toast = useToast();
  const { refetchDeployments } = useProjectDetailsContext();
  const { data: deployment } = useFetchDeploymentForEdit();
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const onDeploymentMigrate = async (deploymentId: string) => {
    let nodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size || 0;
    if (values.customDiskSize && values.customDiskSize[values.nodeSizeId]) {
      nodeDiskSize = values.customDiskSize[values.nodeSizeId];
    }
    try {
      await apiClients.replicationClient.CreateDeploymentMigration({
        source_deployment_id: deploymentId,
        target_deployment: {
          model: values.modelId,
          node_count: values.numberOfNodes,
          node_disk_size: nodeDiskSize,
          node_size_id: values.nodeSizeId,
        },
      });
    } catch (e) {
      const errorMessage = typeof e === "string" ? e : e?.message;
      toast({
        title: "Error",
        description: `Deployment upgrade failed: ${errorMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    refetchDeployments();
    history.replace(Routes.dashboard_project_deployment_detailsWithId(deployment?.project_id || "", deployment?.id || ""));

    onClose();
  };
  if (status !== "ask") {
    return null;
  }
  return (
    <Modal isOpen={status === "ask"} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upgrade to a paid model</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Your free deployment will be upgraded to a paid one. The free deployment will be automatically deleted after the upgrade.</Text>

          <Text>You cannot make any changes to your deployment once upgrade is started.</Text>
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={onClose}>No</Button>
          <Button
            onClick={() => {
              if (!deployment?.id) {
                return;
              }
              onDeploymentMigrate(deployment?.id);
            }}
            colorScheme="green"
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
