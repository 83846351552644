//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { FlexBox } from "../../../../ui/_flex";

const FileFields = ({ fields }: { fields: { [key: string]: string } }) => {
  return (
    <>
      {Object.keys(fields).map((key: string) => {
        return (
          <FlexBox justify="space-between" margin="10px 0" key={key}>
            <div className="file-data-header ellipsis mask-data" title={key}>
              {key}
            </div>
            <div className="file-data-value ellipsis mask-data" title={fields[key]}>
              {!!fields[key] ? fields[key] : "-"}
            </div>
          </FlexBox>
        );
      })}
    </>
  );
};

export default FileFields;
