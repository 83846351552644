//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Grid, Modal, DropdownProps, Checkbox } from "semantic-ui-react";
import {
  ListPaymentProvidersRequest as ApiListPaymentProvidersRequest,
  Organization as ApiOrganization,
  PaymentMethod as ApiPaymentMethod,
  PaymentProvider as ApiPaymentProvider,
  PaymentProviderList as ApiPaymentProviderList,
  PreparePaymentMethodRequest as ApiPreparePaymentMethodRequest,
  PreparedPaymentMethod as ApiPreparedPaymentMethod,
  CreatePaymentMethodRequest as ApiCreatePaymentMethodRequest,
} from "../../api/lib";
import { ErrorMessage, FormActionButtonSave, FormActionButtonCancel, InlineDropdown, Processing } from "../../ui/lib";
import BluesnapCreditCard from "./BluesnapCreditCard";
import { ITracking } from "../../tracking/api";
import { reportError } from "../../errors/reporting";
import { useFeature } from "flagged";
import { FlexBox } from "../../ui/_flex";
import apiClients from "../../api/apiclients";

interface ICreateViewArgs {
  paymentInfoRef: (elem: IPaymentInfo) => void;
  list?: ApiPaymentProviderList;
  provider_id?: string;
  provider?: ApiPaymentProvider;
  preparedPaymentMethod?: ApiPreparedPaymentMethod;
  onProviderChange: (id: string) => void;
  onCreate: (req: ApiCreatePaymentMethodRequest) => void;
  onCanCreate: (canCreated: boolean) => void;
  onSaveCanceled: () => void;
}

export const CreateView = ({ ...args }: ICreateViewArgs) => {
  const isBillingDebugEnabled = !!useFeature("billing-debug");
  const list = args.list || {};
  const items = list.items || [];
  const has_providers = !_.isEmpty(items);
  const options = items.map((x) => {
    return {
      key: x.id,
      text: x.description || x.name,
      value: x.id,
    };
  });
  const has_provider = !!args.provider;
  const provider = args.provider || {};
  const has_prepared_payment_method = !!args.preparedPaymentMethod;
  const preparedPaymentMethod = args.preparedPaymentMethod || {};

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width="16">
          {has_providers && (
            <InlineDropdown
              required
              selection
              fluid
              search
              placeholder="Select your payment type"
              value={args.provider_id}
              options={options}
              onChange={(e: object, d: DropdownProps) => args.onProviderChange(d.value as string)}
            />
          )}
          {!has_providers && <div>No payment providers available</div>}
        </Grid.Column>
      </Grid.Row>
      {has_provider && has_prepared_payment_method && (
        <Grid.Row>
          <Grid.Column width="16">
            {provider.type == "creditcard" && (
              <BluesnapCreditCard
                {...args}
                preparedPaymentMethod={preparedPaymentMethod}
                ref={(elem: any) => args.paymentInfoRef(elem)}
                debugLog={isBillingDebugEnabled}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

interface IPaymentMethodsAPI {
  ListPaymentProviders: (req: ApiListPaymentProvidersRequest) => Promise<ApiPaymentProviderList>;
  PreparePaymentMethod: (req: ApiPreparePaymentMethodRequest) => Promise<ApiPreparedPaymentMethod>;
  CreatePaymentMethod: (req: ApiCreatePaymentMethodRequest) => Promise<ApiPaymentMethod>;
}

// Interface decribing the properties of the create payment method component
interface ICreatePaymentMethodProps {
  api: IPaymentMethodsAPI;
  organization: ApiOrganization;
  tracking: ITracking;
  onClose: () => void;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

// Interface decribing the state of the create payment method component
interface ICreatePaymentMethodState {
  list?: ApiPaymentProviderList;
  provider_id?: string;
  provider?: ApiPaymentProvider;
  preparedPaymentMethod?: ApiPreparedPaymentMethod;
  refreshNeeded: boolean;
  last_organization_id?: string;
  errorMessage?: string;
  loading: boolean;
  creating: boolean;
  canCreate: boolean;
  isDefault: boolean;
}

interface IPaymentInfo {
  onClickSave: () => void;
}

class CreatePaymentMethod extends Component<ICreatePaymentMethodProps, ICreatePaymentMethodState> {
  private paymentInfoRef?: IPaymentInfo = undefined;

  state = {
    list: undefined,
    provider_id: undefined,
    provider: undefined,
    preparedPaymentMethod: undefined,
    refreshNeeded: false,
    last_organization_id: undefined,
    errorMessage: undefined,
    loading: false,
    creating: false,
    canCreate: false,
    isDefault: true,
  } as ICreatePaymentMethodState;

  reloadPaymentProviders = async () => {
    this.setState({ loading: true });
    try {
      const req = { organization_id: this.props.organization.id } as ApiListPaymentProvidersRequest;
      const list = await this.props.api.ListPaymentProviders(req);
      this.setState({ list: list }, async () => {
        const items = list.items || [];
        if (items.length == 1) {
          await this.onProviderChange(items[0].id || "");
        }
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  refreshPaymentProviders = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPaymentProviders);
  };

  static getDerivedStateFromProps(props: ICreatePaymentMethodProps, state: ICreatePaymentMethodState) {
    if (props.organization.id != state.last_organization_id) {
      return {
        list: undefined,
        provider_id: undefined,
        provider: undefined,
        preparedPaymentMethod: undefined,
        refreshNeeded: true,
        last_organization_id: props.organization.id,
        loading: false,
        creating: false,
        canCreate: false,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshPaymentProviders();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshPaymentProviders);
    }
  }

  onProviderChange = async (id: string) => {
    const provider = _.find((this.state.list || {}).items, { id: id });
    if (provider) {
      this.setState({ loading: true });
      try {
        const req = {
          provider_id: id,
          organization_id: this.props.organization.id,
        } as ApiPreparePaymentMethodRequest;
        const prepReq = await this.props.api.PreparePaymentMethod(req);
        this.setState({
          provider_id: id,
          provider: provider,
          preparedPaymentMethod: prepReq,
        });
      } catch (e) {
        this.setState({ errorMessage: e });
        reportError(e);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({
        provider_id: id,
        provider: undefined,
        preparedPaymentMethod: undefined,
      });
    }
  };

  onSave = () => {
    try {
      if (this.paymentInfoRef) {
        this.setState(
          {
            creating: true,
            errorMessage: undefined,
          },
          this.paymentInfoRef.onClickSave
        );
      } else {
        console.log("payerInfoRef not set");
      }
    } catch (e) {
      this.setState({ creating: false, errorMessage: `Failed to create payment method (${e})` });
    }
  };

  onSaveCanceled = () => {
    this.setState({ creating: false });
  };

  onCanCreate = (canCreate: boolean) => {
    this.setState({ canCreate: canCreate });
  };

  onCreate = async (req: ApiCreatePaymentMethodRequest) => {
    this.setState({ creating: true, errorMessage: undefined });
    try {
      const createdPaymentMethod = await this.props.api.CreatePaymentMethod(req);
      if (this.state.isDefault) {
        await apiClients.billingClient.SetDefaultPaymentMethod({
          payment_method_id: createdPaymentMethod.id,
          organization_id: this.props.organization.id,
        });
      }
      this.setState({ creating: false });
      this.props.tracking.trackPaymentMethodAdded();
      this.props.onClose();
    } catch (e) {
      this.setState({
        creating: false,
        errorMessage: e,
      });
      reportError(e);
    }
  };

  onDismissErrorMessage = () => {
    this.setState({ errorMessage: undefined });
  };

  onClickSelectAsDefault = () => {
    this.setState({ isDefault: !this.state.isDefault });
  };

  render() {
    const has_provider = !!this.state.provider_id;
    const has_prepared_payment_method = !!this.state.preparedPaymentMethod;
    const can_save = has_provider && has_prepared_payment_method && !!this.paymentInfoRef && this.state.canCreate;
    return (
      <Modal open={true} size="large">
        <Modal.Header>
          <h1 className="heading-1">Add payment method</h1>
        </Modal.Header>
        <Modal.Content>
          <Processing active={this.state.loading} message="Preparing payment method" />
          <Processing active={this.state.creating} message="Creating payment method" />
          <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissErrorMessage} />
          <CreateView
            {...this.state}
            list={this.state.list || {}}
            onProviderChange={this.onProviderChange}
            onCreate={this.onCreate}
            onCanCreate={this.onCanCreate}
            onSaveCanceled={this.onSaveCanceled}
            paymentInfoRef={(elem: IPaymentInfo) => (this.paymentInfoRef = elem)}
          />
        </Modal.Content>
        <Modal.Actions>
          <FlexBox justify="space-between" align="center">
            <Checkbox label="Set payment method as default" fitted={false} checked={this.state.isDefault} onClick={this.onClickSelectAsDefault} />
            <div>
              <FormActionButtonCancel onClick={this.props.onClose} />
              <FormActionButtonSave onClick={this.onSave} disabled={!can_save} primary data-bluesnap="submitButton" />
            </div>
          </FlexBox>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreatePaymentMethod;
