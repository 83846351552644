//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export class HelpUrl {
  static url = "https://docs.arangodb.com/";
  static training_center = "https://www.arangodb.com/arangodb-training-center/oasis-managed-service/";
  static status_page = "https://cloud-status.arangodb.com/";
  static arangodb_client_tools_download_page = "https://www.arangodb.com/download-major/";
  static arango_import_cli_options_page = "https://www.arangodb.com/docs/stable/programs-arangoimport.html";
  static arango_restore_cli_options_page = "https://www.arangodb.com/docs/stable/programs-arangorestore.html";
  static arango_dump_cli_options_page = "https://www.arangodb.com/docs/stable/programs-arangodump.html";
  static arango_private_endpoints = "https://docs.arangodb.com/stable/arangograph/deployments/private-endpoints/";
}
