//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import api from "../api/api";
import { reportError } from "../errors/reporting";
import _ from "lodash";
import { IMarker, providerColors } from "./_regionHelpers";

import worldData from "./world-110m.json";

interface IRegionMapViewArgs {
  showPlanned: boolean;
  markers?: IMarker[];
  selectedMarker?: number;
  onMouseEnter: (index: number) => void;
  onMouseLeave: (index: number) => void;
}

const RegionMapView = ({ ...args }: IRegionMapViewArgs) => {
  const markers = args.markers || [];
  return (
    <ComposableMap
      height={400}
      projection="geoMercator"
      projectionConfig={{
        center: [0, 20],
        scale: 130,
      }}
    >
      <Geographies geography={worldData}>
        {({ geographies }) => geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />)}
      </Geographies>
      {markers.map((m, i) => {
        const regions = m.regions;
        const radius = 3 + regions.length;
        const allPlanned = _.every(regions, (r) => r.planned);
        if (allPlanned && !args.showPlanned) return undefined;
        return (
          <Marker key={`m${i}`} coordinates={m.coordinates} onMouseEnter={() => args.onMouseEnter(i)} onMouseLeave={() => args.onMouseLeave(i)}>
            <g fill={allPlanned ? "yellow" : "var(--green-600)"}>
              <circle cx="0" cy="0" r={radius} />
            </g>
          </Marker>
        );
      })}
      {markers.map((m, i) => {
        const isSelected = i === args.selectedMarker;
        const regions = m.regions;
        const boxHeight = 15 + 15 * regions.length;
        const long = m.coordinates[0];
        const x = long > 100 ? -150 : -65;
        return (
          <Marker key={`m${i}`} coordinates={m.coordinates} onMouseEnter={() => args.onMouseEnter(i)} onMouseLeave={() => args.onMouseLeave(i)}>
            {isSelected && (
              <g>
                <rect x={x - 15} y="10" rx="5" ry="5" width="200" height={boxHeight} style={{ fill: "white", stroke: "black", strokeWidth: 2, opacity: 0.7 }} />
                {regions.map((r, i) => (
                  <g>
                    <circle cx={x - 5} cy={25 + i * 15} r="3" style={{ fill: providerColors(r.provider_id) }} />
                    <text
                      textAnchor="start"
                      x={x}
                      y={30 + i * 15}
                      style={{ fontStyle: r.planned ? "italic" : "normal", fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                      {r.name}
                    </text>
                  </g>
                ))}
              </g>
            )}
          </Marker>
        );
      })}
      <g>
        <circle cx="10" cy="350" r="5" fill="var(--green-600)" />
        <text x="20" y="355" fill="grey">
          Available region
        </text>
      </g>
      {args.showPlanned && (
        <g>
          <circle cx="10" cy="370" r="5" fill="yellow" />
          <text x="20" y="375" fill="grey">
            Planned / possible region
          </text>
        </g>
      )}
    </ComposableMap>
  );
};

interface IRegionMapProps {
  showPlanned?: boolean;
}

interface IRegionMapState {
  markers?: IMarker[];
  selectedMarker?: number;
}

// Regions shows the available regions on a map.
export class RegionMap extends Component<IRegionMapProps, IRegionMapState> {
  state = {
    markers: undefined,
    selectedMarker: undefined,
  } as IRegionMapState;

  reloadRegionMarkers = async () => {
    try {
      const resp = await fetch("/regions-markers");
      const samples = await api.decodeResults(resp);
      this.setState({ markers: samples as IMarker[] });
    } catch (e) {
      console.log(e);
      reportError(e);
      // Try again in 5sec
      setInterval(this.reloadRegionMarkers, 5000);
    }
  };

  componentDidMount() {
    this.reloadRegionMarkers();
  }

  onMouseEnter = (index: number) => {
    this.setState({ selectedMarker: index });
  };

  onMouseLeave = (index: number) => {
    this.setState({ selectedMarker: undefined });
  };

  render() {
    return <RegionMapView {...this.state} showPlanned={!!this.props.showPlanned} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} />;
  }
}
