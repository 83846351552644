//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/common/v1/common";
import {
  Member as ApiMember,
  MemberList as ApiMemberList,
  Organization as ApiOrganization,
  OrganizationMembersRequest as ApiOrganizationMembersRequest,
} from "../../api/resourcemanager/v1/resourcemanager";
import { Confirm, ConfirmInfo, ErrorMessage, LoaderBoxForTable as LoaderBox, Loading, Processing } from "../../ui/lib";
import OrganizationMember from "./OrganizationMember";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { reportError } from "../../errors/reporting";
import _ from "lodash";

// Interface describing an user
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Given Name</Table.HeaderCell>
      <Table.HeaderCell>Family Name</Table.HeaderCell>
      <Table.HeaderCell>E-mail</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Is Owner</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing the user list
interface IListView extends IOrganizationListProps {
  items: ApiMember[];
  loading: boolean;
  canDelete: boolean;
  onClickDelete: (id: string, name: string) => void;
  canConvertToOwner: boolean;
  onConvertToOwner: (id: string, name: string) => void;
  canConvertToMember: boolean;
  onConvertToMember: (id: string, name: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <OrganizationMember {...args} key={item.user_id} member={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>This organization has no users</div>;

// Interface describing the organization member list view arguments
export interface IOrganizationMemberListViewArgs extends IOrganizationListProps {
  loading: boolean;
  organizationMembers?: ApiMemberList;
  canDelete: boolean;
  onClickDelete: (id: string, name: string) => void;
  canConvertToOwner: boolean;
  onConvertToOwner: (id: string, name: string) => void;
  canConvertToMember: boolean;
  onConvertToMember: (id: string, name: string) => void;
}

export const OrganizationMemberListView = ({ ...args }: IOrganizationMemberListViewArgs) => {
  if (!args.organizationMembers) {
    return <Loading />;
  }
  if (!args.organizationMembers.items || args.organizationMembers.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.organizationMembers.items} loading={args.loading} />;
};

// Interface decribing the properties of the organization member list component
interface IOrganizationListProps {
  organization: ApiOrganization;
  loading: boolean;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  subscribeUrl: ((callback: () => Promise<void>, url?: string) => Promise<string | void>) | undefined;
  unsubscribe: ((id?: string | void) => void) | undefined;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
}

// Interface decribing the state of the organization member list component
interface IOrganizationListState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  organizationMembers?: ApiMemberList;
}

// The component to show the organization members inside an organization as a list.
class OrganizationMemberList extends Component<IOrganizationListProps, IOrganizationListState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    organizationMembers: undefined,
  } as IOrganizationListState;

  reloadOrganizationMembers = async () => {
    const listOptions = { context_id: this.props.organization.id } as ApiListOptions;
    const organizationMembers = await apiClients.resourceManagerClient.ListOrganizationMembers(listOptions);
    this.setState({ organizationMembers: organizationMembers });
  };

  getOrganizationMemberName = (id: string) => {
    const members = this.state.organizationMembers || {};
    const member = _.find(members.items || [], (x) => x.user_id == id) || {};
    const user = member.user || {};
    return user.name;
  };

  onClickDelete = async (id: string, name: string) => {
    const confirmInfo = {
      header: "Delete Organization Member",
      content: `Are you sure you want to delete member '${name}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const req = {
        organization_id: this.props.organization.id,
        members: { items: [{ user_id: id } as ApiMember] } as ApiMemberList,
      } as ApiOrganizationMembersRequest;
      await apiClients.resourceManagerClient.DeleteOrganizationMembers(req);
      this.props.refreshNow?.(this.reloadOrganizationMembers);
    } catch (e) {
      this.setState({ errorMessage: `Organization member deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onConvertToMember = async (id: string, name: string) => {
    const confirmInfo = {
      header: "Change To Normal Member",
      content: `Are you sure you want to change member '${name}' from an owner to a normal member?`,
      onConfirm: () => this.onConvertToMemberConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onConvertToMemberConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const req = {
        organization_id: this.props.organization.id,
        members: { items: [{ user_id: id, owner: false } as ApiMember] } as ApiMemberList,
      } as ApiOrganizationMembersRequest;
      await apiClients.resourceManagerClient.UpdateOrganizationMembers(req);
      this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationMembers);
    } catch (e) {
      this.setState({ errorMessage: `Organization member ownership change failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onConvertToOwner = async (id: string, name: string) => {
    const confirmInfo = {
      header: "Change To Normal Member",
      content: `Are you sure you want to change member '${name}' from a normal member to an owner?`,
      onConfirm: () => this.onConvertToOwnerConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onConvertToOwnerConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const req = {
        organization_id: this.props.organization.id,
        members: { items: [{ user_id: id, owner: true } as ApiMember] } as ApiMemberList,
      } as ApiOrganizationMembersRequest;
      await apiClients.resourceManagerClient.UpdateOrganizationMembers(req);
      this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationMembers);
    } catch (e) {
      this.setState({ errorMessage: `Organization member ownership change failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };
  subscriptionId?: string | void;
  subscribeMembers = async () => {
    this.subscriptionId = await this.props.subscribeUrl?.(this.reloadOrganizationMembers, `${this.props.organization.url}/members/*`);
  };
  componentDidMount() {
    this.subscribeMembers();
  }
  componentWillUnmount(): void {
    this.props.unsubscribe?.(this.subscriptionId);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const organizationMembers = this.state.organizationMembers;
    const canUpdateOrg =
      this.props.hasPermissionByUrl &&
      this.props.hasPermissionByUrl(this.props.organization.url || "", ResourceType.Organization, "resourcemanager.organization.update");

    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting member, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <OrganizationMemberListView
          {...this.props}
          organizationMembers={organizationMembers}
          canDelete={!!canUpdateOrg}
          onClickDelete={this.onClickDelete}
          canConvertToMember={!!canUpdateOrg}
          onConvertToMember={this.onConvertToMember}
          canConvertToOwner={!!canUpdateOrg}
          onConvertToOwner={this.onConvertToOwner}
        />
      </div>
    );
  }
}

export default OrganizationMemberList;
