//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CheckboxProps, DropdownProps, Form, Grid, InputOnChangeData } from "semantic-ui-react";

import apiClients from "../../api/apiclients";
import { isBadRequest, User as ApiUser } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { educationalRoleOptions, EducationRoles, StyledFieldDropdown } from "../../onboarding/user/EnterNames";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Loading,
  MainContent,
  PhoneNumber,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { toggleSentry } from "../../util/sentry";

interface IUpdateUserViewArgs extends IUpdateUserState, IUpdateUserProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateUser: () => void;
  updateEducationalStatus: (hasEducationalStatus: boolean) => void;
  updateEnhancedTroubleshooting: (disable_enhanced_troubleshooting: boolean) => void;
}

const UpdateUserView = ({ ...args }: IUpdateUserViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Updating user, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      {args.user ? (
        <Form>
          <Section>
            <SectionHeader title="Personal Info" />
            <SectionContent>
              <Grid>
                <Grid.Row columns={16}>
                  <Grid.Column width={6}>
                    <Form.Input autoFocus label="Name" placeholder="Name" name="name" value={args.name} onChange={args.updateFormInputChange} />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Input label="Given name" placeholder="Given name" name="given_name" value={args.given_name} onChange={args.updateFormInputChange} />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Input
                      label="Family name"
                      placeholder="Family name"
                      name="family_name"
                      value={args.family_name}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={16}>
                  <Grid.Column width={6}>
                    <Form.Input
                      label={args.hasEducationalStatus ? "University name" : "Company name"}
                      placeholder={args.hasEducationalStatus ? "University name" : "Company name"}
                      name="company_name"
                      value={args.company_name}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Input
                      label="Slack name"
                      placeholder="Account name in arangodb-community slack"
                      name="slack_name"
                      value={args.slack_name}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Input
                      label="Mobile phone number"
                      control={PhoneNumber}
                      placeholder="Mobile phone number"
                      name="mobile_phone"
                      value={args.mobile_phone}
                      onChange={args.updateFormInputChange}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={16}>
                  <Grid.Column width={6}>
                    <Form.Checkbox
                      label="This is an educational account"
                      name="hasEducationalStatus"
                      checked={args.hasEducationalStatus}
                      onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                        return args.updateEducationalStatus(!!data.checked);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Checkbox
                      label="Enhanced Troubleshooting"
                      name="disable_enhanced_troubleshooting"
                      checked={!Boolean(args.user.disable_enhanced_troubleshooting)}
                      onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                        return args.updateEnhancedTroubleshooting(!Boolean(data.checked));
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                {args.hasEducationalStatus && (
                  <Grid.Row columns={16}>
                    <Grid.Column width={6}>
                      <StyledFieldDropdown
                        label="Education Role"
                        placeholder="Select your role"
                        name="educationRole"
                        required
                        fluid
                        value={String(args.educationRole).toLowerCase()}
                        options={educationalRoleOptions}
                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                          args.updateFormInputChange(null, {
                            name: "educationRole",
                            value: data.value as string,
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </SectionContent>
          </Section>
          <FormContentActions>
            <FormContentAction>
              <FormActionButtonSave primary disabled={!args.active} onClick={args.updateUser} />
            </FormContentAction>
            <FormContentAction>
              <FormActionButtonCancel onClick={args.history.goBack} />
            </FormContentAction>
          </FormContentActions>
        </Form>
      ) : (
        <Loading />
      )}
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the UpdateUser component
interface IUpdateUserProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onUserUpdated: (userId: string) => void;
}

// Interface decribing the state of the UpdateUser component
interface IUpdateUserState {
  user?: ApiUser;
  errorMessage?: string;
  processing: boolean;
  name: string;
  given_name: string;
  family_name: string;
  company_name: string;
  slack_name: string;
  mobile_phone: string;
  educationRole?: EducationRoles;
  hasEducationalStatus?: boolean;
}

// Component to update a user
class UpdateUser extends Component<IUpdateUserProps, IUpdateUserState> {
  state = {
    errorMessage: undefined,
    processing: false,
    user: undefined,
    name: "",
    given_name: "",
    family_name: "",
    company_name: "",
    slack_name: "",
    mobile_phone: "",
  } as IUpdateUserState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
      name: user.name || "",
      given_name: user.given_name || "",
      family_name: user.family_name || "",
      company_name: user.company_name || "",
      slack_name: user.slack_name || "",
      mobile_phone: user.mobile_phone || "",
      hasEducationalStatus: user.has_educational_status,
      educationRole: user.educational_role as EducationRoles,
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    type ActionObjectType = {
      [action: string]: () => void;
    };

    const actionObject: ActionObjectType = {
      name: () => this.setState({ name: args.value }),
      given_name: () => this.setState({ given_name: args.value }),
      family_name: () => this.setState({ family_name: args.value }),
      company_name: () => this.setState({ company_name: args.value }),
      slack_name: () => this.setState({ slack_name: args.value }),
      mobile_phone: () => this.setState({ mobile_phone: args.value }),
      educationRole: () => this.setState({ educationRole: args.value as EducationRoles }),
    };

    actionObject[args.name]();
  };

  updateUser = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const user = this.state.user || ({} as ApiUser);
      user.name = this.state.name;
      user.given_name = this.state.given_name;
      user.family_name = this.state.family_name;
      user.company_name = this.state.company_name;
      user.slack_name = this.state.slack_name;
      user.mobile_phone = this.state.mobile_phone;
      user.has_educational_status = this.state.hasEducationalStatus;
      if (this.state.hasEducationalStatus && !this.state.educationRole) {
        this.setState({ errorMessage: "Please select your educational role", processing: false });
        return;
      }
      user.educational_role = this.state.educationRole;

      const updatedUser = await apiClients.iamClient.UpdateUser(user);

      this.props.onUserUpdated(updatedUser.id || "");
      // Disable/Enable sentry replay based on enhanced troubleshooting
      toggleSentry(updatedUser);
    } catch (e) {
      this.setState({ errorMessage: `User update failed: ${e}` });
      if (!isBadRequest(e)) {
        reportError(e);
      }
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(new BreadCrumbItem("My account", Routes.dashboard_user_details));
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("user");
    const user = this.state.user || {};
    this.props.topMenuInfo.setTitles(user.name || "", "");
  };

  updateEducationalStatus = (hasEducationalStatus: boolean) => {
    this.setState({ hasEducationalStatus, user: { ...this.state.user, has_educational_status: !!hasEducationalStatus } }, () => {
      if (!hasEducationalStatus) {
        this.setState({ educationRole: undefined, user: { ...this.state.user, educational_role: undefined } });
      }
    });
  };

  updateEnhancedTroubleshooting = (disable_enhanced_troubleshooting: boolean) => {
    this.setState({ user: { ...this.state.user, disable_enhanced_troubleshooting: disable_enhanced_troubleshooting } });
  };

  render() {
    return (
      <UpdateUserView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        updateUser={this.updateUser}
        updateEducationalStatus={this.updateEducationalStatus}
        updateEnhancedTroubleshooting={this.updateEnhancedTroubleshooting}
      />
    );
  }
}

export default withRefresh()(UpdateUser);
