//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon } from "semantic-ui-react";

interface IPrepaidIconArgs {}

export const PrepaidIcon = ({ ...args }: IPrepaidIconArgs) => {
  return (
    <Icon.Group size="large">
      <Icon name="credit card outline" />
      <Icon name="check" corner="top right" color="olive" />
    </Icon.Group>
  );
};
