//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { IAMProvider as ApiIAMProvider, IAMProviderType, Organization as ApiOrganization, Project as ApiProject } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
} from "../../ui/lib";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { GeneralView } from "./GeneralViews";
import { LdapView } from "./LdapViews";
import apiClients from "../../api/apiclients";
interface ICreateIAMProviderViewArgs {
  processingCreate: boolean;
  errorMessage?: string;
  active: boolean;
  iamprovider: ApiIAMProvider;
  handleDismissError: () => void;
  onUpdate: (value: ApiIAMProvider) => void;
  onCreate: () => void;
  onCancel: () => void;
}

const CreateIAMProviderView = ({ ...args }: ICreateIAMProviderViewArgs) => (
  <ContentSegment>
    <Processing active={args.processingCreate} message="Creating IAM provider, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <GeneralView {...args} editable canChangeType />
        {args.iamprovider.type == IAMProviderType.Ldap && <LdapView {...args} editable />}
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active} onClick={args.onCreate} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.onCancel} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the CreateIAMProvider component
interface ICreateIAMProviderProps extends RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  onNewIAMProviderCreated: (iamproviderId: string) => void;
}

// Interface decribing the state of the CreateIAMProvider component
interface ICreateIAMProviderState {
  errorMessage?: string;
  processingCreate: boolean;
  iamprovider: ApiIAMProvider;
}

// Component to create an IAM provider
class CreateIAMProvider extends Component<ICreateIAMProviderProps, ICreateIAMProviderState> {
  state = {
    errorMessage: undefined,
    processingCreate: false,
    iamprovider: {
      type: IAMProviderType.Ldap,
    },
  } as ICreateIAMProviderState;

  createIAMProvider = async () => {
    try {
      this.setState({ processingCreate: true, errorMessage: undefined });
      const iamprovider = _.clone(this.state.iamprovider);
      iamprovider.project_id = this.props.project.id;
      const createdProvider = await apiClients.securityClient.CreateIAMProvider(iamprovider);

      this.props.onNewIAMProviderCreated(createdProvider.id || "");
    } catch (e) {
      this.setState({ errorMessage: `IAM provider creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingCreate: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(this.props.organization.id || "")),
      new BreadCrumbItem(this.props.project.name || "", Routes.dashboard_projectWithId(this.props.project.id || "")),
      new BreadCrumbItem("IAM providers", Routes.dashboard_project_iamprovidersWithId(this.props.project.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("iamprovider");
    this.props.topMenuInfo.setTitles(this.state.iamprovider.name || "New IAM provider", "");
  };

  render() {
    return (
      <CreateIAMProviderView
        {...this.props}
        {...this.state}
        active={!this.state.processingCreate}
        handleDismissError={this.handleDismissError}
        onCreate={this.createIAMProvider}
        onCancel={this.props.history.goBack}
        onUpdate={(x) => this.setState({ iamprovider: x })}
      />
    );
  }
}

export default CreateIAMProvider;
