//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Button, Card, Form, Grid, Icon, Label, Loader, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  CPUSize as ApiCPUSize,
  Currency as ApiCurrency,
  GetDefaultCurrencyRequest as ApiGetDefaultCurrencyRequest,
  ListCPUSizesRequest as ApiListCPUSizesRequest,
  NodeSizesRequest as ApiNodeSizesRequest,
  Organization as ApiOrganization,
  Project as ApiProject,
} from "../../api/lib";
import { Provider as ApiProvider, Region as ApiRegion } from "../../api/platform/v1/platform";
import { Routes } from "../../routes";
import { ITracking } from "../../tracking/api";
import { CommonContentActions, ContentSegment, ErrorMessage, MainContent, Section, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import SelectProvider from "../deployment/SelectProvider";
import SelectRegion from "../deployment/SelectRegion";
import { HistoryHelper } from "../HistoryHelper";
import { TopMenuInfo } from "../TopMenuInfo";
import SelectProject from "./SelectProject";
import { Feature, useFeature } from "flagged";
import PricingForModel from "./PricingForModel";
import { EnterpriseTierID, FreeTierID, ProTierID } from "../../constants";
import history from "../../history";
import { FlexBox } from "../../ui/_flex";
import styled from "@emotion/styled";
import { useDashboardContext } from "../DashboardContextProvider";
import { RenderGuard } from "../../util/RenderGuard";

const StyledCardDescription = styled(Card.Description)`
  height: 100% !important;
`;

const UsingPackageIndicator = () => (
  <Popup
    position="top right"
    wide
    trigger={
      <Label corner="right">
        <Icon name="check" />
      </Label>
    }
    content="You're currently using this package."
  />
);

const IntroView = ({ ...args }: { organization: ApiOrganization }) => {
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();
  return (
    <Section>
      <SectionHead>
        <SectionHeader title="ArangoGraph Packages" />
      </SectionHead>
      <SectionContent>
        <Card.Group itemsPerRow="3">
          <Card>
            <Card.Content>
              <Card.Header>Free Trial</Card.Header>
              <Card.Description>
                <p className="para">Try ArangoGraph for {isPerpetualFreeTrialAvailable ? "free for 30 days." : "free for 14 days."}</p>
                <p className="para">Get started quickly, without needing to enter a credit card.</p>
                <div>
                  <b>The free trial gives you access to:</b>
                  <ul className="unordered-list">
                    <li>1 small (4GB) deployment {!isPerpetualFreeTrialAvailable && "in a region of your choice for 14 days"}</li>
                    <li>Local backups</li>
                    <li>1 ArangoGraph Notebook for learning and data science</li>
                  </ul>
                </div>
              </Card.Description>
              {args.organization.tier && args.organization.tier.id === FreeTierID && UsingPackageIndicator()}
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>On-Demand</Card.Header>
              <StyledCardDescription>
                <FlexBox direction="column" justify="space-between" height="100%">
                  <div>
                    <p className="para">Add a payment method to gain access to ArangoGraph's full feature set.</p>
                    <p className="para">You'll only be charged each month for what you actually use.</p>
                    <div>
                      <b>Unlocks all ArangoGraph functionality, including:</b>
                      <ul className="unordered-list">
                        <li>Multiple larger deployments</li>
                        <li>Backup to durable cloud storage, with multi-region support</li>
                        <li>Enhanced security features such as Private Endpoints</li>
                      </ul>
                    </div>
                  </div>
                  <RenderGuard renderIf={args.organization.tier?.id === ProTierID || args.organization.tier?.id === FreeTierID}>
                    <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                      <Button primary onClick={() => history.push(Routes.dashboard_organization_billingWithId(args.organization.id || ""))}>
                        Upgrade Now
                      </Button>
                    </div>
                  </RenderGuard>
                </FlexBox>
              </StyledCardDescription>
              {args.organization.tier && args.organization.tier.id === ProTierID && UsingPackageIndicator()}
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>Committed</Card.Header>
              <StyledCardDescription>
                <FlexBox direction="column" justify="space-between" height="100%">
                  <div style={{ height: "100%" }}>
                    <p className="para">Take advantage of the discounts available by committing to ArangoGraph for a year.</p>
                    <p className="para">ArangoGraph credits provide the same flexibility of On-Demand, but at a lower price.</p>
                    <div>
                      <b>In addition gain access to:</b>
                      <ul className="unordered-list">
                        <li>24/7 Premium Support</li>
                        <li>ArangoDB Professional Services Engagements</li>
                        <li>Ability to transact via the AWS and GCP marketplaces</li>
                      </ul>
                    </div>
                  </div>
                  {args.organization.tier && args.organization.tier.id !== EnterpriseTierID && (
                    <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                      <Button primary as="a" href="https://www.arangodb.com/contact" target="_blank">
                        Contact Us
                      </Button>
                    </div>
                  )}
                </FlexBox>
              </StyledCardDescription>
              {args.organization.tier && args.organization.tier.id === EnterpriseTierID && UsingPackageIndicator()}
            </Card.Content>
          </Card>
        </Card.Group>
      </SectionContent>
    </Section>
  );
};

interface IPricingViewArgs extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  project?: ApiProject;
  projectCount?: number;
  currency: ApiCurrency;
  provider?: ApiProvider;
  region?: ApiRegion;
  cpu_sizes: ApiCPUSize[];
  node_cpu_size: string;
  show_unrounded_prices: boolean;
  onShowUnroundedPricesChange: () => void;
  onProviderUpdated: (provider?: ApiProvider) => void;
  onRegionUpdated: (region?: ApiRegion) => void;
  onProjectUpdated: (projectCount: number, project?: ApiProject) => void;
  onNodeCPUSizeChanged: (id: string) => void;
}

const PricingView = ({ ...args }: IPricingViewArgs) => {
  const isDeveloperModeEnabled = useFeature("model.developer");
  const hasRegion = !!args.region;
  const hasProject = !!args.project;
  const hasNoProjects = args.projectCount === 0;
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();
  return (
    <div>
      <IntroView {...args} />
      <div>
        <Section>
          <SectionHead>
            <SectionHeader title="Pricing" />
          </SectionHead>
          <SectionContent>
            <Form>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width="5">
                    <SelectProvider {...args} organizationId={args.organization.id || ""} select_first />
                  </Grid.Column>
                  <Grid.Column width="5">
                    <SelectRegion
                      {...args}
                      organizationId={args.organization.id || ""}
                      select_first
                      hasNoModel
                      isPerpetualFreeTrialAvailable={isPerpetualFreeTrialAvailable}
                    />
                  </Grid.Column>
                  <Grid.Column width="5">
                    <SelectProject {...args} organizationId={args.organization.id || ""} select_first />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </SectionContent>
        </Section>

        {hasNoProjects && (
          <div>
            Please{" "}
            <NavLink className="text-link" to={Routes.dashboard_organization_projects_create}>
              create a project
            </NavLink>{" "}
            first.
          </div>
        )}
        {hasRegion && hasProject && isDeveloperModeEnabled && <PricingForModel {...args} model="developer" show_node_count={false} initial_node_count={1} />}
        {hasRegion && hasProject && <PricingForModel {...args} model="oneshard" show_node_count={false} initial_node_count={3} />}
      </div>
    </div>
  );
};

interface IPricingProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  tracking: ITracking;
}

interface IPricingState {
  errorMessage?: string;
  provider?: ApiProvider;
  region?: ApiRegion;
  cpu_sizes?: ApiCPUSize[];
  project?: ApiProject;
  projectCount?: number;
  currency?: ApiCurrency;
  node_cpu_size?: string;
  show_unrounded_prices: boolean;
}

class Pricing extends Component<IPricingProps, IPricingState> {
  state = {
    errorMessage: undefined,
    provider: undefined,
    region: undefined,
    cpu_sizes: undefined,
    project: undefined,
    projectCount: undefined,
    currency: undefined,
    node_cpu_size: undefined,
    show_unrounded_prices: false,
  } as IPricingState;

  reloadCurrency = async () => {
    const req = {
      organization_id: this.props.organization.id,
    } as ApiGetDefaultCurrencyRequest;
    const currency = await apiClients.currencyClient.GetDefaultCurrency(req);
    this.setState({
      currency: currency,
    });
  };

  refreshCurrency = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCurrency);
  };

  reloadCPUAndNodeSizes = async (projectID: string, regionID: string) => {
    // Request CPU sizes
    const cpuReq = {
      project_id: projectID,
    } as ApiListCPUSizesRequest;
    const cpuSizeList = await apiClients.dataClient.ListCPUSizes(cpuReq);
    const allCpuItems = cpuSizeList.items || [];

    // Request node sizes
    const nodeSizeReq = {
      project_id: "all",
      region_id: regionID,
      // Ignore model here
    } as ApiNodeSizesRequest;
    const nodeSizeList = await apiClients.dataClient.ListNodeSizes(nodeSizeReq);
    const nodeSizeItems = nodeSizeList.items || [];

    // Select only CPU sizes that have at least 1 matching node size
    const availableCpuItems = _.filter(allCpuItems, (x) => !!_.find(nodeSizeItems, (ns) => ns.cpu_size == x.id));

    this.setState((old) => {
      const hasNodeCpuSize = !!old.node_cpu_size;
      const hasAvailableNodeCpuSize = hasNodeCpuSize && !!_.find(availableCpuItems, (x) => x.id == old.node_cpu_size);
      const firstAvailableCpuSize = !_.isEmpty(availableCpuItems) ? availableCpuItems[0].id : undefined;
      return {
        node_cpu_size: hasAvailableNodeCpuSize ? old.node_cpu_size : firstAvailableCpuSize,
        cpu_sizes: availableCpuItems,
      };
    });
  };

  refreshCPUAndNodeSizes = () => {
    const project = this.state.project || {};
    const region = this.state.region || {};
    const projectID = project.id;
    const regionID = region.id;
    if (!!projectID && !!regionID) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadCPUAndNodeSizes(projectID, regionID));
    } else {
      this.setState({ cpu_sizes: [] });
    }
  };

  componentDidMount() {
    this.updateTopMenu();
    this.refreshCurrency();
    this.props.tracking.trackVisitedPricingPage();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    this.props.topMenuInfo.clearBreadCrumb();
    this.props.topMenuInfo.setImageSource("organization");
    this.props.topMenuInfo.setTitles("Pricing", "");
  };

  hasOrganizationPermission = (p: Permission) => {
    return this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(this.props.organization.url || "", ResourceType.Organization, p);
  };

  onProviderUpdated = (provider?: ApiProvider) => {
    this.setState({
      provider: provider,
      region: undefined,
    });
  };

  onRegionUpdated = (region?: ApiRegion) => {
    this.setState({ region: region }, this.refreshCPUAndNodeSizes);
  };

  onProjectUpdated = (projectCount: number, project?: ApiProject) => {
    this.setState(
      {
        projectCount: projectCount,
        project: project,
      },
      this.refreshCPUAndNodeSizes
    );
  };

  onShowUnroundedPricesChange = () => {
    this.setState((old) => {
      return {
        show_unrounded_prices: !old.show_unrounded_prices,
      };
    });
  };

  onNodeCPUSizeChanged = (id: string) => {
    this.setState({ node_cpu_size: id });
  };

  render() {
    const cpu_sizes = this.state.cpu_sizes || [];

    return (
      <Feature name="model.developer">
        <ContentSegment>
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <CommonContentActions>
            <Loader size="mini" active={this.props.loading} inline />
          </CommonContentActions>

          <MainContent>
            <PricingView
              {...this.props}
              {...this.state}
              cpu_sizes={cpu_sizes}
              node_cpu_size={this.state.node_cpu_size || ""}
              currency={this.state.currency || {}}
              onProviderUpdated={this.onProviderUpdated}
              onRegionUpdated={this.onRegionUpdated}
              onProjectUpdated={this.onProjectUpdated}
              onShowUnroundedPricesChange={this.onShowUnroundedPricesChange}
              onNodeCPUSizeChanged={this.onNodeCPUSizeChanged}
            />
          </MainContent>
        </ContentSegment>
      </Feature>
    );
  }
}

export default withRefresh()(Pricing);
