//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";

export const useFetchDBVersions = () => {
  const { selectedOrganization } = useDashboardContext();
  const { data: deployment } = useFetchDeploymentForEdit();
  const fetchVersions = async () => {
    const versions = await apiClients.dataClient.ListVersions({
      organization_id: selectedOrganization.id,
      current_version: deployment?.version || "",
    });
    return versions;
  };
  return useQuery([selectedOrganization.id, deployment?.version || "newDeployment", "version"], fetchVersions);
};
