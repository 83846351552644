//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";

// The component to show the audit log feature, when not supported by current tier.
class AuditLogNotSupportedTab extends Component {
  render() {
    return (
      <div>
        <Section>
          <SectionHead>
            <SectionHeader title="Summary" />
          </SectionHead>
          <SectionContent>
            <Segment>
              <h3 className="heading-3">What are Audit logs?</h3>
              <p className="para">
                Audit logs capture a record (called an "event") of everything that is done in an ArangoGraph Insights Platform deployment or in your ArangoGraph
                Insights Platform organization.
              </p>
              <p className="para">Each event can be recorded in the cloud, or sent to a server you own, as soon as the event happens.</p>

              <h3 className="heading-3">Getting started</h3>
              <p className="para">Audit logs are currently not enabled for your organization.</p>
              <p className="para">Enter your billing information to enable Audit logs for your organization.</p>
            </Segment>
          </SectionContent>
        </Section>
      </div>
    );
  }
}

export default AuditLogNotSupportedTab;
