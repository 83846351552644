//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";

export const SelectedModelMessage = () => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { t } = useTranslation();
  const i18nKey = `selectedModelMessage.${values.modelId}`;
  return (
    <Alert status="info" borderRadius="md" variant="left-accent" colorScheme="green">
      <AlertIcon />
      <AlertDescription>
        <Trans t={t} i18nKey={i18nKey} />
      </AlertDescription>
    </Alert>
  );
};
