//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { TopMenuBar } from "../../../components/TopMenuBar";
import { useDocumentTitle } from "../../../components/useDocumentTitle";
import { Routes } from "../../../routes";
import roleBindingIcon from "../../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/role-binding.svg";

import { useDashboardContext } from "../../DashboardContextProvider";
import { BreadCrumbItem } from "../../TopMenuInfo";

export const CreateOrganizationRoleBindingsTopMenu = () => {
  const { selectedOrganization } = useDashboardContext();

  const breadcrumbs = [new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || ""))];
  useDocumentTitle(`Organization: ${selectedOrganization.name} |  New role binding`);
  return <TopMenuBar breadcrumbs={breadcrumbs} icon={roleBindingIcon} title={"New role binding"} />;
};
