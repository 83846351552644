//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Form, Grid, Input } from "semantic-ui-react";
import { IAMProvider as ApiIAMProvider, IAMProviderType } from "../../api/lib";
import { Section, SectionHeader, SectionContent } from "../../ui/lib";
import moment from "moment";
import _ from "lodash";
import { Value } from "./StaticViews";

interface IGeneralViewArgs {
  editable: boolean;
  iamprovider: ApiIAMProvider;
  onUpdate: (value: ApiIAMProvider) => void;
  canChangeType: boolean;
}

export const GeneralView = ({ ...args }: IGeneralViewArgs) => {
  const updateName = (value: string) => {
    const clone = _.cloneDeep(args.iamprovider);
    clone.name = value;
    args.onUpdate(clone);
  };
  const updateDescription = (value: string) => {
    const clone = _.cloneDeep(args.iamprovider);
    clone.description = value;
    args.onUpdate(clone);
  };
  const updateType = (value: string) => {
    if (args.canChangeType) {
      const clone = _.cloneDeep(args.iamprovider);
      clone.type = value;
      args.onUpdate(clone);
    }
  };
  return (
    <Section>
      <SectionHeader title="General" />
      <SectionContent>
        <Grid columns="16">
          <Grid.Row>
            <Grid.Column width={4}>
              <Form.Field required={args.editable}>
                <label>Name</label>
                {args.editable && (
                  <Input
                    autoFocus
                    placeholder={`Name (e.g. "MyLDAPProvider-${moment().year()}")`}
                    name="name"
                    value={args.iamprovider.name}
                    onChange={(e, d) => updateName(d.value)}
                    transparent={!args.editable}
                  />
                )}
                {!args.editable && <Value value={args.iamprovider.name} />}
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={12}>
              <Form.Field>
                <label>Short description</label>
                {args.editable && (
                  <Input
                    placeholder="Description"
                    name="description"
                    value={args.iamprovider.description}
                    onChange={(e, d) => updateDescription(d.value)}
                    transparent={!args.editable}
                  />
                )}
                {!args.editable && <Value value={args.iamprovider.description} />}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="4">
              <Button
                content="LDAP"
                type="button"
                active={args.iamprovider.type == IAMProviderType.Ldap}
                onClick={() => updateType(IAMProviderType.Ldap)}
                disabled={!args.canChangeType || !args.editable}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionContent>
    </Section>
  );
};
