//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { formatPrice } from "../../util/Price";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchCalculateDeploymentPrice } from "./useFetchCalculateDeploymentPrice";
import { useFetchCreditBundles } from "./useFetchCreditBundles";
import { useFetchNodeSizes } from "./useFetchNodeSizes";

export const usePriceSummary = () => {
  const { values } = useFormikContext<NewDeploymentFormValues>();
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: values.regionId,
    modelId: values.modelId,
  });
  const nodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === values.nodeSizeId)?.min_disk_size || 0;

  const { data: deploymentPriceData, isFetching: isFetchingPrice } = useFetchCalculateDeploymentPrice({
    modelId: values.modelId,
    regionId: values.regionId,
    providerId: values.providerId,
    supportPlanId: values.supportPlanId,
    nodeDiskSizeId: values.nodeSizeId,
    nodeDiskSize,
    diskPerformanceId: values.diskPerformanceId,
    nodeCount: values.numberOfNodes,
    servers: values.servers,
    customDiskSize: values.customDiskSize,
  });
  const { data: creditBundles, isFetching: isFetchingCreditBundles } = useFetchCreditBundles();
  const hasCredits = (creditBundles?.items?.length || 0) > 0;
  let finalPrice = hasCredits
    ? formatPrice(deploymentPriceData?.priceWithCredits.price_per_hour || 0.0, "", "")
    : formatPrice(deploymentPriceData?.price.price_per_hour || 0.0, deploymentPriceData?.currency.sign || "", "");
  return {
    price: finalPrice,
    priceHeading: hasCredits ? "Credits/hour" : "Price/hour",
    isFetching: isFetchingPrice || isFetchingCreditBundles,
    deploymentPriceData,
    hasCredits,
  };
};
