import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Loader, Message, Segment } from "semantic-ui-react";
import Auth from "../auth/Auth";
import { getQueryParams, validateQueryParamsForExternalAuth } from "../util/QueryParams";
import homeImage from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Routes } from "../routes";
import apiClients from "../api/apiclients";
import api from "../api/api";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Authorize = (
  props: {
    auth: Auth;
  } & RouteComponentProps
) => {
  const { resource_type, resource_id, callback_url } = getQueryParams();
  const [error, setError] = useState("");

  useEffect(() => {
    props.auth.renewToken(async (authErr, authResult) => {
      if ((authErr && authErr.error === "login_required") || !authResult.accessToken) {
        props.auth.setRedirectToRoute(`${Routes.authorize}?resource_type=${resource_type}&resource_id=${resource_id}&callback_url=${callback_url}`);
        setError("Login Required");
        props.auth.login();
        return;
      }

      // Validate if all the params present
      const err = validateQueryParamsForExternalAuth({
        callbackURL: callback_url,
        resourceID: resource_id,
        resourceType: resource_type,
      });
      if (err) {
        setError(err);
        return;
      }
      // Set the cookie
      try {
        api.token = authResult.accessToken;
        await apiClients.authClient.Authorize({
          resource_id: resource_id,
          resource_type: resource_type,
          callback_url: callback_url,
        });
      } catch (err) {
        setError(err.message);
        return;
      }
      setError("");
      window.location.replace(decodeURIComponent(`${callback_url}`));
    });
  }, []);

  return (
    <StyledContainer>
      <Segment style={{ textAlign: "center" }}>
        <img src={homeImage} alt="logo" height="70" />
        <>
          <p className="para">Please wait while we are processing your request</p>
          <Loader active inline>
            Loading...
          </Loader>
        </>
      </Segment>
      <br />
      {error && <Message error>{error}</Message>}
    </StyledContainer>
  );
};

export default Authorize;
