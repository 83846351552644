//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import { Form } from "formik";
import React from "react";
import { AdvancedSection } from "./AdvancedSection";
import { GeneralSection } from "./GeneralSection";
import { RightSection } from "./RightSection";
import { SizingSection } from "./SizingSection";

export const NewDeploymentForm = () => {
  return (
    <Grid as={Form} height="100%" templateColumns="minmax(300px, 1fr) minmax(200px, 0.5fr)" overflow="hidden">
      <Grid templateRows="min-content min-content min-content" height="100%" overflow="auto" paddingBottom="12">
        <GeneralSection />
        <SizingSection />
        <AdvancedSection />
      </Grid>
      <RightSection />
    </Grid>
  );
};
