//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form } from "semantic-ui-react";
import {
  IAMProvider as ApiIAMProvider,
  IAMProviderType,
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  Project as ApiProject,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Loading,
  Processing,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { GeneralView } from "./GeneralViews";
import { LdapView } from "./LdapViews";
import apiClients from "../../api/apiclients";
interface IUpdateIAMProviderViewArgs {
  processingUpdate: boolean;
  errorMessage?: string;
  active: boolean;
  iamprovider: ApiIAMProvider;
  handleDismissError: () => void;
  onUpdate: (value: ApiIAMProvider) => void;
  onSave: () => void;
  onCancel: () => void;
}

const UpdateIAMProviderView = ({ ...args }: IUpdateIAMProviderViewArgs) => (
  <ContentSegment>
    <Processing active={args.processingUpdate} message="Updating IAM provider, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <Form>
      <GeneralView {...args} editable canChangeType={false} />
      {args.iamprovider.type == IAMProviderType.Ldap && <LdapView {...args} editable />}
      <FormContentActions>
        <FormContentAction>
          <FormActionButtonSave primary disabled={!args.active} onClick={args.onSave} />
        </FormContentAction>
        <FormContentAction>
          <FormActionButtonCancel onClick={args.onCancel} />
        </FormContentAction>
      </FormContentActions>
    </Form>
  </ContentSegment>
);

interface IUpdateIAMProviderProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  onIAMProviderUpdated: (iamproviderId: string) => void;
}

interface IUpdateIAMProviderState {
  errorMessage?: string;
  processingUpdate: boolean;
  iamproviderId?: string;
  iamprovider?: ApiIAMProvider;
}

// Component to update an IAM provider
class UpdateIAMProvider extends Component<IUpdateIAMProviderProps, IUpdateIAMProviderState> {
  state = {
    errorMessage: undefined,
    processingUpdate: false,
    iamproviderId: undefined,
    iamprovider: undefined,
  } as IUpdateIAMProviderState;

  updateIAMProvider = async () => {
    if (this.state.iamprovider) {
      try {
        this.setState({ processingUpdate: true, errorMessage: undefined });
        const iamprovider = _.clone(this.state.iamprovider || {});
        const createdIAMProvider = await apiClients.securityClient.UpdateIAMProvider(iamprovider);

        this.props.onIAMProviderUpdated(createdIAMProvider.id || "");
      } catch (e) {
        this.setState({ errorMessage: `IAM provider update failed: ${e}` });
        reportError(e);
      }
      this.setState({ processingUpdate: false });
    }
  };

  reloadIAMProviderInfo = async () => {
    const idOptions = { id: this.state.iamproviderId } as ApiIDOptions;
    const iamprovider = await apiClients.securityClient.GetIAMProvider(idOptions);
    if (!this.state.iamprovider) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadIAMProviderInfo, iamprovider.url);
    }
    this.setState({ iamprovider: iamprovider });
  };

  componentDidMount() {
    const iamproviderId = (this.props.match.params as any).iamproviderId;
    this.setState({ iamproviderId: iamproviderId }, () => {
      //notice inside the reloadIAMProviderInfo we use the iamproviderId
      this.props.refreshNow && this.props.refreshNow(this.reloadIAMProviderInfo);
    });
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    const iamprovider = this.state.iamprovider || {};
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(this.props.organization.id || "")),
      new BreadCrumbItem(this.props.project.name || "", Routes.dashboard_projectWithId(this.props.project.id || "")),
      new BreadCrumbItem("IAM Providers", Routes.dashboard_project_iamprovidersWithId(this.props.project.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("iamprovider");
    this.props.topMenuInfo.setTitles(iamprovider.name || "Update IAM provider", "");
  };

  render() {
    if (this.state.iamprovider) {
      return (
        <UpdateIAMProviderView
          {...this.props}
          {...this.state}
          active={!this.state.processingUpdate}
          handleDismissError={this.handleDismissError}
          iamprovider={this.state.iamprovider || {}}
          onSave={this.updateIAMProvider}
          onCancel={this.props.history.goBack}
          onUpdate={(x) => this.setState({ iamprovider: x })}
        />
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(UpdateIAMProvider);
