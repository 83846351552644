//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { TopMenuBar } from "../../../components/TopMenuBar";
import { useDocumentTitle } from "../../../components/useDocumentTitle";
import { Routes } from "../../../routes";
import roleBindingIcon from "../../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/role-binding.svg";

import { useDashboardContext } from "../../DashboardContextProvider";
import { BreadCrumbItem } from "../../TopMenuInfo";
import { useProjectDetailsContext } from "../ProjectDetailsContextProvider";

export const CreateProjectRoleBindingsTopMenu = () => {
  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const breadcrumbs = [
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_projects),
  ];

  useDocumentTitle(`Project: ${project.name} | New role binding`);
  return <TopMenuBar breadcrumbs={breadcrumbs} icon={roleBindingIcon} title={"New role binding"} />;
};
