//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
// Ewout Prangsma
//

import styled from "@emotion/styled";
import React from "react";
import { Checkbox } from "semantic-ui-react";
import { TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { TermsAndConditionsModal } from "./TermsAndConditionsModal";

const TermsAndConditionsBox = styled("div")`
  padding-bottom: 24px;
`;

interface ITermsAndConditionsAcceptanceViewArgs {
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsChanged: (accepted: boolean) => void;
  termsAndConditions?: ApiTermsAndConditions;
}

export const TermsAndConditionsAcceptanceView = ({ ...args }: ITermsAndConditionsAcceptanceViewArgs) => {
  return (
    <TermsAndConditionsBox>
      <Checkbox
        toggle
        checked={args.acceptedTermsAndConditions}
        onChange={(e, d) => args.acceptedTermsAndConditionsChanged(!!d.checked)}
        label={<label>I accept the&nbsp;</label>}
      />
      <TermsAndConditionsModal {...args} />
    </TermsAndConditionsBox>
  );
};
