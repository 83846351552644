//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDataloaderStore } from "../DataloaderStore";
import { uploadGraph } from "./graphUpload.utils";
import { uploadFiles } from "./filesUpload.utils";
import { useTrackingContext } from "../../../../tracking/TrackingContextProvider";
import { useDeploymentStore } from "../../../../util/storage/DeploymentStore";
import { UploadedFileReport } from "../types";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getFilesReportsSize = (files: UploadedFileReport[]) =>
  files?.reduce((acc, file) => {
    return acc + (file.size || 0);
  }, 0);

const useUploadGraphAndFiles = () => {
  const { tracking } = useTrackingContext();
  const { id: deploymentId = "" } = useDeploymentStore().deployment;
  const { setMigrationJob, updateDeploymentData, getDeploymentDataloaderState } = useDataloaderStore();
  const { edges, nodes, files } = getDeploymentDataloaderState();

  const uploadGraphAndFiles = async () => {
    const startTime = performance.now();
    setMigrationJob({ status: "graph_creation", graphBeingUploadedProgress: 0 });

    try {
      await uploadGraph();

      setMigrationJob({
        status: "graph_creation",
        graphBeingUploadedProgress: 100,
      });
      // Amount of time in ms that the progress bar will be seen at 100% in the UI
      await delay(350);
      await uploadFiles();

      if (!useDataloaderStore.getState().getMigrationJob()?.migrationCancelled) {
        const endTime = performance.now();
        const runTime = parseInt(((endTime - startTime) / 1000).toFixed(0));
        const size = getFilesReportsSize(useDataloaderStore.getState().getMigrationJob()?.filesAlreadyUploaded || []) || 0;
        tracking.trackDataLoaderDataImportFinished({
          deploymentId,
          runTimeSeconds: runTime,
          totalCollections: edges.length + nodes.length,
          totalDocuments: files.length,
          totalFileSize: size,
        });
        setMigrationJob({ status: "finished" });
      } else {
        updateDeploymentData({
          migrationJob: {
            status: "not_started",
            fileBeingUploaded: undefined,
            filesAlreadyUploaded: undefined,
            migrationCancelled: false,
          },
        });
      }
    } catch (error) {
      setMigrationJob({
        status: "error",
      });
    }
  };

  return {
    uploadGraphAndFiles,
  };
};

export default useUploadGraphAndFiles;
