//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Button, Card, Grid, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  CPUSize as ApiCPUSize,
  CPUSizeList as ApiCPUSizeList,
  IDOptions as ApiIDOptions,
  ListCPUSizesRequest as ApiListCPUSizesRequest,
  NodeSize as ApiNodeSize,
  NodeSizeList as ApiNodeSizeList,
  NodeSizesRequest as ApiNodeSizesRequest,
  Organization as ApiOrganization,
  PrepaidDeployment as ApiPrepaidDeployment,
  Project as ApiProject,
} from "../../api/lib";
import { Plan as ApiSupportPlan } from "../../api/support/v1/support";
import { ButtonsCardContent, DeploymentCard, FloatLeft, ModelTitle, StretchedButtonGroup, StyledCardHeader } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { IDataVolumeStats } from "../deployment/DataVolumeView";
import Location from "../deployment/Location";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import CreateDeploymentModal from "./CreateDeploymentModal";
import { PrepaidIcon } from "./PrepaidIcon";

const StyledGrid = styled(Grid)`
  margin-top: 1em !important;
`;

const Field = styled("div")`
  margin-bottom: 0.5em;
`;

const FieldLabel = styled("label")`
  font-weight: bold;
`;

const FieldValue = styled("div")``;

// Interface describing a prepaid deployment
interface IPrepaidDeploymentCardView extends IWithRefreshProps {
  organization: ApiOrganization;
  project: ApiProject;
  item: ApiPrepaidDeployment;
  node_sizes: ApiNodeSize[];
  cpu_sizes: ApiCPUSize[];
  support_plan: ApiSupportPlan;
  onClickCreate: () => void;
  onCancelCreate: () => void;
  onViewDeployment: (deploymentID: string) => void;
  creating: boolean;
}

const PrepaidDeploymentCardView = ({ ...args }: IPrepaidDeploymentCardView) => {
  const hasProjectPermission = (p: Permission) => args.hasPermissionByUrl && args.hasPermissionByUrl(args.project.url || "", ResourceType.Project, p);
  const hasPrepaidDeploymentPermission = (p: Permission) =>
    args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.PrepaidDeployment, p);
  const has_prepaiddeployment_create = hasProjectPermission("data.deployment.create") && hasPrepaidDeploymentPermission("prepaid.prepaiddeployment.get");

  const is_active = !!args.item.is_active;
  const model = args.item.model || {};
  const node_size = _.find(args.node_sizes, (x) => x.id == model.node_size_id) || {};
  const cpu_size = _.find(args.cpu_sizes, (x) => x.id == node_size.cpu_size) || {};
  const { refetchDeployments } = useProjectDetailsContext();

  return (
    <DeploymentCard>
      {args.creating && (
        <CreateDeploymentModal
          {...args}
          onClose={args.onCancelCreate}
          onDeploymentCreated={(deploymentID: string) => {
            refetchDeployments();
            args.onViewDeployment(deploymentID);
          }}
        />
      )}
      <Card.Content textAlign="left">
        <StyledCardHeader>
          <FloatLeft>
            <Popup
              trigger={
                <span>
                  <PrepaidIcon />
                </span>
              }
              content={
                <p className="para">
                  Your contract contains this prepaid deployment. Click on <b>create</b> to turn it into a running deployment.
                </p>
              }
            />
          </FloatLeft>
          <span className="text-link">{args.item.name}</span>
        </StyledCardHeader>
        <StyledGrid columns={2}>
          <Grid.Column textAlign="center">
            <Field>
              <FieldLabel>Provider</FieldLabel>
              <FieldValue>
                <Location {...args} regionId={args.item.region_id} showProvider showProviderIcon={false} showRegion={false} />
              </FieldValue>
            </Field>
            <Field>
              <FieldLabel>Region</FieldLabel>
              <FieldValue>
                <Location {...args} regionId={args.item.region_id} showProvider={false} showProviderIcon={false} showRegion />
              </FieldValue>
            </Field>
            <Field>
              <FieldLabel>Support plan</FieldLabel>
              <FieldValue>{args.support_plan.name || "?"}</FieldValue>
            </Field>
            <Field>
              <FieldLabel>Prepaid period</FieldLabel>
              <FieldValue>
                {moment(args.item.starts_at).format("ll")} {" - "} {moment(args.item.ends_at).format("ll")}
              </FieldValue>
            </Field>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Field>
              <FieldLabel>Model</FieldLabel>
              <FieldValue>
                <ModelTitle model={model.model || ""} />
              </FieldValue>
            </Field>
            <Field>
              <FieldLabel>Node memory</FieldLabel>
              <FieldValue>
                {node_size.memory_size ? node_size.memory_size : "?"}
                <span> GB</span>
              </FieldValue>
            </Field>
            <Field>
              <FieldLabel>Node CPU</FieldLabel>
              <FieldValue>{cpu_size.name || "?"}</FieldValue>
            </Field>
            <Field>
              <FieldLabel>Node disk</FieldLabel>
              <FieldValue>
                {model.node_disk_size || "?"}
                <span> GB</span>
              </FieldValue>
            </Field>
          </Grid.Column>
        </StyledGrid>
      </Card.Content>
      <ButtonsCardContent extra>
        <StretchedButtonGroup className="two">
          {has_prepaiddeployment_create && (
            <Button disabled={!is_active} onClick={() => args.onClickCreate()}>
              create
            </Button>
          )}
        </StretchedButtonGroup>
      </ButtonsCardContent>
    </DeploymentCard>
  );
};

interface IPrepaidDeploymentCardProps extends IWithRefreshProps, IDataVolumeStats {
  item: ApiPrepaidDeployment;
  organization: ApiOrganization;
  project: ApiProject;
  onViewDeployment: (deploymentID: string) => void;
}

// Interface decribing the state of the CACertificate component
interface IPrepaidDeploymentCardState {
  prev_project_id?: string;
  prev_region_id?: string;
  prev_support_plan_id?: string;

  node_sizes?: ApiNodeSizeList;
  cpu_sizes?: ApiCPUSizeList;
  support_plan?: ApiSupportPlan;
  creating: boolean;
  refreshNeeded: boolean;
}

class IPrepaidDeploymentCard extends Component<IPrepaidDeploymentCardProps, IPrepaidDeploymentCardState> {
  state = {
    prev_project_id: undefined,
    prev_region_id: undefined,
    prev_support_plan_id: undefined,
    node_sizes: undefined,
    cpu_sizes: undefined,
    support_plan: undefined,
    creating: false,
    refreshNeeded: false,
  } as IPrepaidDeploymentCardState;

  reloadNodeSizes = async () => {
    const req = {
      project_id: this.props.project.id,
      region_id: this.props.item.region_id,
    } as ApiNodeSizesRequest;
    const node_sizes = await apiClients.dataClient.ListNodeSizes(req);
    this.setState({ node_sizes: node_sizes });
  };

  refreshNodeSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadNodeSizes);
  };

  reloadCPUSizes = async () => {
    const req = {
      project_id: this.props.project.id,
    } as ApiListCPUSizesRequest;
    const cpu_sizes = await apiClients.dataClient.ListCPUSizes(req);
    this.setState({ cpu_sizes: cpu_sizes });
  };

  refreshCPUSizes = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCPUSizes);
  };

  reloadSupportPlan = async () => {
    const idOptions = { id: this.props.item.support_plan_id } as ApiIDOptions;
    const support_plan = await apiClients.supportClient.GetPlan(idOptions);
    this.setState({ support_plan: support_plan });
  };

  refreshSupportPlan = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadSupportPlan);
  };

  static getDerivedStateFromProps(props: IPrepaidDeploymentCardProps, state: IPrepaidDeploymentCardState) {
    if (props.project.id != state.prev_project_id || props.item.region_id != state.prev_region_id || props.item.support_plan_id != state.prev_support_plan_id) {
      return {
        prev_project_id: props.project.id,
        prev_region_id: props.item.region_id,
        prev_support_plan_id: props.item.support_plan_id,
        creating: false,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshNodeSizes();
    this.refreshCPUSizes();
    this.refreshSupportPlan();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshNodeSizes();
        this.refreshCPUSizes();
        this.refreshSupportPlan();
      });
    }
  }

  onClickCreate = () => {
    this.setState({ creating: true });
  };
  onCancelCreate = () => {
    this.setState({ creating: false });
  };

  render() {
    const node_sizes = this.state.node_sizes || {};
    const cpu_sizes = this.state.cpu_sizes || {};
    const support_plan = this.state.support_plan || {};

    return (
      <PrepaidDeploymentCardView
        {...this.props}
        {...this.state}
        {...this}
        node_sizes={node_sizes.items || []}
        cpu_sizes={cpu_sizes.items || []}
        support_plan={support_plan}
      />
    );
  }
}

export default withRefresh()(IPrepaidDeploymentCard);
