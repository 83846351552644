//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import styled from "@emotion/styled";
import Slider, { SliderProps, Handle, HandleProps, Range, RangeProps } from "rc-slider";
import { Grid } from "semantic-ui-react";

interface handleArgs extends HandleProps {
  value: any;
  transformedValues?: number[];
  index: number;
}

const Tooltip = styled("div")`
  position: absolute;
  transform: translateX(-25%) translateY(-32px);
  white-space: nowrap;
  display: inline-block;
`;

export const SliderWithTooltip = ({ ...args }: SliderProps) => (
  <StyledSlider
    {...args}
    handle={(props: handleArgs) => (
      <Handle {...props}>
        <Tooltip>{args.tipFormatter ? args.tipFormatter(props.value) : props.value}</Tooltip>
      </Handle>
    )}
  />
);

interface ISliderWithLabelsProps extends SliderProps {
  label_left?: string;
  label_right?: string;
}

interface IRangeSliderProps extends RangeProps {
  transformedValues?: number[];
}

export const SliderWithLabels = ({ ...args }: ISliderWithLabelsProps) => (
  <Grid>
    {args.label_left && (
      <Grid.Column width="2" textAlign="right">
        {args.label_left}
      </Grid.Column>
    )}
    <Grid.Column width="12">
      <StyledSlider {...args} />
    </Grid.Column>
    {args.label_right && <Grid.Column width="2">{args.label_right}</Grid.Column>}
  </Grid>
);

export const RangeSliderWithLabels = (props: IRangeSliderProps) => {
  const { transformedValues } = props;
  return (
    <StyledRangeSlider
      {...props}
      handle={(props: handleArgs) => {
        return <Handle {...props}>{<Tooltip>{!!transformedValues && `${transformedValues[props.index]} GB`}</Tooltip>}</Handle>;
      }}
    />
  );
};

export const SliderRow = styled(Grid.Row)`
  padding-top: 48px !important;
`;

export const StyledSlider = styled(Slider)`
  &.rc-slider-disabled {
    background-color: inherit !important;
  }
  .rc-slider-track,
  .rc-slider-dot-active {
    background-color: var(--green-700);
  }
  .rc-slider-handle,
  .rc-slider-dot-active {
    background-color: var(--green-700);
    border-color: var(--green-700);
    &:focus {
      box-shadow: 0 0 0 5px var(--green-700);
    }
  }
`;

export const StyledRangeSlider = styled(Range)`
  &.rc-slider-disabled {
    background-color: inherit !important;
  }
  .rc-slider-track,
  .rc-slider-dot-active {
    background-color: var(--green-700);
  }
  .rc-slider-handle,
  .rc-slider-dot-active {
    background-color: var(--green-700);
    border-color: var(--green-700);
    &:focus {
      box-shadow: 0 0 0 5px var(--green-700);
    }
  }
`;
