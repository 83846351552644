//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "./HomeTemplate";
import DocumentTitle from "react-document-title";
import { Routes } from "../routes";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { ITracking } from "../tracking/api";
import { Box } from "../ui/_box";

interface IEmailVerifiedViewArgs {
  tracking: ITracking;
  isAuthenticated: boolean;
  onContinue: () => void;
}

const EmailVerifiedView = ({ ...args }: IEmailVerifiedViewArgs) => {
  return (
    <HomeTemplateView {...args} signupModalOpen={false} onCloseSignupModal={() => {}}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
              <h1 className="heading-1">
                Welcome back!
                <br />
                Your email has been verified
              </h1>
            </Grid.Column>
            {false && (
              <Grid.Column width="8" only="computer tablet">
                <TransparentLogo1 src={cloud} />
                <TransparentLogo2 src={cloud} />
                {/*<!-- FullLogo src={cloud} />*/}
              </Grid.Column>
            )}
          </GridRowIntro>
        </Grid>

        <p className="para">Please continue to login to ArangoGraph.</p>

        <p className="para">
          <Button primary onClick={args.onContinue} size="large">
            Log in
          </Button>
        </p>
      </Box>
    </HomeTemplateView>
  );
};

interface IAuth {
  isAuthenticated: () => boolean;
  login: () => void;
  logoutTo: (route: string) => void;
}

interface IEmailVerifiedProps extends RouteComponentProps {
  tracking: ITracking;
  auth: IAuth;
  entry?: boolean;
}

interface IEmailVerifiedState {}

// EmailVerified is the landing page after email verification
class EmailVerified extends Component<IEmailVerifiedProps, IEmailVerifiedState> {
  state = {} as IEmailVerifiedState;

  login = () => {
    this.props.auth.login();
  };

  componentDidMount() {
    if (!!this.props.entry) {
      if (this.props.auth.isAuthenticated()) {
        this.props.auth.logoutTo(Routes.email_verified);
      } else {
        this.props.history.replace(Routes.email_verified);
      }
    } else {
      this.props.tracking.trackEmailVerified();
    }
  }

  render() {
    return (
      <DocumentTitle title="Email verified">
        <EmailVerifiedView {...this.props} {...this.state} isAuthenticated={this.props.auth.isAuthenticated()} onContinue={this.login} />
      </DocumentTitle>
    );
  }
}

export default EmailVerified;
