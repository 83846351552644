//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Grid, InputOnChangeData } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization, OrganizationInvite as ApiOrganizationInvite } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";

interface ICreateOrganizationInviteViewArgs extends ICreateOrganizationInviteState, ICreateOrganizationInviteProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  createOrganizationInvite: () => void;
}

const CreateOrganizationInviteView = ({ ...args }: ICreateOrganizationInviteViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Creating organization invite, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <Section>
          <SectionHeader title="Recipient" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={8}>
                  <Form.Input autoFocus required label="E-mail" placeholder="e-mail" name="email" value={args.email} onChange={args.updateFormInputChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active || !args.email} onClick={args.createOrganizationInvite} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.history.goBack} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the CreateOrganizationInvite component
interface ICreateOrganizationInviteProps extends RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onNewOrganizationInviteCreated: (organizationInviteId: string) => void;
}

// Interface decribing the state of the CreateOrganizationInvite component
interface ICreateOrganizationInviteState {
  errorMessage?: string;
  processing: boolean;
  email: string;
}

// Component to create a group
class CreateOrganizationInvite extends Component<ICreateOrganizationInviteProps, ICreateOrganizationInviteState> {
  state = {
    errorMessage: undefined,
    processing: false,
    email: "",
  } as ICreateOrganizationInviteState;

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "email":
        this.setState({ email: args.value });
        break;
    }
  };

  createOrganizationInvite = async () => {
    if (!this.state.email) {
      this.setState({ errorMessage: "Please enter an email address" });
      return;
    }
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const organizationInvite = {
        organization_id: this.props.organization.id,
        email: this.state.email,
      } as ApiOrganizationInvite;
      const createdOrganizationInvite = await apiClients.resourceManagerClient.CreateOrganizationInvite(organizationInvite);

      this.props.onNewOrganizationInviteCreated(createdOrganizationInvite.id || "");
    } catch (e) {
      this.setState({ errorMessage: `OrganizationInvite creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Organization Invites", Routes.dashboard_organization_organizationInvitesWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("organization-invite");
    this.props.topMenuInfo.setTitles("New organization invite", "");
  };

  render() {
    return (
      <CreateOrganizationInviteView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        createOrganizationInvite={this.createOrganizationInvite}
      />
    );
  }
}

export default CreateOrganizationInvite;
