//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Organization as ApiOrganization } from "../../api/lib";
import { DangerZoneItem } from "../../ui/lib";

interface IDangerZoneViewArgs {
  organization: ApiOrganization;
  isFreeTier: boolean;
  deleteDisabled: boolean;
  onDeleteOrganization: () => void;
  lockDisabled: boolean;
  onLockOrganization: () => void;
  unlockDisabled: boolean;
  onUnlockOrganization: () => void;
}

export const DangerZoneView = ({ ...args }: IDangerZoneViewArgs) => {
  const locked = !!args.organization.locked;
  return (
    <div>
      <p className="para">Find listed all critical actions concerning your organization.</p>
      {!locked && (
        <DangerZoneItem buttonIcon="lock" buttonText="Lock organization..." onClick={args.onLockOrganization} disabled={args.lockDisabled}>
          <strong>Lock</strong> this organization. A locked organization cannot be deleted without unlocking first.
        </DangerZoneItem>
      )}
      {locked && (
        <DangerZoneItem buttonIcon="unlock" buttonText="Unlock organization..." onClick={args.onUnlockOrganization} disabled={args.unlockDisabled}>
          <strong>Unlock</strong> this organization. You must unlock before the organization can be deleted.
        </DangerZoneItem>
      )}
      <DangerZoneItem
        buttonIcon="trash"
        buttonColor="red"
        buttonText="Delete organization..."
        onClick={args.onDeleteOrganization}
        disabled={args.deleteDisabled}
      >
        <span>
          <strong>Delete</strong> this organization and all projects &amp; deployments (and other resources) in it.
        </span>
      </DangerZoneItem>
    </div>
  );
};
