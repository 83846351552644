//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "./HomeTemplate";
import Markdown from "react-markdown";
import apiClients from "../api/apiclients";
import { TermsAndConditions as ApiTermsAndConditions } from "../api/lib";
import { ErrorMessage, Loading } from "../ui/lib";
import DocumentTitle from "react-document-title";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { reportError } from "../errors/reporting";
import { Box } from "../ui/_box";

interface ITermsAndConditionsViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
  termsAndConditions?: ApiTermsAndConditions;
  errorMessage?: string;
}

const TermsAndConditionsView = ({ ...args }: ITermsAndConditionsViewArgs) => {
  const has_termsAndConditions = !!args.termsAndConditions;
  const termsAndConditions = args.termsAndConditions || {};

  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
              <h1 className="heading-1">Terms &amp; Conditions</h1>
            </Grid.Column>
            {false && (
              <Grid.Column width="8" only="computer tablet">
                <TransparentLogo1 src={cloud} />
                <TransparentLogo2 src={cloud} />
                {/*<!-- FullLogo src={cloud} />*/}
              </Grid.Column>
            )}
          </GridRowIntro>
        </Grid>

        <ErrorMessage active={!!args.errorMessage} message={args.errorMessage} />
        {!has_termsAndConditions && <Loading message="Loading terms &amp; conditions..." />}

        {has_termsAndConditions && (
          <Segment>
            <Markdown
              children={termsAndConditions.content || ""}
              components={{
                p: ({ children }) => <p className="para">{children}</p>,
                ol: ({ children }) => <ol className="ordered-list">{children}</ol>,
                ul: ({ children }) => <ul className="unordered-list">{children}</ul>,
                h1: ({ children }) => <h1 className="heading-1">{children}</h1>,
                h2: ({ children }) => <h2 className="heading-2">{children}</h2>,
                h3: ({ children }) => <h3 className="heading-3">{children}</h3>,
                h4: ({ children }) => <h4 className="heading-4">{children}</h4>,
                h5: ({ children }) => <h5 className="heading-5">{children}</h5>,
              }}
            />
          </Segment>
        )}
      </Box>
    </HomeTemplateView>
  );
};

interface ITermsAndConditionsProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

interface ITermsAndConditionsState {
  termsAndConditions?: ApiTermsAndConditions;
  errorMessage?: string;
}

// TermsAndConditions shows the current T&C
class TermsAndConditions extends Component<ITermsAndConditionsProps, ITermsAndConditionsState> {
  state = {
    termsAndConditions: undefined,
    errorMessage: undefined,
  } as ITermsAndConditionsState;

  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  reloadTermsAndConditions = async () => {
    const req = {};
    const termsAndConditions = await apiClients.resourceManagerClient.GetCurrentTermsAndConditions(req);
    this.setState({
      termsAndConditions: termsAndConditions,
      errorMessage: undefined,
    });
  };

  refreshTermsAndConditions = () => {
    try {
      this.reloadTermsAndConditions();
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    }
  };

  componentDidMount() {
    this.refreshTermsAndConditions();
  }

  render() {
    return (
      <DocumentTitle title="Terms and conditions">
        <TermsAndConditionsView
          {...this.props}
          {...this.state}
          isAuthenticated={this.props.auth.isAuthenticated()}
          onClickLogin={this.login}
          onSignup={this.signup}
          onClickLogout={this.logout}
        />
      </DocumentTitle>
    );
  }
}

export default withSignupModal(TermsAndConditions);
