//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import * as React from "react";
import { Modal } from "semantic-ui-react";
import { Routes } from "../routes";
import { Subtract } from "utility-types";
import { ITracking } from "../tracking/api";
import history from "../history";
import { FlexBox } from "../ui/_flex";
import { Box } from "../ui/_box";
import { FormActionButtonCancel, FormActionButtonSave } from "../ui/_buttons";

export interface ISignupModalViewArgs {
  tracking: ITracking;
  signupModalOpen: boolean;
  onCloseSignupModal: () => void;
  onSignup: () => void;
}

export const SignupModalView = ({ ...args }: ISignupModalViewArgs) => {
  const [processing, setProcessing] = React.useState(false);

  const onAccept = () => {
    setProcessing(true);
    args.tracking.trackAcceptedTerms();
    args.onSignup();
  };

  const onDoNotAccept = () => {
    args.tracking.trackNotAcceptedTerms();
    args.onCloseSignupModal();
    history.push(Routes.home);
  };

  return (
    <Modal
      open={args.signupModalOpen}
      onClose={args.onCloseSignupModal}
      onUnmount={() => {
        setProcessing(false);
      }}
      onOpen={args.tracking.trackSignUpClicked}
    >
      <Modal.Header>
        <Box padding="10px" fontSize={"1.5rem"} fontWeight={600}>
          Sign up to ArangoGraph
        </Box>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Box padding="10px" fontSize={"1.1rem"}>
            Before signing up, please accept our{" "}
            <a href={Routes.terms_and_conditions} className="text-link" target="_blank" rel="noreferrer">
              terms &amp; conditions
            </a>{" "}
            and{" "}
            <a href={Routes.privacy_policy} className="text-link" target="_blank" rel="noreferrer">
              privacy policy
            </a>
            .
          </Box>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FlexBox justify="flex-end" padding={"15px 0"}>
          <FormActionButtonCancel disabled={processing} onClick={onDoNotAccept} title="Decline" />
          <FormActionButtonSave primary loading={processing} disabled={processing} onClick={onAccept} icon="checkmark" title="Accept" />
        </FlexBox>
      </Modal.Actions>
    </Modal>
  );
};

export interface IWithSignupModalProps extends IWithSignupModalInjectedProps {
  tracking: ITracking;
}

interface IAuth {
  // Go to signup on auth0
  signup: () => void;
}

// Interface decribing the external properties of the withSignupModal HOC (higher-order component)
interface IWithSignupModalExternalProps {
  auth: IAuth;
}

// Interface decribing the properties of the withSignupModal HOC (higher-order component)
export interface IWithSignupModalInjectedProps {
  // Once clicked, this will open the signup modal
  onClickSignup: () => void;
  // Is the signup modal open?
  signupModalOpen: boolean;
  // Fired when the signup modal is closing;
  onCloseSignupModal: () => void;
}

// Interface decribing the state of the withSignupModal HOC (higher-order component)
export interface IWithSignupModalState {
  signupModalOpen: boolean;
}

export interface IWithSignupModalAdditionalProps extends IWithSignupModalInjectedProps, IWithSignupModalExternalProps {}

export const withSignupModal = <P extends IWithSignupModalAdditionalProps>(WrappedComponent: React.ComponentType<P>) =>
  class WithSignupModal extends React.Component<Subtract<P, IWithSignupModalInjectedProps>, IWithSignupModalState> {
    state = {
      signupModalOpen: false,
    } as IWithSignupModalState;

    // Define how your HOC is shown in ReactDevTools
    static displayName = `WithSignupModal(${WrappedComponent.displayName || WrappedComponent.name})`;

    onClickSignup = () => {
      // OAS-9324: replace terms and conditions by auth0 terms and conditions.
      // Because of legal reasons this was urgent, there will be another ticket
      // for removing the whole signup/terms and condtions modal/flow. Disabled it for now.
      this.props.auth.signup();
      // this.setState({ signupModalOpen: true });
    };

    onCloseSignupModal = () => {
      this.setState({ signupModalOpen: false });
    };

    render(): JSX.Element {
      // render the wrapped component, passing the props and state
      return (
        <WrappedComponent
          {...(this.props as P)}
          signupModalOpen={this.state.signupModalOpen}
          onSignup={this.props.auth.signup}
          onClickSignup={this.onClickSignup}
          onCloseSignupModal={this.onCloseSignupModal}
        />
      );
    }
  };
