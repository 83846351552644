//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { Project as ApiProject, ProjectList as ApiProjectList } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  CommonContentAction,
  Confirm,
  ConfirmInfo,
  ContentSegment,
  ErrorMessage,
  ListActionDelete,
  ListActionView,
  LoaderBoxForTable as LoaderBox,
  Loading,
  MainContent,
  Processing,
  TextLink,
  Locked,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem } from "../TopMenuInfo";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import { NewProjectButton } from "../newProject/NewProjectButton";

// Interface describing a project
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a projects
interface IRowView {
  active: boolean;
  item: ApiProject;
  onClickView: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const { hasPermissionByUrl } = useWithRefresh();
  const locked = !!args.item.locked;
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink
          label={args.item.name}
          onClick={args.onClickView}
          disabled={!hasPermissionByUrl || !hasPermissionByUrl(args.item.url || "", ResourceType.Project, "resourcemanager.project.get")}
        />
        {locked && <Locked />}
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {hasPermissionByUrl?.(args.item.url || "", ResourceType.Project, "resourcemanager.project.get") && (
            <ListActionView disabled={!args.active} onClick={args.onClickView} />
          )}
          {hasPermissionByUrl?.(args.item.url || "", ResourceType.Project, "resourcemanager.project.delete") && (
            <ListActionDelete disabled={!args.active || locked} onClick={args.onClickDelete} />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the project
interface IListView {
  active: boolean;
  items: ApiProject[];
  onClickView: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { loading, registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No project inside this organization</div>;

// Interface describing the project list view arguments
export interface IProjectListViewArgs {
  active: boolean;
  projects?: ApiProjectList;
  onClickView: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const ProjectListView = ({ ...args }: IProjectListViewArgs) => {
  if (!args.projects) {
    return <Loading />;
  }
  if (!args.projects.items || args.projects.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.projects.items} />;
};

// Interface decribing the properties of the project list component
interface IProjectListProps {
  onProjectSelected: (projectId: string) => void;
}

// The component to show the projects inside an organization as a list.
const ProjectList = ({ ...props }: IProjectListProps) => {
  const { subscribeUrl, refreshNow } = useWithRefresh();
  const { topMenuInfo, selectedOrganization } = useDashboardContext();
  const [projects, setProjects] = useState<ApiProjectList | undefined>(undefined);
  const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo | undefined>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const history = useHistory();
  const { items = [] } = projects || {};

  const reloadProjects = async () => {
    const listOptions = { context_id: selectedOrganization.id } as ApiListOptions;
    const projectsResult = await apiClients.resourceManagerClient.ListProjects(listOptions);
    setProjects(projectsResult);
  };

  const onClickView = (id: string) => props.onProjectSelected(id);

  const getProjectName = (id: string) => {
    const project = items.find((p) => p.id == id) || {};

    return project.name || "";
  };

  const onClickDelete = async (id: string) => {
    const projectName = getProjectName(id);
    const resultConfirmInfo = {
      header: "Delete Project",
      content: `Are you sure you want to delete project '${projectName}'?`,
      warning: "This implies deletion of the deployments in this project and all data stored in the database!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: () => onClickDeleteConfirmed(id),
      onDenied: () => setConfirmInfo(undefined),
    } as ConfirmInfo;

    setConfirmInfo(resultConfirmInfo);
  };

  const onClickDeleteConfirmed = async (id: string) => {
    try {
      setProcessing(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);

      const idOptions = { id } as ApiIDOptions;
      await apiClients.resourceManagerClient.DeleteProject(idOptions);

      refreshNow?.(reloadProjects);
    } catch (e) {
      setErrorMessage(`Project deletion failed: ${e}`);
      reportError(e);
    }
    setProcessing(false);
  };

  const handleDismissError = () => setErrorMessage(undefined);

  const updateTopMenu = () => {
    topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || ""))
    );
    topMenuInfo.setBreadCrumbItems(breadCrumb);
    topMenuInfo.setImageSource("projects");
    topMenuInfo.setTitles("Projects", "");
  };

  useEffect(() => {
    subscribeUrl?.(reloadProjects, `${selectedOrganization.url}/Project/*`);
    updateTopMenu();
  }, []);

  return (
    <ContentSegment>
      <Confirm confirmInfo={confirmInfo} />
      <Processing active={processing} message="Deleting project, please wait..." />
      <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />
      <CommonContentAction>
        <NewProjectButton />
      </CommonContentAction>
      <MainContent>
        <ProjectListView {...props} projects={projects} active={!processing} onClickView={onClickView} onClickDelete={onClickDelete} />
      </MainContent>
    </ContentSegment>
  );
};

export default ProjectList;
