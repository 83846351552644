//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { CheckboxProps } from "semantic-ui-react";
import { useFileAndEdgeModifiers } from "../hooks/useFileAndEdgeModifiers";
import { useGraphEdgeHandlers } from "../../graph-modeller/GraphProvider";
import { StyledCheckbox, StyledFileHeader } from "../Styles";
import { StyledEmptySegment } from "../../../../../ui/_style";
import { EXTRA_FIELD } from "../../hooks/useFileParser";

export const EdgeFileMetadata = () => {
  const { getEdge } = useGraphEdgeHandlers();

  const edge = getEdge();

  const {
    assignFileHeadersToEdgeInGlobalStore,
    removeFileHeadersOfEdgeFromGlobalStore,
    getAssignedFileHeadersToEdgeFromGlobalStore,
    getAssignedFileToEdgeFromGlobalStore,
  } = useFileAndEdgeModifiers();
  const selectedFile = getAssignedFileToEdgeFromGlobalStore();

  const [checkedFields, setCheckedFieldsInLocalState] = useState<string[]>([]);

  useEffect(() => {
    if (!isEmpty(edge)) {
      setCheckedFieldsInLocalState(getAssignedFileHeadersToEdgeFromGlobalStore() || []);
    }
  }, [edge]);

  const handleFieldsSelection = (key: string, checked?: boolean) => {
    if (checked) {
      assignFileHeadersToEdgeInGlobalStore(key);
      setCheckedFieldsInLocalState([...checkedFields, key]);
      return;
    }

    removeFileHeadersOfEdgeFromGlobalStore(key);
    setCheckedFieldsInLocalState(checkedFields.filter((f) => f !== key));
  };

  if (!selectedFile) return <StyledEmptySegment>Assign a file to this edge to see the headers here.</StyledEmptySegment>;

  return (
    <>
      {Object.keys(selectedFile.fields).map((key: string) => {
        if (key !== EXTRA_FIELD) {
          return (
            <StyledFileHeader justify="space-between" align="center" margin="10px 0" key={key}>
              <StyledCheckbox
                label={key}
                className="file-data-header mask-data"
                checked={checkedFields.includes(`${key}`)}
                onChange={(_: unknown, data: CheckboxProps) => {
                  const { checked } = data;
                  handleFieldsSelection(key, checked);
                }}
              />

              <div className="file-data-value ellipsis mask-data" title={selectedFile.fields[key]}>
                {!!selectedFile.fields[key] ? selectedFile.fields[key] : "-"}
              </div>
            </StyledFileHeader>
          );
        }
        return null;
      })}
    </>
  );
};
