//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Divider, Loader, Message, Modal } from "semantic-ui-react";
import { CopyBackupRequest, Region } from "../../api/lib";
import SelectRegion from "../deployment/SelectRegion";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonCreate } from "../../ui/lib";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

interface ICloneBackupToDifferentRegionArgs extends IWithRefreshProps {
  open?: boolean;
  deploymentRegion: string;
  isMultiRegionBackupEnabled?: boolean;
  sourceID: string;
  title?: string;
  onClose: () => void;
}

const CopyBackupToDifferentRegion = ({ ...args }: ICloneBackupToDifferentRegionArgs) => {
  const organization = useGlobalStore((state) => state.organization);
  const provider = useDeploymentStore((state) => state.provider);
  const [targetRegion, updateTargetRegion] = useState<Region>({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const copyBackup = async () => {
    setLoading(true);
    try {
      const req: CopyBackupRequest = {
        source_backup_id: args.sourceID,
        region_id: targetRegion.id,
      };
      await apiClients.backupClient.CopyBackup(req);
      args.onClose();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const { isPerpetualFreeTrialAvailable } = useDashboardContext();

  return (
    <Modal open>
      <Modal.Header data-testid="CopyBackupModalHeader">
        {args.title ? args.title : "Copy backup to a different region"} <Loader active={loading} />{" "}
      </Modal.Header>
      <Modal.Content>
        <ErrorMessage active={!!error} onDismiss={() => setError("")} message={error} testID="CopyBackupModalError" />
        <Message data-testid="CopyBackupModalMessage">Select a region to copy your backup</Message>
        <Divider hidden />
        <SelectRegion
          {...args}
          organizationId={organization.id || ""}
          provider={provider}
          select_first={false}
          onRegionUpdated={function (region?: Region | undefined): void {
            updateTargetRegion(region || {});
          }}
          isPerpetualFreeTrialAvailable={isPerpetualFreeTrialAvailable}
        />
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={args.onClose} testID="CopyBackupModalCancelAction" />
        <FormActionButtonCreate primary onClick={copyBackup} loading={loading} disabled={loading} testID="CopyBackupModalConfirmAction" />
      </Modal.Actions>
    </Modal>
  );
};

export default withRefresh()(CopyBackupToDifferentRegion);
