//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Td } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { RoleBinding } from "../../api/lib";
import { LinkText } from "../../components/LinkText";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { useFetchRoleInfoList } from "./useFetchRoleInfoList";

export const RoleCell = ({ roleBindings }: { roleBindings: (RoleBinding | undefined)[] }) => {
  const firstTwoRoleIds = roleBindings
    .slice(0, 2)
    .map((role) => role?.role_id)
    .filter((role) => role) as string[];
  const { data: roleInfoList, status } = useFetchRoleInfoList({ roleIds: firstTwoRoleIds });
  const { selectedOrganization } = useDashboardContext();
  if (status === "loading") {
    return (
      <Td>
        <Spinner size="xs" />
      </Td>
    );
  }
  return (
    <Td>
      <Flex gap="2" alignItems="center">
        <Flex as="span" display="inline-flex" gap="1">
          {roleInfoList?.map((role) => {
            if (!role) {
              return null;
            }
            return (
              <Flex display="inline-flex" key={role.id}>
                <LinkText
                  key={role.id}
                  as={RouterLink}
                  color="blue.600"
                  to={Routes.dashboard_organization_role_detailsWithId(role.organization_id || selectedOrganization.id || "", role.id || "")}
                >
                  {role.name}
                </LinkText>
                {
                  // Add a comma after the role name if there is another role
                  // and the current role is the first role
                  roleBindings.length > 1 && roleBindings[0]?.role_id === role.id && ", "
                }
              </Flex>
            );
          })}
        </Flex>
        <MoreRolesButton roleBindings={roleBindings} />
      </Flex>
    </Td>
  );
};

const MoreRolesButton = ({ roleBindings }: { roleBindings: (RoleBinding | undefined)[] }) => {
  if (roleBindings.length <= 2) {
    return null;
  }
  return (
    <Popover isLazy trigger="click" offset={[64, 8]}>
      <PopoverTrigger>
        <Button size="xs" display="inline-flex" alignItems="center" gap="1" rightIcon={<ChevronDownIcon />}>
          {`${roleBindings.length - 2} more`}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        _focus={{
          outline: "none",
        }}
      >
        <PopoverArrow />
        <PopoverBody>
          <RolePopoverBody roles={roleBindings} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const RolePopoverBody = ({ roles }: { roles: (RoleBinding | undefined)[] }) => {
  const { selectedOrganization } = useDashboardContext();
  // skip first two roles
  const roleIds = roles
    .slice(2)
    .map((role) => role?.role_id)
    .filter((role) => role) as string[];
  const { data: roleInfoList, status } = useFetchRoleInfoList({ roleIds });
  if (status === "loading") {
    return <Spinner size="xs" />;
  }
  return (
    <Flex direction="column" gap="2" maxHeight="300px" overflow="auto">
      {roleInfoList?.map((role) => (
        <LinkText key={role?.id} as={RouterLink} to={Routes.dashboard_organization_role_detailsWithId(selectedOrganization.id || "", role?.id || "")}>
          {role?.name}
        </LinkText>
      ))}
    </Flex>
  );
};
