//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { Icon, Menu, SemanticICONS } from "semantic-ui-react";
import { StyledTabWithBadge } from "./_style";

const TabHeaderTitle = styled("span")`
  font-size: 1.1rem;
`;

interface ITabHeader {
  key: string;
  title: string | React.ReactNode | undefined;
  icon?: SemanticICONS;
  color?: string;
  rightAligned?: boolean;
  rightContent?: ReactNode | string | undefined;
  observerID?: string;
}

export const TabHeader = ({ ...args }: ITabHeader) => (
  <Menu.Item className="tab-header" key={args.key} style={args.rightAligned ? { marginLeft: "auto" } : {}} data-observerid={args.observerID}>
    <TabHeaderTitle>
      {args.icon && <Icon name={args.icon} style={{ color: args.color || "var(--green-700)" }} />}
      {args.title}
    </TabHeaderTitle>
  </Menu.Item>
);

export const TabWithBadge = ({ ...args }: ITabHeader) => (
  <Menu.Item className="tab-header" key={args.key} style={args.rightAligned ? { marginLeft: "auto" } : {}} data-observerid={args.observerID}>
    <StyledTabWithBadge>
      {args.icon && <Icon name={args.icon} style={{ color: args.color || "var(--green-700)" }} />}
      {args.title}
      <span className="badge">{args.rightContent && <span style={{ marginLeft: "0.5em" }}>{args.rightContent}</span>}</span>
    </StyledTabWithBadge>
  </Menu.Item>
);

export const OverviewTabHeader = () => TabHeader({ key: "overview", title: "Overview", icon: "map signs", observerID: "overview" });
