//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";

export const useFetchRoleInfoList = ({ roleIds }: { roleIds: string[] }) => {
  const fetchRoleInfoList = async () => {
    return await Promise.all(roleIds.map((roleId) => apiClients.iamClient.GetRole({ id: roleId })));
  };
  return useQuery(["roleInfoList", ...roleIds], fetchRoleInfoList);
};
