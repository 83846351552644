//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { FlexBox } from "../../../../ui/_flex";
import { Button, Icon } from "semantic-ui-react";
import { isFileAssociatedInGraph } from "../utils";
import { FileObject } from "../types";
import { StyledActionMessage, StyledDeleteActionButton } from "./Styles";
import { useDataloaderStore } from "../DataloaderStore";

const DeleteFileAction = ({ file }: { file: FileObject }) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);
  const { updateFiles, getDeploymentDataloaderState } = useDataloaderStore();
  const { files } = getDeploymentDataloaderState();

  const onDelete = () => {
    if (isFileAssociatedInGraph(file.id)) return;

    const filesAfterDeletion = files.filter((fileBeforeDelete) => fileBeforeDelete.id !== file.id);
    updateFiles(filesAfterDeletion);
  };

  return (
    <FlexBox align="center">
      {!isConfirmDeleteOpen && (
        <Button size="tiny" disabled={isFileAssociatedInGraph(file.id)} color="red" onClick={() => setIsConfirmDeleteOpen(true)}>
          Delete file
        </Button>
      )}
      {isFileAssociatedInGraph(file.id) && (
        <StyledActionMessage>
          <Icon name="warning circle" color="red" />
          Please remove all graph associations for this file to delete.
        </StyledActionMessage>
      )}
      {isConfirmDeleteOpen && (
        <FlexBox align="center" justify="space-between" width="100%">
          <StyledActionMessage data-testid="delete-confirmation-message">
            Are you sure you want to delete <br />
            <strong>{file.name}</strong>?
          </StyledActionMessage>
          <div>
            <StyledDeleteActionButton size="tiny" onClick={() => setIsConfirmDeleteOpen(false)}>
              Cancel
            </StyledDeleteActionButton>
            <StyledDeleteActionButton size="tiny" color="red" onClick={onDelete}>
              Delete!
            </StyledDeleteActionButton>
          </div>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default DeleteFileAction;
