//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel } from "../../../ui/_buttons";
import { IAWSAvailabilityZonesArgs } from "../types";

const AWSAvailabilityZonesView = ({ onClose, availabilityZones = [] }: IAWSAvailabilityZonesArgs) => {
  return (
    <Modal open onClose={onClose} data-testid="availabilityZoneModal">
      <Modal.Header data-testid="availabilityZoneModalHeader">Availability zones</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          The following zones are available.
          <Divider hidden />
          {availabilityZones && (
            <Message data-testid="availabilityZones">
              <Message.List data-testid="availabilityZonesList">
                {availabilityZones.map((zone: any) => (
                  <Message.Item key={zone} data-testid="availabilityZoneItem">
                    {zone}
                  </Message.Item>
                ))}
              </Message.List>
            </Message>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="availabilityZoneModalCloseAction" />
      </Modal.Actions>
    </Modal>
  );
};

export { AWSAvailabilityZonesView };
