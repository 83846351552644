//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { Toolbar } from "./Toolbar";
import { useDataloaderStore } from "../DataloaderStore";
import { NodeManipulatorMenu } from "./NodeManipulatorMenu";
import { EdgeManipulatorMenu } from "./EdgeManipulatorMenu";
import { GraphCanvas } from "./Styles";
import styled from "@emotion/styled";

const CanvasContainer = styled.div`
  /* Hide zoom in and zoom out buttons */
  .vis-network .vis-button.vis-up,
  .vis-network .vis-button.vis-down,
  .vis-network .vis-button.vis-left,
  .vis-network .vis-button.vis-right,
  .vis-network .vis-button.vis-zoomIn,
  .vis-network .vis-button.vis-zoomOut {
    display: none;
  }

  .vis-button.vis-zoomExtends:hover {
    box-shadow: none !important;
  }
  .vis-button {
    text-align: center !important;
  }

  .vis-button:after {
    font-size: 30px;
    color: #666;
  }

  .vis-button:hover:after {
    font-size: 30px;
    color: lightgray;
  }

  .vis-button.vis-zoomExtends {
    bottom: 15px !important;
    background-image: none !important;
  }

  .vis-button.vis-zoomExtends:after {
    background-image: none !important;
    font-size: 30px;
    content: "¤";
    font-weight: bold;
    text-align: center;
    bottom: 0;
    display: block;
  }
`;

export const Canvas = ({ id }: { id: string }) => {
  const { setActiveEdge: setActiveEdgeInStore, setActiveNode: setActiveNodeInStore } = useDataloaderStore();

  useEffect(() => {
    return () => {
      setActiveEdgeInStore(null);
      setActiveNodeInStore(null);
    };
  }, []);

  return (
    <GraphCanvas className="graph-canvas" id="graphCanvas" canvasRenderTargetID={id}>
      <Toolbar />
      <div className="relative">
        <NodeManipulatorMenu />
        <EdgeManipulatorMenu />
        <CanvasContainer id={id} />
      </div>
    </GraphCanvas>
  );
};
