//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export const setHasSkippedUseCaseSelection = (hasSkippedUseCaseSelection: boolean) => {
  localStorage.setItem("hasSkippedUseCaseSelection", JSON.stringify(hasSkippedUseCaseSelection));
};

export const getHasSkippedUseCaseSelection = () => {
  const hasSkippedUseCaseSelection = localStorage.getItem("hasSkippedUseCaseSelection");
  return hasSkippedUseCaseSelection ? JSON.parse(hasSkippedUseCaseSelection) : true;
};
