import { useFeature } from "flagged";
import React from "react";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import AuditLogNotSupportedTab from "./AuditLogNotSupportedTab";
import ProjectAuditLogTab from "./ProjectAuditLogTab";
import { Redirect } from "react-router-dom";
import { Routes } from "../../routes";

export const ProjectAuditLogView = () => {
  const isAuditLogEnabled = useFeature("auditlog");

  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const { hasPermissionByUrl, refreshNow, loading } = useWithRefresh();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const has_auditlog_list = !!checkOrgPermission("audit.auditlog.list");
  const has_auditlog_feature = !!selectedOrganization.tier?.has_auditlog_feature;
  if (isAuditLogEnabled && has_auditlog_list && has_auditlog_feature) {
    return (
      <ProjectAuditLogTab
        refreshNow={refreshNow}
        hasPermissionByUrl={hasPermissionByUrl}
        project={project}
        organization={selectedOrganization}
        loading={loading}
      />
    );
  }
  if (isAuditLogEnabled && !has_auditlog_feature) {
    return <AuditLogNotSupportedTab />;
  }

  return <Redirect to={Routes.dashboard_project_deploymentsWithId(project.id || "")} />;
};
