//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import React from "react";
import { CACertificateSelector } from "./CACertificateSelector";
import { DBVersionSelector } from "./DBVersionSelector";
import { DeploymentProfileSelector } from "./DeploymentProfileSelector";
import { IAMProviderSelector } from "./IAMProviderSelector";
import { IPAllowlistSelector } from "./IPAllowlistSelector";
import { SupportPlanSelector } from "./SupportPlanSelector";
import { TogglableSection } from "./TogglableSection";

export const AdvancedSection = () => {
  return (
    <TogglableSection title="Advanced" defaultIsOpen={false}>
      <Grid gap="4" templateColumns="1fr 1fr">
        <DBVersionSelector />
        <SupportPlanSelector />
        <CACertificateSelector />
        <IPAllowlistSelector />
        <DeploymentProfileSelector />
        <IAMProviderSelector />
      </Grid>
    </TogglableSection>
  );
};
