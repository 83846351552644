//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { AlertStatus, BoxProps } from "@chakra-ui/react";
import React from "react";
import { Notification, NotificationSeverity } from "../api/lib";
import { AlertList } from "./AlertList";

const severityMap: { [key: number]: AlertStatus } = {
  0: "info",
  1: "warning",
  2: "error",
};

export const NotificationBar = ({
  notifications,
  marginBottom,
  borderRadius,
}: {
  notifications: { [key: string]: Notification } | undefined;
  marginBottom?: BoxProps["marginBottom"];
  borderRadius?: BoxProps["borderRadius"];
}) => {
  const hasNotifications = notifications && Object.keys(notifications).length > 0;

  if (!notifications || !hasNotifications) {
    return null;
  }

  const sortedNotifications = Object.keys(notifications)
    .map((notificationKey) => {
      const notification = notifications[notificationKey];
      const severity = (notification.severity && NotificationSeverity[notification.severity]) || NotificationSeverity.NOTIFICATION_SEVERITY_INFO;
      return {
        ...notification,
        key: notificationKey,
        severity: severity,
      };
    })
    .sort((notificationA, notificationB) => {
      // Sort by severity first (descending)
      if (notificationA.severity !== notificationB.severity) {
        return +notificationB.severity - +notificationA.severity;
      }
      // If severities are equal, sort by object key alphabetically (ascending)
      return notificationA.key.localeCompare(notificationB.key);
    });

  return (
    <AlertList
      borderRadius={borderRadius}
      marginBottom={marginBottom}
      alerts={sortedNotifications.map((notification) => ({
        status: severityMap[notification.severity as number],
        message: notification.notification || "",
      }))}
    />
  );
};
