//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchNodeSizes } from "./useFetchNodeSizes";

export const useFetchDeploymentSize = ({
  servers,
  modelId,
  nodeSizeId,
  numberOfNodes,
  regionId,
  customDiskSize,
}: {
  servers?: NewDeploymentFormValues["servers"];
  modelId: string;
  nodeSizeId: string;
  numberOfNodes: number;
  regionId: string;
  customDiskSize: {
    [key: string]: number;
  };
}) => {
  const { data: nodeSizes } = useFetchNodeSizes({
    regionId: regionId,
    modelId: modelId,
  });
  const nodeDiskSize = nodeSizes?.items?.find((ns) => ns.id === nodeSizeId)?.min_disk_size || 0;
  const finalNodeDiskSize = customDiskSize[nodeSizeId] || nodeDiskSize;
  const fetchDeploymentSize = async () => {
    const req = {
      coordinators: servers?.coordinators || 0,
      coordinator_memory_size: servers?.coordinator_memory_size || 0,
      dbservers: servers?.dbservers || 0,
      dbserver_memory_size: servers?.dbserver_memory_size || 0,
      dbserver_disk_size: servers?.dbserver_disk_size || 0,
      model: modelId || "",
      node_size_id: nodeSizeId || "",
      node_disk_size: finalNodeDiskSize,
      node_count: numberOfNodes,
      region_id: regionId,
    };
    const size = await apiClients.dataClient.CalculateDeploymentSize(req);
    return size;
  };
  return useQuery([regionId, modelId, nodeSizeId, finalNodeDiskSize, numberOfNodes, servers, "deploymentSize"], fetchDeploymentSize, {
    refetchOnWindowFocus: false,
    /**
     * Keep previous data when fetching new data
     * This prevents page reflow when new node sizes is fetched
     */
    keepPreviousData: true,
    enabled: !!regionId && !!modelId && !!nodeSizeId && !!numberOfNodes && !!nodeDiskSize,
  });
};
