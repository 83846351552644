//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { find, isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Card, Grid, Segment } from "semantic-ui-react";
import {
  Invoice as ApiInvoice,
  InvoiceList as ApiInvoiceList,
  ListInvoicesRequest as ApiListInvoicesRequest,
  Organization as ApiOrganization,
  GetPreliminaryInvoiceRequest as ApiGetPreliminaryInvoiceRequest,
  CurrencyList as ApiCurrencyList,
} from "../../api/lib";
import { Cached as cachedApiClients } from "../../api/apiclients";
import {
  ContentActionButtonNew,
  ErrorMessage,
  ListActionDownload,
  Loading,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  UnderlinedH3,
} from "../../ui/lib";
import Downloader from "js-file-downloader";
import Auth from "../../auth/Auth";
import { reportError } from "../../errors/reporting";
import InvoiceDetails from "./InvoiceDetailsView";

interface IInvoiceViewArgs {
  invoice: ApiInvoice;
  onDownload: () => void;
}

const InvoiceView = ({ ...args }: IInvoiceViewArgs) => {
  return (
    <Card>
      <Card.Content>
        <Grid width="16">
          <Grid.Column width="4">
            <UnderlinedH3>Number</UnderlinedH3>
            <div>{args.invoice.id}</div>
          </Grid.Column>
          <Grid.Column width="4">
            <UnderlinedH3>Date</UnderlinedH3>
            <div>{moment(args.invoice.created_at).format()}</div>
          </Grid.Column>
          <Grid.Column width="4">
            <UnderlinedH3>Amount</UnderlinedH3>
            <div>{args.invoice.total_amount_incl_taxes}</div>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <ListActionDownload onClick={args.onDownload} />
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};

interface ListViewArgs {
  list?: ApiInvoiceList;
  onDownload: (invoiceId: string) => void;
}

const ListView = ({ ...args }: ListViewArgs) => {
  const has_list = !!args.list;
  const list = args.list || {};
  const items = list.items || [];
  const is_empty = isEmpty(items);
  if (!has_list) {
    return <Loading message="Loading invoices..." />;
  }
  if (is_empty) {
    return <Segment>No invoices yet</Segment>;
  }
  const cards = items.map((x) => <InvoiceView key={x.id} invoice={x} onDownload={() => args.onDownload(x.id || "")} />);
  return <Card.Group itemsPerRow="1">{cards}</Card.Group>;
};

interface IInvoicesAPI {
  ListInvoices: (req: ApiListInvoicesRequest) => Promise<ApiInvoiceList>;
  GetPreliminaryInvoice: (req: ApiGetPreliminaryInvoiceRequest) => Promise<ApiInvoice>;
}

// Interface decribing the properties of the invoices component
interface IInvoicesProps {
  auth: Auth;
  invoiceAPI: IInvoicesAPI;
  organization: ApiOrganization;
  isInvoicePreliminaryViewAllowed: boolean;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

// Interface decribing the state of the invoices component
interface IInvoicesState {
  list?: ApiInvoiceList;
  refreshNeeded: boolean;
  last_organization_id?: string;
  loading: boolean;
  loadingMessage: string;
  currencies?: ApiCurrencyList;
  currencySign?: string;
  errorMessage?: unknown;
  isViewingInvoice: boolean;
  invoice: ApiInvoice;
}

class Invoices extends Component<IInvoicesProps, IInvoicesState> {
  state = {
    refreshNeeded: false,
    errorMessage: undefined,
    loading: false,
  } as IInvoicesState;

  componentDidMount() {
    this.refreshInvoices();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshInvoices);
    }
  }

  reloadInvoices = async () => {
    const req = { organization_id: this.props.organization.id } as ApiListInvoicesRequest;
    const list = await this.props.invoiceAPI.ListInvoices(req);
    this.setState({ list: list });
  };

  refreshInvoices = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInvoices);
  };

  toggleInvoiceDetailsView = () => {
    this.setState((oldState) => ({ isViewingInvoice: !oldState.isViewingInvoice }));
  };

  static getDerivedStateFromProps(props: IInvoicesProps, state: IInvoicesState) {
    if (props.organization.id != state.last_organization_id) {
      return {
        list: undefined,
        refreshNeeded: true,
        downloading: false,
        last_organization_id: props.organization.id,
      };
    }
    // No state update necessary
    return null;
  }

  onDownloadInvoice = async (invoiceID: string) => {
    try {
      this.setState({ loading: true, loadingMessage: "Downloading invoice.... ", errorMessage: undefined });
      const fileURL = `/downloads/invoices/${invoiceID}.pdf`;
      const downloader = new Downloader({
        url: fileURL,
        headers: [{ name: "Authorization", value: `bearer ${this.props.auth.getAccessToken()}` }],
      });
      await downloader;
    } catch (e: unknown) {
      this.setState({ errorMessage: e });
      reportError(e);
    } finally {
      this.setState({ loading: false, loadingMessage: "" });
    }
  };

  setCurrency = () => {
    const { invoice, currencies } = this.state;
    const { currency_id } = invoice;
    const list = (currencies && currencies.items) || [];
    const currency = find(list, (c) => c.id === currency_id);
    this.setState({ currencySign: currency && currency.sign });
  };

  getPreliminaryInvoice = async () => {
    try {
      const { id } = this.props.organization;
      const { currencies = {} } = this.state;
      const req = { organization_id: id } as ApiGetPreliminaryInvoiceRequest;
      this.setState({ isViewingInvoice: false, loading: true, loadingMessage: "Fetching preliminary invoice.... ", errorMessage: undefined });
      const preliminaryInvoice = await this.props.invoiceAPI.GetPreliminaryInvoice(req);
      if (isEmpty(currencies)) {
        const currencyList = await cachedApiClients.currencyClient.ListCurrencies({});
        this.setState({ currencies: currencyList });
      }
      this.setState({ invoice: preliminaryInvoice }, () => {
        this.setCurrency();
        this.toggleInvoiceDetailsView();
      });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ loading: false, loadingMessage: "" });
    }
  };

  render() {
    const { list = {}, isViewingInvoice: isViewingPreliminaryInvoice, loading, loadingMessage, invoice, errorMessage, currencySign = "" } = this.state;
    const { isInvoicePreliminaryViewAllowed } = this.props;

    return (
      <Section>
        <SectionHead>
          <SectionHeader title="Invoices" />
          <SectionButtons>
            {isInvoicePreliminaryViewAllowed && (
              <ContentActionButtonNew
                primary
                content="Get Preliminary Invoice"
                disabled={loading}
                onClick={this.getPreliminaryInvoice}
              ></ContentActionButtonNew>
            )}
            {isViewingPreliminaryInvoice && (
              <InvoiceDetails
                isPreliminaryView
                header="Preliminary Invoice details"
                invoice={invoice}
                currency={currencySign}
                onClose={this.toggleInvoiceDetailsView}
              />
            )}
          </SectionButtons>
          <Processing active={loading} message={loadingMessage} />
        </SectionHead>
        <SectionContent>
          <ErrorMessage active={!!errorMessage} message={errorMessage} />
          <ListView {...this.props} {...this.state} list={list} onDownload={this.onDownloadInvoice} />
        </SectionContent>
      </Section>
    );
  }
}

export default Invoices;
