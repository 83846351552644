//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import styled from "@emotion/styled";
import { Accordion, Icon } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import { IAccordionTitleViewArgs } from "./types";

const StyledAccordionTitle = styled(Accordion.Title)`
  background: var(--gray-50) !important;
  border: 1px solid var(--gray-200) !important;
  boroder-right: 0 !important;
  boroder-left: 0 !important;
  color: var(--gray-600) !important;
  & i.icon.chevron.up {
    transform: rotate(0) !important;
  }
`;

const AccordionTitleView = ({ active, index, testID, label, rightContent = null, onClick }: IAccordionTitleViewArgs) => {
  return (
    <span data-testid={testID}>
      <StyledAccordionTitle
        active={active}
        index={index}
        data-testid={`${testID}Title`}
        onClick={() => {
          onClick && onClick(index);
        }}
      >
        <FlexBox justify="space-between" data-testid={`${testID}Flex`}>
          <span data-testid={`${testID}TitleLabel`}>
            {label} {rightContent}
          </span>
          <Icon name={active ? "chevron up" : "chevron down"} data-testid={`${testID}Icon`} />
        </FlexBox>
      </StyledAccordionTitle>
    </span>
  );
};

export default AccordionTitleView;
