//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useEffect } from "react";
import { useDashboardContext } from "../dashboard/DashboardContextProvider";

export const useDisableTopMenu = () => {
  const { topMenuInfo } = useDashboardContext();

  useEffect(() => {
    topMenuInfo.disable();
    return () => {
      topMenuInfo.enable();
    };
  }, []);
};
