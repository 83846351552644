import React from "react";
import Auth from "./Auth";

const AuthContext = React.createContext<{
  auth: Auth;
}>({} as any);

export const AuthContextProvider = ({ auth, children }: { auth: Auth; children: React.ReactNode }) => {
  return <AuthContext.Provider value={{ auth }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};
