//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import { Checkbox, Form } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";

export const StyledCheckbox = styled(Checkbox)`
  margin: 10px !important;
`;

export const StyledFileHeader = styled(FlexBox)`
  & .file-data-value {
    max-width: 100px;
  }

  & label {
    max-width: 150px;
    word-break: break-word;
    text-align: justify;
  }
`;

export const StyledEditor = styled.div`
  padding: 10px 20px;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 400px);
`;

export const StyledFormInput = styled(Form.Input)`
  &.disabled {
    opacity: 0.8 !important;
    pointer-events: none !important;
    border-bottom: 1px solid var(--gray-200) !important;
  }
`;
