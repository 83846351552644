import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { BreadCrumbTextLink, MenuBreadCrumbText } from "../ui/lib";
import { HistoryHelper } from "./HistoryHelper";
import { BreadCrumbItem } from "./TopMenuInfo";

interface IBreadCrumbViewArgs {
  items: BreadCrumbItem[];
  marginLeft?: string;
  marginRight?: string;
}
export const BreadCrumbView = ({ ...args }: IBreadCrumbViewArgs) => {
  const history = useHistory();
  return (
    <MenuBreadCrumbText marginLeft={args.marginLeft} marginRight={args.marginRight}>
      {args.items.map((item, index) => {
        const goto = item.getTo();
        return (
          <span key={`key_${index}`}>
            <BreadCrumbTextLink
              label={item.getText()}
              disabled={!goto}
              onClick={() => {
                HistoryHelper.push(history, goto || "", item.getText());
              }}
            />{" "}
            <Icon name="angle right" size="small" />
          </span>
        );
      })}
    </MenuBreadCrumbText>
  );
};
