import { Grid } from "@chakra-ui/react";
import React from "react";
import { useDisableTopMenu } from "../../components/useDisableTopMenu";
import { ProjectPolicyList } from "./policy/ProjectPolicyList";
import { ProjectPolicyTopMenu } from "./policy/ProjectPolicyTopMenu";

export const ProjectPoliciesView = () => {
  useDisableTopMenu();
  return (
    <Grid templateRows={"auto minmax(0, 1fr)"} overflow="hidden" height="100vh">
      <ProjectPolicyTopMenu />
      <ProjectPolicyList />
    </Grid>
  );
};
