//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Header } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { StyledWrongFileIcon } from "./Styles";

const FileErrorView = ({ error = "Something went wrong!" }: { error?: string }) => {
  return (
    <FlexBox direction="column" justify="center" height="100%" align="center" position="absolute" top="0" padding="20px" color="var(--gray-600)">
      <StyledWrongFileIcon>
        <Icon name="file alternate outline" color="red" size="huge" />
        <Icon name="close" color="red" size="small" />
      </StyledWrongFileIcon>
      <Header>{error}</Header>
    </FlexBox>
  );
};

export default FileErrorView;
