//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { IPAllowlist as ApiIPAllowlist, IPAllowlistList as ApiIPAllowlistList } from "../../api/lib";
import { Project as ApiProject } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import {
  Confirm,
  ConfirmInfo,
  ListActionDelete,
  ListActionEdit,
  ListActionView,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  TextLink,
  Locked,
  Warnings,
} from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import IPAllowlistDetailsModal from "./IPAllowlistDetailsModal";
import UpdateIPAllowlistModal from "./UpdateIPAllowlistModal";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing a ca certificate
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Allowed CIDR ranges</Table.HeaderCell>
      <Table.HeaderCell>Remote Inspection</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a ca certificate
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiIPAllowlist;
  onClickView: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const locked = !!args.item.locked;
  const warnings = args.item.warnings || [];
  const hasWarning = !_.isEmpty(warnings);
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink
          label={args.item.name}
          onClick={args.onClickView}
          disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.IPAllowlist, "security.ipallowlist.get")}
        />
        {locked && <Locked />}
        {hasWarning && (
          <span style={{ float: "right" }}>
            <Warnings warnings={warnings} />
          </span>
        )}
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.cidr_ranges ? args.item.cidr_ranges.join(", ") : "?"}</Table.Cell>
      <Table.Cell>{args.item.remote_inspection_allowed ? "Allowed" : "Not allowed"}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.IPAllowlist, "security.ipallowlist.get") && (
            <ListActionView onClick={args.onClickView} />
          )}
          {args.hasPermissionByUrl &&
            args.hasPermissionByUrl(args.item.url || "", ResourceType.IPAllowlist, "security.ipallowlist.update") &&
            !args.item.is_deleted && <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />}
          {args.hasPermissionByUrl &&
            args.hasPermissionByUrl(args.item.url || "", ResourceType.IPAllowlist, "security.ipallowlist.delete") &&
            !args.item.is_deleted && <ListActionDelete disabled={!args.active || locked} onClick={args.onClickDelete} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the IP allowlist list
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  items: ApiIPAllowlist[];
  loading: boolean;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No IP allowlists inside this project</div>;

// Interface describing the IP allowlist list view arguments
export interface IIPAllowlistListViewArgs {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  loading: boolean;
  project: ApiProject;
  IPAllowlists?: ApiIPAllowlistList;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const IPAllowlistListView = ({ ...args }: IIPAllowlistListViewArgs) => {
  if (!args.IPAllowlists) {
    return <Loading />;
  }
  const items = args.IPAllowlists.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, "name")} loading={args.loading} />;
};

// Interface decribing the properties of the IP allowlist list component
interface IIPAllowlistListProps extends IWithRefreshProps {
  project: ApiProject;
  ipallowlists?: ApiIPAllowlistList;
  onIPAllowlistDeleted: (id: string) => void;
  onError: (message?: string) => void;
  onRefreshList: () => void;
}

// Interface decribing the state of the IP allowlist list component
interface IIPAllowlistListState {
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  editId?: string;
  viewId?: string;
}

// The component to show the IP allowlists inside a project as a list.
class IPAllowlistList extends Component<IIPAllowlistListProps, IIPAllowlistListState> {
  state = {
    processing: false,
    confirmInfo: undefined,
    editId: undefined,
  } as IIPAllowlistListState;

  onClickView = (id: string) => {
    this.setState({ viewId: id, editId: undefined });
  };
  onCloseView = () => {
    this.setState({ viewId: undefined });
  };

  onClickEdit = (id: string) => {
    this.setState({ editId: id, viewId: undefined });
  };
  onCloseEdit = () => {
    this.setState({ editId: undefined });
  };

  getIPAllowlistName = (id: string) => {
    const lists = this.props.ipallowlists;
    if (lists && lists.items) {
      const list = lists.items.find((c) => c.id == id);
      if (list) {
        return list.name;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const listName = this.getIPAllowlistName(id);
    const confirmInfo = {
      header: "Delete IP allowlist",
      content: `Are you sure you want to delete IP allowlist '${listName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.props.onError(undefined);
      this.setState({ processing: true, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.securityClient.DeleteIPAllowlist(idOptions);
      this.props.onIPAllowlistDeleted(id);
    } catch (e) {
      this.props.onError(`IP allowlist deletion failed: ${e}`);
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onIPAllowlistUpdated = () => {
    this.onCloseEdit();
    this.props.onRefreshList();
  };

  render() {
    const showEdit = !!this.state.editId;
    const showView = !!this.state.viewId;

    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting IP allowlist, please wait..." />
        {showEdit && (
          <UpdateIPAllowlistModal
            {...this.props}
            ipallowlistId={this.state.editId || ""}
            onClose={this.onCloseEdit}
            onIPAllowlistUpdated={this.onIPAllowlistUpdated}
          />
        )}
        {showView && (
          <IPAllowlistDetailsModal
            {...this.props}
            ipallowlistId={this.state.viewId || ""}
            onEdit={() => this.onClickEdit(this.state.viewId || "")}
            onClose={this.onCloseView}
          />
        )}
        <IPAllowlistListView
          {...this.props}
          {...this.state}
          IPAllowlists={this.props.ipallowlists}
          active={!this.state.processing}
          onClickView={this.onClickView}
          onClickEdit={this.onClickEdit}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default withRefresh()(IPAllowlistList);
