//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Header, Modal, Loader } from "semantic-ui-react";

interface IProcessingViewArgs {
  active: boolean;
  message?: string;
  onClose?: () => void;
}

export const Processing = ({ ...args }: IProcessingViewArgs) => {
  const { active, message } = args;
  if (active)
    return (
      <Modal defaultOpen={true} basic size="small" closeOnDimmerClick={false} closeOnEscape={false}>
        <Header content={message || "Processing..."} />
        <Loader />
      </Modal>
    );
  return null;
};
