//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { DropdownProps, Input, InputOnChangeData, Grid } from "semantic-ui-react";
import { CountryCode, getCountryCallingCode, parsePhoneNumberFromString, isSupportedCountry } from "libphonenumber-js";
import { getData } from "country-list";
import { InlineDropdown } from "./_style";

interface IPhoneNumberProps {
  name: string;
  value: string;
  onChange: (e: any, args: InputOnChangeData) => void;
}

function extractCountry(number: string): string {
  const pn = parsePhoneNumberFromString(number);
  return pn ? (pn.country as string) : "";
}

function extractCountryCallingCode(number: string): string {
  const pn = parsePhoneNumberFromString(number);
  return pn ? (pn.countryCallingCode as string) : "";
}

function stripCountryCode(number: string): string {
  const pn = parsePhoneNumberFromString(number);
  return pn ? (pn.nationalNumber as string) : "";
}

interface IPhoneNumberState {
  country: string;
  countryCallingCode: string;
  nationalNumber: string;
}

const countryOptions = getData()
  .filter((entry) => isSupportedCountry(entry.code))
  .map((entry) => ({
    key: entry.code,
    text: `(+${getCountryCallingCode(entry.code as CountryCode)}) ${entry.name}`,
    value: entry.code,
    flag: entry.code.toLowerCase(),
  }));

export class PhoneNumber extends Component<IPhoneNumberProps, IPhoneNumberState> {
  state = {
    country: extractCountry(this.props.value),
    countryCallingCode: extractCountryCallingCode(this.props.value),
    nationalNumber: stripCountryCode(this.props.value),
  } as IPhoneNumberState;

  formatNumber = () => {
    const pn = parsePhoneNumberFromString(`+${this.state.countryCallingCode}${this.state.nationalNumber}`);
    return pn ? pn.format("E.164") : "";
  };

  onCountryChange = (e: object, data: DropdownProps) => {
    const country = data.value as string;
    const countryCallingCode = getCountryCallingCode(country as CountryCode);
    this.setState(
      {
        country: country,
        countryCallingCode: countryCallingCode as string,
      },
      this.fireOnChange
    );
  };

  onNationalNumberChange = (event: React.SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) => {
    const nationalNumber = data.value;
    this.setState(
      {
        nationalNumber: nationalNumber,
      },
      this.fireOnChange
    );
  };

  fireOnChange = () => {
    this.props.onChange(this, { name: this.props.name, value: this.formatNumber() });
  };

  render() {
    return (
      <Grid>
        <Grid.Row columns={16}>
          <Grid.Column width={8}>
            <InlineDropdown
              selection
              fluid
              search
              placeholder="Select your country"
              value={this.state.country}
              options={countryOptions}
              onChange={this.onCountryChange}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Input value={this.state.nationalNumber} onChange={this.onNationalNumberChange} error={this.formatNumber() === ""} inline />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
