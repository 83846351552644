//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Version as ApiVersion } from "../api/data/v1/data";

export const formatVersion = (v: ApiVersion) => {
  if (v.is_end_of_life) {
    return `${v.version} (End Of Life)`;
  }
  return v.version;
};
