//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useState } from "react";
import { Deployment, Deployment_ModelSpec, Deployment_CertificateSpec } from "../../api/lib";
import apiClients from "../../api/apiclients";
import { ModelFree, ModelSingleServer } from "../../constants";
import { reportError } from "../../errors/reporting";

interface IUseCreateFirstDeployment {
  certificateId: string;
  projectId: string;
  onNewDeploymentCreated: (id: string) => void;
  termsAndConditionsId: string;
  regionId: string;
  isPerpetualFreeTrialAvailable: boolean;
}
const useCreateFirstDeployment = ({
  certificateId,
  projectId,
  onNewDeploymentCreated,
  termsAndConditionsId,
  regionId,
  isPerpetualFreeTrialAvailable,
}: IUseCreateFirstDeployment) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const createFirstDeployment = async () => {
    try {
      setProcessing(true);
      setErrorMessage(undefined);

      if (!regionId) throw new Error("Please select Region");
      const modelString = !isPerpetualFreeTrialAvailable ? ModelSingleServer : ModelFree;
      const defaultVersion = await apiClients.dataClient.GetDefaultVersion();
      const nodeSizes = await apiClients.dataClient.ListNodeSizes({
        project_id: projectId,
        region_id: regionId,
        model: modelString,
      });

      const defaultNodeSize = ((nodeSizes || {}).items || [])[0];
      const disk_sizes = (defaultNodeSize || {}).disk_sizes;
      let diskSize = (defaultNodeSize || {}).max_disk_size;
      if (disk_sizes && disk_sizes.length > 0) {
        diskSize = disk_sizes[0];
      }

      const certs = {
        ca_certificate_id: certificateId,
      } as Deployment_CertificateSpec;

      const model = {
        model: modelString,
        node_count: 1,
        node_size_id: (defaultNodeSize || {}).id,
        node_disk_size: diskSize,
      } as Deployment_ModelSpec;

      const deployment = {
        project_id: projectId,
        name: "My first deployment",
        description: "",
        region_id: regionId,
        version: defaultVersion.version,
        certificates: certs,
        model: model,
        is_platform_authentication_enabled: true,
        drop_vst_support: true,
      } as Deployment;

      if (!!termsAndConditionsId) {
        deployment.accepted_terms_and_conditions_id = termsAndConditionsId;
      }

      const createdDeployment = await apiClients.dataClient.CreateDeployment(deployment);

      onNewDeploymentCreated(createdDeployment.id || "");
    } catch (e) {
      setErrorMessage(`Deployment creation failed: ${e.message}`);
      reportError(e);
    }
    setProcessing(false);
  };

  return {
    processing,
    errorMessage,
    createFirstDeployment,
  };
};

export default useCreateFirstDeployment;
