//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { IPrivateNetworkSummary, REGIONS } from "./types";
import AWSPrivateNetworkSummaryView from "./aws/AWSSummaryView";
import AKSPrivateNetworkSummaryView from "./AKSSummaryView";
import GCPSummaryView from "./gcp/GCPSummaryView";

const PrivateNetworkSummaryView = ({
  privateNetworkDetail,
  provider,
  onSubscriptionIDChange,
  onAlternateDNSNamesChange,
  onSupportClick,
  onAWSPrincipalsChange,
  onProjectNamesChange,
}: IPrivateNetworkSummary) => {
  return (
    <>
      {provider === REGIONS.aws && (
        <AWSPrivateNetworkSummaryView
          privateNetworkDetail={privateNetworkDetail}
          onAlternateDNSNamesChange={onAlternateDNSNamesChange}
          onAWSPrincipalsChange={onAWSPrincipalsChange}
          onSupportClick={onSupportClick}
        />
      )}
      {provider === REGIONS.aks && (
        <AKSPrivateNetworkSummaryView
          privateNetworkDetail={privateNetworkDetail}
          onSubscriptionIDChange={onSubscriptionIDChange}
          onAlternateDNSNamesChange={(dnsNames: string[]) => {
            return onAlternateDNSNamesChange({ alternateDNSNames: dnsNames });
          }}
          onSupportClick={onSupportClick}
        />
      )}
      {provider === REGIONS.gcp && (
        <GCPSummaryView
          privateNetworkDetail={privateNetworkDetail}
          onAlternateDNSNamesChange={onAlternateDNSNamesChange}
          onSupportClick={onSupportClick}
          onProjectNamesChange={onProjectNamesChange}
        />
      )}
    </>
  );
};

export default PrivateNetworkSummaryView;
