import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { ContentActionButtonNew, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import GroupList from "../group/GroupList";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const OrganizationGroups = () => {
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const is_org_deleted = !!selectedOrganization.is_deleted;
  const has_group_create = checkOrgPermission("iam.group.create");
  const history = useHistory();
  const onGroupCreate = () => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_groups_createWithId(selectedOrganization.id));
  };
  const onGroupSelected = (id: string) => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_group_detailsWithId(selectedOrganization.id, id));
  };
  const { subscribeUrl, refreshNow, loading, hasPermissionByUrl, unsubscribe } = useWithRefresh();
  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Groups"
          help={
            <div>
              <p className="para">Groups (of members) make it easier to organise your access policies.</p>
            </div>
          }
        />
        <SectionButtons>{has_group_create && !is_org_deleted && <ContentActionButtonNew primary content="New group" onClick={onGroupCreate} />}</SectionButtons>
      </SectionHead>
      <SectionContent>
        <GroupList
          topMenuInfo={topMenuInfo}
          loading={loading}
          history={history}
          onGroupSelected={onGroupSelected}
          organization={selectedOrganization}
          refreshNow={refreshNow}
          subscribeUrl={subscribeUrl}
          unsubscribe={unsubscribe}
          hasPermissionByUrl={hasPermissionByUrl}
        />
      </SectionContent>
    </Section>
  );
};
