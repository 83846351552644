//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Message, Modal, Segment } from "semantic-ui-react";
import { FormActionButtonCancel } from "../../../ui/_buttons";
import { ContentKeyValuePairs, ContentKeyValuePair, ContentKV_Key, ContentKV_Value } from "../../../ui/_style";
import { IGCPConnectionsModalViewArgs, IGCPConnectionsViewArgs } from "../types";

const GCPPrivateEndpointConnections = ({ connections }: IGCPConnectionsViewArgs) => {
  if (!connections.length)
    return (
      <Segment data-testid="noConnection">
        <Icon name="warning sign" /> No connections Established
      </Segment>
    );
  return (
    <>
      <Message data-testid="connectionInfo">Below you will find detailed information of all the connections made from Private Endpoint(s).</Message>

      {connections.map((connection) => (
        <Segment data-testid="connectionCard" key={connection.id}>
          <ContentKeyValuePairs>
            <ContentKeyValuePair>
              <ContentKV_Key>Name {connection.state === "failed" && <Icon name="warning circle" color="red" data-testid={connection.id} />}</ContentKV_Key>
              <ContentKV_Value>{connection.name}</ContentKV_Value>
            </ContentKeyValuePair>
          </ContentKeyValuePairs>
          <ContentKeyValuePairs>
            <ContentKeyValuePair>
              <ContentKV_Key>ID</ContentKV_Key>
              <ContentKV_Value>{connection.id}</ContentKV_Value>
            </ContentKeyValuePair>
          </ContentKeyValuePairs>
          <ContentKeyValuePairs>
            <ContentKeyValuePair>
              <ContentKV_Key>State</ContentKV_Key>
              <ContentKV_Value>{connection.state}</ContentKV_Value>
            </ContentKeyValuePair>
          </ContentKeyValuePairs>
        </Segment>
      ))}
    </>
  );
};

export default GCPPrivateEndpointConnections;

const GCPPrivateEndpointConnectionsModalView = ({ onClose, connections }: IGCPConnectionsModalViewArgs) => {
  return (
    <Modal open onClose={onClose} data-testid="privateNetworkGCPConnectionsModal">
      <Modal.Header>Active connections</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <GCPPrivateEndpointConnections connections={connections} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="privateNetworkGCPConnectionsModalcloseAction" />
      </Modal.Actions>
    </Modal>
  );
};

export { GCPPrivateEndpointConnectionsModalView };
