//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import { useDataloaderStore } from "../../DataloaderStore";
import { isFileAssociatedInGraph } from "../../utils";
import { getCollectionNameErrors, getGraphNameErrors } from "../../utils/namesValidations.utils";

const useStartImportConditions = () => {
  const { name, files, nodes, nodeMappings, edges, edgeMappings, migrationJob, currentDatabase } = useDataloaderStore().getDeploymentDataloaderState();
  const { db, collections, graphs } = currentDatabase;
  const collectionsNames = collections.map((collection) => collection.name);

  const nodesLabels = _.map(nodes, "label");
  const edgesLabels = _.map(edges, "label");

  const hasDatabaseNotAvailable = db == undefined;

  const isUnnamedGraph = name === "";
  const isGraphNameAlreadyInUse = name !== "" && graphs.some((graph) => graph === name);
  const isGraphNameValid = name !== "" && !!getGraphNameErrors(name).length;

  const hasNoGraphFiles = files.length === 0;
  const hasFilesWithoutSize = !!files.length && files.some((file) => !file.file.size);
  const hasFilesWithErrors = !!files.length && files.some(({ errors = [] }) => errors.length > 0);
  const hasNoGraphNodes = nodes.length === 0;
  const hasNoGraphEdges = edges.length === 0;
  const hasNodeWithoutName = nodesLabels.some((label) => label === "" || !label);
  const hasEdgeWithoutName = edgesLabels.some((label) => label === "" || !label);
  const hasNotAllNodesLinkedToFile = !nodes.every((node) => nodeMappings.hasOwnProperty(node.id));
  const hasNotAllEdgesLinkedToFile = !edges.every((edge) => edgeMappings.hasOwnProperty(edge.id));
  const hasUnassociatesFiles = !files.every((file) => isFileAssociatedInGraph(file.id));
  const hasMissingNodePrimaryKeys = !Object.keys(nodeMappings).every((nodeMapping) => !!nodeMappings[nodeMapping].primaryKey);
  const hasMissingEdgePrimaryKeys = !Object.keys(edgeMappings).every(
    (edgeMapping) => !!edgeMappings[edgeMapping].targetNodePrimaryKey && !!edgeMappings[edgeMapping].sourceNodePrimaryKey
  );

  const hasDuplicatedNodesLabels = _.uniq(nodesLabels).length !== nodes.length;
  const hasDuplicatedEdgesLabels = _.uniq(edgesLabels).length !== edges.length;
  const hasInvalidNodeLabels = nodesLabels.some((nodeLabel = "") => nodeLabel !== "" && !!getCollectionNameErrors(nodeLabel).length);
  const hasInvalidEdgeLabels = edgesLabels.some((edgesLabel = "") => edgesLabel !== "" && !!getCollectionNameErrors(edgesLabel).length);

  const hasDuplicatedLabelBetweenNodesAndEdges = _.intersection(nodesLabels, edgesLabels).length > 0;
  const hasDuplicatedLabelBetweenNodesAndCollections = _.intersection(collectionsNames, nodesLabels).length > 0;
  const hasDuplicatedLabelBetweenEdgesAndCollections = _.intersection(collectionsNames, edgesLabels).length > 0;

  const hasMigrationInProgress = migrationJob.status !== "not_started" && migrationJob.status !== "graph_modeler";

  type Condition = { condition: boolean; message: string };
  const conditions: Condition[] = [
    {
      condition: hasDatabaseNotAvailable,
      message: "Database is not available for import",
    },
    {
      condition: isUnnamedGraph,
      message: "You must provide a name for the graph",
    },
    {
      condition: isGraphNameAlreadyInUse,
      message: "The graph name provided is already in use",
    },
    {
      condition: isGraphNameValid,
      message: "The graph name provided is not valid",
    },
    {
      condition: hasNoGraphFiles,
      message: "You must upload at least one file",
    },
    {
      condition: hasFilesWithoutSize,
      message: "One or more files have no size",
    },
    {
      condition: hasFilesWithErrors,
      message: "One or more files have validation errors",
    },
    {
      condition: hasNoGraphNodes,
      message: "You must create at least one node",
    },
    {
      condition: hasNoGraphEdges,
      message: "You must create at least one edge",
    },
    {
      condition: hasNotAllNodesLinkedToFile,
      message: "All nodes must be linked to a file",
    },
    {
      condition: hasNodeWithoutName,
      message: "A label must be provided to all nodes",
    },
    {
      condition: hasEdgeWithoutName,
      message: "A label must be provided to all edges",
    },
    {
      condition: hasInvalidNodeLabels,
      message: "Every node label must be valid",
    },
    {
      condition: hasInvalidEdgeLabels,
      message: "Every edge label must be valid",
    },
    {
      condition: hasDuplicatedEdgesLabels,
      message: "Each edge label must be unique",
    },
    {
      condition: hasDuplicatedNodesLabels,
      message: "Each node label must be unique",
    },
    {
      condition: hasDuplicatedLabelBetweenNodesAndEdges,
      message: "One node and one edge cannot have the same label",
    },
    {
      condition: hasDuplicatedLabelBetweenNodesAndCollections,
      message: "A node cannot reuse an existing collection label",
    },
    {
      condition: hasDuplicatedLabelBetweenEdgesAndCollections,
      message: "An edge cannot reuse an existing collection label",
    },
    {
      condition: hasNotAllEdgesLinkedToFile,
      message: "All edges must be linked to  a file",
    },
    {
      condition: hasUnassociatesFiles,
      message: "Every file must be related to at least one node or edge",
    },
    {
      condition: hasMissingNodePrimaryKeys,
      message: "Every node must have a primary identifier",
    },
    {
      condition: hasMissingEdgePrimaryKeys,
      message: "Every edge must have origin and destination primary keys",
    },
    {
      condition: hasMigrationInProgress,
      message: "It is not possible to initiate an import while the previous import is in progress",
    },
  ];

  const fulfilledConditions = conditions.filter(({ condition }) => condition).map(({ message }) => message);

  const isSaveDisabled = conditions.some(({ condition }) => condition);

  return {
    fulfilledConditions,
    isSaveDisabled,
  };
};

export { useStartImportConditions };
