import React from "react";
import { OrganizationList } from "../../api/lib";
import { Routes } from "../../routes";
import { SidebarHeading } from "./SidebarHeading";
import { SidebarLink } from "./SidebarLink";
import { SidebarRightWrap } from "./SidebarRightWrap";
import { useDashboardContext } from "../DashboardContextProvider";
import { EnterpriseTierID } from "../../constants";
import { OrganizationSelector } from "../OrganizationSelector";
import { useCheckPermissionByUrl } from "./useCheckPermissionByUrl";
import { Box } from "../../ui/_box";

export const DashboardSidebar = ({
  onClickSupportRequest,
  organizations,
  onOpenInternalDashboard,
}: {
  onClickSupportRequest: () => void;
  organizations: OrganizationList | undefined;
  onOpenInternalDashboard: () => void;
}) => {
  const { selectedOrganization, isFreeTier } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const hasProjectsPermission = checkOrgPermission("resourcemanager.project.list");
  const showBillingLink = checkOrgPermission("billing.config.set");
  const orgId = selectedOrganization?.id;
  return (
    <SidebarRightWrap onClickSupportRequest={onClickSupportRequest} organizationsList={organizations} onOpenInternalDashboard={onOpenInternalDashboard}>
      <OrganizationSelector />
      <Box display="flex" direction="column" overflow="auto">
        <SidebarHeading>Dashboard</SidebarHeading>
        <SidebarLink icon="database" to={{ pathname: Routes.dashboard }}>
          Deployments
        </SidebarLink>
        <SidebarLink show={hasProjectsPermission} to={{ pathname: Routes.dashboard_projects }} icon="folder open">
          Projects
        </SidebarLink>
        {selectedOrganization?.tier?.id !== EnterpriseTierID && (
          <SidebarLink to={{ pathname: Routes.dashboard_pricing }} icon="dollar sign">
            Pricing
          </SidebarLink>
        )}
        {isFreeTier && (
          <SidebarLink
            show={isFreeTier ? showBillingLink : true}
            to={{ pathname: Routes.dashboard_organization_billingWithId(orgId || "") }}
            icon="credit card outline"
          >
            Unlock All Features
          </SidebarLink>
        )}
      </Box>
    </SidebarRightWrap>
  );
};
