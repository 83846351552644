//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchLimits = ({ regionId }: { regionId: string }) => {
  const { project } = useProjectDetailsContext();
  const fetchLimits = async () => {
    const req = { project_id: project.id, region_id: regionId };
    const limits = await apiClients.dataClient.GetServersSpecLimits(req);
    return limits;
  };
  return useQuery([project.id, regionId, "limits"], fetchLimits, {
    enabled: !!project.id && !!regionId,
  });
};
