//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import styled from "@emotion/styled";
import React from "react";
import { numberFormat } from "humanize";
import { Icon, Popup, Modal, Table, SemanticICONS } from "semantic-ui-react";
import {
  AuditLog_Destination as ApiAuditLogDestination,
  AuditLog_DestinationStatus as ApiAuditLogDestinationStatus,
  AuditLog_DestinationCounters as ApiAuditLogDestinationCounters,
} from "../../api/lib";
import { FormActionButtonCancel, IconWithTooltip, TextLink } from "../../ui/lib";
import { humanizeFileSize } from "../../util/FileSize";

const StyledContainer = styled("span")``;

const StyledLink = styled(TextLink)``;

interface IDestinationViewArgs {
  destination: ApiAuditLogDestination;
}

export const DestinationView = ({ ...args }: IDestinationViewArgs) => {
  const excluded_topics = args.destination.excluded_topics || [];
  const suffix = _.isEmpty(excluded_topics) ? "All topics" : `All but ${excluded_topics.length} ${excluded_topics.length > 1 ? "topics" : "topic"}`;
  if (args.destination.type == "cloud") {
    return <DestinationDetailsView {...args} include_http={false} icon="cloud" tooltip="Upload to the cloud" suffix={suffix} />;
  }
  const http_post = args.destination.http_post || {};
  if (args.destination.type == "https-post") {
    return <DestinationDetailsView {...args} include_http icon="server" tooltip={`POST to ${http_post.url}`} suffix={suffix} />;
  }
  return <span>?</span>;
};

interface IDestinationDetailsViewArgs {
  icon: SemanticICONS;
  tooltip: string;
  suffix: string;
  destination: ApiAuditLogDestination;
  include_http: boolean;
}

export const DestinationDetailsView = ({ ...args }: IDestinationDetailsViewArgs) => {
  const [open, setOpen] = React.useState(false);
  const statuses = args.destination.Statuses || [];
  const hasErrors = _.some(statuses, (x) => !!x.has_errors);
  const color = hasErrors ? "red" : "grey";
  const hasStatuses = !_.isEmpty(statuses);

  return (
    <StyledContainer>
      {hasStatuses && (
        <StyledLink onClick={() => setOpen(true)}>
          <IconWithTooltip name={args.icon} tooltip={args.tooltip} color={color} /> {args.suffix}
        </StyledLink>
      )}
      {!hasStatuses && (
        <span>
          <IconWithTooltip name={args.icon} tooltip={args.tooltip} color={color} /> {args.suffix}
        </span>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Destination status</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Deployment / ArangoGraph Insights Platform</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Updated at</Table.HeaderCell>
              <Table.HeaderCell>Statistics today</Table.HeaderCell>
              <Table.HeaderCell>Statistics yesterday</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {statuses.map((x, i) => (
                <DestinationStatusView {...args} key={`stat${i}`} status={x} />
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <FormActionButtonCancel onClick={() => setOpen(false)} title="Close" />
        </Modal.Actions>
      </Modal>
    </StyledContainer>
  );
};

interface IDestinationStatusViewArgs {
  status: ApiAuditLogDestinationStatus;
  include_http: boolean;
}

export const DestinationStatusView = ({ ...args }: IDestinationStatusViewArgs) => {
  const status = args.status;
  const hasError = !!status.has_errors;

  return (
    <Table.Row>
      <Table.Cell>{status.deployment_name || status.deployment_id || "ArangoGraph Insights Platform"}</Table.Cell>
      <Table.Cell>
        {hasError && <Popup trigger={<Icon name="warning sign" color="red" />} content={<span>{status.error_details || "-"}</span>} />}
        {!hasError && <Icon name="check" />}
      </Table.Cell>
      <Table.Cell>{status.updated_at ? moment(status.updated_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell>
        <DestinationCountersView {...args} counters={status.counters_since_midnight || {}} />
      </Table.Cell>
      <Table.Cell>
        <DestinationCountersView {...args} counters={status.counters_yesterday || {}} />
      </Table.Cell>
    </Table.Row>
  );
};

interface IDestinationCounterViewArgs {
  counters: ApiAuditLogDestinationCounters;
  include_http: boolean;
}

export const DestinationCountersView = ({ ...args }: IDestinationCounterViewArgs) => {
  const counters = args.counters;

  return (
    <Table basic="very" compact="very" size="small">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Total events:</Table.Cell>
          <Table.Cell>{numberFormat(counters.events || 0, 0)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Excluded events:</Table.Cell>
          <Table.Cell>{numberFormat(counters.events_excluded || 0, 0)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Undeliverable events:</Table.Cell>
          <Table.Cell>{numberFormat(counters.events_undeliverable || 0, 0)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bytes processed:</Table.Cell>
          <Table.Cell>{counters.bytes_succeeded ? humanizeFileSize(counters.bytes_succeeded) : "0"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bytes failed:</Table.Cell>
          <Table.Cell>{counters.bytes_failed ? humanizeFileSize(counters.bytes_failed) : "0"}</Table.Cell>
        </Table.Row>
        {args.include_http && (
          <Table.Row>
            <Table.Cell>HTTP posts succeeded:</Table.Cell>
            <Table.Cell>{numberFormat(counters.https_posts_succeeded || 0, 0)}</Table.Cell>
          </Table.Row>
        )}
        {args.include_http && (
          <Table.Row>
            <Table.Cell>HTTP posts failed:</Table.Cell>
            <Table.Cell>{numberFormat(counters.https_posts_failed || 0, 0)}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
