//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Header, Icon, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { AppLinkButton } from "../../ui/_link";
import {
  PRIVATE_NETWORK_OVERVIEW_URL,
  PRIVATE_NETWORK_SERVICE_PROVIDER_OVERVIEW,
  AZURE_PRIVATE_LINK_URL,
  PRIVATE_LINK_SERVICE_VIDEO,
} from "./privateNetworkURLs";

interface IPrivateNetworkHelpView {
  onClose: () => void;
  onSupportClick: (() => void) | undefined;
}

const AKSPrivateNetworkHelpView = ({ onClose, onSupportClick }: IPrivateNetworkHelpView) => {
  return (
    <Modal open data-testid="privateNetworkHelpModal">
      <Modal.Header>Here's what you can refer</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message>
            This deployment has been configured with a Private endpoint. That means that all traffic to and from your deployment go through a private endpoint
            "tunnel" inside Microsoft Azure.
          </Message>

          <Header as="h3">To understand more about Private endpoints, refer the following links:</Header>

          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="Private Endpoints Overview" href={PRIVATE_NETWORK_OVERVIEW_URL} target="_blank" />
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="Private Link Service Provider Overview" href={PRIVATE_NETWORK_SERVICE_PROVIDER_OVERVIEW} target="_blank" />
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="Introduction to Azure Private link" href={AZURE_PRIVATE_LINK_URL} target="_blank" />
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="How to create a Private Link Service" href={PRIVATE_LINK_SERVICE_VIDEO} target="_blank" />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} />
        <FormActionButtonSave
          primary
          icon="help"
          title="Request Help"
          onClick={() => {
            onClose();
            onSupportClick && onSupportClick();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export { AKSPrivateNetworkHelpView };
