//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { TopMenuButtonInfo } from "./TopMenuInfo";
import * as H from "history";

// Interface describing the internal represeantation of the history state
interface IHistoryState {
  title: string;
  url?: string;
}

// HistoryHelper allows you to push history, with optional a title (and optional the url) from the page you are coming from.
// This enables us to get a customized 'back' functionality and to check if the previous page was a specific URL.
export class HistoryHelper {
  // Get the back button info, with an alternative 'back' text if available.
  static getBackButtonInfo = (history: H.History): TopMenuButtonInfo => {
    const state = history.location.state as IHistoryState;
    return new TopMenuButtonInfo(state && state.title ? state.title : "back", history.goBack);
  };

  // Push history with an optional title and url
  static push = (history: H.History, path: string, title?: string, url?: string): void => {
    const state = title ? ({ title: title } as IHistoryState) : undefined;
    if (state && url) {
      state.url = url;
    }
    history.push(path, state);
  };

  // Check if the provided url is found in the history state (previous page)
  static isFrom = (history: H.History, url: string): boolean => {
    const state = history.location.state as IHistoryState;
    return state && state.url == url;
  };
}
