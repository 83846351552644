//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React, { Component } from "react";
import api from "../api/api";
import { reportError } from "../errors/reporting";
import { Grid } from "semantic-ui-react";
import _ from "lodash";
import { IMarker, providerColors } from "./_regionHelpers";

interface IRegionGridViewArgs {
  markers?: IMarker[];
}

const RegionGridView = ({ ...args }: IRegionGridViewArgs) => {
  const markers = args.markers || [];
  const allRegions = _.filter(_.flatten(markers.map((m) => m.regions)), (r) => !r.planned);
  const hasRegions = !_.isEmpty(allRegions);
  const providers = _.orderBy(
    _.uniqBy(
      allRegions.map((r) => {
        return { id: r.provider_id, name: r.provider_name };
      }),
      (p) => p.id
    ),
    "name"
  );
  return (
    <Grid columns="16" stackable>
      {hasRegions && (
        <Grid.Row columns="3">
          {providers.map((p, i) => {
            const providerRegions = _.orderBy(
              _.filter(allRegions, (r) => r.provider_id == p.id),
              "name"
            );
            return (
              <Grid.Column key={p.id}>
                <h3 className="heading-3">
                  <span style={{ fontSize: "170%", color: providerColors(p.id) }}>&bull;</span>
                  &nbsp;
                  {p.name}
                </h3>
                {providerRegions.map((r, i) => (
                  <div>{r.name}</div>
                ))}
              </Grid.Column>
            );
          })}
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width="16">
          <p className="para">More regions are added based on popular demand.</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface IRegionGridProps {}

interface IRegionGridState {
  markers?: IMarker[];
}

// RegionGrid shows the available regions in a grid by provider.
export class RegionGrid extends Component<IRegionGridProps, IRegionGridState> {
  state = {
    markers: undefined,
  } as IRegionGridState;

  reloadRegionMarkers = async () => {
    try {
      const resp = await fetch("/regions-markers");
      const samples = await api.decodeResults(resp);
      this.setState({ markers: samples as IMarker[] });
    } catch (e) {
      console.log(e);
      reportError(e);
      // Try again in 5sec
      setInterval(this.reloadRegionMarkers, 5000);
    }
  };

  componentDidMount() {
    this.reloadRegionMarkers();
  }

  render() {
    return <RegionGridView {...this.state} />;
  }
}
