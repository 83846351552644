//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Icon, Loader, Popup, Table } from "semantic-ui-react";
import { CACertificate as ApiCACertificate, CACertificateList as ApiCACertificateList, IDOptions as ApiIDOptions, Project as ApiProject } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import {
  Confirm,
  ConfirmInfo,
  humanizeDuration,
  ListActionDelete,
  ListActionEdit,
  ListActionSetDefault,
  ListActionView,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  TextLink,
  Locked,
} from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CACertificateDetailsModal from "./CACertificateDetailsModal";
import UpdateCACertificateModal from "./UpdateCACertificateModal";
import apiClients from "../../api/apiclients";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing a ca certificate
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Lifetime</Table.HeaderCell>
      <Table.HeaderCell>Expires</Table.HeaderCell>
      <Table.HeaderCell>Default</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a ca certificate
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiCACertificate;
  onClickView: () => void;
  onClickEdit: () => void;
  onClickSetDefault: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const expiresAt = args.item.expires_at ? moment(args.item.expires_at) : undefined;
  const isExpired = args.item.is_expired;
  const expiresSoon = args.item.will_expire_soon;
  const locked = !!args.item.locked;
  return (
    <Table.Row>
      <Table.Cell>
        <TextLink
          label={args.item.name}
          onClick={args.onClickView}
          disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.get")}
        />
        {locked && <Locked />}
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{args.item.use_well_known_certificate ? "Well-known & Self-signed" : "Self-signed only"}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.lifetime ? humanizeDuration(args.item.lifetime) : "?"}</Table.Cell>
      <Table.Cell>
        {isExpired && <Popup content="Certificate is expired" trigger={<Icon name="warning sign" color="red" />} />}
        {expiresSoon && <Popup content="Certificate will expire soon" trigger={<Icon name="warning" color="yellow" />} />}
        {expiresAt ? expiresAt.fromNow() : "?"}
      </Table.Cell>
      <Table.Cell>{args.item.is_default ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.get") && (
            <ListActionView onClick={args.onClickView} />
          )}
          {args.hasPermissionByUrl &&
            args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.update") &&
            !args.item.is_deleted && <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />}
          {args.hasPermissionByUrl &&
            args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.set-default") &&
            !args.item.is_default && <ListActionSetDefault disabled={!args.active} onClick={args.onClickSetDefault} />}
          {args.hasPermissionByUrl &&
            args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.delete") &&
            !args.item.is_deleted && <ListActionDelete disabled={!args.active || locked} onClick={args.onClickDelete} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the ca certificate list
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  items: ApiCACertificate[];
  loading: boolean;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickSetDefault={() => args.onClickSetDefault(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No ca certificates inside this project</div>;

// Interface describing the ca certificate list view arguments
export interface ICACertificateListViewArgs {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  loading: boolean;
  project: ApiProject;
  caCertificates?: ApiCACertificateList;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const CACertificateListView = ({ ...args }: ICACertificateListViewArgs) => {
  if (!args.caCertificates) {
    return <Loading />;
  }
  const items = args.caCertificates.items || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={_.orderBy(items, "name")} loading={args.loading} />;
};

// Interface decribing the properties of the ca certificate list component
interface ICACertificateListProps extends IWithRefreshProps {
  project: ApiProject;
  cacertificates?: ApiCACertificateList;
  onError: (message?: string) => void;
  onCACertificateDeleted: (id: string) => void;
  onCACertificateDefaultChanged: (id: string) => void;
  onRefreshList: () => void;
}

// Interface decribing the state of the ca certificate list component
interface ICACertificateListState {
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  editId?: string;
  viewId?: string;
}

// The component to show the ca certificates inside a project as a list.
class CACertificateList extends Component<ICACertificateListProps, ICACertificateListState> {
  state = {
    processing: false,
    confirmInfo: undefined,
    viewId: undefined,
  } as ICACertificateListState;

  onClickView = (id: string) => {
    this.setState({ viewId: id, editId: undefined });
  };
  onCloseView = () => {
    this.setState({ viewId: undefined });
  };

  onClickEdit = (id: string) => {
    this.setState({ editId: id, viewId: undefined });
  };
  onCloseEdit = () => {
    this.setState({ editId: undefined });
  };

  getCACertificateName = (id: string) => {
    const caCertificates = this.props.cacertificates;
    if (caCertificates && caCertificates.items) {
      const caCertificate = caCertificates.items.find((c) => c.id == id);
      if (caCertificate) {
        return caCertificate.name;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const caCertificateName = this.getCACertificateName(id);
    const confirmInfo = {
      header: "Delete Certificate",
      content: `Are you sure you want to delete certificate '${caCertificateName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.props.onError(undefined);
      this.setState({ processing: true, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.cryptoClient.DeleteCACertificate(idOptions);
      this.props.onCACertificateDeleted(id);
    } catch (e) {
      this.props.onError(`Certificate deletion failed: ${e}`);
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onClickSetDefault = async (id: string) => {
    const caCertificates = this.props.cacertificates;
    if (caCertificates && caCertificates.items) {
      const caCertificate = caCertificates.items.find((c) => c.id == id);
      if (caCertificate) {
        try {
          this.props.onError(undefined);
          this.setState({ processing: true, confirmInfo: undefined });
          await apiClients.cryptoClient.SetDefaultCACertificate(caCertificate);
        } catch (e) {
          this.props.onError(`Marking certificate as default failed: ${e}`);
          reportError(e);
        }
        this.setState({ processing: false });
      }
    }
  };

  onCACertificateUpdated = () => {
    this.onCloseEdit();
    this.props.onRefreshList();
  };

  render() {
    const showEdit = !!this.state.editId;
    const showView = !!this.state.viewId;

    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting certificate, please wait..." />
        {showEdit && (
          <UpdateCACertificateModal
            {...this.props}
            caCertificateId={this.state.editId || ""}
            onClose={this.onCloseEdit}
            onCACertificateUpdated={this.onCACertificateUpdated}
          />
        )}
        {showView && (
          <CACertificateDetailsModal
            {...this.props}
            caCertificateId={this.state.viewId || ""}
            onEdit={() => this.onClickEdit(this.state.viewId || "")}
            onClose={this.onCloseView}
          />
        )}
        <CACertificateListView
          {...this.props}
          {...this.state}
          caCertificates={this.props.cacertificates}
          active={!this.state.processing}
          onClickView={this.onClickView}
          onClickEdit={this.onClickEdit}
          onClickSetDefault={this.onClickSetDefault}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default withRefresh()(CACertificateList);
