//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ReactNode } from "react";
import { useContext } from "react";
import { GraphContext } from "./GraphContext";
import { GraphProvider } from "./types";
import { useGraph } from "./hooks/useGraph";

export const GraphContextProvider = ({ children, ...rest }: GraphProvider & { children: ReactNode }) => {
  return (
    <GraphContext.Provider
      value={{
        ...useGraph(rest),
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};

export const useGraphState = () => {
  const { showEdgeManipulator, showNodeManipulator, canvasZoom, position, hideAllMenu } = useContext(GraphContext);
  return { showEdgeManipulator, showNodeManipulator, canvasZoom, position, hideAllMenu };
};

export const useGraphNodeHandlers = () => {
  const { addNewNode, addConnectedNode, deleteNode, getNode, updateNode, editNode, getNodeDataset, startNodeConnectionMode, stopNodeConnectionMode } =
    useContext(GraphContext);
  return { addNewNode, addConnectedNode, deleteNode, getNode, updateNode, editNode, getNodeDataset, startNodeConnectionMode, stopNodeConnectionMode };
};

export const useGraphEdgeHandlers = () => {
  const { editEdge, getEdge, deleteEdge, updateEdge, allowEdgeDefinitionChange, getEdgeDataset } = useContext(GraphContext);
  return { editEdge, getEdge, deleteEdge, updateEdge, allowEdgeDefinitionChange, getEdgeDataset };
};
