//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import { Notebook } from "../../../api/notebook/v1/notebook";
import { Confirm, ConfirmInfo, ErrorMessage, ListAction } from "../../../ui/lib";
import { DataVolumeView } from "../DataVolumeView";
import { MemoryUsageView } from "../MemoryUsageView";
import { CPUUsageView } from "../CPUUsageView";
import EditNotebookView from "./EditNotebookView";
import apiClients from "../../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../../api/lib";
import moment from "moment";
import { AppLinkButton } from "../../../ui/_link";
import { useNotebookPermissions } from "../useNotebookPermissions";
import { useFetchNotebooksList } from "./useFetchNotebooksList";

const PHASES = {
  initialising: "Initialising",
  running: "Running",
  hibernating: "Hibernating",
  hibernated: "Hibernated",
  error: "Error",
};

const NotebookTableView = () => {
  const { canDeleteNotebook, canUpdateNotebook, canPauseNotebook, canResumeNotebook } = useNotebookPermissions();
  const { data, refetch: refetchNotebooksList } = useFetchNotebooksList();
  const { notebooks = [] } = data || {};
  const [error, setError] = useState(undefined);
  const [confirmation, setConfirmation] = useState<ConfirmInfo | undefined>(undefined);
  const [pausingNotebook, updatePausingState] = useState("");
  const [resumingNotebook, updateResumingState] = useState("");
  const [deletingNotebook, updateDeletingState] = useState("");

  const deleteNotebook = async (id: string) => {
    updateDeletingState(id);
    try {
      const req: ApiIDOptions = {
        id,
      };
      await apiClients.notebookClient.DeleteNotebook(req);
      await refetchNotebooksList();
      setConfirmation(undefined);
    } catch (err) {
      setConfirmation(undefined);
      setError(err);
    } finally {
      updateDeletingState("");
    }
  };

  const pauseNotebook = async (id: string) => {
    updatePausingState(id);
    try {
      const req: ApiIDOptions = {
        id,
      };
      await apiClients.notebookClient.PauseNotebook(req);
      await refetchNotebooksList();
      setConfirmation(undefined);
    } catch (err) {
      setConfirmation(undefined);
      setError(err);
    } finally {
      updatePausingState("");
    }
  };

  const resumeNotebook = async (id: string) => {
    updateResumingState(id);
    try {
      const req: ApiIDOptions = {
        id,
      };
      await apiClients.notebookClient.ResumeNotebook(req);
      await refetchNotebooksList();
    } catch (err) {
      setError(err);
    } finally {
      updateResumingState("");
    }
  };

  const handleDelete = (notebook: Notebook) => {
    const confirmInfo: ConfirmInfo = {
      header: "Delete Notebook",
      content: (
        <>
          Are you sure you want to delete <b>{notebook.name || ""} </b>?
        </>
      ),
      warning: "Once deleted, the notebook cannot be retrieved.",
      invertPositiveNegative: true,
      onConfirm: () => {
        deleteNotebook(notebook.id as string);
      },
      onDenied: () => setConfirmation(undefined),
    };
    setConfirmation(confirmInfo);
  };

  const handlePause = (notebook: Notebook) => {
    const confirmInfo: ConfirmInfo = {
      header: "Pause Notebook",
      content: (
        <>
          Are you sure you want to pause <b>{notebook.name || ""} </b>?
        </>
      ),
      invertPositiveNegative: true,
      onConfirm: () => {
        pauseNotebook(notebook.id as string);
      },
      onDenied: () => setConfirmation(undefined),
    };
    setConfirmation(confirmInfo);
  };

  return (
    <>
      <Confirm confirmInfo={confirmation} loadingConfirmation={!!pausingNotebook || !!deletingNotebook} />
      <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />

      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Endpoint</Table.HeaderCell>
            <Table.HeaderCell>Paused</Table.HeaderCell>
            <Table.HeaderCell>Phase</Table.HeaderCell>
            <Table.HeaderCell>Memory</Table.HeaderCell>
            <Table.HeaderCell>CPU</Table.HeaderCell>
            <Table.HeaderCell>Disk Used</Table.HeaderCell>
            <Table.HeaderCell>ML Services</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notebooks.map((notebook) => {
            return (
              <Table.Row key={notebook.id}>
                <Table.Cell>
                  <Popup
                    hoverable
                    trigger={<span>{notebook.name}</span>}
                    content={
                      <div>
                        <p>Notebook id: {notebook.id}</p>
                        {!!notebook.description && <p>{notebook.description}</p>}
                      </div>
                    }
                  />
                </Table.Cell>
                <Table.Cell>{moment(notebook.created_at).fromNow()}</Table.Cell>
                <Table.Cell>
                  {notebook.status && notebook.status.endpoint_default ? <AppLinkButton href={notebook.status.endpoint_default} label="Open Notebook" /> : "-"}
                </Table.Cell>
                <Table.Cell>{notebook.is_paused ? moment(notebook.last_paused_at).fromNow() : "No"}</Table.Cell>
                <Table.Cell>
                  {(notebook.status || {}).phase === PHASES.error ? (
                    <Popup trigger={<Icon name="info circle" color="red" />} content={(notebook.status || {}).message} />
                  ) : (
                    <></>
                  )}
                  {(notebook.status || {}).phase || "-"}
                </Table.Cell>
                <Table.Cell>
                  <MemoryUsageView
                    limit={((notebook.status || {}).usage || {}).last_memory_limit || 0}
                    used={((notebook.status || {}).usage || {}).last_memory_usage || 0}
                  />
                </Table.Cell>
                <Table.Cell>
                  <CPUUsageView
                    limit={((notebook.status || {}).usage || {}).last_cpu_limit || 0}
                    used={((notebook.status || {}).usage || {}).last_cpu_usage || 0}
                  />
                </Table.Cell>

                <Table.Cell>
                  <DataVolumeView data_volume_info={((notebook.status || {}).usage || {}).data_volume_info} />
                </Table.Cell>

                <Table.Cell>{notebook.ml_enabled ? "Available" : "Not available"}</Table.Cell>

                <Table.Cell textAlign="right" collapsing>
                  <div className="table-action-buttons">
                    {canUpdateNotebook && <EditNotebookView notebookId={notebook.id || ""} />}
                    {canPauseNotebook && !notebook.is_paused && (
                      <ListAction
                        icon="pause"
                        tooltip="Pause notebook"
                        onClick={() => handlePause(notebook)}
                        loading={pausingNotebook === notebook.id}
                        disabled={
                          notebook.is_deleted || (notebook.status || {}).phase === PHASES.initialising || (notebook.status || {}).phase === PHASES.error
                        }
                      />
                    )}
                    {canResumeNotebook && notebook.is_paused && (
                      <ListAction
                        icon="play"
                        loading={resumingNotebook === notebook.id}
                        tooltip="Resume notebook"
                        onClick={() => resumeNotebook(notebook.id || "")}
                        disabled={
                          notebook.is_deleted || (notebook.status || {}).phase === PHASES.initialising || (notebook.status || {}).phase === PHASES.error
                        }
                      />
                    )}
                    {canDeleteNotebook && (
                      <ListAction
                        icon="trash"
                        loading={deletingNotebook === notebook.id}
                        tooltip="Delete notebook"
                        onClick={() => handleDelete(notebook)}
                        disabled={notebook.is_deleted}
                      />
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default NotebookTableView;
