//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Deployment as ApiDeployment, Deployment_Status as ApiDeployment_Status } from "../../api/data/v1/data";
import { StatusTextOKStyle, StatusTextNOKStyle, StyledLink } from "../../ui/lib";

// Interface describing a status
interface IDeploymentStatusView {
  deployment: ApiDeployment;
  status?: ApiDeployment_Status;
  icons: boolean;
}

export const DeploymentStatusView = ({ ...args }: IDeploymentStatusView) => {
  const isPrepaid = !isEmpty(args.deployment.prepaid_deployment_id);

  if (args.deployment.is_deleted) {
    return (
      <span>
        <StatusTextOKStyle>Deleting </StatusTextOKStyle>
        {args.icons && <Icon name="spinner" loading={true} />}
      </span>
    );
  }
  if (args.status) {
    if (!args.status.bootstrapped) {
      return (
        <span>
          <StatusTextOKStyle>Bootstrapping </StatusTextOKStyle>
          <Popup content={`Bootstrapping: ${args.status.description}`} trigger={<span>{args.icons ? <Icon name="spinner" loading={true} /> : null}</span>} />
        </span>
      );
    }
    if (args.status.description === "Hibernated") {
      return (
        <span>
          <StatusTextOKStyle>Hibernated </StatusTextOKStyle>
          {args.icons && <Icon name="pause" />}
        </span>
      );
    }
    if (args.deployment.is_paused) {
      return (
        <span>
          {isPrepaid ? (
            <Popup
              content="The deployment has been paused because your contract has ended. Please contact your account manager for further action."
              position="bottom center"
              trigger={
                <span>
                  <StyledLink className="text-link">
                    <StatusTextOKStyle>Paused </StatusTextOKStyle>
                  </StyledLink>
                  <Icon name="info circle" className="secondary-text" size="small" />
                </span>
              }
            />
          ) : (
            <StatusTextOKStyle>Paused </StatusTextOKStyle>
          )}
          {args.icons && <Icon name="pause" />}
        </span>
      );
    }
    if (args.status.upgrading) {
      return (
        <span>
          <StatusTextOKStyle>Upgrading </StatusTextOKStyle>
          {args.icons && <Icon name="hourglass half" loading={true} />}
        </span>
      );
    }
    if (args.status.ready) {
      return (
        <span>
          <StatusTextOKStyle>OK </StatusTextOKStyle>
          {args.icons && <Icon name="check" color="green" />}
        </span>
      );
    }
    if (!args.status.created) {
      return (
        <span>
          <StatusTextOKStyle>Creating </StatusTextOKStyle>
          <Popup content={`Creating: ${args.status.description}`} trigger={args.icons ? <Icon name="spinner" loading={true} /> : null} />
        </span>
      );
    }

    // Any failed server results in Error
    if (args.status.servers && args.status.servers.some((ss) => !!ss.failed)) {
      return (
        <span>
          <StatusTextNOKStyle color="var(--red-700)">Error </StatusTextNOKStyle>
          <Popup content={`Error: ${args.status.description}`} trigger={<span>{args.icons ? <Icon name="exclamation triangle" color="red" /> : null}</span>} />
        </span>
      );
    }
    return (
      <span>
        <StatusTextNOKStyle color="var(--red-700)">Not Ready </StatusTextNOKStyle>
        <Popup content={`Not Ready: ${args.status.description}`} trigger={<span>{args.icons ? <Icon name="exclamation circle" color="red" /> : null}</span>} />
      </span>
    );
  }

  return (
    <span>
      <StatusTextNOKStyle color="var(--red-700)">Unknown </StatusTextNOKStyle>
      {args.icons && <Icon name="question" />}
    </span>
  );
};
