//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Text } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { useDocumentTitle } from "../../components/useDocumentTitle";
import { Routes } from "../../routes";
import deploymentIcon from "../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/deployment.svg";
import { useDashboardContext } from "../DashboardContextProvider";
import { TopMenuBar } from "../../components/TopMenuBar";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { BreadCrumbItem } from "../TopMenuInfo";
import { DeploymentMigrationStatusMessage } from "./DeploymentMigrationMessage";
import { useFetchDeploymentForEdit } from "./useFetchDeploymentForEdit";

export const EditDeploymentTopMenu = () => {
  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const { data: deployment, isPrepaid } = useFetchDeploymentForEdit();

  const breadcrumbs = new Array<BreadCrumbItem>(
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem(project.name || "", Routes.dashboard_projectWithId(project.id || "")),
    new BreadCrumbItem("Deployments", Routes.dashboard_project_deploymentsWithId(project.id || "")),
    // note: this breadcrumb was missing in the previous version
    new BreadCrumbItem(deployment?.name || "", Routes.dashboard_project_deployment_detailsWithId(project.id || "", deployment?.id || ""))
  );
  const [nameField] = useField("name");
  useDocumentTitle(`Edit deployment: ${nameField.value}`);
  return (
    <TopMenuBar
      breadcrumbs={breadcrumbs}
      icon={deploymentIcon}
      title={`Edit: ${nameField.value}`}
      subheading={
        isPrepaid && <Text color="gray.500">This is a prepaid deployment. Only name, description, version, certificates, and IP allow list can be edited</Text>
      }
    >
      <DeploymentMigrationStatusMessage />
    </TopMenuBar>
  );
};
