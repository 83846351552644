//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Provider as ApiProvider, ProviderList as ApiProviderList, ListProvidersRequest as ApiListProvidersRequest } from "../../api/platform/v1/platform";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectProviderViewArgs extends ISelectProviderState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

export const createProviderName = (p: ApiProvider) => {
  if (!!p.prerelease) {
    return `${p.name} (Preview)`;
  }
  return p.name;
};

const SelectProviderView = ({ ...args }: ISelectProviderViewArgs) => {
  return args.providers && args.providers.items ? (
    <Form.Dropdown
      required
      fluid
      selection
      label="Provider"
      placeholder="Select Provider"
      name="provider"
      value={args.selectedProvider && args.selectedProvider.name}
      onChange={args.updateFormDropdownChange}
      options={args.providers.items.map((i) => {
        return { key: i.id, text: createProviderName(i), value: i.name };
      })}
    />
  ) : (
    <Form.Dropdown required fluid selection label="Provider" placeholder="Select Provider" name="provider" loading={true} />
  );
};

// Interface decribing the properties of the SelectProvider component
interface ISelectProviderProps extends IWithRefreshProps {
  organizationId: string;
  provider?: ApiProvider;
  selectedProvider?: ApiProvider;
  select_first?: boolean;
  selectedProviderId?: string;
  onProviderUpdated: (provider?: ApiProvider) => void;
}

// Interface decribing the state of the SelectProvider component
interface ISelectProviderState {
  providers?: ApiProviderList;
  selectedProvider?: ApiProvider;
}

// Component to seelct a provider
class SelectProvider extends Component<ISelectProviderProps, ISelectProviderState> {
  state = {
    providers: undefined,
    selectedProvider: this.props.provider,
  } as ISelectProviderState;

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "provider":
        const providers = this.state.providers;
        const selectedProvider = providers && providers.items && providers.items.find((p) => p.name == args.value);
        this.setState({ selectedProvider: selectedProvider });
        this.props.onProviderUpdated(selectedProvider);
    }
  };

  reloadProviders = async () => {
    const listReq = { organization_id: this.props.organizationId } as ApiListProvidersRequest;
    const providers = await apiClients.platformClient.ListProviders(listReq);

    // Intentionally setting here to override for free upgrades. Will be removed in the new create deployment flow and code refactor
    this.setState({ selectedProvider: this.props.selectedProvider }, () => {
      this.props.onProviderUpdated(this.props.selectedProvider);
    });

    this.setState(
      (old) => {
        const update = { providers: providers } as ISelectProviderState;
        if (!old.selectedProvider && !!this.props.select_first) {
          update.selectedProvider = _.first(providers.items || []);
        }
        return update;
      },
      () => {
        if (!!this.props.select_first) {
          this.props.onProviderUpdated(this.state.selectedProvider);
        }
      }
    );
    this.setState(
      {
        providers: providers,
        selectedProvider: _.find(providers.items, (p) => p.id === this.props.selectedProviderId) || _.first(providers.items || []),
      },
      () => {
        this.props.onProviderUpdated(this.state.selectedProvider);
      }
    );
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadProviders);
  }

  render() {
    return <SelectProviderView {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />;
  }
}

export default withRefresh()(SelectProvider);
