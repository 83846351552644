//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Icon, Label, Popup } from "semantic-ui-react";
import { Deployment as ApiDeployment } from "../../api/data/v1/data";
import {
  ButtonsCardContent,
  DeploymentCard,
  FloatLeft,
  FloatRight,
  StatusStyle,
  StatusTextNOKStyle,
  StretchedButtonGroup,
  StyledCardHeader,
  StyledCardProvider,
  StyledCardRegion,
  TextLink,
  Locked,
} from "../../ui/lib";
import { ListDeploymentNotificationsRequest as ApiListDeploymentNotificationsRequest } from "../../api/notification/v1/notification";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { DeploymentStatusView } from "./DeploymentStatusView";
import Location from "./Location";
import { PrepaidIcon } from "../prepaid/PrepaidIcon";
import { IDataVolumeStats } from "./DataVolumeView";
import { isEmpty } from "lodash";
import apiClients from "../../api/apiclients";
import copy from "copy-to-clipboard";
import { ModelFree } from "../../constants";
import { useDashboardContext } from "../DashboardContextProvider";
import { DeploymentMigration, isNotFound } from "../../api/lib";
import { RenderGuard } from "../../util/RenderGuard";
import { Box } from "../../ui/_box";

// Interface describing a deployment
interface IDeploymentCardView extends IWithRefreshProps, IDataVolumeStats {
  item: ApiDeployment;
  onClickView: () => void;
  onOpenDatabase: () => void;
  onResumeDeployment: () => void;
}

export const DeploymentCardView = ({ ...args }: IDeploymentCardView) => {
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();

  const [notificationCount, setNotificationCount] = React.useState<number | string>(0);
  const [migrationState, setMigrationState] = React.useState<DeploymentMigration>({});

  const hasDeploymentPermission = (p: Permission) => args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.Project, p);
  const has_deployment_get = hasDeploymentPermission("data.deployment.get");
  const has_deployment_resume = hasDeploymentPermission("data.deployment.resume");
  const isDeploymentPaused = !!args.item.is_paused;
  const locked = !!args.item.locked;
  const hasReplaceBy = !!args.item.replace_version_by;
  const hasCustomImage = !isEmpty(args.item.custom_image);
  const replaceBy = args.item.replace_version_by || {};
  const replaceByDate = replaceBy.auto_update_date;
  const hasUpgradeRecommendation = !!args.item.upgrade_recommendation;
  const upgradeRecommendation = args.item.upgrade_recommendation || {};
  const isPrepaid = !isEmpty(args.item.prepaid_deployment_id);
  const hasPrepaidDeploymentExpired = moment(args.item.prepaid_deployment_ends_at).isBefore(moment());
  const { isDiskNearingCapacity } = args;
  const versionIsEndOfLife = args.item.version_is_end_of_life;
  const isWarning = hasReplaceBy || versionIsEndOfLife;
  const { status = {} } = args.item;
  const { private_endpoint_only: privateEndpointOnly = false, endpoint_private_endpoint: privateEndpoint = "" } = status;
  const [copied, setCopied] = useState(false);

  const getNotificationCount = async () => {
    const req: ApiListDeploymentNotificationsRequest = { deployment_id: args.item.id, unread_only: true, options: { page: 0, page_size: 10 } };
    const notifications = await apiClients.notificationClient.ListDeploymentNotifications(req);
    const { items = [] } = notifications;
    setNotificationCount(items.length > 9 ? "9+" : items.length);
  };

  const getDeploymentMigrationDetails = async () => {
    try {
      const response = await apiClients.replicationClient.GetDeploymentMigration({ id: args.item.id });
      setMigrationState(response);
    } catch (e) {
      if (isNotFound(e)) {
        setMigrationState({});
      }
    }
  };

  useEffect(() => {
    getNotificationCount();
    getDeploymentMigrationDetails();
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);
  const expiryString = args.item.expiration ? `Expires ${moment(args.item.expiration?.expires_at).fromNow()}` : "";
  return (
    <DeploymentCard>
      <Card.Content textAlign="center">
        <StyledCardHeader>
          {isPrepaid && (
            <FloatLeft>
              <Popup
                trigger={
                  <span>
                    <PrepaidIcon />
                  </span>
                }
                content={<p className="para">This deployment is prepaid as part of your contract with ArangoDB.</p>}
              />
            </FloatLeft>
          )}

          {!hasCustomImage && (hasReplaceBy || hasUpgradeRecommendation || versionIsEndOfLife) && (
            <FloatRight>
              <Popup
                trigger={<Icon name={isWarning ? "exclamation circle" : "idea"} size="big" color={isWarning ? "red" : "olive"} />}
                content={
                  <div>
                    {hasReplaceBy && (
                      <div>
                        <p className="para">
                          This deployment will be upgraded automatically to version <strong>{replaceBy.version} </strong>
                          {!replaceByDate && <span>shortly</span>}
                          {!!replaceByDate && <span>{moment(replaceByDate).fromNow()}</span>}.
                        </p>
                        <p className="para">Reason: {replaceBy.reason}</p>
                      </div>
                    )}
                    {!hasReplaceBy && versionIsEndOfLife && (
                      <div>
                        <p className="para">This deployment is using a version that has reached the End Of Life and therefore is no longer supported.</p>
                        <p className="para">You are recommended to upgrade to a newer version as soon as possible!</p>
                      </div>
                    )}
                    {!hasReplaceBy && !versionIsEndOfLife && hasUpgradeRecommendation && (
                      <div>
                        <p className="para">
                          You are recommended to upgrade this deployment to version <strong>{upgradeRecommendation.version}</strong>.
                        </p>
                        <p className="para">Reason: {upgradeRecommendation.reason}</p>
                      </div>
                    )}
                  </div>
                }
              />
            </FloatRight>
          )}
          <TextLink onClick={args.onClickView} disabled={!has_deployment_get}>
            {args.item.name}{" "}
            {!!notificationCount && (
              <Popup
                trigger={<Icon name="bell outline" color="green" size="small" />}
                content={`You have ${notificationCount} notification${Number(notificationCount) > 1 || notificationCount === "9+" ? "s" : ""}`}
                position="top center"
              />
            )}
            {isDiskNearingCapacity && (
              <Popup
                content="You are almost out of disk space. Please increase the disk size to avoid disk space issues."
                position="top right"
                trigger={<Icon name="warning circle" color="yellow" />}
              />
            )}
          </TextLink>
          {locked && <Locked />}
        </StyledCardHeader>
        <StyledCardProvider className="provider">
          <Location {...args} regionId={args.item.region_id} showProvider showProviderIcon showRegion={false} />{" "}
        </StyledCardProvider>
        <StyledCardRegion className="region">
          <Location {...args} regionId={args.item.region_id} showProvider={false} showRegion />
        </StyledCardRegion>
        <StatusStyle>STATUS&nbsp;&nbsp;</StatusStyle>
        <DeploymentStatusView icons deployment={args.item} status={args.item.status} />
        {args.item.expiration && args.item.expiration.expires_at && (
          <Popup
            content={args.item.expiration.reason}
            trigger={
              <div>
                {args.item.model?.model === ModelFree && isPerpetualFreeTrialAvailable ? (
                  <StatusTextNOKStyle color="var(--green-700)">Free ({expiryString})</StatusTextNOKStyle>
                ) : (
                  <StatusTextNOKStyle color="var(--red-700)">{expiryString}</StatusTextNOKStyle>
                )}
              </div>
            }
          />
        )}
        <RenderGuard renderIf={!!migrationState.status?.phase || !!migrationState?.status?.phase || !!migrationState?.target_deployment}>
          <Box padding={"20px"}>
            <Popup
              content={"Your free deployment is being upgraded to a paid one. This deployment will be automatically deleted after the upgrade."}
              trigger={
                <Label basic circular>
                  {migrationState.status?.phase?.toLowerCase() === "failed" && (
                    <span>
                      {" "}
                      <Icon name="warning circle" color="orange" />
                    </span>
                  )}
                  {migrationState.status?.phase?.toLowerCase() === "error" && (
                    <span>
                      {" "}
                      <Icon name="warning circle" color="orange" />
                    </span>
                  )}
                  Upgrading deployment
                  {migrationState.status?.phase?.toLowerCase() === "completed" && <span> completed</span>}
                  {migrationState.status?.phase?.toLowerCase() === "failed" && <span> failed</span>}
                  {migrationState.status?.phase?.toLowerCase() === "error" && <span> failed</span>}
                </Label>
              }
            />
          </Box>
        </RenderGuard>
      </Card.Content>
      <ButtonsCardContent extra>
        <StretchedButtonGroup className="two" attached="bottom">
          {has_deployment_get && <Button onClick={() => args.onClickView()}>View</Button>}
          {!isDeploymentPaused &&
            has_deployment_get &&
            (!privateEndpointOnly ? (
              <Button
                disabled={args.item.is_deleted || !args.item.status || !args.item.status.endpoint || !args.item.status.bootstrapped}
                onClick={() => args.onOpenDatabase()}
              >
                Open database UI
              </Button>
            ) : (
              <Button
                disabled={args.item.is_deleted || !args.item.status || !args.item.status.endpoint || !args.item.status.bootstrapped}
                onClick={() => {
                  copy(privateEndpoint);
                  setCopied(true);
                }}
              >
                {copied ? "Copied Endpoint" : "Copy Endpoint"}
              </Button>
            ))}
          {isDeploymentPaused && has_deployment_resume && !hasPrepaidDeploymentExpired && (
            <Button onClick={() => args.onResumeDeployment()}>Resume Deployment</Button>
          )}
        </StretchedButtonGroup>
      </ButtonsCardContent>
    </DeploymentCard>
  );
};
