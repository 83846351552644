import React from "react";
import { Button, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { OrganizationBillingFlags } from "../../api/lib";
import { useAuthContext } from "../../auth/AuthContextProvider";
import { Routes } from "../../routes";
import { useTrackingContext } from "../../tracking/TrackingContextProvider";
import { Loading, Section, SectionContent, SectionHead, SectionHeader, TextLink } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import BillingDetails from "./BillingDetails";
import Invoices from "./Invoices";
import PaymentMethods from "./PaymentMethods";
import { Redirect } from "react-router-dom";

const useBillingFlags = () => {
  const [billingFlags, setBillingFlags] = React.useState<OrganizationBillingFlags | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { selectedOrganization } = useDashboardContext();
  const fetchBillingFlags = async () => {
    const idOptions = { id: selectedOrganization?.id };
    setIsLoading(true);
    const billingFlags = await apiClients.billingClient.GetOrganizationBillingFlags(idOptions);
    setBillingFlags(billingFlags);
    setIsLoading(false);
  };
  React.useEffect(() => {
    fetchBillingFlags();
  }, [selectedOrganization?.id]);
  return { billingFlags, isLoading };
};

export const OrganizationBilling = () => {
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const isBillingConfigGetAllowed = checkOrgPermission("billing.config.get");
  const isBillingConfigSetAllowed = checkOrgPermission("billing.config.set");
  const { isFreeTier } = useDashboardContext();
  const { billingFlags, isLoading: isLoadingBillingFlags } = useBillingFlags();
  const hasBillingFlags = !!billingFlags;
  const is_allowed_to_access_payment_methods = !!billingFlags?.is_allowed_to_access_payment_methods;
  const is_allowed_to_list_invoices = !!billingFlags?.is_allowed_to_list_invoices;
  const isPaymentMethodListAllowed = is_allowed_to_access_payment_methods && checkOrgPermission("billing.paymentmethod.list");
  const isPaymentMethodCreateAllowed = is_allowed_to_access_payment_methods && checkOrgPermission("billing.paymentmethod.create");
  const isPaymentMethodUpdateAllowed = is_allowed_to_access_payment_methods && checkOrgPermission("billing.paymentmethod.update");
  const isPaymentMethodDeleteAllowed = is_allowed_to_access_payment_methods && checkOrgPermission("billing.paymentmethod.delete");
  const isInvoiceListAllowed = is_allowed_to_list_invoices && checkOrgPermission("billing.invoice.list");
  const isInvoicePreliminaryViewAllowed = is_allowed_to_list_invoices && checkOrgPermission("billing.invoice.get-preliminary");

  const showBillingTab = hasBillingFlags && (isBillingConfigGetAllowed || isPaymentMethodListAllowed || isInvoiceListAllowed);

  const [billingConfigValid, setBillingConfigValid] = React.useState<boolean>(true);
  const onBillingConfigChanged = (isValid: boolean) => {
    setBillingConfigValid(isValid);
  };
  const { refreshNow } = useWithRefresh();
  const { selectedOrganization, onReloadOrganization } = useDashboardContext();
  const { auth } = useAuthContext();
  const { tracking } = useTrackingContext();
  if (isLoadingBillingFlags) {
    return <Loading />;
  }
  if (!showBillingTab) {
    return <Redirect to={Routes.dashboard_organization_overviewWithId(selectedOrganization.id || "")} />;
  }
  return (
    <div>
      {isFreeTier && (
        <Section>
          <SectionHead>
            <SectionHeader title="Unlock All Features" />
          </SectionHead>
          <SectionContent>
            <Segment>
              <p className="para">To unlock all ArangoGraph functionality, enter your billing address, then add a payment method.</p>
              <p className="para">
                As soon as you've added a payment method, all ArangoGraph functionality is immediately unlocked. From that point on, your deployments will no
                longer expire and you can create more &amp; larger deployments.
              </p>
              <Button as={TextLink} onClick={() => window.location.replace(Routes.dashboard_pricing)}>
                See all ArangoGraph packages
              </Button>
            </Segment>
          </SectionContent>
        </Section>
      )}
      {isBillingConfigGetAllowed && (
        <BillingDetails
          api={apiClients.billingClient}
          isEditAllowed={!!isBillingConfigSetAllowed}
          onBillingConfigChanged={onBillingConfigChanged}
          isPaymentMethodListAllowed={!!isPaymentMethodListAllowed}
          isPaymentMethodCreateAllowed={!!isPaymentMethodCreateAllowed}
        />
      )}
      {isPaymentMethodListAllowed && (
        <PaymentMethods
          organization={selectedOrganization}
          onReloadOrganization={onReloadOrganization}
          tracking={tracking}
          refreshNow={refreshNow}
          api={apiClients.billingClient}
          isAddAllowed={!!isPaymentMethodCreateAllowed}
          isAddEnabled={billingConfigValid}
          isUpdateAllowed={!!isPaymentMethodUpdateAllowed}
          isDeleteAllowed={!!isPaymentMethodDeleteAllowed}
        />
      )}
      {isInvoiceListAllowed && (
        <Invoices
          refreshNow={refreshNow}
          auth={auth}
          organization={selectedOrganization}
          isInvoicePreliminaryViewAllowed={!!isInvoicePreliminaryViewAllowed}
          invoiceAPI={apiClients.billingClient}
        />
      )}
    </div>
  );
};
