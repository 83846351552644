//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Loader, Segment } from "semantic-ui-react";
import homeImage from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import Auth from "../auth/Auth";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Logout = (
  props: {
    auth: Auth;
  } & RouteComponentProps
) => {
  useEffect(() => {
    props.auth.logout();
  }, []);

  return (
    <StyledContainer>
      <Segment style={{ textAlign: "center" }}>
        <img src={homeImage} alt="logo" height="70" />
        <>
          <p className="para">Please wait while we signing out</p>
          <Loader active inline>
            Loading...
          </Loader>
        </>
      </Segment>
      <br />
    </StyledContainer>
  );
};

export default Logout;
