//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Dispatch, SetStateAction } from "react";
import { Network } from "vis-network";
import { GRAPH_ATTRIBUTES } from "../graphOptions";

export const useGraphEvents = ({
  hideAllMenu,
  setPosition,
  toggleNodeMenu,
  toggleEdgeMenu,
  setCanvasZoom,
  selectNode,
  selectEdge,
  setEditView,
}: {
  hideAllMenu: () => void;
  setPosition: Dispatch<SetStateAction<{ left: string; top: string }>>;
  toggleNodeMenu: Dispatch<SetStateAction<boolean>>;
  toggleEdgeMenu: Dispatch<SetStateAction<boolean>>;
  setCanvasZoom: Dispatch<SetStateAction<{ scale: number; direction: string }>>;
  selectNode: (node?: number) => void;
  selectEdge: (edge?: number) => void;
  setEditView: (mode: "EDGE" | "NODE" | undefined) => void;
}) => {
  const getNodeAtPointer = (net: Network, pointer: any) => {
    return net.getNodeAt(pointer.DOM);
  };

  const getNodePosition = (net: Network, pointer: any) => {
    return net.canvasToDOM(net.getPosition(getNodeAtPointer(net, pointer)));
  };

  const getEdgeAtPointer = (net: Network, pointer: any) => {
    return net.getEdgeAt(pointer.DOM);
  };

  const handleDragStart = () => {
    hideAllMenu();
  };

  const handleCanvasHold = () => {
    hideAllMenu();
  };

  const handleCanvasClick = (net: Network, { pointer }: any) => {
    hideAllMenu();

    if (!net) {
      return;
    }

    const node = getNodeAtPointer(net, pointer) as number;
    const edge = getEdgeAtPointer(net, pointer) as number;

    if (node) {
      selectNode(node);
      const { x, y } = getNodePosition(net, pointer);
      setPosition({ left: `${x + GRAPH_ATTRIBUTES.menuLeftOffset}px`, top: `${y - GRAPH_ATTRIBUTES.menuTopOffset}px` });
      toggleNodeMenu(true);
      setEditView("NODE");
      return;
    }

    if (edge) {
      const { DOM } = pointer;
      net.selectEdges([edge]);
      selectEdge(edge);
      setPosition({ left: `${DOM.x}px`, top: `${DOM.y}px` });
      toggleEdgeMenu(true);
      setEditView("EDGE");
      return;
    }
  };

  const handleCanvasContextClick = (net: Network, { pointer, event }: any) => {};

  const handleNodeClick = (_: Network, { nodes }: any) => {
    selectNode(nodes[0].id);
  };

  const handleEdgeClick = (_: Network, { edges }: any) => {
    selectEdge(edges[0].id);
  };

  const handleZoom = ({ direction, scale }: any) => {
    setCanvasZoom({ direction, scale });
    hideAllMenu();
  };

  const handleEdgeDeselect = () => {
    hideAllMenu();
    setEditView(undefined);
    selectEdge();
  };

  const handleNodeDeselect = () => {
    hideAllMenu();
    setEditView(undefined);
    selectNode();
  };

  return {
    handleDragStart,
    handleCanvasHold,
    handleCanvasClick,
    handleZoom,
    handleCanvasContextClick,
    handleEdgeClick,
    handleNodeClick,
    handleEdgeDeselect,
    handleNodeDeselect,
  };
};
