//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import {
  Organization as ApiOrganization,
  OrganizationList as ApiOrganizationList,
  ProjectList as ApiProjectList,
} from "../../api/resourcemanager/v1/resourcemanager";
import Auth from "../../auth/Auth";
import { Routes } from "../../routes";
import { ITracking } from "../../tracking/api";
import { Confirm, ContentSegment, ErrorMessage, Loading, MainContent, Processing } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import CreateGroup from "../group/CreateGroup";
import GroupDetails from "../group/GroupDetails";
import UpdateGroup from "../group/UpdateGroup";
import CreateOrganizationInvite from "../organization-invites/CreateOrganizationInvite";
import CreateProject from "../project/CreateProject";
import CreateRole from "../role/CreateRole";
import RoleDetails from "../role/RoleDetails";
import UpdateRole from "../role/UpdateRole";
import { OrganizationDetailsContextProvider, useOrganizationDetailsContext } from "../organization/OrganizationDetailsContextProvider";
import { useDashboardContext } from "../DashboardContextProvider";
import { Message } from "semantic-ui-react";
import moment from "moment";
import { CreateOrganizationRoleBindings } from "../organization/policy/CreateOrganizationRoleBindings";
import { OrganizationPolicyWrap } from "../organization/policy/OrganizationPolicyWrap";
import { OrganizationInvitesWrap } from "../organization/OrganizationInvitesWrap";
import { OrganizationRolesWrap } from "../organization/OrganizationRolesWrap";
import { OrganizationAuditLogsWrap } from "../organization/OrganizationAuditLogsWrap";
import { OrganizationMembers } from "../organization/OrganizationMembers";
import { OrganizationGroups } from "../organization/OrganizationGroups";
import { OrganizationBilling } from "../organization/OrganizationBilling";
import { OrganizationOverview } from "../organization/OrganizationOverview";
import { OrganizationDangerZone } from "../organization/OrganizationDangerZone";
import { OrganizationCreditsBundlePermissionWrap } from "../organization/credits/CreditsBundlePermissionWrap";

// Interface describing the organization view arguments
interface IOrganizationViewArgs extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onOrganizationInviteDeleted: (id: string) => void;
  selectedOrganization: ApiOrganization;
  onOrganizationSelected: (organizationId: string) => void;
  onOrganizationDeleted: (organizationId: string) => void;
  auth: Auth;
  tracking: ITracking;

  onNewProjectCreated: (projectId: string, fromDashboardHome: boolean) => void;
  onReloadOrganization: () => void;
}

const OrganizationView = ({ ...args }: IOrganizationViewArgs) => {
  return (
    <div>
      <OrganizationDetailsContextProvider onReloadOrganization={args.onReloadOrganization} onOrganizationDeleted={args.onOrganizationDeleted}>
        <OrganizationRoutes {...args} />
      </OrganizationDetailsContextProvider>
    </div>
  );
};

const DeletedOrganizationView = () => {
  const { selectedOrganization } = useDashboardContext();
  return (
    <div>
      {selectedOrganization.is_deleted && (
        <Message>This organization has been deleted {selectedOrganization.deleted_at ? moment(selectedOrganization.deleted_at).fromNow() : "-"}.</Message>
      )}
    </div>
  );
};

const RedirectOrganizationHashRoutes = (props: { defaultRoute?: string } & RouteComponentProps) => {
  const { selectedOrganization } = useDashboardContext();
  const hash = props.location.hash;
  const { defaultRoute } = props;
  switch (hash) {
    case "#billing":
      return <Redirect to={Routes.dashboard_organization_billingWithId(selectedOrganization.id || "")} />;
    case "#members":
      return <Redirect to={Routes.dashboard_organization_membersWithId(selectedOrganization.id || "")} />;
    case "#groups":
      return <Redirect to={Routes.dashboard_organization_groupsWithId(selectedOrganization.id || "")} />;
    case "#invites":
      return <Redirect to={Routes.dashboard_organization_organizationInvitesWithId(selectedOrganization.id || "")} />;
    case "#roles":
      return <Redirect to={Routes.dashboard_organization_rolesWithId(selectedOrganization.id || "")} />;
    case "#policy":
      return <Redirect to={Routes.dashboard_organization_policyWithId(selectedOrganization.id || "")} />;
    case "#auditlogs":
      return <Redirect to={Routes.dashboard_organization_auditlogsWithId(selectedOrganization.id || "")} />;
    case "#overview":
      return <Redirect to={Routes.dashboard_organization_overviewWithId(selectedOrganization.id || "")} />;
    default:
      return <Redirect to={defaultRoute ? defaultRoute : Routes.dashboard_organization_overviewWithId(selectedOrganization.id || "")} />;
  }
};

const OrganizationRoutes = ({ ...args }: IOrganizationViewArgs) => {
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { confirmInfo, processingDelete, processingLock, processingUnlock, errorMessage, handleDismissError } = useOrganizationDetailsContext();
  const isTopMenuDisabled = topMenuInfo.getIsDisabled();
  return (
    <ContentSegment
      paddingLeft={isTopMenuDisabled ? "0px !important" : undefined}
      paddingTop={isTopMenuDisabled ? "0px !important" : undefined}
      paddingRight={isTopMenuDisabled ? "0px !important" : undefined}
    >
      <Confirm confirmInfo={confirmInfo} />
      <Processing active={processingDelete} message="Deleting organization, please wait..." />
      <Processing active={processingLock} message="Locking organization, please wait..." />
      <Processing active={processingUnlock} message="Unlocking organization, please wait..." />
      <Processing active={processingDelete} message="Deleting organization, please wait..." />
      <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />
      <DeletedOrganizationView />
      <MainContent marginTop={isTopMenuDisabled ? "0px" : undefined}>
        <Route
          exact
          path={Routes.dashboard_organization}
          render={() => <Redirect to={Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")} />}
        />
        <Route
          exact
          path={Routes.dashboard_organization_details}
          render={(props) => {
            return <RedirectOrganizationHashRoutes {...props} />;
          }}
        />
        <Route
          exact
          path={Routes.dashboard_organization_people}
          render={(props) => {
            return <RedirectOrganizationHashRoutes defaultRoute={Routes.dashboard_organization_membersWithId(selectedOrganization?.id || "")} {...props} />;
          }}
        />
        <Route
          exact
          path={Routes.dashboard_organization_access_control}
          render={(props) => {
            return <RedirectOrganizationHashRoutes defaultRoute={Routes.dashboard_organization_rolesWithId(selectedOrganization?.id || "")} {...props} />;
          }}
        />
        <Route path={Routes.dashboard_organization_overview} render={() => <OrganizationOverview />} />
        <Route path={Routes.dashboard_organization_credits} render={() => <OrganizationCreditsBundlePermissionWrap />} />
        <Route path={Routes.dashboard_organization_billing} render={() => <OrganizationBilling />} />
        <Route exact path={Routes.dashboard_organization_groups} render={() => <OrganizationGroups />} />
        <Route exact path={Routes.dashboard_organization_members} render={() => <OrganizationMembers />} />
        <Route exact path={Routes.dashboard_organization_organizationInvites} render={() => <OrganizationInvitesWrap />} />
        <Route exact path={Routes.dashboard_organization_auditlogs} render={() => <OrganizationAuditLogsWrap />} />
        <Route exact path={Routes.dashboard_organization_roles} render={() => <OrganizationRolesWrap />} />
        <Route exact path={Routes.dashboard_organization_policy} render={() => <OrganizationPolicyWrap />} />
        <Route exact path={Routes.dashboard_organization_dangerzone} render={() => <OrganizationDangerZone />} />

        <Route
          path={Routes.dashboard_organization_organizationInvites_create}
          render={(props) => (
            <CreateOrganizationInvite
              {...args}
              {...props}
              organization={selectedOrganization}
              onNewOrganizationInviteCreated={(id: string) => {
                props.history.replace(Routes.dashboard_organization_organizationInvitesWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route exact path={Routes.dashboard_organization_projects} render={() => <Redirect to={Routes.dashboard_projects} />} />
        <Route
          path={Routes.dashboard_organization_projects_create}
          render={(props) => <CreateProject {...args} {...props} onNewProjectCreated={args.onNewProjectCreated} organization={selectedOrganization || {}} />}
        />
        <Route
          path={Routes.dashboard_organization_groups_create}
          render={(props) => (
            <CreateGroup
              {...args}
              {...props}
              loading={false}
              organization={selectedOrganization}
              onNewGroupCreated={() => {
                props.history.replace(Routes.dashboard_organization_groupsWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route
          path={Routes.dashboard_organization_group_details}
          render={(props) => (
            <GroupDetails
              {...args}
              {...props}
              loading={false}
              organization={selectedOrganization}
              onGroupDeleted={() => {
                props.history.replace(Routes.dashboard_organization_groupsWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route
          path={Routes.dashboard_organization_group_edit}
          render={(props) => (
            <UpdateGroup
              {...args}
              {...props}
              organization={selectedOrganization}
              onGroupUpdated={(id: string) => {
                props.history.replace(Routes.dashboard_organization_groupsWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route
          path={Routes.dashboard_organization_roles_create}
          render={(props) => (
            <CreateRole
              {...args}
              {...props}
              loading={false}
              organization={selectedOrganization}
              onNewRoleCreated={() => {
                props.history.replace(Routes.dashboard_organization_rolesWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route
          path={Routes.dashboard_organization_role_details}
          render={(props) => (
            <RoleDetails
              {...args}
              {...props}
              loading={false}
              organization={selectedOrganization}
              onRoleDeleted={() => {
                props.history.replace(Routes.dashboard_organization_rolesWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route
          path={Routes.dashboard_organization_role_edit}
          render={(props) => (
            <UpdateRole
              {...args}
              {...props}
              loading={false}
              organization={selectedOrganization}
              onRoleUpdated={(id: string) => {
                props.history.replace(Routes.dashboard_organization_rolesWithId(selectedOrganization.id || ""));
              }}
            />
          )}
        />
        <Route path={Routes.dashboard_organization_policy_create} render={(props) => <CreateOrganizationRoleBindings />} />
        <Route path={Routes.dashboard_organization_events} render={() => null} />
      </MainContent>
    </ContentSegment>
  );
};
const NoOrganizationView = () => (
  <div>
    <h1 className="heading-1">No Organization selected, please select or create one.</h1>
  </div>
);

// Interface decribing the properties of the organization component
interface IOrganizationProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organizations?: ApiOrganizationList;
  selectedOrganization?: ApiOrganization;
  auth: Auth;
  tracking: ITracking;
  projects?: ApiProjectList;
  onOrganizationInviteDeleted: (id: string) => void;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  onOrganizationDeleted: (organizationId: string) => void;
  onNewProjectCreated: (projectId: string, fromDashboardHome: boolean) => void;
  onReloadOrganization: () => void;
}

// The Organization component
class Organization extends Component<IOrganizationProps> {
  render() {
    if (!this.props.organizations) {
      return <Loading />;
    }
    if (!this.props.selectedOrganization) {
      return <NoOrganizationView />;
    }
    return <OrganizationView {...this.props} selectedOrganization={this.props.selectedOrganization || {}} />;
  }
}

export default withRefresh()(Organization);
