//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "./HomeTemplate";
import DocumentTitle from "react-document-title";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../ui/_box";

interface IPrivacyPolicyViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
}

const PrivacyPolicyView = ({ ...args }: IPrivacyPolicyViewArgs) => (
  <HomeTemplateView {...args}>
    <Box maxWidth={"960px"} margin={"0 auto"}>
      <Grid columns="16" stackable>
        <GridRowIntro>
          <Grid.Column width="8">
            <a href="/">
              <img src={logo} alt="ArangoGraph" />
            </a>
            <h1 className="heading-1">Privacy policy</h1>
          </Grid.Column>
          {false && (
            <Grid.Column width="8" only="computer tablet">
              <TransparentLogo1 src={cloud} />
              <TransparentLogo2 src={cloud} />
              {/*<!-- FullLogo src={cloud} />*/}
            </Grid.Column>
          )}
        </GridRowIntro>
      </Grid>

      <Segment>
        <>
          <a href="https://www.iubenda.com/privacy-policy/22080653" className="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy">
            Privacy Policy
          </a>
          {(function (w, d) {
            var loader = function () {
              var s = d.createElement("script");
              var tag = d.getElementsByTagName("script")[0];
              s.src = "https://cdn.iubenda.com/iubenda.js";
              tag.parentNode && tag.parentNode.insertBefore && tag.parentNode.insertBefore(s, tag);
            };
            if (w.addEventListener) {
              w.addEventListener("load", loader, false);
            } else if ((w as any)["attachEvent"]) {
              (w as any)["attachEvent"]("onload", loader);
            } else {
              w.onload = loader;
            }
          })(window, document)}
        </>
      </Segment>
    </Box>
  </HomeTemplateView>
);

// Interface decribing the properties of the privacy policy component
interface IPrivacyPolicyProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

// Interface decribing the state of the privacy policy component
interface IPrivacyPolicyState {}

// PrivacyPolicy shows the privacy policy
class PrivacyPolicy extends Component<IPrivacyPolicyProps, IPrivacyPolicyState> {
  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  render() {
    return (
      <DocumentTitle title="Privacy policy">
        <PrivacyPolicyView
          {...this.props}
          isAuthenticated={this.props.auth.isAuthenticated()}
          onClickLogin={this.login}
          onSignup={this.signup}
          onClickLogout={this.logout}
        />
      </DocumentTitle>
    );
  }
}

export default withSignupModal(PrivacyPolicy);
