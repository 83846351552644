//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _, { isEmpty } from "lodash";
import React, { Component } from "react";
import { DropdownProps, Form, Icon } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { ListRegionsRequest as ApiListRegionsRequest, Provider as ApiProvider, Region as ApiRegion } from "../../api/platform/v1/platform";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { useFeature } from "flagged";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { FlexBox } from "../../ui/_flex";

interface ISelectRegionViewArgs extends ISelectRegionState {
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

export const createRegionName = (r: ApiRegion) => {
  let regionName = r.location;

  if (r.prerelease) {
    regionName = `${regionName} (Preview)`;
  }

  return regionName;
};

export const SelectRegionView = ({ ...args }: ISelectRegionViewArgs) => {
  const isMLServicesAvailable = useFeature("MLServices");
  if (!args.loading && !args.regions && !!args.prevProvider) {
    return (
      <FlexBox style={{ padding: "2rem 0rem 0rem 0rem" }} justify="center">
        <Icon name="info circle" style={{ color: "var(--red-700)" }} />
        <p className="para">The selected provider is not available for the selected model. Please, choose a different provider.</p>
      </FlexBox>
    );
  }

  return args.regions ? (
    <Form.Dropdown
      required
      fluid
      selection
      label="Region"
      placeholder="Select Region"
      name="region"
      value={args.selectedRegion && args.selectedRegion.location}
      onChange={args.updateFormDropdownChange}
      options={args.regions.map((r) => {
        return {
          key: r.id,
          text: r.ml_supported && isMLServicesAvailable ? `${createRegionName(r)} (ML Compatible)` : createRegionName(r),
          value: r.location,
          icon: !!r.out_of_stock ? "warning sign" : !!r.low_stock ? "warning" : "",
          className: !!r.out_of_stock ? "red-text" : "",
          description: !!r.out_of_stock ? "Out of stock" : !!r.low_stock ? "Low on stock" : "",
        };
      })}
    />
  ) : (
    <Form.Dropdown
      required
      fluid
      selection
      label="Region"
      placeholder="Select Region"
      name="region"
      loading={!!args.selectedProvider}
      disabled={!args.selectedProvider}
    />
  );
};

// Interface decribing the properties of the SelectRegion component
interface ISelectRegionProps extends IWithRefreshProps {
  organizationId: string;
  provider?: ApiProvider;
  region?: ApiRegion;
  select_first?: boolean;
  assignProvidedRegionFirst?: boolean;
  onRegionUpdated: (region?: ApiRegion) => void;
  hasNoModel?: boolean;
  isPerpetualFreeTrialAvailable: boolean;
  selectedProvider?: ApiProvider;
  selectedRegion?: ApiRegion;
}

// Interface decribing the state of the SelectRegion component
interface ISelectRegionState {
  selectedProvider?: ApiProvider;
  regions?: ApiRegion[];
  selectedRegion?: ApiRegion;
  refreshNeeded: boolean;
  prevProvider?: ApiProvider;
  modelId: string | undefined;
  loading: boolean;
}

// Component to select a region
class SelectRegion extends Component<ISelectRegionProps, ISelectRegionState> {
  state = {
    selectedProvider: undefined,
    regions: undefined,
    selectedRegion: undefined,
    refreshNeeded: false,
    prevProvider: undefined,
    loading: false,
  } as ISelectRegionState;

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "region":
        const regions = this.state.regions;
        const selectedRegion = regions && regions.find((r) => r.location == args.value);
        this.setState({ selectedRegion: selectedRegion });
        this.props.onRegionUpdated(selectedRegion);
        break;
    }
  };

  reload = (provider?: ApiProvider) => {
    if (provider) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadRegions(provider));
    }
  };

  reloadRegions = async (provider: ApiProvider) => {
    this.setState({
      regions: undefined,
      selectedRegion: undefined,
      loading: true,
    });
    let listReq = {
      provider_id: provider.id,
      organization_id: this.props.organizationId,
    } as ApiListRegionsRequest;

    if (this.props.isPerpetualFreeTrialAvailable) {
      listReq = {
        ...listReq,
        model_id: this.props.hasNoModel ? "" : useDeploymentStore.getState().deployment.model?.model || "",
      };
    }

    const regions = await apiClients.platformClient.ListRegions(listReq);
    // Filter out unavailable regions
    const availableRegions = regions && regions.items ? regions.items.filter((x) => x.available) : undefined;

    // Intentionally setting here to override for free upgrades. Will be removed in the new create deployment flow and code refactor
    this.setState({ selectedProvider: this.props.selectedProvider, selectedRegion: this.props.selectedRegion });

    this.setState(
      (old) => {
        const update = { regions: availableRegions, loading: false } as ISelectRegionState;
        if (!old.selectedRegion && !!this.props.select_first) {
          update.selectedRegion = _.first(availableRegions || []);
        }
        return update;
      },
      () => {
        if (this.props.select_first) {
          this.props.onRegionUpdated(this.state.selectedRegion);
        }
      }
    );

    if (this.props.assignProvidedRegionFirst && !isEmpty(this.props.region)) {
      this.setState({ selectedRegion: this.props.region }, () => {
        this.props.onRegionUpdated(this.state.selectedRegion);
      });
    }
  };

  static getDerivedStateFromProps(props: ISelectRegionProps, state: ISelectRegionState) {
    const modelId = useDeploymentStore.getState().deployment.model?.model;

    if (props.provider !== state.prevProvider) {
      return {
        refreshNeeded: true,
        prevProvider: props.provider,
      };
    } else if (props.provider !== state.prevProvider || (modelId !== state.modelId && props.isPerpetualFreeTrialAvailable)) {
      return {
        refreshNeeded: true,
        prevProvider: props.provider,
        modelId: modelId,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.reload(this.props.selectedProvider);
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => this.reload(this.props.provider));
    }
  }

  render() {
    return (
      <div>
        <SelectRegionView {...this.state} updateFormDropdownChange={this.updateFormDropdownChange} />
      </div>
    );
  }
}

export default withRefresh()(SelectRegion);
