//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, InputOnChangeData, Modal, DropdownProps, Grid, Popup, Icon, Divider } from "semantic-ui-react";
import { NumberInput, Section, SectionButtons, SectionHead, SectionHeader, SectionContent, Footnote, Loading, ErrorMessage } from "../../ui/lib";
import styled from "@emotion/styled";
import apiClients from "../../api/apiclients";
import { FlexBox } from "../../ui/_flex";
import { createRegionName } from "../deployment/SelectRegion";
import { ListRegionsRequest, Region } from "../../api/lib";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";

// 20 years in days, used as a sensible maximum value for uploaded retention period.
const twentyYearsInDays = 7300;

export enum BackupNotificationType {
  Never,
  FailureOnly,
  Always,
}

export class BackupNotification {
  public static values(): BackupNotificationType[] {
    return [BackupNotificationType.Never, BackupNotificationType.FailureOnly, BackupNotificationType.Always];
  }

  public static fromAPI(str: string): BackupNotificationType {
    switch (str) {
      case "Never":
        return BackupNotificationType.Never;
      case "FailureOnly":
        return BackupNotificationType.FailureOnly;
      case "Always":
      default:
        //should never happen, fallback to always
        return BackupNotificationType.Always;
    }
  }

  // toAPI returns API value for notification type.
  public static toAPI(type: BackupNotificationType): string {
    switch (type) {
      case BackupNotificationType.Never:
        return "Never";
      case BackupNotificationType.FailureOnly:
        return "FailureOnly";
      case BackupNotificationType.Always:
        return "Always";
    }
  }

  // toText returns UI text for notification type.
  public static toText(type: BackupNotificationType): string {
    switch (type) {
      case BackupNotificationType.Never:
        return "Never";
      case BackupNotificationType.FailureOnly:
        return "In case of failure only";
      case BackupNotificationType.Always:
        return "Always";
    }
  }
}

export enum BackupScheduleType {
  Hourly = 1,
  Daily,
  Monthly,
}

export class BackupSchedule {
  public static values(): BackupScheduleType[] {
    return [BackupScheduleType.Hourly, BackupScheduleType.Daily, BackupScheduleType.Monthly];
  }

  public static toType(str: string): BackupScheduleType {
    switch (str) {
      case "Hourly":
        return BackupScheduleType.Hourly;
      case "Daily":
        return BackupScheduleType.Daily;
      case "Monthly":
        return BackupScheduleType.Monthly;
      default:
        //should never happen, fallback to 'please select'
        return 0 as BackupScheduleType;
    }
  }

  public static toString(type: BackupScheduleType): string {
    switch (type) {
      case BackupScheduleType.Hourly:
        return "Hourly";
      case BackupScheduleType.Daily:
        return "Daily";
      case BackupScheduleType.Monthly:
        return "Monthly";
    }
  }

  public static isEmpty(type: BackupScheduleType): boolean {
    switch (type) {
      case BackupScheduleType.Hourly:
        return false;
      case BackupScheduleType.Daily:
        return false;
      case BackupScheduleType.Monthly:
        return false;
      default:
        return true;
    }
  }
}

export interface ICreateBackupPolicyViewArgs extends IBackupPolicyViewArgs {
  createBackupPolicy: boolean;
  areRegionsValid?: () => boolean;
  onClickCancelCreateBackupPolicy: () => void;
  onClickSaveCreateBackupPolicy: () => void;
}

export const CreateBackupPolicyView = ({ ...args }: ICreateBackupPolicyViewArgs) => {
  const has_name = !_.isEmpty(args.name);
  const has_scheduleType = !BackupSchedule.isEmpty(args.schedule_type);
  const [error, setError] = useState("");

  return (
    <Modal
      size="large"
      open={!!args.createBackupPolicy}
      onClose={() => {
        setError("");
        args.onClickCancelCreateBackupPolicy();
      }}
    >
      <Modal.Header>New Backup Policy</Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} onDismiss={() => setError("")} message={error} />
        <BackupPolicyView {...args} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setError("");
            args.onClickCancelCreateBackupPolicy();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          icon="save"
          labelPosition="right"
          content="Create Backup Policy"
          disabled={!(has_name && has_scheduleType)}
          onClick={() => {
            setError("");
            if (args.areRegionsValid && !args.areRegionsValid()) {
              setError("Regions cannot be empty when backup is enabled on multiple regions");
            } else {
              args.onClickSaveCreateBackupPolicy();
              setError("");
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export interface IEditBackupPolicyViewArgs extends IBackupPolicyViewArgs {
  editBackupPolicy: boolean;
  areRegionsValid?: () => boolean;
  onClickCancelEditBackupPolicy: () => void;
  onClickSaveEditBackupPolicy: () => void;
}

export const EditBackupPolicyView = ({ ...args }: IEditBackupPolicyViewArgs) => {
  const has_name = !_.isEmpty(args.name);
  const has_scheduleType = !BackupSchedule.isEmpty(args.schedule_type);
  const [error, setError] = useState("");

  return (
    <Modal
      size="large"
      open={!!args.editBackupPolicy}
      onClose={() => {
        setError("");
        args.onClickCancelEditBackupPolicy();
      }}
    >
      <Modal.Header>Edit Backup Policy</Modal.Header>
      <Modal.Content scrolling>
        <ErrorMessage active={!!error} onDismiss={() => setError("")} message={error} />
        <BackupPolicyView {...args} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setError("");
            args.onClickCancelEditBackupPolicy();
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          icon="save"
          labelPosition="right"
          content="Save"
          disabled={!(has_name && has_scheduleType)}
          onClick={() => {
            setError("");
            if (args.areRegionsValid && !args.areRegionsValid()) {
              setError("Regions cannot be empty when backup is enabled on multiple regions");
            } else {
              args.onClickSaveEditBackupPolicy();
              setError("");
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export interface IBackupPolicyHourlyViewArgs {
  hourlyIntervalHours: number;
  hourlyIntervalMinutes: number;
  updateHourlyIntervalHours: (newValue: number) => void;
  updateHourlyIntervalMinutes: (newValue: number) => void;
  isEditMode?: boolean;
}

const BackupPolicyHourlyView = ({ ...args }: IBackupPolicyHourlyViewArgs) => {
  useEffect(() => {
    args.updateHourlyIntervalHours(args.hourlyIntervalHours);
    args.updateHourlyIntervalMinutes(args.hourlyIntervalMinutes);
  }, []);
  return (
    <FlexBox justify="flex-start">
      <Form.Field>
        <label>Every:</label>
        <NumberInput value={args.hourlyIntervalHours} min={1} max={23} ext=" hours" onChange={args.updateHourlyIntervalHours} />
      </Form.Field>
      <Form.Field className="padding-left-10">
        <label>
          At:{" "}
          {!args.isEditMode && (
            <Popup
              trigger={<Icon name="info circle" />}
              content="The minute(s) value is randomized to avoid multiple backups being triggered at the same time."
            />
          )}
        </label>
        <NumberInput value={args.hourlyIntervalMinutes} min={0} max={59} ext=" min" onChange={args.updateHourlyIntervalMinutes} />
      </Form.Field>
    </FlexBox>
  );
};

export interface IBackupPolicyDailyViewArgs {
  dailyMonday: boolean;
  dailyTuesday: boolean;
  dailyWednesday: boolean;
  dailyThursday: boolean;
  dailyFriday: boolean;
  dailySaturday: boolean;
  dailySunday: boolean;
  toggleDailyDay: (day: string) => void;
  dailyTimeHours: number;
  updateDailyTimeHours: (newValue: number) => void;
  dailyTimeMinutes: number;
  updateDailyTimeMinutes: (newValue: number) => void;
}

const StyledCheckboxes = styled("div")`
  display: inline-flex;
`;

const StyledCheckbox = styled(Form.Checkbox)`
  padding: 5px;
`;

const BackupPolicyDailyView = ({ ...args }: IBackupPolicyDailyViewArgs) => (
  <Grid.Row columns={16}>
    <Grid.Column width={16}>
      <Form.Field>
        <label>Days:</label>
        <StyledCheckboxes>
          <StyledCheckbox label="Mon" onChange={() => args.toggleDailyDay("Monday")} checked={args.dailyMonday} />
          <StyledCheckbox label="Tue" onChange={() => args.toggleDailyDay("Tuesday")} checked={args.dailyTuesday} />
          <StyledCheckbox label="Wed" onChange={() => args.toggleDailyDay("Wednesday")} checked={args.dailyWednesday} />
          <StyledCheckbox label="Thu" onChange={() => args.toggleDailyDay("Thursday")} checked={args.dailyThursday} />
          <StyledCheckbox label="Fri" onChange={() => args.toggleDailyDay("Friday")} checked={args.dailyFriday} />
          <StyledCheckbox label="Sat" onChange={() => args.toggleDailyDay("Saturday")} checked={args.dailySaturday} />
          <StyledCheckbox label="Sun" onChange={() => args.toggleDailyDay("Sunday")} checked={args.dailySunday} />
        </StyledCheckboxes>
      </Form.Field>
      <Form.Field>
        <label>At time: </label>
        <NumberInput value={args.dailyTimeHours} min={0} max={23} ext=" hr" onChange={args.updateDailyTimeHours} />
        &nbsp;
        <NumberInput value={args.dailyTimeMinutes} min={0} max={59} ext=" min" onChange={args.updateDailyTimeMinutes} />
      </Form.Field>
    </Grid.Column>
  </Grid.Row>
);

export interface IBackupPolicyMonthlyViewArgs {
  monthlyDay: number;
  updateMonthlyDay: (newValue: number) => void;
  monthlyTimeHours: number;
  updateMonthlyTimeHours: (newValue: number) => void;
  monthlyTimeMinutes: number;
  updateMonthlyTimeMinutes: (newValue: number) => void;
}

const BackupPolicyMonthlyView = ({ ...args }: IBackupPolicyMonthlyViewArgs) => (
  <Grid.Row columns={16}>
    <Grid.Column width={16}>
      <Form.Field>
        <label>Day of month:</label>
        <NumberInput value={args.monthlyDay} min={0} max={31} onChange={args.updateMonthlyDay} />
      </Form.Field>
      <Form.Field>
        <label>At time:</label>
        <NumberInput value={args.monthlyTimeHours} min={0} max={23} ext=" hr" onChange={args.updateMonthlyTimeHours} />
        &nbsp;
        <NumberInput value={args.monthlyTimeMinutes} min={0} max={59} ext=" min" onChange={args.updateMonthlyTimeMinutes} />
      </Form.Field>
    </Grid.Column>
  </Grid.Row>
);

export interface IBackupPolicyViewArgs extends IBackupPolicyHourlyViewArgs, IBackupPolicyDailyViewArgs, IBackupPolicyMonthlyViewArgs {
  editBackupPolicy?: boolean;
  name: string;
  updateName: (newValue: string) => void;
  description: string;
  updateDescription: (newValue: string) => void;
  hasRetentionPeriodUpload: boolean;
  toggleHasRetentionPeriodUpload: () => void;
  retentionPeriodUploadInDays: number;
  updateRetentionPeriodUploadInDays: (newValue: number) => void;
  retentionPeriodNoUploadInHours: number;
  updateRetentionPeriodNoUploadInHours: (newValue: number) => void;
  isBackupUploadFeatureAvailable: boolean;
  upload: boolean;
  toggleUpload: () => void;
  is_paused: boolean;
  toggleIsPaused: () => void;
  email_notification: BackupNotificationType;
  updateEmailNotification: (newValue: BackupNotificationType) => void;
  schedule_type: BackupScheduleType;
  updateScheduleType: (newValue: BackupScheduleType) => void;
  locked: boolean;
  onLockedChanged: () => void;
  onMultiRegionSelection: (data: string[]) => void;
  isMultiRegionBackupEnabled?: boolean;
  additionalRegions: string[];
  toggleRegionDropdown: (enabled: boolean) => void;
  checkedMultipleRegionBackup: boolean;
}

const StyledMultiRegionDropdown = styled(Form.Dropdown)`
  & > .dropdown .label {
    background-color: white !important;
    padding: 12px 11px !important;
  }
`;

const BackupPolicyView = ({ ...args }: IBackupPolicyViewArgs) => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const { additionalRegions } = args;
    if (additionalRegions.length) {
      args.toggleRegionDropdown(true);
      reloadRegions();
    }

    if (args.checkedMultipleRegionBackup) {
      reloadRegions();
    }
  }, []);

  const reloadRegions = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const provider = useDeploymentStore.getState().region;
      const organization = useGlobalStore.getState().organization;
      const listReq: ListRegionsRequest = { provider_id: provider.provider_id, organization_id: organization.id };
      const regions = await apiClients.platformClient.ListRegions(listReq);
      const availableRegions = regions && regions.items ? regions.items.filter((x) => x.available) : [];
      setRegions(availableRegions.filter((region: Region) => region.id !== provider.id));
      const { additionalRegions } = args;
      const selectedRegions = availableRegions.filter((region) => additionalRegions.includes(region.id || "")).map((reg) => reg.location) || [];
      setSelectedRegions(selectedRegions as string[]);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form>
      <Section>
        <SectionHead>
          <SectionHeader title="General" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Form.Input
                  autoFocus
                  required
                  label="Name"
                  placeholder='Name (e.g. "before upgrade")'
                  name="name"
                  value={args.name}
                  onChange={(e: any, id: InputOnChangeData) => {
                    args.updateName(id.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Input
                  label="Short description"
                  placeholder="Description"
                  name="description"
                  value={args.description}
                  onChange={(e: any, id: InputOnChangeData) => {
                    args.updateDescription(id.value);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Schedule" />
          <SectionButtons>
            <Button type="button" onClick={() => args.updateScheduleType(BackupScheduleType.Hourly)} primary={args.schedule_type == BackupScheduleType.Hourly}>
              Hourly
            </Button>
            <Button type="button" onClick={() => args.updateScheduleType(BackupScheduleType.Daily)} primary={args.schedule_type == BackupScheduleType.Daily}>
              Daily
            </Button>
            <Button
              type="button"
              onClick={() => args.updateScheduleType(BackupScheduleType.Monthly)}
              primary={args.schedule_type == BackupScheduleType.Monthly}
            >
              Monthly
            </Button>
          </SectionButtons>
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                {args.schedule_type != BackupScheduleType.Hourly &&
                  args.schedule_type != BackupScheduleType.Daily &&
                  args.schedule_type != BackupScheduleType.Monthly && <span>Please select schedule interval first.</span>}
                {args.schedule_type == BackupScheduleType.Hourly && <BackupPolicyHourlyView {...args} isEditMode={args.editBackupPolicy} />}
                {args.schedule_type == BackupScheduleType.Daily && <BackupPolicyDailyView {...args} />}
                {args.schedule_type == BackupScheduleType.Monthly && <BackupPolicyMonthlyView {...args} />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>

      <Section>
        <SectionHead>
          <SectionHeader title="Options" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row columns={16}></Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={8}>
                <Form.Checkbox
                  disabled={!args.isBackupUploadFeatureAvailable}
                  toggle
                  label="Upload backup to cloud storage"
                  onChange={args.toggleUpload}
                  checked={args.upload}
                />
                <Footnote>
                  When a backup is uploaded to cloud storage it is preserved for a very long time and does not occupy any disk space on the servers.
                  {args.isMultiRegionBackupEnabled && (
                    <>
                      <b>This will also allow copying the backup to different regions and it can be configured in the section below.</b>
                      <Divider hidden />
                    </>
                  )}
                </Footnote>
                <Form.Checkbox toggle label="Is paused" onChange={args.toggleIsPaused} checked={args.is_paused} />
                <Footnote>When a backup policy is paused, no backups are created for it.</Footnote>
              </Grid.Column>
              {args.upload && (
                <Grid.Column width={8}>
                  <Form.Checkbox toggle label="Has retention period" onChange={args.toggleHasRetentionPeriodUpload} checked={args.hasRetentionPeriodUpload} />
                  <NumberInput
                    disabled={!args.hasRetentionPeriodUpload}
                    value={args.retentionPeriodUploadInDays}
                    min={1}
                    max={twentyYearsInDays}
                    ext=" days"
                    onChange={args.updateRetentionPeriodUploadInDays}
                  />
                </Grid.Column>
              )}
              {!args.upload && (
                <Grid.Column width={8}>
                  <Form.Checkbox toggle label="Has retention period" checked={true} disabled={true} />
                  <Footnote>Retention period specifies how long backups (created from this policy) are retained after creation.</Footnote>
                  <NumberInput value={args.retentionPeriodNoUploadInHours} min={1} max={6} ext=" hours" onChange={args.updateRetentionPeriodNoUploadInHours} />
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                <Form.Checkbox label="Locked" checked={args.locked} onChange={args.onLockedChanged} toggle />
                <Footnote>Locked policies cannot be deleted.</Footnote>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>

      {args.isMultiRegionBackupEnabled && args.upload && (
        <Section>
          <ErrorMessage active={!!error} message={error} />
          <SectionHead>
            <SectionHeader title="Multiple region backup" />
          </SectionHead>
          <SectionContent>
            <Grid columns={16}>
              <Grid.Row>
                <Grid.Column width="16">
                  <Form.Checkbox
                    checked={args.checkedMultipleRegionBackup}
                    disabled={!args.upload}
                    label="Backup in multiple regions"
                    onChange={() => {
                      !args.checkedMultipleRegionBackup && reloadRegions();
                      args.toggleRegionDropdown(!args.checkedMultipleRegionBackup);
                    }}
                    toggle
                  />
                  <Footnote>When this is enabled, backups can be created in multiple regions apart from the default one.</Footnote>
                  <Footnote>This is possible only when the upload to cloud storage is enabled.</Footnote>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  {loading ? <Loading /> : null}
                  {!loading && args.checkedMultipleRegionBackup && regions.length ? (
                    <StyledMultiRegionDropdown
                      width={16}
                      label="Regions"
                      required
                      scrolling
                      selection
                      disabled={!args.upload}
                      fluid
                      name="additional_region_ids"
                      value={selectedRegions}
                      multiple
                      placeholder="Select multiple regions"
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        const value = data.value as string[];
                        const selectedRegions = value
                          .map((v) => regions.find((region) => v === region.location))
                          .map((region) => (region || {}).id)
                          .filter((region) => region);
                        args.onMultiRegionSelection(selectedRegions as string[]);
                        setSelectedRegions(value);
                      }}
                      options={regions.map((region) => {
                        return {
                          key: region.id,
                          text: createRegionName(region),
                          value: region.location,
                          icon: !!region.out_of_stock ? "warning sign" : !!region.low_stock ? "warning" : "",
                          className: !!region.out_of_stock ? "red-text" : "",
                          description: !!region.out_of_stock ? "Out of stock" : !!region.low_stock ? "Low on stock" : "",
                        };
                      })}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
      )}
      <Section>
        <SectionHead>
          <SectionHeader title="Notifications" />
        </SectionHead>
        <SectionContent>
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={6}>
                <Form.Dropdown
                  required
                  fluid
                  selection
                  label="Email notification"
                  placeholder="Select email notification"
                  name="email_notification"
                  value={args.email_notification}
                  onChange={(e: any, data: DropdownProps) => {
                    args.updateEmailNotification(data.value as BackupNotificationType);
                  }}
                  options={BackupNotification.values().map((bnt) => {
                    return { key: bnt, text: BackupNotification.toText(bnt), value: bnt };
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
    </Form>
  );
};
