import { useQuery } from "@tanstack/react-query";
import apiClients from "./api/apiclients";

export const useFetchCurrentUser = () => {
  const fetchCurrentUser = async () => {
    return apiClients.iamClient.GetThisUser();
  };

  return useQuery(["currentUser"], fetchCurrentUser);
};
