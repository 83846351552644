//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { ConfirmInfo } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { HistoryHelper } from "../HistoryHelper";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

interface OrganizationDetailsContextType {
  errorMessage?: string;
  processingLock: boolean;
  processingUnlock: boolean;
  processingDelete: boolean;
  confirmInfo?: ConfirmInfo;
  isProcessing: boolean;
  handleDismissError: () => void;
  onError: (message?: string) => void;
  onClickDelete: () => void;
  onLockOrganization: () => void;
  onUnlockOrganization: () => void;
}
const OrganizationDetailsContext = React.createContext<OrganizationDetailsContextType>({} as OrganizationDetailsContextType);
export const useOrganizationDetailsContext = () => React.useContext(OrganizationDetailsContext);

export const OrganizationDetailsContextProvider = (props: {
  children: React.ReactNode;
  onReloadOrganization: () => void;
  onOrganizationDeleted: (id: string) => void;
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [processingLock, setProcessingLock] = React.useState<boolean>(false);
  const [processingUnlock, setProcessingUnlock] = React.useState<boolean>(false);
  const [processingDelete, setProcessingDelete] = React.useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = React.useState<ConfirmInfo | undefined>(undefined);
  const isProcessing = processingDelete || processingLock || processingUnlock;
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const history = useHistory();
  const { subscribeUrl } = useWithRefresh();

  const reloadOrganizationInfo = async () => {
    const idOptions = { id: selectedOrganization.id };
    const organization = await apiClients.resourceManagerClient.GetOrganization(idOptions);
    useGlobalStore.getState().setOrganization(organization);
  };

  const getOrganizationName = () => {
    return selectedOrganization ? selectedOrganization.name : "";
  };

  const onClickDelete = async () => {
    const organizationName = getOrganizationName();
    const confirmInfo = {
      header: "Delete Organization",
      content: `Are you sure you want to delete organization '${organizationName}'?`,
      warning: "This implies deletion of the projects and deployments in this project and all data stored in the database!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: () => onClickDeleteConfirmed(),
      onDenied: () => setConfirmInfo(undefined),
    } as ConfirmInfo;

    setConfirmInfo(confirmInfo);
  };

  const onClickDeleteConfirmed = async () => {
    try {
      setProcessingDelete(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      await apiClients.resourceManagerClient.DeleteOrganization({
        id: selectedOrganization.id,
      });

      props.onOrganizationDeleted(selectedOrganization.id || "");
    } catch (e) {
      setErrorMessage(`Organization deletion failed: ${e}`);
      reportError(e);
    }
    setProcessingDelete(false);
  };

  const onLockOrganization = async () => {
    try {
      setProcessingLock(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      const response = await apiClients.resourceManagerClient.UpdateOrganization({
        ...selectedOrganization,
        locked: true,
      });
      useGlobalStore.getState().setOrganization(response);
    } catch (e) {
      setErrorMessage(`Locking organization failed: ${e}`);
      reportError(e);
    }
    setProcessingLock(false);
  };

  const onUnlockOrganization = async () => {
    try {
      setProcessingUnlock(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      const response = await apiClients.resourceManagerClient.UpdateOrganization({
        ...selectedOrganization,
        locked: false,
      });
      useGlobalStore.getState().setOrganization(response);
    } catch (e) {
      setErrorMessage(`Unlocking organization failed: ${e}`);
      reportError(e);
    }
    setProcessingUnlock(false);
  };

  const subscribe = () => {
    subscribeUrl?.(reloadOrganizationInfo, `${selectedOrganization.url}`);
  };
  const location = useLocation();
  useEffect(() => {
    updateTopMenu();
  }, [location]);
  useEffect(() => {
    subscribe();
    updateTopMenu();
  }, [selectedOrganization]);

  const handleDismissError = () => {
    setErrorMessage(undefined);
  };

  const updateTopMenu = () => {
    topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(history));

    topMenuInfo.clearBreadCrumb();
    topMenuInfo.setImageSource("organization");
  };
  const onUpdateNameAndDescription = async (name: string, description: string) => {
    // Note that we do not try/catch here, since that is handled in the top menu.
    await apiClients.resourceManagerClient.UpdateOrganization({ ...selectedOrganization, name, description });
    props.onReloadOrganization();
  };
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const isUpdateAllowed = checkOrgPermission("resourcemanager.organization.update");

  useEffect(() => {
    topMenuInfo.setTitles(selectedOrganization.name || "", selectedOrganization.description || "", isUpdateAllowed ? onUpdateNameAndDescription : undefined);
  }, [selectedOrganization, location]);

  const onError = (message?: string) => {
    setErrorMessage(message);
  };
  return (
    <OrganizationDetailsContext.Provider
      value={{
        errorMessage,
        processingLock,
        processingUnlock,
        processingDelete,
        confirmInfo,
        isProcessing,
        handleDismissError,
        onError,
        onClickDelete,
        onLockOrganization,
        onUnlockOrganization,
      }}
    >
      {props.children}
    </OrganizationDetailsContext.Provider>
  );
};
