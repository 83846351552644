//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { Divider, Header, List, Message } from "semantic-ui-react";

const StyledEmptyMessage = styled(Message)`
  background: var(--gray-50) !important;
  box-shadow: 0 0 6px 0px inset var(--gray-50) !important;
  border: 1px solid var(--gray-200) !important;
  color: var(--gray-900) !important;
  line-height: 1.8 !important;
  margin-top: 20px !important;
  opacity: 0.9;
`;

const EmptyPrincipalMessage = () => {
  return (
    <>
      <StyledEmptyMessage>
        <Header as="h4" disabled data-testid="emptyPrincipalMessageHeader">
          To add new principles
        </Header>
        <Divider />
        <List size="mini" as="ul">
          <List.Item as="li" data-testid="emptyPrincipalAccountIDMessage">
            Add an account ID which is your 12 digit AWS account ID.
          </List.Item>
          <List.Item as="li" data-testid="emptyPrincipalUsernamesMessage">
            Adding usernames is optional. If you want to add them, add each username in a separate line in the username textbox.
          </List.Item>
          <List.Item as="li" data-testid="emptyPrincipalRoleNamesMessage">
            Adding role names is optional. If you want to add them, add each role name in a separate line in the role name textbox.
          </List.Item>
        </List>
        <Divider hidden />
        <span data-testid="emptyPrincipalSkipMessage">You can also skip this now and add principal(s) later from the summary view</span>
      </StyledEmptyMessage>
    </>
  );
};
export default EmptyPrincipalMessage;
