//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Header, Icon, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import { AppLinkButton } from "../../../ui/_link";
import { WHAT_IS_PRIVATE_LINK, AWS_CREATE_ENDPOINT_SERVICE } from "../privateNetworkURLs";
import { IPrivateNetworkHelpView } from "../types";

const AWSPrivateNetworkHelpView = ({ onClose, onSupportClick }: IPrivateNetworkHelpView) => {
  return (
    <Modal open data-testid="awsprivateNetworkHelpModal">
      <Modal.Header data-testid="awsprivateNetworkHelpModalHeader">Here's what you can refer</Modal.Header>
      <Modal.Content>
        <Modal.Description data-testid="awsprivateNetworkHelpModalDescription">
          <Message>
            This deployment has been configured with a Private endpoint. That means that all traffic to and from your deployment go through a private endpoint
            "tunnel" inside Amazon VPC.
          </Message>
          <Header as="h3">To understand more, refer the following links:</Header>
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="About private link" href={WHAT_IS_PRIVATE_LINK} target="_blank" />
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="Connect to an endpoint" href={AWS_CREATE_ENDPOINT_SERVICE} target="_blank" />
          <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="awsprivateNetworkHelpModalClose" />
        <FormActionButtonSave
          testID="awsprivateNetworkHelpModalSupportButton"
          primary
          icon="help"
          title="Request Help"
          onClick={() => {
            onClose();
            onSupportClick && onSupportClick();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AWSPrivateNetworkHelpView;
