//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Modal, Form, Icon, Divider } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { FlexBox } from "../../ui/_flex";
import { HeaderCaption } from "../../ui/_style";
import EmailListView from "./NotificationEmails";

const StyledInputIcon = styled(Icon)`
  color: var(--green-600) !important;
  background-color: var(--gray-50) !important;
  cursor: pointer !important;
  pointer-events: all !important;
  &:hover {
    background-color: var(--gray-200) !important;
  }
`;

const InputWithIcon = styled(Form.Input)`
  & > div > input:focus + .add-icon {
    border-bottom: 1px solid var(--green-700) !important;
  }
`;

const StyledInfo = styled.span`
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  position: absolute;
  top: 70px;
`;

interface IAddEmailAddressesViewArgs {
  onSave: (emailList: Array<string>) => void;
  onCancel: () => void;
  onExistingEmailAddition?: (email: string) => void;
  existingEmailAddresses: string[];
  loading?: boolean;
}

const AddEmailAddressesView = ({ onSave, onCancel, onExistingEmailAddition, loading, existingEmailAddresses }: IAddEmailAddressesViewArgs) => {
  const [email, setEmail] = useState<string>("");
  const [emailList, setEmailList] = useState<Array<string>>([]);
  const [isFormValid, setIsFormValid] = useState<boolean | null | undefined>(true);
  const [statusMessage, setStatusMessage] = useState<string>("");

  useEffect(() => {
    setEmailList([]);
    setEmail("");
  }, []);

  useEffect(() => {
    setStatusMessage("");
    !email && setIsFormValid(true);
  }, [email]);

  const handleAddEmail = (isValid: boolean | null | undefined) => {
    if (existingEmailAddresses.includes(email)) {
      onExistingEmailAddition && onExistingEmailAddition(email);
      setStatusMessage("Email address already exists");
      return;
    }

    if (emailList.includes(email)) {
      setStatusMessage("Email address already exists");
      return;
    }
    if (email && isValid) {
      setEmailList([...emailList, email]);
      setStatusMessage("");
      setEmail("");
    }
  };

  const handleRemoveEmail = (email: string) => {
    setEmailList(emailList.filter((e) => e !== email));
  };

  const isValidEmailSet = (value: string) => {
    if (!value) {
      return true;
    }
    const regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const email = value.toString().toLowerCase();
    return regex.test(email);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isValid = isValidEmailSet(email);
    if (e.key === "Enter") {
      if (!isValid) {
        setStatusMessage("Email address is invalid");
        setIsFormValid(isValid);
        return;
      }
      setIsFormValid(isValid);
      handleAddEmail(isValid);
    }
  };

  return (
    <Modal open size="mini">
      <Modal.Header data-testid="addEmailHeader">
        Add e-mail addresses
        <HeaderCaption data-testid="addEmailCaption">Configure e-mail addresses to receive notifications</HeaderCaption>
      </Modal.Header>
      <Modal.Content scrolling>
        <Form data-testid="addEmailForm">
          <Form.Field
            className="notification-email-field"
            control={InputWithIcon}
            autoFocus={true}
            label="E-mail"
            data-testid="addEmailModalInput"
            icon={
              isFormValid ? (
                <StyledInputIcon className="add-icon" name="add" onClick={() => handleAddEmail(isValidEmailSet(email))} />
              ) : (
                <Icon name="warning circle" color="red" className="error-icon" />
              )
            }
            placeholder="Start typing e-mail addresses"
            value={email}
            onKeyPress={handleKeyPress}
            onBlur={() => {
              setIsFormValid(isValidEmailSet(email));
            }}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            autoComplete="off"
            error={!isFormValid}
          />
          {!!statusMessage && <StyledInfo data-testid="modalStatusMessage">{statusMessage}</StyledInfo>}
        </Form>

        <Divider hidden />

        <EmailListView emails={emailList} onRemove={handleRemoveEmail} showIcon emptyMessage="No e-mail addresses added." />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <FlexBox direction="row-reverse"> </FlexBox>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onCancel} disabled={loading} data-testid="addEmailModalCancelAction" />
        <FormActionButtonSave
          primary
          onClick={() => onSave(emailList)}
          disabled={loading || !emailList.length}
          loading={loading}
          data-testid="addEmailModalSaveAction"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddEmailAddressesView;
