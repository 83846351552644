//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FormButton } from "@arangodb/ui";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { EditableTitleForm } from "./EditableTitleForm";
interface TitleSubtitleFormValues {
  title: string;
  subTitle: string;
}

const EditableTitleModal = ({
  title,
  subTitle,
  onClose,
  onSave,
}: {
  title: string;
  subTitle: string;
  onClose: () => void;
  onSave: (title: string, subTitle: string) => Promise<void>;
}) => {
  const toast = useToast();

  const onSubmit = async (values: TitleSubtitleFormValues) => {
    const { title, subTitle } = values;
    try {
      await onSave(title, subTitle);

      toast({
        title: "Title updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (e) {
      toast({
        title: "Failed to update title",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Formik<TitleSubtitleFormValues>
      initialValues={{
        title,
        subTitle,
      }}
      validationSchema={Yup.object({
        title: Yup.string().required("Required"),
        subTitle: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      <Modal isOpen={true} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent as={Form}>
          <ModalHeader>New certificate</ModalHeader>
          <ModalBody>
            <EditableTitleForm />
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onClose}>Cancel</Button>
            <FormButton colorScheme="green">Save</FormButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Formik>
  );
};

export default EditableTitleModal;
