//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useContext, useEffect, useState } from "react";
import { Divider, Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { textToStrings } from "../../../api/_util";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import { ErrorMessage } from "../../../ui/_errorMessage";
import { IWizardContext, WizardContext } from "../../wizard/Wizard";
import { IProjectNamesModalViewArgs, IProjectNamesView } from "../types";

const ProjectNamesView = ({ onChange, projects = [], onUpdateFieldValidity, disableChange = false, statusMessage }: IProjectNamesView) => {
  const [projectNames, setProjectNames] = useState<string[]>(projects);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);

  const context = useContext<IWizardContext>(WizardContext);

  const isFormValid = (names: string[]) => {
    if (!names || !names.length) {
      return true;
    }

    const regex = /^[a-z]([-a-z0-9A-Z]*[a-z0-9A-Z])?$/;
    return names.every((projectName) => regex.test(projectName));
  };

  useEffect(() => {
    const { existingState } = context;
    const { data = undefined } = existingState || {};
    const { updateWizardState } = context;
    setProjectNames(data || projects);
    updateWizardState &&
      updateWizardState({
        isValid: isFormValid(projectNames),
        data: projectNames.filter((projectNames) => !!projectNames),
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (disableChange) return;

    const { value } = event.target;
    const projects = textToStrings(value);
    setProjectNames(projects);
    onChange && onChange(projects);
  };

  const handleBlur = () => {
    onUpdateFieldValidity && onUpdateFieldValidity(isFormValid(projectNames));
    setIsValueValid(isFormValid(projectNames));

    const { updateWizardState } = context;
    updateWizardState &&
      updateWizardState({
        isValid: isFormValid(projectNames),
        data: projectNames.filter((projectNames) => !!projectNames),
      });
  };

  return (
    <>
      <Message data-testid="projectNamesInfo" warning={disableChange}>
        {!disableChange ? (
          <>
            Please provide the Google project names.
            <div data-testid="projectNameSkipMessage">You can also skip this now and add them later from the summary view</div>
          </>
        ) : (
          <span>
            <Grid>
              <Grid.Column width="1" verticalAlign="middle" textAlign="center">
                <Icon name="warning circle" />
              </Grid.Column>
              <Grid.Column width="15">{statusMessage}</Grid.Column>
            </Grid>
          </span>
        )}
      </Message>
      <Divider hidden />
      <Form>
        <Form.TextArea
          error={!isValueValid}
          data-testid="gcpProjectNamesField"
          label="Google Project Names (1 per line)"
          placeholder="Enter project Names"
          onChange={handleChange}
          value={projectNames.join("\n")}
          onBlur={handleBlur}
        />

        <Form.TextArea
          data-testid="projectNamesExample"
          label="Example for project names addition"
          value={["some-new-name", "some-another-project"].join("\n")}
        />
      </Form>
    </>
  );
};

export default ProjectNamesView;

const ProjectNamesModalView = ({ onClose, onChange, onSave, projects = [], disableChange, statusMessage }: IProjectNamesModalViewArgs) => {
  const [projectNames, setProjectNames] = useState<string[]>(projects);
  const [isValid, updateFieldState] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(undefined);

  const handleChange = (projects: string[]): void => {
    setProjectNames(projects);
    onChange && onChange(projects);
  };

  const handleSave = async () => {
    setLoading(true);
    const { error } = (await onSave(projectNames)) || {};
    setLoading(false);
    !!error && setError(error);
    if (!error && !loading) onClose();
  };

  return (
    <Modal open onClose={onClose} data-testid="privateNetworkProjectNamesViewModal">
      <Modal.Header>Google project names</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} />
          <ProjectNamesView
            onChange={handleChange}
            projects={projectNames}
            onUpdateFieldValidity={(state: boolean) => {
              updateFieldState(state);
            }}
            disableChange={disableChange}
            statusMessage={statusMessage}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} testID="cancelProjectNamesModal" />
        <FormActionButtonSave primary disabled={!isValid || disableChange} loading={loading} onClick={handleSave} icon="save" testID="saveProjetNamesAction" />
      </Modal.Actions>
    </Modal>
  );
};

export { ProjectNamesModalView };
