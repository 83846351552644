//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
// Ewout Prangsma
//

import styled from "@emotion/styled";
import React from "react";
import { Button, Icon, Loader, Menu } from "semantic-ui-react";
import { DateTimeInput, SimplePagingButtons } from "../../ui/lib";
import { AuditLogTopic as ApiAuditLogTopic } from "../../api/lib";
import { EditExcludedTopicsView } from "./EditExcludedTopicsViews";

const TopicsContainer = styled("div")`
  max-width: 50vw;
`;

const StyledMenu = styled(Menu)`
  .refresh {
    opacity: 0.2;
    border: none !important;
    padding: 0 !important;
  }
  :hover {
    .refresh {
      opacity: 1;
    }
  }
`;

const LastMenuItem = styled(Menu.Item)`
  min-width: 65px;
`;

interface IEventFilterViewArgs {
  loading: boolean;
  hasNext: boolean;
  page: number;
  onRefresh: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;

  from?: string;
  to?: string;
  onTimeRangeChange: (from: string, to: string) => void;

  all_topics: ApiAuditLogTopic[];
  excluded_topics: string[];
  onExcludedTopicsChanged: (value: string[]) => void;
}

export const EventFilterView = ({ ...args }: IEventFilterViewArgs) => {
  const showRefresh = !args.loading && !args.hasNext && args.page == 0;
  return (
    <StyledMenu>
      <Menu.Item header>Filter</Menu.Item>
      <Menu.Item>
        <Icon name="clock outline" />
        <DateTimeInput {...args} onChange={args.onTimeRangeChange} />
      </Menu.Item>
      <Menu.Item>
        <TopicsContainer>
          <EditExcludedTopicsView {...args} empty_text="All topics" non_empty_text="All topics except" />
        </TopicsContainer>
      </Menu.Item>
      <LastMenuItem position="right" fitted="vertically">
        {args.loading && <Loader active size="small" />}
        {showRefresh && <Button icon="refresh" basic onClick={args.onRefresh} size="tiny" className="refresh" />}
        <SimplePagingButtons {...args} size="tiny" disabled={args.loading} />
      </LastMenuItem>
    </StyledMenu>
  );
};
