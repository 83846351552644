//
// This file is AUTO-GENERATED by protoc-gen-ts.
// Do not modify it manually.
///
import api from '../../api'
import * as googleTypes from '../../googleTypes'
import { Empty as arangodb_cloud_common_v1_Empty } from '../../common/v1/common'
import { Version as arangodb_cloud_common_v1_Version } from '../../common/v1/common'

// File: api/v1/api.proto
// Package: arangodb.cloud.dataloader.v1
export interface Datasource {
  // string
  id?: string;
  
  // string
  name?: string;
  
  // googleTypes.Timestamp
  creation_date?: googleTypes.Timestamp;
  
  // googleTypes.Timestamp
  modification_date?: googleTypes.Timestamp;
  
  // possible values: CSV
  // string
  type?: string;
  
  // string
  data_file_name?: string;
  
  // DatasourceField
  fields?: DatasourceField[];
}
export interface DatasourceByIdRequest {
  // string
  datasource_id?: string;
}
export interface DatasourceField {
  // string
  id?: string;
  
  // string
  name?: string;
  
  // possible values: string, numeric, boolean
  // string
  data_type?: string;
  
  // flag that indicates that the field is a constant(default value provided by user manually)
  // boolean
  is_constant?: boolean;
  
  // value for the constant field
  // string
  constant_value?: string;
  
  // field name in the source(column name in csv file)
  // string
  source_field_name?: string;
}
export interface DatasourceList {
  // Datasource
  datasources?: Datasource[];
}
export interface Edges {
  // OneToManyEdge
  one_to_many?: OneToManyEdge[];
  
  // ManyToManyEdge
  many_to_many?: ManyToManyEdge[];
}
export interface Graph {
  // string
  id?: string;
  
  // string
  name?: string;
  
  // googleTypes.Timestamp
  creation_date?: googleTypes.Timestamp;
  
  // googleTypes.Timestamp
  modification_date?: googleTypes.Timestamp;
  
  // Vertex
  vertices?: Vertex[];
  
  // Edges
  edges?: Edges;
}
export interface GraphByIdRequest {
  // string
  graph_id?: string;
}
export interface GraphGenerateRequest {
  // string
  graph_id?: string;
  
  // if true, and the graph with the same id was previously generated, the new generation will earse all existing data and regenerate the graph from the source data.
  // boolean
  override?: boolean;
}
export interface GraphList {
  // Graph
  graphs?: Graph[];
}

// used to preview the data from datasource
export interface KeyValuePair {
  // string
  name?: string;
  
  // string
  value?: string;
}
export interface LoadGraphJob {
  // string
  id?: string;
  
  // snapshot of the graph at the time when the job is created
  // Graph
  graph_snapshot?: Graph;
  
  // Datasource
  datasources?: Datasource[];
  
  // googleTypes.Timestamp
  start_date?: googleTypes.Timestamp;
  
  // googleTypes.Timestamp
  finish_date?: googleTypes.Timestamp;
  
  // in progress or failed os success
  // string
  status?: string;
  
  // LoadGraphJobError
  error?: LoadGraphJobError;
}
export interface LoadGraphJobError {
  // string
  error_message?: string;
}
export interface LoadGraphJobStatusRequest {
  // string
  job_id?: string;
}

// represents the edge data for which(_from and _to) come from the datasource.
// This is an equivalent to many to many relationship in relation DB
export interface ManyToManyEdge {
  // string
  id?: string;
  
  // string
  from_vertex_id?: string;
  
  // string
  datasource_id?: string;
  
  // string
  from_datasource_field_id?: string;
  
  // string
  to_vertex_id?: string;
  
  // string
  to_datasource_field_id?: string;
  
  // EG: we have two vertices MOVIE and PERSON, we may want to have an edge between them RATES, and that will tell us that user have rated a particular movie.
  // string
  relationship?: string;
}

// represents the edge that connect two vertices using the fields in vertices.
// This is an equivalent to foreign keys in relational DB
export interface OneToManyEdge {
  // string
  id?: string;
  
  // VertexFieldIdentifier
  from_vertex_field?: VertexFieldIdentifier;
  
  // VertexFieldIdentifier
  to_vertex_field?: VertexFieldIdentifier;
  
  // EG: we have two vertices MOVIE and PERSON, we may want to have an edge between them RATES, and that will tell us that user have rated a particular movie.
  // string
  relationship?: string;
}
export interface SampleDatasourceResponse {
  // KeyValuePair
  rows?: KeyValuePair[];
}
export interface SizeQuotaResponse {
  // The size quota limit in bytes. Shows how much disk space is availible for the user.
  // number
  limit?: number;
}
export interface Vertex {
  // string
  id?: string;
  
  // string
  name?: string;
  
  // string
  datasource_id?: string;
  
  // VertexField
  data?: VertexField[];
}

// Uniquely identifies a field in datasource.
export interface VertexField {
  // string
  vertex_field_id?: string;
  
  // string
  datasource_field_id?: string;
  
  // this allows us to override the name from the datasource in case same datasource used for different vertices,
  // and the field in the vertex needs to be named differently
  // string
  name?: string;
  
  // Shows whether the field maps to _key in DB. Can be true only for the Vertices. For edges must be always false.
  // boolean
  is_key?: boolean;
}

// identifies the filed in vertex for the edge for one-to-many edges
export interface VertexFieldIdentifier {
  // string
  vertex_id?: string;
  
  // string
  vertex_field_id?: string;
}
export interface IDataLoaderService {
  // GetVersion returns current running API-s version
  GetVersion: (req?: arangodb_cloud_common_v1_Empty) => Promise<arangodb_cloud_common_v1_Version>;
  
  // List of availible datasources
  ListDatasources: (req?: arangodb_cloud_common_v1_Empty) => Promise<DatasourceList>;
  
  // updates the datasource
  UpdateDatasource: (req: Datasource) => Promise<Datasource>;
  
  // get datasource by name
  GetDatasource: (req: DatasourceByIdRequest) => Promise<Datasource>;
  
  // delete datasource
  DeleteDatasource: (req: DatasourceByIdRequest) => Promise<void>;
  
  // samples the data from the datasource
  SampleDatasourceData: (req: DatasourceByIdRequest) => Promise<SampleDatasourceResponse>;
  
  // Get size quota
  GetSizeQuota: (req?: arangodb_cloud_common_v1_Empty) => Promise<SizeQuotaResponse>;
  
  // List of availible graphs
  ListGraphs: (req?: arangodb_cloud_common_v1_Empty) => Promise<GraphList>;
  
  // creates the graph
  CreateGraph: (req: Graph) => Promise<Graph>;
  
  // updates the graph
  UpdateGraph: (req: Graph) => Promise<Graph>;
  
  // deletes graph
  DeleteGraph: (req: GraphByIdRequest) => Promise<void>;
  
  // get graph by id
  GetGraph: (req: GraphByIdRequest) => Promise<Graph>;
  
  // triggers the process of loading data and creation of the graph in arango.
  LoadDataGraph: (req: GraphGenerateRequest) => Promise<LoadGraphJob>;
  
  // get the status for the graph data load job
  LoadJobStatus: (req: LoadGraphJobStatusRequest) => Promise<LoadGraphJob>;
}
export class DataLoaderService implements IDataLoaderService {
  // GetVersion returns current running API-s version
  async GetVersion(req?: arangodb_cloud_common_v1_Empty): Promise<arangodb_cloud_common_v1_Version> {
    const path = `/api/data/v1/api-version`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // List of availible datasources
  async ListDatasources(req?: arangodb_cloud_common_v1_Empty): Promise<DatasourceList> {
    const path = `/api/data/v1/datasources`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // updates the datasource
  async UpdateDatasource(req: Datasource): Promise<Datasource> {
    const url = `/api/data/v1/datasources`;
    return api.put(url, req);
  }
  
  // get datasource by name
  async GetDatasource(req: DatasourceByIdRequest): Promise<Datasource> {
    const path = `/api/data/v1/datasources/${encodeURIComponent(req.datasource_id || '')}`;
    const url = path + api.queryString(req, [`datasource_id`]);
    return api.get(url, undefined);
  }
  
  // delete datasource
  async DeleteDatasource(req: DatasourceByIdRequest): Promise<void> {
    const path = `/api/data/v1/datasources/${encodeURIComponent(req.datasource_id || '')}`;
    const url = path + api.queryString(req, [`datasource_id`]);
    return api.delete(url, undefined);
  }
  
  // samples the data from the datasource
  async SampleDatasourceData(req: DatasourceByIdRequest): Promise<SampleDatasourceResponse> {
    const path = `/api/data/v1/datasources/${encodeURIComponent(req.datasource_id || '')}/sample`;
    const url = path + api.queryString(req, [`datasource_id`]);
    return api.get(url, undefined);
  }
  
  // Get size quota
  async GetSizeQuota(req?: arangodb_cloud_common_v1_Empty): Promise<SizeQuotaResponse> {
    const path = `/api/data/v1/datasources/sizeQuota`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // List of availible graphs
  async ListGraphs(req?: arangodb_cloud_common_v1_Empty): Promise<GraphList> {
    const path = `/api/data/v1/graphs`;
    const url = path + api.queryString(req, []);
    return api.get(url, undefined);
  }
  
  // creates the graph
  async CreateGraph(req: Graph): Promise<Graph> {
    const url = `/api/data/v1/graphs`;
    return api.post(url, req);
  }
  
  // updates the graph
  async UpdateGraph(req: Graph): Promise<Graph> {
    const url = `/api/data/v1/graphs`;
    return api.put(url, req);
  }
  
  // deletes graph
  async DeleteGraph(req: GraphByIdRequest): Promise<void> {
    const path = `/api/data/v1/graphs/${encodeURIComponent(req.graph_id || '')}`;
    const url = path + api.queryString(req, [`graph_id`]);
    return api.delete(url, undefined);
  }
  
  // get graph by id
  async GetGraph(req: GraphByIdRequest): Promise<Graph> {
    const path = `/api/data/v1/graphs/${encodeURIComponent(req.graph_id || '')}`;
    const url = path + api.queryString(req, [`graph_id`]);
    return api.get(url, undefined);
  }
  
  // triggers the process of loading data and creation of the graph in arango.
  async LoadDataGraph(req: GraphGenerateRequest): Promise<LoadGraphJob> {
    const path = `/api/data/v1/graphs/${encodeURIComponent(req.graph_id || '')}/generate`;
    const url = path + api.queryString(req, [`graph_id`]);
    return api.get(url, undefined);
  }
  
  // get the status for the graph data load job
  async LoadJobStatus(req: LoadGraphJobStatusRequest): Promise<LoadGraphJob> {
    const path = `/api/data/v1/loadJobs/${encodeURIComponent(req.job_id || '')}`;
    const url = path + api.queryString(req, [`job_id`]);
    return api.get(url, undefined);
  }
}
