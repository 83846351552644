import { Button } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const InviteCollaboratorsButton = () => {
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const history = useHistory();

  const onNewInvite = () => {
    history.push(Routes.dashboard_organization_organizationInvites_createWithId(selectedOrganization.id || ""), topMenuInfo.getTitle());
  };

  const hasCreateInvitePermission = checkOrgPermission("resourcemanager.organization-invite.create");
  const isSSOEnabled = selectedOrganization.authentication_providers?.enable_sso;
  return (
    <Button onClick={onNewInvite} isDisabled={!hasCreateInvitePermission || isSSOEnabled} colorScheme="green" size="md">
      Invite collaborators
    </Button>
  );
};
