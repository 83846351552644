//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Form, Modal } from "semantic-ui-react";
import {
  AuditLog as ApiAuditLog,
  AuditLog_Destination as ApiAuditLogDestination,
  AuditLogTopic as ApiAuditLogTopic,
  Organization as ApiOrganization,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonCreate, FormContentAction, FormContentActions, MainContent, Processing } from "../../ui/lib";
import apiClients from "../../api/apiclients";
import { EditAuditLogView } from "./EditAuditLogViews";

interface ICreateAuditLogViewArgs extends ICreateAuditLogModalState, ICreateAuditLogModalProps {
  organization_id: string;
  active: boolean;
  handleDismissError: () => void;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onToggleCloud: () => void;
  onExcludedCloudTopicsChanged: (value: string[]) => void;
  onExcludedTopicsChanged: (index: number, value: string[]) => void;
  onNewDestinationCreated: (value: ApiAuditLogDestination) => void;
  onDeleteDestination: (index: number) => void;
  onDestinationUpdated: (index: number, value: ApiAuditLogDestination) => void;
  onToggleAccessPriceIncrease: () => void;
  onCreate: () => void;
}

const CreateAuditLogView = ({ ...args }: ICreateAuditLogViewArgs) => {
  const tier = args.organization.tier || {};
  const has_cloud_destination = !!tier.has_auditlog_destination_cloud;
  const has_https_destination = !!tier.has_auditlog_destination_https_post;
  const canCreate = !_.isEmpty(args.name) && (args.cloud || !_.isEmpty(args.destinations));
  return (
    <Modal open onClose={args.onClose} size="large">
      <Modal.Header>New audit log</Modal.Header>
      <Modal.Content>
        <Processing active={args.processing} message="Creating audit log, please wait..." />
        <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
        <MainContent>
          <Form>
            <EditAuditLogView {...args} has_cloud_destination={has_cloud_destination} has_https_destination={has_https_destination} show_price_warning />
            <FormContentActions>
              <FormContentAction>
                <FormActionButtonCreate primary disabled={!args.active || !canCreate || !args.accept_price_increase} onClick={args.onCreate} />
              </FormContentAction>
              <FormContentAction>
                <FormActionButtonCancel onClick={args.onClose} />
              </FormContentAction>
            </FormContentActions>
          </Form>
        </MainContent>
      </Modal.Content>
    </Modal>
  );
};

interface ICreateAuditLogModalProps {
  organization: ApiOrganization;
  all_topics: ApiAuditLogTopic[];
  onNewAuditLogCreated: (auditLogId: string) => void;
  onClose: () => void;
}

interface ICreateAuditLogModalState {
  errorMessage?: string;
  processing: boolean;
  name: string;
  description: string;
  cloud: boolean;
  excluded_cloud_topics: string[];
  destinations: ApiAuditLogDestination[];
  accept_price_increase: boolean;
}

// Component to create an audit log
class CreateAuditLogModal extends Component<ICreateAuditLogModalProps, ICreateAuditLogModalState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
    cloud: true,
    excluded_cloud_topics: [],
    destinations: [],
    accept_price_increase: false,
  } as ICreateAuditLogModalState;

  getExcludedTopics = () => {
    const excludedTopics = this.props.all_topics as Array<Required<ApiAuditLogTopic>>;
    return excludedTopics.filter((topic) => topic.exclude_by_default);
  };

  getExcludedTopicsNames = (excludedTopics: Array<Required<ApiAuditLogTopic>>): string[] => {
    if (!excludedTopics || !excludedTopics.length) {
      return [];
    }

    return excludedTopics.map((excludedTopic) => excludedTopic.topic);
  };

  componentDidMount() {
    const excludedCloudTopics = this.getExcludedTopics();
    if (!!excludedCloudTopics && excludedCloudTopics.length) {
      this.onExcludedCloudTopicsChanged(this.getExcludedTopicsNames(excludedCloudTopics));
    }
  }

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };
  onToggleCloud = () => {
    this.setState((old) => {
      return { cloud: !old.cloud };
    });
  };
  onToggleAccessPriceIncrease = () => {
    this.setState((old) => {
      return { accept_price_increase: !old.accept_price_increase };
    });
  };
  onExcludedCloudTopicsChanged = (value: string[]) => {
    this.setState({ excluded_cloud_topics: value });
  };
  onExcludedTopicsChanged = (index: number, value: string[]) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      destinations[index].excluded_topics = value;
      return { destinations: destinations };
    });
  };
  onNewDestinationCreated = (value: ApiAuditLogDestination) => {
    this.setState(
      (old) => {
        return { destinations: _.concat(old.destinations, value) };
      },
      () => {
        this.onExcludedTopicsChanged(this.state.destinations.length - 1, this.getExcludedTopicsNames(this.getExcludedTopics()));
      }
    );
  };
  onDestinationUpdated = (index: number, value: ApiAuditLogDestination) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      destinations[index] = value;
      return { destinations: destinations };
    });
  };
  onDeleteDestination = (index: number) => {
    this.setState((old) => {
      const destinations = _.cloneDeep(old.destinations);
      _.pullAt(destinations, index);
      return { destinations: destinations };
    });
  };

  onCreate = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const tier = this.props.organization.tier || {};
      const has_cloud_destination = !!tier.has_auditlog_destination_cloud;
      const destinations = _.cloneDeep(this.state.destinations);
      if (has_cloud_destination && this.state.cloud) {
        destinations.push({
          type: "cloud",
          excluded_topics: this.state.excluded_cloud_topics,
        });
      }
      const req = {
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        destinations: destinations,
      } as ApiAuditLog;
      const result = await apiClients.auditClient.CreateAuditLog(req);

      this.props.onNewAuditLogCreated(result.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Audit log creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return <CreateAuditLogView {...this.props} {...this.state} {...this} organization_id={this.props.organization.id || ""} active={!this.state.processing} />;
  }
}

export default CreateAuditLogModal;
