//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export function isAPIError(e: any, status: number) {
  return e.status == status;
}

export function isBadRequest(e: any) {
  return isAPIError(e, 400);
}

export function isUnauthorized(e: any) {
  return isAPIError(e, 401);
}

export function isForbidden(e: any) {
  return isAPIError(e, 403);
}

export function isNotFound(e: any) {
  return isAPIError(e, 404);
}

export function isConflict(e: any) {
  return isAPIError(e, 409);
}

export function isPreconditionFailed(e: any) {
  return isAPIError(e, 412);
}
