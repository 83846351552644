import React from "react";
import { Grid, Container } from "semantic-ui-react";
import screenshot from "./high-avail.png";
import { Box } from "../../ui/_box";
import heroBlob from "./home-blob.png";
import { useBreakpointValue } from "@chakra-ui/react";

const HighAvailability = () => {
  const showOnDesktop = useBreakpointValue({ base: false, md: true }); // Show on medium screens (desktops) and above

  return (
    <Box position="relative">
      <Box position="absolute" left={0} top={0} zIndex={"-1"} opacity={0.6}>
        <img src={heroBlob} alt="bgBackgroundBlog" height={800} />
      </Box>
      <Box padding={"60px 0 60px 0"}>
        <Container>
          <Grid columns="16" stackable padded relaxed tablet={8} mobile={12}>
            <Grid.Row centered>
              <Grid.Column computer={8} tablet={14} mobile={16}>
                {showOnDesktop && (
                  <Box position="relative" top={"20px"}>
                    <img src={screenshot} alt="App Screenshot" width={"80%"} />
                  </Box>
                )}
              </Grid.Column>
              <Grid.Column computer={8} tablet={14} mobile={16} textAlign="left" verticalAlign="middle">
                <Box fontSize={"3rem"} color="var(--green-900)" lineheight={1.5} fontWeight={700}>
                  High Availability
                </Box>
                <Box fontSize={"1.4rem"} fontWeight={400} lineheight={1.3} padding={"16px 0 0 0"}>
                  Ensure that your graph data is available with minimal effort by your operations team. Featuring Multi-availability Zone Clusters, Managed
                  Zero-downtime Upgrades, Managed Backups, One-Click Disaster Recovery, Monitoring & Alerting and Managed Log Management.
                </Box>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
export default HighAvailability;
