//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Button } from "semantic-ui-react";

const CodeContainer = styled("div")`
  position: relative;

  button {
    opacity: 10%;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &:hover {
    button.copy {
      opacity: 100%;
    }
  }
`;

const CustomCodeBlock = ({ value, language }: { value: string; language?: string }) => {
  const [copied, setCopied] = React.useState(false);
  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  return (
    <CodeContainer>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <Button basic className="copy" disabled={!value} icon={copied ? "checkmark" : "copy"} size="mini" />
      </CopyToClipboard>
      <SyntaxHighlighter language={language}>{value}</SyntaxHighlighter>
    </CodeContainer>
  );
};

export default CustomCodeBlock;
