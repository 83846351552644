//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { FlexBox } from "../../../ui/_flex";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { useWithRefresh } from "../../../util/WithRefreshContext";
import ImportDataInstructions from "../ImportDataInstructions";
import { StyledHeader } from "./MigrationWizard";
import { Icon } from "semantic-ui-react";

const ImportDataToCloudInstructions = ({ onBack }: { onBack: () => void }) => {
  const { refreshNow, permissionCache, loading, eventSubscriptionManager } = useWithRefresh();
  const { deployment } = useDeploymentStore();
  const { status: deploymentStatus = {} } = deployment;

  const isConvertedToPrivateEndpoint = !!deploymentStatus.private_endpoint;
  const endpointAvailable = !!(deploymentStatus?.endpoint && deploymentStatus.bootstrapped);

  return (
    <>
      <FlexBox margin="0 0 24px 0" direction="column">
        <StyledHeader>Where is your data coming from?</StyledHeader>
      </FlexBox>
      <FlexBox
        margin="0 0 16px 0"
        justify="space-between"
        direction="column"
        minHeight="550px"
        backgroundColor="var(--gray-100)"
        borderRadius="12px"
        padding="24px 32px"
      >
        <FlexBox align="baseline" className="pointer" width="fit-content" margin="8px 0 0 0">
          <span onClick={onBack}>
            <Icon name="arrow left" className="dark-green-text" />
            Back
          </span>
        </FlexBox>
        <ImportDataInstructions
          loading={loading}
          refreshNow={refreshNow}
          eventSubscriptionManager={eventSubscriptionManager}
          permissionCache={permissionCache}
          isPrivateEndpoint={isConvertedToPrivateEndpoint}
          disabled={!endpointAvailable}
        />
      </FlexBox>
    </>
  );
};

export default ImportDataToCloudInstructions;
