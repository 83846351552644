export const useAPIUtils = () => {
  const fetchDataForAllPages = async <T>(
    api: (page: number) => Promise<{ data: T[] | undefined; error?: any } | { data: undefined; error: any }>,
    pageSize: number
  ) => {
    let currentPage = 0;
    let currentPageSize = pageSize;
    const response: T[] = [];

    while (currentPageSize === pageSize) {
      const { data: nextData = [], error: nextError } = await api(currentPage);

      if (nextError) {
        return { data: undefined, error: nextError };
      }

      ++currentPage;
      currentPageSize = nextData.length;
      nextData.length && response.push(...nextData);
    }
    return { data: response, error: undefined };
  };

  return { fetchDataForAllPages };
};
