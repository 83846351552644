//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { Group as ApiGroup, GroupList as ApiGroupList } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { Routes } from "../../routes";
import {
  Confirm,
  ConfirmInfo,
  ErrorMessage,
  ListActionDelete,
  ListActionEdit,
  ListActionView,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  TextLink,
} from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { TopMenuInfo } from "../TopMenuInfo";
import { HistoryHelper } from "../HistoryHelper";
import { reportError } from "../../errors/reporting";
import { History } from "history";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing a group
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Assign New Members</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a group
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiGroup;
  onClickView: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>
      <TextLink
        label={args.item.name}
        onClick={args.onClickView}
        disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.Group, "iam.group.get")}
      />
    </Table.Cell>
    <Table.Cell>{args.item.description}</Table.Cell>
    <Table.Cell>{args.item.is_default ? "Yes" : "No"}</Table.Cell>
    <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.Group, "iam.group.get") && (
          <ListActionView onClick={args.onClickView} />
        )}
        {!args.item.is_virtual &&
          !args.item.is_deleted &&
          args.hasPermissionByUrl &&
          args.hasPermissionByUrl(args.item.url || "", ResourceType.Group, "iam.group.update") && (
            <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />
          )}
        {!args.item.is_virtual &&
          !args.item.is_deleted &&
          args.hasPermissionByUrl &&
          args.hasPermissionByUrl(args.item.url || "", ResourceType.Group, "iam.group.delete") && (
            <ListActionDelete disabled={!args.active} onClick={args.onClickDelete} />
          )}
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the group
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  items: ApiGroup[];
  active: boolean;
  loading: boolean;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No group inside this organization</div>;

// Interface describing the group list view arguments
export interface IGroupListViewArgs {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  loading: boolean;
  organization: ApiOrganization;
  groups?: ApiGroupList;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const GroupListView = ({ ...args }: IGroupListViewArgs) => {
  if (!args.groups) {
    return <Loading />;
  }
  if (!args.groups.items || args.groups.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.groups.items} />;
};

// Interface decribing the properties of the group list component
interface IGroupListProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  history: History;
  loading: boolean;
  onGroupSelected: (id: string) => void;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  subscribeUrl: ((callback: () => Promise<void>, url?: string) => Promise<string | void>) | undefined;
  unsubscribe: ((id?: string | void) => void) | undefined;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
}

// Interface decribing the state of the group list component
interface IGroupListState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  groups?: ApiGroupList;
}

// The component to show the groups inside an organization as a list.
class GroupList extends Component<IGroupListProps, IGroupListState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    groups: undefined,
  } as IGroupListState;
  subscriptionId?: string | void;
  reloadGroups = async () => {
    const listOptions = { context_id: this.props.organization.id } as ApiListOptions;
    const groups = await apiClients.iamClient.ListGroups(listOptions);
    this.setState({ groups: groups });
  };

  onClickView = (id: string) => {
    this.props.onGroupSelected(id);
  };

  onClickEdit = (id: string) => {
    HistoryHelper.push(
      this.props.history,
      Routes.dashboard_organization_group_editWithId(this.props.organization.id || "", id),
      this.props.topMenuInfo.getTitle()
    );
  };

  getGroupName = (id: string) => {
    const groups = this.state.groups;
    if (groups && groups.items) {
      const group = groups.items.find((g) => g.id == id);
      if (group) {
        return group.name;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const groupName = this.getGroupName(id);
    const confirmInfo = {
      header: "Delete Group",
      content: `Are you sure you want to delete group '${groupName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.iamClient.DeleteGroup(idOptions);
      this.props.refreshNow?.(this.reloadGroups);
    } catch (e) {
      this.setState({ errorMessage: `Group deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };
  subscribeGroups = async () => {
    this.subscriptionId = await this.props.subscribeUrl?.(this.reloadGroups, `${this.props.organization.url}/Group/*`);
  };
  componentDidMount() {
    this.subscribeGroups();
  }
  componentWillUnmount(): void {
    if (this.subscriptionId) {
      this.props.unsubscribe?.(this.subscriptionId);
    }
  }
  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    const groups = this.state.groups;

    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting group, please wait..." />
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <GroupListView
          {...this.props}
          active={!this.state.processing}
          groups={groups}
          onClickView={this.onClickView}
          onClickEdit={this.onClickEdit}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default GroupList;
