//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { CheckboxProps, Header } from "semantic-ui-react";
import { useGraphNodeHandlers } from "../../graph-modeller/GraphProvider";
import { StyledCheckbox, StyledFileHeader } from "../Styles";
import { useFileAndNodeModifiers } from "../hooks/useFileAndNodeModifiers";
import styled from "@emotion/styled";
import { EXTRA_FIELD } from "../../hooks/useFileParser";

const StyledHeaderMessage = styled.div`
  width: 100%;
  padding: 30px;
  text-align: center;
  font-size: 1.2rem !important;
  border: 1px dashed var(--gray-200);
  box-shadow: none !important;
  background-color: var(--gray-50);
  color: var(--gray-600);
`;

export const NodeFileHeaders = () => {
  const { getNode } = useGraphNodeHandlers();
  const {
    assignFileHeadersToNodeInGlobalStore,
    removeFileHeaderFromNodeInGlobalStore,
    getFieldsAssignedToNodeFromGlobalStore,
    getAssignedFileToNodeFromGlobalStore,
  } = useFileAndNodeModifiers();

  const [checkedFields, setCheckedFieldsInLocalState] = useState<string[]>([]);

  const node = getNode() || {};
  const selectedFile = getAssignedFileToNodeFromGlobalStore();

  const handleFieldsSelection = (key: string, checked?: boolean) => {
    if (checked) {
      assignFileHeadersToNodeInGlobalStore(key);
      setCheckedFieldsInLocalState([...checkedFields, key]);
      return;
    }

    removeFileHeaderFromNodeInGlobalStore(key);
    setCheckedFieldsInLocalState(checkedFields.filter((str) => str !== key));
  };

  useEffect(() => {
    if (node && node.id) {
      setCheckedFieldsInLocalState(getFieldsAssignedToNodeFromGlobalStore() || []);
      return;
    }
  }, [node]);

  if (!selectedFile) return <StyledHeaderMessage>Associate a file to this node to see the headers here.</StyledHeaderMessage>;

  return (
    <>
      <Header sub>File headers</Header>
      {Object.keys(selectedFile.fields).map((key: string) => {
        if (key !== EXTRA_FIELD) {
          return (
            <StyledFileHeader justify="space-between" align="center" margin="10px 0" key={key}>
              <StyledCheckbox
                label={key}
                className="file-data-header mask-data"
                checked={checkedFields.includes(`${key}`)}
                onChange={(_: unknown, data: CheckboxProps) => {
                  const { checked } = data;
                  handleFieldsSelection(key, checked);
                }}
              />

              <div className="file-data-value ellipsis mask-data" title={selectedFile.fields[key]}>
                {!!selectedFile.fields[key] ? selectedFile.fields[key] : "-"}
              </div>
            </StyledFileHeader>
          );
        }
        return null;
      })}
    </>
  );
};
