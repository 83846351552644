//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { StyledFileItem } from "../files/Styles";
import { FileObject, UploadedFileReport } from "../types";
import { useDataloaderStore } from "../DataloaderStore";
import ReplaceFileModal from "./ReplaceFileModal";
import CollectionUploadProgress from "./CollectionUploadProgress";
import { useFileReUpload } from "./useFileReupload";
import UploadedFileReportWithErrors from "./UploadedFileWithErrors";
import { CollectionType } from "arangojs/collection";
import { getType } from "../utils/filesUpload.utils";
import { pluralize } from "../../../../api/_util";

export interface IUploadFileProps {
  uploadedFileReport: UploadedFileReport;
}

const SuccessUploadedFileReport = ({
  collectionName,
  uploadedFileName,
  successfulUploads,
  collectionType,
}: {
  collectionName: string;
  uploadedFileName: string;
  successfulUploads: number;
  collectionType: CollectionType | undefined;
}) => {
  const typeText = getType(collectionType) || "Item";

  return (
    <FlexBox justify="space-between" direction="row" className="file-item">
      <FlexBox justify="flex-start">
        <Icon name="file alternate outline" size="big" className="dark-green-text" />
        <div className="file-section">
          <div className="name ellipsis" title={uploadedFileName}>
            <b>
              {successfulUploads} {pluralize(typeText, successfulUploads)}
            </b>{" "}
            added to the <b>{collectionName}</b> collection based on <b>{uploadedFileName}</b>.
          </div>
        </div>
      </FlexBox>
    </FlexBox>
  );
};

const FailedUploadedFileReport = ({
  collectionName,
  uploadedFileName,
  uploadError,
  successfulUploads,
  collectionType,
}: {
  collectionName: string;
  uploadedFileName: string;
  uploadError: string;
  successfulUploads: number;
  collectionType: CollectionType | undefined;
}) => {
  const typeText = getType(collectionType) || "Item";

  return (
    <FlexBox justify="space-between" direction="row" className="file-item">
      <FlexBox justify="flex-start">
        <Icon name="file alternate outline" size="big" className="red-text" />
        <div className="file-section">
          <div className="name ellipsis" title={uploadedFileName}>
            <b>
              {successfulUploads} {pluralize(typeText, successfulUploads)}
            </b>{" "}
            added to the <b>{collectionName}</b> collection based on <b>{uploadedFileName}</b>.
          </div>
          <div className="size">
            <Icon name="info circle" className="red-text" />
            {uploadError}
          </div>
        </div>
      </FlexBox>
    </FlexBox>
  );
};

export const UploadedFile = ({ uploadedFileReport }: IUploadFileProps) => {
  const { collectionName, errors, name: uploadedFileName, fullReportURL, uploadError, successfulUploads, collectionType, ignored } = uploadedFileReport;
  const { setMigrationJob, getDeploymentDataloaderState } = useDataloaderStore();
  const { migrationJob } = getDeploymentDataloaderState();
  const { fileBeingUploaded } = migrationJob;
  const [isReplaceFileModalOpen, setIsReplaceFileModalOpen] = useState<boolean>(false);
  const [droppedFile, setDroppedFile] = useState<FileObject | undefined>(undefined);
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  const { saveDroppedFileAtCollection } = useFileReUpload({
    collectionName,
    newFile: droppedFile,
    selectedFields: checkedFields,
  });

  const handleReplaceFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsReplaceFileModalOpen(true);
  };

  const handleIgnoreFileErrors = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const { filesAlreadyUploaded } = migrationJob;
    const newFilesAlreadyUploaded = filesAlreadyUploaded?.map((file) =>
      file.collectionName === collectionName
        ? {
            ...file,
            ignored: true,
          }
        : file
    );

    setMigrationJob({
      status: "finished",
      filesAlreadyUploaded: newFilesAlreadyUploaded,
    });
  };

  const handleOnSave = async () => {
    setIsReplaceFileModalOpen(false);
    await saveDroppedFileAtCollection();
    setDroppedFile(undefined);
    setCheckedFields([]);
  };

  if (fileBeingUploaded?.collectionName === uploadedFileReport.collectionName) return <CollectionUploadProgress />;

  return (
    <StyledFileItem key={collectionName}>
      <ReplaceFileModal
        isOpen={isReplaceFileModalOpen}
        onClose={() => setIsReplaceFileModalOpen(false)}
        fileToReplace={uploadedFileReport}
        droppedFile={droppedFile}
        setDroppedFile={setDroppedFile}
        checkedFields={checkedFields}
        setCheckedFields={setCheckedFields}
        onSave={handleOnSave}
      />

      {!uploadError ? (
        !errors.length ? (
          <SuccessUploadedFileReport
            collectionName={collectionName}
            uploadedFileName={uploadedFileName}
            successfulUploads={successfulUploads}
            collectionType={collectionType}
          />
        ) : (
          <UploadedFileReportWithErrors
            collectionName={collectionName}
            uploadedFileName={uploadedFileName}
            successfulUploads={successfulUploads}
            fullReportURL={fullReportURL}
            errors={errors}
            ignored={!!ignored}
            collectionType={collectionType}
            handleIgnoreFileErrors={handleIgnoreFileErrors}
            handleReplaceFile={handleReplaceFile}
          />
        )
      ) : (
        <FailedUploadedFileReport
          collectionName={collectionName}
          uploadedFileName={uploadedFileName}
          uploadError={uploadError}
          successfulUploads={successfulUploads}
          collectionType={collectionType}
        />
      )}
    </StyledFileItem>
  );
};
