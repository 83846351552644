//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Role as ApiRole } from "../../api/iam/v1/iam";
import { RouteComponentProps } from "react-router-dom";

const HeaderView = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a permission
interface IRowView {
  item: string;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>{args.item}</Table.Cell>
  </Table.Row>
);

// Interface describing the permission
interface IListView {
  items: string[];
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item} item={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No permissions inside this role</div>;

// Interface describing the permission list view arguments
export interface IPermissionListViewArgs extends RouteComponentProps {
  permissions?: string[];
}

export const PermissionListView = ({ ...args }: IPermissionListViewArgs) => {
  if (!args.permissions || args.permissions.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.permissions} />;
};

// Interface decribing the properties of the permission list component
interface IPermissionListProps extends RouteComponentProps {
  role: ApiRole;
}

// Interface decribing the state of the role list component
interface IPermissionListState {}

// The component to show the permissions inside a role as a list.
class PermissionList extends Component<IPermissionListProps, IPermissionListState> {
  state = {} as IPermissionListState;

  render() {
    return <PermissionListView {...this.props} permissions={this.props.role.permissions} />;
  }
}

export default PermissionList;
