//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Deployment as ApiDeployment,
  DeploymentList as ApiDeploymentList,
  ListOptions as ApiListOptions,
  Organization as ApiOrganization,
  Project as ApiProject,
  ProjectList as ApiProjectList,
  SupportRequest as ApiSupportRequest,
  User as ApiUser,
} from "../../api/lib";
import { Button, ButtonGroup, DropdownProps, Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import apiClients from "../../api/apiclients";
import { ErrorMessage, Processing, Section, SectionContent, SectionHeader } from "../../ui/lib";
import _, { isEmpty } from "lodash";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { reportError } from "../../errors/reporting";
import styled from "@emotion/styled";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { FlexBox } from "../../ui/_flex";

const StyledForm = styled(Form)`
  .section {
    margin-bottom: 0 !important;
  }
`;

const StyledSection = styled(Section)`
  .section {
    background-color: yellow;
  }
  margin-bottom: 0 !important;
`;

const StyledDiv = styled("div")`
  margin-top: 0.5em !important;
`;

const lowSeverityHelpText = `Use for questions regarding the ArangoGraph Insights Platform (no loss of service is involved).`;
const normalSeverityHelpText = `Use in case of errors causing a minor loss of service or any flaws without severe effect on processing.`;
const highSeverityHelpText = `Use in case the error severely interferes with the appropriate use of ArangoGraph however, operations can continue in a restricted fashion.`;
const criticalSeverityHelpText = `Use in case of complete loss of service or unable to use ArangoGraph or the usage is unacceptable i.e. extremely slow or significant hinderence exists.`;

interface ISupportRequestShowIDViewArgs {
  onClose: () => void;
  supportRequestID: string;
  copiedRequestID: boolean;
  onCopiedRequestID: () => void;
}

export const SupportRequestShowIDView = ({ ...args }: ISupportRequestShowIDViewArgs) => {
  return (
    <Modal size="small" open>
      <Modal.Header>Your support request has been submitted</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="para">Your request for support has been successfully submitted to ArangoDB.</p>
          <p className="para">
            Support request ID: <strong>{args.supportRequestID}</strong>&nbsp;
            <CopyToClipboard text={args.supportRequestID} onCopy={args.onCopiedRequestID}>
              <Icon name={args.copiedRequestID ? "checkmark" : "copy"} className="secondary-text" />
            </CopyToClipboard>
          </p>
          <p className="para">Our support team will contact you soon.</p>
          <p className="para">Thank you for contacting us.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={args.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface ISupportRequestModalViewArgs {
  onSubmit: () => void;
  onClose: () => void;
  username: string;
  email: string;
  onUsernameChanged: (value: string) => void;
  onEmailChanged: (value: string) => void;
  organization?: ApiOrganization;
  projects?: ApiProjectList;
  deployments?: ApiDeploymentList;
  title: string;
  onTitleChanged: (value: string) => void;
  description: string;
  onDescriptionChanged: (value: string) => void;
  severity: string;
  isSeverityHighAllowed: boolean;
  isSeverityCriticalAllowed: boolean;
  processing: boolean;
  updateSeverity: (sev: string) => void;
  updateProjectDropdownChange: (e: any, args: DropdownProps) => void;
  updateDeploymentDropdownChange: (e: any, args: DropdownProps) => void;
  handleDismissError: () => void;
  errorMessage?: string;
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  selectedProject?: ApiProject;
  selectedDeployment?: ApiDeployment;
  setSupportDeploymentReason?: (isDeploymentRelated?: boolean) => void;
}

export const SupportRequestModalView = ({ ...args }: ISupportRequestModalViewArgs) => {
  const [isDeploymentRelated, setIsDeploymentRelatedReason] = useState(true);

  const projectList = args.projects || {};
  const projects = projectList.items || [];
  // Filter the return value at the end. The options requires a return value otherwise key, text, value might be
  // undefined and that is not allowed. Same for deployments. This means map must return a proper empty result.
  const projectItems = _.orderBy(projects, "name")
    .map((p) => {
      if (args.hasPermissionByUrl && p.url && args.hasPermissionByUrl(p.url, ResourceType.Project, "resourcemanager.project.get")) {
        return { key: p.id, text: p.name, value: p.id };
      }
      return {};
    })
    .filter((p) => {
      return p.key;
    });
  projectItems.unshift({
    key: "",
    text: "No project",
    value: "",
  });

  const deploymentList = args.deployments || {};
  const deployments = deploymentList.items || [];
  const deploymentItems = _.orderBy(deployments, "name")
    .map((d) => {
      if (args.hasPermissionByUrl && d.url && args.hasPermissionByUrl(d.url, ResourceType.Deployment, "data.deployment.get")) {
        return { key: d.id, text: d.name, value: d.id };
      }
      return {};
    })
    .filter((p) => {
      return p.key;
    });
  deploymentItems.unshift({
    key: "",
    text: "No deployment",
    value: "",
  });

  let severityLabelText = lowSeverityHelpText;
  switch (args.severity) {
    case "Low":
      severityLabelText = lowSeverityHelpText;
      break;
    case "Normal":
      severityLabelText = normalSeverityHelpText;
      break;
    case "High":
      severityLabelText = highSeverityHelpText;
      break;
    case "Critical":
      severityLabelText = criticalSeverityHelpText;
      break;
  }

  useEffect(() => {
    args.setSupportDeploymentReason && args.setSupportDeploymentReason(isDeploymentRelated);
    args.updateDeploymentDropdownChange({}, { name: "deployment", value: undefined });
  }, [isDeploymentRelated]);
  return (
    <Modal size="large" open onClose={args.onClose}>
      <Modal.Header>Request help</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
          <Processing active={args.processing} message="Submitting support request ..." />
          <div>
            <StyledForm>
              <Grid columns="1" stackable>
                <Grid.Column>
                  <StyledSection>
                    <SectionHeader title="General" />
                    <SectionContent>
                      <Form.Input
                        required
                        label="Name"
                        autoFocus
                        name="username"
                        placeholder="Enter your name"
                        value={args.username}
                        onChange={(e, d) => args.onUsernameChanged(d.value)}
                      />
                      <Form.Input
                        required
                        label="Email"
                        name="email"
                        placeholder="Enter your email address"
                        value={args.email}
                        onChange={(e, d) => args.onEmailChanged(d.value)}
                      />
                    </SectionContent>
                  </StyledSection>
                </Grid.Column>
                <Grid.Column>
                  <StyledSection>
                    <SectionHeader title="Subject" />
                    <SectionContent>
                      <Message>
                        <Form.Checkbox
                          toggle
                          checked={isDeploymentRelated}
                          onClick={() => {
                            setIsDeploymentRelatedReason(!isDeploymentRelated);
                          }}
                          label="Request is related to a deployment"
                        />
                      </Message>
                      <Form.Dropdown
                        fluid
                        required
                        selection
                        value={(args.selectedProject || {}).id}
                        label="Project"
                        name="project"
                        placeholder="Select Project"
                        options={projectItems}
                        disabled={!isDeploymentRelated}
                        onChange={args.updateProjectDropdownChange}
                      />
                      <Form.Dropdown
                        fluid
                        required
                        selection
                        disabled={!args.selectedProject || !isDeploymentRelated}
                        label="Deployment"
                        name="deployment"
                        value={(args.selectedDeployment || {}).id || ""}
                        placeholder="Select Deployment"
                        options={deploymentItems}
                        onChange={args.updateDeploymentDropdownChange}
                      />

                      {!isDeploymentRelated && (
                        <Message warning={!isDeploymentRelated} visible>
                          <FlexBox justify="space-between">
                            <span>
                              Our response time is related to the support plan used for your deployment.
                              <br />
                              To get the desired response time, make sure to select your deployment!
                            </span>
                          </FlexBox>
                        </Message>
                      )}
                    </SectionContent>
                  </StyledSection>
                </Grid.Column>

                <Grid.Column>
                  <StyledSection>
                    <SectionHeader title="Severity" />
                    <SectionContent>
                      <ButtonGroup>
                        <Button type="button" content="Low" primary={args.severity == "Low"} onClick={() => args.updateSeverity("Low")} />
                        <Button type="button" content="Normal" primary={args.severity == "Normal"} onClick={() => args.updateSeverity("Normal")} />
                        <Button
                          type="button"
                          content="High"
                          primary={args.severity == "High"}
                          onClick={() => args.updateSeverity("High")}
                          disabled={!args.isSeverityHighAllowed}
                        />
                        <Button
                          type="button"
                          content="Critical"
                          primary={args.severity == "Critical"}
                          onClick={() => args.updateSeverity("Critical")}
                          disabled={!args.isSeverityCriticalAllowed}
                        />
                      </ButtonGroup>
                      <StyledDiv>
                        <p className="para">{severityLabelText}</p>
                      </StyledDiv>
                    </SectionContent>
                  </StyledSection>
                </Grid.Column>
                <Grid.Column>
                  <StyledSection>
                    <SectionHeader title="Details" />
                    <SectionContent>
                      <Form.Input
                        required
                        label="Title"
                        name="title"
                        placeholder="Please enter a short descriptive title "
                        value={args.title}
                        onChange={(e, d) => args.onTitleChanged(d.value)}
                      />
                      <Form.TextArea
                        label="Description"
                        required
                        name="Description"
                        placeholder="Please enter issue details"
                        value={args.description}
                        onInput={(e, d) => args.onDescriptionChanged(d.value as string)}
                        rows="5"
                      />
                    </SectionContent>
                  </StyledSection>
                </Grid.Column>
              </Grid>
            </StyledForm>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button icon="close" content="Cancel" onClick={args.onClose} labelPosition="right" />

        <Button
          primary
          icon="check"
          content="Submit"
          onClick={args.onSubmit}
          disabled={
            args.username == "" || args.email == "" || args.title == "" || args.description == "" || (isDeploymentRelated && isEmpty(args.selectedDeployment))
          }
          labelPosition="right"
        />
      </Modal.Actions>
    </Modal>
  );
};

// Interface decribing the properties of the SupportRequest component
interface ISupportRequestProps extends IWithRefreshProps, RouteComponentProps {
  showSupportRequestModal: boolean;
  onClose: () => void;
  organization?: ApiOrganization;
  user?: ApiUser;
}

// Interface decribing the state of the SupportRequest component
interface ISupportRequestState {
  errorMessage?: string;
  processing: boolean;
  projects?: ApiProjectList;
  deployments?: ApiDeploymentList;
  // form fields
  username: string;
  email: string;
  title: string;
  description: string;
  severity: string;
  selectedProject?: ApiProject;
  selectedDeployment?: ApiDeployment;
  isDisplayed: boolean;
  requestID: string;
  copiedRequestID: boolean;
  isDeploymentRelated?: boolean;
}

class SupportRequest extends Component<ISupportRequestProps, ISupportRequestState> {
  state = {
    errorMessage: undefined,
    processing: false,
    projects: undefined,
    deployments: undefined,
    username: "",
    email: "",
    title: "",
    description: "",
    severity: "Low",
    selectedDeployment: undefined,
    selectedProject: undefined,
    isDisplayed: false,
    requestID: "",
    copiedRequestID: false,
    isDeploymentRelated: true,
  } as ISupportRequestState;

  reloadProjects = async () => {
    if (this.props.organization) {
      const req = {
        context_id: this.props.organization.id,
      } as ApiListOptions;
      const list = await apiClients.resourceManagerClient.ListProjects(req);
      this.setState({ projects: list }, () => {
        this.updateProjectDropdownChange(
          {},
          {
            name: "project",
            value: useGlobalStore.getState().project.id,
          }
        );
      });
    }
  };

  reloadDeployments = async () => {
    if (this.state.selectedProject) {
      const req = {
        context_id: this.state.selectedProject.id,
      } as ApiListOptions;
      const list = await apiClients.dataClient.ListDeployments(req);
      this.setState({ deployments: list });
    }
  };

  onSubmit = async () => {
    if (this.state.isDeploymentRelated && !this.state.selectedDeployment) {
      this.setState({ errorMessage: "Please select a deployment to raise a ticket" });
      return;
    }
    this.setState({ processing: true });
    const req = {
      user_name: this.state.username,
      severity: this.state.severity.toLowerCase(),
      title: this.state.title,
      description: this.state.description,
      email_address: this.state.email,
    } as ApiSupportRequest;
    if (this.props.user) {
      req.user_id = this.props.user.id;
    }
    if (this.state.isDeploymentRelated && this.state.selectedProject) {
      req.project_id = this.state.selectedProject.id;
    }
    if (this.state.isDeploymentRelated && this.state.selectedDeployment) {
      req.deployment_id = this.state.selectedDeployment.id;
    }
    if (this.props.organization) {
      req.organization_id = this.props.organization.id;
    }
    try {
      const resp = await apiClients.supportClient.CreateSupportRequest(req);
      if (resp.id) {
        this.setState({ requestID: resp.id });
      } else {
        this.setState({ errorMessage: "Unable to create support request. Please check with your site administrator." });
      }
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onResetAndClose = () => {
    this.setState(
      {
        projects: undefined,
        deployments: undefined,
        username: "",
        email: "",
        title: "",
        description: "",
        severity: "Low",
        selectedDeployment: undefined,
        selectedProject: undefined,
        copiedRequestID: false,
        requestID: "",
      },
      this.props.onClose
    );
  };

  updateProjectDropdownChange = (e: any, args: DropdownProps) => {
    if (args.name === "project") {
      const projects = this.state.projects;
      const selectedProject = projects && projects.items && projects.items.find((p) => p.id == args.value);
      this.setState({ selectedProject: selectedProject }, () => {
        this.props.refreshNow && this.props.refreshNow(this.reloadDeployments);
      });
      // clear the previous value
      this.setState({ selectedDeployment: undefined });
    }
  };

  updateDeploymentDropdownChange = (e: any, args: DropdownProps) => {
    if (args.name === "deployment") {
      const deployments = this.state.deployments;
      const selectedDeployment = deployments && deployments.items && deployments.items.find((d) => d.id == args.value);
      this.setState({ selectedDeployment: selectedDeployment });
    }
  };

  updateSeverity = (sev: string) => {
    this.setState({ severity: sev });
  };

  componentDidMount() {
    this.reloadProjects();
    if (this.props.user) {
      this.setState({
        username: this.props.user.name || "",
        email: this.props.user.email || "",
      });
    }
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCopiedRequestID = () => {
    this.setState(
      {
        copiedRequestID: true,
      },
      () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.setState({ copiedRequestID: false });
          }, 3000);
      }
    );
  };

  onUsernameChanged = (value: string) => {
    this.setState({ username: value });
  };
  onEmailChanged = (value: string) => {
    this.setState({ email: value });
  };
  onTitleChanged = (value: string) => {
    this.setState({ title: value });
  };
  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };

  setSupportDeploymentReason = (isDeploymentRelated?: boolean) => {
    this.setState({ isDeploymentRelated });
  };

  render() {
    if (this.state.requestID) {
      return (
        <SupportRequestShowIDView
          onClose={this.onResetAndClose}
          supportRequestID={this.state.requestID}
          copiedRequestID={this.state.copiedRequestID}
          onCopiedRequestID={this.onCopiedRequestID}
        />
      );
    }

    const organization = this.props.organization || {};
    const tier = organization.tier || {};

    return (
      <SupportRequestModalView
        {...this.state}
        {...this.props}
        onSubmit={this.onSubmit}
        onClose={this.onResetAndClose}
        updateProjectDropdownChange={this.updateProjectDropdownChange}
        updateDeploymentDropdownChange={this.updateDeploymentDropdownChange}
        updateSeverity={this.updateSeverity}
        handleDismissError={this.handleDismissError}
        hasPermissionByUrl={this.props.hasPermissionByUrl}
        isSeverityHighAllowed={!!tier.has_support_severity_high}
        isSeverityCriticalAllowed={!!tier.has_support_severity_critical}
        selectedProject={this.state.selectedProject}
        selectedDeployment={this.state.selectedDeployment}
        onUsernameChanged={this.onUsernameChanged}
        onEmailChanged={this.onEmailChanged}
        onTitleChanged={this.onTitleChanged}
        onDescriptionChanged={this.onDescriptionChanged}
        setSupportDeploymentReason={this.setSupportDeploymentReason}
      />
    );
  }
}

export default withRefresh()(SupportRequest);
