//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { humanizeFileSize } from "../../../../util/FileSize";
import { FileListViewArgs } from "../types";
import { getSize, MAX_TOTAL_FILE_SIZE_LIMIT, SINGLE_SESSION_FILE_COUNT } from "../utils";
import FileItem from "./FileItem";
import { StyledFilesHolder } from "./Styles";
import { useDataloaderStore } from "../DataloaderStore";

const FileListView = ({ openFileDialog }: FileListViewArgs) => {
  const [size, setSize] = useState<number>(0);

  const { files } = useDataloaderStore().getDeploymentDataloaderState();

  useEffect(() => {
    setSize(getSize(files));
  }, [files]);

  return (
    <StyledFilesHolder>
      <FlexBox padding="30px 20px 0 20px" justify="space-between">
        <span>
          <Header as="span">Your Files{"  "}</Header>
          <Popup
            as="span"
            on="hover"
            trigger={<Icon name="info circle" className="padding-left-5 " />}
            content="You can add more files by dragging and dropping them in this area"
          />
        </span>
        <span className="file-size-info">
          <strong>{humanizeFileSize(size)}</strong> / <strong>{humanizeFileSize(MAX_TOTAL_FILE_SIZE_LIMIT)}</strong> | Total: <strong> {files.length}</strong>
        </span>
      </FlexBox>

      {files.length < SINGLE_SESSION_FILE_COUNT && (
        <div className="file-actions">
          To add more you can drag &amp; drop files here or{"  "}
          <div onClick={openFileDialog} className="cursor-pointer browse-file">
            Click here to browse more files
          </div>
        </div>
      )}

      <FileItem />
    </StyledFilesHolder>
  );
};

export default FileListView;
