//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ChangeEvent, useState } from "react";
import { Button, Dropdown, Form, Icon, Input, InputOnChangeData, Popup } from "semantic-ui-react";
import { useDataloaderStore } from "./DataloaderStore";
import { ErrorMessage } from "../../../ui/_errorMessage";
import { useArangoClient } from "./hooks/useArangoClient";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { Loading } from "../../../ui/_loading";
import { FormActionButtonSave } from "../../../ui/_buttons";
import { getDatabaseNameErrors } from "./utils/namesValidations.utils";
import { v4 } from "uuid";

const DatabaseDropdownToggle = ({
  handleSelectDatabase,
  setIsNewDatabaseOpen,
}: {
  handleSelectDatabase: (databaseName: string) => void;
  setIsNewDatabaseOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { databasesList, currentDatabase } = useDataloaderStore().getDeploymentDataloaderState();

  return databasesList.length ? (
    <Dropdown placeholder="Select a database" name="database" value={currentDatabase.db?.name} text={currentDatabase.db?.name} selection labeled>
      <Dropdown.Menu>
        {databasesList.sort().map((databaseName) => (
          <Dropdown.Item onClick={() => handleSelectDatabase(databaseName)}>{databaseName}</Dropdown.Item>
        ))}
        <Dropdown.Item onClick={() => setIsNewDatabaseOpen(true)}>
          <Icon name="plus" />
          <span>New Database</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Button onClick={() => setIsNewDatabaseOpen(true)} primary>
      New Database
    </Button>
  );
};

const DatabasePicker = () => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const { deployment } = useDeploymentStore();
  const { status: deploymentStatus = {} } = deployment;

  const [newDatabaseName, setNewDatabaseName] = useState<string>("");
  const [newDatabaseNameErrors, setNewDatabaseNameErrors] = useState<string[]>([]);
  const [isNewDatabaseOpen, setIsNewDatabaseOpen] = useState<boolean>(false);

  const hasDatabaseNameErrors = !!newDatabaseNameErrors.length;

  const handleSelectDatabase = (databaseName: string) => {
    setCurrentDatabase(databaseName);
  };

  const handleChangeNewDatabaseName = (e: ChangeEvent, { value }: InputOnChangeData) => {
    setNewDatabaseName(value);
    setNewDatabaseNameErrors(value !== "" ? getDatabaseNameErrors(value) : []);
  };

  const handleCancelCreateNewDatabase = () => {
    setNewDatabaseName("");
    setNewDatabaseNameErrors([]);
    setIsNewDatabaseOpen(false);
  };

  const handleCreateNewDatabase = async () => {
    const { errorMessage } = await createNewDatabase(newDatabaseName);
    if (!errorMessage) {
      setIsNewDatabaseOpen(false);
    }
    setNewDatabaseName("");
  };

  const { createNewDatabase, setCurrentDatabase, isNewDatabaseLoading, errorDuringDatabaseCreation, dismissErrorMessage } = useArangoClient(
    deploymentStatus.endpoint
  );

  return (
    <>
      <ErrorMessage
        message={`Error while creating the database: ${errorDuringDatabaseCreation}`}
        active={!!errorDuringDatabaseCreation}
        onDismiss={dismissErrorMessage}
      />
      {isNewDatabaseLoading ? (
        <Loading message={`Creating ${newDatabaseName} database`} />
      ) : !isNewDatabaseOpen ? (
        <DatabaseDropdownToggle handleSelectDatabase={handleSelectDatabase} setIsNewDatabaseOpen={setIsNewDatabaseOpen} />
      ) : (
        <Form>
          <Form.Field label="Database name" error={hasDatabaseNameErrors} control={Input} width={3}>
            <Popup
              open={hasDatabaseNameErrors && isInputFocused}
              position="right center"
              trigger={
                <Input
                  placeholder="my_new_database"
                  name="name"
                  value={newDatabaseName}
                  focus
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                  onChange={handleChangeNewDatabaseName}
                  error={hasDatabaseNameErrors}
                  icon={hasDatabaseNameErrors ? "info circle" : undefined}
                />
              }
            >
              {newDatabaseNameErrors.map((nameError) => (
                <p key={v4()}>{nameError}</p>
              ))}
            </Popup>
          </Form.Field>
          <Button onClick={handleCancelCreateNewDatabase}>Cancel</Button>
          <FormActionButtonSave
            title="Create new database"
            icon="plus"
            primary
            onClick={handleCreateNewDatabase}
            disabled={hasDatabaseNameErrors || !newDatabaseName}
          />
        </Form>
      )}
    </>
  );
};

export default DatabasePicker;
