import React from "react";
import { useDashboardContext } from "../../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../../sidebar/useCheckPermissionByUrl";
import { Routes } from "../../../routes";
import { CreditsBundleView } from "./CreditsBundleView";
import { RenderWithRedirection } from "../../../util/RenderGuard";

export const OrganizationCreditsBundlePermissionWrap = () => {
  const { selectedOrganization } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const hasCreditsViewPermission = !!checkOrgPermission("credit.creditbundle.list");
  return (
    <RenderWithRedirection
      renderIf={hasCreditsViewPermission}
      redirectWhenCannotRender={Routes.dashboard_organization_overviewWithId(selectedOrganization?.id || "")}
    >
      <CreditsBundleView />
    </RenderWithRedirection>
  );
};
