import React from "react";
import { Card, Loader, Message } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { useLocation } from "react-router-dom";
import { useDataloaderStore } from "../DataloaderStore";

export const FileUploadProgressView = () => {
  const { getDeploymentDataloaderState } = useDataloaderStore();

  const { migrationJob, files } = getDeploymentDataloaderState();
  const { status, fileBeingUploaded, totalFilesToUpload, filesAlreadyUploaded } = migrationJob;
  const { hash } = useLocation();

  if (status !== "files_upload") return null;

  if (hash === "#dataloader") return null;

  const calculateOverallProgress = () => {
    const totalFiles = totalFilesToUpload || files.length;
    const remainingFiles = totalFiles - (filesAlreadyUploaded?.length || 0);
    const completedFilesProgress = (totalFiles - remainingFiles) * (100 / totalFiles);
    const currentFileProgress = (fileBeingUploaded?.progress || 0) / totalFiles;
    const overallProgress = Math.round(completedFilesProgress + currentFileProgress);
    return overallProgress;
  };

  return (
    <Card
      style={{
        cursor: "move",
        position: "fixed",
        padding: "30px",
        minWidth: "20%",
        bottom: "50px",
        right: "60px",
        zIndex: 999999,
      }}
    >
      <FlexBox justify="space-between">
        <h4 className="heading-4">File Upload Progress</h4>
        <span>Overall Progress: {calculateOverallProgress()}%</span>
      </FlexBox>
      <Message>
        <FlexBox justify="space-between" align="center">
          <span>
            {fileBeingUploaded?.name} - <b>{fileBeingUploaded?.progress}%</b>
          </span>
          <Loader active inline size="mini" />
        </FlexBox>
      </Message>
    </Card>
  );
};
