import { useEffect, useState } from "react";
import apiClients from "../../api/apiclients";
import { IDOptions, Region, TermsAndConditions } from "../../api/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";

const useCurrentTermsAndConditions = () => {
  const { selectedOrganization } = useDashboardContext();
  const { refreshNow } = useWithRefresh();
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditions | undefined>(undefined);
  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>(undefined);

  const reloadCurrentTermsAndConditions = async () => {
    const req = {
      id: selectedOrganization.id || "",
    } as IDOptions;
    const termsAndConditionsResponse = await apiClients.resourceManagerClient.GetCurrentTermsAndConditions(req);
    setTermsAndConditions(termsAndConditionsResponse);
  };

  const refreshCurrentTermsAndConditions = () => {
    refreshNow && refreshNow(reloadCurrentTermsAndConditions);
  };

  const onRegionUpdated = (region?: Region) => setSelectedRegion(region);

  useEffect(() => {
    refreshCurrentTermsAndConditions();
  }, []);

  return {
    termsAndConditions,
    selectedRegion,
    onRegionUpdated,
  };
};

export default useCurrentTermsAndConditions;
