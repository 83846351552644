//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { numberFormat as formatNumber } from "humanize";
import { Invoice, Invoice_Item } from "../../api/billing/v1/billing";
import { Modal, Table, Message, Icon, Segment, Header } from "semantic-ui-react";
import { FormActionButtonCancel } from "../../ui/lib";
import styled from "@emotion/styled";

interface IInvoiceDetailsViewArgs {
  invoice: Invoice;
  currency: string;
  header?: string;
  isPreliminaryView?: boolean;
  onClose?: () => void;
}

interface IInvoiceUsageItemArgs {
  description: string;
  amount: number;
  isPrepaid: boolean;
  currency: string;
}
interface IInvoiceAmountArgs {
  currency: string;
  amount: number;
}
interface IInvoiceItemsArgs {
  items: Invoice_Item[];
  currency: string;
}

const AmountSpan = styled("span")`
  min-width: 5em;
  text-align: right;
  display: inline-block;
`;

const TotalAmountSpan = styled(AmountSpan)`
  font-size: 1.7em;
`;

const PrepaidView = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 6em;
  & > i {
    position: relative;
    top: 0.4em;
  }
`;

const InvoiceUsageItem = (args: Readonly<IInvoiceUsageItemArgs>): JSX.Element => {
  const { description, amount, isPrepaid, currency: currencySign } = args;
  return (
    <Table.Row>
      <Table.Cell size="small">{description}</Table.Cell>
      <Table.Cell textAlign="right">
        <Header>
          {isPrepaid ? (
            <PrepaidView>
              Prepaid <Icon name="check" circular color="green" inverted size="tiny" />
            </PrepaidView>
          ) : (
            <AmountSpan>
              {formatNumber(amount)} {currencySign}
            </AmountSpan>
          )}
        </Header>
      </Table.Cell>
    </Table.Row>
  );
};

const HeaderView = (): JSX.Element => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const PreliminaryInvoiceDescription = ({ ...args }: Readonly<IInvoiceDetailsViewArgs>): JSX.Element => {
  const { invoice } = args;
  const { items = [] } = invoice;
  return (
    <Modal.Description>
      {items.length ? (
        <Message warning>
          <Icon name="warning circle" />
          This preliminary invoice is based on the usage until today. The final invoice amount might vary.
        </Message>
      ) : (
        <Segment>
          <Icon name="warning circle" />
          No details found
        </Segment>
      )}
    </Modal.Description>
  );
};

const InvoiceItems = ({ ...args }: Readonly<IInvoiceItemsArgs>): JSX.Element => {
  const { items = [], currency } = args;

  return (
    <Table striped>
      <HeaderView />
      <Table.Body>
        {items.map((item, idx) => {
          const { description = "", amount = 0, is_prepaid = false } = item;
          return (
            <InvoiceUsageItem
              key={(item.amount || `${idx}`).toString() + idx}
              description={description}
              amount={amount}
              isPrepaid={is_prepaid}
              currency={currency}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

const InvoiceAmount = ({ ...args }: Readonly<IInvoiceAmountArgs>): JSX.Element => {
  const { currency: currencySign, amount = 0 } = args;

  return (
    <Message>
      <Header>
        Total Amount payable <em>(Excluding tax)</em>
        <TotalAmountSpan>
          {formatNumber(amount)} {currencySign}
        </TotalAmountSpan>
      </Header>
    </Message>
  );
};

const InvoiceDetails = ({ ...args }: Readonly<IInvoiceDetailsViewArgs>): JSX.Element => {
  const { onClose, header, isPreliminaryView } = args;
  const { invoice, currency: currencySign } = args;
  const { total_amount_excl_taxes = 0, items = [] } = invoice;

  return (
    <Modal open={true} size="large" onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>{header} </Modal.Header>
      <Modal.Content scrolling>
        {isPreliminaryView && <PreliminaryInvoiceDescription {...args} />}
        {!!items.length && <InvoiceItems {...args} items={items} currency={currencySign} />}
      </Modal.Content>
      <Modal.Actions>
        {!!items.length && <InvoiceAmount currency={currencySign} amount={total_amount_excl_taxes} />}
        <FormActionButtonCancel title="Close" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default InvoiceDetails;
