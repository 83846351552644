//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Box } from "../../../../ui/_box";
import { FlexBox } from "../../../../ui/_flex";
import { StyledErrorLabel, StyledFileItem } from "../files/Styles";
import { CustomParseError, ParsedFileReport } from "../types";

const ErrorsDisplay = ({ errors }: { errors: CustomParseError[] }) => (
  <div className="file-content">
    <FlexBox justify="space-between" direction="column">
      {errors.map((error, index) => (
        <StyledErrorLabel prompt basic className="mask-data" key={index}>
          <>
            <p className="red-text">
              {error.code} at row {error.row}.
            </p>{" "}
            <p className="para">{error.description}</p>
          </>
        </StyledErrorLabel>
      ))}
    </FlexBox>
  </div>
);

const ParsedFileWithErrors = ({ parsedFileReport }: { parsedFileReport: ParsedFileReport }) => {
  const { errors, name, parseReportURL } = parsedFileReport;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isFullReportValid, setIsFullReportValid] = useState<boolean>(true);

  useEffect(() => {
    if (parseReportURL) {
      const checkValidity = async () => {
        try {
          const response = await fetch(parseReportURL);
          setIsFullReportValid(response.ok);
        } catch (error) {
          setIsFullReportValid(false);
        }
      };

      checkValidity();
    }
  }, [parseReportURL]);

  return (
    <StyledFileItem>
      <FlexBox justify="space-between" direction="row" className="file-item cursor-pointer" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <FlexBox justify="flex-start">
          <Icon name="file alternate outline" size="big" style={{ color: "var(--yellow-600)" }} />
          <div className="file-section">
            <div className="name ellipsis" title={name}>
              <b>{name}</b>.
            </div>

            <div className="size">
              <Icon name="info circle" style={{ color: "var(--yellow-600)" }} />
              {!!parseReportURL ? "Several errors found during upload" : `${errors.length} errors found during upload.`}
            </div>
          </div>
        </FlexBox>

        <FlexBox justify="center" align="center">
          {!!parseReportURL && (
            <Box margin="0 16px 0 0">
              <Button
                onClick={(e) => e.stopPropagation()}
                as="a"
                href={parseReportURL}
                download={`${name}-errors-report.csv`}
                icon="download"
                labelPosition="right"
                primary
                content="See full report"
                disabled={!isFullReportValid}
              />
            </Box>
          )}
          <Icon className="file-item-icon" name={`chevron ${isDropdownOpen ? "up" : "down"}`} />
        </FlexBox>
      </FlexBox>

      <div>{!!errors.length && isDropdownOpen && <ErrorsDisplay errors={errors} />}</div>
    </StyledFileItem>
  );
};

export default ParsedFileWithErrors;
