//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { AuditLog_Header as ApiAuditLogHeader } from "../../api/lib";
import _ from "lodash";

// SplitHeader splits key=value into AuditLog_Header.
const SplitHeader = (input: string) => {
  const parts = _.split(input, "=");
  if (parts.length !== 2) {
    throw Error(`Expected key=value, got '${input}'`);
  }
  const key = _.trim(parts[0]);
  if (_.isEmpty(key)) {
    throw Error("Expected non empty key");
  }
  const value = _.trim(parts[1]);
  if (_.isEmpty(value)) {
    throw Error("Expected non empty value");
  }
  return {
    key: key,
    value: value,
  } as ApiAuditLogHeader;
};

export const SplitHeadersString = (value: string) => {
  // Split into non-empty, trimmed lines
  const lines = _.filter(
    _.map(_.split(value, "\n"), (x) => _.trim(x)),
    (x) => !_.isEmpty(x)
  );
  return _.map(lines, (x) => SplitHeader(x));
};

export const JoinHeadersToString = (value: ApiAuditLogHeader[]) => {
  const lines = value.map((x) => `${x.key}=${x.value}`);
  return lines.join("\n");
};
