//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { Dropdown, DropdownProps, Icon, SemanticICONS } from "semantic-ui-react";

interface IAppLinkArgs {
  className?: string;
  href?: string;
  handleClick?: () => void;
  target?: string;
  label: string;
  icon?: SemanticICONS;
  testID?: string;
  noUnderline?: boolean;
}

interface DropdownLinkArgs extends IAppLinkArgs {
  onClick?: (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => void;
  options: string[];
}

const StyledLinkIcon = styled(Icon)`
  position: relative;
  top: -3px;
  left: -2px;
  color: var(--gray-600);
  opacity: 0.8;
  cursor: pointer;
  font-size: 0.9em !important;
`;

const StyledAppLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid var(--green-600);
  cursor: pointer;

  &:hover > i.icon {
    color: var(--gray-950) !important;
  }
`;

const AppLink = ({ className = "", href, handleClick, target, label, icon, testID, noUnderline }: IAppLinkArgs) => {
  return (
    <StyledAppLink
      data-testid={testID}
      className={`${className} ${noUnderline && "border-none"}`}
      href={href}
      onClick={handleClick}
      target={target || "_blank"}
    >
      <span className="main-label">{label}</span> {icon && <StyledLinkIcon name={icon} />}
    </StyledAppLink>
  );
};

const DropdownAsLink = ({ ...args }: DropdownLinkArgs) => {
  return (
    <Dropdown trigger={AppLink({ ...args })} icon={null} closeOnEscape scrolling>
      <Dropdown.Menu>
        {args.options.map((option, index) => (
          <Dropdown.Item key={index} text={option} onClick={() => {}} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { AppLink as AppLinkButton };
export { DropdownAsLink as DropdownLinkButton };
