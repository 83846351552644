//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../../api/apiclients";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";

export const useFetchNotebookModels = () => {
  const { deployment } = useDeploymentStore();
  const { id: deploymentId = "" } = deployment;

  const fetchNotebookModels = async () => {
    return await apiClients.notebookClient.ListNotebookModels({ deployment_id: deploymentId });
  };

  return useQuery([deploymentId, "notebooksModels"], fetchNotebookModels);
};
