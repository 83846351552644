//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, AlertStatus, Box, BoxProps, Flex, IconButton, Text } from "@chakra-ui/react";
import React from "react";

type AlertType = {
  status: AlertStatus;
  message: string;
};
export const AlertList = ({
  alerts,
  marginBottom,
  borderRadius,
}: {
  alerts: Array<AlertType>;
  marginBottom?: BoxProps["marginBottom"];
  borderRadius?: BoxProps["borderRadius"];
}) => {
  const [currentAlert, setCurrentAlert] = React.useState(0);
  const goToNextAlert = () => {
    if (currentAlert < alerts.length - 1) setCurrentAlert(currentAlert + 1);
  };
  const goToPreviousAlert = () => {
    if (currentAlert > 0) setCurrentAlert(currentAlert - 1);
  };

  return (
    <Box width="100%" marginBottom={marginBottom} borderRadius={borderRadius} overflow="hidden">
      <Alert paddingY="2" status={alerts[currentAlert].status}>
        <AlertIcon />
        <Text>{alerts[currentAlert].message}</Text>
        <AlertPagination currentAlert={currentAlert} alerts={alerts} goToNextAlert={goToNextAlert} goToPreviousAlert={goToPreviousAlert} />
      </Alert>
    </Box>
  );
};

const AlertPagination = ({
  currentAlert,
  alerts,
  goToNextAlert,
  goToPreviousAlert,
}: {
  currentAlert: number;
  alerts: Array<AlertType>;
  goToNextAlert: () => void;
  goToPreviousAlert: () => void;
}) => {
  if (alerts.length === 1) {
    return null;
  }
  return (
    <Flex whiteSpace="nowrap" gap="2" marginLeft="auto" alignItems="center">
      <IconButton size="xs" isDisabled={currentAlert === 0} aria-label="Previous Alert" icon={<ChevronLeftIcon />} onClick={goToPreviousAlert} />
      <Text fontSize="xs">
        {currentAlert + 1} of {alerts.length}
      </Text>
      <IconButton size="xs" aria-label="Next Alert" isDisabled={currentAlert === alerts.length - 1} icon={<ChevronRightIcon />} onClick={goToNextAlert} />
    </Flex>
  );
};
