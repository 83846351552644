//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import { Point } from "react-simple-maps";

export interface IMarker {
  markerOffset: number;
  coordinates: Point;
  regions: IRegionInfo[];
}

export interface IRegionInfo {
  provider_id: string;
  provider_name: string;
  name: string;
  planned: boolean;
}

export const providerColors = (providerID: string) => {
  switch (providerID) {
    case "aks":
      return "#008AD7";
    case "aws":
      return "#FF9900";
    case "gcp":
      return "#2DA94F";
  }
};
