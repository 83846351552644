//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchNodeSizes = ({ regionId, modelId }: { regionId: string; modelId: string }) => {
  const { project } = useProjectDetailsContext();
  const fetchNodeSizes = async () => {
    const nodeSizes = await apiClients.dataClient.ListNodeSizes({
      project_id: project.id,
      region_id: regionId,
      model: modelId,
    });

    return nodeSizes;
  };
  return useQuery([project.id, regionId, modelId, "nodeSizes"], fetchNodeSizes, {
    refetchOnWindowFocus: false,
    /**
     * Keep previous data when fetching new data
     * This prevents page reflow when new node sizes is fetched
     */
    keepPreviousData: true,
    enabled: !!regionId && !!modelId,
  });
};
