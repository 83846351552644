//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchDeploymentProfiles = () => {
  const { selectedOrganization } = useDashboardContext();

  const fetchDeploymentProfiles = async () => {
    return await apiClients.deploymentProfileClient.ListDeploymentProfiles({
      organization_id: selectedOrganization.id,
    });
  };
  return useQuery([selectedOrganization.id, "deploymentProfiles"], fetchDeploymentProfiles);
};
