//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Header, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { Role as ApiRole } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  CommonContentAction,
  CommonContentActions,
  CommonKeyValuePair,
  CommonKeyValuePairs,
  CommonKV_Key,
  CommonKV_Value,
  Confirm,
  ConfirmInfo,
  ContentActionButtonDelete,
  ContentActionButtonEdit,
  ContentSegment,
  ErrorMessage,
  Loading,
  MainContent,
  Processing,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import PermissionList from "./PermissionList";

interface IRoleDetailsViewArgs extends RouteComponentProps {
  loading: boolean;
  role: ApiRole;
}

const RoleDetailsView = ({ ...args }: IRoleDetailsViewArgs) => (
  <div>
    <CommonKeyValuePairs>
      <CommonKeyValuePair>
        <CommonKV_Key>Created</CommonKV_Key>
        <CommonKV_Value>{args.role.created_at ? moment(args.role.created_at).fromNow() : "-"}</CommonKV_Value>
      </CommonKeyValuePair>
      <CommonKeyValuePair>
        <CommonKV_Key>Is Predefined</CommonKV_Key>
        <CommonKV_Value>{args.role.is_predefined ? "Yes" : "No"}</CommonKV_Value>
      </CommonKeyValuePair>
      {args.role.is_deleted && (
        <CommonKeyValuePair>
          <CommonKV_Key>Deleting</CommonKV_Key>
          <CommonKV_Value>{args.role.deleted_at ? moment(args.role.deleted_at).fromNow() : "-"}</CommonKV_Value>
        </CommonKeyValuePair>
      )}
    </CommonKeyValuePairs>
    <MainContent>
      <Header sub>Permissions</Header>
      <PermissionList {...args} />
    </MainContent>
  </div>
);

// Interface decribing the properties of the role details component
interface IRoleDetailsProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onRoleDeleted: (roleId: string) => void;
}

// Interface decribing the state of the role details component
interface IRoleDetailsState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  roleId?: string;
  role?: ApiRole;
}

class RoleDetails extends Component<IRoleDetailsProps, IRoleDetailsState> {
  state = {
    roleId: undefined,
    role: undefined,
  } as IRoleDetailsState;

  reloadRoleInfo = async () => {
    const idOptions = { id: this.state.roleId } as ApiIDOptions;
    const role = await apiClients.iamClient.GetRole(idOptions);
    if (!this.state.role) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadRoleInfo, role.url);
    }
    this.setState({ role: role });
  };

  getRoleName = () => {
    const role = this.state.role;
    if (role) {
      return role.name;
    }
    return "";
  };

  onClickDelete = async () => {
    const roleName = this.getRoleName();
    const confirmInfo = {
      header: "Delete Role",
      content: `Are you sure you want to delete role '${roleName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: this.state.roleId || "" } as ApiIDOptions;
      await apiClients.iamClient.DeleteRole(idOptions);
      this.props.onRoleDeleted && this.props.onRoleDeleted(this.state.roleId || "");
    } catch (e) {
      this.setState({ errorMessage: `Role deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  componentDidMount() {
    const roleId = (this.props.match.params as any).roleId;
    this.setState(
      {
        roleId: roleId,
      },
      () => {
        //notice inside the reloadRoleInfo we use the roleId
        this.props.refreshNow && this.props.refreshNow(this.reloadRoleInfo);
      }
    );
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Roles", Routes.dashboard_organization_rolesWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("role");
    const role = this.state.role || {};
    this.props.topMenuInfo.setTitles(role.name || "", role.description || "");
  };

  render() {
    const role = this.state.role;
    if (role) {
      return (
        <ContentSegment>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <Processing active={this.state.processing} message="Deleting role, please wait..." />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <CommonContentActions>
            <CommonContentAction>
              {this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(role.url || "", ResourceType.Role, "iam.role.update") && (
                <ContentActionButtonEdit
                  primary
                  disabled={this.state.processing}
                  onClick={() => {
                    HistoryHelper.push(
                      this.props.history,
                      Routes.dashboard_organization_role_editWithId(this.props.organization.id || "", this.state.roleId || ""),
                      this.props.topMenuInfo.getTitle()
                    );
                  }}
                />
              )}
            </CommonContentAction>
            <CommonContentAction>
              {this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(role.url || "", ResourceType.Role, "iam.role.delete") && (
                <ContentActionButtonDelete disabled={this.state.processing} onClick={this.onClickDelete} />
              )}
            </CommonContentAction>
            <Loader size="mini" active={this.props.loading} inline />
          </CommonContentActions>
          <RoleDetailsView {...this.props} role={role} />
        </ContentSegment>
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(RoleDetails);
