//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { HelpUrl } from "../../HelpUrl";

interface IDownloadClientToolsViewArgs {
  showModal: boolean;
  onClose: () => void;
}

export const DownloadClientToolsView = ({ ...args }: IDownloadClientToolsViewArgs) => {
  return (
    <Modal open={args.showModal} onClose={args.onClose}>
      <Modal.Header>
        <Icon name="download" className="secondary" />
        Download Client Tools
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="para">
            Get the ArangoDB client tools for your operating system from our{" "}
            <a className="text-link" target="_blank" href={HelpUrl.arangodb_client_tools_download_page} rel="noreferrer">
              download page
            </a>
            .
          </p>
          <p className="para">
            Note that you can use the client tools from the Community edition to import data into your ArangoGraph Insights Platform deployment.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary labelPosition="right" onClick={args.onClose} icon="check" content="OK" />
      </Modal.Actions>
    </Modal>
  );
};
