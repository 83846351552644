//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Form, Grid, Input, TextArea } from "semantic-ui-react";
import { IAMProvider as ApiIAMProvider, IAMProvider_LDAPSettings as ApiIAMProviderLDAPSettings } from "../../api/lib";
import { Section, SectionHeader, SectionContent, NumberInput } from "../../ui/lib";
import _ from "lodash";
import { Value } from "./StaticViews";

const defaultPort = 389;

interface ILdapSubViewArgs {
  editable: boolean;
  settings: ApiIAMProviderLDAPSettings;
  updateSettings: (updater: (input: ApiIAMProviderLDAPSettings) => ApiIAMProviderLDAPSettings) => void;
}

export const ServerView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updateServer = (value: string) =>
    args.updateSettings((x) => {
      x.server = value;
      return x;
    });
  const updatePort = (value: number) =>
    args.updateSettings((x) => {
      x.port = value;
      return x;
    });
  const updateTLSCACertificate = (value: string) =>
    args.updateSettings((x) => {
      x.tls_ca_certificate_pem = value;
      return x;
    });
  const updateRefreshRate = (value: number) =>
    args.updateSettings((x) => {
      x.refresh_rate = value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field required={args.editable}>
            <label>Server</label>
            {args.editable && (
              <Input
                autoFocus
                placeholder={`Server (e.g. "ldap.example.com"}`}
                name="server"
                value={settings.server}
                onChange={(e, d) => updateServer(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.server} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field required={args.editable}>
            <label>Port</label>
            {args.editable && <NumberInput min={1} max={64 * 1024 - 1} value={settings.port || defaultPort} onChange={updatePort} />}
            {!args.editable && <Value value={settings.port || defaultPort} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Server TLS certificate</label>
            {args.editable && (
              <TextArea
                name="tls"
                placeholder="PEM encoded public key of TLS certificate"
                value={settings.tls_ca_certificate_pem}
                onChange={(e, d) => updateTLSCACertificate(d.value as string)}
                rows={10}
              />
            )}
            {!args.editable && <Value value={settings.tls_ca_certificate_pem} pre />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Refresh rate (sec)</label>
            {args.editable && <NumberInput min={0} max={60 * 60 * 24 * 365} value={settings.refresh_rate || 0} onChange={updateRefreshRate} />}
            {!args.editable && <Value value={settings.refresh_rate || 0} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const BaseView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updateBaseDN = (value: string) =>
    args.updateSettings((x) => {
      x.base_distinguished_name = value;
      return x;
    });
  const updateBindDN = (value: string) =>
    args.updateSettings((x) => {
      x.bind_distinguished_name = value;
      return x;
    });
  const updateBindPassword = (value: string) =>
    args.updateSettings((x) => {
      x.bind_password = value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field required={args.editable}>
            <label>Base distinguished name</label>
            {args.editable && (
              <Input
                placeholder={`Base DSN (e.g. "dc=arangodb,dc=com"}`}
                name="server"
                value={settings.base_distinguished_name}
                onChange={(e, d) => updateBaseDN(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.base_distinguished_name} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field required={args.editable}>
            <label>Bind distinguished name</label>
            {args.editable && (
              <Input
                placeholder={`Bind DSN (e.g. "uid=arangoadmin,dc=arangodb,dc=com"}`}
                name="server"
                value={settings.bind_distinguished_name}
                onChange={(e, d) => updateBindDN(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.bind_distinguished_name} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {args.editable && (
        <Grid.Row>
          <Grid.Column width={16}>
            <Form.Input
              label="Bind password"
              placeholder={`Bind password (e.g. "supersecretpassword"}`}
              name="server"
              value={settings.bind_password}
              onChange={(e, d) => updateBindPassword(d.value)}
              type="password"
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export const SimpleAuthView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updatePrefix = (value: string) =>
    args.updateSettings((x) => {
      x.prefix = value;
      return x;
    });
  const updateSuffix = (value: string) =>
    args.updateSettings((x) => {
      x.suffix = value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Prefix</label>
            {args.editable && <Input placeholder={`Prefix`} name="prefix" value={settings.prefix} onChange={(e, d) => updatePrefix(d.value)} />}
            {!args.editable && <Value value={settings.prefix} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Suffix</label>
            {args.editable && <Input placeholder={`Suffix`} name="suffix" value={settings.suffix} onChange={(e, d) => updateSuffix(d.value)} />}
            {!args.editable && <Value value={settings.suffix} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const SearchAuthView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updateSearchScope = (value: string) =>
    args.updateSettings((x) => {
      x.search_scope = value;
      return x;
    });
  const updateSearchFilter = (value: string) =>
    args.updateSettings((x) => {
      x.search_filter = value;
      return x;
    });
  const updateSearchAttribute = (value: string) =>
    args.updateSettings((x) => {
      x.search_attribute = value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Search scope</label>
            {args.editable && (
              <Input placeholder={`Search scope`} name="search-scope" value={settings.search_scope} onChange={(e, d) => updateSearchScope(d.value)} />
            )}
            {!args.editable && <Value value={settings.search_scope} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Search filter</label>
            {args.editable && (
              <Input placeholder={`Search filter`} name="search-filter" value={settings.search_filter} onChange={(e, d) => updateSearchFilter(d.value)} />
            )}
            {!args.editable && <Value value={settings.search_filter} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Search attribute</label>
            {args.editable && (
              <Input
                placeholder={`Search attribute`}
                name="search-attribute"
                value={settings.search_attribute}
                onChange={(e, d) => updateSearchAttribute(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.search_attribute} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const RolesView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updateRolesAttributeName = (value: string) =>
    args.updateSettings((x) => {
      x.roles_attribute_name = value;
      return x;
    });
  const updateRolesSearch = (value: string) =>
    args.updateSettings((x) => {
      x.roles_search = value;
      return x;
    });
  const updateRolesInclude = (value: string) =>
    args.updateSettings((x) => {
      x.roles_include = value;
      return x;
    });
  const updateRolesExclude = (value: string) =>
    args.updateSettings((x) => {
      x.roles_exclude = value;
      return x;
    });
  const updateRolesTransformation = (value: string) =>
    args.updateSettings((x) => {
      x.roles_transformation = value;
      return x;
    });
  const updateSuperUserRole = (value: string) =>
    args.updateSettings((x) => {
      x.super_user_role = value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Roles search</label>
            {args.editable && (
              <Input placeholder={`Roles search`} name="roles-search" value={settings.roles_search} onChange={(e, d) => updateRolesSearch(d.value)} />
            )}
            {!args.editable && <Value value={settings.roles_search} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Roles attribute name</label>
            {args.editable && (
              <Input
                placeholder={`Roles attribute name`}
                name="roles-attributename"
                value={settings.roles_attribute_name}
                onChange={(e, d) => updateRolesAttributeName(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.roles_attribute_name} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Roles include</label>
            {args.editable && (
              <Input placeholder={`Roles include`} name="roles-include" value={settings.roles_include} onChange={(e, d) => updateRolesInclude(d.value)} />
            )}
            {!args.editable && <Value value={settings.roles_include} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Roles exclude</label>
            {args.editable && (
              <Input placeholder={`Roles exclude`} name="roles-exclude" value={settings.roles_exclude} onChange={(e, d) => updateRolesExclude(d.value)} />
            )}
            {!args.editable && <Value value={settings.roles_exclude} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Roles transformation</label>
            {args.editable && (
              <Input
                placeholder={`Roles transformation`}
                name="roles-transformation"
                value={settings.roles_transformation}
                onChange={(e, d) => updateRolesTransformation(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.roles_transformation} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Super user role</label>
            {args.editable && (
              <Input
                placeholder={`Super user role`}
                name="super-user-role"
                value={settings.super_user_role}
                onChange={(e, d) => updateSuperUserRole(d.value)}
              />
            )}
            {!args.editable && <Value value={settings.super_user_role} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const OtherView = ({ ...args }: ILdapSubViewArgs) => {
  const settings = args.settings;
  const updateSerialized = (value?: boolean) =>
    args.updateSettings((x) => {
      x.serialized = !!value;
      return x;
    });
  const updateSerializeTimeout = (value: number) =>
    args.updateSettings((x) => {
      x.serialize_timeout_sec = value;
      return x;
    });
  const updateRetries = (value: number) =>
    args.updateSettings((x) => {
      x.retries = value;
      return x;
    });
  const updateRestart = (value?: boolean) =>
    args.updateSettings((x) => {
      x.restart = !!value;
      return x;
    });
  const updateReferrals = (value?: boolean) =>
    args.updateSettings((x) => {
      x.referrals = !!value;
      return x;
    });
  const updateTimeout = (value: number) =>
    args.updateSettings((x) => {
      x.timeout_sec = value;
      return x;
    });
  const updateNetworkTimeout = (value: number) =>
    args.updateSettings((x) => {
      x.network_timeout_sec = value;
      return x;
    });
  const updateAsyncConnect = (value?: boolean) =>
    args.updateSettings((x) => {
      x.async_connect = !!value;
      return x;
    });

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Checkbox
            label="Serialized"
            name="serialized"
            checked={settings.serialized}
            onChange={(e, d) => updateSerialized(d.checked)}
            readOnly={!args.editable}
            slider
          />
        </Grid.Column>
      </Grid.Row>
      {settings.serialized && (
        <Grid.Row>
          <Grid.Column width={16}>
            <Form.Field>
              <label>Serialize timeout (sec)</label>
              {args.editable && <NumberInput min={0} max={60 * 60 * 24 * 365} value={settings.serialize_timeout_sec || 0} onChange={updateSerializeTimeout} />}
              {!args.editable && <Value value={settings.serialize_timeout_sec || 0} />}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Retries</label>
            {args.editable && <NumberInput min={0} max={5} value={settings.retries || 0} onChange={updateRetries} />}
            {!args.editable && <Value value={settings.retries || 0} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Checkbox
            label="Restart"
            name="restart"
            checked={settings.restart}
            onChange={(e, d) => updateRestart(d.checked)}
            readOnly={!args.editable}
            slider
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Checkbox
            label="Referrals"
            name="referrals"
            checked={settings.referrals}
            onChange={(e, d) => updateReferrals(d.checked)}
            readOnly={!args.editable}
            slider
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Timeout (sec)</label>
            {args.editable && <NumberInput min={0} max={60} value={settings.timeout_sec || 0} onChange={updateTimeout} />}
            {!args.editable && <Value value={settings.timeout_sec || 0} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
            <label>Network timeout (sec)</label>
            {args.editable && <NumberInput min={0} max={60} value={settings.network_timeout_sec || 0} onChange={updateNetworkTimeout} />}
            {!args.editable && <Value value={settings.network_timeout_sec || 0} />}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Checkbox
            label="Async connect"
            name="async-connect"
            checked={settings.async_connect}
            onChange={(e, d) => updateAsyncConnect(d.checked)}
            readOnly={!args.editable}
            slider
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface ILdapViewArgs {
  editable: boolean;
  iamprovider: ApiIAMProvider;
  onUpdate: (value: ApiIAMProvider) => void;
}

export const LdapView = ({ ...args }: ILdapViewArgs) => {
  const data = {
    editable: args.editable,
    settings: args.iamprovider.ldap_settings || {},
    updateSettings: (updater: (input: ApiIAMProviderLDAPSettings) => ApiIAMProviderLDAPSettings) => {
      const clone = _.cloneDeep(args.iamprovider);
      clone.ldap_settings = updater(clone.ldap_settings || {});
      args.onUpdate(clone);
    },
  };

  return (
    <Grid columns="16">
      <Grid.Row>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Server settings" />
            <SectionContent>
              <ServerView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Connection settings" />
            <SectionContent>
              <BaseView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Simple authentication" />
            <SectionContent>
              <SimpleAuthView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Search authentication" />
            <SectionContent>
              <SearchAuthView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Roles" />
            <SectionContent>
              <RolesView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
        <Grid.Column width="8">
          <Section>
            <SectionHeader title="Other" />
            <SectionContent>
              <OtherView {...data} />
            </SectionContent>
          </Section>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
