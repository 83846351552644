//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _, { isEmpty } from "lodash";
import styled from "@emotion/styled";
import React, { Component, useEffect, useState } from "react";
import { Dropdown, Card, Grid, Icon, Segment } from "semantic-ui-react";
import {
  CreatePaymentMethodRequest as ApiCreatePaymentMethodRequest,
  Currency as ApiCurrency,
  CurrencyList as ApiCurrencyList,
  IDOptions as ApiIDOptions,
  ListPaymentMethodsRequest as ApiListPaymentMethodsRequest,
  ListPaymentProvidersRequest as ApiListPaymentProvidersRequest,
  Organization as ApiOrganization,
  PaymentMethod as ApiPaymentMethod,
  PaymentMethodList as ApiPaymentMethodList,
  PaymentProviderList as ApiPaymentProviderList,
  PreparedPaymentMethod as ApiPreparedPaymentMethod,
  PreparePaymentMethodRequest as ApiPreparePaymentMethodRequest,
  SetDefaultPaymentMethodRequest as ApiSetDefaultPaymentMethodRequest,
  AvailableCredits_CreditCurrencyAmount,
  GetAvailableCreditsRequest,
} from "../../api/lib";
import apiClients from "../../api/apiclients";

import { Cached as cachedApiClients } from "../../api/apiclients";
import {
  Confirm,
  ConfirmInfo,
  ContentActionButtonNew,
  ErrorMessage,
  FloatRight,
  FormActionButtonDropdown,
  Loading,
  Processing,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  UnderlinedH3,
} from "../../ui/lib";
import CreatePaymentMethod from "./CreatePaymentMethod";
import { ITracking } from "../../tracking/api";
import { reportError } from "../../errors/reporting";
import VouchersView from "./VouchersView";

interface ICardArgs {
  paymentMethod: ApiPaymentMethod;
  currency?: ApiCurrency;
  onSetAsDefault: () => void;
  onDelete: () => void;
}

const DropdownWithoutIcon = styled(Dropdown)`
  .hide.icon {
    display: none;
  }
`;

const CreditCardView = ({ ...args }: ICardArgs) => {
  const ccInfo = args.paymentMethod.credit_card_info || {};
  const currency = args.currency || {};
  const is_default = !!args.paymentMethod.is_default;
  return (
    <Card className={args.paymentMethod.is_default ? "card--active" : ""}>
      <div className="card-indicator">
        <Icon name="checkmark" className="card-indicator__icon" />
      </div>
      <Card.Content>
        <Grid width="16">
          <Grid.Row>
            <Grid.Column width="16">
              <FloatRight>
                {!is_default && (
                  <DropdownWithoutIcon trigger={<FormActionButtonDropdown title="Edit" primary />} icon="hide">
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={args.onSetAsDefault}>Set as default</Dropdown.Item>
                      <Dropdown.Item onClick={args.onDelete}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </DropdownWithoutIcon>
                )}
              </FloatRight>
              <div>
                <Icon name="credit card" />
                {args.paymentMethod.name}
              </div>
              {args.paymentMethod.description && <div>{args.paymentMethod.description}</div>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="6">
              <UnderlinedH3>Number</UnderlinedH3>
              <div>.... .... .... {ccInfo.last_digits}</div>
            </Grid.Column>
            <Grid.Column width="4">
              <UnderlinedH3>Type</UnderlinedH3>
              <div>Credit card</div>
            </Grid.Column>
            <Grid.Column width="4">
              <UnderlinedH3>Currency</UnderlinedH3>
              <div>{currency.name || "?"}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

const CardView = ({ ...args }: ICardArgs) => {
  if (args.paymentMethod.type == "creditcard") {
    return CreditCardView(args);
  }
  return (
    <Card>
      <Card.Content>
        <div>
          {args.paymentMethod.id} / {args.paymentMethod.type}
        </div>
      </Card.Content>
    </Card>
  );
};

interface ListViewArgs {
  list?: ApiPaymentMethodList;
  currencies?: ApiCurrencyList;
  organization?: ApiOrganization;
  onError: (err: string) => void;
  onSetAsDefault: (id: string) => void;
  onDelete: (id: string) => void;
}

const ListView = ({ ...args }: ListViewArgs) => {
  const [vouchers, setVouchers] = useState<AvailableCredits_CreditCurrencyAmount[] | undefined>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const has_list = !!args.list;
  const has_currencies = !!args.currencies;
  const list = args.list || {};
  const items = list.items || [];
  const currenciesList = args.currencies || {};
  const currencies = currenciesList.items || [];

  const getVouchers = async () => {
    setError(undefined);
    const { organization = {} } = args;
    try {
      const req: GetAvailableCreditsRequest = {
        organization_id: organization.id,
      };
      const response = await apiClients.billingClient.GetAvailableCredits(req);
      setVouchers(response.credits);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    items.filter((item) => item.type === "credit").length > 0 && getVouchers();
  }, [items]);

  if (!has_list || !has_currencies) {
    return <Loading message="Loading payment methods..." />;
  }
  if (isEmpty(items) && !vouchers) {
    return <Segment>No payment methods yet</Segment>;
  }
  const cards = items.map((item) => {
    if (item.type === "credit") {
      return <VouchersView vouchers={vouchers} />;
    }
    return (
      <CardView
        key={item.id}
        paymentMethod={item}
        currency={_.find(currencies, (currency) => currency.id == item.currency_id)}
        onSetAsDefault={() => args.onSetAsDefault(item.id || "")}
        onDelete={() => args.onDelete(item.id || "")}
      />
    );
  });
  return (
    <>
      <ErrorMessage message={error} active={!!error} />
      <Card.Group itemsPerRow="2">{cards}</Card.Group>
    </>
  );
};

interface IPaymentMethodsAPI {
  ListPaymentMethods: (req: ApiListPaymentMethodsRequest) => Promise<ApiPaymentMethodList>;
  ListPaymentProviders: (req: ApiListPaymentProvidersRequest) => Promise<ApiPaymentProviderList>;
  PreparePaymentMethod: (req: ApiPreparePaymentMethodRequest) => Promise<ApiPreparedPaymentMethod>;
  CreatePaymentMethod: (req: ApiCreatePaymentMethodRequest) => Promise<ApiPaymentMethod>;
  SetDefaultPaymentMethod: (req: ApiSetDefaultPaymentMethodRequest) => Promise<void>;
  DeletePaymentMethod: (req: ApiIDOptions) => Promise<void>;
}

// Interface decribing the properties of the payment methods component
interface IPaymentMethodsProps {
  api: IPaymentMethodsAPI;
  organization: ApiOrganization;
  tracking: ITracking;
  isAddAllowed: boolean;
  isAddEnabled: boolean;
  isUpdateAllowed: boolean;
  isDeleteAllowed: boolean;
  onReloadOrganization: () => void;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

// Interface decribing the state of the payment methods component
interface IPaymentMethodsState {
  list?: ApiPaymentMethodList;
  currencies?: ApiCurrencyList;
  refreshNeeded: boolean;
  last_organization_id?: string;
  errorMessage?: string;
  adding: boolean;
  settingDefault: boolean;
  deleting: boolean;
  confirmInfo?: ConfirmInfo;
}

class PaymentMethods extends Component<IPaymentMethodsProps, IPaymentMethodsState> {
  state = {
    list: undefined,
    currencies: undefined,
    refreshNeeded: false,
    errorMessage: undefined,
    adding: false,
    settingDefault: false,
    deleting: false,
  } as IPaymentMethodsState;

  reloadCurrencies = async () => {
    const list = await cachedApiClients.currencyClient.ListCurrencies({});
    this.setState({ currencies: list });
  };

  refreshCurrencies = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCurrencies);
  };

  reloadPaymentMethods = async () => {
    const req = { organization_id: this.props.organization.id } as ApiListPaymentMethodsRequest;
    const list = await this.props.api.ListPaymentMethods(req);
    this.setState({ list: list });
  };

  refreshPaymentMethods = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadPaymentMethods);
  };

  static getDerivedStateFromProps(props: IPaymentMethodsProps, state: IPaymentMethodsState) {
    if (props.organization.id != state.last_organization_id) {
      return {
        list: undefined,
        adding: false,
        settingDefault: false,
        deleting: false,
        confirmInfo: undefined,
        refreshNeeded: true,
        last_organization_id: props.organization.id,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshCurrencies();
    this.refreshPaymentMethods();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshPaymentMethods);
    }
  }

  onAdd = () => {
    this.setState({
      adding: true,
      errorMessage: undefined,
    });
  };

  onCloseAdd = () => {
    this.setState({
      adding: false,
      errorMessage: undefined,
    });
    this.props.onReloadOrganization();
    this.refreshPaymentMethods();
  };

  onSetAsDefault = async (id: string) => {
    this.setState({ settingDefault: true, errorMessage: undefined });
    try {
      const req = {
        organization_id: this.props.organization.id,
        payment_method_id: id,
      } as ApiSetDefaultPaymentMethodRequest;
      await this.props.api.SetDefaultPaymentMethod(req);
      this.refreshPaymentMethods();
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    } finally {
      this.setState({ settingDefault: false });
    }
  };

  onDelete = (id: string) => {
    const confirmInfo = {
      header: "Delete payment method",
      content: `Are you sure you want to delete this payment method?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onDeleteConfirmed = async (id: string) => {
    this.setState({ deleting: true, errorMessage: undefined, confirmInfo: undefined });
    try {
      const req = {
        id: id,
      } as ApiIDOptions;
      await this.props.api.DeletePaymentMethod(req);
      this.refreshPaymentMethods();
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    } finally {
      this.setState({ deleting: false });
    }
  };

  render() {
    const has_list = !!this.state.list;
    return (
      <Section>
        <SectionHead>
          <SectionHeader title="Payment methods" />
          {this.props.isAddAllowed && (
            <SectionButtons>
              <ContentActionButtonNew primary content="Add" disabled={!has_list || !this.props.isAddEnabled} onClick={this.onAdd} />
            </SectionButtons>
          )}
          {this.state.adding && (
            <CreatePaymentMethod
              tracking={this.props.tracking}
              api={this.props.api}
              organization={this.props.organization}
              refreshNow={this.props.refreshNow}
              onClose={this.onCloseAdd}
            />
          )}
        </SectionHead>
        <SectionContent>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <Processing active={this.state.settingDefault} message="Changing default payment method" />
          <ListView
            {...this.props}
            {...this.state}
            list={this.state.list || {}}
            onSetAsDefault={this.onSetAsDefault}
            onDelete={this.onDelete}
            onError={(err: string) => {
              this.setState({ errorMessage: err });
            }}
          />
        </SectionContent>
      </Section>
    );
  }
}

export default PaymentMethods;
