import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const useDeploymentPermissions = () => {
  const { checkDeploymentPermission } = useCheckPermissionByUrl();

  const isDeploymentEditAllowed = checkDeploymentPermission("data.deployment.update");
  const isDeploymentDeleteAllowed = checkDeploymentPermission("data.deployment.delete");

  const isNewRoleBindingAllowed = checkDeploymentPermission("iam.policy.update");

  const isGetRootPasswordAllowed = checkDeploymentPermission("data.deploymentcredentials.get");
  const isRotateServerAllowed = checkDeploymentPermission("data.deployment.rotate-server");

  const isListMetricsTokensAllowed = checkDeploymentPermission("metrics.token.list");
  const isPrivateEndpointFeatureAllowed = checkDeploymentPermission("network.privateendpointservice.get-feature");
  const isGetAuditLogAttachmentAllowed = checkDeploymentPermission("audit.auditlogattachment.get");
  const canUpdateRootPasswordRotationSchedule = checkDeploymentPermission("data.deployment.update-scheduled-root-password-rotation");

  const canPauseDeployement = checkDeploymentPermission("data.deployment.pause");

  const isCopyBackupAllowed = checkDeploymentPermission("backup.backup.copy");

  return {
    isDeploymentEditAllowed,
    isDeploymentDeleteAllowed,
    isNewRoleBindingAllowed,
    isRotateServerAllowed,
    isGetRootPasswordAllowed,
    isListMetricsTokensAllowed,
    isPrivateEndpointFeatureAllowed,
    isGetAuditLogAttachmentAllowed,
    canUpdateRootPasswordRotationSchedule,
    canPauseDeployement,
    isCopyBackupAllowed,
  };
};
