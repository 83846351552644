//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { ListRegionsRequest } from "../../api/lib";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchRegions = ({ providerId, modelId }: { providerId: string | undefined; modelId: string | undefined }) => {
  const { selectedOrganization } = useDashboardContext();
  const fetchRegions = async () => {
    const listReq = {
      provider_id: providerId,
      organization_id: selectedOrganization.id,
      model_id: modelId,
    } as ListRegionsRequest;
    const data = await apiClients.platformClient.ListRegions(listReq);

    if (data.items) {
      const newItems = [...data.items].sort((itemA, itemB) => {
        return (itemB.priority ? itemB.priority : 0) - (itemA.priority ? itemA.priority : 0);
      });
      const newData = { ...data, items: newItems };
      return newData;
    }

    return data;
  };

  // this is a little complex as
  // we don't want to update the regions for every model change,
  // only when 'free' is selected
  // so for everything else setting it to empty string
  const modelIdKey = modelId === "free" ? "free" : "";

  return useQuery([selectedOrganization.id, providerId, modelIdKey, "regions"], fetchRegions, {
    refetchOnWindowFocus: false,
    enabled: !!providerId && !!selectedOrganization.id,
  });
};
