//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ChevronDownIcon } from "@chakra-ui/icons";
import { Collapse, Flex, Grid, Heading, useDisclosure } from "@chakra-ui/react";
import React from "react";

export const TogglableSection = ({ children, title, defaultIsOpen = true }: { children: React.ReactNode; title: string; defaultIsOpen?: boolean }) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen,
  });

  return (
    <Flex direction="column" gap="4">
      <Grid
        paddingY="4"
        alignItems="center"
        templateColumns="1fr 40px"
        onClick={onToggle}
        _hover={{ cursor: "pointer", bg: "gray.200" }}
        backgroundColor="gray.50"
        paddingX="5"
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Heading size="md">{title}</Heading>
        <ChevronDownIcon transform={isOpen ? "rotate(180deg)" : ""} />
      </Grid>
      <Collapse in={isOpen} animateOpacity>
        <Flex direction="column" gap="4" paddingX="5" paddingBottom="4">
          {children}
        </Flex>
      </Collapse>
    </Flex>
  );
};
