//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Divider, Form, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import copy from "copy-to-clipboard";
import { IServiceAttachmentArgs } from "../types";

const GCPServiceAttachmentView = ({ onClose, onServiceAttachmentCopy, initialValue = "" }: IServiceAttachmentArgs) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      onServiceAttachmentCopy && onServiceAttachmentCopy(initialValue);
      onClose && onClose();
    }
  }, [copied]);

  const handleCopy = () => {
    copy(initialValue);
    setCopied(true);
  };

  return (
    <Modal open onClose={onClose} data-testid="serviceAttachmentModal">
      <Modal.Header data-testid="serviceAttachmentModalHeader">Service Attachment </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input data-testid="serviceAttachmentInputField" value={initialValue} label="GCP Service Attachment" />
          </Form>
          <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="serviceAttachmentViewCloseAction" />
        <FormActionButtonSave title="Copy & Close" icon={copied ? "check" : "copy"} primary onClick={handleCopy} testID="serviceNameCopyButton" />
      </Modal.Actions>
    </Modal>
  );
};

export { GCPServiceAttachmentView };
