import React from "react";
import { Icon } from "semantic-ui-react";
import { OrganizationList } from "../../api/lib";
import { Routes } from "../../routes";
import { SidebarHeading } from "./SidebarHeading";
import { SidebarLink } from "./SidebarLink";
import { SidebarRightWrap } from "./SidebarRightWrap";
import styled from "@emotion/styled";
import { OrganizationSelector } from "../OrganizationSelector";
import { useFeature } from "flagged";

const StyledIcon = styled(Icon)`
  &&& {
    height: 20px;
    width: 8px;
    margin-right: 6px;
    line-height: 22px;
  }
`;
export const ProjectSidebar = ({
  onClickSupportRequest,
  organizations,
  onOpenInternalDashboard,
  orgId,
  projectMatch,
}: {
  onClickSupportRequest: () => void;
  organizations: OrganizationList | undefined;
  onOpenInternalDashboard: () => void;
  orgId: string;
  projectMatch: {
    params: {
      projectId: string;
    };
  };
}) => {
  const isAuditLogEnabled = useFeature("auditlog");
  return (
    <SidebarRightWrap onClickSupportRequest={onClickSupportRequest} organizationsList={organizations} onOpenInternalDashboard={onOpenInternalDashboard}>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_organization_projectsWithId(orgId),
        }}
      >
        <StyledIcon name="angle left" />
        Back to projects
      </SidebarLink>
      <OrganizationSelector />
      <SidebarHeading>Project Details</SidebarHeading>
      <SidebarLink to={{ pathname: Routes.dashboard_project_deploymentsWithId(projectMatch.params.projectId) }} icon="database">
        Deployments
      </SidebarLink>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_securityWithId(projectMatch.params.projectId),
        }}
        icon="shield"
      >
        Security
      </SidebarLink>

      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_policyWithId(projectMatch.params.projectId),
        }}
        icon="file alternate outline"
      >
        Policy
      </SidebarLink>
      <SidebarLink
        show={!!isAuditLogEnabled}
        to={{
          pathname: Routes.dashboard_project_auditlogWithId(projectMatch.params.projectId),
        }}
        icon="clipboard list"
      >
        Audit Log
      </SidebarLink>
      <SidebarHeading>Delete/Lock</SidebarHeading>
      <SidebarLink
        to={{
          pathname: Routes.dashboard_project_dangerzoneWithId(projectMatch.params.projectId),
        }}
        icon="trash"
        iconColor="red"
      >
        Delete/Lock
      </SidebarLink>
    </SidebarRightWrap>
  );
};
