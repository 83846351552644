import { Deployment, DeploymentFeaturesRequest } from "../../api/lib";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import apiClients from "../../api/apiclients";

export const useDeploymentFeatures = () => {
  const { checkDeploymentPermission } = useCheckPermissionByUrl();

  const canGetFeatures = checkDeploymentPermission("data.deploymentfeatures.get");

  const fetchDeploymentFeatures = async (deployment: Deployment) => {
    if (!canGetFeatures) {
      return { error: "Permission denied" };
    }

    const { model = {}, region_id, project_id } = deployment;
    const { node_size_id, model: modelId } = model || {};

    const req: DeploymentFeaturesRequest = {
      project_id,
      model: modelId,
      node_size_id,
      region_id,
    };
    try {
      const features = await apiClients.dataClient.GetDeploymentFeatures(req);
      return { features };
    } catch (error) {
      return { error };
    }
  };

  return {
    fetchDeploymentFeatures,
  };
};
