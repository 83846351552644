//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Message, MessageProps } from "semantic-ui-react";
import styled from "@emotion/styled";
import _ from "lodash";

interface IErrorMessageViewArgs {
  active: boolean;
  onDismiss?: (event: React.MouseEvent<HTMLElement>, data: MessageProps) => void;
  message?: string | unknown;
  testID?: string;
  header?: string;
  className?: string;
}

const StickyMessage = styled(Message)`
  position: sticky !important;
  top: 0 !important;
  z-index: 9999;
  background: var(--red-50) !important;
`;

export const ErrorMessage = ({ ...args }: IErrorMessageViewArgs) => {
  if (args.active)
    return (
      <StickyMessage
        data-testid={args.testID}
        error
        color="red"
        onDismiss={args.onDismiss}
        header={!!args.header ? args.header : "An error occurred, please retry."}
        content={_.toString(args.message || "")}
      />
    );
  return null;
};
