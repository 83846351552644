//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import moment from "moment";
import React from "react";
import { Table } from "semantic-ui-react";
import { Token as ApiToken } from "../../../api/lib";
import { ListActionDelete, ListActionRevoke } from "../../../ui/lib";
import { IWithRefreshProps } from "../../../util/WithRefresh";

interface IListMetricsTokensViewArgs extends IWithRefreshProps {
  tokens: ApiToken[];
  onClickRevoke: (token: ApiToken) => void;
  onClickDelete: (token: ApiToken) => void;
}

export const ListMetricsTokensView = ({ ...args }: IListMetricsTokensViewArgs) => {
  const hasTokens = !_.isEmpty(args.tokens);
  if (!hasTokens) {
    return <div>There are currently no metrics tokens.</div>;
  }
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Created</Table.HeaderCell>
        <Table.HeaderCell>Revoked</Table.HeaderCell>
        <Table.HeaderCell>Expires</Table.HeaderCell>
        <Table.HeaderCell>Deleted</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {args.tokens.map((x, i) => (
          <RowView key={`row${i}`} token={x} onClickRevoke={() => args.onClickRevoke(x)} onClickDelete={() => args.onClickDelete(x)} />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IRowViewArgs {
  token: ApiToken;
  onClickRevoke: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowViewArgs) => {
  const isExpired = !!args.token.is_expired;
  const isRevoked = !!args.token.is_revoked;
  const isDeleted = !!args.token.is_deleted;
  return (
    <Table.Row>
      <Table.Cell>{args.token.name || "?"}</Table.Cell>
      <Table.Cell>{args.token.description || ""}</Table.Cell>
      <Table.Cell>{moment(args.token.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{isRevoked ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>
        {isExpired && <span>Expired</span>}
        {!isExpired && <span>{moment(args.token.expires_at).fromNow()}</span>}
      </Table.Cell>
      <Table.Cell>
        {isDeleted && <span>{moment(args.token.deleted_at).fromNow()}</span>}
        {!isDeleted && <span>-</span>}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          <ListActionRevoke tooltip="Revoke token" onClick={args.onClickRevoke} />
          <ListActionDelete tooltip="Delete token" onClick={args.onClickDelete} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
