//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Grid, InputOnChangeData } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser } from "../../api/lib";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActions,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { ITracking } from "../../tracking/api";

interface ICreateOrganizationViewArgs extends ICreateOrganizationState, ICreateOrganizationProps {
  active: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  createOrganization: () => void;
}

const CreateOrganizationView = ({ ...args }: ICreateOrganizationViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Creating organization, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      <Form>
        <Section>
          <SectionHeader title="General" />
          <SectionContent>
            <Grid>
              <Grid.Row columns={16}>
                <Grid.Column width={4}>
                  <Form.Input
                    autoFocus
                    required
                    label="Name"
                    placeholder='Name (e.g. "My company name")'
                    name="name"
                    value={args.name}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    label="Short description"
                    placeholder="Description"
                    name="description"
                    value={args.description}
                    onChange={args.updateFormInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SectionContent>
        </Section>
        <FormContentActions>
          <FormContentAction>
            <FormActionButtonCreate primary disabled={!args.active} onClick={args.createOrganization} />
          </FormContentAction>
          <FormContentAction>
            <FormActionButtonCancel onClick={args.history.goBack} />
          </FormContentAction>
        </FormContentActions>
      </Form>
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the CreateOrganization component
interface ICreateOrganizationProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  tracking: ITracking;
  onNewOrganizationCreated: (organizationId: string) => void;
}

// Interface decribing the state of the CreateOrganization component
interface ICreateOrganizationState {
  user?: ApiUser;
  errorMessage?: string;
  processing: boolean;
  name: string;
  description: string;
}

// Component to create an organization
class CreateOrganization extends Component<ICreateOrganizationProps, ICreateOrganizationState> {
  state = {
    errorMessage: undefined,
    processing: false,
    name: "",
    description: "",
  } as ICreateOrganizationState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  createOrganization = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const organization = {
        name: this.state.name,
        description: this.state.description,
      } as ApiOrganization;
      const createdOrganization = await apiClients.resourceManagerClient.CreateOrganization(organization);
      this.props.onNewOrganizationCreated(createdOrganization.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Organization creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem("My account", Routes.dashboard_user_details),
      new BreadCrumbItem((this.state.user && this.state.user.name) || "", Routes.dashboard_user_details),
      new BreadCrumbItem("Organizations", Routes.dashboard_user_organizations)
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("organization");
    this.props.topMenuInfo.setTitles(this.state.name || "New Organization", "");
  };

  render() {
    return (
      <CreateOrganizationView
        {...this.props}
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        createOrganization={this.createOrganization}
      />
    );
  }
}

export default withRefresh()(CreateOrganization);
