//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser } from "../../api/lib";
import { Organization as ApiOrganization, OrganizationList as ApiOrganizationList } from "../../api/resourcemanager/v1/resourcemanager";
import { ErrorMessage, ListActionDelete, ListActionView, LoaderBoxForTable as LoaderBox, Loading, MainContent, TextLink, Locked } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import { useWithRefresh } from "../../util/WithRefreshContext";

// Interface describing an organization
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Tier</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing an organization
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiOrganization;
  onClickView: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const locked = !!args.item.locked;
  return (
    <Table.Row>
      <Table.Cell>{args.item.id}</Table.Cell>
      <Table.Cell>
        <TextLink
          label={args.item.name}
          onClick={args.onClickView}
          disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.Organization, "resourcemanager.organization.get")}
        />
        {locked && <Locked />}
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{(args.item.tier || {}).name}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.Organization, "resourcemanager.organization.get") && (
            <ListActionView disabled={!args.active} onClick={args.onClickView} />
          )}
          {args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.Organization, "resourcemanager.organization.delete") && (
            <ListActionDelete disabled={!args.active || locked} onClick={args.onClickDelete} />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

// Interface describing the organization list
interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  items: ApiOrganization[];
  loading: boolean;
  onClickView: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No organization available</div>;

// Interface describing the organization list view arguments
export interface IOrganizationListViewArgs extends IWithRefreshProps, RouteComponentProps {
  active: boolean;
  loading: boolean;
  organizations?: ApiOrganizationList;
  onClickView: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const OrganizationListView = ({ ...args }: IOrganizationListViewArgs) => {
  if (!args.organizations) {
    return <Loading />;
  }
  if (!args.organizations.items || args.organizations.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.organizations.items} loading={args.loading} />;
};

// Interface decribing the properties of the organization list component
interface IOrganizationListProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onOrganizationSelected: (organizationID: string) => void;
  onDeleteOrganization: (organizationID: string) => void;
  onClickDeleteConfirmed: (organizationID: string) => void;
  processing: boolean;
  organizations?: ApiOrganizationList;
}

// Interface decribing the state of the organization list component
interface IOrganizationListState {
  user?: ApiUser;
  errorMessage?: string;
}

// The component to show the projects inside an organization as a list.
class OrganizationList extends Component<IOrganizationListProps, IOrganizationListState> {
  state = {
    user: undefined,
    errorMessage: undefined,
  } as IOrganizationListState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  onClickView = (id: string) => {
    this.props.onOrganizationSelected(id);
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  render() {
    return (
      <div>
        <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
        <MainContent>
          <OrganizationListView
            {...this.props}
            {...this.state}
            organizations={this.props.organizations}
            active={!this.props.processing}
            onClickView={this.onClickView}
            onClickDelete={this.props.onDeleteOrganization}
          />
        </MainContent>
      </div>
    );
  }
}

export default withRefresh()(OrganizationList);
