import React from "react";
import { Grid, Button, Container } from "semantic-ui-react";
import { IWithSignupModalProps } from "../SignupModalView";
import screenshot from "./screenshot.png";
import logo3 from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../../ui/_box";
import heroBlob from "./home-blob.png";
import { useBreakpointValue } from "@chakra-ui/react";

export interface IHomeViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
  loginWithSSO?: (organizationId?: string) => void;
}

const HeroSection = ({ ...args }: IHomeViewArgs) => {
  const showOnDesktop = useBreakpointValue({ base: false, md: true }); // Show on medium screens (desktops) and above

  return (
    <Box position="relative">
      <Box position="absolute" left={0} top={0} zIndex={"-1"} opacity={0.3}>
        <img src={heroBlob} alt="bgBackgroundBlog" height={800} />
      </Box>
      <Box padding={"60px 0 60px 0"}>
        <Container>
          <Grid columns="16" stackable padded relaxed tablet={8} mobile={12}>
            <Grid.Row centered>
              <Grid.Column computer={6} tablet={14} mobile={16} textAlign="left">
                <Box position="relative" left={"-20px"}>
                  <img src={logo3} alt="ArangoGraph" title="ArangoGraph" width={"100%"} />
                </Box>
                <Box color="#293619" fontWeight={500} fontSize={"2.2rem"} lineheight={1.3}>
                  Where Cloud Meets performance
                </Box>
                <Box fontSize={"1.4rem"} fontWeight={500} lineheight={1.3} padding={"16px 0 0 0"} color="#5a6e3e">
                  ArangoGraph supports many cloud deployment regions across the 3 main cloud providers, and if you need on-prem for other use-cases, you can
                  always deploy self-managed too.
                </Box>
                <Box margin={"20px 0"}>
                  <Button primary onClick={args.onClickSignup} size="large" className="width-full">
                    <Box padding={"10px"} fontSize={"1.4rem"}>
                      Start Free
                    </Box>
                  </Button>
                  <Box color="var(--green-700)" fontSize={"1.2rem"} fontWeight={600} width={"100%"} textAlign="center" padding={"15px 0"}>
                    No credit card needed to get started .
                  </Box>
                </Box>
              </Grid.Column>
              <Grid.Column computer={1} tablet={14} mobile={16} textAlign="center"></Grid.Column>
              <Grid.Column computer={9} tablet={14} mobile={16} verticalAlign="middle">
                {showOnDesktop && (
                  <Box boxShadow="0 0 12px 0 var(--green-400)" position="relative" top={"20px"}>
                    <img src={screenshot} alt="App Screenshot" width={"100%"} />
                  </Box>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default HeroSection;
