//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEqual } from "lodash";
import { DataSet } from "vis-data";
import { Node, Edge } from "vis-network";
import { useDataloaderStore } from "../../DataloaderStore";
import { isEdgeReadyForImport, isNodeReadyForImport } from "../../utils";
import {
  edgeReadyForImportExtraOptions,
  edgeNotReadyForImportExtraOptions,
  nodeNotReadyForImportExtraOptions,
  nodeReadyForImportExtraOptions,
} from "../graphOptions";

export const useDatasetEvents = ({ nodes, edges }: { nodes: DataSet<Node, "id">; edges: DataSet<Edge, "id"> }) => {
  const { updateDeploymentData } = useDataloaderStore();

  nodes.on("remove", () => {
    if (isEqual(nodes.get(), useDataloaderStore.getState().getDeploymentDataloaderState().nodes)) return;

    updateDeploymentData({ nodes: nodes.get() });
    updateDeploymentData({ edges: edges.get() });
  });

  edges.on("remove", () => {
    if (isEqual(edges.get(), useDataloaderStore.getState().getDeploymentDataloaderState().edges)) return;

    updateDeploymentData({ edges: edges.get() });
  });

  nodes.on("update", () => {
    const nodesToStore = nodes.get();
    if (isEqual(nodesToStore, useDataloaderStore.getState().getDeploymentDataloaderState().nodes)) return;

    const resultNodes = nodesToStore.map((node) =>
      isNodeReadyForImport(node) ? { ...node, ...nodeReadyForImportExtraOptions } : { ...node, ...nodeNotReadyForImportExtraOptions }
    );

    updateDeploymentData({ nodes: resultNodes });
  });

  edges.on("update", () => {
    const edgesToStore = edges.get();
    if (isEqual(edgesToStore, useDataloaderStore.getState().getDeploymentDataloaderState().edges)) return;

    const resultEdges = edgesToStore.map((edge) =>
      isEdgeReadyForImport(edge) ? { ...edge, ...edgeReadyForImportExtraOptions } : { ...edge, ...edgeNotReadyForImportExtraOptions }
    );

    updateDeploymentData({ edges: resultEdges });
  });

  nodes.on("add", () => {
    if (isEqual(nodes.get(), useDataloaderStore.getState().getDeploymentDataloaderState().nodes)) return;

    const existingNodes = useDataloaderStore.getState().getDeploymentDataloaderState().nodes;
    const updatedNodes = nodes.get();

    const newNodes = updatedNodes.filter((node) => !existingNodes.find((existingNode) => existingNode.id === node.id));

    updateDeploymentData({ nodes: [...existingNodes, ...newNodes] });
  });

  edges.on("add", () => {
    if (isEqual(edges.get(), useDataloaderStore.getState().getDeploymentDataloaderState().edges)) return;

    const existingEdges = useDataloaderStore.getState().getDeploymentDataloaderState().edges;
    const updatedEdges = edges.get();

    const newEdges = updatedEdges.filter((edge) => !existingEdges.find((existingEdge) => existingEdge.id === edge.id));

    updateDeploymentData({ edges: [...existingEdges, ...newEdges] });
  });
};
