//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { Component } from "react";
import { Button, Grid } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "../home/HomeTemplate";
import { IWithSignupModalProps, withSignupModal } from "../home/SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../ui/_box";

const ButtonsBox = styled("div")`
  margin-top: 48px;
`;

interface IAccessDeniedViewArgs extends IWithSignupModalProps {
  reason: string;
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onClickLogout: () => void;
  onSignup: () => void;
}

const AccessDeniedView = ({ ...args }: IAccessDeniedViewArgs) => {
  const has_reason = !!args.reason;
  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
              <h1 className="heading-1">Access denied</h1>
            </Grid.Column>
            {false && (
              <Grid.Column width="8" only="computer tablet">
                <TransparentLogo1 src={cloud} />
                <TransparentLogo2 src={cloud} />
                {/*<!-- FullLogo src={cloud} />*/}
              </Grid.Column>
            )}
          </GridRowIntro>
        </Grid>
        <div>
          <p className="para">Access to ArangoGraph is currently denied for your user account.</p>
          {has_reason && <p className="para">Reason: {args.reason}</p>}
          <p className="para">
            If you believe this is incorrect, please create a support request using the <strong>Request help</strong> button in the top of this page.
          </p>
          <ButtonsBox>
            <Button icon="arrow right" labelPosition="right" primary onClick={args.onClickLogout} content="Logout" />
          </ButtonsBox>
        </div>
      </Box>
    </HomeTemplateView>
  );
};

interface IAuth {
  accessDeniedReason: string;
  isAuthenticated: () => boolean;
  login: () => void;
  signup: () => void;
  logout: () => void;
}

// Interface decribing the properties of the access-denied component
interface IAccessDeniedProps extends IWithSignupModalProps {
  auth: IAuth;
}

// Interface decribing the state of the access-denied component
interface IAccessDeniedState {
  reason: string;
}

// This is the access denied component
class AccessDenied extends Component<IAccessDeniedProps, IAccessDeniedState> {
  state = {
    reason: "",
  } as IAccessDeniedState;

  componentDidMount() {
    this.setState({ reason: this.props.auth.accessDeniedReason });
  }

  render() {
    return (
      <AccessDeniedView
        {...this.props}
        reason={this.state.reason}
        isAuthenticated={this.props.auth.isAuthenticated()}
        onClickLogin={this.props.auth.login}
        onSignup={this.props.auth.signup}
        onClickLogout={this.props.auth.logout}
      />
    );
  }
}

export default withSignupModal(AccessDenied);
