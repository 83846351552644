//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moize from "moize";
import { AuditService } from "./audit/v1/audit";
import { BackupService } from "./backup/v1/backup";
import { BillingService } from "./billing/v1/billing";
import { CryptoService } from "./crypto/v1/crypto";
import { CurrencyService } from "./currency/v1/currency";
import { DataService } from "./data/v1/data";
import { ExampleDatasetService } from "./example/v1/example";
import { IAMService } from "./iam/v1/iam";
import { PlatformService } from "./platform/v1/platform";
import { PrepaidService } from "./prepaid/v1/prepaid";
import { ReplicationService } from "./replication/v1/replication";
import { ResourceManagerService } from "./resourcemanager/v1/resourcemanager";
import { SecurityService } from "./security/v1/security";
import { SupportService } from "./support/v1/support";
import { MetricsService } from "./metrics/v1/metrics";
import { MonitoringService } from "./monitoring/v1/monitoring";
import { NotificationService } from "./notification/v1/notification";
import { NetworkService } from "./network/v1/network";
import { NotebookService } from "./notebook/v1/notebook";
import { DeploymentProfileService } from "./deployment-profile/v1/deploymentprofile";
import { MLService } from "./ml/v1/ml";
import { DataLoaderService } from "./external/data-loader/v1/dataloader";
import { AuthService } from "./auth/v1/auth";
import { CreditsService } from "./credits/v1/credits";
import { DashboardService } from "./dashboard/v1/dashboard";

const clients = {
  dashboardClient: new DashboardService(),
  auditClient: new AuditService(),
  authClient: new AuthService(),
  backupClient: new BackupService(),
  billingClient: new BillingService(),
  cryptoClient: new CryptoService(),
  creditBundleClient: new CreditsService(),
  currencyClient: new CurrencyService(),
  dataClient: new DataService(),
  exampleClient: new ExampleDatasetService(),
  iamClient: new IAMService(),
  metricsClient: new MetricsService(),
  monitoringClient: new MonitoringService(),
  platformClient: new PlatformService(),
  prepaidClient: new PrepaidService(),
  replicationClient: new ReplicationService(),
  resourceManagerClient: new ResourceManagerService(),
  securityClient: new SecurityService(),
  supportClient: new SupportService(),
  notificationClient: new NotificationService(),
  networkClient: new NetworkService(),
  notebookClient: new NotebookService(),
  deploymentProfileClient: new DeploymentProfileService(),
  mlClient: new MLService(),
  dataloaderClient: new DataLoaderService(),
};

export const StableCacheOpts = {
  isPromise: true,
  maxAge: 120000, // 2min
  isDeepEqual: true,
};

export const Cached = {
  currencyClient: {
    ListCurrencies: moize(clients.currencyClient.ListCurrencies, StableCacheOpts),
  },
  platformClient: {
    GetProvider: moize(clients.platformClient.GetProvider, StableCacheOpts),
    GetRegion: moize(clients.platformClient.GetRegion, StableCacheOpts),
    ListProviders: moize(clients.platformClient.ListProviders, StableCacheOpts),
    ListRegions: moize(clients.platformClient.ListRegions, StableCacheOpts),
  },
};

export default clients;
