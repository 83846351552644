//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import api from "../../api/api";
import apiclients from "../../api/apiclients";
import { User as ApiUser } from "../../api/lib";
import { Routes } from "../../routes";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CreateOrganization from "../my-organizations/CreateOrganization";
import { TopMenuInfo } from "../TopMenuInfo";
import PhoneNumberVerificationCode from "../user/PhoneNumberVerificationCode";
import UpdateUser from "../user/UpdateUser";
import { IUserDetailsAPI } from "../user/UserDetails";
import UserMyAccount, { UserMyAccountTabPage } from "../user/UserMyAccount";
import { ITracking } from "../../tracking/api";

// API used by UserView
interface IUserViewAPI extends IUserDetailsAPI {
  ResendUserMobilePhoneVerification: (user: ApiUser) => Promise<void>;
}

interface IUserViewArgs extends IWithRefreshProps {
  topMenuInfo: TopMenuInfo;
  tracking: ITracking;
  onClickLogout: () => void;
  onOrganizationInviteAccepted: (id: string) => void;
  onOrganizationInviteRejected: (id: string) => void;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  api: IUserViewAPI;
}

const UserView = ({ ...args }: IUserViewArgs) => (
  <div>
    <Route exact path={Routes.dashboard_user} render={(props) => <Redirect to={Routes.dashboard_user_my_account} />} />
    <Route
      path={Routes.dashboard_user_my_account}
      render={(props) => (
        <UserMyAccount
          {...args}
          {...props}
          tabPage={UserMyAccountTabPage.Overview}
          api={args.api}
          showId={false}
          onClickVerifyCode={() => props.history.push(Routes.dashboard_user_verify_phone)}
        />
      )}
    />
    <Route
      path={Routes.dashboard_user_details}
      render={(props) => (
        <UserMyAccount
          {...args}
          {...props}
          tabPage={UserMyAccountTabPage.Overview}
          api={args.api}
          showId={false}
          onClickVerifyCode={() => props.history.push(Routes.dashboard_user_verify_phone)}
        />
      )}
    />
    <Route
      path={Routes.dashboard_user_edit}
      render={(props) => (
        <UpdateUser
          {...args}
          {...props}
          loading={false}
          onUserUpdated={(id: string) => {
            props.history.replace(Routes.dashboard_user_details);
          }}
        />
      )}
    />
    <Route
      path={Routes.dashboard_user_verify_phone}
      render={(props) => (
        <PhoneNumberVerificationCode
          {...args}
          {...props}
          loading={false}
          onVerified={(id: string) => {
            props.history.replace(Routes.dashboard_user_details);
          }}
        />
      )}
    />
    <Route
      exact
      path={Routes.dashboard_user_organizations}
      render={(props) => <UserMyAccount {...args} {...props} tabPage={UserMyAccountTabPage.Organizations} showId={false} />}
    />
    <Route path={Routes.dashboard_user_organizations_create} render={(props) => <CreateOrganization {...args} {...props} loading={false} />} />
    <Route
      path={Routes.dashboard_user_organizationInvites}
      render={(props) => <UserMyAccount {...args} {...props} tabPage={UserMyAccountTabPage.Invites} showId={false} />}
    />
    <Route
      path={Routes.dashboard_user_api_keys}
      render={(props) => <UserMyAccount {...args} {...props} tabPage={UserMyAccountTabPage.ApiKeys} showId={false} />}
    />
  </div>
);

// Interface decribing the properties of the user component
interface IUserProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  tracking: ITracking;
  onClickLogout: () => void;
  onOrganizationInviteAccepted: (id: string) => void;
  onOrganizationInviteRejected: (id: string) => void;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
}

// Interface decribing the state of the user component
interface IUserState {}

class User extends Component<IUserProps, IUserState> {
  GetToken = () => api.token;
  GetUser = apiclients.iamClient.GetThisUser;
  ResendUserMobilePhoneVerification = apiclients.iamClient.ResendUserMobilePhoneVerification;

  render() {
    return <UserView {...this.props} api={this} />;
  }
}

export default withRefresh()(User);
