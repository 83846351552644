//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FileError } from "react-dropzone";
import { humanizeFileSize } from "../../../util/FileSize";
import { ERROR_CODE, EdgeMappings, FileObject, NodeMappings } from "./types";
import { useDataloaderStore } from "./DataloaderStore";
import { Edge, Node } from "vis-network";
import { getCollectionNameErrors } from "./utils/namesValidations.utils";

export const MAX_TOTAL_FILE_SIZE_LIMIT = 1073741824;
export const SINGLE_SESSION_FILE_COUNT = 25;

export const getSize = (dryRunFiles: FileObject[]) => {
  return dryRunFiles.reduce((acc, file) => {
    return acc + parseInt(file.fileSize as string);
  }, 0);
};

export const hasFileCountLimitReached = (length: number) => {
  return length > SINGLE_SESSION_FILE_COUNT;
};

export const hasTotalFileSizeLimitReached = (files: FileObject[]) => {
  return getSize(files) > MAX_TOTAL_FILE_SIZE_LIMIT;
};

export const validateDroppedFiles = (files: FileObject[]): FileError | null => {
  if (hasFileCountLimitReached(files.length)) {
    const message = `${ERROR_CODE.fileCountLimitReached}: Files more than ${SINGLE_SESSION_FILE_COUNT} cannot be added to a single session.`;
    return {
      message,
      code: ERROR_CODE.fileCountLimitReached,
    };
  }

  if (hasTotalFileSizeLimitReached(files)) {
    const message = `${ERROR_CODE.fileSizeQuotaReached}: Files more than ${humanizeFileSize(MAX_TOTAL_FILE_SIZE_LIMIT)} in total size are not allowed.`;
    return {
      message,
      code: ERROR_CODE.fileSizeQuotaReached,
    };
  }
  return null;
};

export const getAttachedNodeToFile = (id: string) => {
  const nodeMappings = useDataloaderStore.getState().getDeploymentDataloaderState().nodeMappings;
  return Object.keys(nodeMappings).filter((n) => {
    const { attachedFile } = nodeMappings[n];
    return (attachedFile || {}).id === id;
  });
};

export const getAttachedEdgeToFile = (id: string) => {
  const edgeMappings = useDataloaderStore.getState().getDeploymentDataloaderState().edgeMappings;
  return Object.keys(edgeMappings).filter((n) => {
    const { attachedFile } = edgeMappings[n];
    return (attachedFile || {}).id === id;
  });
};
export const isFileAssociatedInGraph = (id: string) => {
  const nodes = getAttachedNodeToFile(id);
  const edges = getAttachedEdgeToFile(id);
  return !!(nodes.length || edges.length);
};

export const isEdgeReadyForImport = (edge: Edge, edgeMap?: { [id: string]: EdgeMappings }) => {
  const { from, label, to, id } = edge;
  const isValidLabel = label !== "" && !getCollectionNameErrors(label || "").length;
  const edgeMappings = edgeMap || useDataloaderStore.getState().getDeploymentDataloaderState().edgeMappings;
  const { attachedFile, sourceNodePrimaryKey, targetNodePrimaryKey } = edgeMappings[String(id)] || {};

  return !!from && !!isValidLabel && !!to && !!attachedFile && !!sourceNodePrimaryKey && !!targetNodePrimaryKey;
};

export const isNodeReadyForImport = (node: Node, nodeMap?: { [id: string]: NodeMappings }) => {
  const { label, id } = node;
  const isValidLabel = label !== "" && !getCollectionNameErrors(label || "").length;
  const nodeMappings = nodeMap || useDataloaderStore.getState().getDeploymentDataloaderState().nodeMappings;
  const { attachedFile, primaryKey } = nodeMappings[String(id)] || {};

  return !!isValidLabel && !!attachedFile && !!primaryKey;
};
