//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { ContentSegment, ErrorMessage, Processing, OnboardingName, OnboardingDescription, CenteredContentForm, OnboardingLogo } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { ITracking } from "../../tracking/api";
import DocumentTitle from "react-document-title";
import _ from "lodash";

import homeImage from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { reportError } from "../../errors/reporting";

interface ICreateDefaultOrganizationViewArgs extends ICreateDefaultOrganizationState {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  onRetry: () => void;
}

const CreateDefaultOrganizationView = ({ ...args }: ICreateDefaultOrganizationViewArgs) => {
  const has_error = !!args.errorMessage;
  return (
    <ContentSegment>
      <Processing active={args.processing} message="Preparing organization, please wait..." />
      <ErrorMessage active={has_error} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <OnboardingLogo>
        <img style={{ width: "100%", height: "100%" }} src={homeImage} alt="logo" />
      </OnboardingLogo>
      <OnboardingName>Preparing your organization</OnboardingName>
      <OnboardingDescription>We're preparing your ArangoGraph organization. Please wait.</OnboardingDescription>
      <CenteredContentForm>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={4} />
            <Grid.Column width={8}>
              {args.processing && <Loader active inline="centered" />}
              {has_error && !args.processing && (
                <div>
                  <p className="para">Sorry, something went wrong.</p>
                  <Button primary big onClick={args.onRetry} content="Retry" icon="retry" />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CenteredContentForm>
    </ContentSegment>
  );
};

interface ICreateDefaultOrganizationProps extends IWithRefreshProps, RouteComponentProps {
  tracking: ITracking;
  onNewOrganizationCreated: (organizationId: string) => void;
  onExistingOrganizationFound: () => void;
}

interface ICreateDefaultOrganizationState {
  errorMessage?: string;
  processing: boolean;
}

// Component to create an organization using default settings
class CreateDefaultOrganization extends Component<ICreateDefaultOrganizationProps, ICreateDefaultOrganizationState> {
  state = {
    errorMessage: undefined,
    processing: false,
  } as ICreateDefaultOrganizationState;

  createDefaultOrganizationIfNeeded = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });

      // Load the current user
      const user = await apiClients.iamClient.GetThisUser();

      // Load the organizations that the current user is a member of
      const currentOrgs = await apiClients.resourceManagerClient.ListOrganizations({});
      if (!_.isEmpty(currentOrgs.items)) {
        // User already member of at least 1 organization
        this.props.onExistingOrganizationFound();
        return;
      }

      // Create organization for user
      const organization = {
        name: `ArangoGraph Insights Platform for ${user.company_name || user.given_name || user.family_name || "me"}`,
        description: "Automatically created organization",
      } as ApiOrganization;
      const createdOrganization = await apiClients.resourceManagerClient.CreateOrganization(organization);

      this.props.onNewOrganizationCreated(createdOrganization.id || "");
    } catch (e) {
      this.setState({ errorMessage: `Organization creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.createDefaultOrganizationIfNeeded();
  }

  onRetry = () => {
    this.createDefaultOrganizationIfNeeded();
  };

  render() {
    return (
      <DocumentTitle title="Onboarding: Preparing your organization">
        <CreateDefaultOrganizationView {...this.state} active={!this.state.processing} handleDismissError={this.handleDismissError} onRetry={this.onRetry} />
      </DocumentTitle>
    );
  }
}

export default withRefresh()(CreateDefaultOrganization);
