//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
import React, { useEffect } from "react";
import apiClients from "../../api/apiclients";
import {
  CACertificateList as ApiCACertificateList,
  DeploymentList as ApiDeploymentList,
  IAMProviderList as ApiIAMProviderList,
  IDOptions as ApiIDOptions,
  IPAllowlistList as ApiIPAllowlistList,
  PrepaidDeploymentList as ApiPrepaidDeploymentList,
  Project as ApiProject,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { ConfirmInfo } from "../../ui/lib";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { useSetupProjectData } from "./useSetupProjectData";
import { useLocation } from "react-router-dom";

interface ProjectDetailsContextType {
  activeTabIndex: number;
  errorMessage?: string;
  processingResumeDeployment: boolean;
  processingLockProject: boolean;
  processingUnlockProject: boolean;
  processingDelete: boolean;
  confirmInfo?: ConfirmInfo;
  createIPAllowlist: boolean;
  createCACertificate: boolean;
  deployments?: ApiDeploymentList;
  prepaidDeployments?: ApiPrepaidDeploymentList;
  cacertificates?: ApiCACertificateList;
  ipallowlists?: ApiIPAllowlistList;
  iamproviders?: ApiIAMProviderList;
  project: ApiProject;
  isProcessing: boolean;
  setCreateCACertificate: (value: boolean) => void;
  setCreateIPAllowlist: (value: boolean) => void;
  onResumeDeployment: (id: string) => void;
  onError: (message?: string) => void;
  handleDismissError: () => void;
  onTabChange: (index: number) => void;
  onShowTab: (tab: ProjectTabPage) => void;
  onClickDelete: () => void;
  onLockProject: () => void;
  onUnlockProject: () => void;
  refetchCACertificates: () => void;
  refetchIPAllowlists: () => void;
  refetchIAMProviders: () => void;
  refetchDeployments: () => void;
}
const ProjectDetailsContext = React.createContext<ProjectDetailsContextType>({} as ProjectDetailsContextType);
export const useProjectDetailsContext = () => React.useContext(ProjectDetailsContext);

enum ProjectTabPage {
  Deployments = 0,
  Security = 1,
  Auditlog = 2,
  Policy = 3,
}

export const ProjectDetailsContextProvider = (props: { children: React.ReactNode; onProjectDeleted: () => void; tabPage?: ProjectTabPage }) => {
  const { reloadProjects } = useDashboardContext();
  const [activeTabIndex, setActiveTabIndex] = React.useState((props.tabPage as number) || 0);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [processingResumeDeployment, setProcessingResumeDeployment] = React.useState<boolean>(false);
  const [processingLockProject, setProcessingLockProject] = React.useState<boolean>(false);
  const [processingUnlockProject, setProcessingUnlockProject] = React.useState<boolean>(false);
  const [processingDelete, setProcessingDelete] = React.useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = React.useState<ConfirmInfo | undefined>(undefined);
  const [createIPAllowlist, setCreateIPAllowlist] = React.useState<boolean>(false);
  const [createCACertificate, setCreateCACertificate] = React.useState<boolean>(false);
  const { project } = useGlobalStore();
  const {
    deployments,
    prepaidDeployments,
    cacertificates,
    ipallowlists,
    iamproviders,
    refetchDeployments,
    refetchPrepaidDeployments,
    refetchCACertificates,
    refetchIPAllowlists,
    refetchIAMProviders,
  } = useSetupProjectData();

  const getProjectName = () => {
    return project.name || "";
  };

  const onClickDelete = async () => {
    const projectName = getProjectName();
    const confirmInfo = {
      header: "Delete Project",
      content: `Are you sure you want to delete project '${projectName}'?`,
      warning: "This implies deletion of the deployments in this project and all data stored in the database!",
      confirm: "Delete!",
      invertPositiveNegative: true,
      onConfirm: () => onClickDeleteConfirmed(),
      onDenied: () => setConfirmInfo(undefined),
    } as ConfirmInfo;
    setConfirmInfo(confirmInfo);
  };

  const onClickDeleteConfirmed = async () => {
    try {
      setProcessingDelete(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);

      const idOptions = { id: project.id } as ApiIDOptions;
      await apiClients.resourceManagerClient.DeleteProject(idOptions);
      props.onProjectDeleted();
    } catch (e) {
      setErrorMessage(`Project deletion failed: ${e}`);
      reportError(e);
    }
  };

  const onLockProject = async () => {
    try {
      setProcessingLockProject(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      await apiClients.resourceManagerClient.UpdateProject({
        ...project,
        locked: true,
      });
      reloadProjects();
    } catch (e) {
      setErrorMessage(`Lock project failed: ${e}`);
      reportError(e);
    }
    setProcessingLockProject(false);
  };
  const onUnlockProject = async () => {
    try {
      setProcessingUnlockProject(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      await apiClients.resourceManagerClient.UpdateProject({
        ...project,
        locked: false,
      });
      reloadProjects();
    } catch (e) {
      setErrorMessage(`Unlock project failed: ${e}`);
      reportError(e);
    }
    setProcessingUnlockProject(false);
  };

  const onResumeDeployment = async (id: string) => {
    try {
      setProcessingResumeDeployment(true);
      setErrorMessage(undefined);
      setConfirmInfo(undefined);
      await apiClients.dataClient.ResumeDeployment({
        id,
      });
      refetchDeployments();
      refetchPrepaidDeployments();
    } catch (e) {
      setErrorMessage(`Resume deployment failed: ${e}`);
      reportError(e);
    }
    setProcessingResumeDeployment(false);
  };

  const onError = (message?: string) => {
    setErrorMessage(message);
  };

  const handleDismissError = () => {
    setErrorMessage(undefined);
  };

  const onUpdateNameAndDescription = async (name: string, description: string) => {
    // Note that we do not try/catch here, since that is handled in the top menu.
    await apiClients.resourceManagerClient.UpdateProject({
      ...project,
      name,
      description,
    });
    reloadProjects();
  };

  const onTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  const onShowTab = (tab: ProjectTabPage) => {
    setActiveTabIndex(tab as number);
  };
  const { checkProjectPermission } = useCheckPermissionByUrl();
  const { topMenuInfo } = useDashboardContext();
  const location = useLocation();
  const has_project_update = checkProjectPermission("resourcemanager.project.update");
  useEffect(() => {
    topMenuInfo.setTitles(project.name || "", project.description || "", has_project_update ? onUpdateNameAndDescription : undefined);
  }, [project, has_project_update, location]);

  const isProcessing = processingDelete || processingLockProject || processingResumeDeployment || processingUnlockProject;

  return (
    <ProjectDetailsContext.Provider
      value={{
        activeTabIndex,
        errorMessage,
        processingResumeDeployment,
        processingLockProject,
        processingUnlockProject,
        processingDelete,
        confirmInfo,
        createIPAllowlist,
        createCACertificate,
        deployments,
        prepaidDeployments,
        cacertificates,
        ipallowlists,
        iamproviders,
        project,
        isProcessing,
        onResumeDeployment,
        onError,
        handleDismissError,
        onTabChange,
        onShowTab,
        onClickDelete,
        onLockProject,
        onUnlockProject,
        setCreateCACertificate,
        setCreateIPAllowlist,
        refetchCACertificates,
        refetchIPAllowlists,
        refetchIAMProviders,
        refetchDeployments,
      }}
    >
      {props.children}
    </ProjectDetailsContext.Provider>
  );
};
