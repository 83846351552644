//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDataloaderStore } from "../../DataloaderStore";
import { FileObject } from "../../types";
import { useGraphEdgeHandlers } from "../../graph-modeller/GraphProvider";

export const useFileAndEdgeModifiers = () => {
  const { getEdge, updateEdge } = useGraphEdgeHandlers();
  const { getDeploymentDataloaderState, updateEdgeMappings } = useDataloaderStore();
  const { edgeMappings, nodeMappings } = getDeploymentDataloaderState();

  /**
   * This function removes the edge mapping from the global store based on the ID passed.
   * @param {string} id
   * @returns
   */
  const removeEdgeFileMappingsFromGlobalStore = () => {
    const { id } = getEdge() || {};
    if (!id) return;
    //Consider using what is being here: docs.pmnd.rs/zustand/recipes/recipes#overwriting-state
    delete edgeMappings[id];
  };

  /**
   * This function returns the edge mappings object for the active Edge in the global store. If not found, it returns an empty object.
   * @returns {EdgeMappings}
   */
  const getExistingEdgeMapping = () => {
    const { id } = getEdge() || {};
    return edgeMappings[String(id)] || {};
  };

  /**
   *
   * This function returns the NodeMapping of the provided Edge ID.
   *
   * @param {string} id
   * @returns {NodeMappings}
   */
  const getNodeMapping = (id: string) => ({ ...nodeMappings[String(id)] });

  /**
   * This function returns the file attached to the active node
   * @returns {FileObject}
   */
  const getAssignedFileToEdgeFromGlobalStore = () => {
    const { attachedFile } = getExistingEdgeMapping() || {};
    return attachedFile;
  };

  /**
   * This function returns the assigned headers to the active edge
   * @returns {string[]}
   */
  const getAssignedFileHeadersToEdgeFromGlobalStore = () => {
    const { selectedFields } = getExistingEdgeMapping() || {};
    return selectedFields;
  };

  /**
   * This function returns the assigned Node keys for the active node. These keys are obtained from the source node.
   * @returns {string[]}
   */
  const getAssignedSourcePrimaryKeyToEdgeFromGlobalStore = () => {
    const { sourceNodePrimaryKey } = getExistingEdgeMapping() || {};
    return sourceNodePrimaryKey;
  };

  /**
   * This function returns the assigned Node keys for the active node. These keys are obtained from the target node.
   * @returns {string[]}
   */
  const getAssignedSourceTargetKeyFromGlobalStore = () => {
    const { targetNodePrimaryKey } = getExistingEdgeMapping() || {};
    return targetNodePrimaryKey;
  };

  /**
   * This function returns the edge mappings object for the active Edge in the global store. If not found, it returns an empty object.
   * @returns {EdgeMappings}
   */
  const getCurrentEdgeMappingFromGlobalStore = () => {
    return getExistingEdgeMapping();
  };

  /**
   *  This function returns the source Node mapping for the selected edge.
   * @returns {NodeMappings}
   */
  const getCurrentSourceNodeOfEdgeFromGlobalStore = () => {
    const { from } = getEdge() || {};
    return getNodeMapping(String(from));
  };

  /**
   *  This function returns the target Node mapping for the selected edge.
   * @returns {NodeMappings}
   */
  const getCurrentTargetNodeFromGlobalStore = () => {
    const { to } = getEdge() || {};
    return getNodeMapping(String(to));
  };

  /**
   * This function helps attach a file to the active or selected edge. Once done, it updates the global store to allow persisting the mapping.
   * @param {FileObject} file
   * @returns {void}
   */
  const assignFileToEdgeInGlobalStore = (file: FileObject) => {
    const edge = getEdge() || {};
    const { id } = edge;
    if (!id) return;

    const updateEdgeMappings = useDataloaderStore.getState().updateEdgeMappings;
    const existingFileAttached = getExistingEdgeMapping();
    const updatedMap = {
      ...edgeMappings,
      [String(id)]: {
        ...existingFileAttached,
        attachedFile: file,
        selectedFields: undefined,
      },
    };
    updateEdgeMappings(updatedMap);
    updateEdge(edge, updatedMap);
  };

  /**
   * This function assigns the selected headers to the active edge and persists them in the global store.
   * @param {string} field
   * @returns {void}
   */
  const assignFileHeadersToEdgeInGlobalStore = (field: string) => {
    const existingEdgeMappings = edgeMappings;

    const edge = getEdge() || {};
    const { id } = edge;
    if (!id) return;

    const existingEdgeMap = existingEdgeMappings[String(id)];
    const existingFields = existingEdgeMap.selectedFields || [];
    const updatedFields = [...existingFields, field];
    const updatedMap = {
      ...existingEdgeMappings,
      [String(id)]: {
        ...existingEdgeMap,
        selectedFields: updatedFields,
      },
    };
    updateEdgeMappings(updatedMap);
    updateEdge(edge, updatedMap);
  };

  /**
   * This function allows removing a specific file header from an edge and persists the updated mapping in the global store.
   * @param {string}  fieldToRemove
   */
  const removeFileHeadersOfEdgeFromGlobalStore = (fieldToRemove: string) => {
    const existingEdgeMappings = edgeMappings;

    const { id } = getEdge() || {};
    const existingEdgeMap = existingEdgeMappings[String(id)];
    const existingFields = existingEdgeMap.selectedFields || [];
    updateEdgeMappings({
      ...existingEdgeMappings,
      [String(id)]: {
        ...existingEdgeMap,
        selectedFields: existingFields.filter((field) => field !== fieldToRemove),
      },
    });
  };

  /**
   *
   * This function helps assigning a primary key which is obtained from the target node of the active edge.
   *
   * @param {string} key
   * @returns {void}
   */
  const assignTargetPrimaryKeyInGlobalStore = async (key: string) => {
    const existingEdgeMappings = edgeMappings;

    const edge = getEdge() || {};
    const { id } = edge;
    if (!id) return;
    const currentEdgeMap = existingEdgeMappings[String(id)];
    const updatedMap = {
      ...existingEdgeMappings,
      [String(id)]: {
        ...currentEdgeMap,
        targetNodePrimaryKey: key,
      },
    };
    updateEdgeMappings(updatedMap);
    updateEdge(edge, updatedMap);
  };

  /**
   *
   * This function helps assigning a primary key which is obtained from the source node of the active edge.
   *
   * @param {string} key
   * @returns {void}
   */
  const assignSourcePrimaryKeyToEdgeInGlobalStore = (key: string) => {
    const existingEdgeMappings = edgeMappings;

    const edge = getEdge() || {};
    const { id } = edge;
    if (!id) return;
    const currentEdgeMap = existingEdgeMappings[String(id)];
    const updatedMap = {
      ...existingEdgeMappings,
      [String(id)]: {
        ...currentEdgeMap,
        sourceNodePrimaryKey: key,
      },
    };
    updateEdgeMappings(updatedMap);
    updateEdge(edge, updatedMap);
  };

  return {
    assignFileToEdgeInGlobalStore,
    assignFileHeadersToEdgeInGlobalStore,
    removeFileHeadersOfEdgeFromGlobalStore,
    assignTargetPrimaryKeyInGlobalStore,
    assignSourcePrimaryKeyToEdgeInGlobalStore,
    getCurrentEdgeMappingFromGlobalStore,
    getCurrentSourceNodeOfEdgeFromGlobalStore,
    getCurrentTargetNodeFromGlobalStore,
    getAssignedFileToEdgeFromGlobalStore,
    getAssignedFileHeadersToEdgeFromGlobalStore,
    getAssignedSourcePrimaryKeyToEdgeFromGlobalStore,
    getAssignedSourceTargetKeyFromGlobalStore,
    removeEdgeFileMappingsFromGlobalStore,
  };
};
