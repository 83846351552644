//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { PolicyContext } from "./usePolicyContext";

const PolicyContextProvider = ({ resourceUrl, children }: { resourceUrl: string | undefined; children: React.ReactNode }) => {
  return (
    <PolicyContext.Provider
      value={{
        resourceUrl,
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
};

export { PolicyContextProvider };
