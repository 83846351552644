//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Segment, Grid, Checkbox, CheckboxProps, Loader, Header, Divider, Button } from "semantic-ui-react";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../../ui/_section";
import apiClients from "../../../api/apiclients";
import { MLServices, Status } from "../../../api/ml/v1/ml";
import { IDOptions } from "../../../api/lib";
import { ErrorMessage } from "../../../ui/lib";
import MLSummaryTableView from "./MLSummaryTableView";
import MLServicesModalView from "./MLServicesModalView";

import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import useMLRegionAvailability from "./useMLRegionAvailability";
import { useWithRefresh } from "../../../util/WithRefreshContext";

import { useFeature } from "flagged";

const MLView = () => {
  const { id: deploymentID = "", model: deploymentModel = {}, status: deploymentStatus = {}, region_id: regionId = "" } = useDeploymentStore().deployment;

  const deploymentModelSupportsML = deploymentModel.model === "free";
  const deploymentStatusRunning = deploymentStatus.description === "Running";

  const { isMLFeaturesAvailableAtRegion: regionSupportsML } = useMLRegionAvailability({ regionId });

  const { subscribeUrl } = useWithRefresh();

  const [error, setError] = useState();
  const [mlServiceEnabled, setMLServiceEnabled] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mlServiceStatusData, setMLServiceStatusData] = useState<Status>({});
  const [showServicesModalView, setShowServicesModalView] = useState(false);

  const showMLToggle = deploymentModelSupportsML && regionSupportsML;
  const toggleEnabled = !updateLoading && deploymentModelSupportsML && regionSupportsML && deploymentStatusRunning;

  const deploymentsURL = window.location.href.split("deployment")[0] + "deployments";

  const mlTrialEnabled = useFeature("ml.trial");

  const loadMLServiceData = async () => {
    setError(undefined);
    try {
      const req: IDOptions = {
        id: deploymentID,
      };

      const { enabled, status } = (await apiClients.mlClient.GetMLServices(req)) || {};
      setMLServiceStatusData(status || {});
      setMLServiceEnabled(!!enabled);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleMLServiceStateChange = async (checked: boolean) => {
    setError(undefined);
    setUpdateLoading(true);
    try {
      const req: MLServices = {
        deployment_id: deploymentID,
        enabled: checked,
      };
      await apiClients.mlClient.UpdateMLServices(req);
      setMLServiceEnabled(checked);
    } catch (err) {
      setError(err);
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    subscribeUrl && subscribeUrl(loadMLServiceData);
  }, []);

  return (
    <>
      {showServicesModalView && mlServiceStatusData && mlServiceStatusData.services && (
        <MLServicesModalView services={mlServiceStatusData.services && mlServiceStatusData.services} close={() => setShowServicesModalView(false)} />
      )}

      <Section>
        <SectionHead>
          <SectionHeader title={<span>ArangoGraphML Settings {updateLoading && <Loader inline />} </span>} />
        </SectionHead>
        <SectionContent>
          <ErrorMessage active={!!error} onDismiss={() => setError(undefined)} message={error} />
          {mlTrialEnabled ? (
            <Segment>
              <Header as="h3">Get started with the FREE trial of ArangoGraphML today!</Header>
              <p className="para">
                Brought to you in partnership with{" "}
                <span>
                  <img alt="intel" src="/images/intel-logo.png" style={{ width: 32, display: "inline-block", paddingLeft: 2 }} />
                </span>
              </p>
              <p className="para">
                ArangoGraphML provides enterprise-ready graph-powered machine learning available as a cloud service and as an on-prem solution. Leverage the
                power of graphs combined with machine learning for more accurate data insights.
              </p>

              {showMLToggle ? ( // SHOW ML enable/disable toggle
                <>
                  <Header as="h3">Service setting</Header>
                  <p className="para">To start using ArangoGraph Machine Learning, enable the ML Services below.</p>
                  <p className="para">Once the ML services are up and running, create a notebook to explore demo use cases and do your own ML experiments.</p>
                  <Divider hidden />
                  <Grid columns="16" padded>
                    <Checkbox
                      checked={mlServiceEnabled}
                      disabled={!toggleEnabled}
                      toggle
                      label="ArangoGraphML Services"
                      onChange={(_: unknown, data: CheckboxProps) => {
                        const { checked } = data;
                        handleMLServiceStateChange(!!checked);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                // SHOW ML not eligible
                <p className="para">
                  <i aria-hidden="true" className="info red circle icon"></i>
                  This deployment is not eligible for the ArangoGraphML services free trial. Please create a new{" "}
                  <a href={deploymentsURL} className="text-link">
                    Free tier deployment
                  </a>{" "}
                  to start your ArangoGraphML adventure.
                </p>
              )}
            </Segment>
          ) : (
            <Segment>
              <Header as="h3">Service setting</Header>
              <p className="para">
                ArangoGraphML provides enterprise-ready graph-powered machine learning available as a cloud service via Jupyter Notebooks running on the
                ArangoGraph Insights Platform. ArangoGraphML combines traditional ML with the power of connected graph data to provide valuable data insights.
              </p>
              <p className="para">Contact us to start using ArangoGraphML and get the maximum value from your data.</p>

              <Button as="a" href="https://www.arangodb.com/contact" target="_blank" primary>
                Contact us
              </Button>
            </Segment>
          )}
        </SectionContent>
      </Section>

      {mlServiceEnabled ? (
        <Section>
          <SectionHead>
            <SectionHeader title={<span>Status summary {loading && <Loader inline />} </span>} />
          </SectionHead>
          <SectionContent>
            <MLSummaryTableView
              {...mlServiceStatusData}
              deploymentIsRunning={deploymentStatusRunning}
              openServicesModal={() => setShowServicesModalView(true)}
            />
          </SectionContent>
        </Section>
      ) : (
        <></>
      )}
    </>
  );
};

export default MLView;
