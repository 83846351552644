//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Loader, Popup, Statistic } from "semantic-ui-react";
import {
  CPUSize as ApiCPUSize,
  Deployment_ModelSpec as ApiDeployment_ModelSpec,
  Deployment_ServersSpec as ApiDeployment_ServersSpec,
  NodeSize as ApiNodeSize,
  Plan as ApiSupportPlan,
} from "../../api/lib";
import { ModelTitle, StatisticsContentGroup, StyledStatsSegment } from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { find, capitalize } from "lodash";
import styled from "@emotion/styled";

interface IConfiguration {
  model?: ApiDeployment_ModelSpec;
  node_size?: ApiNodeSize;
  servers?: ApiDeployment_ServersSpec;
  cpu_size?: ApiCPUSize;
  supportPlanID?: string;
}

interface IConfigurationViewArgs extends IWithRefreshProps {
  current: IConfiguration;
  update?: IConfiguration;
  showSupportPlan: boolean;
  supportPlans: ApiSupportPlan[];
  isDiskAlmostFull?: boolean;
  diskPerformanceID: string;
}

const StyledSuffix = styled.span`
  font-size: 1rem !important;
  color: var(--gray-900) !important;
  text-transform: uppercase !important;
`;
const getSupportPlan = (supportPlans: ApiSupportPlan[], supportPlanID?: string) => {
  if (!supportPlanID) {
    return undefined;
  }
  return find(supportPlans, (x) => x.id === supportPlanID) || { name: capitalize(supportPlanID), description: capitalize(supportPlanID) };
};

const UpdateArrow = () => <Icon name="arrow down" />;

interface ISupportPlanViewArgs {
  supportPlan?: ApiSupportPlan;
}

const SupportPlanView = ({ ...args }: ISupportPlanViewArgs) => {
  if (!args.supportPlan) {
    return <Loader size="tiny" inline active />;
  }
  const supportPlan = args.supportPlan || {};
  return (
    <div>
      <Popup content={supportPlan.description || "?"} trigger={<span>{supportPlan.name || "?"}</span>} />
    </div>
  );
};

interface ISingleNodeMemoryViewArgs {
  node_size: ApiNodeSize;
}

const SingleNodeMemoryView = ({ ...args }: ISingleNodeMemoryViewArgs) => {
  return (
    <div>
      {args.node_size.memory_size ? args.node_size.memory_size : "?"}
      <span> GB</span>
    </div>
  );
};

interface ISingleNodeCPUViewArgs {
  cpu_size: ApiCPUSize;
}

const SingleNodeCPUView = ({ ...args }: ISingleNodeCPUViewArgs) => {
  return <div>{args.cpu_size.name || "?"}</div>;
};

interface ISingleNodeDiskViewArgs {
  node_disk_size?: number;
  diskPerformanceID: string;
}

const SingleNodeDiskView = ({ ...args }: ISingleNodeDiskViewArgs) => {
  return (
    <div>
      {args.node_disk_size || "?"}
      <span> GB {args.diskPerformanceID && <StyledSuffix>({args.diskPerformanceID})</StyledSuffix>}</span>
    </div>
  );
};

interface IConfigurationDeveloperViewArgs extends IConfigurationViewArgs {}

const ConfigurationDeveloperView = ({ ...args }: IConfigurationDeveloperViewArgs) => {
  const current = args.current;
  const model = current.model || {};
  const node_size = current.node_size || {};
  const cpu_size = current.cpu_size || {};
  const supportPlan = getSupportPlan(args.supportPlans, current.supportPlanID);

  const hasUpdate = !!args.update;
  const update = args.update || {};
  const updatedModel = update.model || {};
  const modelChanged = hasUpdate && model.model != updatedModel.model;
  const updatedNodeSize = update.node_size || {};
  const nodeSizeChanged = hasUpdate && node_size.id != updatedNodeSize.id;
  const updatedCPUSize = update.cpu_size || {};
  const cpuSizeChanged = hasUpdate && cpu_size.id != updatedCPUSize.id;
  const diskSizeChanged = hasUpdate && model.node_disk_size != updatedModel.node_disk_size;
  const updatedSupportPlan = getSupportPlan(args.supportPlans, update.supportPlanID);
  const hasUpdatedSupportPlan = !!updatedSupportPlan;
  const supportPlanChanged = hasUpdatedSupportPlan && current.supportPlanID != update.supportPlanID;

  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="5">
          <Statistic size="mini">
            <Statistic.Label>Model</Statistic.Label>
            <Statistic.Value>
              <ModelTitle model={model.model || ""} />
              {modelChanged && <UpdateArrow />}
              {modelChanged && <ModelTitle model={updatedModel.model || ""} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>Node memory</Statistic.Label>
            <Statistic.Value>
              <SingleNodeMemoryView node_size={node_size} />
              {nodeSizeChanged && <UpdateArrow />}
              {nodeSizeChanged && <SingleNodeMemoryView node_size={updatedNodeSize} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>Node CPU</Statistic.Label>
            <Statistic.Value>
              <SingleNodeCPUView cpu_size={cpu_size} />
              {cpuSizeChanged && <UpdateArrow />}
              {cpuSizeChanged && <SingleNodeCPUView cpu_size={updatedCPUSize} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>
              Node disk
              {args.isDiskAlmostFull && (
                <Popup
                  content="You are almost out of disk space. Please increase the disk size to avoid disk space issues."
                  position="top right"
                  trigger={<Icon name="warning circle" color="yellow" />}
                />
              )}
            </Statistic.Label>
            <Statistic.Value>
              <SingleNodeDiskView node_disk_size={model.node_disk_size} diskPerformanceID={args.diskPerformanceID} />
              {diskSizeChanged && <UpdateArrow />}
              {diskSizeChanged && <SingleNodeDiskView node_disk_size={updatedModel.node_disk_size} diskPerformanceID={args.diskPerformanceID} />}
            </Statistic.Value>
          </Statistic>
          {args.showSupportPlan && (
            <Statistic size="mini">
              <Statistic.Label>Support plan</Statistic.Label>
              <Statistic.Value>
                <SupportPlanView supportPlan={supportPlan} />
                {supportPlanChanged && <UpdateArrow />}
                {supportPlanChanged && <SupportPlanView supportPlan={updatedSupportPlan} />}
              </Statistic.Value>
            </Statistic>
          )}
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

interface IClusterNodeMemoryViewArgs {
  node_count: number;
  node_size: ApiNodeSize;
}

const ClusterNodeMemoryView = ({ ...args }: IClusterNodeMemoryViewArgs) => {
  return (
    <div>
      {args.node_count}
      <span> &times; </span>
      {args.node_size.memory_size || "?"}
      <span> GB</span>
    </div>
  );
};

interface IClusterNodeCPUViewArgs {
  cpu_size: ApiCPUSize;
}

const ClusterNodeCPUView = ({ ...args }: IClusterNodeCPUViewArgs) => {
  return <div>{args.cpu_size.name || "?"}</div>;
};

interface IClusterNodeDiskViewArgs {
  node_count: number;
  node_disk_size?: number;
  diskPerformanceID: string;
}

const ClusterNodeDiskView = ({ ...args }: IClusterNodeDiskViewArgs) => {
  return (
    <div>
      {args.node_count}
      <span> &times; </span>
      {args.node_disk_size || "?"}
      <span> GB {args.diskPerformanceID && <StyledSuffix>({args.diskPerformanceID})</StyledSuffix>}</span>
    </div>
  );
};

interface IConfigurationOneShardOrShardedViewArgs extends IConfigurationViewArgs {}

const ConfigurationOneShardOrShardedView = ({ ...args }: IConfigurationOneShardOrShardedViewArgs) => {
  const current = args.current;
  const model = current.model || {};
  const node_size = current.node_size || {};
  const nodeCount = model.node_count || 3;
  const cpu_size = current.cpu_size || {};
  const supportPlan = getSupportPlan(args.supportPlans, current.supportPlanID);
  const hasUpdate = !!args.update;
  const update = args.update || {};
  const updatedModel = update.model || {};
  const modelChanged = hasUpdate && model.model != updatedModel.model;
  const updatedNodeSize = update.node_size || {};
  const updatedNodeCount = updatedModel.node_count || 3;
  const nodeSizeChanged = hasUpdate && (node_size.id != updatedNodeSize.id || nodeCount != updatedNodeCount);
  const updatedCPUSize = update.cpu_size || {};
  const cpuSizeChanged = hasUpdate && cpu_size.id != updatedCPUSize.id;
  const diskSizeChanged = hasUpdate && (model.node_disk_size != updatedModel.node_disk_size || nodeCount != updatedNodeCount);
  const updatedSupportPlan = getSupportPlan(args.supportPlans, update.supportPlanID);
  const hasUpdatedSupportPlan = !!updatedSupportPlan;
  const supportPlanChanged = hasUpdatedSupportPlan && current.supportPlanID != update.supportPlanID;

  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="5">
          <Statistic size="mini">
            <Statistic.Label>Model</Statistic.Label>
            <Statistic.Value>
              <ModelTitle model={model.model || ""} />
              {modelChanged && <UpdateArrow />}
              {modelChanged && <ModelTitle model={updatedModel.model || ""} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>Nodes memory</Statistic.Label>
            <Statistic.Value>
              <ClusterNodeMemoryView node_count={nodeCount} node_size={node_size} />
              {nodeSizeChanged && <UpdateArrow />}
              {nodeSizeChanged && <ClusterNodeMemoryView node_count={updatedNodeCount} node_size={updatedNodeSize} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>Nodes CPU</Statistic.Label>
            <Statistic.Value>
              <ClusterNodeCPUView cpu_size={cpu_size} />
              {cpuSizeChanged && <UpdateArrow />}
              {cpuSizeChanged && <ClusterNodeCPUView cpu_size={updatedCPUSize} />}
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>
              Nodes disk
              {args.isDiskAlmostFull && (
                <Popup
                  content="You are almost out of disk space. Please increase the disk size to avoid disk space issues."
                  position="top right"
                  trigger={<Icon name="warning circle" color="yellow" />}
                />
              )}
            </Statistic.Label>
            <Statistic.Value>
              <ClusterNodeDiskView node_count={nodeCount} node_disk_size={model.node_disk_size} diskPerformanceID={args.diskPerformanceID} />
              {diskSizeChanged && <UpdateArrow />}
              {diskSizeChanged && (
                <ClusterNodeDiskView node_count={updatedNodeCount} node_disk_size={updatedModel.node_disk_size} diskPerformanceID={args.diskPerformanceID} />
              )}
            </Statistic.Value>
          </Statistic>
          {args.showSupportPlan && (
            <Statistic size="mini">
              <Statistic.Label>Support plan</Statistic.Label>
              <Statistic.Value>
                <SupportPlanView supportPlan={supportPlan} />
                {supportPlanChanged && <UpdateArrow />}
                {supportPlanChanged && <SupportPlanView supportPlan={updatedSupportPlan} />}
              </Statistic.Value>
            </Statistic>
          )}
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

const ConfigurationFlexibleView = ({ ...args }: IConfigurationViewArgs) => {
  const current = args.current;
  const supportPlan = getSupportPlan(args.supportPlans, current.supportPlanID);
  const update = args.update || {};
  const updatedSupportPlan = getSupportPlan(args.supportPlans, update.supportPlanID);
  const hasUpdatedSupportPlan = !!updatedSupportPlan;
  const supportPlanChanged = hasUpdatedSupportPlan && current.supportPlanID != update.supportPlanID;

  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="4">
          <Statistic size="mini">
            <Statistic.Label>Coordinators memory</Statistic.Label>
            <Statistic.Value>
              {current.servers ? current.servers.coordinators : "?"}
              <span> &times; </span>
              {current.servers ? current.servers.coordinator_memory_size : "?"}
              <span> GB</span>
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>DBServers memory</Statistic.Label>
            <Statistic.Value>
              {current.servers ? current.servers.dbservers : "?"}
              <span> &times; </span>
              {current.servers ? current.servers.dbserver_memory_size : "?"}
              <span> GB</span>
            </Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>DBServers disk</Statistic.Label>
            <Statistic.Value>
              {current.servers ? current.servers.dbservers : "?"}
              <span> &times; </span>
              {current.servers ? current.servers.dbserver_disk_size : "?"}
              <span> GB</span>
            </Statistic.Value>
          </Statistic>
          {args.showSupportPlan && (
            <Statistic size="mini">
              <Statistic.Label>Support plan</Statistic.Label>
              <Statistic.Value>
                <SupportPlanView supportPlan={supportPlan} />
                {supportPlanChanged && <UpdateArrow />}
                {supportPlanChanged && <SupportPlanView supportPlan={updatedSupportPlan} />}
              </Statistic.Value>
            </Statistic>
          )}
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

export const ConfigurationView = ({ ...args }: IConfigurationViewArgs) => {
  const current = args.current;
  const model = current.model || {};
  if (model.model == "oneshard") {
    return <ConfigurationOneShardOrShardedView {...args} />;
  }
  if (model.model == "sharded") {
    return <ConfigurationOneShardOrShardedView {...args} />;
  }
  if (model.model == "developer") {
    return <ConfigurationDeveloperView {...args} />;
  }
  return ConfigurationFlexibleView(args);
};
