//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Dropdown } from "semantic-ui-react";
import { User as ApiUser } from "../api/iam/v1/iam";
import { TopMenuOnboarding, TopMenuUserOnboarding, TextLink } from "../ui/lib";

import userUiIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/ui/user.svg";
export interface IOnboardingMenuArgs {
  user?: ApiUser;
  onClickLogout: () => void;
}

export const OnboardingMenu = ({ ...args }: IOnboardingMenuArgs) => {
  return (
    <TopMenuOnboarding>
      <TextLink
        onClick={() => {
          window.open("https://www.arangodb.com/contact/");
        }}
      >
        Request help
      </TextLink>
      <TopMenuUserOnboarding>
        <Dropdown item icon={<img src={userUiIcon} alt="user" />} simple direction="left">
          <Dropdown.Menu>
            <Dropdown.Header>Signed in as</Dropdown.Header>
            <Dropdown.Item disabled> {args.user ? args.user.name : "?"}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>My Account</Dropdown.Header>
            <Dropdown.Item onClick={args.onClickLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </TopMenuUserOnboarding>
    </TopMenuOnboarding>
  );
};
