//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDataloaderStore } from "../DataloaderStore";
import { Edge } from "vis-network";
import { DocumentCollection, EdgeCollection } from "arangojs/collection";
import { useDeploymentStore } from "../../../../util/storage/DeploymentStore";

const generateNodeAndEdgeCollections = () => {
  const { updateFiles, getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { nodeMappings, edgeMappings, nodes, edges, files, currentDatabase } = getDeploymentDataloaderState();
  const { db } = currentDatabase;

  const nodeCollections: DocumentCollection[] = [];
  const edgeCollections: EdgeCollection[] = [];

  if (!db)
    return {
      nodeCollections,
      edgeCollections,
    };

  let updatedFiles = [...files];

  for (const node of nodes) {
    if (!node.label) break;

    const correspondingFile = files.find((file) => file.id === nodeMappings[node.id].attachedFile.id);

    if (!correspondingFile) break;

    const correspondingFileIndex = files.findIndex((file) => file.id === correspondingFile.id);
    const newCorrespondingFile = { ...correspondingFile, collectionName: node.label };

    updatedFiles[correspondingFileIndex] = newCorrespondingFile;

    const nodeCollection = db.collection(node.label);

    if (nodeCollection) nodeCollections.push(nodeCollection);
  }

  for (const edge of edges) {
    if (!edge.label) break;
    const correspondingFile = files.find((file) => file.id === edgeMappings[edge.id].attachedFile.id);

    if (!correspondingFile) break;

    const correspondingFileIndex = files.findIndex((file) => file.id === correspondingFile.id);
    const newCorrespondingFile = { ...correspondingFile, collectionName: edge.label };

    updatedFiles[correspondingFileIndex] = newCorrespondingFile;

    const edgeCollection = db.collection(edge.label);

    if (edgeCollection) edgeCollections.push(edgeCollection);
  }

  updateFiles(updatedFiles);

  return {
    nodeCollections,
    edgeCollections,
  };
};

const generateEdgeDefinitions = () => {
  const { getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { nodes, edges } = getDeploymentDataloaderState();

  const { nodeCollections, edgeCollections } = generateNodeAndEdgeCollections();

  const edgeDefinitions: {
    collection: EdgeCollection;
    from: DocumentCollection;
    to: DocumentCollection;
  }[] = [];

  for (const edgeCollection of edgeCollections) {
    const correspondingEdge = edges.find((edge) => edge.label === edgeCollection.name) as Edge | undefined;

    if (!correspondingEdge) break;

    const { from: sourceId, to: targetId } = correspondingEdge;

    const sourceNode = nodes.find((node) => node.id === sourceId);
    const targetNode = nodes.find((node) => node.id === targetId);

    const from = nodeCollections.find((nodeCollection) => nodeCollection?.name === sourceNode?.label);
    const to = nodeCollections.find((nodeCollection) => nodeCollection?.name === targetNode?.label);

    if (!from || !to) break;

    edgeDefinitions.push({
      collection: edgeCollection,
      from,
      to,
    });
  }

  const orphanCollections = nodeCollections.filter(
    (nodeCollection) => !edgeDefinitions.some(({ from, to }) => from.name === nodeCollection.name || to.name === nodeCollection.name)
  );

  return {
    edgeDefinitions,
    orphanCollections,
  };
};

const uploadGraph = async () => {
  const { deployment } = useDeploymentStore.getState();
  const { currentDatabase, name } = useDataloaderStore.getState().getDeploymentDataloaderState();

  const { edgeDefinitions, orphanCollections } = generateEdgeDefinitions();

  const { db } = currentDatabase;
  const { model, node_count } = deployment.model || {};

  await db?.createGraph(name, edgeDefinitions, {
    isSmart: model === "sharded",
    numberOfShards: model === "sharded" ? node_count : 1,
    orphanCollections,
  });
};

export { uploadGraph };
