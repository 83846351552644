//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import styled from "@emotion/styled";

const SubValue = styled("div")`
  font-size: 60%;
  font-weight: normal;
`;

interface IChildren {
  children?: any;
  size?: string;
}

export const Statistics3 = ({ ...args }: IChildren) => <div className={`ui ${args.size || "three"} mini statistics`}>{args.children}</div>;

interface IStatistic {
  label: any;
  value: number | string;
  subvalue?: string;
  ext?: string;
  prefix?: string;
}

export const Statistic = ({ ...args }: IStatistic) => (
  <div className="statistic">
    <div className="label">{args.label}</div>
    <div className="value">
      {args.prefix} {args.value} {args.ext}
      {!!args.subvalue && <SubValue>{args.subvalue}</SubValue>}
    </div>
  </div>
);
