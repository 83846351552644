//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ParseError, ParseStepResult, parse, unparse } from "papaparse";
import { omit } from "lodash";
import { useState } from "react";
import { FileObject, ParsedFileReport, CustomParseError } from "../types";
import { useDataloaderStore } from "../DataloaderStore";
import { delay } from "lodash";
import { MAX_ERRORS_LENGTH } from "../utils/filesUpload.utils";

export type UploadState = {
  file: FileObject;
  error?: string;
  progress?: number;
  uploadId: string;
};

export const EXTRA_FIELD = "__parsed_extra";
const MAX_CHUNK_MB_IN_BYTES = 5 * 1024 * 1024;

export type FileParserArgs = {
  previewFileData: (file: File) => Promise<{ fields: Omit<Record<string, string>, "__parsed_extra">; errors: CustomParseError[] }>;
  previewFileObject: (fileObj: FileObject) => Promise<FileObject>;
  isFileBeingParsed: (id: string) => boolean;
  queueFileToPreview: (files: FileObject[]) => Promise<FileObject[]>;
};

export const replaceParseErrorCode = (errorCode: ParseError["code"]) => {
  const errorCodeMap: Record<ParseError["code"], string> = {
    InvalidQuotes: "Invalid Quotation Mark",
    MissingQuotes: "Missing Quotation Marks",
    TooFewFields: "Insufficient Data Columns",
    TooManyFields: "Excessive Data Columns",
    UndetectableDelimiter: "Unidentifiable Column Separator",
  };

  return errorCodeMap[errorCode];
};

export const describeParseErrorCode = (errorCode: ParseError["code"]) => {
  const errorCodeMap: Record<ParseError["code"], string> = {
    InvalidQuotes: "This error indicates issues with quotation marks in the CSV data. It can occur due to improper use of quotes.",
    MissingQuotes: "This error occurs when quotation marks are missing or improperly placed in the CSV data, potentially affecting data enclosure.",
    TooFewFields: "This error occurs when a CSV row has fewer columns than expected. It may indicate missing or improperly formatted data.",
    TooManyFields: "This error occurs when a CSV row has more columns than expected, possibly due to extra data or formatting issues.",
    UndetectableDelimiter: "This error suggests that the parser could not identify the field separator character in the CSV data.",
  };

  return errorCodeMap[errorCode];
};

const parseFile = async (file: FileObject, updateProgress: (file: FileObject, progress: number) => void) => {
  const csvString = await file.file.text();
  let processedDocuments = 0;
  let chunk: ParseStepResult<Record<string, string>>[] = [];
  let chunkSizeInBytes = 0;

  const totalDocuments = Math.ceil(csvString.split("\n").length);
  const parsingErrors: ParseError[] = [];
  const processingData = new Promise((resolve) => {
    parse<Record<string, string>>(csvString, {
      header: true,
      skipEmptyLines: true,
      step: async (results, parser) => {
        const dataBytes = new TextEncoder().encode(JSON.stringify(results)).length * 2;
        chunkSizeInBytes += dataBytes;
        processedDocuments++;
        chunk.push(results);
        results.errors.forEach((error) => parsingErrors.push(error));

        if (chunkSizeInBytes >= MAX_CHUNK_MB_IN_BYTES) {
          parser.pause();
          if (useDataloaderStore.getState().getDeploymentDataloaderState().migrationJob.migrationCancelled) {
            parser.abort();
            return;
          }

          chunk = [];
          chunkSizeInBytes = 0;

          const currentProgress = Math.floor((processedDocuments / totalDocuments) * 100);
          updateProgress(file, currentProgress);
          parser.resume();
        }
      },

      complete: async (results) => {
        results.errors.forEach((error) => parsingErrors.push(error));
        updateProgress(file, 100);
        delay(resolve, 500);
      },
    });
  });

  await processingData;
  return parsingErrors;
};

const generateReportUrl = (errors: CustomParseError[]) => {
  const csv = unparse(errors);
  const csvDataBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  return URL.createObjectURL(csvDataBlob);
};

const getParsedFileReport = (file: FileObject, errors: CustomParseError[]): ParsedFileReport => {
  return {
    name: file.name,
    errors: errors.slice(0, MAX_ERRORS_LENGTH),
    parseReportURL: errors.length > MAX_ERRORS_LENGTH ? generateReportUrl(errors) : undefined,
  };
};

const updateFileParsingProgress = (file: FileObject, progress: number) => {
  const { setMigrationJob, getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { migrationJob } = getDeploymentDataloaderState();
  if (!migrationJob.migrationCancelled) {
    setMigrationJob({
      status: "files_validation",
      fileBeingParsed: {
        id: file.id,
        name: file.name,
        progress,
      },
    });
  } else {
    setMigrationJob({
      status: "not_started",
      fileBeingParsed: undefined,
      filesAlreadyParsed: [],
    });
  }
};

const parseAllFiles = async () => {
  const { setMigrationJob, getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { files } = getDeploymentDataloaderState();

  setMigrationJob({ status: "files_validation" });

  const parsedFilesReports: ParsedFileReport[] = [];

  for (const file of files) {
    if (!useDataloaderStore.getState().getDeploymentDataloaderState().migrationJob.migrationCancelled) {
      setMigrationJob({ status: "files_validation", fileBeingParsed: { name: file.name, progress: 0, id: file.id } });

      const errors = await parseFile(file, updateFileParsingProgress);
      const transformedErrors = errors.map((error) => ({ ...error, code: replaceParseErrorCode(error.code), description: describeParseErrorCode(error.code) }));
      parsedFilesReports.push(getParsedFileReport(file, transformedErrors));

      if (!useDataloaderStore.getState().getDeploymentDataloaderState().migrationJob.migrationCancelled) {
        setMigrationJob({
          status: "files_validation",
          fileBeingParsed: undefined,
          filesAlreadyParsed: parsedFilesReports,
        });
      }
    }
  }

  if (!useDataloaderStore.getState().getDeploymentDataloaderState().migrationJob.migrationCancelled) {
    setMigrationJob({ status: "files_validation", fileBeingUploaded: undefined, filesAlreadyParsed: parsedFilesReports });
  }
};

const useFileParser = (): FileParserArgs => {
  const [filesBeingParsed, setFilestoParse] = useState<string[]>([]);

  const isFileBeingParsed = (id: string) => filesBeingParsed.includes(id);

  const queueFileToPreview = async (files: FileObject[]) => {
    setFilestoParse(files.map((file) => file.id));

    return Promise.all(files.map((file) => previewFileObject(file))).finally(() => {
      setFilestoParse([]);
    });
  };

  const previewFileData = async (file: File) => {
    const text = await file.text();
    const { data, errors } = parse<Record<string, string>>(text, {
      header: true,
      skipEmptyLines: true,
      preview: 1,
    });

    const transformedErrors = errors.map((error) => ({
      ...error,
      code: replaceParseErrorCode(error.code),
      description: describeParseErrorCode(error.code),
    }));

    return {
      fields: omit(data[0], [EXTRA_FIELD]),
      errors: transformedErrors,
    };
  };

  const previewFileObject = async (fileObj: FileObject) => {
    const { file } = fileObj;

    const { fields, errors } = await previewFileData(file);

    return {
      ...fileObj,
      fields,
      errors,
    };
  };

  return {
    previewFileData,
    previewFileObject,
    isFileBeingParsed,
    queueFileToPreview,
  };
};

export { useFileParser, parseAllFiles, parseFile, getParsedFileReport };
