//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchCPUSizes = ({ regionId }: { regionId: string }) => {
  const { project } = useProjectDetailsContext();
  const fetchCPUSizes = async () => {
    const cpu_sizes = await apiClients.dataClient.ListCPUSizes({
      project_id: project.id,
    });
    return cpu_sizes;
  };
  return useQuery([project.id, "cpuSizes"], fetchCPUSizes);
};
