//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon } from "semantic-ui-react";
import { useDataloaderStore } from "./DataloaderStore";
import { EdgeEditView } from "./edit-panel/Edge/EdgeEditView";
import { NodeEditView } from "./edit-panel/Node/NodeEditView";
import { StyledEditor } from "./edit-panel/Styles";
import { StyledDataloaderEmptyMessage } from "./Styles";

export const EditView = () => {
  const { editView } = useDataloaderStore().getDeploymentDataloaderState();

  return (
    <StyledEditor>
      {!editView && (
        <StyledDataloaderEmptyMessage>
          <Icon name="cloudsmith" color="grey" />
          Select a node or an edge to edit.
        </StyledDataloaderEmptyMessage>
      )}
      <NodeEditView />
      <EdgeEditView />
    </StyledEditor>
  );
};
