//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import styled from "@emotion/styled";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Accordion, Grid, Icon, Segment } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro, TransparentLogo1, TransparentLogo2 } from "./HomeTemplate";
import Markdown from "react-markdown";
import DocumentTitle from "react-document-title";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import cloud from "../semantic/themes/arangodb-oasis-default/assets/images/logos/cloud.svg";
import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";

import { ListOptions } from "../api/common/v1/common";
import apiClients from "../api/apiclients";
import {
  FaqGroup as ApiFaqGroup,
  FaqGroupEntry as ApiFaqGroupEntry,
  FaqGroupEntryList as ApiFaqGroupEntryList,
  FaqGroupList as ApiFaqGroupList,
} from "../api/lib";
import { Loading } from "../ui/lib";
import { reportError } from "../errors/reporting";
import { Box } from "../ui/_box";

const Group = styled("div")`
  margin-bottom: 48px;
`;

const GroupSegment = styled(Segment)`
  .active.title {
    font-weight: bold;
  }
`;

const Question = styled("span")``;

const Answer = styled("div")`
  padding-left: 32px;
`;

interface IFaqGroupViewArgs {
  group: ApiFaqGroup;
  entries?: ApiFaqGroupEntry[];
  selectedGroupId: string;
  selectedIndex: number;
  onClick: (index: number) => void;
}

const FaqGroupView = ({ ...args }: IFaqGroupViewArgs) => {
  return (
    <Group>
      <h2 className="heading-2">{args.group.name}</h2>
      <GroupSegment>
        <Accordion>
          {args.entries &&
            args.entries.map((e, i) => {
              const active = args.selectedGroupId == args.group.id && args.selectedIndex == i;
              return [
                <Accordion.Title key={`q${args.group.id}${i}`} active={active} onClick={() => args.onClick(i)}>
                  <Icon name="dropdown" />
                  <Question>{e.question}</Question>
                </Accordion.Title>,
                <Accordion.Content key={`a${args.group.id}${i}`} active={active}>
                  <Answer>
                    <Markdown
                      children={e.answer || ""}
                      components={{
                        p: ({ children }) => <p className="para">{children}</p>,
                        ol: ({ children }) => <ol className="ordered-list">{children}</ol>,
                        ul: ({ children }) => <ul className="unordered-list">{children}</ul>,
                        h1: ({ children }) => <h1 className="heading-1">{children}</h1>,
                        h2: ({ children }) => <h2 className="heading-2">{children}</h2>,
                        h3: ({ children }) => <h3 className="heading-3">{children}</h3>,
                        h4: ({ children }) => <h4 className="heading-4">{children}</h4>,
                        h5: ({ children }) => <h5 className="heading-5">{children}</h5>,
                      }}
                    />
                  </Answer>
                </Accordion.Content>,
              ];
            })}
        </Accordion>
      </GroupSegment>
    </Group>
  );
};

interface IFaqViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  groups?: ApiFaqGroupList;
  entries?: Map<string, ApiFaqGroupEntryList>;
  selectedGroupId: string;
  selectedIndex: number;
  loading: boolean;
  onClick: (groupId: string, index: number) => void;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
}

const FaqView = ({ ...args }: IFaqViewArgs) => {
  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
              <h1 className="heading-1">Frequently asked questions</h1>
            </Grid.Column>
            {false && (
              <Grid.Column width="8" only="computer tablet">
                <TransparentLogo1 src={cloud} />
                <TransparentLogo2 src={cloud} />
                {/*<!-- FullLogo src={cloud} />*/}
              </Grid.Column>
            )}
          </GridRowIntro>
        </Grid>
        {args.loading ? (
          <Loading />
        ) : (
          <span>
            <div>
              {args.groups &&
                args.groups.items &&
                args.groups.items
                  .sort((a, b) => {
                    return a.name! < b.name! ? -1 : 1;
                  })
                  .map((x) => {
                    return (
                      <FaqGroupView
                        {...args}
                        key={x.id}
                        group={x}
                        entries={args.entries && args.entries.get(x.id!)!.items!}
                        onClick={(i) => args.onClick(x.id!, i)}
                      />
                    );
                  })}
            </div>
            <Group>
              <h2 className="heading-2">Other questions...</h2>
              <p className="para">
                If you have questions that are not answered here, please{" "}
                <a className="text-link" href="https://www.arangodb.com/contact" target="_blank" rel="noreferrer">
                  contact us
                </a>
                .
              </p>
            </Group>
          </span>
        )}
      </Box>
    </HomeTemplateView>
  );
};

interface IFaqProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

interface IFaqState {
  loading: boolean;
  selectedGroupId: string;
  selectedIndex: number;
  errorMessage?: string;
  faqGroupList?: ApiFaqGroupList;
  faqGroupEntryList?: Map<string, ApiFaqGroupEntryList>;
}

// Faq shows frequently asked questions
class Faq extends Component<IFaqProps, IFaqState> {
  state = {
    selectedGroupId: "",
    selectedIndex: 0,
    errorMessage: undefined,
    faqGroupList: undefined,
    faqGroupEntryList: undefined,
    loading: false,
  } as IFaqState;

  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  onClick = (groupId: string, index: number) => {
    this.setState((old) => {
      const isSame = old.selectedGroupId == groupId && old.selectedIndex == index;
      return {
        selectedGroupId: isSame ? "" : groupId,
        selectedIndex: index,
      };
    });
  };

  fetchEntries = async (id: string): Promise<ApiFaqGroupEntryList> => {
    const faqGroupEntryRequest = { context_id: id } as ListOptions;
    return await apiClients.supportClient.ListFaqGroupEntries(faqGroupEntryRequest);
  };

  reloadFaqs = async () => {
    this.setState({ loading: true });
    const faqGroupsRequest = {} as ListOptions;
    const faqGroupList = await apiClients.supportClient.ListFaqGroups(faqGroupsRequest);
    const faqGroupEntryList = new Map<string, ApiFaqGroupEntryList>();
    for (const e of faqGroupList.items!) {
      if (e.id) {
        const entry = await this.fetchEntries(e.id);
        faqGroupEntryList.set(e.id, entry);
      }
    }
    this.setState({
      faqGroupList: faqGroupList,
      faqGroupEntryList: faqGroupEntryList,
      errorMessage: undefined,
    });
  };

  refreshFaqs = async () => {
    try {
      await this.reloadFaqs();
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.refreshFaqs();
  }

  render() {
    return (
      <DocumentTitle title="Frequently asked questions">
        <FaqView
          {...this.props}
          {...this.state}
          loading={this.state.loading}
          isAuthenticated={this.props.auth.isAuthenticated()}
          groups={this.state.faqGroupList}
          entries={this.state.faqGroupEntryList}
          onClick={this.onClick}
          onClickLogin={this.login}
          onSignup={this.signup}
          onClickLogout={this.logout}
        />
      </DocumentTitle>
    );
  }
}

export default withSignupModal(Faq);
