//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";

import arangodb_oasis_logo_darkbg_right from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangodb-oasis-logo-darkbg-right.png";
import arangodb_oasis_logo_whitebg_right from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangodb-oasis-logo-whitebg-right.png";
import logo_social from "../logo-social.png";
import { GridRowIntro, HomeTemplateView } from "./HomeTemplate";
import { Box } from "../ui/_box";

interface ILogosViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
}

const LogosView = ({ ...args }: ILogosViewArgs) => {
  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="5">
              <img src={arangodb_oasis_logo_darkbg_right} alt="ArangoGraph" />
            </Grid.Column>
            <Grid.Column width="5">
              <img src={arangodb_oasis_logo_whitebg_right} alt="ArangoGraph" />
            </Grid.Column>
            <Grid.Column width="5">
              <img src={logo_social} alt="ArangoGraph" />
            </Grid.Column>
          </GridRowIntro>
        </Grid>
      </Box>
    </HomeTemplateView>
  );
};

interface ILogosProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

interface ILogosState {}

// Logos is used to embed PNG logos
class Logos extends Component<ILogosProps, ILogosState> {
  state = {} as ILogosState;

  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  render() {
    return (
      <LogosView
        {...this.props}
        {...this.state}
        isAuthenticated={this.props.auth.isAuthenticated()}
        onClickLogin={this.login}
        onSignup={this.signup}
        onClickLogout={this.logout}
      />
    );
  }
}

export default withSignupModal(Logos);
