//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useState, useEffect } from "react";
import _ from "lodash";
import apiClients from "../../../api/apiclients";
import { useGlobalStore } from "../../../util/storage/GobalStore";
import { Provider, ListRegionsRequest, Region } from "../../../api/lib";

const useMLRegionAvailability = ({ regionId }: { regionId: string }) => {
  const [mlCompatibleRegions, setMLCompatibleRegions] = useState<Region[]>([]);

  const loadRegionsForProvider = async (provider: Provider) => {
    const organization = useGlobalStore.getState().organization;
    const listReq = { provider_id: provider.id, organization_id: organization.id } as ListRegionsRequest;
    try {
      const regions = await apiClients.platformClient.ListRegions(listReq);
      const availableRegions = regions && regions.items ? regions.items.filter((region) => region.available) : undefined;
      return _.orderBy(availableRegions || [], "location");
    } catch {
      return [];
    }
  };

  const loadProvidersRegions = async () => {
    const organization = useGlobalStore.getState().organization;
    const listReq = { organization_id: organization.id };
    const providersList = await apiClients.platformClient.ListProviders(listReq);
    const providers = providersList.items || [];

    const allRegionsPromise = providers.map((provider) => loadRegionsForProvider(provider));
    const allRegions = _.flatten(await Promise.all(allRegionsPromise)) || [];

    const mlRegions = allRegions.filter((region) => !!region.ml_supported) || [];

    const mlRegionsSorted = _.sortBy(mlRegions, [(obj) => _.defaultTo(obj.provider_id, ""), (obj) => _.defaultTo(obj.location, "")]);

    const providersMap = {
      aws: "AWS",
      gcp: "Google Cloud Platform",
      aks: "Azure",
    };

    const mlRegionsMappedProviders = mlRegionsSorted.map((region) => ({
      ...region,
      provider_id: providersMap[region.provider_id as keyof typeof providersMap] || region.provider_id,
    }));

    setMLCompatibleRegions(mlRegionsMappedProviders);
  };

  useEffect(() => {
    loadProvidersRegions();
  }, []);

  const isMLFeaturesAvailableAtRegion = !!mlCompatibleRegions.find((region) => region.id === regionId);

  return {
    mlCompatibleRegions,
    isMLFeaturesAvailableAtRegion,
  };
};

export default useMLRegionAvailability;
