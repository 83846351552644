//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";

interface IModelTitleViewArgs {
  model: string;
}

export const ModelTitleString = (model: string) => {
  switch (model) {
    case "oneshard":
      return "OneShard";
    case "sharded":
      return "Sharded";
    case "developer":
      return "Single Server";
    case "free":
      return "Free";
    case "flexible":
      return "Flexible";
    case "":
      return "Flexible";
    default:
      return model;
  }
};

export const ModelTitle = ({ ...args }: IModelTitleViewArgs) => <span>{ModelTitleString(args.model)}</span>;
