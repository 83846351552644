//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Modal } from "semantic-ui-react";
import { FormActionButton } from "../../ui/_buttons";
import { FlexBox } from "../../ui/_flex";
import { AppLinkButton, DropdownLinkButton } from "../../ui/_link";
import { HeaderCaption } from "../../ui/_style";
import HTMLRenderer from "../../ui/HTMLRenderer";
import styled from "@emotion/styled";
import moment from "moment";

interface iNotificationDetailArgs {
  onClose: () => void;
  title: string;
  onClickSupportRequest: () => void;
  showModal: boolean;
  content: string;
  recipients: string[];
  createdAt: string;
}

const StyledMessageHolder = styled.div`
  border: 1px solid var(--gray-600);
  border-left: 0;
  border-right: 0;
  padding-top: 40px;
  margin-bottom: 20px;
`;

export const StyledMicroHeaderInfo = styled.div`
  font-size: 0.8rem;
  color: var(--gray-600);
`;

const NotificationDetail = ({ onClose, title, showModal, content, recipients, createdAt = "", onClickSupportRequest }: iNotificationDetailArgs) => {
  return (
    <Modal open={showModal} size="large" data-testid="notificationDetailModal">
      <Modal.Header>
        <FlexBox justify="space-between">
          {title}
          <HeaderCaption>
            <DropdownLinkButton label="Click here to see the recipients for this notification" icon="caret down" options={recipients.sort()} />
          </HeaderCaption>
        </FlexBox>
        {!!createdAt && (
          <StyledMicroHeaderInfo data-testid="notificationCreatedAt">Sent on {moment(createdAt).format("MMM Do YYYY, h:mm A")}</StyledMicroHeaderInfo>
        )}
      </Modal.Header>
      <Modal.Content>
        <StyledMessageHolder>
          <HTMLRenderer
            renderContent={content}
            type="email"
            config={{
              WHOLE_DOCUMENT: true,
            }}
          />
        </StyledMessageHolder>
        <FlexBox justify="space-between" lineheight={2}>
          <span>
            If you did not make this request, please contact us to{" "}
            <AppLinkButton testID="notificationSupportAction" label="request help" handleClick={onClickSupportRequest} />
          </span>
          <FormActionButton title="Close" icon="cancel" onClick={onClose} data-testid="closeNotificationModalAction" />
        </FlexBox>
      </Modal.Content>
    </Modal>
  );
};

export default NotificationDetail;
