//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Form, Input, InputOnChangeData, Popup } from "semantic-ui-react";
import { useGraphEdgeHandlers } from "../../graph-modeller/GraphProvider";
import { useDataloaderStore } from "../../DataloaderStore";
import { StyledForm } from "../../graph-modeller/Styles";
import { getCollectionNameErrors } from "../../utils/namesValidations.utils";
import { v4 } from "uuid";

export const EdgeLabel = () => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const { getEdge, updateEdge } = useGraphEdgeHandlers();
  const edge = getEdge() || {};
  const { edgeMappings } = useDataloaderStore().getDeploymentDataloaderState();

  const [label, setLabel] = useState(edge.label || "");
  const [labelErrors, setLabelErrors] = useState<string[]>(getCollectionNameErrors(edge.label || ""));

  const hasLabelError = label !== "" && !!labelErrors.length;

  useEffect(() => {
    setLabel(edge.label || "");
  }, [edge]);

  return (
    <StyledForm error={hasLabelError}>
      <Form.Field control={Input} label="Edge label" error={hasLabelError}>
        <Popup
          open={hasLabelError && isInputFocused}
          position="bottom right"
          trigger={
            <Input
              data-testid="edge-label-input"
              fluid
              placeholder="Enter a label for the selected Edge"
              value={label}
              autoFocus
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                const { value } = data;
                updateEdge(
                  {
                    ...edge,
                    label: String(value),
                  },
                  edgeMappings
                );
                setLabel(value);
                setLabelErrors(getCollectionNameErrors(value));
              }}
              error={hasLabelError}
              icon={hasLabelError ? "info circle" : undefined}
            />
          }
        >
          {labelErrors.map((nameError) => (
            <p key={v4()}>{nameError}</p>
          ))}
        </Popup>
      </Form.Field>
    </StyledForm>
  );
};
