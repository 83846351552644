import * as Sentry from "@sentry/browser";
import { User as ApiUser } from "../api/iam/v1/iam";

const productionEnv = "production";
const newReplayInstance = new Sentry.Replay({
  maskAllInputs: false,
  maskAllText: false,
  mask: [".mask-data"],
});

export const toggleSentry = (user?: ApiUser) => {
  const enabled = !Boolean((user || {}).disable_enhanced_troubleshooting) && window.ENVIRONMENT === productionEnv;
  const client = Sentry.getCurrentHub().getClient();
  let replay = client?.getIntegration(Sentry.Replay);
  if (enabled) {
    // start replay
    if (client && client.addIntegration) {
      if (!replay) {
        replay = newReplayInstance;
      }
      client.addIntegration(replay);
      Sentry.setUser({
        email: (user || {}).email,
        id: (user || {}).id,
      });
      const isReplaySessionPresent = replay.getReplayId();
      if (!isReplaySessionPresent) {
        replay.start();
      }
    }
  } else {
    // stop replay
    if (replay) {
      replay.stop();
    }
  }
};
