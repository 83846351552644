import React from "react";
import { Grid, Button } from "semantic-ui-react";
import { Box } from "../../ui/_box";

const GetStartedView = ({ onClickSignup }: { onClickSignup: () => void }) => (
  <Box padding={"60px 0 60px 0"}>
    <Grid columns="16" stackable>
      <Grid.Row centered>
        <Grid.Column width="8" textAlign="center">
          <Button primary onClick={onClickSignup} size="massive">
            Start Free
            <br />
            <Box fontSize={"1.2rem"} color="var(--gray-200_)" fontWeight={400}>
              No credit card needed to get started
            </Box>
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width="12" textAlign="center">
          <Box padding={"10px 0"}>
            <b>Take ArangoGraph for a free 14-day test ride.</b>
          </Box>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Box>
);

export default GetStartedView;
