import { Box, Grid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {
  Organization as ApiOrganization,
  OrganizationList as ApiOrganizationList,
  ProjectList as ApiProjectList,
} from "../api/resourcemanager/v1/resourcemanager";
import { useAuthContext } from "../auth/AuthContextProvider";
import { Routes } from "../routes";
import { useTrackingContext } from "../tracking/TrackingContextProvider";
import { PersistentState } from "../util/PersistentState";
import { IWithRefreshProps } from "../util/WithRefresh";
import { useWithRefresh } from "../util/WithRefreshContext";
import DashboardHome from "./dashboard/DashboardHome";
import { useDashboardContext } from "./DashboardContextProvider";
import { PageNotFound } from "./PageNotFound";
import Pricing from "./pricing/Pricing";
import ProjectList from "./project/ProjectList";
import Organization from "./routes/Organization";
import Project from "./routes/Project";
import User from "./routes/User";
import { MainSidebar } from "./sidebar/MainSidebar";
import { DashboardTopMenu } from "./topMenu/DashboardTopMenu";
import { TopMenuInfo } from "./TopMenuInfo";

// Interface describing the organization view arguments
export interface IOrganizationViewArgs extends IWithRefreshProps {
  topMenuInfo: TopMenuInfo;
  organizations?: ApiOrganizationList;
  onClickLogout: () => void;
  reloadOrganizationInvites: () => void;
  selectedOrganization: ApiOrganization;
  onOrganizationSelected: (organizationId: string) => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  onOrganizationDeleted: (organizationId: string) => void;
  onReloadOrganization: () => void;
  onOpenInternalDashboard: () => void;
  projects?: ApiProjectList;
  onNewProjectCreated: (projectId: string, fromDashboardHome: boolean) => void;
  onProjectDeleted: () => void;
  onClickSupportRequest: () => void;
  debugMode: boolean;
}
export const MainView = ({ ...args }: IWithRefreshProps) => {
  const { onClickSupportRequest } = useDashboardContext();

  useEffect(() => {
    if (PersistentState.isRequestRedirectionPending(Routes.requestHelp)) {
      onClickSupportRequest();
      PersistentState.removeRedirection();
    }
  }, []);

  return (
    <Box>
      <Box margin="4em 0 0 0">
        <Box position="fixed" width="100%" left="0" top="0" bottom="0px">
          <MainSidebar />
          <DashboardRoutes />
        </Box>
      </Box>
    </Box>
  );
};

const DashboardRoutes = () => {
  const { auth } = useAuthContext();
  const { tracking } = useTrackingContext();
  const {
    selectedOrganization,
    topMenuInfo,
    reloadOrganizationInvites,
    onOrganizationSelected,
    onClickLogout,
    onNewOrganizationCreated,
    onOrganizationDeleted,
    onNewProjectCreated,
    onReloadOrganization,
    projects,
    organizations,
  } = useDashboardContext();
  const { eventSubscriptionManager, permissionCache } = useWithRefresh();

  if (!selectedOrganization || !topMenuInfo) {
    return null;
  }
  return (
    <Grid templateRows={topMenuInfo.getIsDisabled() ? "auto" : "auto 1fr"} overflow="hidden" minHeight="100%" maxHeight="100%" marginLeft="320px">
      <DashboardTopMenu />
      <Box overflow="auto" width="100%">
        <Switch>
          <Route
            exact
            path={Routes.dashboard}
            render={(props) => (
              <DashboardHome
                {...props}
                projects={projects}
                loading={false}
                organization={selectedOrganization}
                tracking={tracking}
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                topMenuInfo={topMenuInfo}
              />
            )}
          />
          <Route
            path={Routes.dashboard_pricing}
            render={(props) => (
              <Pricing
                {...props}
                loading={false}
                organization={selectedOrganization}
                topMenuInfo={topMenuInfo}
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                tracking={tracking}
              />
            )}
          />
          <Route
            path={Routes.dashboard_projects}
            render={(props) => (
              <ProjectList
                {...props}
                onProjectSelected={(projectId: string) => {
                  props.history.push(Routes.dashboard_project_detailsWithId(projectId));
                }}
              />
            )}
          />
          <Route
            path={Routes.dashboard_user}
            render={(props) => (
              <User
                {...props}
                loading={false}
                onOrganizationSelected={onOrganizationSelected}
                onOrganizationInviteAccepted={reloadOrganizationInvites}
                onOrganizationInviteRejected={reloadOrganizationInvites}
                topMenuInfo={topMenuInfo}
                tracking={tracking}
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                onClickLogout={onClickLogout}
                onNewOrganizationCreated={onNewOrganizationCreated}
              />
            )}
          />
          <Route
            path={Routes.dashboard_organization}
            render={(props) => (
              <Organization
                topMenuInfo={topMenuInfo}
                auth={auth}
                tracking={tracking}
                onOrganizationSelected={onOrganizationSelected}
                onNewOrganizationCreated={onNewOrganizationCreated}
                onOrganizationDeleted={onOrganizationDeleted}
                onNewProjectCreated={onNewProjectCreated}
                onReloadOrganization={onReloadOrganization}
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                projects={projects}
                {...props}
                loading={false}
                onOrganizationInviteDeleted={reloadOrganizationInvites}
              />
            )}
          />
          <Route path={Routes.dashboard_project} render={() => <Project />} />
          <Route render={() => <PageNotFound />} />
        </Switch>
      </Box>
    </Grid>
  );
};
