import styled from "@emotion/styled";
import { LocationDescriptorObject } from "history";
import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Box } from "../../ui/_box";

const StyledNavLink = styled(NavLink)`
  color: var(--gray-100);
  :hover {
    color: white;
    background-color: var(--gray-800);
  }
  &.active {
    color: white;
    background-color: var(--gray-900);
  }
`;
const StyledIcon = styled(Icon)`
  &&& {
    margin-bottom: 4px;
    margin-right: 12px;
  }
`;
export const SidebarLink = ({
  to,
  children,
  show = true,
  count,
  icon,
  iconColor,
}: {
  show?: boolean;
  to: LocationDescriptorObject<unknown> | string;
  children: React.ReactNode;
  count?: string;
  icon?: SemanticICONS;
  iconColor?: SemanticCOLORS;
}) => {
  if (!show) {
    return null;
  }
  return (
    <HashNavLink to={to}>
      <Box padding="0 16px" width="100%" cursor="pointer" height="36px" display="flex" align="center">
        {icon && <StyledIcon inverted name={icon} color={iconColor} />}
        {children}
        {count && (
          <Box
            height="20px"
            width="20px"
            borderRadius="50%"
            display="flex"
            align="center"
            justify="center"
            backgroundColor="var(--red-700)"
            color="var(--gray-100)"
            fontSize="10px"
            fontWeight="700"
            margin="0 0 0 8px"
          >
            {count}
          </Box>
        )}
      </Box>
    </HashNavLink>
  );
};
const HashNavLink = ({ to, children }: { to: LocationDescriptorObject<unknown> | string; children: React.ReactNode }) => {
  return (
    <StyledNavLink
      to={to}
      exact
      isActive={(_match, location) => {
        if (typeof to === "string" && location.pathname === to) {
          return true;
        }
        if (typeof to === "object") {
          if (location.pathname === to.pathname) {
            if (location.hash === to.hash) {
              return true;
            }
            if (!to.hash && !location.hash) {
              return true;
            }
          }
        }
        return false;
      }}
    >
      {children}
    </StyledNavLink>
  );
};
