//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Options } from "vis-network";
import { getCSSVar } from "../../../../semantic/util";

const fontFamily = getCSSVar("--font-family");
const green600 = getCSSVar("--green-600");
const green700 = getCSSVar("--green-200");
const red200 = getCSSVar("--red-200");
const red600 = getCSSVar("--red-600");
const red700 = getCSSVar("--red-700");
const gray400 = getCSSVar("--gray-400");
const gray600 = getCSSVar("--gray-600");

export const DATALOADER_DIAGRAM_CONFIG: Options = {
  layout: {
    randomSeed: 0,
    improvedLayout: true,
  },
  nodes: {
    shape: "dot",
    size: 30,
    font: {
      face: fontFamily,
    },
    shadow: false,
    color: {
      border: gray400,
      background: "white",
      hover: {
        border: gray600,
        background: "white",
      },
      highlight: {
        border: green600,
        background: "white",
      },
    },
    borderWidthSelected: 3,
  },
  edges: {
    smooth: true,
    arrows: {
      to: { enabled: true, type: "arrow" },
    },
    color: {
      highlight: green600,
    },
    font: {
      face: fontFamily,
      align: "bottom",
    },
  },
  physics: {
    barnesHut: {
      springLength: 150,
      avoidOverlap: 1,
      damping: 0.2,
    },
    maxVelocity: 15,
    minVelocity: 2,
    enabled: true,
  },
  manipulation: {
    enabled: false,
    addEdge: (data: any, callback: any) => {
      callback(data);
    },
    controlNodeStyle: {
      shape: "dot",
      size: 3,
      color: {
        border: gray600,
        background: "white",
      },
      borderWidthSelected: 4,
      borderWidth: 4,
    },
  },

  interaction: {
    hover: true,
    dragView: true,
    zoomView: true,
    dragNodes: true,
    hideEdgesOnDrag: false,
    hideEdgesOnZoom: false,
    hideNodesOnDrag: false,
    hoverConnectedEdges: true,
    keyboard: {
      enabled: false,
      speed: { x: 10, y: 10, zoom: 0.02 },
      bindToWindow: true,
      autoFocus: true,
    },
    multiselect: false,
    navigationButtons: true,
    selectable: true,
    selectConnectedEdges: true,
    tooltipDelay: 300,
    zoomSpeed: 0.5,
  },
};

export const GRAPH_ATTRIBUTES: {
  menuLeftOffset: number;
  menuTopOffset: number;
} = {
  menuLeftOffset: 30,
  menuTopOffset: 60,
};

export const editModeGraphOptions: Options = {
  ...DATALOADER_DIAGRAM_CONFIG,
  nodes: {
    ...DATALOADER_DIAGRAM_CONFIG.nodes,
    shadow: {
      enabled: true,
      color: green700,
      size: 10,
      x: 0,
      y: 0,
    },
  },
};

export const edgeNotReadyForImportExtraOptions = {
  dashes: true,
  color: {
    color: red600,
    highlight: red700,
    hover: red700,
  },
};

export const edgeReadyForImportExtraOptions = {
  dashes: false,
  color: {
    color: undefined,
    highlight: green600,
  },
};

export const nodeNotReadyForImportExtraOptions = {
  shapeProperties: {
    borderDashes: [5, 5],
  },
  color: {
    border: red600,
    background: red200,
    hover: {
      border: red700,
      background: red200,
    },
    highlight: {
      border: red700,
      background: red200,
    },
  },
};

export const nodeReadyForImportExtraOptions = {
  borderWidth: 2,
  shapeProperties: {
    borderDashes: false,
  },
  color: {
    border: gray400,
    background: "white",
    hover: {
      border: gray600,
      background: "white",
    },
    highlight: {
      border: green600,
      background: "white",
    },
  },
};
