//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchTermsAndConditions = () => {
  const { selectedOrganization } = useDashboardContext();
  const fetchTermsAndConditions = async () => {
    const termsAndConditions = await apiClients.resourceManagerClient.GetCurrentTermsAndConditions({
      id: selectedOrganization.id || "",
    });
    return termsAndConditions;
  };
  return useQuery([selectedOrganization.id, "termsAndConditions"], fetchTermsAndConditions);
};
