//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
// Ewout Prangsma
//

import React, { Component } from "react";
import { Icon, Loader, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { CACertificate as ApiCACertificate } from "../../api/crypto/v1/crypto";
import { LoaderBox, TextLink } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import CACertificateDetailsModal from "../cacertificate/CACertificateDetailsModal";

interface ICACertificateViewArgs {
  caCertificate?: ApiCACertificate;
  onShow: () => void;
}

const CACertificateView = ({ ...args }: ICACertificateViewArgs) => {
  if (args.caCertificate) {
    const isExpired = !!args.caCertificate.is_expired;
    const expiresSoon = !!args.caCertificate.will_expire_soon;
    return (
      <TextLink onClick={args.onShow}>
        {isExpired && <Popup content="Certificate is expired" trigger={<Icon name="warning sign" color="red" />} />}
        {expiresSoon && <Popup content="Certificate will expire soon" trigger={<Icon name="warning" color="yellow" />} />}
        {args.caCertificate.name}
      </TextLink>
    );
  }

  return <div>retrieving</div>;
};

// Interface decribing the properties of the CACertificate component
interface ICACertificateProps extends IWithRefreshProps {
  caCertificateId?: string;
}

// Interface decribing the state of the CACertificate component
interface ICACertificateState {
  caCertificate?: ApiCACertificate;
  showDetails: boolean;
}

class CACertificate extends Component<ICACertificateProps, ICACertificateState> {
  state = {
    caCertificate: undefined,
    showDetails: false,
  } as ICACertificateState;

  reloadCACertificateInfo = async () => {
    const idOptions = { id: this.props.caCertificateId } as ApiIDOptions;
    const caCertificate = await apiClients.cryptoClient.GetCACertificate(idOptions);
    this.setState({ caCertificate: caCertificate });
  };

  componentDidMount() {
    if (this.props.caCertificateId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadCACertificateInfo);
    }
  }

  render() {
    if (!this.props.caCertificateId) {
      return <div>Unknown</div>;
    }

    return (
      <div>
        <LoaderBox>
          <Loader size="mini" active={this.props.loading} inline />
        </LoaderBox>
        {this.state.showDetails && (
          <CACertificateDetailsModal {...this.props} caCertificateId={this.props.caCertificateId || ""} onClose={() => this.setState({ showDetails: false })} />
        )}
        <CACertificateView {...this.state} onShow={() => this.setState({ showDetails: true })} />
      </div>
    );
  }
}

export default withRefresh()(CACertificate);
