//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import moment from "moment";
import _ from "lodash";
import { Duration } from "../api/googleTypes";

export const humanizeDuration = (x: Duration) => {
  const s = _.toNumber(_.toString(x).replace("s", ""));
  return moment.duration(s, "seconds").humanize();
};
