//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { Grid, Icon, Message, Modal, Segment } from "semantic-ui-react";
import { FormActionButtonCancel } from "../../../ui/_buttons";
import { ContentKeyValuePairs, ContentKeyValuePair, ContentKV_Key, ContentKV_Value } from "../../../ui/_style";
import { IAWSPrivateEndpointConnectionsViewArgs, IAWSPrivateEndpointConnectionsViewModalArgs } from "../types";

const AWSPrivateEndpointConnections = ({ connections }: IAWSPrivateEndpointConnectionsViewArgs) => {
  if (!connections.length)
    return (
      <Segment data-testid="noConnection">
        <Icon name="warning sign" /> No connections Established
      </Segment>
    );
  return (
    <>
      <Message data-testid="connectionInfo">Below you will find detailed information of all the connections made from Private Endpoint(s).</Message>

      {connections.map((connection) => (
        <Segment data-testid="connectionCard" key={connection.id}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>
                      Name {connection.state === "failed" && <Icon name="warning circle" color="red" data-testid={connection.id} />}
                    </ContentKV_Key>
                    <ContentKV_Value>{connection.owner}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
              <Grid.Column width={6}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>ID</ContentKV_Key>
                    <ContentKV_Value>{connection.id}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>Created At</ContentKV_Key>
                    <ContentKV_Value>{moment(connection.created_at).fromNow()}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
              <Grid.Column width={6}>
                <ContentKeyValuePairs>
                  <ContentKeyValuePair>
                    <ContentKV_Key>State</ContentKV_Key>
                    <ContentKV_Value>{connection.state}</ContentKV_Value>
                  </ContentKeyValuePair>
                </ContentKeyValuePairs>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ))}
    </>
  );
};

export default AWSPrivateEndpointConnections;

const AWSPrivateEndpointConnectionsModalView = ({ onClose, connections }: IAWSPrivateEndpointConnectionsViewModalArgs) => {
  return (
    <Modal open onClose={onClose} data-testid="privateNetworkAWSConnectionsModal">
      <Modal.Header>Active connections</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AWSPrivateEndpointConnections connections={connections} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="privateNetworkAWSConnectionsModalcloseAction" />
      </Modal.Actions>
    </Modal>
  );
};

export { AWSPrivateEndpointConnectionsModalView };
