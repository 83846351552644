//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { Button, Flex, Text, useClipboard, useToast } from "@chakra-ui/react";

const CopyTextButton = ({ textToCopy }: { textToCopy: string }) => {
  const { onCopy, hasCopied } = useClipboard(textToCopy || "", 5000);
  const toast = useToast();

  const onCopyText = async () => {
    onCopy();
    toast({
      title: `Copied to clipboard`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Button
      variant="link"
      onClick={onCopyText}
      fontWeight="normal"
      fontSize="large"
      color="gray.800"
      _hover={{
        color: "gray.950",
      }}
    >
      <Flex alignItems="center" gap="2">
        <Text>Copy</Text>
        {hasCopied ? <CheckIcon color="green" /> : <CopyIcon />}
      </Flex>
    </Button>
  );
};

export { CopyTextButton };
