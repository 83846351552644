//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchSupportPlans = ({ modelId }: { modelId: string }) => {
  const { selectedOrganization } = useDashboardContext();

  const fetchSupportPlans = async () => {
    const plans = await apiClients.supportClient.ListPlans({
      organization_id: selectedOrganization.id,
      model: modelId,
    });
    return plans;
  };
  return useQuery([selectedOrganization.id, modelId, "supportPlans"], fetchSupportPlans);
};
