//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Divider, Form, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import copy from "copy-to-clipboard";
import { PRIVATE_ENDPOINT_CREATION_GUIDEGUIDE } from "./privateNetworkURLs";

interface IAliasViewArgs {
  initialValue?: string;
}

const AliasView = ({ initialValue = "" }: IAliasViewArgs) => {
  return (
    <>
      <Message data-testid="aliasInfo">
        To create a Private Endpoint in your VNet, follow the instructions found in{" "}
        <a href={PRIVATE_ENDPOINT_CREATION_GUIDEGUIDE} target="_blank" className="text-link" rel="noopener noreferrer">
          this guide
        </a>
        .
        <br />
        In the <b>Resource</b> step of this guide, choose <b>Resource ID or alias</b> and use the alias listed below.
      </Message>
      <Divider hidden />
      <Form>
        <Form.Input data-testid="aliasInputField" value={initialValue} label="Azure Alias" />
      </Form>
    </>
  );
};

export default AliasView;

interface IAliasModalViewArgs extends IAliasViewArgs {
  onClose: () => void;
  onAliasCopy?: (alias: string) => void;
}

const AliasModalView = ({ onClose, onAliasCopy, initialValue = "" }: IAliasModalViewArgs) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      onAliasCopy && onAliasCopy(initialValue);
      onClose && onClose();
    }
  }, [copied]);

  const handleCopy = () => {
    copy(initialValue);
    setCopied(true);
  };

  return (
    <Modal open onClose={onClose} data-testid="privateNetworkAliasViewModal">
      <Modal.Header>Alias view</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AliasView initialValue={initialValue} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} data-testid="aliasViewCloseAction" />
        <FormActionButtonSave title="Copy & Close" icon={copied ? "check" : "copy"} primary onClick={handleCopy} data-testid="aliasCopyButton" />
      </Modal.Actions>
    </Modal>
  );
};

export { AliasModalView };
