//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import React from "react";
import { useDisableTopMenu } from "../../../components/useDisableTopMenu";
import { OrganizationPolicyTopMenu } from "./OrganizationPolicyTopMenu";
import { OrganizationPolicyList } from "./OrganizationPolicyList";

export const OrganizationPolicyWrap = () => {
  useDisableTopMenu();
  return (
    <Grid templateRows={"auto minmax(0, 1fr)"} overflow="hidden" height="100vh">
      <OrganizationPolicyTopMenu />
      <OrganizationPolicyList />
    </Grid>
  );
};
