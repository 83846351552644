//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import {
  AuditLog_Destination as ApiAuditLogDestination,
  TestAuditLogHttpsPostDestinationRequest as ApiTestAuditLogHttpsPostDestinationRequest,
} from "../../api/lib";
import {
  ErrorMessage,
  FloatRight,
  FormActionButton,
  FormActionButtonCancel,
  FormActionButtonCreate,
  FormContentAction,
  FormContentActionRight,
  FormContentActions,
  MainContent,
} from "../../ui/lib";
import { EditAuditLogHttpDestinationView } from "./EditAuditLogDestinationViews";
import { SplitHeadersString } from "./Helpers";
import apiClients from "../../api/apiclients";

interface ICreateAuditLogHttpDestinationViewArgs extends ICreateAuditLogHttpDestinationModalState, ICreateAuditLogHttpDestinationModalProps {
  onURLChanged: (value: string) => void;
  onTrustedServerCAChanged: (value: string) => void;
  onClientCertificateChanged: (value: string) => void;
  onClientKeyChanged: (value: string) => void;
  onHeadersChanged: (value: string) => void;
  onCreate: () => void;
  onTest: () => void;
}

const CreateAuditLogHttpDestinationView = ({ ...args }: ICreateAuditLogHttpDestinationViewArgs) => {
  const hasURL = !_.isEmpty(args.url);
  const hasClientCertificate = !_.isEmpty(args.client_certificate_pem);
  const hasClientKey = !_.isEmpty(args.client_key_pem);
  const canCreate = hasURL && hasClientCertificate && hasClientKey;
  return (
    <Modal open onClose={args.onClose} size="large">
      <Modal.Header>New HTTP destination</Modal.Header>
      <Modal.Content>
        <MainContent>
          <ErrorMessage active={!!args.errorMessage} message={args.errorMessage} />
          <Form>
            <EditAuditLogHttpDestinationView {...args} />
            {args.testing && (
              <Message>
                <FloatRight>
                  <Icon loading name="circle notch" />
                </FloatRight>
                Testing...
              </Message>
            )}
            {args.testSucceeded && !args.testing && <Message content="Test succeeded" positive />}
            {!!args.testFailure && !args.testing && <Message content={args.testFailure} negative />}
            <FormContentActions>
              <Grid>
                <Grid.Column width="12">
                  <FormContentAction>
                    <FormActionButtonCreate primary disabled={!canCreate} onClick={args.onCreate} />
                  </FormContentAction>
                  <FormContentAction>
                    <FormActionButtonCancel onClick={args.onClose} />
                  </FormContentAction>
                </Grid.Column>
                <Grid.Column width="4" textAlign="right">
                  <FormContentActionRight>
                    <FormActionButton onClick={args.onTest} disabled={!canCreate || args.testing} title="Test" icon="paper plane outline" />
                  </FormContentActionRight>
                </Grid.Column>
              </Grid>
            </FormContentActions>
          </Form>
        </MainContent>
      </Modal.Content>
    </Modal>
  );
};

interface ICreateAuditLogHttpDestinationModalProps {
  organization_id: string;
  onNewDestinationCreated: (value: ApiAuditLogDestination) => void;
  onClose: () => void;
}

interface ICreateAuditLogHttpDestinationModalState {
  errorMessage?: string;
  url: string;
  trusted_server_ca_pem: string;
  client_certificate_pem: string;
  client_key_pem: string;
  headers: string;
  testing: boolean;
  testSucceeded: boolean;
  testFailure?: string;
}

// Component to create an audit log HTTP destination
class CreateAuditLogHttpDestinationModal extends Component<ICreateAuditLogHttpDestinationModalProps, ICreateAuditLogHttpDestinationModalState> {
  state = {
    errorMessage: undefined,
    url: "",
    trusted_server_ca_pem: "",
    client_certificate_pem: "",
    client_key_pem: "",
    headers: "",
    testing: false,
    testSucceeded: false,
    testFailure: undefined,
  } as ICreateAuditLogHttpDestinationModalState;

  onURLChanged = (value: string) => {
    this.setState({ url: value, testSucceeded: false, testFailure: undefined });
  };
  onTrustedServerCAChanged = (value: string) => {
    this.setState({ trusted_server_ca_pem: value, testSucceeded: false, testFailure: undefined });
  };
  onClientCertificateChanged = (value: string) => {
    this.setState({ client_certificate_pem: value, testSucceeded: false, testFailure: undefined });
  };
  onClientKeyChanged = (value: string) => {
    this.setState({ client_key_pem: value, testSucceeded: false, testFailure: undefined });
  };
  onHeadersChanged = (value: string) => {
    this.setState({ headers: value, testSucceeded: false, testFailure: undefined });
  };

  onCreate = async () => {
    try {
      const req = {
        type: "https-post",
        http_post: {
          url: this.state.url,
          trusted_server_ca_pem: this.state.trusted_server_ca_pem,
          client_certificate_pem: this.state.client_certificate_pem,
          client_key_pem: this.state.client_key_pem,
          headers: SplitHeadersString(this.state.headers),
        },
      } as ApiAuditLogDestination;
      this.props.onNewDestinationCreated(req);
    } catch (e) {
      this.setState({ errorMessage: e });
    }
  };

  onTest = async () => {
    try {
      this.setState({ testing: true, testSucceeded: false, testFailure: undefined, errorMessage: undefined });
      const req = {
        organization_id: this.props.organization_id,
        settings: {
          url: this.state.url,
          trusted_server_ca_pem: this.state.trusted_server_ca_pem,
          client_certificate_pem: this.state.client_certificate_pem,
          client_key_pem: this.state.client_key_pem,
          headers: SplitHeadersString(this.state.headers),
        },
      } as ApiTestAuditLogHttpsPostDestinationRequest;
      const server = await apiClients.auditClient.TestAuditLogHttpsPostDestination(req, (evt) => {
        if (!!evt.error) {
          if (!!evt.error.message) {
            this.setState({ testSucceeded: false, testFailure: `Test did not succeed: ${evt.error.message}` });
          } else {
            this.setState({ errorMessage: `Test request failed: ${evt.error}` });
          }
        } else if (!!evt.message) {
          if (!!evt.message.has_errors) {
            this.setState({ testSucceeded: false, testFailure: `Test did not succeed: ${evt.message.error_details}` });
          } else {
            this.setState({ testSucceeded: true, testFailure: undefined });
          }
        }
      });
      await server.closed;
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ testing: false });
    }
  };

  render() {
    return <CreateAuditLogHttpDestinationView {...this.props} {...this.state} {...this} active />;
  }
}

export default CreateAuditLogHttpDestinationModal;
