//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { Organization, Project } from "../../api/lib";
import { GlobalStoreRequiredProps, PersistentStoreRequiredProps } from "./types";

export const useGlobalStore = create<GlobalStoreRequiredProps>((set) => ({
  visibleTabElement: {},
  featureFlags: [],
  organization: {},
  project: {},
  setOrganization: (organization: Organization) => set((state) => ({ ...state, organization })),
  setVisibleTabElement: (visibleTabElement: { [key: string]: boolean }) => set((state) => ({ ...state, visibleTabElement })),
  setFeatureFlags: (featureFlags: string[]) => set((state) => ({ ...state, featureFlags })),
  setProject: (project: Project) => set((state) => ({ ...state, project })),
}));

export const usePersistentStorage = create<Partial<PersistentStoreRequiredProps>>()(
  persist(
    (set) => ({
      organization: {},
      setOrganization: (organization: Organization) => set((state) => ({ ...state, organization })),
    }),
    {
      name: "appState",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
