//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Loader, Table } from "semantic-ui-react";
import { OrganizationInviteList as ApiOrganizationInviteList, OrganizationInvite as ApiOrganizationInvite } from "../../api/resourcemanager/v1/resourcemanager";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Loading, LoaderBoxForTable as LoaderBox, ListActionAccept, ListActionReject } from "../../ui/lib";

// Interface describing a pending invite
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Created By</Table.HeaderCell>
      <Table.HeaderCell>Organization Name</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a pending invite
interface IRowView {
  active: boolean;
  item: ApiOrganizationInvite;
  onAccept: () => void;
  onReject: () => void;
}

const RowView = ({ ...args }: IRowView) => (
  <Table.Row>
    <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{args.item.created_by_name}</Table.Cell>
    <Table.Cell>{args.item.organization_name}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        <ListActionAccept disabled={!args.active} onClick={args.onAccept} />
        <ListActionReject disabled={!args.active} onClick={args.onReject} />
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface describing the pending invites
interface IListView {
  active: boolean;
  items: ApiOrganizationInvite[];
  loading: boolean;
  onAccept: (id: string) => void;
  onReject: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.items.map((item) => (
        <RowView {...args} key={item.id} item={item} onAccept={() => args.onAccept(item.id || "")} onReject={() => args.onReject(item.id || "")} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No organization invites pending</div>;

// Interface describing the pending invites view arguments
export interface IPendingOrganizationInviteListViewArgs extends RouteComponentProps {
  active: boolean;
  organizationInvites?: ApiOrganizationInviteList;
  loading: boolean;
  onAccept: (id: string) => void;
  onReject: (id: string) => void;
}

export const PendingOrganizationInviteListView = ({ ...args }: IPendingOrganizationInviteListViewArgs) => {
  if (!args.organizationInvites) {
    return <Loading />;
  }
  const pendingItems = (args.organizationInvites.items || []).filter((x) => !(x.accepted || x.rejected));
  if (!pendingItems.length) {
    return <EmptyView />;
  }
  return <ListView {...args} items={pendingItems} />;
};
