//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchDeploymentFeatures = ({ modelId, regionId, nodeSizeId }: { modelId: string; regionId: string; nodeSizeId: string }) => {
  const { project } = useProjectDetailsContext();
  const fetchDeploymentFeatures = async () => {
    const req = {
      project_id: project.id,
      region_id: regionId || undefined,
      model: modelId || undefined,
      node_size_id: nodeSizeId,
    };
    return await apiClients.dataClient.GetDeploymentFeatures(req);
  };

  return useQuery([project.id, regionId, modelId, nodeSizeId, "deploymentFeatures"], fetchDeploymentFeatures, {
    enabled: !!project.id && !!regionId && !!modelId && !!nodeSizeId,
  });
};
