//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Message } from "semantic-ui-react";
import { DangerZoneItem, Section } from "../../ui/lib";
import { useDeploymentPermissions } from "./useDeploymentPermissions";

interface IDangerZoneViewArgs {
  locked: boolean;
  onDeleteDeployment: () => void;
  onLockDeployment: () => void;
  onUnlockDeployment: () => void;
  onPauseDeployment: () => void;
  processing: boolean;
  disableAllActions?: boolean;
  isPauseFeatureAvailable: boolean | undefined;
  isDeploymentPaused: boolean;
}

export const DangerZoneView = ({ ...args }: IDangerZoneViewArgs) => {
  const { isDeploymentEditAllowed, isDeploymentDeleteAllowed, canPauseDeployement } = useDeploymentPermissions();
  const pauseDisabled = !canPauseDeployement || args.isDeploymentPaused;

  return (
    <Section>
      <Message>Find listed all critical actions concerning your deployment.</Message>
      {!args.locked && (
        <DangerZoneItem
          buttonIcon="lock"
          buttonText="Lock deployment..."
          onClick={args.onLockDeployment}
          disabled={args.processing || !isDeploymentEditAllowed || !!args.disableAllActions}
        >
          <strong>Lock</strong> this deployment. A locked deployment cannot be deleted without unlocking first.
        </DangerZoneItem>
      )}
      {args.isPauseFeatureAvailable && (
        <DangerZoneItem
          buttonIcon="pause"
          buttonText="Pause deployment..."
          onClick={args.onPauseDeployment}
          disabled={pauseDisabled || !!args.disableAllActions}
        >
          {pauseDisabled ? (
            <div>
              To resume this deployment, go to the <strong>Overview tab</strong>
            </div>
          ) : (
            <>
              <strong>Pause</strong> this deployment.
            </>
          )}
        </DangerZoneItem>
      )}
      {args.locked && (
        <DangerZoneItem
          buttonIcon="unlock"
          buttonText="Unlock deployment..."
          onClick={args.onUnlockDeployment}
          disabled={args.processing || !isDeploymentEditAllowed || !!args.disableAllActions}
        >
          <strong>Unlock</strong> this deployment. You must unlock before the deployment can be deleted.
        </DangerZoneItem>
      )}
      <DangerZoneItem
        buttonIcon="trash"
        buttonColor="red"
        buttonText="Delete deployment..."
        onClick={args.onDeleteDeployment}
        disabled={args.processing || args.locked || !isDeploymentDeleteAllowed || !!args.disableAllActions}
      >
        <strong>Delete</strong> this deployment and all data in it.
      </DangerZoneItem>
    </Section>
  );
};
