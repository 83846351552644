//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Divider, Form, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import copy from "copy-to-clipboard";
import { IServiceNameArgs } from "../types";

const AWServiceNameView = ({ onClose, onServiceNameCopy, initialValue = "" }: IServiceNameArgs) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      onServiceNameCopy && onServiceNameCopy(initialValue);
      onClose && onClose();
    }
  }, [copied]);

  const handleCopy = () => {
    copy(initialValue);
    setCopied(true);
  };

  return (
    <Modal open onClose={onClose} data-testid="serviceNameAndAvailabilityZoneModal">
      <Modal.Header data-testid="serviceNameAndAvailabilityZoneModalHeader">Service name </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Input data-testid="serviceNameInputField" value={initialValue} label="AWS Service name" />
          </Form>
          <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="serviceNameViewCloseAction" />
        <FormActionButtonSave title="Copy & Close" icon={copied ? "check" : "copy"} primary onClick={handleCopy} testID="serviceNameCopyButton" />
      </Modal.Actions>
    </Modal>
  );
};

export { AWServiceNameView };
