import React from "react";
import backupScheduleIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/backup-schedule.svg";
import certificateIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/certificate.svg";
import dashboardIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/dashboard.svg";
import deploymentIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/deployment.svg";
import eventIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/event.svg";
import groupIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/group.svg";
import ipallowlistIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/icons/primary/ip-whitelist.svg";
import membersIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/members.svg";
import organizationInviteIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/organization-invite.svg";
import organizationIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/organization.svg";
import projectIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/project.svg";
import roleBindingIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/role-binding.svg";
import roleIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/role.svg";
import userIcon from "../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/user.svg";
import { ImageFull } from "../ui/lib";
import { TopMenuImages } from "./TopMenuInfo";

interface ITopMenuIconViewArgs {
  imageSource?: TopMenuImages;
}

export const TopMenuIconView = ({ ...args }: ITopMenuIconViewArgs) => {
  let result = "";
  switch (args.imageSource) {
    case "dashboard":
      result = dashboardIcon;
      break;
    case "user":
      result = userIcon;
      break;
    case "organization-invites":
    case "organization-invite":
      result = organizationInviteIcon;
      break;
    case "organizations":
    case "organization":
      result = organizationIcon;
      break;
    case "projects":
    case "project":
      result = projectIcon;
      break;
    case "members":
      result = membersIcon;
      break;
    case "groups":
    case "group":
      result = groupIcon;
      break;
    case "roles":
    case "role":
      result = roleIcon;
      break;
    case "deployments":
    case "deployment":
      result = deploymentIcon;
      break;
    case "certificates":
    case "certificate":
      result = certificateIcon;
      break;
    case "role-bindings":
    case "role-binding":
      result = roleBindingIcon;
      break;
    case "events":
      result = eventIcon;
      break;
    case "ipallowlist":
    case "ipallowlists":
      result = ipallowlistIcon;
      break;
    case "backup-policies":
      result = backupScheduleIcon;
      break;
  }

  if (result != "") {
    return <ImageFull src={result} alt={args.imageSource} />;
  }
  return <span></span>;
};
