import moment from "moment";
import React from "react";
import styled from "@emotion/styled";
import { Header, Segment } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import { Timestamp } from "../../api/googleTypes";

interface INotificationItemArgs {
  id: string;
  title: string;
  type?: string;
  createdOn: Timestamp | undefined;
  isRead: boolean | undefined;
  onItemClick: (id: string) => void;
}

const NotificationItemCard = styled(Segment)`
  cursor: pointer;
  padding: 30px 20px !important;
  &:hover {
    background-color: var(--gray-100) !important;
  }
`;

const NotificationTitle = styled(Header)`
  font-weight: ${(props) => (props["data-isread"] ? "normal !important" : "bold !important")};
  margin: 0 !important;
`;

const NotificationItem = ({ id, title, createdOn, isRead, onItemClick }: INotificationItemArgs) => {
  return (
    <NotificationItemCard vertical onClick={() => onItemClick(id)} data-testid="notificationItem">
      <FlexBox justify="space-between" margin={0} align="center">
        <Header as={NotificationTitle} data-isread={isRead} data-testid="notificationItemTitle">
          {title}
        </Header>
        <span data-testid="notificationItemDate"> {moment(createdOn).fromNow()}</span>
      </FlexBox>
    </NotificationItemCard>
  );
};

export default NotificationItem;
