//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Button, Dimmer, Loader, Segment } from "semantic-ui-react";
import { FlexBox } from "../../../ui/_flex";
import { Section, SectionHead, SectionHeader } from "../../../ui/_section";
import { ErrorMessage } from "../../../ui/_errorMessage";
import { RenderGuard } from "../../../util/RenderGuard";
import { METRICS_VALUES, SERVERS_VALUES } from "./useMetricsUsageApi";
import styled from "@emotion/styled";

import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import SingleServerMetrics from "./SingleServerMetrics";
import MultipleServerMetrics from "./MultipleServerMetrics";

export const METRICS: { [key: string]: METRICS_VALUES } = {
  CPU: "cpu",
  MEMORY: "memory",
  DISK: "disk",
};

export const SERVERS: { [key: string]: SERVERS_VALUES } = {
  DBSERVER: "Dbserver",
  COORDINATOR: "Coordinator",
};

export const RANGES = {
  MINUTES_10: 0,
  HOURS_1: 1,
  DAYS_1: 2,
  WEEKS_1: 3,
};

export const StyledButtonGroup = styled(Button.Group)`
  margin-bottom: 32px !important;
`;

const MetricsView = () => {
  const { model = {} } = useDeploymentStore().deployment;
  const { model: modelName } = model;
  const isSingleServerDeployment = modelName === "developer";

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Metrics"
          help={`This shows ${
            isSingleServerDeployment ? "server" : "DBServers and Coordinators"
          } usage over selected timeframe. This may change day-to-day based on the deployments that you have running.`}
          triggerText="What's this"
        />
      </SectionHead>
      <RenderGuard renderIf={!loading}>
        <ErrorMessage active={!!error} message={error} />
      </RenderGuard>
      <FlexBox margin="32px 0 0 0" justify="space-between" align="flex-start">
        {isSingleServerDeployment ? (
          <SingleServerMetrics setError={setError} setLoading={setLoading} loading={loading} />
        ) : (
          <MultipleServerMetrics setError={setError} setLoading={setLoading} loading={loading} />
        )}
      </FlexBox>
      <RenderGuard renderIf={!!loading}>
        <Segment data-testid="loadingView">
          <Dimmer inverted active>
            <Loader inverted>{"Loading..."}</Loader>
          </Dimmer>
          <div style={{ minHeight: "340px" }} />
        </Segment>
      </RenderGuard>
    </Section>
  );
};

export default MetricsView;
