//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { Form, Grid, Modal, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, IPAllowlist as ApiIPAllowlist } from "../../api/lib";
import {
  FormActionButtonCancel,
  FormActionButtonEdit,
  FormContentAction,
  FormContentActions,
  Loading,
  MainContent,
  Section,
  SectionContent,
  Statistic,
  Statistics3,
  Warnings,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface IIPAllowlistDetailsViewArgs extends IWithRefreshProps {
  ipallowlist: ApiIPAllowlist;
  onEdit?: () => void;
  onCancel: () => void;
}

const IPAllowlistDetailsView = ({ ...args }: IIPAllowlistDetailsViewArgs) => {
  const canEdit = !!args.onEdit;
  const warnings = args.ipallowlist.warnings;
  return (
    <Modal open onClose={args.onCancel}>
      <Modal.Header>IP allowlist details</Modal.Header>
      <Modal.Content>
        {args.loading && <Loading />}
        {!args.loading && (
          <MainContent>
            <Form>
              <Section>
                <SectionContent>
                  <Grid>
                    <Grid.Row columns={16}>
                      <Grid.Column width={4}>
                        <Form.Input readonly label="Name" name="name" value={args.ipallowlist.name || ""} />
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Form.Input readonly label="Short description" name="description" value={args.ipallowlist.description || ""} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={16}>
                      <Grid.Column width={16}>
                        <Form.TextArea
                          readonly
                          label={
                            <span>
                              Allowed CIDR ranges <Warnings warnings={warnings} />
                            </span>
                          }
                          name="cidr_ranges"
                          value={(args.ipallowlist.cidr_ranges || []).join("\n")}
                          rows="10"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Segment>
                    <Statistics3 size="3">
                      <Statistic label="remote inspection" value={args.ipallowlist.remote_inspection_allowed ? "Allowed" : "Not allowed"} />
                      <Statistic label="created" value={args.ipallowlist.created_at ? moment(args.ipallowlist.created_at).fromNow() : "-"} />
                      {args.ipallowlist.is_deleted && (
                        <Statistic label="deleted" value={args.ipallowlist.deleted_at ? moment(args.ipallowlist.deleted_at).fromNow() : "-"} />
                      )}
                    </Statistics3>
                  </Segment>
                </SectionContent>
              </Section>
              <FormContentActions>
                {canEdit && (
                  <FormContentAction>
                    <FormActionButtonEdit primary onClick={args.onEdit} />
                  </FormContentAction>
                )}
                <FormContentAction>
                  <FormActionButtonCancel onClick={args.onCancel} />
                </FormContentAction>
              </FormContentActions>
            </Form>
          </MainContent>
        )}
      </Modal.Content>
    </Modal>
  );
};

interface IIPAllowlistDetailsProps extends IWithRefreshProps {
  ipallowlistId: string;
  onEdit?: () => void;
  onClose: () => void;
}

interface IIPAllowlistDetailsState {
  ipallowlist?: ApiIPAllowlist;
}

class IPAllowlistDetailsModal extends Component<IIPAllowlistDetailsProps, IIPAllowlistDetailsState> {
  state = {
    ipallowlist: undefined,
  } as IIPAllowlistDetailsState;

  reloadIPAllowlistInfo = async () => {
    const idOptions = { id: this.props.ipallowlistId } as ApiIDOptions;
    const ipallowlist = await apiClients.securityClient.GetIPAllowlist(idOptions);
    this.setState({
      ipallowlist: ipallowlist,
    });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadIPAllowlistInfo);
  }

  render() {
    const loading = !this.state.ipallowlist;
    const ipallowlist = this.state.ipallowlist || {};
    const canEdit =
      !loading &&
      !!this.props.onEdit &&
      this.props.hasPermissionByUrl &&
      this.props.hasPermissionByUrl(ipallowlist.url || "", ResourceType.IPAllowlist, "security.ipallowlist.update");

    return (
      <IPAllowlistDetailsView
        {...this.props}
        {...this.state}
        ipallowlist={ipallowlist}
        onCancel={this.props.onClose}
        onEdit={canEdit ? this.props.onEdit : undefined}
      />
    );
  }
}

export default withRefresh()(IPAllowlistDetailsModal);
