//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  Currency as ApiCurrency,
  DeploymentPrice as ApiDeploymentPrice,
  DeploymentPriceRequest as ApiDeploymentPriceRequest,
  NodeSize as ApiNodeSize,
  Organization as ApiOrganization,
  Project as ApiProject,
} from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { formatRoundedPrice, formatUnroundedPrice } from "../../util/Price";
import PriceModelView from "./PriceModelView";
import ReactTimeout from "react-timeout";

interface IPriceViewArgs extends IWithRefreshProps, RouteComponentProps {
  node_size: ApiNodeSize;
  model: string;
  price?: ApiDeploymentPrice;
  currency: ApiCurrency;
  is_free_tier: boolean;
  supports_auditlog: boolean;
  organization_id: string;
  show_unrounded_prices: boolean;
  is_single: boolean;
  showCreditsPrice?: boolean;
}

const PriceView = ({ ...args }: IPriceViewArgs) => {
  const has_price = !!args.price;
  const price = args.price || {};
  const curSign = args.currency.sign || "";
  const formatPrice = args.show_unrounded_prices ? formatUnroundedPrice : formatRoundedPrice;
  const cpu = args.node_size.cpu_size && args.node_size.memory_size ? args.node_size.memory_size / Number(args.node_size.cpu_size.replace("c", "")) : 0;
  const disk =
    args.node_size.min_disk_size === args.node_size.max_disk_size
      ? `${args.node_size.min_disk_size} GB`
      : `${args.node_size.min_disk_size} - ${args.node_size.max_disk_size} GB`;
  return (
    <Table.Row>
      <Table.Cell>{args.node_size.name}</Table.Cell>
      <Table.Cell>{cpu}</Table.Cell>
      <Table.Cell>{args.node_size.memory_size || 0} GB</Table.Cell>
      <Table.Cell>{disk}</Table.Cell>
      <Table.Cell className="pointer">
        <PriceModelView
          {...args}
          trigger={
            <span className={has_price ? "text-link" : ""}>
              {has_price ? formatPrice(price.price_per_hour || 0, args.showCreditsPrice ? "" : curSign, "hr") : "loading"}
            </span>
          }
          isPrepaidDeployment={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

interface IDeploymentModelPriceProps extends IWithRefreshProps, RouteComponentProps {
  provider_id: string;
  region_id: string;
  node_size: ApiNodeSize;
  node_count: number;
  model: string;
  organization: ApiOrganization;
  project: ApiProject;
  currency: ApiCurrency;
  show_unrounded_prices: boolean;
  showCreditsPrice?: boolean;
}

interface IDeploymentModelPriceState {
  model?: string;
  region_id?: string;
  project_id?: string;
  node_size_id?: string;
  node_count: number;
  price?: ApiDeploymentPrice;
  showCreditsPrice?: boolean;
  refreshNeeded: boolean;
}

class DeploymentModelPrice extends Component<IDeploymentModelPriceProps, IDeploymentModelPriceState> {
  state = {
    model: undefined,
    region_id: undefined,
    project_id: undefined,
    node_size_id: undefined,
    node_count: 3,
    price: undefined,
    refreshNeeded: false,
  } as IDeploymentModelPriceState;

  timer: ReactTimeout.Timer = 0;

  reloadPrice = async () => {
    const preq = {
      organization_id: this.props.organization.id,
      project_id: this.props.project.id,
      cloud_provider_id: this.props.provider_id,
      cloud_region_id: this.props.region_id,
      model: this.props.model,
      node_size_id: this.props.node_size.id,
      node_disk_size: this.props.node_size.min_disk_size,
      node_count: this.props.node_count,
    } as ApiDeploymentPriceRequest;

    if (this.props.showCreditsPrice) {
      preq.use_credit_pricing = true;
    }
    const price = await apiClients.dataClient.CalculateDeploymentPrice(preq);
    // Check if the received data is what we actually need, if not, do not update the price.
    if (this.state.node_count == preq.node_count) {
      this.setState({ price: price });
    }
  };

  refreshPrice = () => {
    // Wait for a split second, so if somebody is dragging the slider we will not get all
    // data directly, however wait until it's hopefully stable.
    if (this.timer != 0) {
      this.props.clearTimeout && this.props.clearTimeout(this.timer);
      this.timer = 0;
    }
    if (this.props.setTimeout) {
      this.timer = this.props.setTimeout(() => {
        this.props.refreshNow && this.props.refreshNow(this.reloadPrice);
      }, 200);
    }
  };

  static getDerivedStateFromProps(props: IDeploymentModelPriceProps, state: IDeploymentModelPriceState) {
    if (
      props.model != state.model ||
      props.region_id != state.region_id ||
      props.project.id != state.project_id ||
      props.node_size.id != state.node_size_id ||
      props.node_count != state.node_count ||
      props.showCreditsPrice != state.showCreditsPrice
    ) {
      return {
        model: props.model,
        region_id: props.region_id,
        project_id: props.project.id,
        node_size_id: props.node_size.id,
        node_count: props.node_count,
        showCreditsPrice: props.showCreditsPrice,
        price: undefined,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshPrice);
    }
  }

  render() {
    const tier = this.props.organization.tier || {};
    const is_free_tier = tier.id == "free";
    const is_single = this.props.model == "developer";
    const supports_auditlog = !!tier.has_auditlog_feature;

    return (
      <PriceView
        {...this.props}
        {...this.state}
        is_free_tier={is_free_tier}
        is_single={is_single}
        supports_auditlog={supports_auditlog && !is_single}
        organization_id={this.props.organization.id || ""}
      />
    );
  }
}

export default withRefresh()(DeploymentModelPrice);
