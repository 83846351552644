//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Grid, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  CPUSize as ApiCPUSize,
  Currency as ApiCurrency,
  ListCPUSizesRequest as ApiListCPUSizesRequest,
  NodeSize as ApiNodeSize,
  NodeSizesRequest as ApiNodeSizesRequest,
  Organization as ApiOrganization,
  Project as ApiProject,
} from "../../api/lib";
import { Provider as ApiProvider, Region as ApiRegion } from "../../api/platform/v1/platform";
import { ModelTitleString, Section, SectionContent, SectionHead, SectionHeader, SliderRow, SliderWithTooltip } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import DeploymentModelPrice from "./DeploymentModelPrice";
import { Feature } from "flagged";

const NodeCountGrid = styled(Grid)`
  margin-bottom: 24px !important;
`;

interface IPricingRowsViewArgs extends IWithRefreshProps, RouteComponentProps {
  model: string;
  show_node_count: boolean;
  organization: ApiOrganization;
  project?: ApiProject;
  projectCount?: number;
  currency: ApiCurrency;
  provider?: ApiProvider;
  region?: ApiRegion;
  node_sizes: ApiNodeSize[];
  cpu_sizes: ApiCPUSize[];
  node_cpu_size: string;
  node_count: number;
  min_node_count: number;
  max_node_count: number;
  show_unrounded_prices: boolean;
  onNodeCountChanged: (value: number) => void;
}

const PricingRowsView = ({ ...args }: IPricingRowsViewArgs) => {
  const provider = args.provider || {};
  const region = args.region || {};
  const project = args.project || {};
  const is_single = args.node_count == 1;

  const showCreditBundlePricing = false; // OAS-9210 part 1

  return (
    <Section>
      <SectionHead>
        <SectionHeader title={`${ModelTitleString(args.model)} Deployments`} />
      </SectionHead>
      <SectionContent>
        {args.show_node_count && (
          <NodeCountGrid>
            <SliderRow>
              <Grid.Column width="3">
                <Form.Field required>
                  <label>Select the number of nodes</label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="2" textAlign="right">
                {args.min_node_count}
              </Grid.Column>
              <Grid.Column width="9">
                <SliderWithTooltip min={args.min_node_count} max={args.max_node_count} value={args.node_count} onChange={args.onNodeCountChanged} />
              </Grid.Column>
              <Grid.Column width="2" textAlign="left">
                {args.max_node_count}
              </Grid.Column>
            </SliderRow>
          </NodeCountGrid>
        )}
        <Table>
          <Table.Header>
            <Table.HeaderCell>Node size</Table.HeaderCell>
            <Table.HeaderCell>{is_single ? "vCPU" : "vCPU/node"}</Table.HeaderCell>
            <Table.HeaderCell>{is_single ? "Memory" : "Memory/node"}</Table.HeaderCell>
            <Table.HeaderCell>{is_single ? "Disk" : "Disk/node"}</Table.HeaderCell>
            <Table.HeaderCell>{showCreditBundlePricing ? "Credits" : "Price"}</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {args.node_sizes.map((x, i) => (
              <DeploymentModelPrice
                {...args}
                showCreditsPrice={showCreditBundlePricing}
                key={`${args.model}${i}`}
                provider_id={provider.id || ""}
                region_id={region.id || ""}
                project={project}
                node_size={x}
              />
            ))}
          </Table.Body>
        </Table>
      </SectionContent>
    </Section>
  );
};

interface IPricingForModelProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  project?: ApiProject;
  currency?: ApiCurrency;
  provider?: ApiProvider;
  region?: ApiRegion;
  show_unrounded_prices: boolean;
  show_node_count: boolean;
  initial_node_count: number;
  node_cpu_size?: string;
  model: string;
}

interface IPricingForModelState {
  node_sizes?: ApiNodeSize[];
  cpu_sizes?: ApiCPUSize[];
  node_count: number;
}

class PricingForModel extends Component<IPricingForModelProps, IPricingForModelState> {
  state = {
    node_sizes: undefined,
    cpu_sizes: undefined,
    node_count: this.props.initial_node_count,
  } as IPricingForModelState;

  reloadCPUAndNodeSizes = async (projectID: string, regionID: string) => {
    // Request CPU sizes
    const cpuReq = {
      project_id: projectID,
    } as ApiListCPUSizesRequest;
    const cpuSizeList = await apiClients.dataClient.ListCPUSizes(cpuReq);
    const allCpuItems = cpuSizeList.items || [];

    // Request node sizes
    const nodeSizeReq = {
      project_id: "all",
      region_id: regionID,
      model: this.props.model,
    } as ApiNodeSizesRequest;
    const nodeSizeList = await apiClients.dataClient.ListNodeSizes(nodeSizeReq);
    const nodeSizeItems = nodeSizeList.items || [];

    // Select only CPU sizes that have at least 1 matching node size
    const availableCpuItems = _.filter(allCpuItems, (x) => !!_.find(nodeSizeItems, (ns) => ns.cpu_size == x.id));

    this.setState({
      cpu_sizes: availableCpuItems,
      node_sizes: nodeSizeItems,
    });
  };

  refreshCPUAndNodeSizes = () => {
    const region = this.props.region || {};
    const project = this.props.project || {};
    const projectID = project.id;
    const regionID = region.id;
    if (!!projectID && !!regionID) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadCPUAndNodeSizes(projectID, regionID));
    } else {
      this.setState({ cpu_sizes: [], node_sizes: [] });
    }
  };

  componentDidMount() {
    this.refreshCPUAndNodeSizes();
  }

  onNodeCountChanged = (value: number) => {
    this.setState({ node_count: value });
  };

  render() {
    const all_node_sizes = this.state.node_sizes || [];
    const cpu_sizes = this.state.cpu_sizes || [];
    const node_sizes = _.filter(all_node_sizes, (x) => x.cpu_size == this.props.node_cpu_size);

    return (
      <Feature name="model.developer">
        <PricingRowsView
          {...this.props}
          {...this.state}
          show_node_count={this.props.model == "sharded"}
          node_sizes={node_sizes}
          cpu_sizes={cpu_sizes}
          node_cpu_size={this.props.node_cpu_size || ""}
          min_node_count={3}
          max_node_count={20}
          currency={this.props.currency || {}}
          onNodeCountChanged={this.onNodeCountChanged}
        />
      </Feature>
    );
  }
}

export default withRefresh()(PricingForModel);
