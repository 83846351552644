//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";

import example_fraud from "./example-fraud.png";

interface IExampleHelpViewArgs {
  onCloseExampleHelp: () => void;
}

export const ExampleHelpView = ({ ...args }: IExampleHelpViewArgs) => (
  <Modal open onClose={args.onCloseExampleHelp}>
    <Modal.Header>Examples</Modal.Header>
    <Modal.Content image>
      <Image src={example_fraud} wrapped />
      <Modal.Description>
        <p className="para">
          ArangoGraph comes with various example datasets and guides how to use those.
          <br />
          There are example datasets to demonstrate various use cases for ArangoDB.
        </p>
        <p className="para">All example can be installed into a running ArangoGraph deployment and can easily be removed once you no longer need it.</p>
        <p className="para">
          To access these examples, create a deployment, click on <b>view</b> to view the deployment and go to the{" "}
          <b>
            <Icon name="lightbulb" style={{ color: "var(--green-700)" }} /> Examples
          </b>{" "}
          tab.
        </p>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={args.onCloseExampleHelp} content="Got it" icon="check" labelPosition="right" positive />
    </Modal.Actions>
  </Modal>
);
