import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { ContentActionButtonNew, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import RoleList from "../role/RoleList";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const OrganizationRolesWrap = () => {
  const { selectedOrganization, topMenuInfo } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const is_org_deleted = !!selectedOrganization?.is_deleted;
  const has_create_role = !!checkOrgPermission("iam.role.create");
  const history = useHistory();
  const onRoleCreate = () => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_roles_createWithId(selectedOrganization?.id));
  };
  const { subscribeUrl, unsubscribe, refreshNow, hasPermissionByUrl } = useWithRefresh();
  const onRoleSelected = (id: string) => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_role_detailsWithId(selectedOrganization.id, id));
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Roles"
          help={
            <div>
              <p className="para">Roles are a list of permissions.</p>
              <p className="para">Roles are assigned to users (or groups) in a Policy.</p>
              <p className="para">
                ArangoGraph comes with a set of predefined roles which are sufficient for most users. Predefined roles cannot be changed or removed.
              </p>
              <p className="para">
                If you want to add your own roles, you can do so using <b>New role</b>.
              </p>
            </div>
          }
        />
        <SectionButtons>{has_create_role && !is_org_deleted && <ContentActionButtonNew primary content="New role" onClick={onRoleCreate} />}</SectionButtons>
      </SectionHead>
      <SectionContent>
        <RoleList
          subscribeUrl={subscribeUrl}
          refreshNow={refreshNow}
          hasPermissionByUrl={hasPermissionByUrl}
          onRoleSelected={onRoleSelected}
          history={history}
          topMenuInfo={topMenuInfo}
          organization={selectedOrganization}
          unsubscribe={unsubscribe}
        />
      </SectionContent>
    </Section>
  );
};
