//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
// Ewout Prangsma
//

import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { AuditLogEvent as ApiAuditLogEvent, AuditLogTopic as ApiAuditLogTopic, GetAuditLogEventsRequest as ApiGetAuditLogEventsRequest } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { DateFormat, ErrorMessage, ParseDate } from "../../ui/lib";
import { EventFilterView } from "./AuditLogEventFilter";

interface IHeaderView {}

const HeaderView = ({ ...args }: IHeaderView) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Timestamp</Table.HeaderCell>
        <Table.HeaderCell>Topic</Table.HeaderCell>
        <Table.HeaderCell>Verb</Table.HeaderCell>
        <Table.HeaderCell>Who</Table.HeaderCell>
        <Table.HeaderCell>Subject</Table.HeaderCell>
        <Table.HeaderCell>Message</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

interface IRowViewArgs {
  item: ApiAuditLogEvent;
}

const RowView = ({ ...args }: IRowViewArgs) => {
  const item = args.item;
  let subject = [] as string[];
  if (!!item.project_id) {
    subject.push(`project ${item.project_id}`);
  }
  if (!!item.deployment_id) {
    subject.push(`deployment ${item.deployment_id}`);
  }
  if (!!item.server_id) {
    subject.push(`server ${item.server_id}`);
  }
  if (!!item.instance_id) {
    subject.push(`instance ${item.instance_id}`);
  }
  if (!!item.database) {
    subject.push(`database ${item.database}`);
  }

  const timestamp = item.timestamp && moment(item.timestamp);
  const hasValidTimestamp = !!timestamp && timestamp.isValid();
  const absTimestamp = hasValidTimestamp && !!timestamp ? timestamp.format(DateFormat) : "?";

  return (
    <Table.Row>
      <Table.Cell>
        {hasValidTimestamp && absTimestamp}
        {!hasValidTimestamp && "n/a"}
      </Table.Cell>
      <Table.Cell>{item.topic || "-"}</Table.Cell>
      <Table.Cell>{item.verb || "-"}</Table.Cell>
      <Table.Cell>{item.user_id || "-"}</Table.Cell>
      <Table.Cell>{subject.join(", ")}</Table.Cell>
      <Table.Cell>{item.message || "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons"></div>
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  items: ApiAuditLogEvent[];
  page: number;
}

const ListView = ({ ...args }: IListView) => {
  return (
    <Table striped>
      <HeaderView {...args} />
      <Table.Body>
        {args.items.map((item, i) => (
          <RowView {...args} key={`row${i}_${args.page}`} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No events in this audit log archive that match the selected filter.</div>;

export interface IAuditLogEventListViewArgs {
  events?: ApiAuditLogEvent[];
  page: number;
}

export const AuditLogEventListView = ({ ...args }: IAuditLogEventListViewArgs) => {
  const items = args.events || [];
  if (_.isEmpty(items)) {
    return <EmptyView />;
  }
  return <ListView {...args} items={items} />;
};

interface IAuditLogEventListProps {
  auditLogId: string;
  auditLogArchiveId: string;
  all_topics: ApiAuditLogTopic[];
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
}

interface IAuditLogEventListState {
  loading: boolean;
  events?: ApiAuditLogEvent[];
  errorMessage?: string;
  prevAuditLogId?: string;
  prevAuditLogArchiveId?: string;
  refreshNeeded: boolean;
  from: string;
  to: string;
  // Cursor used for current page
  current_page_cursor: string;
  // Cursor used for next page (if any)
  next_page_cursor?: string;
  // List of cursors for previous pages ([page1_cursor...pageN_cursor])
  cursor_stack: string[];
  pageSize: number;
  request: number;
  excluded_topics: string[];
}

// The component to show the audit logs inside an organization as a list.
class AuditLogEventList extends Component<IAuditLogEventListProps, IAuditLogEventListState> {
  state = {
    loading: false,
    events: undefined,
    errorMessage: undefined,
    prevAuditLogArchiveId: undefined,
    refreshNeeded: false,
    from: "now-1h",
    to: "now",
    current_page_cursor: "",
    next_page_cursor: undefined,
    cursor_stack: [],
    pageSize: 10,
    request: 1,
    excluded_topics: [],
    include_topics: false,
  } as IAuditLogEventListState;

  static getDerivedStateFromProps(props: IAuditLogEventListProps, state: IAuditLogEventListState) {
    if (props.auditLogId != state.prevAuditLogId || props.auditLogArchiveId != state.prevAuditLogArchiveId) {
      return {
        prevAuditLogId: props.auditLogId,
        prevAuditLogArchiveId: props.auditLogArchiveId,
        refreshNeeded: true,
        current_page_cursor: "",
        next_page_cursor: undefined,
        cursor_stack: [],
      };
    }
    return {};
  }

  componentDidMount() {
    this.refreshEvents();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshEvents);
    }
  }

  reloadEvents = async () => {
    const request = this.state.request + 1;
    const cursor = this.state.current_page_cursor;
    this.setState({ loading: true, request: request }, async () => {
      try {
        const from = ParseDate(this.state.from);
        const to = ParseDate(this.state.to);
        const req = {
          auditlog_id: this.props.auditLogId,
          auditlogarchive_id: this.props.auditLogArchiveId,
          limit: this.state.pageSize,
          from: from,
          to: to,
          excluded_topics: this.state.excluded_topics,
          cursor: cursor,
        } as ApiGetAuditLogEventsRequest;
        let list = [] as ApiAuditLogEvent[];
        let lastCursor: string | undefined = undefined;
        const stream = await apiClients.auditClient.GetAuditLogEvents(req, (evt) => {
          if (!!evt.error) {
            console.log(evt.error);
          } else if (!!evt.message) {
            lastCursor = evt.message.cursor;
            const items = evt.message.items || [];
            list = _.concat(list, items);
          }
        });
        await stream.closed;
        if (list.length < this.state.pageSize) {
          lastCursor = undefined;
        }
        this.setState({ next_page_cursor: lastCursor, events: list });
      } catch (e) {
        reportError(e);
      } finally {
        this.setState((old) => {
          return old.request == request ? { loading: false } : { loading: old.loading };
        });
      }
    });
  };

  refreshEvents = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadEvents);
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onTimeRangeChange = (from: string, to: string) => {
    this.setState({ from: from, to: to }, this.refreshEvents);
  };
  onExcludedTopicsChanged = (value: string[]) => {
    this.setState({ excluded_topics: value }, this.refreshEvents);
  };

  onNextPage = () => {
    this.setState((old) => {
      const has_next_cursor = !!old.next_page_cursor;
      const current_page_cursor = old.next_page_cursor || old.current_page_cursor;
      const cursor_stack = has_next_cursor ? _.concat(old.cursor_stack, old.current_page_cursor) : old.cursor_stack;
      return { current_page_cursor: current_page_cursor, cursor_stack: cursor_stack };
    }, this.refreshEvents);
  };
  onPreviousPage = () => {
    this.setState((old) => {
      const has_prev_cursor = !_.isEmpty(old.cursor_stack);
      const current_page_cursor = has_prev_cursor ? old.cursor_stack[old.cursor_stack.length - 1] : old.current_page_cursor;
      const cursor_stack = has_prev_cursor ? _.take(old.cursor_stack, old.cursor_stack.length - 1) : old.cursor_stack;
      return { current_page_cursor: current_page_cursor, next_page_cursor: undefined, cursor_stack: cursor_stack };
    }, this.refreshEvents);
  };

  render() {
    const page = this.state.cursor_stack.length;
    const has_next_cursor = !!this.state.next_page_cursor;

    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
        <EventFilterView {...this.props} {...this.state} {...this} page={page} hasNext={has_next_cursor} onRefresh={this.refreshEvents} />
        <AuditLogEventListView {...this.props} {...this.state} {...this} page={page} />
      </div>
    );
  }
}

export default AuditLogEventList;
