//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Loader, Popup } from "semantic-ui-react";

interface IChildren {
  children?: any;
}

export const Section = ({ ...args }: IChildren) => <section className="section">{args.children}</section>;

export const SectionHead = ({ ...args }: IChildren) => <div className="section__head">{args.children}</div>;

interface ISectionHeader extends IChildren {
  title: string | React.ReactNode;
  help?: string | JSX.Element;
  triggerText?: string;
  loading?: boolean;
  onActionTextClick?: () => void;
  actionableText?: string | JSX.Element;
  testID?: string;
}

export const SectionHeader = ({ ...args }: ISectionHeader) => (
  <header className="section__header" data-testid={args.testID}>
    <div className="section__title">{args.title}</div>
    {!!args.loading && <Loader active inline="centered" size="tiny" />}
    {args.children}
    {!!args.actionableText && (
      <SectionAddons>
        <span className="text-link what-is-this pointer" onClick={args.onActionTextClick} data-testid={`${args.testID}ActionText`}>
          {args.actionableText}
        </span>
      </SectionAddons>
    )}
    {!!args.help && (
      <SectionAddons>
        <Popup
          on="click"
          size="large"
          trigger={
            <span className="text-link what-is-this" data-testid={`${args.testID}Help`}>
              {args.triggerText || "What is this?"}
            </span>
          }
          position="top right"
          content={args.help}
          data-testid={`${args.testID}HelpPopup`}
        />
      </SectionAddons>
    )}
  </header>
);

interface ISectionContent extends IChildren {
  topAttached?: boolean;
}

export const SectionContent = ({ ...args }: ISectionContent) => {
  let className = "section__content";
  if (args.topAttached) {
    className += " top-attached";
  }
  return <section className={className}>{args.children}</section>;
};

export const SectionAddons = ({ ...args }: IChildren) => (
  <ol className="section__addons">
    <li>{args.children}</li>
  </ol>
);

export const SectionButtons = ({ ...args }: IChildren) => <div className="section__buttons">{args.children}</div>;
