//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import AuditLogNotSupportedTab from "./AuditLogNotSupportedTab";
import DeploymentAuditLogTab, { IDeploymentAuditLogTabProps } from "./DeploymentAuditLogTab";

export const DeploymentAuditLogTabWrap = (props: { enableAuditLogWithList: boolean; enableAuditLogWithoutList: boolean } & IDeploymentAuditLogTabProps) => {
  const { enableAuditLogWithList, enableAuditLogWithoutList } = props;
  if (enableAuditLogWithList) {
    return <DeploymentAuditLogTab {...props} />;
  }
  if (enableAuditLogWithoutList) {
    return <AuditLogNotSupportedTab {...props} />;
  }
  return null;
};
