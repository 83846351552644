//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { ReactNode, useContext } from "react";
import { PapaparseContext } from "./FileParserContext";
import { useFileParser } from "../hooks/useFileParser";

export const PapaparseProvider = ({ children }: { children: ReactNode }) => {
  return <PapaparseContext.Provider value={{ ...useFileParser() }}>{children}</PapaparseContext.Provider>;
};

export const useFileParsingHandlers = () => {
  const { isFileBeingParsed, previewFileData, previewFileObject, queueFileToPreview } = useContext(PapaparseContext);
  return { isFileBeingParsed, previewFileData, previewFileObject, queueFileToPreview };
};
