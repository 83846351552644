//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { AWSPrincipal, IPrincipalsSummaryModalViewArgs } from "../types";
import { Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import { ErrorMessage } from "../../../ui/_errorMessage";
import PrincipalsView from "./PrincipalsView";

const PrincipalsSummaryModalView = ({ onClose, onSave, awsPrincipals, disableChange, statusMessage }: IPrincipalsSummaryModalViewArgs) => {
  const [principals, updatePrincipals] = useState<AWSPrincipal[]>(awsPrincipals);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(undefined);

  const handleChange = (updatedPrincipals: AWSPrincipal[]): void => {
    updatePrincipals(updatedPrincipals);
  };

  const handleSave = async () => {
    setLoading(true);
    const { error } = (await onSave(principals)) || {};
    setLoading(false);
    !!error && setError(error);
    if (!error && !loading) onClose();
  };

  return (
    <Modal open onClose={onClose} data-testid="principalsSummaryModal">
      <Modal.Header data-testid="principalsSummaryModalHeader">AWS Principals</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} testID="principalsSummaryModalErrorMessage" />
          <PrincipalsView principals={awsPrincipals} onChange={handleChange} disableChange={disableChange} statusMessage={statusMessage} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} testID="principalsSummaryModalCancelButton" />
        <FormActionButtonSave primary disabled={disableChange} loading={loading} onClick={handleSave} icon="save" testID="principalsSummaryModalSaveButton" />
      </Modal.Actions>
    </Modal>
  );
};

export default PrincipalsSummaryModalView;
