//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Header, Icon, Message, Modal } from "semantic-ui-react";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import { AppLinkButton } from "../../../ui/_link";
import { GCP_CONFIGURE_ENDPOINT, GCP_WHAT_IS_PRIVATE_LINK } from "../privateNetworkURLs";
import { IPrivateNetworkHelpView } from "../types";

const GCPPrivateNetworkHelpView = ({ onClose, onSupportClick }: IPrivateNetworkHelpView) => {
  return (
    <Modal open data-testid="gcpprivateNetworkHelpModal">
      <Modal.Header data-testid="gcpprivateNetworkHelpModalHeader">Here's what you can refer</Modal.Header>
      <Modal.Content>
        <Modal.Description data-testid="gcpprivateNetworkHelpModalDescription">
          <Message>
            This deployment has been configured with a Private endpoint. That means that all traffic to and from your deployment go through the private
            endpoint.
          </Message>
          <Header as="h3">To understand more, refer the following links:</Header>
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="About GCP Private endpoint" href={GCP_WHAT_IS_PRIVATE_LINK} target="_blank" />
          <Divider hidden />
          <Icon name="linkify" />
          <AppLinkButton label="Configure endpoint" href={GCP_CONFIGURE_ENDPOINT} target="_blank" />
          <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel title="Close" onClick={onClose} testID="gcpprivateNetworkHelpModalClose" />
        <FormActionButtonSave
          testID="gcpprivateNetworkHelpModalSupportButton"
          primary
          icon="help"
          title="Request Help"
          onClick={() => {
            onClose();
            onSupportClick && onSupportClick();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default GCPPrivateNetworkHelpView;
