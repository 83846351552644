//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchDiskPerformance = ({
  modelId,
  regionId,
  nodeDiskSizeId,
  nodeDiskSize,
}: {
  modelId: string;
  regionId: string;
  nodeDiskSizeId: string;
  nodeDiskSize: number;
}) => {
  const { selectedOrganization } = useDashboardContext();
  const getDiskPerformanceList = async () => {
    const req = {
      region_id: regionId,
      node_size_id: nodeDiskSizeId,
      dbserver_disk_size: nodeDiskSize,
      organization_id: selectedOrganization.id,
    };
    return await apiClients.dataClient.ListDiskPerformances(req);
  };
  return useQuery([modelId, regionId, nodeDiskSizeId, nodeDiskSize, "diskPerformance"], getDiskPerformanceList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!regionId && !!nodeDiskSizeId && !!nodeDiskSize,
  });
};
