//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useRouteMatch } from "react-router-dom";
import { useFetchDeployment } from "./useFetchDeployment";

export const useFetchDeploymentForEdit = () => {
  const match = useRouteMatch<{ deploymentId: string; projectId: string }>();
  const { deploymentId } = match.params;
  const queryResult = useFetchDeployment({ deploymentId });
  return { ...queryResult, isPrepaid: !!queryResult.data?.prepaid_deployment_id, isExisting: !!queryResult.data?.id };
};
