//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertDescription, AlertIcon, Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { Provider } from "../../api/lib";
import { ProviderIconView } from "../deployment/ProviderIcon";
import { useFetchProviders } from "./useFetchProviders";
export const ProviderSelector = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { data: providers } = useFetchProviders();

  const [field, , helpers] = useField("providerId");
  if (!providers?.items || providers?.items.length === 0) {
    return (
      <FormControl isRequired>
        <FormLabel>Provider</FormLabel>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <AlertDescription> No providers available.</AlertDescription>
        </Alert>
      </FormControl>
    );
  }
  return (
    <Flex gap="2" direction="column">
      <FormControl isRequired isDisabled={isDisabled}>
        <FormLabel>Provider</FormLabel>
        <Flex gap="2">
          {providers?.items?.map((provider) => (
            <ProviderChip
              isDisabled={isDisabled}
              isSelected={provider.id === field.value}
              key={provider.id}
              provider={provider}
              onClick={() => helpers.setValue(provider.id)}
            />
          ))}
        </Flex>
      </FormControl>
    </Flex>
  );
};

const ProviderChip = ({
  provider,
  isSelected,
  isDisabled,
  onClick,
}: {
  provider: Provider;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}) => {
  const backgroundColor = isSelected ? "blue.50" : "gray.50";
  const borderColor = isSelected ? "blue.500" : "gray.200";
  const color = isSelected ? "blue.600" : "gray.900";
  let hoverBackgroundColor = "gray.100";
  if (isDisabled) {
    hoverBackgroundColor = backgroundColor;
  }
  if (isSelected) {
    hoverBackgroundColor = "blue.100";
    if (isDisabled) {
      hoverBackgroundColor = "blue.50";
    }
  }
  const cursor = isDisabled ? "not-allowed" : "pointer";

  return (
    <Box
      padding="2"
      borderRadius="md"
      border="1px solid"
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      color={color}
      onClick={() => !isDisabled && onClick()}
      cursor={cursor}
      _hover={{
        backgroundColor: hoverBackgroundColor,
      }}
      width="24"
      height="10"
      title={provider.name}
    >
      <ProviderIconView providerId={provider.id} />
    </Box>
  );
};
