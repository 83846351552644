//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { find, isEmpty, split } from "lodash";
import { BillingConfig as ApiBillingConfig } from "./billing/v1/billing";

// IsBillingConfigValid returns true if the given config
// has a country and address.
export const IsBillingConfigValid = (cfg: ApiBillingConfig) => {
  const address = cfg.address || {};
  const address_lines = address.address || [];
  const has_country = !!address.country_code;
  const has_address = !!find(address_lines, (x) => !isEmpty(x));
  return has_country && has_address;
};

export const textToStrings = (value: string): string[] => {
  return isEmpty(value) ? [] : split(value, "\n");
};

export const pluralize = (word: string, count: number): string => {
  return count <= 1 ? word : word + "s";
};
