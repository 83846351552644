//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Icon, Loader, SemanticICONS, Form } from "semantic-ui-react";
import { Region as ApiRegion, Provider as ApiProvider } from "../../api/platform/v1/platform";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { LoaderBox, DeploymentLogo } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ProviderIconView } from "./ProviderIcon";
import { Cached as apiClients } from "../../api/apiclients";
import { createProviderName } from "./SelectProvider";
import { createRegionName } from "./SelectRegion";
interface ILocationViewArgs extends ILocationState {
  showProvider: boolean;
  showProviderIcon?: boolean;
  showRegion: boolean;
  iconName?: SemanticICONS;
  className?: string;
}

const LocationView = ({ ...args }: ILocationViewArgs) => (
  <div className={args.className}>
    {args.showProviderIcon && args.provider && (
      <DeploymentLogo className="logo">
        <ProviderIconView providerId={args.provider.id} />
      </DeploymentLogo>
    )}
    {args.iconName ? <Icon name={args.iconName} /> : undefined}
    {args.showProvider ? (
      args.provider ? (
        createProviderName(args.provider)
      ) : (
        <LoaderBox>
          <Loader size="mini" active={true} inline />
        </LoaderBox>
      )
    ) : undefined}
    {args.showProvider && args.showRegion ? "-" : undefined}
    {args.showRegion ? (
      args.region ? (
        createRegionName(args.region)
      ) : (
        <LoaderBox>
          <Loader size="mini" active={true} inline />
        </LoaderBox>
      )
    ) : undefined}
  </div>
);

interface ILocationFormViewArgs extends ILocationState {
  showProvider: boolean;
  showRegion: boolean;
  autoFocus?: boolean;
}

const LocationFormView = ({ ...args }: ILocationFormViewArgs) => {
  if (args.showProvider && args.provider) {
    return <Form.Input autoFocus={!!args.autoFocus} required readOnly label="Provider" value={args.provider.name} />;
  }
  if (args.showRegion && args.region) {
    return <Form.Input autoFocus={!!args.autoFocus} required readOnly label="Region" value={args.region.location} />;
  }

  return (
    <LoaderBox>
      <Loader size="mini" active={true} inline />
    </LoaderBox>
  );
};

// Interface decribing the properties of the location component
interface ILocationProps extends IWithRefreshProps {
  regionId?: string;
  showProviderIcon?: boolean;
  showProvider: boolean;
  showRegion: boolean;
  iconName?: SemanticICONS;
  className?: string;
  asForm?: boolean;
  autoFocus?: boolean;
}

// Interface decribing the state of the location component
interface ILocationState {
  region?: ApiRegion;
  provider?: ApiProvider;
}

class Location extends Component<ILocationProps, ILocationState> {
  state = {
    region: undefined,
    provider: undefined,
  } as ILocationState;

  reloadProviderInfo = async () => {
    const region = this.state.region as ApiRegion | undefined;
    if (region) {
      const idOptions = { id: region.provider_id } as ApiIDOptions;
      const provider = await apiClients.platformClient.GetProvider(idOptions);
      this.setState({ provider: provider });
    }
  };

  reloadRegionInfo = async () => {
    const idOptions = { id: this.props.regionId } as ApiIDOptions;
    const region = await apiClients.platformClient.GetRegion(idOptions);
    this.setState({ region: region }, () => {
      this.props.refreshNow && this.props.refreshNow(this.reloadProviderInfo);
    });
  };

  componentDidMount() {
    if (this.props.regionId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadRegionInfo);
    }
  }

  render() {
    if (!this.props.regionId) {
      return <div>Unknown</div>;
    }

    if (this.props.asForm) {
      return <LocationFormView {...this.props} {...this.state} />;
    }

    return <LocationView {...this.props} {...this.state} />;
  }
}

export default withRefresh()(Location);
