//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { ContentSegment } from "../../ui/lib";
import { IDashboardHomeProps, IDashboardHomeState } from "./DashboardHome.types";
import { DashboardDeploymentList } from "./deploymentList/DashboardDeploymentList";
import { FreeDeploymentAdView } from "./FreeDeploymentAd";
import { NewDeploymentButton } from "../newDeployment/NewDeploymentButton";

// Interface describing the dashboard home view arguments
interface IDashboardHomeViewArgs extends IDashboardHomeProps, IDashboardHomeState {}
export const DashboardDeploymentsView = ({ organization, deployments }: IDashboardHomeViewArgs) => {
  return (
    <ContentSegment>
      <NewDeploymentButton />
      <FreeDeploymentAdView organization={organization} deployments={deployments} />
      <DashboardDeploymentList />
    </ContentSegment>
  );
};
