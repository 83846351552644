//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { textToStrings } from "../../../api/lib";
import { ErrorMessage } from "../../../ui/lib";
import { FormActionButtonCancel, FormActionButtonSave } from "../../../ui/_buttons";
import PrincipalAdditionFormView from "./PrincipalAdditionFormView";
import { isPrincipalAccountIDValid } from "./PrincipalsView";
import { IPrincipalsAdditionModalView } from "../types";

const PrincipalsAdditionFormModalView = ({ isEditMode, onCancel, onAdd, onSave, principalToEdit }: IPrincipalsAdditionModalView) => {
  const [accountId, updateAccountId] = useState<string>("");
  const [usernames, updateUsernames] = useState<string>("");
  const [roleNames, updateRoleNames] = useState<string>("");
  const [errorMessage, updateErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (principalToEdit) {
      updateAccountId(principalToEdit.account_id);
      updateUsernames(principalToEdit.user_names ? principalToEdit.user_names.join("\n") : "");
      updateRoleNames(principalToEdit.role_names ? principalToEdit.role_names.join("\n") : "");
    }

    return () => {
      reset();
    };
  }, [principalToEdit]);

  const reset = () => {
    updateAccountId("");
    updateUsernames("");
    updateRoleNames("");
    updateErrorMessage(undefined);
  };

  const isPrincipalValid = () => {
    if (accountId === "") {
      updateErrorMessage("Account ID cannot be empty");
      return false;
    }
    if (!isPrincipalAccountIDValid(accountId)) {
      updateErrorMessage("Account ID is invalid");
      return false;
    }
    return true;
  };

  const onSavePrincipal = () => {
    if (!isPrincipalValid()) {
      return;
    }

    onSave && onSave({ account_id: accountId, user_names: textToStrings(usernames), role_names: textToStrings(roleNames) });
    reset();
  };

  const onAddPrincipal = () => {
    if (!isPrincipalValid()) {
      return;
    }

    onAdd && onAdd({ account_id: accountId, user_names: textToStrings(usernames), role_names: textToStrings(roleNames) });
    reset();
  };

  return (
    <Modal open>
      <Modal.Header data-testid="principalAdditionModalHeader">Add a principal</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!errorMessage} onDismiss={() => updateErrorMessage("")} message={errorMessage} testID="principalAdditionModalErrorMessage" />
          <PrincipalAdditionFormView
            onAccountIDChange={updateAccountId}
            onUsernamesChange={updateUsernames}
            onRoleNamesChange={updateRoleNames}
            accountId={accountId}
            usernames={usernames}
            roleNames={roleNames}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel
          title="Cancel"
          onClick={() => {
            onCancel && onCancel();
            reset();
          }}
          testID="principalAdditionModalCancelButton"
        />
        {isEditMode ? (
          <FormActionButtonSave icon="save" primary title={"Save"} onClick={onSavePrincipal} disabled={!accountId} testID="principalAdditionModalSaveButton" />
        ) : (
          <FormActionButtonSave icon="plus" primary title="Add" onClick={onAddPrincipal} disabled={!accountId} testID="principalAdditionModalAddButton" />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default PrincipalsAdditionFormModalView;
