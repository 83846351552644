//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import { useDataloaderStore } from "../../DataloaderStore";
import { useGraphNodeHandlers } from "../../graph-modeller/GraphProvider";
import { FileObject } from "../../types";
import { useFileAndNodeModifiers } from "../hooks/useFileAndNodeModifiers";

export const NodeFileDataSelector = () => {
  const { getNode } = useGraphNodeHandlers();
  const {
    assignFileToNodeInGlobalStore,
    assignPrimaryKeyToNodeInGlobalStore,
    getPrimaryKeyAssignedToNodeFromGlobalStore,
    getAssignedFileToNodeFromGlobalStore,
  } = useFileAndNodeModifiers();

  const node = getNode() || {};

  const { files } = useDataloaderStore().getDeploymentDataloaderState() || [];

  const [selectedFile, setSelectedFileInLocalState] = useState<FileObject | undefined>();
  const [primaryKey, setPrimarykeyInLocalState] = useState("");

  useEffect(() => {
    if (node && node.id) {
      setSelectedFileInLocalState(getAssignedFileToNodeFromGlobalStore());
      setPrimarykeyInLocalState(getPrimaryKeyAssignedToNodeFromGlobalStore() || "");
      return;
    }
  }, [node]);

  const handleFileSelection = (file: FileObject) => {
    setSelectedFileInLocalState(file);
    assignFileToNodeInGlobalStore(file);
  };

  const handlePrimaryKeySelection = (value: string) => {
    setPrimarykeyInLocalState(String(value));
    assignPrimaryKeyToNodeInGlobalStore(String(value));
  };

  return (
    <>
      <Form.Field>
        <label htmlFor="fileSelector">File</label>
        <Form.Dropdown
          fluid
          value={(selectedFile || {}).id || ""}
          selection
          placeholder="Select a file"
          name="fileSelector"
          search
          noResultsMessage="No files found"
          onChange={(_: unknown, data: DropdownProps) => {
            const { value } = data;
            handleFileSelection(files.filter((file) => file.id === String(value))[0]);
          }}
          options={files.map((file) => ({
            key: file.id,
            text: file.name,
            value: file.id,
          }))}
        />
      </Form.Field>

      <Form.Field>
        <label htmlFor="idSelector">Primary Identifier</label>
        <Form.Dropdown
          fluid
          selection
          value={primaryKey}
          placeholder="Select primary identifier"
          name="idSelector"
          search
          className="mask-data"
          noResultsMessage="No fields found"
          onChange={(_: unknown, data: DropdownProps) => {
            const { value } = data;
            handlePrimaryKeySelection(String(value));
          }}
          options={
            selectedFile
              ? Object.keys(selectedFile.fields).map((key) => {
                  return {
                    key,
                    value: key,
                    text: key,
                  };
                })
              : []
          }
        />
      </Form.Field>
    </>
  );
};
