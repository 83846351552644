//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader, Table } from "semantic-ui-react";
import {
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  IAMProvider as ApiIAMProvider,
  IAMProviderList as ApiIAMProviderList,
  Project as ApiProject,
} from "../../api/lib";
import { Routes } from "../../routes";
import {
  Confirm,
  ConfirmInfo,
  ListActionDelete,
  ListActionEdit,
  ListActionView,
  ListActionSetDefault,
  LoaderBoxForTable as LoaderBox,
  Loading,
  Processing,
  TextLink,
} from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import { HistoryHelper } from "../HistoryHelper";
import { ResourceType, Permission } from "../../util/PermissionCache";
import { reportError } from "../../errors/reporting";
import apiClients from "../../api/apiclients";
import { useWithRefresh } from "../../util/WithRefreshContext";

interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Default</Table.HeaderCell>
      <Table.HeaderCell>Deleted</Table.HeaderCell>
      <Table.HeaderCell>
        Actions
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a ca certificate
interface IRowView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  item: ApiIAMProvider;
  onClickView: () => void;
  onClickEdit: () => void;
  onClickSetDefault: () => void;
  onClickDelete: () => void;
}

const RowView = ({ ...args }: IRowView) => {
  const hasPermission = (p: Permission) => !!(args.hasPermissionByUrl && args.hasPermissionByUrl(args.item.url || "", ResourceType.IAMProvider, p));
  const has_get = hasPermission("security.iamprovider.get");
  const has_update = hasPermission("security.iamprovider.update");
  const has_set_default = hasPermission("security.iamprovider.set-default");
  const has_delete = hasPermission("security.iamprovider.delete");

  return (
    <Table.Row>
      <Table.Cell>
        <TextLink
          label={args.item.name}
          onClick={args.onClickView}
          disabled={!args.hasPermissionByUrl || !args.hasPermissionByUrl(args.item.url || "", ResourceType.CACertificate, "crypto.cacertificate.get")}
        />
      </Table.Cell>
      <Table.Cell>{args.item.description}</Table.Cell>
      <Table.Cell>{args.item.type}</Table.Cell>
      <Table.Cell>{moment(args.item.created_at).fromNow()}</Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>{args.item.is_deleted ? moment(args.item.deleted_at).fromNow() : "-"}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <div className="table-action-buttons">
          {has_get && <ListActionView onClick={args.onClickView} />}
          {has_update && !args.item.is_deleted && <ListActionEdit disabled={!args.active} onClick={args.onClickEdit} />}
          {has_set_default && !args.item.is_default && <ListActionSetDefault disabled={!args.active} onClick={args.onClickSetDefault} />}
          {has_delete && !args.item.is_deleted && <ListActionDelete disabled={!args.active} onClick={args.onClickDelete} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

interface IListView {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  items: ApiIAMProvider[];
  loading: boolean;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
}

const ListView = ({ ...args }: IListView) => {
  const { registerActivePermissionUrls } = useWithRefresh();
  React.useEffect(() => {
    const urls = args.items.map((item) => item.url || "");
    registerActivePermissionUrls?.(urls);
  }, [args.items]);
  return (
    <Table striped>
      <HeaderView loading={args.loading} />
      <Table.Body>
        {args.items.map((item) => (
          <RowView
            {...args}
            key={item.id}
            item={item}
            onClickView={() => args.onClickView(item.id || "")}
            onClickDelete={() => args.onClickDelete(item.id || "")}
            onClickSetDefault={() => args.onClickSetDefault(item.id || "")}
            onClickEdit={() => args.onClickEdit(item.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const EmptyView = () => <div>No IAM providers inside this project</div>;

export interface IIAMProviderListViewArgs extends RouteComponentProps {
  hasPermissionByUrl?: (url: string, type: ResourceType, permission: Permission) => boolean;
  active: boolean;
  loading: boolean;
  project: ApiProject;
  iamproviders?: ApiIAMProviderList;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickSetDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
}

export const IAMProviderListView = ({ ...args }: IIAMProviderListViewArgs) => {
  if (!args.iamproviders) {
    return <Loading />;
  }
  if (!args.iamproviders.items || args.iamproviders.items.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} items={args.iamproviders.items} loading={args.loading} />;
};

interface IIAMProviderListProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  iamproviders?: ApiIAMProviderList;
  onError: (message?: string) => void;
  onIAMProviderSelected: (id: string) => void;
  onIAMProviderDeleted: (id: string) => void;
  onIAMProviderDefaultChanged: (id: string) => void;
}

interface IIAMProviderListState {
  processing: boolean;
  confirmInfo?: ConfirmInfo;
}

// The component to show the IAM provider inside a project as a list.
class IAMProviderList extends Component<IIAMProviderListProps, IIAMProviderListState> {
  state = {
    processing: false,
    confirmInfo: undefined,
  } as IIAMProviderListState;

  onClickView = (id: string) => {
    this.props.onIAMProviderSelected(id);
  };

  onClickEdit = (id: string) => {
    HistoryHelper.push(this.props.history, Routes.dashboard_project_iamprovider_editWithId(this.props.project.id || "", id), this.props.topMenuInfo.getTitle());
  };

  getIAMProviderName = (id: string) => {
    const iamproviders = this.props.iamproviders;
    if (iamproviders && iamproviders.items) {
      const iamprovider = iamproviders.items.find((c) => c.id == id);
      if (iamprovider) {
        return iamprovider.name;
      }
    }
    return "";
  };

  onClickDelete = async (id: string) => {
    const name = this.getIAMProviderName(id);
    const confirmInfo = {
      header: "Delete IAM provider",
      content: `Are you sure you want to delete IAM provider '${name}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(id),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async (id: string) => {
    try {
      this.props.onError(undefined);
      this.setState({ processing: true, confirmInfo: undefined });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.securityClient.DeleteIAMProvider(idOptions);
      this.props.onIAMProviderDeleted(id);
    } catch (e) {
      this.props.onError(`IAM provider deletion failed: ${e}`);
      reportError(e);
    }
    this.setState({ processing: false });
  };

  onClickSetDefault = async (id: string) => {
    const iamproviders = this.props.iamproviders;
    if (iamproviders && iamproviders.items) {
      const iamprovider = iamproviders.items.find((c) => c.id == id);
      if (iamprovider) {
        try {
          this.props.onError(undefined);
          this.setState({ processing: true, confirmInfo: undefined });
          await apiClients.securityClient.SetDefaultIAMProvider(iamprovider);
        } catch (e) {
          this.props.onError(`Marking IAM provider as default failed: ${e}`);
          reportError(e);
        }
        this.setState({ processing: false });
      }
    }
  };

  render() {
    return (
      <div>
        <Confirm confirmInfo={this.state.confirmInfo} />
        <Processing active={this.state.processing} message="Deleting IAM provider, please wait..." />
        <IAMProviderListView
          {...this.props}
          {...this.state}
          iamproviders={this.props.iamproviders}
          active={!this.state.processing}
          onClickView={this.onClickView}
          onClickEdit={this.onClickEdit}
          onClickSetDefault={this.onClickSetDefault}
          onClickDelete={this.onClickDelete}
        />
      </div>
    );
  }
}

export default withRefresh()(IAMProviderList);
