//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";

interface IPageNotFoundArgs {}

// Component used when a page cannot be found (no route exist)
export const PageNotFound = ({ ...args }: IPageNotFoundArgs) => <p className="para">Page not found</p>;
