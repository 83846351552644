//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { SyntheticEvent, useState, useEffect } from "react";
import { Form, DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { Cached as apiClients } from "../../api/apiclients";
import { Provider as ApiProvider, ProviderList as ApiProviderList, ListProvidersRequest as ApiListProvidersRequest } from "../../api/platform/v1/platform";

interface ISelectProviderArgs {
  organizationId: string;
  selectedProvider?: ApiProvider;
  onProviderUpdated?: (provider?: ApiProvider | any) => void;
}

const SelectProvider2 = (args: ISelectProviderArgs) => {
  const [providers, setProviders] = useState<ApiProviderList | undefined>();
  const [selectedProvider, setSelectedProvider] = useState<ApiProvider | undefined>(args.selectedProvider);

  const onChange = (e: SyntheticEvent, props: DropdownProps) => {
    if (providers && providers.items) {
      const filteredProviders: ApiProvider[] = providers.items.filter((item) => item.id == props.value);
      if (filteredProviders.length == 1) {
        setSelectedProvider(filteredProviders[0]);
        if (args.onProviderUpdated) args.onProviderUpdated(filteredProviders[0]);
      }
    }
  };

  const populateProviderList = async () => {
    const listReq = { organization_id: args.organizationId } as ApiListProvidersRequest;
    const providers = await apiClients.platformClient.ListProviders(listReq);
    if (providers && providers.items) {
      providers.items = providers.items.filter((p) => p.id !== "aks");
      setProviders(providers);
      if (selectedProvider) setSelectedProvider(selectedProvider);
    }
  };

  const generateProviderListOptions: () => DropdownItemProps[] = () => {
    if (!providers || !providers.items) return [];
    return providers.items.map((i) => {
      return { key: i.id, text: i.name, value: i.id };
    });
  };

  useEffect(() => {
    if (!providers) populateProviderList();
  }, []);

  if (!providers || !providers.items)
    return <Form.Dropdown required fluid selection label="Provider" placeholder="Select Provider" name="provider" loading={true} />;
  return (
    <Form.Dropdown
      style={{ marginBottom: 14 }}
      required
      fluid
      selection
      label="Provider"
      placeholder="Select Provider"
      name="provider"
      value={selectedProvider && selectedProvider.id}
      onChange={onChange}
      options={generateProviderListOptions()}
    />
  );
};

export default SelectProvider2;
