//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";
import { useFetchRegions } from "./useFetchRegions";

export const useReinitializeRegion = () => {
  const { values, setFieldValue } = useFormikContext<NewDeploymentFormValues>();
  const { data: regions, isRefetching } = useFetchRegions({
    providerId: values.providerId,
    modelId: values.modelId,
  });
  const { isExisting } = useFetchDeploymentForEdit();

  useEffect(() => {
    // regions will change when values.providerId changes
    if (isRefetching || isExisting) {
      return;
    }
    const initialRegionId =
      regions?.items?.find((region) => {
        return !region.out_of_stock && !region.low_stock && region.available && !region.prerelease;
      })?.id ||
      regions?.items?.[0]?.id ||
      "";
    setFieldValue("regionId", initialRegionId);
  }, [values.providerId, isRefetching, regions]);
};
