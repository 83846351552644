//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Group as ApiGroup, User as ApiUser } from "../../api/iam/v1/iam";

// Interface describing an user
interface IGroupMemberView {
  group: ApiGroup;
  user: ApiUser;
}

const GroupMemberView = ({ ...args }: IGroupMemberView) => (
  <Table.Row>
    <Table.Cell>{args.user.name}</Table.Cell>
    <Table.Cell>{args.user.given_name}</Table.Cell>
    <Table.Cell>{args.user.family_name}</Table.Cell>
    <Table.Cell>{args.user.email}</Table.Cell>
    <Table.Cell>{moment(args.user.created_at).fromNow()}</Table.Cell>
  </Table.Row>
);

// Interface decribing the properties of the group member component
interface IGroupMemberProps {
  group: ApiGroup;
  user: ApiUser;
}

// Interface decribing the state of the group member component
interface IGroupMemberState {}

// The component to show the group member inside a group member list.
class GroupMember extends Component<IGroupMemberProps, IGroupMemberState> {
  state = {} as IGroupMemberState;

  render() {
    return <GroupMemberView {...this.props} />;
  }
}

export default GroupMember;
