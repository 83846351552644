//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useRef, useState } from "react";
import { BillingConfig, CreatePaymentMethodRequest, Organization } from "../../api/lib";
import apiClients from "../../api/apiclients";
import { isVatNumberValid, isVatPossible } from "./vat";
import { IWithRefreshProps } from "../../util/WithRefresh";
import GetStartedModal from "./GetStartedModal";
import AddBillingAddressModal from "./AddBillingAddressModal";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import { IPaymentInfo } from "./AddPaymentMethodModal";
import usePaymentInfo from "./usePaymentInfo";
import { FreeTierID } from "../../constants";
import { useTrackingContext } from "../../tracking/TrackingContextProvider";
import { useDashboardContext } from "../DashboardContextProvider";

interface IBillingWizard {
  isBillingDetailsEditAllowed: boolean;
  isPaymentMethodsAdditionAllowed: boolean;
  organization: Organization;
  config: BillingConfig;
  onSave: (newConfig: BillingConfig) => void;
  refreshNow: IWithRefreshProps["refreshNow"];
}

const BillingWizard = ({
  isBillingDetailsEditAllowed,
  isPaymentMethodsAdditionAllowed,
  refreshNow,
  organization,
  onSave,
  config: configProp,
}: IBillingWizard) => {
  const { onReloadOrganization } = useDashboardContext();
  const { tracking } = useTrackingContext();
  const paymentInfoRef = useRef<IPaymentInfo | undefined>(undefined);

  const [config, setConfig] = useState<BillingConfig>(configProp);
  const [isGetStartedOpen, setIsGetStartedOpen] = useState<boolean>(true);
  const [isAddBillingAddressModalOpen, setIsAddBillingAddressModalOpen] = useState<boolean>(false);
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] = useState<boolean>(false);
  const [creatingPaymentMethods, setCreatingPaymentMethods] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { providersList, providerId, provider, loadingPaymentMethods, preparedPaymentMethod, refreshPaymentMethods, onProviderChange } = usePaymentInfo({
    setErrorMessage,
    organization,
    refreshNow,
  });

  const { items: providers = [] } = providersList || {};
  const { tier = {} } = organization;

  const isVatPossibleValue = isVatPossible(config);
  const isVatValid = isVatNumberValid(config, config.vat_number);
  const vatValidation = !isVatPossibleValue || isVatValid;

  const canSaveBillingDetails =
    vatValidation && !!config.address && !!config.address.city && !!config.address.country_code && !!config.address.address && config.company_legal_name;

  const canSavePaymentMethod = !!providerId && !!preparedPaymentMethod && !!paymentInfoRef.current && canCreate;

  const isFreeTier = tier.id === FreeTierID;
  const hasProviders = providers.length > 0;

  const onCreate = async (req: CreatePaymentMethodRequest) => {
    setCreatingPaymentMethods(true);
    setErrorMessage(undefined);

    try {
      const createdPaymentMethod = await apiClients.billingClient.CreatePaymentMethod(req);

      await apiClients.billingClient.SetDefaultPaymentMethod({
        payment_method_id: createdPaymentMethod.id,
        organization_id: organization.id,
      });
      setCreatingPaymentMethods(false);
      tracking.trackPaymentMethodAdded();
      refreshPaymentMethods();
    } catch (e) {
      setCreatingPaymentMethods(false);
      setErrorMessage(e);
    } finally {
      onReloadOrganization();
      setIsAddPaymentMethodModalOpen(false);
    }
  };

  const onSavePaymentMethod = () => {
    try {
      if (paymentInfoRef) {
        setCreatingPaymentMethods(true);
        setErrorMessage(undefined);
        paymentInfoRef.current && paymentInfoRef.current.onClickSave();
      }
    } catch (e) {
      setCreatingPaymentMethods(false);
      setErrorMessage(`Failed to create payment method (${e})`);
    }
  };

  useEffect(() => {
    setConfig(configProp);
  }, [configProp]);

  const hasPermissions = isPaymentMethodsAdditionAllowed && isBillingDetailsEditAllowed;

  return (
    <>
      <GetStartedModal
        isOpen={!loadingPaymentMethods && hasPermissions && isFreeTier && hasProviders && isGetStartedOpen}
        onClickStart={() => {
          setIsAddBillingAddressModalOpen(true);
          setIsGetStartedOpen(false);
        }}
        onSkip={() => setIsGetStartedOpen(false)}
      />

      <AddBillingAddressModal
        isOpen={isAddBillingAddressModalOpen}
        config={config}
        configSetter={setConfig}
        onClickSave={() => {
          setIsAddPaymentMethodModalOpen(true);
          setIsAddBillingAddressModalOpen(false);
        }}
        disableSaveButton={!canSaveBillingDetails}
        onSkip={() => setIsAddBillingAddressModalOpen(false)}
      />

      <AddPaymentMethodModal
        isOpen={isAddPaymentMethodModalOpen}
        creatingPaymentMethods={creatingPaymentMethods}
        loadingPaymentMethods={loadingPaymentMethods}
        errorMessage={errorMessage}
        onDismissErrorMessage={() => setErrorMessage(undefined)}
        providersList={providersList || {}}
        onProviderChange={onProviderChange}
        onCreate={onCreate}
        onCanCreate={(canCreate: boolean) => setCanCreate(canCreate)}
        onSaveCanceled={() => setCreatingPaymentMethods(false)}
        paymentInfoRef={(elem: IPaymentInfo) => (paymentInfoRef.current = elem)}
        preparedPaymentMethod={preparedPaymentMethod}
        provider={provider || {}}
        providerId={providerId || ""}
        onClickBack={() => {
          setIsAddBillingAddressModalOpen(true);
          setIsAddPaymentMethodModalOpen(false);
        }}
        onClickSave={() => {
          onSave(config);
          onSavePaymentMethod();
        }}
        disableSaveButton={!canSavePaymentMethod}
        onSkip={() => setIsAddPaymentMethodModalOpen(false)}
      />
    </>
  );
};

export default BillingWizard;
