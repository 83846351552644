import { Box } from "@chakra-ui/react";
import React from "react";
import { SidebarContent } from "./SidebarContent";

export const MainSidebar = () => {
  return (
    <Box zIndex={"2"} width="320px" position="fixed" top="0" left="0" bottom="0">
      <SidebarContent />
    </Box>
  );
};
