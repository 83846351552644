import React from "react";
import { Grid, Container } from "semantic-ui-react";
import screenshot from "./highly-secure.png";
import { Box } from "../../ui/_box";
import rightBlob1 from "./right-blob1.png";
import { useBreakpointValue } from "@chakra-ui/react";

const HighlySecure = () => {
  const showOnDesktop = useBreakpointValue({ base: false, md: true }); // Show on medium screens (desktops) and above

  return (
    <Box position="relative">
      <Box position="absolute" right={0} top={"100px"} zIndex={"-1"}>
        <img src={rightBlob1} alt="bgBackgroundBlog" height={600} />
      </Box>
      <Box padding={"60px 0 60px 0"}>
        <Container>
          <Grid columns="16" stackable padded relaxed tablet={8} mobile={12}>
            <Grid.Row centered>
              <Grid.Column computer={7} tablet={14} mobile={16}>
                {showOnDesktop && (
                  <Box position="relative" top={"20px"}>
                    <img src={screenshot} alt="App Screenshot" width={"80%"} />
                  </Box>
                )}
              </Grid.Column>
              <Grid.Column computer={1} tablet={14} mobile={16} textAlign="center"></Grid.Column>
              <Grid.Column computer={8} tablet={14} mobile={16} textAlign="left" verticalAlign="middle">
                <Box fontSize={"3rem"} color="var(--green-900)" lineheight={1.5} fontWeight={700}>
                  Highly Secure
                </Box>
                <Box fontSize={"1.4rem"} fontWeight={400} lineheight={1.3} padding={"16px 0 0 0"}>
                  Encryption at transit & at rest guarantee all your data is encrypted before it hits the disk or sent over the network. In addition, you can
                  assign fine-grained role-based access control (RBAC) policies to your organization, your projects or deployments. This assigns permissions to
                  groups or individual users.
                </Box>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
export default HighlySecure;
