//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { Routes } from "../../routes";
import { EventSubscriptionManager } from "../../util/EventSubscriptionManager";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import OrganizationInvites from "../organization/OrganizationInvites";
import { Loading, ContentBelowMenu, Footer } from "../../ui/lib";
import {
  ListOptions as ApiListOptions,
  OrganizationInvite as ApiOrganizationInvite,
  OrganizationInviteList as ApiOrganizationInviteList,
  User as ApiUser,
} from "../../api/lib";
import apiClients from "../../api/apiclients";
import EnterPhoneNumber from "../user/EnterPhoneNumber";
import PhoneNumberVerificationCode from "../user/PhoneNumberVerificationCode";
import EnterNames from "../user/EnterNames";
import CreateDefaultOrganization from "../organization/CreateDefaultOrganization";
import { OnboardingTemplate } from "../OnboardingTemplate";
import Auth from "../../auth/Auth";
import { ITracking } from "../../tracking/api";
import _ from "lodash";

// Interface describing the onboarding view arguments
interface IExactOnboardingViewArgs {
  eventSubscriptionManager: EventSubscriptionManager;
  tracking: ITracking;
  user: ApiUser;
  organizationInvites: ApiOrganizationInvite[];
}

const ExactOnboardingView = ({ ...args }: IExactOnboardingViewArgs) => {
  if (!!args.user.mobile_phone_needs_verification) {
    if (!args.user.mobile_phone) {
      return <Redirect to={Routes.onboarding_user_phone_number} />;
    }
    if (!args.user.mobile_phone_verified) {
      return <Redirect to={Routes.onboarding_user_verification_code} />;
    }
  }
  if (!args.user.given_name || !args.user.family_name || !args.user.company_name) {
    return <Redirect to={Routes.onboarding_user_names} />;
  }

  if (!_.isEmpty(args.organizationInvites)) {
    return <Redirect to={Routes.onboarding_organizations_invites} />;
  }

  return <Redirect to={Routes.onboarding_finished} />;
};

// Interface describing the onboarding view arguments
interface IOnboardingViewArgs extends IWithRefreshProps {
  user: ApiUser;
  organizationInvites: ApiOrganizationInvite[];
  tracking: ITracking;
  onInviteAccepted: (organizationId: string) => void;
  onInviteRejected: (organizationId: string) => void;
  onNoOpenInvites: () => void;
  onPhoneNumberUpdated: (userId: string) => void;
  onNamesUpdated: (userId: string) => void;
  onPhoneNumberVerified: (userId: string) => void;
  onClickLogout: () => void;
  onNewOrganizationCreated: (organizationId: string) => void;
  onExistingOrganizationFound: () => void;
}

const OnboardingView = ({ ...args }: IOnboardingViewArgs) => (
  <OnboardingTemplate {...args}>
    <ContentBelowMenu>
      <Route exact path={Routes.onboarding} render={(props) => <ExactOnboardingView {...args} {...props} />} />
      <Route path={Routes.onboarding_user_phone_number} render={(props) => <EnterPhoneNumber {...args} {...props} loading={false} />} />
      <Route path={Routes.onboarding_user_verification_code} render={(props) => <PhoneNumberVerificationCode {...args} {...props} loading={false} />} />
      <Route path={Routes.onboarding_user_names} render={(props) => <EnterNames {...args} {...props} loading={false} />} />
      <Route path={Routes.onboarding_organizations_invites} render={(props) => <OrganizationInvites {...args} {...props} loading={false} />} />
      <Route exact path={Routes.onboarding_finished} render={(props) => <CreateDefaultOrganization {...args} {...props} />} />
    </ContentBelowMenu>
    <Footer>
      <div>
        <b>ArangoGraph:</b>&nbsp;<i>The Managed Cloud for ArangoDB</i>
      </div>
    </Footer>
  </OnboardingTemplate>
);

// Interface decribing the properties of the onboarding component
interface IOnboardingProps extends IWithRefreshProps, RouteComponentProps {
  onClickLogout: () => void;
  auth: Auth;
  tracking: ITracking;
}

// Interface decribing the state of the onboarding component
interface IOnboardingState {
  user?: ApiUser;
  organizationInvites?: ApiOrganizationInviteList;
}

// The Onboarding component
class Onboarding extends Component<IOnboardingProps, IOnboardingState> {
  state = {
    user: undefined,
    organizationInvites: undefined,
  } as IOnboardingState;

  reloadUserInfo = async () => {
    // Load user info
    const user = await apiClients.iamClient.GetThisUser();
    this.setState(
      {
        user: user,
      },
      () => {
        if (user.dashboard_access_denied) {
          this.props.auth.accessDeniedReason = user.dashboard_access_denied_reason || "";
          this.props.history.replace(Routes.accessdenied);
        }
      }
    );
  };

  refreshUserInfo = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
  };

  reloadOpenOrganizationInvites = async (cb: (invites: ApiOrganizationInvite[]) => void) => {
    // Load organization invites
    const listOptions = {} as ApiListOptions;
    const organizationInvites = await apiClients.resourceManagerClient.ListMyOrganizationInvites(listOptions);
    this.setState(
      {
        organizationInvites: organizationInvites,
      },
      () => {
        const allInvites = organizationInvites.items || [];
        const openInvites = _.filter(allInvites, (x) => !x.accepted && !x.rejected);
        cb(openInvites);
      }
    );
  };

  refreshOpenOrganizationInvites = (cb: (invites: ApiOrganizationInvite[]) => void) => {
    this.props.refreshNow && this.props.refreshNow(() => this.reloadOpenOrganizationInvites(cb));
  };

  onPhoneNumberUpdated = async (userId: string) => {
    //console.log(`onPhoneNumberUpdated`);
    const user = await apiClients.iamClient.GetThisUser();
    if (user.mobile_phone_needs_verification) {
      this.props.history.replace(Routes.onboarding_user_verification_code);
    }
  };

  onPhoneNumberVerified = (userId: string) => {
    //console.log(`onPhoneNumberVerified`);
    this.props.history.replace(Routes.onboarding_user_names);
  };

  onNamesUpdated = (userId: string) => {
    //console.log(`onNamesUpdated`);
    this.refreshOpenOrganizationInvites((invites) => {
      if (_.isEmpty(invites)) {
        this.props.history.replace(Routes.onboarding_finished);
      } else {
        this.props.history.replace(Routes.onboarding_organizations_invites);
      }
    });
  };

  onInviteAccepted = (organizationId: string) => {
    //console.log(`onInviteAccepted`);
    this.refreshOpenOrganizationInvites((invites) => {
      if (_.isEmpty(invites)) {
        this.props.history.replace(Routes.onboarding_finished);
      } else {
        this.props.history.replace(Routes.onboarding_organizations_invites);
      }
    });
  };

  onInviteRejected = (organizationId: string) => {
    this.props.history.replace(Routes.onboarding_finished);
  };

  onNoOpenInvites = () => {
    this.props.history.replace(Routes.onboarding_finished);
  };

  onOnboardingFinished = () => {
    this.props.tracking.trackOnboardingFinished();
    this.props.history.replace(Routes.dashboard);
  };

  onOnboardingStarted = () => {
    this.props.tracking.trackOnboardingStarted();
  };

  componentDidMount() {
    this.refreshUserInfo();
    this.refreshOpenOrganizationInvites(() => {});
    this.onOnboardingStarted();
  }

  render() {
    const user = this.state.user;
    const organizationInvites = this.state.organizationInvites;

    if (user && organizationInvites) {
      return (
        <OnboardingView
          {...this.props}
          {...this.state}
          user={user}
          organizationInvites={organizationInvites.items || []}
          onPhoneNumberUpdated={this.onPhoneNumberUpdated}
          onPhoneNumberVerified={this.onPhoneNumberVerified}
          onNamesUpdated={this.onNamesUpdated}
          onInviteAccepted={this.onInviteAccepted}
          onInviteRejected={this.onInviteRejected}
          onNoOpenInvites={this.onNoOpenInvites}
          onNewOrganizationCreated={this.onOnboardingFinished}
          onExistingOrganizationFound={this.onOnboardingFinished}
        />
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(Onboarding);
