//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { DeploymentPriceRequest } from "../../api/lib";
import { useDashboardContext } from "../DashboardContextProvider";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchCalculateDeploymentPrice = ({
  modelId,
  regionId,
  providerId,
  supportPlanId,
  nodeDiskSizeId,
  nodeDiskSize,
  diskPerformanceId,
  servers,
  nodeCount,
  customDiskSize,
}: {
  modelId: string;
  regionId: string;
  providerId: string;
  nodeDiskSizeId: string;
  supportPlanId: string;
  nodeDiskSize: number;
  diskPerformanceId: string;
  nodeCount: number;
  servers?: {
    coordinators?: number;
    coordinator_memory_size?: number;
    dbservers?: number;
    dbserver_memory_size?: number;
    dbserver_disk_size?: number;
  };
  customDiskSize: {
    [key: string]: number;
  };
}) => {
  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const finalNodeDiskSize = customDiskSize[nodeDiskSizeId] || nodeDiskSize;
  const fetchDeploymentPrice = async () => {
    const selectedProviderId = providerId;
    const selectedRegionId = regionId;
    const selectedSupportPlanId = supportPlanId;
    const tier = selectedOrganization.tier || {};
    const needs_support_plan = !selectedSupportPlanId && tier.has_support_plans;

    if (selectedProviderId && selectedRegionId && !needs_support_plan) {
      const finalServers = servers || {
        coordinators: 3,
        coordinator_memory_size: 0,
        dbservers: 3,
        dbserver_memory_size: 0,
        dbserver_disk_size: 0,
      };
      const request: DeploymentPriceRequest = {
        organization_id: selectedOrganization.id,
        project_id: project.id,
        cloud_provider_id: selectedProviderId,
        cloud_region_id: selectedRegionId,
        model: modelId,
        support_plan_id: selectedSupportPlanId || undefined,
        coordinators: finalServers.coordinators,
        coordinator_memory_size: finalServers.coordinator_memory_size,
        dbservers: finalServers.dbservers,
        dbserver_memory_size: finalServers.dbserver_memory_size,
        dbserver_disk_size: finalServers.dbserver_disk_size,
        node_size_id: nodeDiskSizeId || "",
        node_disk_size: finalNodeDiskSize || 0,
        node_count: nodeCount || (modelId === "developer" ? 1 : 3),
        disk_performance_id: diskPerformanceId || undefined,
      };
      const price = await apiClients.dataClient.CalculateDeploymentPrice(request);
      const priceWithCredits = await apiClients.dataClient.CalculateDeploymentPrice({
        ...request,
        use_credit_pricing: true,
      });
      const currency = await apiClients.currencyClient.GetCurrency({ id: price.currency_id });
      return { price, currency, priceWithCredits };
    }
  };
  return useQuery(
    [providerId, modelId, regionId, nodeDiskSizeId, finalNodeDiskSize, supportPlanId, nodeDiskSize, diskPerformanceId, servers, nodeCount, "deploymentPrice"],
    fetchDeploymentPrice,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnMount: false,
      enabled:
        !!providerId &&
        !!regionId &&
        !!modelId &&
        !!selectedOrganization.id &&
        !!project.id &&
        !!supportPlanId &&
        !!nodeDiskSizeId &&
        !!diskPerformanceId &&
        !!nodeCount &&
        !!nodeDiskSize,
    }
  );
};
