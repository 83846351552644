//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { StyledFileItem, StyledProgress } from "../files/Styles";
import { useDataloaderStore } from "../DataloaderStore";

const CollectionUploadProgress = () => {
  const { migrationJob } = useDataloaderStore().getDeploymentDataloaderState();
  const { fileBeingUploaded } = migrationJob;

  return (
    <StyledFileItem key={fileBeingUploaded?.collectionName}>
      <FlexBox direction="column" padding="16px">
        <FlexBox align="center" margin="0 0 16px 0">
          <Icon name="file alternate outline" size="big" style={{ color: "var(--yellow-600)" }} />
          <p className="para">
            Filling collection <b>{fileBeingUploaded?.collectionName}</b> with file <b>{fileBeingUploaded?.name}</b>.
          </p>
        </FlexBox>
        <StyledProgress percent={fileBeingUploaded?.progress || 0} color="green" progress />
      </FlexBox>
    </StyledFileItem>
  );
};

export default CollectionUploadProgress;
