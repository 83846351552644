//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Message, Divider, Button, Segment, Header, CardDescription } from "semantic-ui-react";
import { FlexBox } from "../../ui/_flex";
import { Section, SectionButtons, SectionHead, SectionHeader } from "../../ui/_section";
import AddEmailAddressesView from "./AddEmailAddressesView";
import EmailListView from "./NotificationEmails";

interface INotificationSettingsArgs {
  existingEmailAddresses: string[];
  handleRemoveEmail: (email: string) => void;
  areSettingsLoading: boolean;
  highlightedEmail: string;
  showEmailAdditionView: boolean;
  addEmailAddresses: () => void;
  handleExistingEmailAddressAddition: (email: string) => void;
  handleSavingEmailAddresses: (emailAddresses: string[]) => void;
  cancelEmailAddressAddition: () => void;
}

const NotificationSettings = ({
  existingEmailAddresses,
  areSettingsLoading,
  highlightedEmail,
  showEmailAdditionView,
  handleRemoveEmail,
  addEmailAddresses,
  cancelEmailAddressAddition,
  handleExistingEmailAddressAddition,
  handleSavingEmailAddresses,
}: INotificationSettingsArgs) => {
  return (
    <Section>
      <Message data-testid="notificationPageMessage">
        These notifications are related to this deployment and are also sent out as emails to the configured addresses.
      </Message>
      <Divider hidden />

      <SectionHead>
        <SectionHeader title="Notifications settings" />
        <SectionButtons>
          <Button
            data-testid="notificationAddEmailAddressAction"
            content="Add e-mail addresses"
            size="medium"
            labelPosition="right"
            icon="plus"
            primary
            onClick={addEmailAddresses}
          />
        </SectionButtons>
      </SectionHead>

      <Segment>
        <FlexBox direction="row" justify="space-between">
          <div>
            <Header data-testid="notificationSectionHeader">E-mail addresses configured for receiving notifications</Header>
            <CardDescription data-testid="notificationSectionDescription">
              The listed e-mail addresses are currently receiving all notifications. Notifications to this deployment will be sent to the creator of the
              deployment and any owners of the organizations.
            </CardDescription>
          </div>
        </FlexBox>
        <Divider section hidden />
        <EmailListView
          emails={existingEmailAddresses}
          onRemove={handleRemoveEmail}
          showIcon
          loading={areSettingsLoading}
          highlightedEmail={highlightedEmail}
          emptyMessage="No custom e-mail addresses are configured. Please add new addresses to send notifications to others then the creator of the deployment and the owners of the organization."
        />
      </Segment>
      {showEmailAdditionView && (
        <AddEmailAddressesView
          onExistingEmailAddition={handleExistingEmailAddressAddition}
          existingEmailAddresses={existingEmailAddresses}
          loading={areSettingsLoading}
          onSave={handleSavingEmailAddresses}
          onCancel={cancelEmailAddressAddition}
        />
      )}
    </Section>
  );
};

export default NotificationSettings;
