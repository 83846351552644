//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useState } from "react";

export type Page = {
  page: number;
  pageSize: number;
};

type Pagination = {
  paginationObject: Page;
  setNextPage: () => void;
  setPreviousPage: () => void;
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
};

const usePagination = (initialPage = 0, initialPageSize = 10): Pagination => {
  const [paginationObject, setPaginationObject] = useState<Page>({
    page: initialPage,
    pageSize: initialPageSize,
  });

  const setNextPage = (): void => {
    const { page, pageSize } = paginationObject;
    setPaginationObject({
      page: page + 1,
      pageSize: pageSize,
    });
  };

  const setPreviousPage = (): void => {
    const { page, pageSize } = paginationObject;
    setPaginationObject({
      page: page ? page - 1 : 0,
      pageSize: pageSize,
    });
  };

  const setPageSize = (pageSize: number): void => {
    const { page } = paginationObject;
    setPaginationObject({
      page: page,
      pageSize: pageSize,
    });
  };

  const setPage = (page: number): void => {
    const { pageSize } = paginationObject;
    setPaginationObject({
      page: page,
      pageSize: pageSize,
    });
  };

  return {
    paginationObject,
    setNextPage,
    setPreviousPage,
    setPageSize,
    setPage,
  };
};

export default usePagination;
