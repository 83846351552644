//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import styled from "@emotion/styled";

interface ILoading {
  message?: string;
  className?: string;
}

export const Loading = ({ message, className = "" }: ILoading) => (
  <Segment className={className} data-testid="loadingView">
    <Dimmer inverted active>
      <Loader inverted>{message || "Loading..."}</Loader>
    </Dimmer>
    <div style={{ minHeight: "3em" }} />
  </Segment>
);

export const FixedLoader = styled(Loader)`
  position: fixed !important;
  top: 16px !important;
  right: 16px !important;
  left: auto !important;
  z-index: 100 !important;
`;
