//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { ITracking, IWebSocketRef } from "./api";
import { Organization as ApiOrganization, User as ApiUser } from "../api/lib";
import { DataLoaderMessage, Message } from "../util/Events";
import _ from "lodash";

export default class Tracking implements ITracking {
  private user?: ApiUser;
  private organization?: ApiOrganization;
  private socketRef?: IWebSocketRef;

  // Link websocket ref
  setWebSocketRef(ref?: IWebSocketRef) {
    this.socketRef = ref;
  }

  configureGTag() {
    const gtagIDs = window.GOOGLE_ANALYTICS_IDS || [];
    const head = document.getElementsByTagName("head")[0];
    if (!head) return;
    if (typeof head.insertAdjacentElement !== "function") return;
    if (typeof head.appendChild !== "function") return;

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${_.first(gtagIDs)}`;
    head.insertAdjacentElement("afterbegin", script);

    const scr = [];
    scr.push("window.dataLayer = window.dataLayer || [];");
    scr.push("function gtag(){dataLayer.push(arguments);}");
    scr.push("gtag('js', new Date());");
    scr.push(`gtag('config', ${_.first(gtagIDs)});`);

    const script2 = document.createElement("script");
    script2.textContent = scr.join("\n");
    head.appendChild(script2);
  }

  sendGTagPageView(path: string) {
    const gtag = (window as any)["gtag"];
    if (typeof gtag === "function") {
      const gtagIDs = window.GOOGLE_ANALYTICS_IDS || [];
      gtagIDs.forEach((x) => {
        gtag("config", x, { page_path: path });
      });
    }
  }

  setUserIdentity(user?: ApiUser, organization?: ApiOrganization) {
    this.user = user;
    this.organization = organization;

    try {
      if (user && user.email && user.given_name && user.family_name && user.company_name) {
        // See https://developers.hubspot.com/docs/methods/tracking_code_api/identify_visitor
        // and https://developers.hubspot.com/docs/methods/contacts/create_contact
        const _hsq = ((window as any)["_hsq"] = (window as any)["_hsq"] || []);
        const org = organization || {};
        const tier = org.tier || {};
        const info = {
          email: user.email,
          firstname: user.given_name,
          lastname: user.family_name,
          company: user.company_name,
          registered_for_arangodb_oasis_: "ArangoGraph Insights Platform",
          organization_id: org.id || "",
          organization_tier_id: tier.id || "",
        };
        _hsq.push(["identify", info]);
      }
    } catch (e) {
      // Ignore errors
      console.log(e);
    }
  }

  // Called to make the referrer of the current page equal to the homepage itself
  setReferrerOverride() {
    const gtag = (window as any)["gtag"];
    if (typeof gtag === "function") {
      const gtagIDs = window.GOOGLE_ANALYTICS_IDS || [];
      const referrer = `${window.location.protocol}://${window.location.host}`;
      if (window.DEBUG) {
        console.log(`Setting referred override to ${referrer}`);
      }
      gtagIDs.forEach((x) => {
        gtag("set", x, { page_referrer: referrer });
      });
    }
  }

  // Track a single page view.
  async trackPageView(path: string) {
    // Track pageview in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackPageView",
          user_id: user.id || "",
          local_path: path,
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking page view - ${err}`);
        }
      }
    }

    // Track pageview in hubspot
    window.DEBUG && console.log(`---- trackPageView(${path})`);
    try {
      const _hsq = ((window as any)["_hsq"] = (window as any)["_hsq"] || []);
      _hsq.push(["setPath", path]);
      _hsq.push(["trackPageView"]);
    } catch (e) {
      // Ignore errors
    }
    // Track in google analytics
    try {
      this.sendGTagPageView(path);
    } catch {
      // Ignore errors
    }
  }

  // Called when user has not yet verified his email address
  trackEmailNeedsVerification() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/emailNeedsVerification");
  }
  // Called when user has verified his email address
  async trackEmailVerified() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/emailVerified");
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackUserUpdated",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Email Needs Verified - ${err}`);
        }
      }
    }
  }

  // Called when user has entered his phone number
  async trackPhoneNumberEntered() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/phoneNumberEntered");
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackUserUpdated",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Phone Number Entered - ${err}`);
        }
      }
    }
  }

  // Called when an educational signup is completed
  async trackEducationalSignup(user?: ApiUser) {
    this.setUserIdentity(user, this.organization);
    this.trackPageView("/tracking/educationalSignup");
    // Track event in backend
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackEducationalSignup",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Educational signup - ${err}`);
        }
      }
    }
  }

  // Called when user has entered the correct verification code for his phone number
  async trackPhoneNumberVerified() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/phoneNumberVerified");
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackUserUpdated",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Phone Number Verified - ${err}`);
        }
      }
    }
  }

  // Called when user names (first, last, company) are set.
  async trackUserNamesEntered(user?: ApiUser) {
    this.setUserIdentity(user, this.organization);
    this.trackPageView("/tracking/userNamesEntered");
    // Track event in backend
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackUserUpdated",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking User Names Entered - ${err}`);
        }
      }
    }
  }

  // Called when the onboarding process has started.
  async trackOnboardingStarted() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/onboardingStarted");
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackOnboardingStarted",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Onboarding Started - ${err}`);
        }
      }
    }
  }
  // Called when the onboarding process has finished.
  async trackOnboardingFinished() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/onboardingFinished");
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackOnboardingFinished",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Onboarding Started - ${err}`);
        }
      }
    }
  }

  // Called when an example dataset is installed.
  async trackExampleDatasetInstallationCreated(deploymentID?: string, exampleDatasetID?: string) {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView(`/tracking/exampleDatasetInstallationCreated/${exampleDatasetID || "?"}`);
    // Track event in backend
    const user = this.user;
    const organization = this.organization;
    const socketRef = this.socketRef;
    if (user && organization && deploymentID && exampleDatasetID && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackExampleDatasetInstallationCreated",
          user_id: user.id || "",
          args: {
            organization_id: organization.id || "",
            deployment_id: deploymentID || "",
            example_dataset_id: exampleDatasetID || "",
          },
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Example Dataset Installation Created - ${err}`);
        }
      }
    }
  }

  // Called when a deployment has been deleted
  async trackExampleDatasetInstallationDeleted(deploymentID?: string, exampleDatasetID?: string) {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView(`/tracking/exampleDatasetInstallationDeleted/${exampleDatasetID || "?"}`);
    // Track event in backend
    const user = this.user;
    const organization = this.organization;
    const socketRef = this.socketRef;
    if (user && organization && deploymentID && exampleDatasetID && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackExampleDatasetInstallationDeleted",
          user_id: user.id || "",
          args: {
            organization_id: organization.id || "",
            deployment_id: deploymentID || "",
            example_dataset_id: exampleDatasetID || "",
          },
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Example Dataset Installation Deleted - ${err}`);
        }
      }
    }
  }

  // Called when one of the login buttons is clicked
  trackLoginClicked() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/login");
  }

  // Called when one of the signup buttons is clicked
  trackSignUpClicked() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/signup");
  }

  // Called when a payment method has been added
  async trackPaymentMethodAdded() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/paymentMethodAdded");
    // Track event in backend
    const user = this.user;
    const organization = this.organization;
    const socketRef = this.socketRef;
    if (user && organization && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackPaymentMethodAdded",
          user_id: user.id || "",
          args: {
            organization_id: organization.id || "",
          },
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Payment Method Added - ${err}`);
        }
      }
    }
  }

  // Called on load of the pricing page
  async trackVisitedPricingPage() {
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;
    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        let msg = {
          type: "TrackPricingPageVisited",
          user_id: user.id || "",
        } as Message;
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Pricing Page Visited - ${err}`);
        }
      }
    }
  }

  // Called when the user clicks Accept on the modal asking to accept Terms & Conditions
  // and Privacy policy.
  trackAcceptedTerms() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/termsAccepted");
  }

  // Called when the user clicks Do Not Accept on the modal asking to accept Terms & Conditions
  // and Privacy policy.
  trackNotAcceptedTerms() {
    this.setUserIdentity(this.user, this.organization);
    this.trackPageView("/tracking/termsNotAccepted");
  }

  // Called on trigger a Data Loader import
  async trackDataLoaderDataImportStarted({
    deploymentId,
    totalCollections,
    totalDocuments,
    totalFileSize,
  }: {
    deploymentId: string;
    totalCollections: number;
    totalDocuments: number;
    totalFileSize: number;
  }) {
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;

    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        const msg: DataLoaderMessage = {
          type: "TrackDataLoaderDataImportStarted",
          user_id: user.id || "",
          data_loader_args: {
            deployment_id: deploymentId,
            total_collections_count: totalCollections,
            total_documents_count: totalDocuments,
            total_file_size: totalFileSize,
          },
        };
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Data Loader Start Import event - ${err}`);
        }
      }
    }
  }

  // Called on finishing a Data Loader import
  async trackDataLoaderDataImportFinished({
    deploymentId,
    totalCollections,
    totalDocuments,
    totalFileSize,
    runTimeSeconds,
  }: {
    deploymentId: string;
    totalCollections: number;
    totalDocuments: number;
    totalFileSize: number;
    runTimeSeconds: number;
  }) {
    // Track event in backend
    const user = this.user;
    const socketRef = this.socketRef;

    if (user && socketRef) {
      const ws = socketRef.getWebSocket();
      if (ws) {
        const msg: DataLoaderMessage = {
          type: "TrackDataLoaderDataImportFinished",
          user_id: user.id || "",
          data_loader_args: {
            deployment_id: deploymentId,
            total_collections_count: totalCollections,
            total_documents_count: totalDocuments,
            total_file_size: totalFileSize,
            runtime_seconds: runTimeSeconds,
          },
        };
        try {
          await ws.sendMessage(JSON.stringify(msg));
        } catch (err) {
          console.warn(`Error while tracking Data Loader Start Import event - ${err}`);
        }
      }
    }
  }
}
