//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
import { CollectionType } from "arangojs/collection";
import { useDataloaderStore } from "../DataloaderStore";
import { EdgeExtraValues, Mappings, FileObject, UploadedFileReport } from "../types";
import { MAX_ERRORS_LENGTH, uploadFile, generateReportUrl } from "../utils/filesUpload.utils";
import { FullItem, OptId } from "vis-data/declarations/data-interface";
import { NodeExtraValues } from "../types";

const useFileReUpload = ({
  collectionName,
  newFile,
  selectedFields,
}: {
  collectionName: string;
  newFile: FileObject | undefined;
  selectedFields: string[];
}) => {
  const { setMigrationJob, updateNodeMappings, updateEdgeMappings, getDeploymentDataloaderState } = useDataloaderStore.getState();
  const { currentDatabase, nodes, edges, nodeMappings, edgeMappings, migrationJob } = getDeploymentDataloaderState();
  const { db } = currentDatabase;

  const getIdByName = (name: string, items: FullItem<Partial<Record<"id", OptId> & (NodeExtraValues | EdgeExtraValues)>, "id">[]) =>
    items.find((item) => item.label === name)?.id || "";

  const updateMappings = (type: CollectionType, name: string, mappings: Mappings, updateFunction: (mapping: Mappings) => void) => {
    const id = getIdByName(name, type === CollectionType.DOCUMENT_COLLECTION ? nodes : edges);

    const updatedMap = {
      ...mappings,
      [String(id)]: {
        ...(mappings[id] || {}),
        selectedFields,
      },
    };

    updateFunction(updatedMap);
  };

  const updateFileReUploadProgress = (file: FileObject, progress: number) => {
    setMigrationJob({
      status: "finished",
      fileBeingUploaded: {
        name: file.name,
        collectionName: file.collectionName || "",
        progress,
      },
    });
  };

  const saveDroppedFileAtCollection = async () => {
    if (!newFile) return;
    // TODO: show error message when truncate was not possible
    await db?.collection(collectionName).truncate();
    // TODO: show error message when listCollections was not possible
    const collections = (await db?.listCollections()) || [];

    const collectionType = collections.find((collection) => collection.name === collectionName)?.type;

    if (collectionType === CollectionType.DOCUMENT_COLLECTION) {
      updateMappings(collectionType, collectionName, nodeMappings, updateNodeMappings);
    } else if (collectionType === CollectionType.EDGE_COLLECTION) {
      updateMappings(collectionType, collectionName, edgeMappings, updateEdgeMappings);
    }

    const { errors: uploadErrors, successfulUploads } = await uploadFile(
      { ...newFile, collectionName },
      collections,
      collectionName,
      updateFileReUploadProgress
    );

    const newUploadedFileReport: UploadedFileReport = {
      name: newFile.name,
      collectionName,
      errors: uploadErrors.slice(0, MAX_ERRORS_LENGTH),
      fullReportURL: uploadErrors.length > MAX_ERRORS_LENGTH ? generateReportUrl(uploadErrors) : undefined,
      successfulUploads,
      collectionType,
      ignored: false,
    };

    const newFilesAlreadyUploaded = migrationJob.filesAlreadyUploaded?.map((fileAlreadyUploaded) =>
      fileAlreadyUploaded.collectionName === collectionName ? newUploadedFileReport : fileAlreadyUploaded
    );

    setMigrationJob({
      status: "finished",
      fileBeingUploaded: undefined,
      filesAlreadyUploaded: newFilesAlreadyUploaded,
    });
  };

  return {
    saveDroppedFileAtCollection,
  };
};

export { useFileReUpload };
