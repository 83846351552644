import React from "react";
import { startCase } from "lodash";
import { Icon, Popup, Statistic } from "semantic-ui-react";
import { StyledStatsSegment, StatisticsContentGroup } from "../../ui/lib";
import { DeploymentReplication as ApiDeploymentReplication } from "../../api/lib";
import moment from "moment";

const MigrationStatusView = ({ replicationDetails }: { replicationDetails: ApiDeploymentReplication }) => {
  const { status: replicationStatus = {} } = replicationDetails || {};
  const { phase = "", message = "" } = replicationStatus;

  const PHASE = {
    initialising: "Initialising",
    "in-progress": "In-Progress",
    error: "Error",
    failed: "Failed",
    stopping: "Stopping",
    completed: "Completed",
  };

  const getProgress = (data: ApiDeploymentReplication) => {
    const { status = {} } = data;
    const { phase: contextPhase = "" } = status;

    const { progress } = status;
    if (!progress) return "-";

    if (contextPhase === PHASE.completed) return "100%";

    const result = Math.floor(progress * 100 * 100) / 100;

    return `${result}%`;
  };

  if (phase === PHASE.completed) {
    const dateMessage = replicationStatus.phase_updated_at ? moment(replicationStatus.phase_updated_at).fromNow() : "";
    const lastMigrationMessage = `Last migration completed ${dateMessage}`;

    return <StyledStatsSegment data-testid="migrationCompletedMessage">{lastMigrationMessage.trim()}</StyledStatsSegment>;
  }

  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths={2}>
          <Statistic size="mini" data-testid="migrationPhaseDetail">
            <Statistic.Label data-testid="migrationPhaseHeader">
              Phase
              {(phase === PHASE.error || phase === PHASE.failed) && (
                <Popup trigger={<Icon name="info circle" color="red" data-testid="migrationErrorIcon" />} content={message} data-testid="errorInformation" />
              )}
            </Statistic.Label>
            <Statistic.Value data-testid="migrationPhaseValue">{phase ? startCase(phase) : "-"}</Statistic.Value>
          </Statistic>
          <Statistic size="mini" data-testid="migrationProgressDetail">
            <Statistic.Label data-testid="migrationProgressHeader">Progress</Statistic.Label>
            <Statistic.Value data-testid="migrationProgressDetailHeader">{getProgress(replicationDetails || {})}</Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

export default MigrationStatusView;
