//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Grid, Segment, Button, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

interface IDangerZoneItemArgs {
  children: React.ReactNode;
  buttonText: string;
  buttonColor?: SemanticCOLORS;
  buttonIcon?: SemanticICONS;
  onClick: () => void;
  disabled: boolean;
}

export const DangerZoneItem = ({ ...args }: IDangerZoneItemArgs) => {
  return (
    <Segment>
      <Grid columns="16">
        <Grid.Column width="10" verticalAlign="middle">
          {args.children}
        </Grid.Column>
        <Grid.Column width="6" verticalAlign="middle">
          <Button
            content={args.buttonText}
            icon={args.buttonIcon}
            labelPosition="right"
            floated="right"
            color={args.buttonColor}
            onClick={args.onClick}
            disabled={args.disabled}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
