//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState, useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Button, Icon, Message, Popup } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { StyledErrorLabel } from "../files/Styles";
import { DocumentMetadataError } from "../types";
import { CollectionType } from "arangojs/collection";
import { getType } from "../utils/filesUpload.utils";
import { pluralize } from "../../../../api/_util";
import { Box } from "../../../../ui/_box";
import styled from "@emotion/styled";

const StyledCircularButton = styled(Button)`
  padding: 8px !important;
  margin-left: 5px !important;
`;

const ErrorsDisplay = ({ errors }: { errors: DocumentMetadataError[] }) => (
  <div className="file-content">
    <FlexBox justify="space-between" direction="column">
      {errors.map((error, index) => (
        <StyledErrorLabel prompt basic className="mask-data" key={index}>
          <p className="red-text">
            Error {error.errorNum}: {error.errorMessage}
          </p>
          <SyntaxHighlighter languages="json" wrapLongLines lineProps={{ style: { lineHeight: "20px" } }}>
            {JSON.stringify(error.processedDocument)}
          </SyntaxHighlighter>
        </StyledErrorLabel>
      ))}
    </FlexBox>
  </div>
);

const UploadedFileReportWithErrors = ({
  collectionName,
  uploadedFileName,
  successfulUploads,
  fullReportURL,
  errors,
  collectionType,
  ignored,
  handleIgnoreFileErrors,
  handleReplaceFile,
}: {
  collectionName: string;
  uploadedFileName: string;
  successfulUploads: number;
  fullReportURL: string | undefined;
  errors: DocumentMetadataError[];
  collectionType: CollectionType | undefined;
  ignored: boolean;
  handleIgnoreFileErrors: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleReplaceFile: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isFullReportValid, setIsFullReportValid] = useState<boolean>(true);

  const typeText = getType(collectionType) || "Item";

  useEffect(() => {
    if (fullReportURL) {
      const checkValidity = async () => {
        try {
          const response = await fetch(fullReportURL);
          setIsFullReportValid(response.ok);
        } catch (error) {
          setIsFullReportValid(false);
        }
      };

      checkValidity();
    }
  }, [fullReportURL]);

  return (
    <>
      <FlexBox justify="space-between" direction="row" className="file-item cursor-pointer" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <FlexBox justify="flex-start" direction="column" maxWidth="65%">
          <Box className="file-section">
            <Box className="name ellipsis">
              <Icon name="file alternate outline" size="big" style={{ color: `${ignored ? "var(--green-700)" : "var(--yellow-600)"}` }} />
              <b>
                {successfulUploads} {pluralize(typeText, successfulUploads)}
              </b>{" "}
              added to the <b>{collectionName}</b> collection based on <b className="name ellipsis">{uploadedFileName}</b>.
            </Box>
          </Box>
          <Message warning style={{ borderColor: `${ignored ? "var(--green-700)" : "var(--yellow-600)"}`, width: "400px" }}>
            <FlexBox justify="space-between" align="center">
              <div>
                <Icon name="info circle" style={{ color: "var(--yellow-600)" }} />
                {!!fullReportURL ? "Several errors found during upload" : `${errors.length} errors found during upload.`}
              </div>
              {!!fullReportURL && (
                <Popup
                  position="top center"
                  style={{ textAlign: "center" }}
                  content={
                    isFullReportValid
                      ? "Click to download the full error report for this collection import. Please note that if the page is reloaded, the report will no longer be accessible"
                      : "The full report is no longer accessible"
                  }
                  trigger={
                    <span>
                      <StyledCircularButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()}
                        as="a"
                        href={fullReportURL}
                        download={`${collectionName}-errors-report`}
                        icon="download"
                        primary
                        disabled={!isFullReportValid}
                      />
                    </span>
                  }
                />
              )}
            </FlexBox>
          </Message>
        </FlexBox>

        <FlexBox justify="center" align="center">
          {!!errors.length && !ignored && (
            <FlexBox width="max-content" margin="0 10px 0 0">
              <Button content="Upload file again" onClick={handleReplaceFile} />
              <Button content="Ignore and save" onClick={handleIgnoreFileErrors} primary />
            </FlexBox>
          )}
          <Icon className="file-item-icon" name={`chevron ${isDropdownOpen ? "up" : "down"}`} />
        </FlexBox>
      </FlexBox>

      <div>{!!errors.length && isDropdownOpen && <ErrorsDisplay errors={errors} />}</div>
    </>
  );
};

export default UploadedFileReportWithErrors;
