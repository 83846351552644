//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React, { Component } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Icon, Message } from "semantic-ui-react";
import { ImportDataInstructions as ApiImportDataInstructions } from "../../api/lib";
import { HelpUrl } from "../../HelpUrl";
import { TextLink } from "../../ui/lib";
import { CopyTextButton } from "../../util/CopyTextButton";
import { ArangoimportTabPage } from "./ImportDataInstructions";

const CodeContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  pre {
    flex-grow: 1;
  }
  button {
    margin-left: 2em !important;
    flex-grow: 0;
  }
`;

const InstructionContainer = styled("div")`
  margin-top: 1.5em;
`;

interface IIArangoimportInstructionsViewArgs {
  instructions?: ApiImportDataInstructions;
  isPrivateEndpoint?: boolean;
  onClickClientTools: () => void;
}

const ArangoimportInstructionsJSONView = ({ onClickClientTools, instructions, isPrivateEndpoint }: IIArangoimportInstructionsViewArgs) => {
  if (instructions && instructions.arango_import_json) {
    const import_json = instructions.arango_import_json ? instructions.arango_import_json.join("\n") : undefined;
    const example_data = `{ "name" : { "first" : "John", "last" : "Connor" }, "active" : true, "age" : 25, "likes" : [ "swimming"] }
{ "name" : { "first" : "Jim", "last" : "O'Brady" }, "age" : 19, "likes" : [ "hiking", "singing" ] }
{ "name" : { "first" : "Lisa", "last" : "Jones" }, "dob" : "1981-04-09", "likes" : [ "running" ] }`;
    return (
      <div>
        <InstructionContainer>
          <p className="para">Import pre-existing data in JSON format using arangoimport.</p>
          <h3 className="heading-3">Step 1</h3>
          <p className="para">
            Download and install <TextLink onClick={onClickClientTools}>ArangoDB client tools</TextLink>.
          </p>
          <h3 className="heading-3">Step 2</h3>
          <p className="para">Example JSON data to import:</p>
          <CodeContainer>
            <SyntaxHighlighter languages="json">{example_data}</SyntaxHighlighter>
            <CopyTextButton textToCopy={example_data || ""} />
          </CodeContainer>
          <h3 className="heading-3">Step 3</h3>
          <p className="para">Import data into this ArangoDB instance on ArangoGraph Insights Platform.</p>
          {isPrivateEndpoint && (
            <Message>
              <Icon name="info" />
              For private endpoint deployments, make sure the endpoint can be reached by your arangostore machine
            </Message>
          )}
          <CodeContainer>
            <SyntaxHighlighter languages="bash">{import_json}</SyntaxHighlighter>
            <CopyTextButton textToCopy={import_json || ""} />
          </CodeContainer>
          <p className="para">
            For all CLI options, consult the{" "}
            <a className="text-link" target="_blank" href={HelpUrl.arango_import_cli_options_page} rel="noreferrer">
              <b>arangoimport</b> documentation
            </a>
            .
          </p>
        </InstructionContainer>
      </div>
    );
  }
  return <div />;
};

const ArangoimportInstructionsCSVView = ({ onClickClientTools, instructions, isPrivateEndpoint }: IIArangoimportInstructionsViewArgs) => {
  if (instructions && instructions.arango_import_csv) {
    const import_csv = instructions.arango_import_csv ? instructions.arango_import_csv.join("\n") : undefined;
    const csv_data = `Identifier;First name;Last name
901242;Rachel;Booker
207074;Laura;Grey
408129;Craig;Johnson
934600;Mary;Jenkins
507916;Jamie;Smith`;
    return (
      <div>
        <InstructionContainer>
          <p className="para">Import pre-existing data in CSV format using arangoimport.</p>
          <h3 className="heading-3">Step 1</h3>
          <p className="para">
            Download and install <TextLink onClick={onClickClientTools}>ArangoDB client tools</TextLink>.
          </p>
          <h3 className="heading-3">Step 2</h3>
          <p className="para">Example CSV data to import:</p>
          <CodeContainer>
            <SyntaxHighlighter languages="csv">{csv_data}</SyntaxHighlighter>
            <CopyTextButton textToCopy={csv_data || ""} />
          </CodeContainer>
          <h3 className="heading-3">Step 3</h3>
          <p className="para">Import data into this ArangoDB instance on ArangoGraph Insights Platform.</p>
          {isPrivateEndpoint && (
            <Message>
              <Icon name="info" />
              For private endpoint deployments, make sure the endpoint can be reached by your arangostore machine
            </Message>
          )}
          <CodeContainer>
            <SyntaxHighlighter languages="bash">{import_csv}</SyntaxHighlighter>
            <CopyTextButton textToCopy={import_csv || ""} />
          </CodeContainer>
          <p className="para">
            For all CLI options, consult the{" "}
            <a className="text-link" target="_blank" href={HelpUrl.arango_import_cli_options_page} rel="noreferrer">
              <b>arangoimport</b> documentation
            </a>
            .
          </p>
        </InstructionContainer>
      </div>
    );
  }
  return <div />;
};

const ArangoimportInstructionsTSVView = ({ onClickClientTools, instructions, isPrivateEndpoint }: IIArangoimportInstructionsViewArgs) => {
  if (instructions && instructions.arango_import_tsv) {
    const import_tsv = instructions.arango_import_tsv ? instructions.arango_import_tsv.join("\n") : undefined;
    const import_data = `Sepal length	Sepal width	Petal length	Petal width	Species
5.1	3.5	1.4	0.2	I. setosa
4.9	3.0	1.4	0.2	I. setosa
4.7	3.2	1.3	0.2	I. setosa
4.6	3.1	1.5	0.2	I. setosa
5.0	3.6	1.4	0.2	I. setosa`;
    return (
      <div>
        <InstructionContainer>
          <p className="para">Import pre-existing data in TSV format using arangoimport.</p>
          <h3 className="heading-3">Step 1</h3>
          <p className="para">
            Download and install <TextLink onClick={onClickClientTools}>ArangoDB client tools</TextLink>.
          </p>
          <h3 className="heading-3">Step 2</h3>
          <p className="para">Example TSV data to import:</p>
          <CodeContainer>
            <SyntaxHighlighter languages="bash">{import_data}</SyntaxHighlighter>
            <CopyTextButton textToCopy={import_data || ""} />
          </CodeContainer>
          <h3 className="heading-3">Step 3</h3>
          <p className="para">Import data into this ArangoDB instance on ArangoGraph Insights Platform.</p>
          {isPrivateEndpoint && (
            <Message>
              <Icon name="info" />
              For private endpoint deployments, make sure the endpoint can be reached by your arangostore machine
            </Message>
          )}
          <CodeContainer>
            <SyntaxHighlighter languages="bash">{import_tsv}</SyntaxHighlighter>
            <CopyTextButton textToCopy={import_tsv || ""} />
          </CodeContainer>
          <p className="para">
            For all CLI options, consult the{" "}
            <a className="text-link" target="_blank" href={HelpUrl.arango_import_cli_options_page} rel="noreferrer">
              <b>arangoimport</b> documentation
            </a>
            .
          </p>
        </InstructionContainer>
      </div>
    );
  }
  return <div />;
};

interface IArangorimportInstructionsProps {
  deploymentId: string;
  disabled?: boolean;
  view: ArangoimportTabPage;
  instructions?: ApiImportDataInstructions;
  isPrivateEndpoint?: boolean;
  onClickClientTools: () => void;
}

class ArangorimportInstructions extends Component<IArangorimportInstructionsProps> {
  render() {
    switch (this.props.view) {
      case ArangoimportTabPage.ArangoImportJSON:
        return <ArangoimportInstructionsJSONView {...this.props} />;
      case ArangoimportTabPage.ArangoImportCSV:
        return <ArangoimportInstructionsCSVView {...this.props} />;
      case ArangoimportTabPage.ArangoImportTSV:
        return <ArangoimportInstructionsTSVView {...this.props} />;
      default:
        break;
    }
  }
}

export default ArangorimportInstructions;
