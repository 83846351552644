//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export type TopMenuImages =
  | "backup-policies"
  | "dashboard"
  | "user"
  | "organization-invites"
  | "organization-invite"
  | "organizations"
  | "organization"
  | "projects"
  | "project"
  | "members"
  | "groups"
  | "group"
  | "roles"
  | "role"
  | "deployments"
  | "deployment"
  | "certificates"
  | "certificate"
  | "iamprovider"
  | "ipallowlist"
  | "ipallowlists"
  | "role-bindings"
  | "role-binding"
  | "events";

function topMenuImageToTitlePrefix(image: TopMenuImages) {
  switch (image) {
    case "user":
      return "User";
    case "organization-invites":
    case "organization-invite":
    case "organizations":
    case "organization":
      return "Organization";
    case "project":
      return "Project";
    case "deployments":
    case "deployment":
      return "Deployment";
    case "certificates":
    case "certificate":
      return "Certificate";
    case "ipallowlist":
    case "ipallowlists":
      return "IP Allowlist";
    case "iamprovider":
      return "IAM Provider";

    case "members":
    case "groups":
    case "group":
    case "roles":
    case "role":
    case "backup-policies":
    case "dashboard":
    case "projects":
    case "role-bindings":
    case "role-binding":
    case "events":
    default:
      return "ArangoGraph Insights Platform";
  }
}

export class TopMenuInfo {
  private refreshView: () => void;
  private locationPathName = "";
  private breadCrumb = Array<BreadCrumbItem>();
  private imageSource?: TopMenuImages;
  private titlePrefix = "";
  private title = "";
  private subTitle = "";
  private saveTitles?: (title: string, subTitle: string) => Promise<void>;
  private backButton?: TopMenuButtonInfo;
  private isDisabled = false;

  constructor(refreshView: () => void) {
    this.refreshView = refreshView;
  }

  public reset(locationPathName: string) {
    if (this.locationPathName != locationPathName) {
      this.locationPathName = locationPathName;
      this.breadCrumb = Array<BreadCrumbItem>();
      this.imageSource = undefined;
      this.titlePrefix = "";
      this.title = "";
      this.subTitle = "";
      this.saveTitles = undefined;
      this.backButton = undefined;
      this.refreshView();
    }
  }

  setBreadCrumbItems(items: BreadCrumbItem[]) {
    if (TopMenuInfo.isDifferent(this.breadCrumb, items)) {
      this.breadCrumb = items;
      this.refreshView();
    }
  }

  static isDifferent(items1: BreadCrumbItem[], items2: BreadCrumbItem[]) {
    if (items1.length != items2.length) {
      return true;
    }

    for (let i = 0; i < items1.length; i++) {
      const i1 = items1[i];
      const i2 = items2[i];
      if (i1.getText() != i2.getText()) {
        return true;
      }
    }

    return false;
  }

  public disable() {
    if (!this.isDisabled) {
      this.isDisabled = true;
      this.refreshView();
    }
  }

  public enable() {
    if (this.isDisabled) {
      this.isDisabled = false;
      this.refreshView();
    }
  }

  public getIsDisabled() {
    return this.isDisabled;
  }

  clearBreadCrumb() {
    if (this.breadCrumb.length > 0) {
      this.breadCrumb = Array<BreadCrumbItem>();
      this.refreshView();
    }
  }

  public getBreadCrumb() {
    return this.breadCrumb;
  }

  public setImageSource(src: TopMenuImages) {
    if (this.imageSource != src) {
      this.imageSource = src;
      this.refreshView();
    }
  }

  public getImageSource() {
    return this.imageSource;
  }

  public setTitles(title: string, subTitle: string, save?: (title: string, subTitle: string) => Promise<void>) {
    if (this.title != title || this.subTitle != subTitle || save != this.saveTitles) {
      this.title = title;
      this.subTitle = subTitle;
      this.saveTitles = save;
      this.refreshView();
    }
  }

  public getTitle() {
    return this.title;
  }

  public getSaveTitles() {
    return this.saveTitles;
  }

  public getTitlePrefix() {
    if (this.imageSource) {
      return topMenuImageToTitlePrefix(this.imageSource);
    }
    return "";
  }

  public getSubTitle() {
    return this.subTitle;
  }

  public setBackButton(button?: TopMenuButtonInfo) {
    const previous = this.backButton;
    this.backButton = button;
    this.buttonRefreshView(previous, button);
  }

  public getBackButton(): TopMenuButtonInfo | undefined {
    return this.backButton;
  }

  private buttonRefreshView(previous?: TopMenuButtonInfo, current?: TopMenuButtonInfo) {
    if ((previous && !current) || (current && current.isDifferent(previous))) {
      this.refreshView();
    }
  }
}

export class TopMenuButtonInfo {
  private text: string;
  private onClicked: () => void;
  private disable: boolean = false;

  constructor(text: string, onClicked: () => void, disable?: boolean) {
    this.text = text;
    this.onClicked = onClicked;
    this.disable = !!disable;
  }

  public isDifferent(other?: TopMenuButtonInfo): boolean {
    if (!other) return true;

    if (this.text != other.text) return true;
    if (this.disable != other.disable) return true;

    return false;
  }

  public getText() {
    return this.text;
  }

  public getOnClicked() {
    return this.onClicked;
  }

  public getDisable() {
    return this.disable;
  }
}

export class BreadCrumbItem {
  private text: string;
  private to?: string;

  constructor(text: string, to?: string) {
    this.text = text;
    this.to = to;
  }

  public getText() {
    return this.text;
  }

  public getTo() {
    return this.to;
  }
}
