//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import React from "react";
import { useFetchDeploymentMigrationStatus } from "./useFetchDeploymentMigrationStatus";

export const DeploymentMigrationStatusMessage = () => {
  const { data: migrationStatus } = useFetchDeploymentMigrationStatus();
  if (!migrationStatus || !migrationStatus.status || !migrationStatus.status.phase) {
    return null;
  }

  if (migrationStatus.status.phase.toLowerCase() === "error" || migrationStatus.status.phase.toLowerCase() === "failed") {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle marginRight={2}>Upgrade failed</AlertTitle>
        <AlertDescription>There was an error while upgrading the deployment.</AlertDescription>
      </Alert>
    );
  }
  if (
    migrationStatus.status.phase.toLowerCase() !== "error" &&
    migrationStatus.status.phase.toLowerCase() !== "failed" &&
    migrationStatus.status.phase.toLowerCase() !== "completed"
  ) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertTitle marginRight={2}>Upgrade in progress</AlertTitle>
        <AlertDescription>
          This free deployment is being upgraded to a paid one, and will be automatically deleted after the upgrade. No changes are allowed during this period.
        </AlertDescription>
      </Alert>
    );
  }
  return null;
};
