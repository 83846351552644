//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment, { Duration } from "moment";
import styled from "@emotion/styled";
import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dimmer, Icon, Dropdown, SemanticICONS, Loader, Menu, Modal, Popup } from "semantic-ui-react";
import { GetDeploymentLogsRequest as ApiGetDeploymentLogsRequest } from "../../api/lib";
import apiclients from "../../api/apiclients";
import { ContentActionButtonServerLogs } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import ReactJson from "react18-json-view";
import { VariableSizeGrid } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import saveFile from "save-as-file";

const LogsContainer = styled("div")`
  min-height: 60vh;
`;

const LogsCell = styled("div")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inherit;
`;

interface IPeriod {
  id: string;
  startAt?: Duration;
  endAt?: Duration;
  title: string;
}

const periods = [
  { id: "default", startAt: moment.duration(1, "h"), endAt: undefined, title: "Last hour" },
  { id: "2h", startAt: moment.duration(2, "h"), endAt: undefined, title: "Last 2 hours" },
  { id: "3h", startAt: moment.duration(3, "h"), endAt: undefined, title: "Last 3 hours" },
  { id: "6h", startAt: moment.duration(6, "h"), endAt: undefined, title: "Last 6 hours" },
  { id: "12h", startAt: moment.duration(12, "h"), endAt: undefined, title: "Last 12 hours" },
  { id: "24h", startAt: moment.duration(24, "h"), endAt: undefined, title: "Last 24 hours" },
] as IPeriod[];

const durationDeltaAsTimestamp = (input?: Duration) => {
  if (!input) {
    return undefined;
  }
  return moment().subtract(input).toDate();
};

interface IRole {
  id: string;
  title: string;
}

const roles = [
  { id: "all", title: "All" },
  { id: "agents", title: "Agents" },
  { id: "coordinators", title: "Coordinators" },
  { id: "dbservers", title: "DBServers" },
] as IRole[];

interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

interface ILogMessage {
  message?: string;
  role?: string;
  time?: string;
  instance?: string;
  stream?: string;
  level?: string;
}

interface ILogsModalViewArgs {
  open: boolean;
  loading: boolean;
  logs?: string[];
  onClose: () => void;
  onRefresh: () => void;
  onSave: () => void;
  hasRoles: boolean;

  selectedPeriodId: string;
  onPeriodFilterChanged: (id: string) => void;

  selectedRoleId: string;
  onRoleFilterChanged: (id: string) => void;
}

interface ICellArgs {
  columnIndex: number;
  rowIndex: number;
  style: any;
}

const LogsModalView = ({ ...args }: ILogsModalViewArgs) => {
  const empty = _.isEmpty(args.logs);
  const logs = args.logs || [];

  const getOptionText = (options: IDropdownOption[], value: string) => {
    const item = _.find(options, (x) => x.value == value);
    return item && item.text;
  };

  const periodOptions = periods.map((x, i) => {
    return {
      key: `period-${x.id || ""}`,
      text: x.title,
      value: x.id,
    };
  });

  const roleOptions = roles.map((x, i) => {
    return {
      key: `role-${x.id || ""}`,
      text: x.title,
      value: x.id,
    };
  });

  const formatTime = (input?: string) => {
    if (_.isEmpty(input)) {
      return "";
    }
    const ts = moment(input);
    const d = ts.diff(moment(), "h");
    if (d < 24) {
      return ts.local().format("H:mm:ss.SSS");
    }
    return ts.fromNow();
  };

  const levelIcon = (level?: string): SemanticICONS => {
    switch (level) {
      case "INFO":
        return "info";
      case "FATAL":
        return "warning sign";
      case "ERROR":
        return "warning sign";
      case "WARNING":
      case "WARN":
        return "warning";
      case "DEBUG":
        return "bug";
      case "TRACE":
        return "dna";
      default:
        return "info";
    }
  };

  const parseLine = (line: string) => {
    try {
      return JSON.parse(line) as ILogMessage;
    } catch (e) {
      return {} as ILogMessage;
    }
  };

  const Cell = (rargs: ICellArgs) => {
    const x = parseLine(logs[logs.length - (1 + rargs.rowIndex)]);
    switch (rargs.columnIndex) {
      case 0:
        return <LogsCell style={rargs.style}>{formatTime(x.time)}</LogsCell>;
      case 1:
        return (
          <LogsCell style={rargs.style}>
            <Popup
              hoverable
              wide="very"
              on="click"
              trigger={<Icon name={levelIcon(x.level)} />}
              content={
                <span>
                  <ReactJson src={x} theme="vscode" displaySize />
                </span>
              }
            />
          </LogsCell>
        );
      case 2:
        return (
          <LogsCell style={rargs.style}>
            <Popup wide="very" position="top left" on="click" trigger={<div>{x.message || ""}</div>} content={<div>{x.message || ""}</div>} />
          </LogsCell>
        );
      default:
        return <LogsCell style={rargs.style}>{x.instance || ""}</LogsCell>;
    }
  };

  return (
    <Modal open={args.open} onClose={args.onClose} size="fullscreen">
      <Modal.Header>
        <Menu borderless pointing stackable>
          <Menu.Item header>Filter</Menu.Item>
          <Dropdown
            key="filter-period"
            item
            value={args.selectedPeriodId}
            text={`Period: ${getOptionText(periodOptions, args.selectedPeriodId) || "?"}`}
            options={periodOptions}
            onChange={(e, d) => args.onPeriodFilterChanged(d.value as string)}
          />
          {args.hasRoles && (
            <Dropdown
              key="filter-role"
              item
              value={args.selectedRoleId}
              text={`Server role: ${getOptionText(roleOptions, args.selectedRoleId) || "?"}`}
              options={roleOptions}
              onChange={(e, d) => args.onRoleFilterChanged(d.value as string)}
            />
          )}
          <Menu.Menu position="right">
            <Menu.Item icon="refresh" content="Refresh" onClick={args.onRefresh} key="refresh" />
            <Menu.Item icon="save" content="Save" onClick={args.onSave} key="save" />
          </Menu.Menu>
        </Menu>
      </Modal.Header>
      <Modal.Content>
        <LogsContainer>
          {args.loading && (
            <Dimmer inverted active>
              <Loader active inverted>
                Fetching logs, please wait
              </Loader>
            </Dimmer>
          )}
          {!args.loading && !empty && (
            <AutoSizer>
              {(sz: Size) => {
                const tableWidth = sz.width;
                const tsWidth = 128;
                const iconWidth = 24;
                const remaining = tableWidth - (tsWidth + iconWidth);
                const colWidths = [tsWidth, iconWidth, remaining * 0.8, remaining * 0.2];
                return (
                  <VariableSizeGrid
                    height={sz.height}
                    columnCount={4}
                    columnWidth={(i) => colWidths[i]}
                    rowCount={logs.length}
                    rowHeight={() => 35}
                    width={tableWidth}
                  >
                    {Cell}
                  </VariableSizeGrid>
                );
              }}
            </AutoSizer>
          )}
          {!args.loading && empty && (
            <span>
              <i>No data</i>
            </span>
          )}
        </LogsContainer>
      </Modal.Content>
    </Modal>
  );
};

interface ILogsModalProps extends IWithRefreshProps, RouteComponentProps {
  deploymentId: string;
  hasRoles: boolean;
}

interface ILogsModalState {
  open: boolean;
  loading: boolean;
  logs?: string[];
  selectedPeriodId: string;
  selectedRoleId: string;
  errorMessage?: string;
}

// Component to show deployment logs
class LogsModal extends Component<ILogsModalProps, ILogsModalState> {
  state = {
    open: false,
    loading: false,
    logs: undefined,
    selectedPeriodId: "default",
    selectedRoleId: "all",
    errorMessage: undefined,
  } as ILogsModalState;

  reloadLogs = async () => {
    console.log("reloadLogs");
    this.setState({ loading: true, errorMessage: undefined, logs: undefined });
    try {
      const req = {
        deployment_id: this.props.deploymentId,
        format: "json",
      } as ApiGetDeploymentLogsRequest;
      const period = _.find(periods, (p) => p.id == this.state.selectedPeriodId);
      const role = _.find(roles, (r) => r.id == this.state.selectedRoleId);
      const query = {} as any;
      if (!!period) {
        query["start_at"] = period.startAt;
        query["end_at"] = period.endAt;
        req.start_at = durationDeltaAsTimestamp(period.startAt);
        req.end_at = durationDeltaAsTimestamp(period.endAt);
      }
      if (this.props.hasRoles && !!role && role.id != "all") {
        query["role"] = role.id;
        req.role = role.id;
      }
      let list = [] as string[];
      const stream = await apiclients.monitoringClient.GetDeploymentLogs(req, (msg) => {
        if (msg.error) {
          this.setState({ errorMessage: msg.error });
        } else {
          const lmsg = msg.message || {};
          const chunk = lmsg.chunk;
          if (!!chunk) {
            const decoded = atob(chunk);
            const lines = _.filter(decoded.split("\n"), (x) => !_.isEmpty(x));
            list = _.concat(list, lines);
            this.setState({ logs: list });
          }
        }
      });
      await stream.closed;
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, logs: undefined, errorMessage: e });
    }
  };

  onOpen = () => {
    if (!this.state.open) {
      this.setState({ open: true }, this.reloadLogs);
    }
  };

  onClose = () => {
    console.log("onClose");
    this.setState({ open: false });
  };

  onPeriodFilterChanged = (id: string) => {
    this.setState({ selectedPeriodId: id }, this.reloadLogs);
  };

  onRoleFilterChanged = (id: string) => {
    this.setState({ selectedRoleId: id }, this.reloadLogs);
  };

  onRefresh = () => {
    this.reloadLogs();
  };

  onSave = () => {
    const fname = `${this.props.deploymentId}.log`;
    const logs = this.state.logs || [];
    const content = logs.join("\n");
    const file = new File([content], fname, { type: "text/plain" });
    saveFile(file, fname);
  };

  render() {
    return (
      <span>
        <ContentActionButtonServerLogs primary onClick={this.onOpen} />
        <LogsModalView {...this.props} {...this.state} {...this} />
      </span>
    );
  }
}

export default withRefresh()(LogsModal);
