//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import "./index.css";
import "rc-slider/assets/index.css";
import { MakeMainRoutes } from "./mainRoutes";
import * as Sentry from "@sentry/react";
import "./translation/i18n";
import { createRoot } from "react-dom/client";

const routes = MakeMainRoutes();

Sentry.init({
  dsn: window.SENTRY_DSN,
  environment: window.ENVIRONMENT,
  release: window.RELEASE,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    "TypeError: Cancelled",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Failed to fetch",
    "Unauthorized",
    "Invalid token",
    "token has invalid claims",
    "token is expired",
    "https://js.hs-analytics.net",
  ],
  integrations: [new Sentry.BrowserTracing()],
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(routes);
