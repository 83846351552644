//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Table } from "semantic-ui-react";
import { OrganizationInviteList as ApiOrganizationInviteList, OrganizationInvite as ApiOrganizationInvite } from "../../api/resourcemanager/v1/resourcemanager";
import { Loading } from "../../ui/lib";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

// Interface describing a pending invite
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Created By</Table.HeaderCell>
      <Table.HeaderCell>Organization Name</Table.HeaderCell>
      <Table.HeaderCell>Accepted</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing a pending invite
interface IRowView {
  item: ApiOrganizationInvite;
}

const RowView = ({ item }: IRowView) => (
  <Table.Row>
    <Table.Cell>{moment(item.created_at).fromNow()}</Table.Cell>
    <Table.Cell>{item.created_by_name}</Table.Cell>
    <Table.Cell>{item.organization_name}</Table.Cell>
    <Table.Cell>{moment(item.accepted_at).fromNow()}</Table.Cell>
  </Table.Row>
);

// Interface describing the pending invites
interface IListView {
  items: ApiOrganizationInvite[];
  loading: boolean;
}

const ListView = ({ items, loading }: IListView) => (
  <Table striped>
    <HeaderView loading={loading} />
    <Table.Body>
      {items.map((item) => (
        <RowView key={item.id} item={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No accepted organization invites</div>;

// Interface describing the pending invites view arguments
export interface IAcceptedOrganizationInviteListViewArgs extends RouteComponentProps {
  organizationInvites?: ApiOrganizationInviteList;
  loading: boolean;
}

export const AcceptedOrganizationInviteListView = ({ ...args }: IAcceptedOrganizationInviteListViewArgs) => {
  if (!args.organizationInvites) {
    return <Loading />;
  }
  const acceptedItems = (args.organizationInvites.items || []).filter((x) => x.accepted);
  if (!acceptedItems.length) {
    return <EmptyView />;
  }
  return <ListView items={acceptedItems} loading={args.loading} />;
};
