//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";

interface PolicyContextType {
  resourceUrl: string | undefined;
}

export const PolicyContext = React.createContext<PolicyContextType>({} as any);

export const usePolicyContext = () => React.useContext(PolicyContext);
