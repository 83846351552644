import React from "react";
import { Grid, Container } from "semantic-ui-react";
import screenshot from "./fully-managed.png";
import { Box } from "../../ui/_box";
import rlb from "./right-low-block.png";
import { useBreakpointValue } from "@chakra-ui/react";

const FullyManagedInfoSection = () => {
  const showOnDesktop = useBreakpointValue({ base: false, md: true }); // Show on medium screens (desktops) and above

  return (
    <Box position="relative">
      <Box position="absolute" right={0} top={0} zIndex={"-1"}>
        <img src={rlb} alt="bgBackgroundBlog" height={400} />
      </Box>
      <Box padding={"60px 0 60px 0"}>
        <Container>
          <Grid columns="16" stackable padded relaxed tablet={8} mobile={12}>
            <Grid.Row centered>
              <Grid.Column computer={8} tablet={14} mobile={16}>
                {showOnDesktop && (
                  <Box position="relative" top={"20px"}>
                    <img src={screenshot} alt="App Screenshot" width={"80%"} />
                  </Box>
                )}
              </Grid.Column>
              <Grid.Column computer={8} tablet={14} mobile={16} textAlign="left" verticalAlign="middle">
                <Box fontSize={"3rem"} color="var(--green-900)" lineheight={1.5} fontWeight={700}>
                  Fully Managed & Supported
                </Box>
                <Box fontSize={"1.4rem"} fontWeight={400} lineheight={1.3} padding={"16px 0 0 0"}>
                  Experience the power and fractional TCO of a fully-managed cloud database, providing seamless native support for multiple data models,
                  including graph, document, search, and key-value. Unleash the potential of diverse data in a single, fully-managed database deployment. Choose
                  from a variety of support plans to meet your needs.
                </Box>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
export default FullyManagedInfoSection;
