//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { useDocumentTitle } from "./useDocumentTitle";

export const DocumentTitle = ({ title, children }: { title: string; children: React.ReactElement }) => {
  useDocumentTitle(title);
  return children;
};
