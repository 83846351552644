//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Header } from "semantic-ui-react";
import { StyledTransparentSegment } from "../../ui/_style";

const AboutPrivateNetwork = () => {
  return (
    <>
      <Header>What is a Private Endpoint?</Header>
      <StyledTransparentSegment className="line-height-1-5">
        By using a Private Endpoint you can ensure that all traffic between your deployment running on ArangoGraph and your clients running inside Microsoft
        Azure will remain inside the Azure network using a private endpoint "tunnel".
      </StyledTransparentSegment>
      <StyledTransparentSegment className="line-height-1-5">
        <p className="line-height-1-5">
          Once you configure a Private Endpoint for your deployment, the public endpoint to your deployment (using a URL like{" "}
          <i>https://1234567.arangodb.com:8529</i>) will be removed after two hours.
        </p>
        <p className="line-height-1-5">This means that all communication with your deployment has to come from a network address inside your VNet.</p>
      </StyledTransparentSegment>
      <Header>How to configure a Private Endpoint?</Header>
      <StyledTransparentSegment className="line-height-1-5">
        <p className="line-height-1-5">To configure a Private Endpoint, you must provide the ID's of one or more of your Azure Subscriptions.</p>
        <p className="line-height-1-5">
          The ArangoGraph Insights Platform will configure a Private Endpoint Service that will automatically connect to Private Endpoints that are created in
          those subscriptions.
        </p>
        <p className="line-height-1-5">
          After creation of the Private Endpoint Service, you will receive an <b>alias</b> that you need during the creation of your Private Endpoint(s).
        </p>
      </StyledTransparentSegment>
      <StyledTransparentSegment>With that alias, you can create Private Endpoints from your Microsoft Azure portal.</StyledTransparentSegment>
    </>
  );
};

export default AboutPrivateNetwork;
