import React from "react";
import { Redirect, Route, RouteComponentProps, useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { ErrorMessage } from "../../ui/_errorMessage";
import { Processing } from "../../ui/_processing";
import { ContentSegment, MainContent } from "../../ui/_style";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useGlobalStore } from "../../util/storage/GobalStore";
import { useDashboardContext } from "../DashboardContextProvider";
import CreateIAMProvider from "../iamprovider/CreateIAMProvider";
import IAMProviderDetails from "../iamprovider/IAMProviderDetails";
import UpdateIAMProvider from "../iamprovider/UpdateIAMProvider";
import { DeletedProjectMessage } from "../project/DeletedProjectMessage";
import { ProjectDetailsContextProvider, useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { ProjectDeploymentsView } from "../project/ProjectDeploymentsView";
import { ProjectPoliciesView } from "../project/ProjectPoliciesView";
import { ProjectSecurityView } from "../project/ProjectSecurityView";
import { ProjectAuditLogView } from "../auditlog/ProjectAuditLogView";
import { ProjectDangerZoneView } from "../project/ProjectDangerZone";
import { DeploymentRoutes } from "../deployment/DeploymentRoutes";
import { Confirm } from "../../ui/lib";
import { NewDeploymentPage } from "../newDeployment/NewDeploymentPage";
import { CreateProjectRoleBindings } from "../project/policy/CreateProjectRoleBindings";

const RedirectProjectHashRoutes = (props: RouteComponentProps) => {
  const hash = props.location.hash;
  switch (hash) {
    case "#security":
      return <Redirect to={Routes.dashboard_project_securityWithId((props.match.params as any).projectId)} />;
    case "#auditlog":
      return <Redirect to={Routes.dashboard_project_auditlogWithId((props.match.params as any).projectId)} />;
    case "#policies":
      return <Redirect to={Routes.dashboard_project_policyWithId((props.match.params as any).projectId)} />;
    case "#dangerzone":
      return <Redirect to={Routes.dashboard_project_dangerzoneWithId((props.match.params as any).projectId)} />;
    case "#deploymentList":
    default:
      return <Redirect to={Routes.dashboard_project_deploymentsWithId((props.match.params as any).projectId)} />;
  }
};
export const ProjectRoutes = ({ onProjectDeleted }: { onProjectDeleted: () => void }) => {
  return (
    <ProjectDetailsContextProvider onProjectDeleted={onProjectDeleted}>
      <ProjectRoutesInner onProjectDeleted={onProjectDeleted} />
    </ProjectDetailsContextProvider>
  );
};

const ProjectRoutesInner = ({ onProjectDeleted }: { onProjectDeleted: () => void }) => {
  const history = useHistory();
  const { project } = useGlobalStore();
  const { selectedOrganization } = useDashboardContext();
  const { refetchDeployments } = useProjectDetailsContext();
  const handlers = {
    onCACertificateDeleted: () => {
      history.replace(Routes.dashboard_project_cacertificatesWithId(project.id || ""));
    },
    onCACertificateUpdated: () => {
      history.replace(Routes.dashboard_project_cacertificatesWithId(project.id || ""));
    },
    onNewCACertificateCreated: () => {
      history.replace(Routes.dashboard_project_cacertificatesWithId(project.id || ""));
    },
    onNewDeploymentCreated: (id: string) => {
      history.replace(Routes.dashboard_project_deployment_detailsWithId(project.id || "", id));
      refetchDeployments();
    },
    onNewIPAllowlistCreated: () => {
      history.replace(Routes.dashboard_project_ipallowlistsWithId(project.id || ""));
    },
    onIPAllowlistDeleted: () => {
      history.replace(Routes.dashboard_project_ipallowlistsWithId(project.id || ""));
    },
    onIPAllowlistUpdated: () => {
      history.replace(Routes.dashboard_project_ipallowlistsWithId(project.id || ""));
    },
    onNewIAMProviderCreated: () => {
      history.replace(Routes.dashboard_project_iamprovidersWithId(project.id || ""));
    },
    onIAMProviderDeleted: () => {
      history.replace(Routes.dashboard_project_iamprovidersWithId(project.id || ""));
    },
    onIAMProviderUpdated: () => {
      history.replace(Routes.dashboard_project_iamprovidersWithId(project.id || ""));
    },
    onProjectDeleted: onProjectDeleted,
    onProjectUpdated: (id: string) => {
      history.replace(Routes.dashboard_project_detailsWithId(project.id || ""));
    },
  };
  const { topMenuInfo } = useDashboardContext();
  const { eventSubscriptionManager, permissionCache } = useWithRefresh();
  const { confirmInfo, processingDelete, processingResumeDeployment, processingLockProject, processingUnlockProject, errorMessage, handleDismissError } =
    useProjectDetailsContext();

  const isTopMenuDisabled = topMenuInfo.getIsDisabled();
  return (
    <div>
      <ContentSegment
        paddingTop={isTopMenuDisabled ? "0px !important" : undefined}
        paddingLeft={isTopMenuDisabled ? "0px !important" : undefined}
        paddingRight={isTopMenuDisabled ? "0px !important" : undefined}
      >
        <Confirm confirmInfo={confirmInfo} />
        <Processing active={processingDelete} message="Deleting project, please wait..." />
        <Processing active={processingResumeDeployment} message="Resuming deployment, please wait..." />
        <Processing active={processingLockProject} message="Locking project, please wait..." />
        <Processing active={processingUnlockProject} message="Unlocking project, please wait..." />
        <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />

        <DeletedProjectMessage />
        <MainContent marginTop={topMenuInfo.getIsDisabled() ? "0px" : undefined}>
          <Route exact path={Routes.dashboard_project} render={() => <Redirect to={Routes.dashboard_project_detailsWithId(project.id || "")} />} />
          <Route exact path={Routes.dashboard_project_details} render={(props) => <RedirectProjectHashRoutes {...props} />} />
          <Route exact path={Routes.dashboard_project_deployments} render={(props) => <RedirectProjectHashRoutes {...props} />} />

          <Route
            exact
            path={Routes.dashboard_project_cacertificates}
            render={() => <Redirect to={Routes.dashboard_project_securityWithId(project.id || "")} />}
          />
          <Route
            exact
            path={Routes.dashboard_project_ipallowlists}
            render={() => <Redirect to={Routes.dashboard_project_securityWithId(project.id || "")} />}
          />
          <Route
            exact
            path={Routes.dashboard_project_iamproviders}
            render={() => <Redirect to={Routes.dashboard_project_securityWithId(project.id || "")} />}
          />
          <Route exact path={Routes.dashboard_project_deployments} render={() => <ProjectDeploymentsView />} />

          <Route exact path={Routes.dashboard_project_security} render={() => <ProjectSecurityView />} />

          <Route exact path={Routes.dashboard_project_auditlog} render={() => <ProjectAuditLogView />} />

          <Route exact path={Routes.dashboard_project_dangerzone} render={() => <ProjectDangerZoneView />} />

          <Route exact path={Routes.dashboard_project_policy} render={() => <ProjectPoliciesView />} />
          <Route path={Routes.dashboard_project_policy_create} render={(props) => <CreateProjectRoleBindings />} />
          <Route path={Routes.dashboard_project_deployments_create} render={() => <NewDeploymentPage />} />
          <Route
            path={Routes.dashboard_project_iamproviders_create}
            render={(props) => <CreateIAMProvider organization={selectedOrganization} topMenuInfo={topMenuInfo} {...props} {...handlers} project={project} />}
          />
          <Route
            path={Routes.dashboard_project_iamprovider_details}
            render={(props) => (
              <IAMProviderDetails
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                organization={selectedOrganization}
                topMenuInfo={topMenuInfo}
                {...props}
                {...handlers}
                loading={false}
                project={project}
              />
            )}
          />
          <Route
            path={Routes.dashboard_project_iamprovider_edit}
            render={(props) => (
              <UpdateIAMProvider
                eventSubscriptionManager={eventSubscriptionManager}
                permissionCache={permissionCache}
                organization={selectedOrganization}
                topMenuInfo={topMenuInfo}
                {...props}
                {...handlers}
                loading={false}
                project={project}
              />
            )}
          />

          {/* this route will handle everything in deployment/...  */}
          <Route path={Routes.dashboard_project_deployment} render={(props) => <DeploymentRoutes {...props} />} />
        </MainContent>
      </ContentSegment>
    </div>
  );
};
