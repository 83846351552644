//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDisableTopMenu } from "../../../components/useDisableTopMenu";
import { Routes } from "../../../routes";
import { CreateRoleBindingSection } from "../../policy/CreateRoleBindingSection";
import { PolicyContextProvider } from "../../policy/PolicyContextProvider";
import { useProjectDetailsContext } from "../ProjectDetailsContextProvider";
import { CreateProjectRoleBindingsTopMenu } from "./CreateProjectRoleBindingsTopMenu";

export const CreateProjectRoleBindings = () => {
  useDisableTopMenu();
  const { project } = useProjectDetailsContext();
  const history = useHistory();
  return (
    <PolicyContextProvider resourceUrl={project.url}>
      <Grid templateRows={"auto minmax(0, 1fr)"} overflow="hidden" height="100vh">
        <CreateProjectRoleBindingsTopMenu />
        <CreateRoleBindingSection
          onSuccess={() => {
            history.push(Routes.dashboard_project_policyWithId(project.id || ""));
          }}
          onCancel={() => {
            history.push(Routes.dashboard_project_policyWithId(project.id || ""));
          }}
        />
      </Grid>
    </PolicyContextProvider>
  );
};
