//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Button, DropdownProps, Form, Table, InputOnChangeData, Modal, Segment } from "semantic-ui-react";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { RouteComponentProps } from "react-router-dom";
import { APIKeySecret, CreateAPIKeyRequest, OrganizationList as ApiOrganizationList } from "../../api/lib";
import apiClients from "../../api/apiclients";
import { ErrorMessage, Processing, FormActionButtonCancel, FormActionButtonCreate } from "../../ui/lib";
import { hoursToDuration } from "../../api/googleTypes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash";
import { reportError } from "../../errors/reporting";

interface ICreateApiKeyViewArgs extends ICreateApiKeyState {
  onClose: () => void;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  toggleReadOnly: () => void;
  createApiKey: () => void;
  organizations?: ApiOrganizationList;
  handleFormDropdownChange: (e: any, args: DropdownProps) => void;
  selectedOrganization: string;
  ttlIsValid: boolean;
}

const CreateApiKeyView = ({ ...args }: ICreateApiKeyViewArgs) => {
  const organizationsList = args.organizations || {};
  const organizations = organizationsList.items || [];
  const organizationItems = _.orderBy(organizations, "name").map((o) => {
    return { key: o.id, text: o.name, value: o.id };
  });
  organizationItems.unshift({
    key: "",
    text: "No limit on organization",
    value: "",
  });

  return (
    <Modal open>
      <Modal.Header>Your new API key</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Processing active={args.processing} message="Creating API key, please wait..." />
          <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
          <Form>
            <Form.Dropdown
              label="Limit API key to this specific organization"
              placeholder="No limit on organization"
              name="organizations"
              value={args.selectedOrganization}
              onChange={args.handleFormDropdownChange}
              options={organizationItems}
            />
            <Form.Input
              label="Time to live"
              placeholder="Time to live in hours"
              name="ttl"
              value={args.ttl}
              onChange={args.updateFormInputChange}
              error={!args.ttlIsValid}
            />
            <Form.Checkbox toggle label="Limit API key to read-only APIs" onChange={args.toggleReadOnly} checked={args.readOnly} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate primary disabled={args.processing} onClick={args.createApiKey} />
        <FormActionButtonCancel onClick={args.onClose} />
      </Modal.Actions>
    </Modal>
  );
};

interface IApiKeyModalViewArgs {
  apiKeySecret: APIKeySecret;
  onClose: () => void;
  copiedKeyID: boolean;
  onCopiedKeyID: () => void;
  copiedKeySecret: boolean;
  onCopiedKeySecret: () => void;
}

const ApiKeyModalView = ({ ...args }: IApiKeyModalViewArgs) => {
  return (
    <Modal size="small" open>
      <Modal.Header>Your new API key</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="para">A new API key has been created for you.</p>
          <p className="para">
            Make sure to store this information in a safe place.
            <br />
            <strong>The API key secret is displayed only here and now.</strong>
            <br />
            You cannot retrieve it afterward.
          </p>
          <Segment>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>API key ID</Table.Cell>
                  <Table.Cell className="mask-data">{args.apiKeySecret.id}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <CopyToClipboard text={args.apiKeySecret.id} onCopy={args.onCopiedKeyID}>
                      <Button icon={args.copiedKeyID ? "checkmark" : "copy"} size="tiny" />
                    </CopyToClipboard>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>API key Secret</Table.Cell>
                  <Table.Cell className="mask-data">{args.apiKeySecret.secret}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <CopyToClipboard text={args.apiKeySecret.secret} onCopy={args.onCopiedKeySecret}>
                      <Button icon={args.copiedKeySecret ? "checkmark" : "copy"} size="tiny" />
                    </CopyToClipboard>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={args.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

// Interface decribing the properties of the CreateOrganization component
interface ICreateApiKeyProps extends IWithRefreshProps, RouteComponentProps {
  organizations?: ApiOrganizationList;
  onClose: () => void;
}

// Interface decribing the state of the CreateOrganization component
interface ICreateApiKeyState {
  errorMessage?: string;
  processing: boolean;
  ttl: string; // hours as number or empty string
  readOnly: boolean;
  apiKeySecret?: APIKeySecret;
  selectedOrganization: string;
  copiedKeyID: boolean;
  copiedKeySecret: boolean;
}

class CreateApiKey extends Component<ICreateApiKeyProps, ICreateApiKeyState> {
  state = {
    errorMessage: undefined,
    processing: false,
    ttl: "",
    readOnly: false,
    apiKeySecret: undefined,
    selectedOrganization: "",
    copiedKeyID: false,
    copiedKeySecret: false,
  } as ICreateApiKeyState;

  handleFormDropdownChange = (e: any, args: DropdownProps) => {
    if (args.name === "organizations") {
      const selectedOrganization = args.value as string;
      this.setState({ selectedOrganization: selectedOrganization });
    }
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    if (args.name === "ttl") {
      this.setState({ ttl: args.value });
    }
  };

  toggleReadOnly = () => {
    this.setState((state: ICreateApiKeyState) => {
      return { readOnly: !state.readOnly };
    });
  };

  createApiKey = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const req = {
        organization_id: this.state.selectedOrganization,
        readonly: this.state.readOnly,
      } as CreateAPIKeyRequest;
      if (this.state.ttl !== "") {
        req.time_to_live = hoursToDuration(parseInt(this.state.ttl));
      }
      const apiKeySecret = await apiClients.iamClient.CreateAPIKey(req);
      this.setState({ apiKeySecret: apiKeySecret });
    } catch (e) {
      this.setState({ errorMessage: `API key creation failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCopiedKeyID = () => {
    this.setState(
      {
        copiedKeyID: true,
        copiedKeySecret: false,
      },
      () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.setState({ copiedKeyID: false });
          }, 3000);
      }
    );
  };

  onCopiedKeySecret = () => {
    this.setState(
      {
        copiedKeyID: false,
        copiedKeySecret: true,
      },
      () => {
        const setTimeout = this.props.setTimeout;
        setTimeout &&
          setTimeout(() => {
            this.setState({ copiedKeySecret: false });
          }, 3000);
      }
    );
  };

  onResetAndClose = () => {
    this.setState(
      {
        errorMessage: undefined,
        processing: false,
        ttl: "",
        readOnly: false,
        apiKeySecret: undefined,
        selectedOrganization: "",
        copiedKeyID: false,
        copiedKeySecret: false,
      },
      this.props.onClose
    );
  };

  ttlIsValid = () => {
    const ttl = this.state.ttl;
    return ttl == "" || !isNaN(parseInt(ttl));
  };

  render() {
    if (this.state.apiKeySecret) {
      return (
        <ApiKeyModalView
          {...this.state}
          apiKeySecret={this.state.apiKeySecret}
          onClose={this.onResetAndClose}
          onCopiedKeyID={this.onCopiedKeyID}
          onCopiedKeySecret={this.onCopiedKeySecret}
        />
      );
    }
    return (
      <CreateApiKeyView
        {...this.props}
        {...this.state}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        toggleReadOnly={this.toggleReadOnly}
        createApiKey={this.createApiKey}
        handleFormDropdownChange={this.handleFormDropdownChange}
        ttlIsValid={this.ttlIsValid()}
      />
    );
  }
}

export default CreateApiKey;
