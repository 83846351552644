//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Checkbox, Icon, Label, Popup, Statistic, Table } from "semantic-ui-react";
import {
  CPUSize as ApiCPUSize,
  Deployment_ModelSpec as ApiDeployment_ModelSpec,
  Deployment_ServersSpec as ApiDeployment_ServersSpec,
  Deployment_ServerStatus as ApiDeployment_ServerStatus,
  Deployment_Status as ApiDeployment_Status,
  NodeSize as ApiNodeSize,
} from "../../api/lib";
import {
  ListActionRotate,
  Section,
  SectionAddons,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
  StatisticsContentGroup,
  StyledStatsSegment,
} from "../../ui/lib";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { DataVolumeView } from "./DataVolumeView";
import LogsModal from "./LogsModal";
import { CPUUsageView } from "./CPUUsageView";
import { MemoryUsageView } from "./MemoryUsageView";
import { useDeploymentPermissions } from "./useDeploymentPermissions";

const StateStatisticsContainer = styled("div")`
  padding-bottom: 24px;
`;

const ServersSummaryTableView = ({ ...args }: IServersStatusViewArgs) => {
  let list = (args.status || {}).servers || [];
  const serversSpec = args.servers || {};
  const model = args.model || {};
  const is_developer = model.model == "developer";
  const agents = is_developer ? 0 : 3;
  const expected = (serversSpec.coordinators || 0) + (serversSpec.dbservers || 0) + agents;
  let membersToCreate = Math.max(0, expected - _.size(list));
  let ok = _.size(_.filter(list, (x: ApiDeployment_ServerStatus) => x.ok));
  let creating = _.size(_.filter(list, (x: ApiDeployment_ServerStatus) => x.creating));
  let upgrading = _.size(_.filter(list, (x: ApiDeployment_ServerStatus) => x.upgrading));
  let failed = _.size(_.filter(list, (x: ApiDeployment_ServerStatus) => x.failed));
  if (args.isDeploymentPaused) {
    membersToCreate = 0;
    ok = expected;
    creating = 0;
    upgrading = 0;
    failed = 0;
  }
  return (
    <StateStatisticsContainer>
      <StyledStatsSegment>
        <StatisticsContentGroup>
          <Statistic.Group widths="4">
            <Statistic>
              <Statistic.Label>
                <Icon name="check" className="primary-text" /> OK
              </Statistic.Label>
              <Statistic.Value>{ok}</Statistic.Value>
            </Statistic>
            <Statistic>
              <Statistic.Label>
                <Icon name="spinner" className="secondary-text" loading={creating + membersToCreate > 0} /> Creating
              </Statistic.Label>
              <Statistic.Value>{creating + membersToCreate}</Statistic.Value>
            </Statistic>
            <Statistic>
              <Statistic.Label>
                <Icon name="sync alternate" className="secondary-text" loading={upgrading > 0} /> Upgrading
              </Statistic.Label>
              <Statistic.Value>{upgrading}</Statistic.Value>
            </Statistic>
            <Statistic>
              <Statistic.Label>
                <Icon name="exclamation triangle" className="red-text" /> Error
              </Statistic.Label>
              <Statistic.Value className={failed > 0 ? "red-text" : ""}>{failed}</Statistic.Value>
            </Statistic>
          </Statistic.Group>
        </StatisticsContentGroup>
      </StyledStatsSegment>
    </StateStatisticsContainer>
  );
};

const ServersStatusTableView = ({ ...args }: IServersStatusViewArgs) => {
  const list = (args.status || {}).servers || [];

  const { isRotateServerAllowed } = useDeploymentPermissions();

  if (list.length == 0) {
    return <span>None</span>;
  }
  const sortedList = _.orderBy(list, (x) => x.id);
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Version</Table.HeaderCell>
          <Table.HeaderCell>State</Table.HeaderCell>
          <Table.HeaderCell>Memory</Table.HeaderCell>
          <Table.HeaderCell>CPU</Table.HeaderCell>
          <Table.HeaderCell>Disk Used</Table.HeaderCell>
          <Table.HeaderCell>Created at</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedList.map((x) => (
          <ServerStatusView
            key={x.id}
            server_status={x}
            {...args}
            canRotateServer={isRotateServerAllowed}
            onRotateServer={() => args.onRotateServer(x.id || "")}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

interface IServerStatusViewArgs extends IWithRefreshProps, RouteComponentProps {
  isDeploymentPaused: boolean;
  server_status?: ApiDeployment_ServerStatus;
  canRotateServer: boolean;
  onRotateServer: () => void;
}

const ServerStatusView = ({ ...args }: IServerStatusViewArgs) => {
  const status = args.server_status || {};
  const isDeploymentPaused = args.isDeploymentPaused;
  return (
    <Table.Row>
      <Table.Cell>{status.id}</Table.Cell>
      <Table.Cell>{status.type}</Table.Cell>
      <Table.Cell>{status.version}</Table.Cell>
      <Table.Cell>
        {isDeploymentPaused && (
          <span>
            <Icon name="pause" className="primary-text" /> Paused
          </span>
        )}
        {!isDeploymentPaused && status.ok && (
          <span>
            <Icon name="check" className="primary-text" /> OK
          </span>
        )}
        {!isDeploymentPaused && status.upgrading && (
          <span>
            <Icon name="sync alternate" className="secondary-text" loading={true} /> Upgrading
          </span>
        )}
        {!isDeploymentPaused && status.creating && (
          <span>
            <Icon name="spinner" className="secondary-text" loading={true} /> Creating
          </span>
        )}
        {!isDeploymentPaused && status.failed && (
          <span>
            <Icon name="exclamation triangle" className="red-text" /> Failed
          </span>
        )}
        {!isDeploymentPaused && !!status.rotation_pending && (
          <span>
            <Label pointing="left" size="small">
              Rotation pending
            </Label>
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <MemoryUsageView used={status.last_memory_usage || 0} limit={status.last_memory_limit || 0} />
      </Table.Cell>
      <Table.Cell>
        <CPUUsageView used={status.last_cpu_usage || 0} limit={status.last_cpu_limit || 0} />
      </Table.Cell>
      <Table.Cell>{status.type == "Coordinator" ? "n/a" : <DataVolumeView data_volume_info={status.data_volume_info} />}</Table.Cell>
      <Table.Cell>
        <Popup
          wide="very"
          position="right center"
          trigger={<div>{moment(status.created_at).fromNow() || ""}</div>}
          content={
            <Table basic="very" singleLine compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Last restart:</Table.Cell>
                  <Table.Cell>{status.last_started_at ? moment(status.last_started_at).fromNow() : "-"}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Number of recent restarts:</Table.Cell>
                  <Table.Cell>{status.recent_restarts || 0}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Exact creation time:</Table.Cell>
                  <Table.Cell>{moment(status.created_at).toString()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Exact last restart time:</Table.Cell>
                  <Table.Cell>{status.last_started_at ? moment(status.last_started_at).toString() : "-"}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          }
        />
      </Table.Cell>
      <Table.Cell>
        {args.canRotateServer && (
          <ListActionRotate
            disabled={isDeploymentPaused || !!status.rotation_pending}
            onClick={args.onRotateServer}
            tooltip="Gracefully restart this server. Graceful restart means to shut down all services normally, allowing current operations to finish, and once safe, restart the server"
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

interface IServersStatusViewArgs extends IWithRefreshProps, RouteComponentProps {
  version?: string;
  custom_image?: string;
  status?: ApiDeployment_Status;
  model?: ApiDeployment_ModelSpec;
  node_size?: ApiNodeSize;
  cpu_size?: ApiCPUSize;
  isDeploymentPaused: boolean;
  onResumeDeployment: () => void;
  servers?: ApiDeployment_ServersSpec;
  deploymentId: string;
  processing: boolean;
  onEditDeployment: () => void;
  onRotateServer: (serverID: string) => void;
  expanded: boolean;
  onToggleExpand: () => void;
  hasRoles: boolean;
  isMonitoringFeatureAvailable: boolean;
}

export const ServersStatusView = ({ ...args }: IServersStatusViewArgs) => {
  return (
    <div>
      <div>
        <Section>
          <SectionHead>
            <SectionHeader title="Servers">
              <SectionAddons>
                <Checkbox toggle label="Expand" checked={args.expanded} onClick={args.onToggleExpand} />
              </SectionAddons>
            </SectionHeader>
            <SectionButtons>
              <LogsModal {...args} />
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            {!args.expanded && <ServersSummaryTableView {...args} />}
            {args.expanded && <ServersStatusTableView {...args} />}
          </SectionContent>
        </Section>
      </div>
    </div>
  );
};
