//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { User as ApiUser, Organization as ApiOrganization, Member as ApiMember } from "../../api/lib";
import { ListActionDelete, ListActionConvertToOwner, ListActionConvertToMember } from "../../ui/lib";
import moment from "moment";

// Interface describing an user
interface IOrganizationMemberView {
  active: boolean;
  organization: ApiOrganization;
  user: ApiUser;
  isOwner: boolean;
  canDelete: boolean;
  onClickDelete: (id: string, name: string) => void;
  canConvertToOwner: boolean;
  onConvertToOwner: (id: string, name: string) => void;
  canConvertToMember: boolean;
  onConvertToMember: (id: string, name: string) => void;
}

const OrganizationMemberView = ({ ...args }: IOrganizationMemberView) => (
  <Table.Row>
    <Table.Cell>{args.user.name}</Table.Cell>
    <Table.Cell>{args.user.given_name}</Table.Cell>
    <Table.Cell>{args.user.family_name}</Table.Cell>
    <Table.Cell>{args.user.email}</Table.Cell>
    <Table.Cell>{args.user.created_at ? moment(args.user.created_at).fromNow() : "-"}</Table.Cell>
    <Table.Cell>{args.isOwner ? "Yes" : "No"}</Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <div className="table-action-buttons">
        {args.isOwner && args.canConvertToMember && (
          <ListActionConvertToMember disabled={!args.active} onClick={() => args.onConvertToMember(args.user.id || "", args.user.name || "")} />
        )}
        {!args.isOwner && args.canConvertToOwner && (
          <ListActionConvertToOwner disabled={!args.active} onClick={() => args.onConvertToOwner(args.user.id || "", args.user.name || "")} />
        )}
        {args.canDelete && <ListActionDelete disabled={!args.active} onClick={() => args.onClickDelete(args.user.id || "", args.user.name || "")} />}
      </div>
    </Table.Cell>
  </Table.Row>
);

// Interface decribing the properties of the organization member component
interface IOrganizationMemberProps {
  organization: ApiOrganization;
  member: ApiMember;
  canDelete: boolean;
  onClickDelete: (id: string, name: string) => void;
  canConvertToOwner: boolean;
  onConvertToOwner: (id: string, name: string) => void;
  canConvertToMember: boolean;
  onConvertToMember: (id: string, name: string) => void;
}

// Interface decribing the state of the organization member component
interface IOrganizationMemberState {
  errorMessage?: string;
  processing: boolean;
}

// The component to show the group member inside an organization member list.
class OrganizationMember extends Component<IOrganizationMemberProps, IOrganizationMemberState> {
  state = {
    errorMessage: undefined,
    processing: false,
  } as IOrganizationMemberState;

  render() {
    const user = this.props.member.user || {};
    return <OrganizationMemberView {...this.props} active={!this.state.processing} isOwner={!!this.props.member.owner} user={user} />;
  }
}

export default OrganizationMember;
