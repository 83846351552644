//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { GetDeploymentMetricsRequest } from "../../../api/lib";
import apiClients from "../../../api/apiclients";
import { Timestamp } from "../../../api/googleTypes";
import moment from "moment";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";

export type METRICS_VALUES = "cpu" | "memory" | "disk";
export type SERVERS_VALUES = "Dbserver" | "Coordinator";
export const SINGLE_SERVER = "Single";
export const DATE_FORMAT = "MMM DD, YYYY HH:mm";

const useMetricsUsageAPI = () => {
  const { id: deploymentId = "" } = useDeploymentStore().deployment;

  const getDeploymentUsageMetrics = async ({
    startDate,
    endDate = new Date(moment().format(DATE_FORMAT)),
    metric,
    server,
  }: {
    startDate: Timestamp;
    endDate?: Timestamp;
    metric: METRICS_VALUES;
    server: SERVERS_VALUES | typeof SINGLE_SERVER;
  }) => {
    const req: GetDeploymentMetricsRequest = {
      deployment_id: deploymentId,
      metric_type: metric,
      server_type: server,
      start_at: new Date(moment(startDate).format(DATE_FORMAT)),
      end_at: endDate,
    };

    try {
      const { metrics = [] } = await apiClients.monitoringClient.GetDeploymentUsageMetrics(req);

      const data = metrics.map((timeSeries) => ({
        ...timeSeries,
        samples: timeSeries.samples?.map((sample) => ({ ...sample, value: (sample?.value || 0) * 100 })),
      }));
      return { data };
    } catch (error) {
      return { error };
    }
  };

  return {
    getDeploymentUsageMetrics,
  };
};

export { useMetricsUsageAPI };
