//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { OrganizationList as ApiOrganizationList, ProjectList as ApiProjectList, DeploymentList as ApiDeploymentList } from "../api/lib";

export class BudgetHelper {
  public static disableOrganizationCreate = (organizations?: ApiOrganizationList, processing?: boolean): boolean => {
    //Note: !orgs.budget.available: implies it's not there (default = 0), or available != 0 if it's there.
    return processing || !organizations || !organizations.budget || (!organizations.budget.unlimited && !organizations.budget.available);
  };

  public static disableProjectCreate = (projects?: ApiProjectList, processing?: boolean): boolean => {
    //Note: !orgs.budget.available: implies it's not there (default = 0), or available != 0 if it's there.
    return processing || !projects || !projects.budget || (!projects.budget.unlimited && !projects.budget.available);
  };

  public static disableDeploymentCreate = (deployments?: ApiDeploymentList, processing?: boolean): boolean => {
    //Note: !orgs.budget.available: implies it's not there (default = 0), or available != 0 if it's there.
    return processing || !deployments || !deployments.budget || (!deployments.budget.unlimited && !deployments.budget.available);
  };
}
