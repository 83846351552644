//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Popup, List, CheckboxProps, Progress } from "semantic-ui-react";
import { FileObject, ParsedFileReport, UploadedFileReport } from "../types";
import { useDropzone } from "react-dropzone";
import { StyledDropzoneArea } from "../files/Styles";
import FileDropInteractionView from "../files/FileDropInteractionView";
import { MAX_TOTAL_FILE_SIZE_LIMIT } from "../utils";
import { v4 } from "uuid";
import { StyledCheckbox, StyledFileHeader } from "../edit-panel/Styles";
import { useDataloaderStore } from "../DataloaderStore";
import { EXTRA_FIELD, useFileParser, parseFile, replaceParseErrorCode, describeParseErrorCode, getParsedFileReport } from "../hooks/useFileParser";
import styled from "@emotion/styled";
import { FlexBox } from "../../../../ui/_flex";
import { CollectionType } from "arangojs/collection";
import { getEdgeOptions, getNodeOptions } from "../utils/filesUpload.utils";
import ParsedFileWithErrors from "./ParsedFileWithErrors";

const StyledModalFileUploadArea = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  height: 70%;
  padding: 0 32px;
  overflow-y: auto;
`;

const StyledModalActions = styled(FlexBox)`
  margin: 16px;
  width: -webkit-fill-available;
  position: absolute;
  bottom: 0;
`;

const StyledModalDescription = styled(Modal.Description)`
  margin-left: 32px !important;
  padding-bottom: 16px !important;
`;

const StyledFlexBox = styled(FlexBox)`
  height: 100%;
  padding-bottom: 24px;
`;

interface IReplaceFileModalProps {
  isOpen: boolean;
  fileToReplace: UploadedFileReport;
  droppedFile: FileObject | undefined;
  checkedFields: string[];
  setDroppedFile: React.Dispatch<React.SetStateAction<FileObject | undefined>>;
  setCheckedFields: React.Dispatch<React.SetStateAction<string[]>>;
  onSave: () => void;
  onClose: () => void;
}

const ReuploadDropzoneArea = ({
  droppedFile,
  collectionName,
  previewErrors,
  setPreviewErrors,
  setDroppedFile,
}: {
  droppedFile: FileObject | undefined;
  previewErrors: string[];
  collectionName: string;
  setPreviewErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setDroppedFile: React.Dispatch<React.SetStateAction<FileObject | undefined>>;
}) => {
  const [dragError, setDragError] = useState<string>("");

  const { currentDatabase, nodeMappings, edgeMappings, nodes, edges } = useDataloaderStore().getDeploymentDataloaderState();
  const { collections, db } = currentDatabase;

  const { previewFileData } = useFileParser();

  const onFileAddition = async (files: File[]) => {
    if (files.length === 1) {
      if (!files[0].size) {
        setPreviewErrors([...previewErrors, "The file is empty"]);
      } else if (files[0].size > MAX_TOTAL_FILE_SIZE_LIMIT) {
        setPreviewErrors([...previewErrors, "The file dropped reached maximum total file size limit"]);
      }

      const { fields, errors } = await previewFileData(files[0]);

      if (!!errors.length) {
        setPreviewErrors([...previewErrors, "The file has some parsing errors"]);
      }

      const correspondingCollection = collections.find((collection) => collection.name === collectionName);
      const collectionType = correspondingCollection?.type;

      const options = { collectionName, collections, nodeMappings, edgeMappings, nodes, edges, db };

      if (collectionType === CollectionType.DOCUMENT_COLLECTION) {
        const { primaryKey } = getNodeOptions(options);

        const isPrimaryKeyPresentInPreview = Object.keys(fields).some((field) => field === primaryKey);

        if (!isPrimaryKeyPresentInPreview) {
          setPreviewErrors([...previewErrors, `Header "${primaryKey}" set as primary key at the modeled graph is not present at dropped file.`]);
        }
      } else if (collectionType === CollectionType.EDGE_COLLECTION) {
        const { sourceNodePrimaryKey, targetNodePrimaryKey } = getEdgeOptions(options);

        const newPreviewErrors: string[] = [];

        const isSourceKeyPresentInPreview = Object.keys(fields).some((field) => field === sourceNodePrimaryKey);
        const isTargetKeyPresentInPreview = Object.keys(fields).some((field) => field === targetNodePrimaryKey);

        if (!isSourceKeyPresentInPreview) {
          newPreviewErrors.push(`Header "${sourceNodePrimaryKey}" set as origin key at the modeled graph is not present at dropped file.`);
        }

        if (!isTargetKeyPresentInPreview) {
          newPreviewErrors.push(`Header "${targetNodePrimaryKey}" set as target key at the modeled graph is not present at dropped file.`);
        }
        setPreviewErrors([...previewErrors, ...newPreviewErrors]);
      }

      setDroppedFile({
        file: files[0],
        name: files[0].name,
        id: `file-to-replace-at-${collectionName}`,
        fields,
        errors,
      });
    }
  };

  const handleOnDragEnter = (e: React.DragEvent) => {
    if (e.dataTransfer.items.length > 1) {
      setDragError("You can drop just one file at a time");
    }
  };

  const handleOnDragLeave = (e: React.DragEvent) => setDragError("");

  const { getRootProps, getInputProps, isDragAccept, open, isDragReject, isDragActive } = useDropzone({
    noClick: true,
    accept: { "text/csv": [] },
    useFsAccessApi: true,
    minSize: 0,
    multiple: false,
    noDragEventsBubbling: false,
    onDragEnter: handleOnDragEnter,
    onDragLeave: handleOnDragLeave,
    onDrop: onFileAddition,
  });

  if (!!droppedFile) return <></>;

  return (
    <StyledDropzoneArea {...getRootProps({ isDragAccept, isDragReject, isDragActive })} style={{ height: "100%" }}>
      <input {...getInputProps()} />

      <FileDropInteractionView
        openFileDialog={open}
        hasFiles={!!droppedFile}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
        error={dragError}
        description="Please, drag and drop the CSV file you want to use as a replacement"
      />
    </StyledDropzoneArea>
  );
};

const ReuploadPreviewErrors = ({ previewErrors }: { previewErrors: string[] }) => {
  if (!previewErrors.length) return <></>;
  return (
    <StyledFlexBox direction="column" justify="space-between">
      <div>
        <Modal.Description>
          <b>The following errors were found when previewing the file:</b>
        </Modal.Description>
        <List bulleted>
          {previewErrors.map((error) => (
            <List.Item key={error}>{error}</List.Item>
          ))}
        </List>
      </div>
      <p className="para">Please, remove the dropped file and drop another one that meets the requirements.</p>
    </StyledFlexBox>
  );
};

const ReuploadFileBeingParsed = ({
  droppedFile,
  progress,
  droppedFileBeingParsed,
}: {
  droppedFile: FileObject | undefined;
  progress: number;
  droppedFileBeingParsed: boolean;
}) => {
  if (!droppedFile || !droppedFileBeingParsed) return <></>;

  return (
    <>
      <h2 className="heading-2">File validation in progress, please wait</h2>
      <h3 className="heading-3">Validating: {droppedFile.name}</h3>
      <Progress percent={progress} color="green" progress />
    </>
  );
};

const ReuploadFileParsingErrors = ({ parsedFileReport }: { parsedFileReport: ParsedFileReport | undefined }) => {
  if (!parsedFileReport?.errors.length) return <></>;

  return (
    <>
      <h2 className="heading-2">The file has some parsing errors:</h2>
      <p className="para">
        If you continue with the data import without addressing parsing errors, the rows with errors will be skipped and excluded from the import - it means
        documents from these rows will not be created in the target collection(s). Please proceed only if you are ok with skipping such rows, otherwise fix and
        upload the files again.
      </p>

      <ParsedFileWithErrors parsedFileReport={parsedFileReport} />
    </>
  );
};

const ReuploadFileFieldsSelectorDisplay = ({
  droppedFile,
  checkedFields,
  setCheckedFields,
  display,
}: {
  droppedFile: FileObject | undefined;
  checkedFields: string[];
  setCheckedFields: React.Dispatch<React.SetStateAction<string[]>>;
  display: boolean;
}) => {
  const handleCheckField = (key: string, checked?: boolean) => setCheckedFields(checked ? [...checkedFields, key] : checkedFields.filter((str) => str !== key));

  if (!display || !droppedFile) return <></>;

  return (
    <>
      {Object.keys(droppedFile.fields).map((key: string) => {
        if (key !== EXTRA_FIELD) {
          return (
            <StyledFileHeader justify="space-between" align="center" margin="10px 0" key={key} className="mask-data">
              <StyledCheckbox
                label={key}
                className="file-data-header"
                checked={checkedFields.includes(`${key}`)}
                onChange={(_: unknown, data: CheckboxProps) => {
                  const { checked } = data;
                  handleCheckField(key, checked);
                }}
              />

              <div className="file-data-value ellipsis" title={droppedFile.fields[key]}>
                {!!droppedFile.fields[key] ? droppedFile.fields[key] : "-"}
              </div>
            </StyledFileHeader>
          );
        }
        return null;
      })}
    </>
  );
};

const ReplaceFileModal = ({ isOpen, fileToReplace, droppedFile, checkedFields, setDroppedFile, setCheckedFields, onClose, onSave }: IReplaceFileModalProps) => {
  const { collectionName, name } = fileToReplace;
  const { currentDatabase, name: graphName } = useDataloaderStore().getDeploymentDataloaderState();
  const { graphs, collections, db } = currentDatabase;

  const [previewErrors, setPreviewErrors] = useState<string[]>([]);
  const [droppedFileBeingParsed, setDroppedFileBeingParsed] = useState<boolean>(false);
  const [parsedFileReport, setParsedFileReport] = useState<ParsedFileReport | undefined>(undefined);
  const [droppedFileParsingProgress, setDroppedFileParsingProgress] = useState<number>(0);

  const [preventSaveErrors, setPreventSaveErrors] = useState<string[]>([]);

  const graphExists = graphs.some((graph) => graph === graphName);
  const collectionExists = collections.some((collection) => collection.name === collectionName);
  const dbExists = !!db;

  const displayFieldsSelector = !!droppedFile?.fields && !previewErrors.length && !droppedFileBeingParsed && !parsedFileReport?.errors.length;

  const updateFileProgress = (_: FileObject, progress: number) => {
    setDroppedFileParsingProgress(progress);
  };

  const handleStartImport = async () => {
    if (!droppedFile) return;
    setDroppedFileBeingParsed(true);
    const parsingErrors = await parseFile(droppedFile, updateFileProgress);
    setDroppedFileBeingParsed(false);
    if (!parsingErrors.length) {
      onSave();
    } else {
      const transformedErrors = parsingErrors.map((error) => ({
        ...error,
        code: replaceParseErrorCode(error.code),
        description: describeParseErrorCode(error.code),
      }));
      const parsedFileReport = getParsedFileReport(droppedFile, transformedErrors);
      setParsedFileReport(parsedFileReport);
    }
  };

  const handleRemoveDroppedFile = () => {
    setDroppedFile(undefined);
    setPreventSaveErrors([]);
    setParsedFileReport(undefined);
    setPreviewErrors([]);
  };

  useEffect(() => {
    const newErrors: string[] = [];
    if (dbExists) {
      if (!graphExists) newErrors.push(`The graph ${graphName} is not available anymore`);
      if (!collectionExists) newErrors.push(`The collection ${collectionName} is not available anymore`);
    } else {
      newErrors.push("The database is not available anymore");
    }

    setPreventSaveErrors(newErrors);
  }, [graphExists, collectionExists, db]);

  return (
    <Modal open={isOpen} onClose={onClose} style={{ height: "60vh" }}>
      <Header>
        Replace the file {name} at collection {collectionName}
      </Header>

      {!!droppedFile && (
        <StyledModalDescription>
          <span>
            Uploaded file: <b>{droppedFile.name || ""}</b>
          </span>
        </StyledModalDescription>
      )}

      <StyledModalFileUploadArea>
        {!droppedFile ? (
          <ReuploadDropzoneArea
            droppedFile={droppedFile}
            collectionName={collectionName}
            previewErrors={previewErrors}
            setDroppedFile={setDroppedFile}
            setPreviewErrors={setPreviewErrors}
          />
        ) : (
          <>
            <ReuploadPreviewErrors previewErrors={previewErrors} />
            <ReuploadFileBeingParsed droppedFile={droppedFile} progress={droppedFileParsingProgress} droppedFileBeingParsed={droppedFileBeingParsed} />
            <ReuploadFileParsingErrors parsedFileReport={parsedFileReport} />
            <ReuploadFileFieldsSelectorDisplay
              checkedFields={checkedFields}
              droppedFile={droppedFile}
              setCheckedFields={setCheckedFields}
              display={displayFieldsSelector}
            />
          </>
        )}
      </StyledModalFileUploadArea>
      <StyledModalActions justify="center">
        {!!droppedFile ? (
          <>
            <Button onClick={handleRemoveDroppedFile} primary={!!previewErrors.length}>
              Remove dropped file
            </Button>
            {!previewErrors.length && !parsedFileReport && (
              <Popup
                position="top center"
                disabled={!preventSaveErrors.length}
                trigger={
                  <span>
                    <Button onClick={handleStartImport} disabled={!!preventSaveErrors.length} primary>
                      Save collection with new file
                    </Button>
                  </span>
                }
                content={
                  <List bulleted>
                    {preventSaveErrors.map((preventSaveError) => (
                      <List.Item key={v4()}>{preventSaveError}</List.Item>
                    ))}
                  </List>
                }
              />
            )}
            {!!parsedFileReport && (
              <Button onClick={onSave} primary>
                Ignore the issues and continue import
              </Button>
            )}
          </>
        ) : (
          <Button primary onClick={onClose}>
            Close
          </Button>
        )}
      </StyledModalActions>
    </Modal>
  );
};

export default ReplaceFileModal;
