//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React from "react";
import { Form, Grid, Table } from "semantic-ui-react";
import {
  ContentActionButtonNew,
  ListActionEdit,
  ListActionDelete,
  ListActionView,
  Section,
  SectionButtons,
  SectionContent,
  SectionHead,
  SectionHeader,
} from "../../ui/lib";
import { AuditLogTopic as ApiAuditLogTopic, AuditLog_Destination as ApiAuditLogDestination } from "../../api/lib";
import CreateAuditLogHttpDestinationModal from "./CreateAuditLogHttpDestinationModal";
import { EditExcludedTopicsView } from "./EditExcludedTopicsViews";
import EditAuditLogHttpDestinationModal from "./EditAuditLogHttpDestinationModal";

interface IEditAuditLogViewArgs {
  organization_id: string;
  auditlog_id?: string;
  has_cloud_destination: boolean;
  has_https_destination: boolean;
  readonly?: boolean;
  name: string;
  description: string;
  cloud: boolean;
  destinations: ApiAuditLogDestination[];
  excluded_cloud_topics: string[];
  all_topics: ApiAuditLogTopic[];
  show_price_warning: boolean;
  accept_price_increase: boolean;

  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onToggleCloud: () => void;
  onNewDestinationCreated: (value: ApiAuditLogDestination) => void;
  onExcludedCloudTopicsChanged: (value: string[]) => void;
  onExcludedTopicsChanged: (index: number, value: string[]) => void;
  onDeleteDestination: (index: number) => void;
  onDestinationUpdated: (index: number, value: ApiAuditLogDestination) => void;
  onToggleAccessPriceIncrease: () => void;
}

export const EditAuditLogView = ({ ...args }: IEditAuditLogViewArgs) => {
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEditIndex, setShowEditIndex] = React.useState(-1);
  const readonly = !!args.readonly;

  return (
    <div>
      {showCreate && (
        <CreateAuditLogHttpDestinationModal
          {...args}
          onClose={() => setShowCreate(false)}
          onNewDestinationCreated={(x) => {
            args.onNewDestinationCreated(x);
            setShowCreate(false);
          }}
        />
      )}
      {showEditIndex >= 0 && (
        <EditAuditLogHttpDestinationModal
          {...args}
          key={`edit_dest${showEditIndex}`}
          destination={args.destinations[showEditIndex]}
          onClose={() => setShowEditIndex(-1)}
          onDestinationUpdated={(x) => {
            args.onDestinationUpdated(showEditIndex, x);
            setShowEditIndex(-1);
          }}
        />
      )}
      <Section>
        <SectionHead>
          <SectionHeader title="General" />
        </SectionHead>
        <SectionContent>
          <Grid columns="16">
            <Grid.Row>
              <Grid.Column width={4}>
                <Form.Input
                  autoFocus
                  label="Name"
                  required={!readonly}
                  placeholder={`Name (e.g. "MyCompanyLog-${moment().year()}")`}
                  name="name"
                  value={args.name}
                  onChange={readonly ? undefined : (e, d) => args.onNameChanged(d.value)}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Form.Input
                  label="Short description"
                  placeholder="Description"
                  name="description"
                  value={args.description}
                  onChange={readonly ? undefined : (e, d) => args.onDescriptionChanged(d.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionHead>
          <SectionHeader title="Destinations" />
          {!readonly && args.has_https_destination && (
            <SectionButtons>
              <ContentActionButtonNew primary content="New HTTP destination" onClick={() => setShowCreate(true)} />
            </SectionButtons>
          )}
        </SectionHead>
        <SectionContent>
          <DestinationListView {...args} onEditDestination={(i) => setShowEditIndex(i)} />
        </SectionContent>
      </Section>
      {args.show_price_warning && (
        <Section>
          <SectionHead>
            <SectionHeader title="Confirmation" />
          </SectionHead>
          <SectionContent>
            <Form.Checkbox
              toggle
              checked={args.accept_price_increase}
              onClick={args.onToggleAccessPriceIncrease}
              label="I understand that adding an auditlog increases the cost of my deployments."
            />
          </SectionContent>
        </Section>
      )}
    </div>
  );
};

interface IDestinationListViewArgs {
  has_cloud_destination: boolean;
  readonly?: boolean;
  destinations: ApiAuditLogDestination[];
  cloud: boolean;
  excluded_cloud_topics: string[];
  all_topics: ApiAuditLogTopic[];

  onToggleCloud: () => void;
  onExcludedCloudTopicsChanged: (value: string[]) => void;
  onExcludedTopicsChanged: (index: number, value: string[]) => void;
  onEditDestination: (index: number) => void;
  onDeleteDestination: (index: number) => void;
}

const DestinationListView = ({ ...args }: IDestinationListViewArgs) => {
  const readonly = !!args.readonly;
  const has_cloud_destination = args.has_cloud_destination;

  return (
    <Table>
      <Table.Header>
        <Table.Row verticalAlign="top">
          <Table.HeaderCell width="2">Destination</Table.HeaderCell>
          <Table.HeaderCell width="6">Excluded topics</Table.HeaderCell>
          <Table.HeaderCell width="1">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Form.Checkbox
              checked={args.cloud}
              disabled={!has_cloud_destination}
              onClick={readonly ? undefined : args.onToggleCloud}
              label="Upload to cloud"
              toggle
            />
          </Table.Cell>
          <Table.Cell>
            <EditExcludedTopicsView
              {...args}
              disabled={!args.cloud}
              excluded_topics={args.excluded_cloud_topics}
              onExcludedTopicsChanged={args.onExcludedCloudTopicsChanged}
            />
          </Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
        {args.destinations.map((d, i) => {
          const httpPost = d.http_post || {};
          return (
            <Table.Row verticalAlign="top">
              <Table.Cell>{httpPost.url || "?"}</Table.Cell>
              <Table.Cell>
                <EditExcludedTopicsView
                  {...args}
                  excluded_topics={d.excluded_topics || []}
                  onExcludedTopicsChanged={(x) => args.onExcludedTopicsChanged(i, x)}
                />
              </Table.Cell>
              <Table.Cell textAlign="right" collapsing>
                <div className="table-action-buttons">
                  {!readonly && <ListActionEdit size="small" onClick={() => args.onEditDestination(i)} />}
                  {readonly && <ListActionView size="small" onClick={() => args.onEditDestination(i)} />}
                  {!readonly && <ListActionDelete size="small" onClick={() => args.onDeleteDestination(i)} />}
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
