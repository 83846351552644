//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Header, Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions } from "../../api/common/v1/common";
import { Group as ApiGroup } from "../../api/iam/v1/iam";
import { Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  CommonContentAction,
  CommonContentActions,
  CommonKeyValuePair,
  CommonKeyValuePairs,
  CommonKV_Key,
  CommonKV_Value,
  Confirm,
  ConfirmInfo,
  ContentActionButtonDelete,
  ContentActionButtonEdit,
  ContentSegment,
  ErrorMessage,
  Loading,
  MainContent,
  Processing,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import GroupMemberList from "./GroupMemberList";

interface IGroupDetailsViewArgs extends IWithRefreshProps, RouteComponentProps {
  group: ApiGroup;
}

const GroupDetailsView = ({ ...args }: IGroupDetailsViewArgs) => (
  <div>
    <CommonKeyValuePairs>
      <CommonKeyValuePair>
        <CommonKV_Key>Created</CommonKV_Key>
        <CommonKV_Value>{args.group.created_at ? moment(args.group.created_at).fromNow() : "-"}</CommonKV_Value>
      </CommonKeyValuePair>
      <CommonKeyValuePair>
        <CommonKV_Key>Is Virtual</CommonKV_Key>
        <CommonKV_Value>{args.group.is_virtual ? "Yes" : "No"}</CommonKV_Value>
      </CommonKeyValuePair>
      <CommonKeyValuePair>
        <CommonKV_Key>Assign New Members</CommonKV_Key>
        <CommonKV_Value>{args.group.is_default ? "Yes" : "No"}</CommonKV_Value>
      </CommonKeyValuePair>
      {args.group.is_deleted && (
        <CommonKeyValuePair>
          <CommonKV_Key>Deleting</CommonKV_Key>
          <CommonKV_Value>{args.group.deleted_at ? moment(args.group.deleted_at).fromNow() : "-"}</CommonKV_Value>
        </CommonKeyValuePair>
      )}
    </CommonKeyValuePairs>
    <MainContent>
      <Header sub>Users</Header>
      <GroupMemberList {...args} />
    </MainContent>
  </div>
);

// Interface decribing the properties of the group details component
interface IGroupDetailsProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onGroupDeleted: (groupId: string) => void;
}

// Interface decribing the state of the group details component
interface IGroupDetailsState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  groupId?: string;
  group?: ApiGroup;
}

class GroupDetails extends Component<IGroupDetailsProps, IGroupDetailsState> {
  state = {
    groupId: undefined,
    group: undefined,
  } as IGroupDetailsState;

  reloadGroupInfo = async () => {
    const idOptions = { id: this.state.groupId } as ApiIDOptions;
    const group = await apiClients.iamClient.GetGroup(idOptions);
    if (!this.state.group) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadGroupInfo, group.url);
    }
    this.setState({ group: group });
  };

  getGroupName = () => {
    const group = this.state.group;
    if (group) {
      return group.name;
    }
    return "";
  };

  onClickDelete = async () => {
    const groupName = this.getGroupName();
    const confirmInfo = {
      header: "Delete Group",
      content: `Are you sure you want to delete group '${groupName}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: this.state.groupId || "" } as ApiIDOptions;
      await apiClients.iamClient.DeleteGroup(idOptions);
      this.props.onGroupDeleted && this.props.onGroupDeleted(this.state.groupId || "");
    } catch (e) {
      this.setState({ errorMessage: `Group deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  componentDidMount() {
    const groupId = (this.props.match.params as any).groupId;
    this.setState(
      {
        groupId: groupId,
      },
      () => {
        //notice inside the reloadRoleInfo we use the groupId
        this.props.refreshNow && this.props.refreshNow(this.reloadGroupInfo);
        this.updateTopMenu();
      }
    );
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Groups", Routes.dashboard_organization_groupsWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("group");
    const group = this.state.group || {};
    this.props.topMenuInfo.setTitles(group.name || "", group.description || "");
  };

  render() {
    const group = this.state.group;
    if (group) {
      return (
        <ContentSegment>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <Processing active={this.state.processing} message="Deleting group, please wait..." />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <CommonContentActions>
            {!(this.state.group || {}).is_virtual && (
              <CommonContentAction>
                {this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(group.url || "", ResourceType.Group, "iam.group.update") && (
                  <ContentActionButtonEdit
                    primary
                    disabled={this.state.processing}
                    onClick={() => {
                      HistoryHelper.push(
                        this.props.history,
                        Routes.dashboard_organization_group_editWithId(this.props.organization.id || "", this.state.groupId || ""),
                        this.props.topMenuInfo.getTitle()
                      );
                    }}
                  />
                )}
              </CommonContentAction>
            )}
            <CommonContentAction>
              {this.props.hasPermissionByUrl && this.props.hasPermissionByUrl(group.url || "", ResourceType.Group, "iam.group.delete") && (
                <ContentActionButtonDelete disabled={this.state.processing} onClick={this.onClickDelete} />
              )}
            </CommonContentAction>
            <Loader size="mini" active={this.props.loading} inline />
          </CommonContentActions>
          <GroupDetailsView {...this.props} group={group} />
        </ContentSegment>
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(GroupDetails);
