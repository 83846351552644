//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { PolicyList } from "../../policy/PolicyList";
import { useProjectDetailsContext } from "../../project/ProjectDetailsContextProvider";

export const DeploymentPolicyList = () => {
  const { project } = useProjectDetailsContext();
  const { deployment } = useDeploymentStore();
  const isProjectDeleted = deployment?.is_deleted || false;
  const history = useHistory();

  const onCreatePolicyClick = () => {
    if (!deployment.id || !project.id) {
      return;
    }
    history.push(Routes.dashboard_project_deployment_policy_createWithId(project.id, deployment.id));
  };
  return (
    <PolicyList
      resourceUrl={deployment.url}
      onCreatePolicyClick={onCreatePolicyClick}
      isCreatePolicyDisabled={isProjectDeleted}
      tooltip={
        <Flex direction="column" gap="2">
          <Text>
            Policy defines which users are used allowed to perform certain operations on the deployment and resources belonging to the deployment such as
            backups.
          </Text>
          <Text>Note that the policy does not define the access rules within the deployment itself (e.g. who can read from a specific collection).</Text>
        </Flex>
      }
    />
  );
};
