//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Header, Icon, Message } from "semantic-ui-react";

const AWSPrivateNetworkConfirmationInfoView = () => {
  return (
    <>
      <Header data-testid="awsPrivateNetworkConfirmationInfoHeader">Please confirm that you want to use a Private Endpoint for your deployment.</Header>
      <Message icon>
        <Icon name="info" size="small" />
        <Message.Content data-testid="awsPrivateNetworkConfirmationInfoMessage">
          Note that once a deployment has a Private Endpoint connection, it cannot be converted back to a public endpoint, without help from the ArangoGraph
          Insights Platform support team.
        </Message.Content>
      </Message>
    </>
  );
};

export default AWSPrivateNetworkConfirmationInfoView;
