//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { IconWithTooltip, Section, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { SplitHeadersString } from "./Helpers";

interface IEditAuditLogHttpDestinationViewArgs {
  readonly?: boolean;
  url: string;
  trusted_server_ca_pem: string;
  client_certificate_pem: string;
  client_key_pem: string;
  headers: string;

  onURLChanged: (value: string) => void;
  onTrustedServerCAChanged: (value: string) => void;
  onClientCertificateChanged: (value: string) => void;
  onClientKeyChanged: (value: string) => void;
  onHeadersChanged: (value: string) => void;
}

export const EditAuditLogHttpDestinationView = ({ ...args }: IEditAuditLogHttpDestinationViewArgs) => {
  const readonly = !!args.readonly;
  const isInvalidURL = !_.isEmpty(args.url) && !args.url.match(/^https:\/\/.+/g);
  let headerError = undefined;
  try {
    SplitHeadersString(args.headers);
  } catch (e) {
    headerError = `${e}`;
  }
  return (
    <div>
      <Section>
        <SectionHead>
          <SectionHeader title="General" />
        </SectionHead>
        <SectionContent>
          <Grid columns="16">
            <Grid.Row>
              <Grid.Column width="16">
                <Form.Input
                  autoFocus
                  required={!readonly}
                  label={
                    <label>
                      URL <IconWithTooltip tooltip="Enter URL, you want audit events to be POST'ed to. This must have an 'https' scheme." />
                    </label>
                  }
                  placeholder={`URL (e.g. "https://myserver.com/auditlogs)`}
                  name="url"
                  value={args.url}
                  onChange={readonly ? undefined : (e, d) => args.onURLChanged(d.value)}
                  error={isInvalidURL}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="8">
                <Form.TextArea
                  label={
                    <label>
                      Server CA certificate{" "}
                      <IconWithTooltip tooltip="Enter the PEM encoded public key of your server certificate. Server certificate is used to validate the connection to your server. Without a server certificate, a well-known certificate is assumed." />
                    </label>
                  }
                  placeholder={`Server CA certificate`}
                  name="trusted_server_ca_pem"
                  value={args.trusted_server_ca_pem}
                  onChange={readonly ? undefined : (e, d) => args.onTrustedServerCAChanged(d.value as string)}
                  rows="8"
                />
              </Grid.Column>
              <Grid.Column width="8">
                <Form.TextArea
                  label={
                    <label>
                      HTTP headers
                      <IconWithTooltip tooltip="Enter all additional HTTP headers in the form of one pair of 'key=value' per line." />
                    </label>
                  }
                  placeholder={`key=value`}
                  name="headers"
                  value={args.headers}
                  onChange={readonly ? undefined : (e, d) => args.onHeadersChanged(d.value as string)}
                  error={!!headerError}
                  rows="8"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="8">
                <Form.TextArea
                  required={!readonly}
                  label={
                    <label>
                      Client certificate{" "}
                      <IconWithTooltip tooltip="Enter the PEM encoded public key of your client certificate. Client certificate is used for authentication." />
                    </label>
                  }
                  placeholder={`Client certificate (PEM)`}
                  name="client_certificate_pem"
                  value={args.client_certificate_pem}
                  onChange={readonly ? undefined : (e, d) => args.onClientCertificateChanged(d.value as string)}
                  rows="8"
                />
              </Grid.Column>
              <Grid.Column width="8">
                <Form.TextArea
                  required={!readonly}
                  label={
                    <label>
                      Client key{" "}
                      <IconWithTooltip tooltip="Enter the PEM encoded private key of your client certificate. Client certificate is used for authentication." />
                    </label>
                  }
                  placeholder={`Client key (PEM)`}
                  name="client_key_pem"
                  value={args.client_key_pem}
                  onChange={readonly ? undefined : (e, d) => args.onClientKeyChanged(d.value as string)}
                  rows="8"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SectionContent>
      </Section>
    </div>
  );
};
