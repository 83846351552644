//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
import styled from "@emotion/styled";
import React, { Component } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Icon, Message } from "semantic-ui-react";
import { ImportDataInstructions as ApiImportDataInstructions } from "../../api/lib";
import { HelpUrl } from "../../HelpUrl";
import { TextLink } from "../../ui/lib";
import { CopyTextButton } from "../../util/CopyTextButton";

const CodeContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  pre {
    flex-grow: 1;
  }
  button {
    margin-left: 2em !important;
    flex-grow: 0;
  }
`;

interface IIArangoimportInstructionsViewArgs {
  instructions?: ApiImportDataInstructions;
  isPrivateEndpoint?: boolean;
  onClickClientTools: () => void;
}

const ArangoimportInstructionsView = ({ onClickClientTools, instructions, isPrivateEndpoint }: IIArangoimportInstructionsViewArgs) => {
  if (instructions) {
    const export_dump = "arangodump --all-databases true \\\n  --output-directory dump\n  <other options for connecting to your local database>";
    const import_dump = instructions.import_dump ? instructions.import_dump.join("\n") : undefined;

    return (
      <div>
        <p className="para">Import data to ArangoGraph Insights Platform from an existing arangodb instance.</p>
        <h3 className="heading-3">Step 1</h3>
        <p className="para">
          Download and install <TextLink onClick={onClickClientTools}>ArangoDB client tools</TextLink>.
        </p>
        <h3 className="heading-3">Step 2</h3>
        <p className="para">Export data from existing ArangoDB instance using.</p>

        <CodeContainer>
          <SyntaxHighlighter languages="bash">{export_dump}</SyntaxHighlighter>
          <CopyTextButton textToCopy={export_dump || ""} />
        </CodeContainer>
        <p className="para">
          For all CLI options, consult the{" "}
          <a className="text-link" target="_blank" href={HelpUrl.arango_dump_cli_options_page} rel="noreferrer">
            <b>arangodump</b> documentation
          </a>
          .
        </p>
        <h3 className="heading-3">Step 3</h3>
        <p className="para">Import data into this ArangoDB instance on ArangoGraph Insights Platform.</p>
        {isPrivateEndpoint && (
          <Message>
            <Icon name="info" />
            For private endpoint deployments, make sure the endpoint can be reached by your arangostore machine
          </Message>
        )}
        <CodeContainer>
          <SyntaxHighlighter languages="bash">{import_dump}</SyntaxHighlighter>
          <CopyTextButton textToCopy={import_dump || ""} />
        </CodeContainer>
        <p className="para">
          For all CLI options, consult the{" "}
          <a className="text-link" target="_blank" href={HelpUrl.arango_restore_cli_options_page} rel="noreferrer">
            <b>arangorestore</b> documentation
          </a>
          .
        </p>
      </div>
    );
  }

  return <div />;
};

interface IArangorestoreInstructionsProps {
  disabled?: boolean;
  instructions?: ApiImportDataInstructions;
  isPrivateEndpoint?: boolean;
  onClickClientTools: () => void;
}

class ArangorestoreInstructions extends Component<IArangorestoreInstructionsProps> {
  render() {
    return <ArangoimportInstructionsView {...this.props} />;
  }
}

export default ArangorestoreInstructions;
