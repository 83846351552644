//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { numberFormat } from "humanize";
import React from "react";
import { Popup, Table } from "semantic-ui-react";
import humanize from "humanize";

const humanizeCPU = (value: number) => {
  if (value < 1.0) {
    return `${humanize.numberFormat(value * 1000, 0)}m`;
  } else {
    return humanize.numberFormat(value, 2);
  }
};

export interface ICPUUsageViewArgs {
  limit: number;
  used: number;
}

export const CPUUsageView = ({ ...args }: ICPUUsageViewArgs) => {
  if (args.limit == 0) {
    return <span>{humanizeCPU(args.used)}</span>;
  }
  return (
    <Popup
      position="right center"
      trigger={<span>{numberFormat(((args.used || 0) * 100) / (args.limit || 0), 1)}%</span>}
      content={
        <Table basic="very" singleLine compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeCPU(args.limit)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Used:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeCPU(args.used)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Available:</Table.Cell>
              <Table.Cell textAlign="right">{humanizeCPU(args.limit - args.used)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    />
  );
};
