//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { User as ApiUser } from "../../api/lib";
import { OrganizationInviteList as ApiOrganizationInviteList } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { ErrorMessage, Processing, Section, SectionContent, SectionHeader } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TopMenuInfo } from "../TopMenuInfo";
import { AcceptedOrganizationInviteListView } from "./AcceptedOrganizationInviteList";
import { PendingOrganizationInviteListView } from "./PendingOrganizationInviteList";
import { RejectedOrganizationInviteListView } from "./RejectedOrganizationInviteList";

// Interface describing the invites view arguments
interface IInvitesViewArgs extends IWithRefreshProps, RouteComponentProps {
  active: boolean;
  processingAccept: boolean;
  processingReject: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  loading: boolean;
  organizationInvites?: ApiOrganizationInviteList;
  onAccept: (id: string) => void;
  onReject: (id: string) => void;
}

const InvitesView = ({ ...args }: IInvitesViewArgs) => {
  return (
    <div>
      <Processing active={args.processingAccept} message="Accepting organization invite, please wait..." />
      <Processing active={args.processingReject} message="Rejecting organization invite, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <div>
        <Section>
          <SectionHeader title="My pending invites" />
          <SectionContent>
            <PendingOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader title="My accepted invites" />
          <SectionContent>
            <AcceptedOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
        <Section>
          <SectionHeader title="My rejected invites" />
          <SectionContent>
            <RejectedOrganizationInviteListView {...args} />
          </SectionContent>
        </Section>
      </div>
    </div>
  );
};

// Interface decribing the properties of the invites component
interface IOrganizationInvitesProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onOrganizationInviteAccepted: (id: string) => void;
  onOrganizationInviteRejected: (id: string) => void;
}

// Interface decribing the state of the invites component
interface IOrganizationInvitesState {
  user?: ApiUser;
  errorMessage?: string;
  processingAccept: boolean;
  processingReject: boolean;
  organizationInvites?: ApiOrganizationInviteList;
}

// The Invides component shows on the left a menu, where you can select 'pending' 'accepted and ' rejected, on the right a list.
class OrganizationInvites extends Component<IOrganizationInvitesProps, IOrganizationInvitesState> {
  state = {
    user: undefined,
    errorMessage: undefined,
    processingAccept: false,
    processingReject: false,
    organizationInvites: undefined,
  } as IOrganizationInvitesState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  reloadOrganizationInvites = async () => {
    const listOptions = {} as ApiListOptions;
    const organizationInvites = await apiClients.resourceManagerClient.ListMyOrganizationInvites(listOptions);
    this.setState({ organizationInvites: organizationInvites });
  };

  acceptInvite = async (id: string) => {
    try {
      this.setState({ processingAccept: true });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.resourceManagerClient.AcceptOrganizationInvite(idOptions);
      this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationInvites);
      this.props.onOrganizationInviteAccepted(id);
    } catch (e) {
      this.setState({ errorMessage: `Accept organization invite failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingAccept: false });
  };

  rejectInvite = async (id: string) => {
    try {
      this.setState({ processingReject: true });
      const idOptions = { id: id } as ApiIDOptions;
      await apiClients.resourceManagerClient.RejectOrganizationInvite(idOptions);
      this.props.refreshNow && this.props.refreshNow(this.reloadOrganizationInvites);
      this.props.onOrganizationInviteRejected(id);
    } catch (e) {
      this.setState({ errorMessage: `Reject organization invite failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingReject: false });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
    this.props.refreshWithTimer && this.props.refreshWithTimer(this.reloadOrganizationInvites, 60000);
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };
  render() {
    const organizationInvites = this.state.organizationInvites;

    return (
      <InvitesView
        {...this.props}
        {...this.state}
        active={!(this.state.processingAccept || this.state.processingReject)}
        handleDismissError={this.handleDismissError}
        organizationInvites={organizationInvites}
        onAccept={this.acceptInvite}
        onReject={this.rejectInvite}
      />
    );
  }
}

export default withRefresh()(OrganizationInvites);
