//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
export const isGroup = (memberId: string) => memberId.startsWith("group:");
export const isUser = (memberId: string) => memberId.startsWith("user:");

export const useFetchMemberInfo = ({ memberId }: { memberId: string }) => {
  const fetchMemberInfo = async () => {
    if (memberId) {
      if (isGroup(memberId)) {
        const groupId = memberId.substring(6);
        if (groupId) {
          return await apiClients.iamClient.GetGroup({ id: groupId });
        }
      }

      if (isUser(memberId)) {
        const userId = memberId.substring(5);
        if (userId) {
          return await apiClients.iamClient.GetUser({ id: userId });
        }
      }
    }
  };
  return useQuery(["memberInfo", memberId], fetchMemberInfo);
};
