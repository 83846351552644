export const getQueryParams = (): Record<string, string> => {
  let queryParams = window.location.search;
  const urlQueryParams = new URLSearchParams(`${queryParams}`);
  const params = Object.fromEntries(urlQueryParams.entries());
  return params;
};

export const validateQueryParamsForExternalAuth = (data: { callbackURL: string; resourceType: string; resourceID: string }): string => {
  if (!data.callbackURL) {
    return "Missing callback_url.";
  }
  if (!data.resourceID) {
    return "Missing resource_id.";
  }
  if (!data.resourceType) {
    return "Missing resource_type.";
  }
  return "";
};
