//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Timestamp } from "../../../api/googleTypes";
import { FlexBox } from "../../../ui/_flex";
import { RenderGuard } from "../../../util/RenderGuard";
import { DeploymentMetrics_Timeseries } from "../../../api/lib";
import { useMetricsUsageAPI, DATE_FORMAT, SINGLE_SERVER } from "./useMetricsUsageApi";
import TimeFrameSelector from "./TimeFrameSelector";
import MetricsSelector from "./MetricsSelector";
import MetricsChart from "./MetricsChart";
import { useDeploymentStore } from "../../../util/storage/DeploymentStore";
import { METRICS, RANGES } from "./MetricsView";

type SINGLE_SERVERS_MAP = "Singlecpu" | "Singlememory" | "Singledisk";

const SingleServerMetrics = ({
  loading,
  setError,
  setLoading,
}: {
  loading: boolean;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { id: deploymentId = "" } = useDeploymentStore().deployment;
  const [selectedMetric, setSelectedMetric] = useState(METRICS.CPU);
  const [startDate, setStartDate] = useState<Timestamp>(new Date(moment().subtract(10, "minutes").format(DATE_FORMAT)));
  const [selectedRange, setSelectedRange] = useState<number>(RANGES.MINUTES_10);

  const [singleserverCPUMetrics, setSingleserverCPUMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [singleserverMemoryMetrics, setSingleserverMemoryMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);
  const [singleserverDiskMetrics, setSingleserverDiskMetrics] = useState<DeploymentMetrics_Timeseries[]>([]);

  const { getDeploymentUsageMetrics } = useMetricsUsageAPI();

  const getMetrics = async (
    getter: () => Promise<{
      data?: DeploymentMetrics_Timeseries[];
      error?: undefined;
    }>,
    setter: React.Dispatch<React.SetStateAction<DeploymentMetrics_Timeseries[]>>
  ) => {
    const { data, error } = await getter();

    if (error) return setError(error);
    if (data) return setter(data);
  };

  const getAllMetrics = async () => {
    setError("");
    setLoading(true);

    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SINGLE_SERVER, metric: METRICS.CPU }), setSingleserverCPUMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SINGLE_SERVER, metric: METRICS.MEMORY }), setSingleserverMemoryMetrics);
    await getMetrics(() => getDeploymentUsageMetrics({ startDate, server: SINGLE_SERVER, metric: METRICS.DISK }), setSingleserverDiskMetrics);

    setLoading(false);
  };

  const singleserverMetricsMap: { [key in SINGLE_SERVERS_MAP]: { chartData: DeploymentMetrics_Timeseries[]; usageLabel: string } } = {
    Singlecpu: { chartData: singleserverCPUMetrics, usageLabel: "CPU Usage" },
    Singlememory: { chartData: singleserverMemoryMetrics, usageLabel: "Memory Usage" },
    Singledisk: { chartData: singleserverDiskMetrics, usageLabel: "Disk Usage" },
  };

  const selectedChart: SINGLE_SERVERS_MAP = `Single${selectedMetric}`;

  const chartData = singleserverMetricsMap[selectedChart].chartData;
  const usageLabel = singleserverMetricsMap[selectedChart].usageLabel;
  const serverId = chartData[0]?.server_id || "";

  useEffect(() => {
    if (deploymentId) {
      getAllMetrics();
    }
  }, [deploymentId, startDate]);

  return (
    <FlexBox direction="column" width="100%">
      <FlexBox justify="space-between">
        <MetricsSelector loading={loading} selectedServer={SINGLE_SERVER} selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} />
        <TimeFrameSelector setStartDate={setStartDate} loading={loading} onChangeRange={setSelectedRange} selectedRange={selectedRange} />
      </FlexBox>
      <RenderGuard renderIf={!loading}>
        <MetricsChart chartData={chartData} usageLabel={usageLabel} selectedServerIds={[serverId]} selectedRange={selectedRange} />
      </RenderGuard>
    </FlexBox>
  );
};

export default SingleServerMetrics;
