import moment from "moment";
import React from "react";
import { Message } from "semantic-ui-react";
import { useProjectDetailsContext } from "./ProjectDetailsContextProvider";

export const DeletedProjectMessage = () => {
  const { project } = useProjectDetailsContext();
  if (project?.is_deleted) {
    return (
      <div>
        <Message size="small">This project has been deleted {project.deleted_at ? moment(project.deleted_at).fromNow() : "-"}.</Message>
      </div>
    );
  }
  return null;
};
