//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Loader, Table } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions, GroupMemberList as ApiGroupMemberList, Group as ApiGroup, User as ApiUser } from "../../api/lib";
import { RouteComponentProps } from "react-router-dom";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Loading, LoaderBoxForTable as LoaderBox } from "../../ui/lib";
import GroupMember from "./GroupMember";

// Interface describing an user
interface IHeaderView {
  loading: boolean;
}

const HeaderView = ({ loading }: IHeaderView) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Given Name</Table.HeaderCell>
      <Table.HeaderCell>Family Name</Table.HeaderCell>
      <Table.HeaderCell>E-mail</Table.HeaderCell>
      <Table.HeaderCell>
        Created
        <LoaderBox>
          <Loader size="mini" active={loading} inline />
        </LoaderBox>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

// Interface describing the user list
interface IListView extends IGroupListProps {
  users: ApiUser[];
  loading: boolean;
}

const ListView = ({ ...args }: IListView) => (
  <Table striped>
    <HeaderView loading={args.loading} />
    <Table.Body>
      {args.users.map((item) => (
        <GroupMember {...args} key={item.id} user={item} />
      ))}
    </Table.Body>
  </Table>
);

const EmptyView = () => <div>No users inside this group</div>;

// Interface describing the group list view arguments
export interface IGroupMemberListViewArgs extends IGroupListProps {
  groupMembers?: ApiGroupMemberList;
}

export const GroupMemberListView = ({ ...args }: IGroupMemberListViewArgs) => {
  if (!args.groupMembers) {
    return <Loading />;
  }
  if (!args.groupMembers.users || args.groupMembers.users.length === 0) {
    return <EmptyView />;
  }
  return <ListView {...args} users={args.groupMembers.users || []} loading={args.loading} />;
};

// Interface decribing the properties of the group list component
interface IGroupListProps extends IWithRefreshProps, RouteComponentProps {
  group: ApiGroup;
}

// Interface decribing the state of the group list component
interface IGroupListState {
  groupMembers?: ApiGroupMemberList;
}

// The component to show the group members inside a group as a list.
class GroupMemberList extends Component<IGroupListProps, IGroupListState> {
  state = {
    groupMembers: undefined,
  } as IGroupListState;

  reloadGroupMembers = async () => {
    const listOptions = { context_id: this.props.group.id } as ApiListOptions;
    const groupMembers = await apiClients.iamClient.ListGroupMembers(listOptions);
    this.setState({ groupMembers: groupMembers });
  };

  componentDidMount() {
    this.props.subscribeUrl && this.props.subscribeUrl(this.reloadGroupMembers, `${this.props.group.url}/*`);
  }

  render() {
    const groupMembers = this.state.groupMembers;

    return <GroupMemberListView {...this.props} groupMembers={groupMembers} />;
  }
}

export default withRefresh()(GroupMemberList);
