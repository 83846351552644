//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Icon } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { FlexBox } from "../../../../ui/_flex";
import { FileMetadataArgs } from "../types";
import { StyledErrorLabel } from "./Styles";
import FileFields from "./FileFields";
import DeleteFileAction from "./DeleteFileAction";

const FileMetadataView = ({ file, hideDeleteButton }: FileMetadataArgs) => {
  const { fields, errors = [] } = file;

  if (isEmpty(fields))
    return (
      <div>
        <p className="para">No fields found</p>
        {!hideDeleteButton && <DeleteFileAction file={file} />}
      </div>
    );

  if (!isEmpty(fields) && errors.length) {
    return (
      <>
        <FlexBox justify="space-between" direction="column">
          {errors.map((error) => {
            const { code, row, description } = error;
            return (
              <StyledErrorLabel prompt basic key={`${code}-${row}`} className="mask-data">
                <>
                  <p className="red-text">
                    <Icon name="warning circle" color="red" />
                    {code} at row {row}.
                  </p>{" "}
                  <p className="para">{description}</p>
                </>
              </StyledErrorLabel>
            );
          })}
        </FlexBox>
        {!hideDeleteButton && <DeleteFileAction file={file} />}
      </>
    );
  }

  return (
    <>
      <div className={`${!file.file.size ? "opacity-60" : ""}`}>
        <FileFields fields={file.fields} />
      </div>
      <Divider hidden />
      {!hideDeleteButton && <DeleteFileAction file={file} />}
    </>
  );
};
export default FileMetadataView;
