//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";

export const StyledDataloaderView = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledHeader = styled.div`
  width: 100%;
  padding: 1rem;
  box-shadow: 0px 4px 3px -2px var(--gray-200);
  z-index: 000;
`;
export const GraphContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  height: 100%;
  width: 100%;
`;

export const MenuBar = styled.div`
  height: 100%;
  box-shadow: -1px 0px 3px -1px var(--gray-300);
  position: relative;
  width: 50px;

  & .menu-item {
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    font-size: 1.3rem;
  }

  & .menu-item:hover {
    background: var(--gray-100);
    cursor: pointer;
  }

  & .menu-item.active {
    color: var(--green-700);
  }
`;

export const StyledMenuContent = styled.div`
  width: calc(100% - 50px);
  height: 100%;
  position: relative;

  & .menu-content {
    display: none;
  }

  & .menu-content.active {
    display: block;
  }
`;

export const StyledDataloaderEmptyMessage = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.2rem;
  border: 1px dashed var(--gray-200);
  box-shadow: none;
  padding-left: 0;
  background-color: var(--gray-50);
  display: flex;
  align-items: center;
  justify-content: center;
`;
