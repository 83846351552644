import React from "react";
import { Section } from "../../ui/lib";
import { useDashboardContext } from "../DashboardContextProvider";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { DangerZoneView } from "./DangerZoneView";
import { useOrganizationDetailsContext } from "./OrganizationDetailsContextProvider";

export const OrganizationDangerZone = () => {
  const { selectedOrganization, isFreeTier } = useDashboardContext();
  const { onLockOrganization, onUnlockOrganization, onClickDelete, isProcessing } = useOrganizationDetailsContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const isUpdateAllowed = checkOrgPermission("resourcemanager.organization.update");
  const isDeleteAllowed = checkOrgPermission("resourcemanager.organization.delete");

  return (
    <Section>
      <DangerZoneView
        organization={selectedOrganization}
        lockDisabled={isProcessing || !isUpdateAllowed}
        onLockOrganization={onLockOrganization}
        unlockDisabled={isProcessing || !isUpdateAllowed}
        onUnlockOrganization={onUnlockOrganization}
        onDeleteOrganization={onClickDelete}
        isFreeTier={isFreeTier}
        deleteDisabled={isProcessing || !isDeleteAllowed}
      />
    </Section>
  );
};
