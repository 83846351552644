//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { numberFormat } from "humanize";
import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import { DataVolumeInfo as ApiDataVolumeInfo, Deployment } from "../../api/lib";
import { humanizeFileSize } from "../../util/FileSize";

export const DISK_SIZE_NOTIFICATION_LIMIT_IN_PERCENTAGE = 80;
export interface IDataVolumeViewArgs {
  data_volume_info?: ApiDataVolumeInfo;
}

export interface IDataVolumeStats {
  id?: string;
  isDiskNearingCapacity: boolean;
}

export const getDiskUsage = (dataVolumeInfo: ApiDataVolumeInfo): number => {
  const { total_bytes, available_bytes } = dataVolumeInfo;

  if (!total_bytes) {
    return 0;
  }

  return parseFloat(((((total_bytes || 0) - (available_bytes || 0)) * 100) / (total_bytes || 0)).toString());
};

export const isDiskNearingUsageCapacity = (dataVolumeInfo: ApiDataVolumeInfo): boolean => {
  return getDiskUsage(dataVolumeInfo) > DISK_SIZE_NOTIFICATION_LIMIT_IN_PERCENTAGE;
};

export const getDataVolumeStatus = (deployment: Deployment): IDataVolumeStats => {
  const { status } = deployment;
  const { servers = [] } = status || {};

  return {
    isDiskNearingCapacity: servers.some((server) => {
      const { data_volume_info = {} } = server;
      return isDiskNearingUsageCapacity(data_volume_info);
    }),
  };
};

export const DataVolumeView = ({ ...args }: IDataVolumeViewArgs) => (
  <span>
    {args.data_volume_info ? (
      <Popup
        position="right center"
        trigger={
          <span>
            {numberFormat(getDiskUsage(args.data_volume_info), 1)}%{" "}
            {isDiskNearingUsageCapacity(args.data_volume_info) && <Icon name="warning circle" color="yellow" />}
          </span>
        }
        content={
          <Table basic="very" singleLine compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Total:</Table.Cell>
                <Table.Cell textAlign="right">{humanizeFileSize(args.data_volume_info.total_bytes)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Used:</Table.Cell>
                <Table.Cell textAlign="right">{humanizeFileSize(args.data_volume_info.used_bytes)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Available:</Table.Cell>
                <Table.Cell textAlign="right">{humanizeFileSize(args.data_volume_info.available_bytes)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        }
      />
    ) : (
      "-"
    )}
  </span>
);
