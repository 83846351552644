//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import Auth from "../auth/Auth";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import { HomeTemplateView, GridRowIntro } from "./HomeTemplate";
import DocumentTitle from "react-document-title";
import { IWithSignupModalProps, withSignupModal } from "./SignupModalView";
import { RegionGrid, RegionMap } from "../ui/lib";

import logo from "../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { Box } from "../ui/_box";

interface IRegionsViewArgs extends IWithSignupModalProps {
  isAuthenticated: boolean;
  onClickLogin: () => void;
  onSignup: () => void;
  onClickLogout: () => void;
}

const RegionsView = ({ ...args }: IRegionsViewArgs) => {
  return (
    <HomeTemplateView {...args}>
      <Box maxWidth={"960px"} margin={"0 auto"}>
        <Grid columns="16" stackable>
          <GridRowIntro>
            <Grid.Column width="8">
              <a href="/">
                <img src={logo} alt="ArangoGraph" />
              </a>
            </Grid.Column>
          </GridRowIntro>
          <Grid.Row>
            <Grid.Column width="16">
              <h1 className="heading-1">Available Regions</h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment>
          <RegionMap showPlanned />
        </Segment>
        <RegionGrid />
      </Box>
    </HomeTemplateView>
  );
};

interface IRegionsProps extends RouteComponentProps, IWithSignupModalProps {
  auth: Auth;
}

interface IRegionsState {}

// Regions shows the available regions on a map.
class Regions extends Component<IRegionsProps, IRegionsState> {
  state = {} as IRegionsState;

  login = () => {
    this.props.auth.login();
  };
  signup = () => {
    this.props.auth.signup();
  };
  logout = () => {
    this.props.auth.logout();
  };

  render() {
    return (
      <DocumentTitle title="Available Regions">
        <RegionsView
          {...this.props}
          {...this.state}
          isAuthenticated={this.props.auth.isAuthenticated()}
          onClickLogin={this.login}
          onSignup={this.signup}
          onClickLogout={this.logout}
        />
      </DocumentTitle>
    );
  }
}

export default withSignupModal(Regions);
