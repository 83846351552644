//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import styled from "@emotion/styled";
import { Message, Button, Popup, Label } from "semantic-ui-react";
import { FlexBox } from "../../../ui/_flex";
import { Field, FieldLabel } from "../../../ui/_style";
import { IPrincipalList } from "../types";

const StyledPrincipalCard = styled(Message)`
  background: var(--gray-50) !important;
  border: 1px solid var(--gray-400) !important;
  box-shadow: 0 0 0 0 !important;
  margin-top: 20px !important;
  position: relative;
  min-height: 110px !important;
`;

const PrincipalList = ({ awsPrincipals, onEdit, onDelete, disableChange }: IPrincipalList) => {
  const getMultiLineView = (data: string[] | undefined) => {
    if (!data || !data.length) {
      return "-";
    }

    if (data.length === 1) {
      return data[0];
    }

    return (
      <Popup
        trigger={
          <span className="pointer" data-testid="principalListPopup">
            {data[0]}{" "}
            <i>
              <Label size="tiny" circular data-testid="principalListPopupLabel">
                {" "}
                {` +${data.length - 1} more`}
              </Label>
            </i>
          </span>
        }
        content={data.map((entry, index) => {
          if (index !== 0) {
            return (
              <div key={index} data-testid="principalListPopupContentItem">
                {entry}
              </div>
            );
          }
          return null;
        })}
        position="right center"
      />
    );
  };

  return (
    <>
      {awsPrincipals.map((principal, index) => {
        return (
          <StyledPrincipalCard key={principal.account_id} data-testid="principalCard">
            <Field data-testid="principalCardAccountIdField">
              <FieldLabel>
                <b data-testid="principalAccountId"> Account ID</b>
              </FieldLabel>
              <span data-testid="principalAccountIdValue">{principal.account_id}</span>
            </Field>
            <Field data-testid="principalCardUsernamesField">
              <FieldLabel>
                <b data-testid="principalUsername">User Names</b>
              </FieldLabel>
              <div data-testid="principalUsernameValue">{getMultiLineView(principal.user_names)}</div>
            </Field>
            <Field data-testid="principalCardRolesnamesField">
              <FieldLabel>
                <b data-testid="principalRolesnames">Roles names</b>
              </FieldLabel>
              <div data-testid="principalRolesnamesValue">{getMultiLineView(principal.role_names)}</div>
            </Field>
            <FlexBox justify="flex-end" position="absolute" right="10px" bottom="10px">
              <Button icon="edit" size="small" onClick={() => onEdit(index)} data-testid="editPrincipal" disabled={disableChange} />
              <Button icon="trash" size="small" onClick={() => onDelete(index)} data-testid="deletePrincipal" disabled={disableChange} />
            </FlexBox>
          </StyledPrincipalCard>
        );
      })}
    </>
  );
};

export default PrincipalList;
