//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
// Ewout Prangsma
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, IPAllowlist as ApiIPAllowlist } from "../../api/lib";
import { LoaderBox, TextLink, Warnings } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import IPAllowlistDetailsModal from "../ipallowlist/IPAllowlistDetailsModal";

interface IIPAllowlistViewArgs extends IIPAllowlistState {
  onView: () => void;
}

const IPAllowlistView = ({ ...args }: IIPAllowlistViewArgs) => {
  if (args.ipallowlist) {
    const warnings = args.ipallowlist.warnings;
    return (
      <TextLink onClick={args.onView}>
        {args.ipallowlist.name} <Warnings warnings={warnings} />
      </TextLink>
    );
  }

  return <div>retrieving</div>;
};

// Interface decribing the properties of the IPAllowlist component
interface IIPAllowlistProps extends IWithRefreshProps, RouteComponentProps {
  ipallowlistId?: string;
}

// Interface decribing the state of the IPAllowlist component
interface IIPAllowlistState {
  ipallowlist?: ApiIPAllowlist;
  showDetails: boolean;
}

class IPAllowlist extends Component<IIPAllowlistProps, IIPAllowlistState> {
  state = {
    ipallowlist: undefined,
    showDetails: false,
  } as IIPAllowlistState;

  reloadIPAllowlistInfo = async () => {
    if (this.props.ipallowlistId) {
      const idOptions = { id: this.props.ipallowlistId } as ApiIDOptions;
      const list = await apiClients.securityClient.GetIPAllowlist(idOptions);
      this.setState({ ipallowlist: list });
    } else {
      this.setState({ ipallowlist: undefined });
    }
  };

  componentDidMount() {
    if (this.props.ipallowlistId) {
      this.props.refreshNow && this.props.refreshNow(this.reloadIPAllowlistInfo);
    }
  }

  render() {
    const ipallowlistId = this.props.ipallowlistId;
    if (!ipallowlistId) {
      return <div>None</div>;
    }

    return (
      <div>
        <LoaderBox>
          <Loader size="mini" active={this.props.loading} inline />
        </LoaderBox>
        {this.state.showDetails && (
          <IPAllowlistDetailsModal {...this.props} ipallowlistId={ipallowlistId} onClose={() => this.setState({ showDetails: false })} />
        )}
        <IPAllowlistView {...this.state} onView={() => this.setState({ showDetails: true })} />
      </div>
    );
  }
}

export default withRefresh()(IPAllowlist);
