//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Button, Icon, Menu, Popup, SemanticICONS, SemanticSIZES } from "semantic-ui-react";
import history from "../history";

interface IOptionalButtonView {
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
}
interface IButtonView {
  disabled?: boolean;
  tooltip?: string;
  icon?: SemanticICONS;
  onClick?: () => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  size?: SemanticSIZES;
}

// MenuActionBack renders a button for a Back action is a menu.
export const MenuActionBack = () => (
  <Menu.Item onClick={() => history.goBack()}>
    <Icon name="arrow left" />
  </Menu.Item>
);

// ListAction renders a generic button for a View action is a listview.
export const ListAction = ({ ...args }: IButtonView) => {
  const hasIcon = !!args.icon;
  const button = (
    <Button icon disabled={args.disabled} onClick={args.onClick} type={args.type || "button"} size={args.size} loading={args.loading}>
      {hasIcon && <Icon name={args.icon} />}
    </Button>
  );
  if (!!args.tooltip) {
    return <Popup position="top right" content={args.tooltip} trigger={button} />;
  }
  return button;
};

// ListActionView renders a button for a View action is a listview.
export const ListActionView = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "eye"} />;

// ListActionEdit renders a button for a Edit action is a listview.
export const ListActionEdit = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "edit"} />;

// ListActionDelete renders a button for a Delete action is a listview.
export const ListActionDelete = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "trash"} />;

// ListActionConvertToOwner renders a button for a "Convert-to-owner" action is a listview.
export const ListActionConvertToOwner = ({ ...args }: IButtonView) => (
  <ListAction {...args} icon={args.icon || "key"} tooltip={args.tooltip || "Convert to owner"} />
);

// ListActionSetDefault renders a button for a "Set-as-default" action is a listview.
export const ListActionSetDefault = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "tag"} tooltip={args.tooltip || "Make default"} />;

// ListActionResetDefault renders a button for a "Reset-default" action is a listview.
export const ListActionResetDefault = ({ ...args }: IButtonView) => (
  <ListAction {...args} icon={args.icon || "undo"} tooltip={args.tooltip || "Reset default"} />
);

// ListActionConvertToMember renders a button for a "Convert-to-member" action is a listview.
export const ListActionConvertToMember = ({ ...args }: IButtonView) => (
  <ListAction {...args} icon={args.icon || "user"} tooltip={args.tooltip || "Convert to normal member"} />
);

// ListActionDownload renders a button for a "Download" action is a listview.
export const ListActionDownload = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "download"} tooltip={args.tooltip || "Download"} />;

// ListActionAccept renders a button for an Accept action is a listview.
export const ListActionAccept = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "check"} />;

// ListActionReject renders a button for a Reject action is a listview.
export const ListActionReject = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "cancel"} />;

// ListActionRevoke renders a button for a Revoke action is a listview.
export const ListActionRevoke = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "undo"} />;

// ListActionRotate renders a button for a Rotate action is a listview.
export const ListActionRotate = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "recycle"} />;

// ListActionSelect renders a button for a Select action is a listview.
export const ListActionSelect = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "check"} />;

// ListActionDeselect renders a button for a De-Select action is a listview.
export const ListActionDeselect = ({ ...args }: IButtonView) => <ListAction {...args} icon={args.icon || "cancel"} />;

interface IOptionalActionButton extends IOptionalButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
  icon?: SemanticICONS;
  tabIndex?: string | number | undefined;
  testID?: string;
}
interface IActionButton extends IButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
  icon?: SemanticICONS;
  type?: "button" | "submit" | "reset";
  attached?: boolean | "left" | "right" | "top" | "bottom";
  labelPosition?: "left" | "right";
  tabIndex?: string | number | undefined;
  testID?: string;
}

interface IDropdownButton extends IOptionalButtonView {
  primary?: boolean;
  big?: boolean;
  title?: string;
  icon?: SemanticICONS;
}

interface IActionButtonWithContent extends IActionButton {
  content: string;
}

interface IActionButtonWithChildren extends IActionButton {
  children?: any;
}

// ContentActionButton renders a button to be used in the content part of a page.
export const ContentActionButton = ({ ...args }: IActionButtonWithChildren) => (
  <Button {...args} disabled={args.disabled} primary={args.primary} onClick={args.onClick} size={args.big ? "big" : "medium"}>
    {args.children}
  </Button>
);

export const ContentActionButtonNew = ({ ...args }: IActionButtonWithContent) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.content}
    icon={args.icon || "plus"}
    labelPosition="right"
    type={args.type || "button"}
    loading={args.loading}
  />
);

export const ContentActionButtonView = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "View"}
    icon={args.icon || "eye"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonCancel = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Cancel"}
    icon={args.icon || "cancel"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonEdit = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Edit"}
    icon={args.icon || "edit"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonDelete = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Delete"}
    icon={args.icon || "trash"}
    labelPosition="right"
  />
);

export const ContentActionButtonLogout = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Logout"}
    icon={args.icon || "log out"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonPolicy = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Policy"}
    icon={args.icon || "shield"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonConnectDrivers = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title || "Connect drivers"}
    icon={args.icon || "shield"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonBackup = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content="Back up now"
    icon={args.icon || "window restore"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonBackupPolicy = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content="Schedule backup"
    icon={args.icon || "time"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const ContentActionButtonServerLogs = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content="Logs"
    icon={args.icon || "history"}
    labelPosition="right"
    type={args.type || "button"}
  />
);

export const FormActionButton = ({ ...args }: IActionButton) => (
  <Button
    disabled={args.disabled}
    primary={args.primary}
    onClick={args.onClick}
    size={args.big ? "big" : "medium"}
    content={args.title}
    icon={args.icon}
    labelPosition={args.labelPosition || "right"}
    type={args.type || "button"}
    loading={args.loading}
    data-testid={args.testID}
  />
);

export const FormActionButtonCreate = ({ ...args }: IOptionalActionButton) => (
  <FormActionButton {...args} title={args.title || "Create"} icon={args.icon || "save"} />
);

export const FormActionButtonResume = ({ ...args }: IOptionalActionButton) => (
  <FormActionButton {...args} title={args.title || "Resume"} icon={args.icon || "play"} />
);

export const FormActionButtonSave = ({ ...args }: IOptionalActionButton) => (
  <FormActionButton {...args} title={args.title || "Save"} icon={args.icon || "check"} />
);

export const FormActionButtonEdit = ({ ...args }: IOptionalActionButton) => (
  <FormActionButton {...args} title={args.title || "Edit"} icon={args.icon || "edit"} />
);

export const FormActionButtonCancel = ({ ...args }: IActionButton) => (
  <FormActionButton {...args} title={args.title || "Cancel"} icon={args.icon || "cancel"} />
);

export const FormActionButtonCopy = ({ ...args }: IActionButton) => <FormActionButton {...args} title={args.title || "Copy"} icon={args.icon || "copy"} />;

export const FormActionButtonDropdown = ({ ...args }: IDropdownButton) => (
  <FormActionButton {...args} title={args.title || "..."} icon={args.icon || "ellipsis horizontal"} />
);

// TopMenuActionButton renders Button that is to be used in the top menu.
export const TopMenuActionButton = ({ ...args }: IActionButtonWithChildren) => (
  <Button disabled={args.disabled} onClick={args.onClick}>
    {args.children}
  </Button>
);
