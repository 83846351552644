//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import apiclients from "../api/apiclients";
import { GetMultipleEffectivePermissionsRequest as ApiGetMultipleEffectivePermissionsRequest, PermissionList as ApiPermissionList } from "../api/lib";
import { PersistentState } from "./PersistentState";
import { filter, isEqual, sortBy } from "lodash";

export type Permission =
  | "audit.auditlog.create"
  | "audit.auditlog.delete"
  | "audit.auditlog.get"
  | "audit.auditlog.list"
  | "audit.auditlog.set-default"
  | "audit.auditlog.test-https-post-destination"
  | "audit.auditlog.update"
  | "audit.auditlogarchive.delete"
  | "audit.auditlogarchive.get"
  | "audit.auditlogarchive.list"
  | "audit.auditlogattachment.create"
  | "audit.auditlogattachment.delete"
  | "audit.auditlogattachment.get"
  | "audit.auditlogevents.get"
  | "backup.backup.copy"
  | "backup.backup.create"
  | "backup.backup.delete"
  | "backup.backup.download"
  | "backup.backup.get"
  | "backup.backup.list"
  | "backup.backup.restore"
  | "backup.backup.update"
  | "backup.backuppolicy.create"
  | "backup.backuppolicy.delete"
  | "backup.backuppolicy.get"
  | "backup.backuppolicy.list"
  | "backup.backuppolicy.update"
  | "backup.feature.get"
  | "billing.config.get"
  | "billing.config.set"
  | "billing.invoice.get"
  | "billing.invoice.list"
  | "billing.invoice.get-preliminary"
  | "billing.paymentmethod.create"
  | "billing.paymentmethod.delete"
  | "billing.paymentmethod.get-default"
  | "billing.paymentmethod.get"
  | "billing.paymentmethod.list"
  | "billing.paymentmethod.set-default"
  | "billing.paymentmethod.update"
  | "billing.paymentprovider.list"
  | "credit.creditbundle.list"
  | "credit.creditbundleusage.list"
  | "credit.creditusagereport.get"
  | "credit.creditusagereport.list"
  | "credit.creditbundleusageprojection.get"
  | "credit.creditdebt.get"
  | "crypto.cacertificate.create"
  | "crypto.cacertificate.delete"
  | "crypto.cacertificate.get"
  | "crypto.cacertificate.list"
  | "crypto.cacertificate.set-default"
  | "crypto.cacertificate.update"
  | "data.deployment.create"
  | "data.deployment.create-test-database"
  | "data.deployment.delete"
  | "data.deployment.get"
  | "data.deployment.list"
  | "data.deployment.pause"
  | "data.deployment.rebalance-shards"
  | "data.deployment.restore-backup"
  | "data.deployment.resume"
  | "data.deployment.rotate-server"
  | "data.deployment.update"
  | "data.deploymentfeatures.get"
  | "data.deployment.update-scheduled-root-password-rotation"
  | "data.deploymentcredentials.get"
  | "data.diskperformance.list"
  | "data.limits.get"
  | "data.nodesize.list"
  | "data.presets.list"
  | "example.exampledatasetinstallation.create"
  | "example.exampledatasetinstallation.delete"
  | "iam.group.create"
  | "iam.group.delete"
  | "iam.group.get"
  | "iam.group.list"
  | "iam.group.update"
  | "iam.policy.get"
  | "iam.policy.update"
  | "iam.role.create"
  | "iam.role.delete"
  | "iam.role.get"
  | "iam.role.list"
  | "iam.role.update"
  | "metrics.token.create"
  | "metrics.token.list"
  | "ml.mlservices.get"
  | "ml.mlservices.update"
  | "notebook.notebook.get"
  | "notebook.notebook.create"
  | "notebook.notebook.delete"
  | "notebook.notebook.update"
  | "notebook.notebook.list"
  | "notebook.notebook.pause"
  | "notebook.notebook.resume"
  | "notebook.model.list"
  | "notification.deployment-notification.list"
  | "notification.deployment-notification.mark-as-read"
  | "notification.deployment-notification.mark-as-unread"
  | "network.privateendpointservice.get-feature"
  | "network.privateendpointservice.get"
  | "network.privateendpointservice.get-by-deployment-id"
  | "network.privateendpointservice.create"
  | "network.privateendpointservice.update"
  | "prepaid.prepaiddeployment.get"
  | "prepaid.prepaiddeployment.list"
  | "replication.deployment.clone-from-backup"
  | "replication.deploymentmigration.create"
  | "replication.deploymentmigration.get"
  | "replication.deploymentreplication.get"
  | "replication.deploymentreplication.update"
  | "resourcemanager.event.list"
  | "resourcemanager.organization-invite.create"
  | "resourcemanager.organization-invite.delete"
  | "resourcemanager.organization-invite.get"
  | "resourcemanager.organization-invite.list"
  | "resourcemanager.organization-invite.update"
  | "resourcemanager.organization.delete"
  | "resourcemanager.organization.get"
  | "resourcemanager.organization.update"
  | "resourcemanager.project.create"
  | "resourcemanager.project.delete"
  | "resourcemanager.project.get"
  | "resourcemanager.project.list"
  | "resourcemanager.project.update"
  | "security.ipallowlist.create"
  | "security.ipallowlist.delete"
  | "security.ipallowlist.get"
  | "security.ipallowlist.list"
  | "security.ipallowlist.update"
  | "security.iamprovider.create"
  | "security.iamprovider.delete"
  | "security.iamprovider.get"
  | "security.iamprovider.list"
  | "security.iamprovider.set-default"
  | "security.iamprovider.update";

export enum ResourceType {
  AuditLog,
  Backup,
  BackupPolicy,
  CACertificate,
  Deployment,
  Group,
  IAMProvider,
  IPAllowlist,
  Policy,
  Role,
  OrganizationInvite,
  Organization,
  Project,
  PrepaidDeployment,
}

type PendingRequest = {
  urls: string[];
  requestHandler: RequestHandler;
};
export class PermissionCache {
  private debug: boolean;
  private cache: Map<string, CacheItem>;
  private pendingRequests: Array<PendingRequest>;

  private lastId: number;

  constructor(debug?: boolean) {
    this.debug = !!debug;
    this.lastId = 2;
    this.cache = PersistentState.retrievePermissionCache(1);
    if (this.debug) {
      console.info(`Cache entries at startup: ${this.cache.size}`);
    }
    this.pendingRequests = [];
  }

  private uptodate = (url: string) => {
    const cacheItem = this.cache.get(url);
    return cacheItem && !cacheItem.isOutdated();
  };

  private upsert = (url: string, permissions: string[]) => {
    const sortedPermissions = sortBy(permissions);
    const previousItem = this.cache.get(url);
    if (!!previousItem && previousItem.permissionsEqual(sortedPermissions)) {
      if (this.debug) {
        console.info(`Cache hit for ${url}`);
      }
      const isPermissionCachePersisting = PersistentState.retrievePermissionCache(previousItem.getId());
      if (!isPermissionCachePersisting.size) {
        if (this.debug) {
          console.info(`Cache entry ${url} is not present in LocalStorage. `);
        }
        PersistentState.savePermissionCache(this.cache);
      }

      previousItem.resetDate();
      return;
    }

    this.lastId++;
    const id = this.lastId;
    this.cache.set(url, new CacheItem(id, sortedPermissions));
    const size = PersistentState.savePermissionCache(this.cache);
    if (this.debug) {
      console.info(`Cache entries: ${this.cache.size} json size: ${size}`);
    }
  };

  getId = () => {
    let maxId = 0;
    this.cache.forEach((v) => {
      maxId = Math.max(maxId, v.getId());
    });
    return maxId;
  };

  clear = () => {
    this.cache.clear();
  };

  checkPermission = (url: string, permission: Permission): CheckCacheResult => {
    const permissions = this.cache.get(url);
    if (permissions) {
      const found = permissions.getPermissions().includes(permission);
      return new CheckCacheResult(true, found);
    }

    return new CheckCacheResult(false, false);
  };

  updatePermissions = async (urls: string[]): Promise<AddCacheResult> => {
    const sanitizedURLs = urls.filter((url) => !!url);

    if (!sanitizedURLs.length) {
      return new AddCacheResult(false, 0);
    }

    if (this.debug) {
      console.log(`Active permission urls: ${sanitizedURLs.length}`);
    }

    const urlsToCheck = sanitizedURLs.filter((url) => !this.uptodate(url));
    if (!urlsToCheck.length) {
      return new AddCacheResult(false, 0);
    }

    let updated = false;
    let requestHandler: PendingRequest = filter(this.pendingRequests, (pendingReq) => {
      return isEqual(pendingReq.urls.sort(), urlsToCheck.sort());
    })[0];

    if (!requestHandler) {
      if (this.debug) {
        console.log(`No existing request handler found - Create new request handler`);
      }
      const reqHandle = new RequestHandler(sanitizedURLs, this.upsert, this.debug);
      this.pendingRequests.push({
        urls: sanitizedURLs,
        requestHandler: reqHandle,
      });
      const result = await reqHandle.getWaiter();
      this.pendingRequests = filter(this.pendingRequests, (req) => {
        return !isEqual(req.urls.sort(), sanitizedURLs.sort());
      });
      updated = result;
    }

    return new AddCacheResult(updated, this.getId());
  };
}

class RequestHandler {
  private debug: boolean;
  private running = true;
  private result = false;
  private waiters: Array<(result: boolean) => void> = new Array<(result: boolean) => void>();

  constructor(urls: string[], upsert: (url: string, permissions: string[]) => void, debug: boolean) {
    this.debug = debug;
    this.getMultipleEffectivePermissions(urls, upsert, debug);
  }

  getWaiter = async (): Promise<boolean> => {
    if (!this.running) {
      return this.result;
    }

    return new Promise<boolean>((resolve) => {
      this.waiters.push(resolve);
    });
  };

  getMultipleEffectivePermissions = async (urls: string[], upsert: (url: string, permissions: string[]) => void, debug: boolean) => {
    try {
      const req: ApiGetMultipleEffectivePermissionsRequest = {
        urls,
      };
      if (debug) {
        console.log(`Requesting permissions for ${urls.length} urls`);
      }
      const permissionListObject = await apiclients.iamClient.GetMultipleEffectivePermissions(req);
      const { items = [] } = permissionListObject;
      items.forEach((permissionListItem) => {
        const { url, items } = permissionListItem as Required<ApiPermissionList>;
        upsert(url, items);
        if (this.debug) {
          console.log(`Inserted or updated cache: ${url}`);
        }
      });

      this.result = true;
    } catch (err) {
      if (debug) {
        console.log(`Error while updating permission: ${err}`);
      }
      const { status } = err;
      if (status === 403) {
        urls.forEach((url) => {
          upsert(url, []);
          if (debug) {
            console.log(`Releasing ${this.waiters.length} waiters for ${url}`);
          }
        });
        this.result = true;
      }
    }
    this.waiters.forEach((waiter) => {
      waiter(this.result);
    });
  };
}

export class CacheItem {
  // Cache is 2 minutes valid
  static expirationTimeMs = 2000 * 60;

  private createDate: number;
  private permissions: string[];
  private id: number;

  constructor(id: number, permissions: string[], createDate?: number) {
    this.createDate = createDate || Date.now();
    this.permissions = permissions;
    this.id = id;
  }

  isOutdated = () => {
    const now = Date.now();
    const diffMs = now - this.createDate;
    return diffMs > CacheItem.expirationTimeMs;
  };

  getPermissions = () => {
    return this.permissions;
  };

  getId = () => {
    return this.id;
  };

  permissionsEqual = (otherPermissions: string[]) => {
    return isEqual(this.permissions, otherPermissions);
  };

  resetDate = () => {
    this.createDate = Date.now();
  };
}

export class CheckCacheResult {
  private inCache: boolean;
  private result: boolean;

  constructor(inCache: boolean, result: boolean) {
    this.inCache = inCache;
    this.result = result;
  }

  getInCache() {
    return this.inCache;
  }

  getResult() {
    return this.result;
  }
}

export class AddCacheResult {
  private updated: boolean;
  private id: number;

  constructor(updated: boolean, id: number) {
    this.updated = updated;
    this.id = id;
  }

  getUpdated = () => {
    return this.updated;
  };

  getId = () => {
    return this.id;
  };
}
