//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { Button, Form, Grid, Modal, Segment, Table } from "semantic-ui-react";
import { Deployment as ApiDeployment, Token as ApiToken } from "../../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../../util/WithRefresh";
import apiClients from "../../../api/apiclients";
import { ErrorMessage, FormActionButtonCancel, FormActionButtonCreate, MainContent, Processing, Section, SectionContent, NumberInput } from "../../../ui/lib";
import moment from "moment";
import { hoursToDuration } from "../../../api/googleTypes";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ICreateMetricsTokenViewArgs {
  name: string;
  description: string;
  lifetimeInDays: number;
  processing: boolean;
  errorMessage?: string;
  onClose: () => void;
  onCreate: () => void;
  handleDismissError: () => void;
  onNameChanged: (value: string) => void;
  onDescriptionChanged: (value: string) => void;
  onLifetimeInDaysChanged: (value: number) => void;

  created: boolean;
  token: string;
  copiedToken: boolean;
  onCopiedToken: () => void;
}

const CreateMetricsTokenView = ({ ...args }: ICreateMetricsTokenViewArgs) => {
  const hasName = !_.isEmpty(args.name);
  const canCreate = hasName;

  return (
    <Modal open onClose={args.onClose}>
      <Modal.Header>New Metrics Token</Modal.Header>
      <Modal.Content>
        <Processing active={args.processing} message="Creating metrics token, please wait..." />
        <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
        <MainContent>
          {args.created && (
            <div>
              <Modal.Description>
                <p className="para">A new metrics token has been created for you.</p>
                <p className="para">
                  Make sure to store this information in a safe place.
                  <br />
                  <strong>The metrics token is displayed only here and now.</strong>
                  <br />
                  You cannot retrieve it afterward.
                </p>
                <Segment>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Token</Table.Cell>
                        <Table.Cell className="mask-data">{args.token}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <CopyToClipboard text={args.token} onCopy={args.onCopiedToken}>
                            <Button icon={args.copiedToken ? "checkmark" : "copy"} size="tiny" />
                          </CopyToClipboard>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>
              </Modal.Description>
            </div>
          )}
          {!args.created && (
            <Form>
              <Section>
                <SectionContent>
                  <Grid columns="16">
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Form.Input
                          autoFocus
                          required
                          label="Name"
                          placeholder={`Name (e.g. "MyTokenFor-${moment().year()}")`}
                          name="name"
                          value={args.name}
                          onChange={(e, d) => args.onNameChanged(d.value)}
                        />
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Form.Input
                          label="Short description"
                          placeholder="Description"
                          name="description"
                          value={args.description}
                          onChange={(e, d) => args.onDescriptionChanged(d.value)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Form.Field>
                          <label>Lifetime</label>
                          <NumberInput value={args.lifetimeInDays} min={1} max={365} ext=" days" onChange={args.onLifetimeInDaysChanged} />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </SectionContent>
              </Section>
            </Form>
          )}
        </MainContent>
      </Modal.Content>
      {!args.created && (
        <Modal.Actions>
          <FormActionButtonCreate primary disabled={args.processing || !canCreate} onClick={args.onCreate} />
          <FormActionButtonCancel onClick={args.onClose} />
        </Modal.Actions>
      )}
      {args.created && (
        <Modal.Actions>
          <FormActionButtonCancel onClick={args.onClose} title="Close" />
        </Modal.Actions>
      )}
    </Modal>
  );
};

interface ICreateMetricsTokenProps extends IWithRefreshProps {
  deployment: ApiDeployment;
  onClose: () => void;
  onTokenCreated: (token: ApiToken) => void;
}

interface ICreateMetricsTokenState {
  name: string;
  description: string;
  lifetimeInDays: number;
  processing: boolean;
  created: boolean;
  token: string;
  copiedToken: boolean;
  errorMessage?: string;
}

class CreateMetricsToken extends Component<ICreateMetricsTokenProps, ICreateMetricsTokenState> {
  state = {
    name: "",
    description: "",
    lifetimeInDays: 90,
    processing: false,
    created: false,
    token: "",
    copiedToken: false,
    errorMessage: undefined,
  } as ICreateMetricsTokenState;

  onCreate = async () => {
    try {
      this.setState({ created: false, processing: true, errorMessage: undefined });
      const req = {
        name: this.state.name,
        description: this.state.description,
        deployment_id: this.props.deployment.id,
        lifetime: hoursToDuration(this.state.lifetimeInDays * 24),
      } as ApiToken;
      const result = await apiClients.metricsClient.CreateToken(req);
      this.props.onTokenCreated(result);
      this.setState({ created: true, token: result.token || "" });
    } catch (e) {
      this.setState({ errorMessage: e });
    } finally {
      this.setState({ processing: false });
    }
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onNameChanged = (value: string) => {
    this.setState({ name: value });
  };

  onDescriptionChanged = (value: string) => {
    this.setState({ description: value });
  };

  onLifetimeInDaysChanged = (value: number) => {
    this.setState({ lifetimeInDays: value });
  };

  onCopiedToken = () => {
    this.setState({ copiedToken: true }, () => {
      const setTimeout = this.props.setTimeout;
      setTimeout &&
        setTimeout(() => {
          this.setState({ copiedToken: false });
        }, 3000);
    });
  };

  render() {
    return <CreateMetricsTokenView {...this.props} {...this.state} {...this} />;
  }
}

export default withRefresh()(CreateMetricsToken);
