//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Routes holds a list of local path's that map to the routes
// NOTICE: this should be entered in Go code as well, found in ../pkg/server/routes.go
export class Routes {
  static isUnauthenticated(route: string) {
    switch (route) {
      case Routes.home:
      case Routes.privacy_policy:
      case Routes.terms_and_conditions:
      case Routes.data_processing_addendum:
      case Routes.regions:
      case Routes.support:
      case Routes.faq:
      case Routes.requestHelp:
      case Routes.signup:
      case Routes.login:
      case Routes.accessdenied:
      case Routes.email_needs_verification:
      case Routes.email_verified:
      case Routes.callback:
      case Routes.authorize:
      case Routes.logout:
        return true;
      default:
        return false;
    }
  }

  // Select which public pages should be accessible as authenticated user
  static isPubliclyAccessibleAsAuthenticatedUser(route: string) {
    switch (route) {
      case Routes.privacy_policy:
      case Routes.terms_and_conditions:
      case Routes.data_processing_addendum:
      case Routes.regions:
      case Routes.support:
      case Routes.faq:
      case Routes.email_verified:
      case Routes.authorize:
      case Routes.logout:
        return true;
      default:
        return false;
    }
  }

  static root = "/";
  static home = "/home";
  static privacy_policy = "/privacy-policy";
  static terms_and_conditions = "/terms-and-conditions";
  static data_processing_addendum = "/data-processing-addendum";
  static onboarding = "/onboarding";
  static dashboard = "/dashboard";
  static dashboard_projects = "/dashboard/projects";
  static callback = "/callback";
  static accessdenied = "/accessdenied";
  static email_needs_verification = "/email-needs-verification";
  static email_verified = "/email-verified";
  static email_verified_entry = "/email-verified-entry";
  static logos = "/logos";
  static support = "/support";
  static regions = "/regions";
  static faq = "/faq";
  static requestHelp = "/request-help";
  static signup = "/signup";
  static directSignup = "/signup?acceptedTermsAndConditions=1";
  static login = "/login";

  //  -- Onboarding related --
  static onboarding_user_phone_number = "/onboarding/user/phone_number";
  static onboarding_user_verification_code = "/onboarding/user/verification_code";
  static onboarding_user_names = "/onboarding/user/names";
  static onboarding_organizations_invites = "/onboarding/organizations/invites";
  static onboarding_finished = "/onboarding/finished";

  //  -- User related --
  static dashboard_user = "/dashboard/user";
  static dashboard_user_details = "/dashboard/user/details";
  static dashboard_user_edit = "/dashboard/user/edit";
  static dashboard_user_verify_phone = "/dashboard/user/verify-phone";
  static dashboard_user_my_account = "/dashboard/user/my-account";
  static dashboard_user_api_keys = "/dashboard/user/api-keys";

  static dashboard_user_organizations = "/dashboard/user/organizations";
  static dashboard_user_organizations_create = "/dashboard/user/organizations/create";
  //other than [create] can be found in '/dashboard/organization*' below

  static dashboard_user_organizationInvites = "/dashboard/user/organization-invites";

  // Pricing related
  static dashboard_pricing = "/dashboard/pricing";

  // Custom auth routing
  static authorize = "/authorize";
  static logout = "/logout";

  //  -- Organization related --
  static dashboard_organization = "/dashboard/organization/:organizationId";
  static dashboard_organizationWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}`;
  static dashboard_organization_overview = "/dashboard/organization/:organizationId/overview";
  static dashboard_organization_overviewWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/overview`;
  static dashboard_organization_details = "/dashboard/organization/:organizationId/details";
  static dashboard_organization_detailsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/details`;

  static dashboard_organization_billing = "/dashboard/organization/:organizationId/billing";
  static dashboard_organization_billingWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/billing`;

  static dashboard_organization_dangerzone = "/dashboard/organization/:organizationId/dangerzone";
  static dashboard_organization_dangerzoneWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/dangerzone`;

  static dashboard_organization_people = "/dashboard/organization/:organizationId/people";
  static dashboard_organization_peopleWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/people`;

  static dashboard_organization_access_control = "/dashboard/organization/:organizationId/accesscontrol";
  static dashboard_organization_auditlogs = "/dashboard/organization/:organizationId/auditlogs";
  static dashboard_organization_auditlogsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/auditlogs`;

  static dashboard_organization_credits = "/dashboard/organization/:organizationId/credits";
  static dashboard_organization_creditsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/credits`;

  static dashboard_organization_access_controlWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/accesscontrol`;

  static dashboard_organization_members = "/dashboard/organization/:organizationId/members";
  static dashboard_organization_membersWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/members`;
  static dashboard_organization_events = "/dashboard/organization/:organizationId/events"; //Notice: should not be in routes.go
  static dashboard_organization_eventsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/events`;

  static dashboard_organization_policy = "/dashboard/organization/:organizationId/policy";
  static dashboard_organization_policyWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/policy`;
  static dashboard_organization_policy_create = "/dashboard/organization/:organizationId/policy/create";
  static dashboard_organization_policy_createWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/policy/create`;

  static dashboard_organization_organizationInvites = "/dashboard/organization/:organizationId/organization-invites";
  static dashboard_organization_organizationInvitesWithId = (organizationId: string): string =>
    `/dashboard/organization/${organizationId}/organization-invites`;
  static dashboard_organization_organizationInvites_create = "/dashboard/organization/:organizationId/organization-invites/create";
  static dashboard_organization_organizationInvites_createWithId = (organizationId: string): string =>
    `/dashboard/organization/${organizationId}/organization-invites/create`;

  static dashboard_organization_projects = "/dashboard/organization/:organizationId/projects";
  static dashboard_organization_projectsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/projects`;
  static dashboard_organization_projects_create = "/dashboard/organization/:organizationId/projects/create";
  static dashboard_organization_projects_createWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/projects/create`;
  //other than [create] can be found in '/dashboard/project*' below

  static dashboard_organization_groups = "/dashboard/organization/:organizationId/groups";
  static dashboard_organization_groupsWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/groups`;
  static dashboard_organization_groups_create = "/dashboard/organization/:organizationId/groups/create";
  static dashboard_organization_groups_createWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/groups/create`;
  static dashboard_organization_group_details = "/dashboard/organization/:organizationId/group/:groupId/details";
  static dashboard_organization_group_detailsWithId = (organizationId: string, groupId: string): string =>
    `/dashboard/organization/${organizationId}/group/${groupId}/details`;
  static dashboard_organization_group_edit = "/dashboard/organization/:organizationId/group/:groupId/edit";
  static dashboard_organization_group_editWithId = (organizationId: string, groupId: string): string =>
    `/dashboard/organization/${organizationId}/group/${groupId}/edit`;

  static dashboard_organization_roles = "/dashboard/organization/:organizationId/roles";
  static dashboard_organization_rolesWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/roles`;
  static dashboard_organization_roles_create = "/dashboard/organization/:organizationId/roles/create";
  static dashboard_organization_roles_createWithId = (organizationId: string): string => `/dashboard/organization/${organizationId}/roles/create`;
  static dashboard_organization_role_details = "/dashboard/organization/:organizationId/role/:roleId/details";
  static dashboard_organization_role_detailsWithId = (organizationId: string, roleId: string): string =>
    `/dashboard/organization/${organizationId}/role/${roleId}/details`;
  static dashboard_organization_role_edit = "/dashboard/organization/:organizationId/role/:roleId/edit";
  static dashboard_organization_role_editWithId = (organizationId: string, roleId: string): string =>
    `/dashboard/organization/${organizationId}/role/${roleId}/edit`;

  //  -- Project related --
  static dashboard_project = "/dashboard/project/:projectId";
  static dashboard_projectWithId = (projectId: string): string => `/dashboard/project/${projectId}`;
  static dashboard_project_details = "/dashboard/project/:projectId/details";
  static dashboard_project_detailsWithId = (projectId: string): string => `/dashboard/project/${projectId}/details`;

  static dashboard_project_policy = "/dashboard/project/:projectId/policy";
  static dashboard_project_policyWithId = (projectId: string): string => `/dashboard/project/${projectId}/policy`;
  static dashboard_project_policy_create = "/dashboard/project/:projectId/policy/create";
  static dashboard_project_policy_createWithId = (projectId: string): string => `/dashboard/project/${projectId}/policy/create`;

  static dashboard_project_dangerzone = "/dashboard/project/:projectId/dangerzone";
  static dashboard_project_dangerzoneWithId = (projectId: string): string => `/dashboard/project/${projectId}/dangerzone`;

  static dashboard_project_auditlog = "/dashboard/project/:projectId/auditlog";
  static dashboard_project_auditlogWithId = (projectId: string): string => `/dashboard/project/${projectId}/auditlog`;

  static dashboard_project_security = "/dashboard/project/:projectId/security";
  static dashboard_project_securityWithId = (projectId: string): string => `/dashboard/project/${projectId}/security`;

  static dashboard_project_cacertificates = "/dashboard/project/:projectId/cacertificates";
  static dashboard_project_cacertificatesWithId = (projectId: string): string => `/dashboard/project/${projectId}/cacertificates`;

  static dashboard_project_deployments = "/dashboard/project/:projectId/deployments";
  static dashboard_project_deploymentsWithId = (projectId: string): string => `/dashboard/project/${projectId}/deployments`;
  static dashboard_project_deployments_create = "/dashboard/project/:projectId/deployments/create";
  static dashboard_project_deployments_createWithId = (projectId: string): string => `/dashboard/project/${projectId}/deployments/create`;
  static dashboard_project_deployment = "/dashboard/project/:projectId/deployment/:deploymentId";
  static dashboard_project_deployment_details = "/dashboard/project/:projectId/deployment/:deploymentId/details";
  static dashboard_project_deployment_detailsWithId = (projectId: string, deploymentId: string): string =>
    `/dashboard/project/${projectId}/deployment/${deploymentId}/details`;
  static dashboard_project_deployment_edit = "/dashboard/project/:projectId/deployment/:deploymentId/edit";
  static dashboard_project_deployment_editWithId = (projectId: string, deploymentId: string): string =>
    `/dashboard/project/${projectId}/deployment/${deploymentId}/edit`;

  static dashboard_project_deployment_policy = "/dashboard/project/:projectId/deployment/:deploymentId/policy";
  static dashboard_project_deployment_policyWithId = (projectId: string, deploymentId: string): string =>
    `/dashboard/project/${projectId}/deployment/${deploymentId}/policy`;
  static dashboard_project_deployment_policy_create = "/dashboard/project/:projectId/deployment/:deploymentId/policy/create";
  static dashboard_project_deployment_policy_createWithId = (projectId: string, deploymentId: string): string =>
    `/dashboard/project/${projectId}/deployment/${deploymentId}/policy/create`;

  static dashboard_project_ipallowlists = "/dashboard/project/:projectId/ipallowlists";
  static dashboard_project_ipallowlistsWithId = (projectId: string): string => `/dashboard/project/${projectId}/ipallowlists`;

  static dashboard_project_iamproviders = "/dashboard/project/:projectId/iamproviders";
  static dashboard_project_iamprovidersWithId = (projectId: string): string => `/dashboard/project/${projectId}/iamproviders`;
  static dashboard_project_iamproviders_create = "/dashboard/project/:projectId/iamproviders/create";
  static dashboard_project_iamproviders_createWithId = (projectId: string): string => `/dashboard/project/${projectId}/iamproviders/create`;
  static dashboard_project_iamprovider_details = "/dashboard/project/:projectId/iamprovider/:iamproviderId/details";
  static dashboard_project_iamprovider_detailsWithId = (projectId: string, iamproviderId: string): string =>
    `/dashboard/project/${projectId}/iamprovider/${iamproviderId}/details`;
  static dashboard_project_iamprovider_edit = "/dashboard/project/:projectId/iamprovider/:iamproviderId/edit";
  static dashboard_project_iamprovider_editWithId = (projectId: string, iamproviderId: string): string =>
    `/dashboard/project/${projectId}/iamprovider/${iamproviderId}/edit`;
}
