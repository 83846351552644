//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { FullItem, OptId } from "vis-data/declarations/data-interface";
import { Node, Edge } from "vis-network";
import { Database } from "arangojs";
import { CollectionMetadata, CollectionType } from "arangojs/collection";
import { DatabaseInfo } from "arangojs/database";

interface ParseError {
  type: "Quotes" | "Delimiter" | "FieldMismatch";
  code: "MissingQuotes" | "UndetectableDelimiter" | "TooFewFields" | "TooManyFields" | "InvalidQuotes";
  message: string;
  row?: number;
}

export type CustomParseError = {
  code: string;
  description: string;
  type: ParseError["type"];
  message: string;
  row?: number;
};

export const ERROR_CODE = {
  fileSizeQuotaReached: "file-size-quota-reached",
  fileCountLimitReached: "file-count-limit-reached",
};

export type FileObject = {
  file: File;
  collectionName?: string;
  fileSize?: string;
  name: string;
  id: string;
  fields?: any;
  errors?: CustomParseError[];
  uploadError?: string;
};

export type FileDropBoxViewArgs = {
  isDragActive: boolean;
  isDragReject: boolean;
  hasFiles?: boolean;
  error?: string;
  description?: string;
  openFileDialog: () => void;
};

export type FileListViewArgs = {
  openFileDialog: () => void;
};

export type NodeMappings = {
  attachedFile: FileObject;
  primaryKey?: string;
  selectedFields?: string[];
};

export type EdgeMappings = {
  attachedFile: FileObject;
  selectedFields?: string[];
  targetNodePrimaryKey?: string;
  sourceNodePrimaryKey?: string;
};

export type Mappings = {
  [id: string]: EdgeMappings | NodeMappings;
};

export type NodeExtraValues = {
  label?: string;
};

export type EdgeExtraValues = {
  label?: string;
};
export interface IAnyObject {
  [key: string]: any;
}

export type DocumentMetadataError = {
  error?: boolean;
  errorNum?: number;
  errorMessage?: string;
  processedDocument?: IAnyObject;
};

export type ParsedFileReport = {
  name: string;
  errors: CustomParseError[];
  parseReportURL?: string;
};

export type UploadedFileReport = {
  name: string;
  collectionName: string;
  errors: DocumentMetadataError[];
  collectionType: CollectionType | undefined;
  fullReportURL?: string;
  uploadError?: string;
  size?: number;
  successfulUploads: number;
  ignored: boolean;
};

export type MigrationStatus = "not_started" | "graph_modeler" | "files_validation" | "graph_creation" | "files_upload" | "finished" | "error";

export type MigrationJob = {
  status: MigrationStatus;
  migrationCancelled?: boolean;
  totalFilesToUpload?: number;
  graphBeingUploadedProgress?: number;
  fileBeingParsed?: {
    name: string;
    progress: number;
    id: string;
    collectionName?: string;
  };
  filesAlreadyParsed?: ParsedFileReport[];
  fileBeingUploaded?: {
    name: string;
    collectionName: string;
    progress?: number;
  };
  filesAlreadyUploaded?: UploadedFileReport[];
};

interface CurrentDatabase {
  db: Database | undefined;
  dbInfo: DatabaseInfo | undefined;
  collections: CollectionMetadata[];
  graphs: string[];
}

export type DeploymentDataloaderState = {
  currentDatabase: CurrentDatabase;
  databasesList: string[];
  name: string;
  files: FileObject[];
  nodes: FullItem<Partial<Record<"id", OptId> & NodeExtraValues>, "id">[];
  edges: FullItem<Partial<Record<"id", OptId> & EdgeExtraValues>, "id">[];
  editView: "EDGE" | "NODE" | undefined;
  graphMode: "ADD_EDGE" | undefined;
  nodeMappings: {
    [id: string]: NodeMappings;
  };
  edgeMappings: {
    [id: string]: EdgeMappings;
  };
  node: Node | null;
  edge: Edge | null;
  errorMessage: string | null;
  migrationJob: MigrationJob;
};

export interface DataloaderActions {
  updateDeploymentData: (data: Partial<DeploymentDataloaderState>) => void;
  getDeploymentDataloaderState: () => DeploymentDataloaderState;
  getActiveNode: () => Node | null;
  getActiveEdge: () => Edge | null;
  getMigrationJob: () => MigrationJob | null;
  setActiveNode: (node: Node | null) => void;
  setActiveEdge: (edge: Edge | null) => void;
  updateFiles: (files: FileObject[]) => void;
  updateNodeMappings: (nodeMappings: { [id: string]: NodeMappings }) => void;
  updateEdgeMappings: (edgeMappings: { [id: string]: EdgeMappings }) => void;
  setEditView: (editView: "EDGE" | "NODE" | undefined) => void;
  setGraphMode: (mode: "ADD_EDGE" | undefined) => void;
  setNodes: (nodes: FullItem<Partial<Record<"id", OptId> & NodeExtraValues>, "id">[]) => void;
  setEdges: (edges: FullItem<Partial<Record<"id", OptId> & EdgeExtraValues>, "id">[]) => void;
  setMigrationJob: (migrationJob: MigrationJob) => void;
  setName: (name: string) => void;
  resetStore: () => void;
}

export interface DataloaderStore {
  deploymentsDataloaders: { [key: string]: DeploymentDataloaderState };
}

export type FileMetadataArgs = { file: FileObject; hideDeleteButton?: boolean };
