//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { NotificationBar } from "../../components/NotificationBar";
import { BreadCrumbView } from "../BreadCrumbView";
import { useDashboardContext } from "../DashboardContextProvider";
import EditableTitle from "../title/EditableTitle";
import { TopMenuIconView } from "../TopMenuIconView";
import { DashboardTopMenuUserDropdowns } from "./DashboardTopMenuUserDropdowns";

export const DashboardTopMenu = () => {
  const { topMenuInfo, selectedOrganization } = useDashboardContext();
  const { notifications } = selectedOrganization;
  const isDisabled = topMenuInfo.getIsDisabled();

  if (isDisabled) {
    return null;
  }

  return (
    <Box zIndex={"banner"} top="0" width="100%" backgroundColor="white">
      <Flex direction="column" width="full">
        <NotificationBar notifications={notifications} />
        <BreadCrumbView items={topMenuInfo.getBreadCrumb()} />
        <Flex margin="16px 0 0 0" marginLeft="3" gap="4" width="full">
          <Box height="56px" width="56px" display="flex" alignItems="center" justifyContent="center">
            <TopMenuIconView imageSource={topMenuInfo.getImageSource()} />
          </Box>
          <EditableTitle title={topMenuInfo.getTitle()} save={topMenuInfo.getSaveTitles()} subTitle={topMenuInfo.getSubTitle()} />
          <DashboardTopMenuUserDropdowns />
        </Flex>
      </Flex>
    </Box>
  );
};
