import React from "react";
import { Grid, Container } from "semantic-ui-react";
import screenshot from "./scale.png";
import { Box } from "../../ui/_box";
import { useBreakpointValue } from "@chakra-ui/react";

const ScaleView = () => {
  const showOnDesktop = useBreakpointValue({ base: false, md: true }); // Show on medium screens (desktops) and above

  return (
    <Box position="relative">
      <Box padding={"60px 0 60px 0"}>
        <Container>
          <Grid columns="16" stackable padded relaxed tablet={8} mobile={12}>
            <Grid.Row centered>
              <Grid.Column computer={8} tablet={14} mobile={16} textAlign="left" verticalAlign="middle">
                <Box fontSize={"3rem"} color="var(--green-900)" lineheight={1.5} fontWeight={700}>
                  Elastic Scale & Multi-Tenancy
                </Box>
                <Box fontSize={"1.4rem"} fontWeight={400} lineheight={1.3} padding={"16px 0 0 0"}>
                  Elastically scale with all deployment models (OneShard & Sharded Clusters) and use the special ArangoDB Enterprise features for efficient
                  queries on distributed data. Avoid costly vertical scaling of competing solutions and rely on ArangoGraph’s seamless horizontal scaling.
                </Box>
              </Grid.Column>
              <Grid.Column computer={1} tablet={14} mobile={16} textAlign="center"></Grid.Column>
              <Grid.Column computer={7} tablet={14} mobile={16}>
                {showOnDesktop && (
                  <Box position="relative" top={"20px"} textAlign="right">
                    <img src={screenshot} alt="App Screenshot" width={"80%"} />
                  </Box>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
export default ScaleView;
