//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDataloaderStore } from "../DataloaderStore";

type ValidationRuleWithFn = {
  rule: RegExp | ((input: string) => boolean);
  message: string;
};

type ValidationRule = {
  rule: RegExp;
  message: string;
};

const getDatabaseNameErrors = (databaseName: string) => {
  const validations: ValidationRuleWithFn[] = [
    {
      rule: /^([a-zA-Z].*)$/,
      message: "Database name must always start with a letter.",
    },
    {
      rule: /^([a-zA-Z0-9\-_]*$)/,
      message: 'Only symbols, "_" and "-" are allowed.',
    },
    {
      rule: (input: string) => input.length <= 64,
      message: "Database name should not be longer than 64 characters.",
    },
    {
      rule: (input: string) =>
        !useDataloaderStore
          .getState()
          .getDeploymentDataloaderState()
          .databasesList.some((database) => database === input),
      message: "Database name is already in use.",
    },
  ];

  const failedValidations = validations.filter((validation) => {
    if (validation.rule instanceof RegExp) {
      return !validation.rule.test(databaseName) ? validation : undefined;
    } else {
      return !validation.rule(databaseName) ? validation : undefined;
    }
  });

  return failedValidations.map((validation) => validation.message);
};

const getGraphNameErrors = (graphName: string) => {
  const validations: ValidationRuleWithFn[] = [
    {
      rule: /^[a-zA-Z0-9_\-:\\.@()\\+,=;$!*%']+$/,
      message: "Only these characters are allowed: a-z, A-Z, 0-9 and  _ - : . @ ( ) + , = ; $ ! * ' %.",
    },
    {
      rule: (input: string) => input.length <= 254,
      message: "Graph name should not be longer than 254 characters.",
    },
  ];

  const failedValidations = validations.filter((validation) => {
    if (validation.rule instanceof RegExp) {
      return !validation.rule.test(graphName) ? validation : undefined;
    } else {
      return !validation.rule(graphName) ? validation : undefined;
    }
  });

  return failedValidations.map((validation) => validation.message);
};

const getCollectionNameErrors = (collectionName: string) => {
  const validations: ValidationRule[] = [
    {
      rule: /^[a-zA-Z]/,
      message: "Labels must always start with a letter.",
    },
    {
      rule: /^[a-zA-Z0-9\-_]*$/,
      message: 'Only symbols, "_" and "-" are allowed.',
    },
  ];

  const failedValidations = validations.filter((validation) => (!validation.rule.test(collectionName) ? validation : undefined));

  return failedValidations.map((validation) => validation.message);
};

export { getDatabaseNameErrors, getGraphNameErrors, getCollectionNameErrors };
