//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useField } from "formik";
import React from "react";
import { useDocumentTitle } from "../../components/useDocumentTitle";
import { Routes } from "../../routes";
import deploymentIcon from "../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/deployment.svg";
import { useDashboardContext } from "../DashboardContextProvider";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";
import { BreadCrumbItem } from "../TopMenuInfo";
import { TopMenuBar } from "../../components/TopMenuBar";

export const NewDeploymentTopMenu = () => {
  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const breadcrumbs = [
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem(project.name || "", Routes.dashboard_projectWithId(project.id || "")),
    new BreadCrumbItem("Deployments", Routes.dashboard_project_deploymentsWithId(project.id || "")),
  ];
  const [nameField] = useField("name");
  useDocumentTitle(`Deployment: ${nameField.value}`);
  return <TopMenuBar breadcrumbs={breadcrumbs} icon={deploymentIcon} title={nameField.value} />;
};
