import styled from "@emotion/styled";
import React from "react";
import { Grid, Icon, Message } from "semantic-ui-react";
import { FormActionButtonCreate } from "../../../ui/_buttons";
import { FlexBox } from "../../../ui/_flex";
import { IPrincipalViewHeader } from "../types";

const StyledPrincipalInfo = styled(Message)`
  height: 40px;
  line-height: 0.8 !important;
  width: 72%;
`;

const PrincipalViewHeader = ({ disableChange, statusMessage, onAddPrincipal }: IPrincipalViewHeader) => {
  return (
    <FlexBox justify="space-between" height="40px" data-testid="principalViewHeader">
      <StyledPrincipalInfo data-testid="principalInfo" warning={disableChange}>
        {!disableChange ? (
          <span data-testid="infoMessage">Configure your AWS Principals here</span>
        ) : (
          <span>
            <Grid>
              <Grid.Column width="1" verticalAlign="middle" textAlign="center">
                <Icon name="warning circle" data-testid="principalInfoIcon" />
              </Grid.Column>
              <Grid.Column width="15" data-testid="principalStatusMessage">
                {statusMessage}
              </Grid.Column>
            </Grid>
          </span>
        )}
      </StyledPrincipalInfo>
      <FormActionButtonCreate title="Add Principal" icon="plus" onClick={onAddPrincipal} disabled={disableChange} testID="addPrincipalButton" />
    </FlexBox>
  );
};

export default PrincipalViewHeader;
