import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

type NotebookPermissions = {
  canGetNotebook: boolean;
  canCreateNotebook: boolean;
  canDeleteNotebook: boolean;
  canUpdateNotebook: boolean;
  canPauseNotebook: boolean;
  canResumeNotebook: boolean;
  canListNotebook: boolean;
  canListNotebookModels: boolean;
  canGetMLServices: boolean;
};

export const useNotebookPermissions = (): NotebookPermissions => {
  const { checkDeploymentPermission } = useCheckPermissionByUrl();
  const canGetNotebook = checkDeploymentPermission("notebook.notebook.get");
  const canCreateNotebook = checkDeploymentPermission("notebook.notebook.create");
  const canDeleteNotebook = checkDeploymentPermission("notebook.notebook.delete");
  const canUpdateNotebook = checkDeploymentPermission("notebook.notebook.update");
  const canPauseNotebook = checkDeploymentPermission("notebook.notebook.pause");
  const canResumeNotebook = checkDeploymentPermission("notebook.notebook.resume");
  const canListNotebook = checkDeploymentPermission("notebook.notebook.list");
  const canListNotebookModels = checkDeploymentPermission("notebook.model.list");
  const canGetMLServices = checkDeploymentPermission("ml.mlservices.get");

  return {
    canGetNotebook,
    canCreateNotebook,
    canDeleteNotebook,
    canUpdateNotebook,
    canPauseNotebook,
    canResumeNotebook,
    canListNotebook,
    canListNotebookModels,
    canGetMLServices,
  };
};
