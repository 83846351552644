//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, InputOnChangeData, Grid } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser, VerifyUserMobilePhoneRequest as ApiVerifyUserMobilePhoneRequest } from "../../api/iam/v1/iam";
import {
  Loading,
  ContentSegment,
  Processing,
  ErrorMessage,
  OnboardingLogo,
  OnboardingName,
  OnboardingDescription,
  OnboardingCenteredContentActions,
  CenteredContentAction,
  TextLink,
  CenteredContentForm,
  OnboardingButton,
  OnboardingFootNote,
  LeftAligned,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { Routes } from "../../routes";

import homeImage from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { ITracking } from "../../tracking/api";
import DocumentTitle from "react-document-title";
import { reportError } from "../../errors/reporting";

interface IPhoneNumberVerificationCodeViewArgs extends IPhoneNumberVerificationCodeState, RouteComponentProps {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  user?: ApiUser;
  onResendVerificationCode: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  verifyCode: () => void;
}

const PhoneNumberVerificationCodeView = ({ ...args }: IPhoneNumberVerificationCodeViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Verifying code, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <OnboardingLogo>
      <img style={{ width: "100%", height: "100%" }} src={homeImage} alt="logo" />
    </OnboardingLogo>
    <OnboardingName>Please enter the 6-digit code we just sent</OnboardingName>
    {args.user && <OnboardingDescription>{args.user.mobile_phone}</OnboardingDescription>}
    {args.user ? (
      <CenteredContentForm>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={6} />
            <Grid.Column width={4}>
              <LeftAligned>
                <Form.Input
                  autoFocus
                  size="big"
                  label="Verification code"
                  placeholder="Verification code"
                  name="code"
                  value={args.code}
                  onChange={args.updateFormInputChange}
                />
              </LeftAligned>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <OnboardingButton>
          <Form.Button primary size="big" disabled={!args.active} content="Verify" onClick={args.verifyCode} />
        </OnboardingButton>
      </CenteredContentForm>
    ) : (
      <Loading />
    )}
    <OnboardingCenteredContentActions>
      <CenteredContentAction>
        <TextLink onClick={args.onResendVerificationCode} disabled={args.resendingVerificationCode}>
          Resend verification code
        </TextLink>
      </CenteredContentAction>
      <CenteredContentAction>
        <TextLink
          onClick={() => {
            args.history.replace(Routes.onboarding_user_phone_number);
          }}
        >
          Change number
        </TextLink>
      </CenteredContentAction>
    </OnboardingCenteredContentActions>
    <OnboardingFootNote>
      If you do not receive a text message within a few minutes, try "Resend verification code" or use "Request Help" in the top of this page to contact us.
    </OnboardingFootNote>
  </ContentSegment>
);

// Interface decribing the properties of the PhoneNumberVerificationCode component
interface IPhoneNumberVerificationCodeProps extends IWithRefreshProps, RouteComponentProps {
  tracking: ITracking;
  onPhoneNumberVerified: (userId: string) => void;
}

// Interface decribing the state of the PhoneNumberVerificationCode component
interface IPhoneNumberVerificationCodeState {
  user?: ApiUser;
  errorMessage?: string;
  resendingVerificationCode: boolean;
  processing: boolean;
  code: string;
}

// Component to enter a phone number verification code
class PhoneNumberVerificationCode extends Component<IPhoneNumberVerificationCodeProps, IPhoneNumberVerificationCodeState> {
  state = {
    user: undefined,
    errorMessage: undefined,
    resendingVerificationCode: false,
    processing: false,
    code: "",
  } as IPhoneNumberVerificationCodeState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "code":
        this.setState({ code: args.value });
        break;
    }
  };

  onResendVerificationCode = async () => {
    const user = this.state.user;
    if (user) {
      try {
        this.setState({ resendingVerificationCode: true, errorMessage: undefined });
        await apiClients.iamClient.ResendUserMobilePhoneVerification();
      } catch (e) {
        this.setState({ errorMessage: `Resending verification code failed: ${e}` });
        reportError(e);
      }
      this.setState({ resendingVerificationCode: false });
    }
  };

  verifyCode = async () => {
    try {
      const user = await apiClients.iamClient.GetThisUser();
      const userID = user ? user.id || "" : "";
      if (userID) {
        this.setState({ processing: true, errorMessage: undefined });
        const req = {
          code: this.state.code,
        } as ApiVerifyUserMobilePhoneRequest;
        await apiClients.iamClient.VerifyUserMobilePhone(req);
        this.props.tracking.trackPhoneNumberVerified();
        this.props.onPhoneNumberVerified(userID);
      }
    } catch (e) {
      this.setState({ errorMessage: `User update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
  }

  render() {
    return (
      <DocumentTitle title="Onboarding: Verify mobile phone number">
        <PhoneNumberVerificationCodeView
          {...this.props}
          {...this.state}
          active={!this.state.processing}
          handleDismissError={this.handleDismissError}
          onResendVerificationCode={this.onResendVerificationCode}
          updateFormInputChange={this.updateFormInputChange}
          verifyCode={this.verifyCode}
        />
      </DocumentTitle>
    );
  }
}

export default withRefresh()(PhoneNumberVerificationCode);
