//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Divider, Form, Header } from "semantic-ui-react";
import { useDataloaderStore } from "../../DataloaderStore";
import { NodeFileDataSelector } from "./NodeFileDataSelector";
import { NodeFileHeaders } from "./NodeFileHeaders";
import { NodeLabel } from "./NodeLabel";

export const NodeEditView = () => {
  const { editView } = useDataloaderStore().getDeploymentDataloaderState();

  if (editView !== "NODE") return null;

  return (
    <>
      <Header>Node details</Header>
      <Divider hidden />
      <Form>
        <NodeLabel />
        <NodeFileDataSelector />
        <NodeFileHeaders />
      </Form>
    </>
  );
};
