//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import {
  AuditLogList as ApiAuditLogList,
  AuditLogTopic as ApiAuditLogTopic,
  AuditLogArchiveList as ApiAuditLogArchiveList,
  ListAuditLogsRequest as ApiListAuditLogsRequest,
  ListAuditLogArchivesRequest as ApiListAuditLogArchivesRequest,
  Organization as ApiOrganization,
} from "../../api/lib";
import { ContentActionButtonNew, ErrorMessage, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { Permission, ResourceType } from "../../util/PermissionCache";
import AuditLogList from "./AuditLogList";
import AuditLogArchiveList from "./AuditLogArchiveList";
import AuditLogEventList from "./AuditLogEventList";
import CreateAuditLogModal from "./CreateAuditLogModal";

interface IOrganizationAuditLogTabProps {
  organization: ApiOrganization;
  loading: boolean;
  refreshNow: ((callback: () => Promise<void>) => Promise<void>) | undefined;
  hasPermissionByUrl: ((url: string, type: ResourceType, permission: Permission) => boolean) | undefined;
}

interface IOrganizationAuditLogTabState {
  errorMessage?: string;
  auditLogs?: ApiAuditLogList;
  auditLogArchives?: ApiAuditLogArchiveList;
  prevOrganizationId?: string;
  refreshNeeded: boolean;
  creating: boolean;
  all_topics?: ApiAuditLogTopic[];
  selected_auditlog_id?: string;
  selected_auditlogarchive_id?: string;
}

// The component to show the audit logs inside an organization as a list.
class OrganizationAuditLogTab extends Component<IOrganizationAuditLogTabProps, IOrganizationAuditLogTabState> {
  state = {
    errorMessage: undefined,
    auditLogs: undefined,
    auditLogArchives: undefined,
    prevOrganizationId: undefined,
    refreshNeeded: false,
    creating: false,
    selected_auditlog_id: undefined,
    selected_auditlogarchive_id: undefined,
  } as IOrganizationAuditLogTabState;

  static getDerivedStateFromProps(props: IOrganizationAuditLogTabProps, state: IOrganizationAuditLogTabState) {
    if (props.organization.id != state.prevOrganizationId) {
      return {
        prevOrganizationId: props.organization.id,
        refreshNeeded: true,
        creating: false,
        selected_auditLog_id: undefined,
      };
    }
    return {};
  }

  componentDidMount() {
    this.refreshAuditLogs();
    this.refreshAuditLogArchives();
    this.refreshAuditLogTopics();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshAuditLogs();
        this.refreshAuditLogArchives();
        this.refreshAuditLogTopics();
      });
    }
  }

  reloadAuditLogTopics = async () => {
    const list = await apiClients.auditClient.ListAuditLogTopics({});
    this.setState({ all_topics: list.items });
  };

  refreshAuditLogTopics = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogTopics);
  };

  reloadAuditLogs = async () => {
    const req = {
      organization_id: this.props.organization.id,
      include_deleted: true, // On organization level we do include deleted audit logs
    } as ApiListAuditLogsRequest;
    const list = await apiClients.auditClient.ListAuditLogs(req);
    this.setState({ auditLogs: list });
    const items = list.items || [];
    if (items.length === 1) {
      this.setState({ selected_auditlog_id: items[0].id }, this.refreshAuditLogArchives);
    }
  };

  refreshAuditLogs = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogs);
  };

  reloadAuditLogArchives = async () => {
    if (!!this.state.selected_auditlog_id) {
      const req = {
        auditlog_id: this.state.selected_auditlog_id,
      } as ApiListAuditLogArchivesRequest;
      const list = await apiClients.auditClient.ListAuditLogArchives(req);
      this.setState({ auditLogArchives: list });
      const items = list.items || [];
      if (items.length === 1) {
        this.setState({ selected_auditlogarchive_id: items[0].id });
      }
    } else {
      this.setState({ auditLogArchives: undefined, selected_auditlogarchive_id: undefined });
    }
  };

  refreshAuditLogArchives = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchives);
  };

  getAuditLogName = (id: string) => {
    const auditLogs = this.state.auditLogs || {};
    const auditLogItems = auditLogs.items || [];
    const auditLog = auditLogItems.find((c) => c.id == id);
    if (auditLog) {
      return auditLog.name;
    }
    return "";
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onCreate = () => {
    this.setState({ creating: true });
  };
  onCloseCreate = () => {
    this.setState({ creating: false });
  };

  onSelectAuditLog = (id: string) => {
    this.setState({ selected_auditlog_id: id, selected_auditlogarchive_id: undefined, auditLogArchives: undefined }, this.refreshAuditLogArchives);
  };
  onSelectAuditLogArchive = (id: string) => {
    this.setState({ selected_auditlogarchive_id: id });
  };

  render() {
    const hasPermission = (p: Permission) => {
      const hasPermissionByUrl = this.props.hasPermissionByUrl;
      return !!(hasPermissionByUrl && hasPermissionByUrl(this.props.organization.url || "", ResourceType.AuditLog, p));
    };

    const creating = this.state.creating;
    const is_org_deleted = !!this.props.organization.is_deleted;
    const has_auditlog_create = hasPermission("audit.auditlog.create");
    const allTopics = this.state.all_topics || [];
    const has_selected_auditlog = !!this.state.selected_auditlog_id;
    const has_selected_auditlogarchive = !!this.state.selected_auditlogarchive_id;

    return (
      <div>
        <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
        {creating && (
          <CreateAuditLogModal
            {...this.props}
            all_topics={allTopics}
            onNewAuditLogCreated={() => {
              this.refreshAuditLogs();
              this.onCloseCreate();
            }}
            onClose={this.onCloseCreate}
          />
        )}
        <Section>
          <SectionHead>
            <SectionHeader
              title="Audit logs"
              help={
                <div>
                  <p className="para">
                    Audit logs capture a record of everything that done in an ArangoGraph Insights Platform deployment or in your ArangoGraph Insights Platform
                    organization.
                  </p>
                  <p className="para">Each event that needs to be captured can be recorded in the cloud, or sent to a server you own when the event happens.</p>
                </div>
              }
            />
            <SectionButtons>
              {has_auditlog_create && !is_org_deleted && <ContentActionButtonNew primary content="New audit log" onClick={this.onCreate} />}
            </SectionButtons>
          </SectionHead>
          <SectionContent>
            <AuditLogList
              {...this.props}
              ownerType="organization"
              selected_auditlog_id={this.state.selected_auditlog_id}
              all_topics={allTopics}
              auditLogs={this.state.auditLogs}
              onRefreshList={this.refreshAuditLogs}
              onClickDeselect={() => {}}
              onClickSelect={this.onSelectAuditLog}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionHead>
            <SectionHeader
              title="Audit log archives"
              help={
                <div>
                  <p className="para">Audit log archives store audit events in the cloud.</p>
                  <p className="para">Each audit log archive is used for a specific deployment or events coming from the ArangoGraph Insights Platform.</p>
                  <p className="para">Audit log archives are created on demand.</p>
                </div>
              }
            />
          </SectionHead>
          <SectionContent>
            {!has_selected_auditlog && <div>No audit log has been selected.</div>}
            {has_selected_auditlog && (
              <AuditLogArchiveList
                {...this.props}
                selected_auditlogarchive_id={this.state.selected_auditlogarchive_id}
                organization_id={this.props.organization.id || ""}
                auditLogArchives={this.state.auditLogArchives}
                onRefreshList={this.refreshAuditLogArchives}
                onClickSelect={this.onSelectAuditLogArchive}
              />
            )}
          </SectionContent>
        </Section>
        <Section>
          <SectionHead>
            <SectionHeader
              title="Audit log events"
              help={
                <div>
                  <p className="para">
                    Audit log events are recorded, when an audit log has been created, for every action in deployments and the ArangoGraph Insights Platform.
                  </p>
                  <p className="para">View these events, filtered by time and/or topic.</p>
                </div>
              }
            />
          </SectionHead>
          <SectionContent>
            {!has_selected_auditlogarchive && <div>No audit log archive has been selected.</div>}
            {has_selected_auditlogarchive && (
              <AuditLogEventList
                {...this.props}
                auditLogId={this.state.selected_auditlog_id || ""}
                auditLogArchiveId={this.state.selected_auditlogarchive_id || ""}
                all_topics={allTopics}
              />
            )}
          </SectionContent>
        </Section>
      </div>
    );
  }
}

export default OrganizationAuditLogTab;
