//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { create } from "zustand";
import { Project, Provider, Region } from "../../api/lib";

interface IOrganisationStore {
  projects: {
    [key: string]: Project;
  };
  providers: {
    [key: string]: Provider;
  };
  regions: {
    [key: string]: Region;
  };
  getProject: (id: string) => Project | undefined;
  setProject: (project: Project) => void;
  getProvider: (id: string) => Provider | undefined;
  setProvider: (updatedProject: Provider) => void;
  getRegion: (id: string) => Region | undefined;
  setRegion: (updatedProject: Region) => void;
}

const useOrganisationStore = create<IOrganisationStore>((set, get) => ({
  projects: {},
  providers: {},
  regions: {},
  getProject: (id) => get().projects[id],
  setProject: (project) => set(({ projects }) => ({ projects: { ...projects, [project.id || ""]: project } })),
  getProvider: (id) => get().providers[id],
  setProvider: (provider) => set(({ providers }) => ({ providers: { ...providers, [provider.id || ""]: provider } })),
  getRegion: (id) => get().regions[id],
  setRegion: (region) => set(({ regions }) => ({ regions: { ...regions, [region.id || ""]: region } })),
}));

export default useOrganisationStore;
