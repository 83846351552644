//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { create } from "zustand";
import { Deployment, Provider, Region } from "../../api/lib";
import { DeploymentStoreRequiredProps } from "./types";

export const useDeploymentStore = create<DeploymentStoreRequiredProps>((set) => ({
  provider: {},
  region: {},
  deployment: {},
  setDeployment: (dpl: Deployment) => set((state) => ({ ...state, deployment: dpl })),
  setProviderDetails: (provider: Provider) => set((state) => ({ ...state, provider })),
  setRegionDetails: (region: Region) => set((state) => ({ ...state, region })),
}));
