//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDisableTopMenu } from "../../../components/useDisableTopMenu";
import { Routes } from "../../../routes";
import { useFetchDeployment } from "../../editDeployment/useFetchDeployment";
import { CreateRoleBindingSection } from "../../policy/CreateRoleBindingSection";
import { PolicyContextProvider } from "../../policy/PolicyContextProvider";
import { useProjectDetailsContext } from "../../project/ProjectDetailsContextProvider";
import { CreateDeploymentRoleBindingsTopMenu } from "./CreateDeploymentRoleBindingsTopMenu";

export const CreateDeploymentRoleBindings = () => {
  useDisableTopMenu();
  const { params } = useRouteMatch<{ deploymentId: string }>();
  const { deploymentId } = params;
  const { data: deployment } = useFetchDeployment({ deploymentId });
  const { project } = useProjectDetailsContext();
  const history = useHistory();
  const goToPolicy = () => {
    history.push(Routes.dashboard_project_deployment_policyWithId(project.id || "", deployment?.id || ""));
  };
  return (
    <PolicyContextProvider resourceUrl={deployment?.url}>
      <Grid templateRows={"auto minmax(0, 1fr)"} overflow="hidden" height="100vh">
        <CreateDeploymentRoleBindingsTopMenu />
        <CreateRoleBindingSection onSuccess={goToPolicy} onCancel={goToPolicy} />
      </Grid>
    </PolicyContextProvider>
  );
};
