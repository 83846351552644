//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Grid } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React from "react";
import { Prompt } from "react-router-dom";
import { usePreventReload } from "../../components/usePreventReload";
import { NewDeploymentForm } from "./NewDeploymentForm";
import { NewDeploymentTopMenu } from "./NewDeploymentTopMenu";

export const NewDeploymentFormContainer = () => {
  const { dirty, submitCount } = useFormikContext();
  usePreventReload({ dirty });
  return (
    <>
      <Prompt message="Are you sure you want to leave this page? All changes will be lost." when={dirty && submitCount === 0} />
      <Grid templateRows={"auto 1fr"} overflow="hidden" height="100vh">
        <NewDeploymentTopMenu />
        <NewDeploymentForm />
      </Grid>
    </>
  );
};
