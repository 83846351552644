//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import apiClients from "../../api/apiclients";
import {
  AuditLogTopic as ApiAuditLogTopic,
  AuditLogArchiveList as ApiAuditLogArchiveList,
  Deployment as ApiDeployment,
  ListAuditLogArchivesRequest as ApiListAuditLogArchivesRequest,
  Organization as ApiOrganization,
  isNotFound,
} from "../../api/lib";
import { ErrorMessage, Section, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import AuditLogArchiveList from "./AuditLogArchiveList";
import AuditLogEventList from "./AuditLogEventList";

export interface IDeploymentAuditLogTabProps extends IWithRefreshProps, RouteComponentProps {
  organization: ApiOrganization;
  deployment: ApiDeployment;
}

interface IDeploymentAuditLogTabState {
  errorMessage?: string;
  auditLogArchives?: ApiAuditLogArchiveList;
  prevOrganizationId?: string;
  refreshNeeded: boolean;
  all_topics?: ApiAuditLogTopic[];
  selected_auditlog_id?: string;
  selected_auditlogarchive_id?: string;
}

// The component to show the audit log archives used by a deployment as a list.
class DeploymentAuditLogTab extends Component<IDeploymentAuditLogTabProps, IDeploymentAuditLogTabState> {
  state = {
    errorMessage: undefined,
    auditLogs: undefined,
    auditLogArchives: undefined,
    prevOrganizationId: undefined,
    refreshNeeded: false,
    creating: false,
    selected_auditlog_id: undefined,
    selected_auditlogarchive_id: undefined,
  } as IDeploymentAuditLogTabState;

  static getDerivedStateFromProps(props: IDeploymentAuditLogTabProps, state: IDeploymentAuditLogTabState) {
    if (props.organization.id != state.prevOrganizationId) {
      return {
        prevOrganizationId: props.organization.id,
        refreshNeeded: true,
        creating: false,
        selected_auditLog_id: undefined,
        selected_auditlogarchive_id: undefined,
      };
    }
    return {};
  }

  componentDidMount() {
    this.refreshAuditLogs();
    this.refreshAuditLogArchives();
    this.refreshAuditLogTopics();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, () => {
        this.refreshAuditLogs();
        this.refreshAuditLogArchives();
        this.refreshAuditLogTopics();
      });
    }
  }

  reloadAuditLogTopics = async () => {
    const list = await apiClients.auditClient.ListAuditLogTopics({});
    this.setState({ all_topics: list.items });
  };

  refreshAuditLogTopics = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogTopics);
  };

  reloadAuditLogs = async () => {
    try {
      const depl = this.props.deployment;
      const auditlog = await apiClients.auditClient.GetAuditLogAttachedToProject({ id: depl.project_id });
      this.setState({ selected_auditlog_id: auditlog.id }, this.refreshAuditLogArchives);
    } catch (e) {
      if (isNotFound(e)) {
        this.setState({ selected_auditlog_id: undefined });
      } else {
        throw e;
      }
    }
  };

  refreshAuditLogs = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogs);
  };

  reloadAuditLogArchives = async () => {
    if (!!this.state.selected_auditlog_id) {
      const req = {
        auditlog_id: this.state.selected_auditlog_id,
        deployment_id: this.props.deployment.id,
      } as ApiListAuditLogArchivesRequest;
      const list = await apiClients.auditClient.ListAuditLogArchives(req);
      this.setState({ auditLogArchives: list });
      const items = list.items || [];
      if (items.length === 1) {
        this.setState({ selected_auditlogarchive_id: items[0].id });
      }
    } else {
      this.setState({ auditLogArchives: undefined, selected_auditlogarchive_id: undefined });
    }
  };

  refreshAuditLogArchives = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadAuditLogArchives);
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onSelectAuditLog = (id: string) => {
    this.setState({ selected_auditlog_id: id, selected_auditlogarchive_id: undefined, auditLogArchives: undefined }, this.refreshAuditLogArchives);
  };
  onSelectAuditLogArchive = (id: string) => {
    this.setState({ selected_auditlogarchive_id: id });
  };

  render() {
    const allTopics = this.state.all_topics || [];
    const has_selected_auditlog = !!this.state.selected_auditlog_id;
    const has_selected_auditlogarchive = !!this.state.selected_auditlogarchive_id;

    return (
      <div>
        <div>
          <ErrorMessage message={this.state.errorMessage} active={!!this.state.errorMessage} onDismiss={this.onDismissError} />
          <Section>
            <SectionHead>
              <SectionHeader
                title="Audit log archives"
                help={
                  <div>
                    <p className="para">Audit log archives store audit events in the cloud.</p>
                    <p className="para">Each listed audit log archive is used for this deployment.</p>
                    <p className="para">Audit log archives are created on demand.</p>
                  </div>
                }
              />
            </SectionHead>
            <SectionContent>
              {!has_selected_auditlog && <div>No audit log has been selected for this deployment.</div>}
              {has_selected_auditlog && (
                <AuditLogArchiveList
                  {...this.props}
                  selected_auditlogarchive_id={this.state.selected_auditlogarchive_id}
                  organization_id={this.props.organization.id || ""}
                  auditLogArchives={this.state.auditLogArchives}
                  onRefreshList={this.refreshAuditLogArchives}
                  onClickSelect={this.onSelectAuditLogArchive}
                  hide_used_for
                />
              )}
            </SectionContent>
          </Section>
          {has_selected_auditlog && (
            <Section>
              <SectionHead>
                <SectionHeader
                  title="Audit log events"
                  help={
                    <div>
                      <p className="para">Audit log events are recorded, when an audit log has been created, for every action in the deployment.</p>
                      <p className="para">View these events, filtered by time and/or topic.</p>
                    </div>
                  }
                />
              </SectionHead>
              <SectionContent>
                {!has_selected_auditlogarchive && <div>No audit log archive has been selected.</div>}
                {has_selected_auditlogarchive && (
                  <AuditLogEventList
                    {...this.props}
                    refreshNow={this.props.refreshNow}
                    auditLogId={this.state.selected_auditlog_id || ""}
                    auditLogArchiveId={this.state.selected_auditlogarchive_id || ""}
                    all_topics={allTopics}
                  />
                )}
              </SectionContent>
            </Section>
          )}
        </div>
      </div>
    );
  }
}

export default withRefresh()(DeploymentAuditLogTab);
