//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { PageSpinner, ReactTable, useSortableReactTable } from "@arangodb/ui";
import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClients from "../../../api/apiclients";
import { CACertificate, Deployment, DeploymentList } from "../../../api/lib";
import { DateDisplay } from "../../../components/DateDisplay";
import { Pagination } from "../../../components/Pagination";
import { Routes } from "../../../routes";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../../ui/lib";
import { Permission, ResourceType } from "../../../util/PermissionCache";
import { useWithRefresh } from "../../../util/WithRefreshContext";
import { BudgetHelper } from "../../BudgetHelper";
import { useDashboardContext } from "../../DashboardContextProvider";
import { setHasSkippedUseCaseSelection } from "../../deployment/use-cases/utils";
import { HistoryHelper } from "../../HistoryHelper";
import { useFetchOrganizationDeployments } from "../../newDeployment/useFetchOrganizationDeployments";
import { FirstDeploymentMessage } from "../FirstDeploymentMessage";
import { DeploymentNameCell } from "./DeploymentNameCell";
import { DeploymentProjectCell } from "./DeploymentProjectCell";
import { DeploymentRegionCell } from "./DeploymentRegionCell";
import { DeploymentStatusCell } from "./DeploymentStatusCell";

const useFetchCACertificates = ({ projectId }: { projectId: string }) => {
  const fetchCACertificates = async () => {
    const listOptions = { context_id: projectId };
    return await apiClients.cryptoClient.ListCACertificates(listOptions);
  };
  return useQuery([projectId, "caCertificates"], fetchCACertificates, {
    enabled: !!projectId,
  });
};

const useIsFirstFreeDeployment = ({
  deployments,
  isLoadingDeployments,
  defaultCertificate,
}: {
  deployments: DeploymentList | undefined;
  isLoadingDeployments: boolean;
  defaultCertificate: CACertificate | undefined;
}) => {
  const { hasPermissionByUrl } = useWithRefresh();
  const { isFreeTier, selectedOrganization, projects } = useDashboardContext();

  const projectList = projects || {};
  const projectsItems = projectList.items || [];
  const project = projectsItems[0];

  const deploymentList = deployments || {};
  const deploymentItems = deploymentList.items || [];
  const totalDeploymentForTier = selectedOrganization.total_deployments?.[selectedOrganization.tier?.id || ""] || 0;
  const hasDeployments = !!deploymentItems.length;
  const hasProject = !!project;
  const hasMultipleProjects = !!project && projectsItems.length > 1;
  const hasProjPermission = (p: Permission) => !!(hasPermissionByUrl && project && hasPermissionByUrl(project.url || "", ResourceType.Project, p));
  const hasCreateDeploymentPermission = hasProjPermission("data.deployment.create");
  const isCreateDeploymentDisabled = !hasCreateDeploymentPermission || BudgetHelper.disableDeploymentCreate(deploymentList, isLoadingDeployments);

  const firstDeployment = !(
    isCreateDeploymentDisabled ||
    !hasProject ||
    hasMultipleProjects ||
    !defaultCertificate ||
    hasDeployments ||
    totalDeploymentForTier > 0
  );

  const isFirstFreeDeployment = isFreeTier && firstDeployment;

  return isFirstFreeDeployment;
};

const columnHelper = createColumnHelper<Deployment>();

const TABLE_COLUMNS = [
  columnHelper.accessor("name", {
    header: "Name",
    id: "name",
    cell: (props) => {
      return <DeploymentNameCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("project_id", {
    header: "Project",
    id: "project_id",
    cell: (props) => {
      return <DeploymentProjectCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("region_id", {
    header: "Region",
    id: "region_id",
    cell: (props) => {
      return <DeploymentRegionCell deployment={props.row.original} />;
    },
  }),
  columnHelper.accessor("created_at", {
    header: "Created",
    id: "created_at",
    cell: (props) => {
      return <DateDisplay date={props.getValue()} />;
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    id: "status",
    cell: (props) => {
      return <DeploymentStatusCell deployment={props.row.original} />;
    },
  }),
];

export const DashboardDeploymentList = () => {
  const history = useHistory();
  const { projects, topMenuInfo } = useDashboardContext();
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const {
    data: deploymentsList,
    isLoading: isLoadingDeployments,
    fetchStatus,
    isPreviousData,
    refetch: refetchDeployments,
  } = useFetchOrganizationDeployments({
    pageNumber: page,
    pageSize,
  });

  const totalUsed = deploymentsList?.budget?.used;
  const totalPages = totalUsed ? Math.ceil(totalUsed / pageSize) : 0;
  const tableInstance = useSortableReactTable({
    columns: TABLE_COLUMNS,
    data: deploymentsList?.items || [],
    enableSorting: false,
  });

  const projectId = projects?.items?.[0]?.id || "";

  const onNewDeploymentCreated = (id: string) => {
    setHasSkippedUseCaseSelection(false);
    HistoryHelper.push(history, Routes.dashboard_project_deployment_detailsWithId(projectId, id), topMenuInfo.getTitle());
    refetchDeployments();
  };

  const { isLoading: isLoadingCACertificates, data: caCertificates } = useFetchCACertificates({ projectId });
  const defaultCertificate = _.find(caCertificates?.items || [], (certificate) => !!certificate.is_default);

  const isFirstFreeDeployment = useIsFirstFreeDeployment({
    deployments: deploymentsList,
    isLoadingDeployments,
    defaultCertificate,
  });

  if (!isPreviousData && (isLoadingDeployments || isLoadingCACertificates)) {
    return <PageSpinner isLoading />;
  }

  if (isFirstFreeDeployment) {
    return <FirstDeploymentMessage defaultCertificate={defaultCertificate} onNewDeploymentCreated={onNewDeploymentCreated} />;
  }

  return (
    <Section>
      <SectionHead>
        <SectionHeader title={<span>Deployments</span>} />
      </SectionHead>
      <SectionContent>
        <PageSpinner isLoading={isLoadingDeployments || (isPreviousData && fetchStatus === "fetching")} />
        <Flex direction="column" gap="2">
          <ReactTable
            table={tableInstance}
            tableProps={{
              variant: "striped",
            }}
          />
          <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        </Flex>
      </SectionContent>
    </Section>
  );
};
