//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useEffect, useState } from "react";
import { NetworkEvents, Data, Edge, Node, IdType } from "vis-network";
import { Network } from "vis-network/standalone/umd/vis-network.min";
import { DataSet } from "vis-data";
import {
  DATALOADER_DIAGRAM_CONFIG,
  edgeNotReadyForImportExtraOptions,
  editModeGraphOptions,
  edgeReadyForImportExtraOptions,
  nodeNotReadyForImportExtraOptions,
  nodeReadyForImportExtraOptions,
} from "../graphOptions";
import { useGraphEvents } from "./useGraphEvents";
import { GraphProvider } from "../types";
import { v4 } from "uuid";
import { useDataloaderStore } from "../../DataloaderStore";
import { useDatasetEvents } from "./useDatasetEvents";
import { isEdgeReadyForImport, isNodeReadyForImport } from "../../utils";
import { NodeMappings, EdgeMappings } from "../../types";

export const useGraph = ({ data, canvasRenderTargetID }: GraphProvider) => {
  const {
    setActiveEdge,
    setActiveNode,
    getActiveNode: getActiveNodeFromGlobalStore,
    getActiveEdge: getActiveEdgeFromGlobalStore,
    setGraphMode,
    setEditView,
    updateNodeMappings,
    updateEdgeMappings,
  } = useDataloaderStore();

  const { edgeMappings, nodeMappings } = useDataloaderStore().getDeploymentDataloaderState();

  const [nodes] = useState(new DataSet(data.nodes as Node[]));
  const [edges] = useState(new DataSet(data.edges as Edge[]));
  const [graphNet, setGraphNet] = useState<Network>();

  const [showEdgeManipulator, toggleEdgeMenu] = useState(false);
  const [showNodeManipulator, toggleNodeMenu] = useState(false);
  const [position, setPosition] = useState({ left: "0px", top: "0px" });
  const [canvasZoom, setCanvasZoom] = useState({ scale: 1, direction: "+" });

  useDatasetEvents({ nodes, edges });

  const removeNodeSelectionFromStore = () => {
    setActiveNode(null);
  };

  const removeEdgeSelectionFromStore = () => {
    setActiveEdge(null);
  };

  /**
   * This function removes the node and edge selection from the store
   */
  const removeNodeAndEdgeSelectionFromStore = () => {
    removeNodeSelectionFromStore();
    removeEdgeSelectionFromStore();
  };

  /**
   * This function hides the node and edge manipulator menus
   */
  const hideAllMenu = () => {
    toggleEdgeMenu(false);
    toggleNodeMenu(false);
  };

  const registerNetworkEvents = (net: Network, event: NetworkEvents, callback: (params?: any) => void) => {
    net.on(event, callback);
  };

  const {
    handleDragStart,
    handleCanvasHold,
    handleCanvasClick,
    handleZoom,
    handleCanvasContextClick,
    handleEdgeClick,
    handleNodeClick,
    handleEdgeDeselect,
    handleNodeDeselect,
  } = useGraphEvents({
    hideAllMenu,
    setPosition,
    toggleNodeMenu,
    toggleEdgeMenu,
    setCanvasZoom,
    setEditView,
    selectNode: (node?: number) => {
      if (node) {
        setActiveNode({ ...nodes.get(node) });
        return;
      }
      setActiveEdge(null);
    },
    selectEdge: (edge?: number) => {
      if (edge) {
        setActiveEdge({ ...edges.get(edge) });
        return;
      }
      setActiveEdge(null);
    },
  });

  /**
   * This function returns a new Node that can be used to add to the graph. Can be used for creating the first node or the next set of nodes.
   * @returns {Node} node
   */
  const getNewNodeItem = (): Node => {
    const id = v4();
    return {
      id,
    };
  };

  /**
   *
   * This function connects an edge from the source node to the target node. If either of one is missing, it does nothing and returns nothing.
   * For the new edge, it auto-generates the UUID to ensure uniqueness of each edge.
   *
   * @param {string} sourceNodeId
   * @param {string} targetNodeId
   *
   * @returns {void} void
   */
  const connectEdge = (sourceNodeId: IdType, targetNodeId: string) => {
    console.log("enter connect edge");
    if (!sourceNodeId || !targetNodeId) return;

    const newEdgeDefinition = {
      ...edgeNotReadyForImportExtraOptions,
      id: v4(),
      from: sourceNodeId,
      to: targetNodeId,
    };
    edges.add(newEdgeDefinition);
  };

  /**
   * This function allows creating new nodes in the canvas. This won't have any out-degree edges
   * @returns {void}
   */
  const addNewNode = () => {
    nodes.add({ ...getNewNodeItem(), ...nodeNotReadyForImportExtraOptions });
    hideAllMenu();

    return;
  };

  /**
   *
   * This function adds a new node which use the previosly active node and connect it to that with an edge.
   * For the new node, it auto-generates the UUID to ensure uniqueness of each node.
   *
   * @returns {void} void
   */
  const addConnectedNode = () => {
    if (!graphNet) return;

    const activeNode = getNode();
    if (!activeNode) return;

    const newNode = getNewNodeItem();
    nodes.add({ ...newNode, ...nodeNotReadyForImportExtraOptions });
    connectEdge(String(activeNode.id), String(newNode.id));

    hideAllMenu();
  };

  /**
   * This returns the edge connecting the node. This includes both inward and outward edges.
   *
   * @param {number} nodeId
   * @returns {Edge | Edge[]} Edge or an array of Edges
   */
  const getEdgesToNode = (nodeId: string) => {
    return edges.get().filter((edge: Edge) => edge.to === nodeId || edge.from === nodeId);
  };

  /**
   *
   * This function removes a node from the graph's dataset.
   *
   * @returns {void} void
   */
  const removeNode = (node: Node) => {
    nodes.remove(node);
  };

  /**
   *
   * This function removes an edge from the graph's dataset.
   *
   * @returns {void} void
   */
  const removeEdge = (edgesToRemove: string[]) => {
    edges.remove(edgesToRemove);
  };

  const removeNodeMapping = (id: string) => {
    const newNodeMappings = { ...nodeMappings };
    delete newNodeMappings[id];
    updateNodeMappings(newNodeMappings);
  };

  const removeEdgeMappings = (edgesToRemove: Edge[]) => {
    const newEdgeMappings = { ...edgeMappings };

    Object.keys(newEdgeMappings).forEach((key) => {
      if (edgesToRemove.some((item) => item.id === key)) {
        delete newEdgeMappings[key];
      }
    });

    updateEdgeMappings(newEdgeMappings);
  };

  /**
   * This function deletes the active/selected node from the graph canvas. If the selectedNode is empty, then it will do nothing and return void.
   * As a side effect, it removes the active Node and Edge from the global store in order to ensure no leaks in operations and hides all the context menus that were open before this action.
   * @returns {void} void
   */
  const deleteNode = () => {
    if (!graphNet) return;

    const selectedNode = getNode();
    if (!selectedNode) return;

    removeNodeMapping(String(selectedNode.id));
    removeEdgeMappings(getEdgesToNode(String(selectedNode.id)));

    removeNode(selectedNode);

    const edgesIdsToRemove = getEdgesToNode(String(selectedNode.id)).map(({ id }) => String(id));

    removeEdge(edgesIdsToRemove);
    removeNodeAndEdgeSelectionFromStore();

    hideAllMenu();
    setEditView(undefined);
  };

  /**
   *
   * This function updates and existing node with the newly created Node value. To ensure updating, this should be an existing node or else it might throw an error.
   * As a side effect, it enables this node as the active node in the global store for continued operations.
   *
   * @param {Node} updatedNode
   */
  const updateNode = (updatedNode: Node, nodeMappings: { [id: string]: NodeMappings }) => {
    const isReadyForImport = isNodeReadyForImport(updatedNode, nodeMappings);
    const extraOptions = isReadyForImport ? nodeReadyForImportExtraOptions : nodeNotReadyForImportExtraOptions;

    nodes.update({ ...updatedNode, ...extraOptions });
    setActiveNode({ ...updatedNode, ...extraOptions });
  };

  /**
   * This function enables node editing mode in the store and hide the menu.
   */
  const editNode = () => {
    setEditView("NODE");
    hideAllMenu();
  };

  /**
   * This function updates and existing edge with the newly created edge value. To ensure updating, this should be an existing edge or else it might throw an error.
   * As a side effect, it enables this edge as the active edge in the global store for continued operations.
   * @param {Edge} updatedEdge
   */
  const updateEdge = (updatedEdge: Edge, edgeMappings: { [id: string]: EdgeMappings }) => {
    const isReadyForImport = isEdgeReadyForImport(updatedEdge, edgeMappings);

    const extraOptions = isReadyForImport ? edgeReadyForImportExtraOptions : edgeNotReadyForImportExtraOptions;
    edges.update({ ...updatedEdge, ...extraOptions });
    setActiveEdge({ ...updatedEdge, ...extraOptions });
  };

  /**
   *
   * This returns the active selected node from the global Dataloader store
   *
   * @returns {Node} node
   */

  const getNode = () => {
    return getActiveNodeFromGlobalStore();
  };

  /**
   * This function returns the edge based on the id that is passed from the dataset.
   *
   * @param {string} edgeId
   * @returns {Edge | null}
   */
  const getEdgeById = (edgeId: string) => {
    if (!edgeId) return;
    return edges.get().filter((e) => e.id === edgeId)[0];
  };

  /**
   * This function helps edit edge definition by selecting the active edge using the global store and then displaying anchors to allow moving edges
   */
  const editEdge = () => {
    if (!graphNet) return;

    setEditView("EDGE");

    const { id: selectedEdge } = getEdge() || {};
    graphNet.selectEdges([String(selectedEdge)]);

    hideAllMenu();
  };

  /**
   *
   * This function deletes the active edge. This active node is obtained from the dataloader store
   * @returns {void}
   */

  const deleteEdge = () => {
    const { id } = getEdge() || {};
    const selectedEdge = getEdgeById(String(id));

    if (!selectedEdge) return;
    removeEdge([String(id)]);

    hideAllMenu();
    setEditView(undefined);
  };

  /**
   * This function resets the graph options to the default configuration
   *
   * @returns {void}
   */
  const resetGraphOptions = () => {
    if (!graphNet) return;
    graphNet.setOptions(DATALOADER_DIAGRAM_CONFIG);
  };

  /**
   * This returns the list of Node dataset
   * @returns {Node[]} Node
   */
  const getNodeDataset = () => nodes.get();

  /**
   * This function disables the add edge mode
   *
   * @returns {void}
   */
  const stopNodeConnectionMode = () => {
    if (!graphNet) return;

    graphNet.disableEditMode();
    setGraphMode(undefined);

    resetGraphOptions();
  };

  /**
   * This function enables edge adding mode allowing to create new edges by dragging from source node to target node
   * @returns {void}
   */

  const enableAddEdgeMode = ({ onDisable, onEnable }: { onEnable?: () => void; onDisable?: () => void }) => {
    if (!graphNet) return;

    graphNet.unselectAll();

    graphNet.setOptions({
      ...editModeGraphOptions,
      manipulation: {
        ...DATALOADER_DIAGRAM_CONFIG.manipulation,
        addEdge: (data: Edge, callback: any) => {
          callback(data);
          stopNodeConnectionMode();
          onDisable && onDisable();
          console.log("manipulation: addEdge:  ENTERED");
          updateEdge(getEdgeById(String(data.id) || "") || {}, edgeMappings);
        },
      },
    });
    graphNet.addEdgeMode();
    onEnable && onEnable();
    hideAllMenu();
    setGraphMode("ADD_EDGE");
  };

  /**
   * This function enables edge edit mode allowing to change edge definitions
   * @returns {void}
   */
  const allowEdgeDefinitionChange = () => {
    if (!graphNet) return;

    graphNet.editEdgeMode();

    setEditView("EDGE");

    hideAllMenu();
  };

  /**
   *
   * This function enables the add edge mode in VisJs and also accepts callback for both enable and disable post calls.
   *
   * @param  {{ onEnable?: () => void; onDisable?: () => void }}
   * @returns void
   */
  const startNodeConnectionMode = ({ onDisable, onEnable }: { onEnable?: () => void; onDisable?: () => void }) => {
    enableAddEdgeMode({ onDisable, onEnable });
    removeNodeSelectionFromStore();
    removeEdgeSelectionFromStore();
  };

  /**
   * This function returns the active edge from the global Dataloader store
   * @returns {Edge} edge
   */
  const getEdge = () => {
    return getActiveEdgeFromGlobalStore();
  };

  /**
   * This returns the list of edge dataset
   * @returns {Edge[]} edges
   */
  const getEdgeDataset = () => edges.get();

  /**
   * This function registers all relevent graph events and renders it on the target DOM element
   */
  const renderGraphAndRegisterGraphEvents = () => {
    const graphContainer = document.getElementById(canvasRenderTargetID);
    if (!graphContainer) return;

    const network = new Network(graphContainer, { nodes, edges } as Data, DATALOADER_DIAGRAM_CONFIG);
    registerNetworkEvents(network, "dragStart", handleDragStart);
    registerNetworkEvents(network, "hold", handleCanvasHold);
    registerNetworkEvents(network, "click", (args) => handleCanvasClick(network, args));
    registerNetworkEvents(network, "zoom", handleZoom);
    registerNetworkEvents(network, "oncontext", (args) => handleCanvasContextClick(network, args));
    registerNetworkEvents(network, "selectEdge", (args) => handleEdgeClick(network, args));
    registerNetworkEvents(network, "selectNode", (args) => handleNodeClick(network, args));
    registerNetworkEvents(network, "deselectEdge", handleEdgeDeselect);
    registerNetworkEvents(network, "deselectNode", handleNodeDeselect);

    setGraphNet(network);
  };

  useEffect(() => {
    renderGraphAndRegisterGraphEvents();
    return () => graphNet && graphNet.destroy();
  }, []);

  return {
    showEdgeManipulator,
    showNodeManipulator,
    canvasZoom,
    position,
    addConnectedNode,
    addNewNode,
    deleteNode,
    getNode,
    updateNode,
    getNodeDataset,
    getEdgeDataset,
    editNode,
    editEdge,
    getEdge,
    deleteEdge,
    updateEdge,
    hideAllMenu,
    allowEdgeDefinitionChange,
    startNodeConnectionMode,
    stopNodeConnectionMode,
  };
};
