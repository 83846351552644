//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import { ListProvidersRequest } from "../../api/lib";
import apiClients from "../../api/apiclients";
import { useDashboardContext } from "../DashboardContextProvider";

export const useFetchProviders = () => {
  const { selectedOrganization } = useDashboardContext();
  const fetchAllProviders = async () => {
    const listReq: ListProvidersRequest = { organization_id: selectedOrganization.id };
    const data = await apiClients.platformClient.ListProviders(listReq);
    return data;
  };

  return useQuery([selectedOrganization.id, "providers"], fetchAllProviders, {
    refetchOnWindowFocus: false,
  });
};
