//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import { useDataloaderStore } from "../../DataloaderStore";
import { useGraphEdgeHandlers } from "../../graph-modeller/GraphProvider";
import { FileObject } from "../../types";
import { useFileAndEdgeModifiers } from "../hooks/useFileAndEdgeModifiers";

export const EdgeFileSelector = () => {
  const { getEdge } = useGraphEdgeHandlers();
  const { files } = useDataloaderStore().getDeploymentDataloaderState();
  const edge = getEdge();

  const [selectedFile, setSelectedFileInLocalState] = useState<FileObject | undefined>();
  const { assignFileToEdgeInGlobalStore, getAssignedFileToEdgeFromGlobalStore } = useFileAndEdgeModifiers();

  const handleFileSelection = (file: FileObject) => {
    setSelectedFileInLocalState(file);
    assignFileToEdgeInGlobalStore(file);
  };

  useEffect(() => {
    if (edge && !isEmpty(edge)) {
      setSelectedFileInLocalState(getAssignedFileToEdgeFromGlobalStore());
    }
  }, [edge]);

  return (
    <Form.Field>
      <label htmlFor="edgeFileSelector">Relation File</label>
      <Form.Dropdown
        fluid
        value={(selectedFile || {}).id || ""}
        selection
        placeholder="Select a file"
        name="edgeFileSelector"
        search
        noResultsMessage="No files found"
        onChange={(_: unknown, data: DropdownProps) => {
          const { value } = data;
          handleFileSelection(files.filter((file) => file.id === String(value))[0]);
        }}
        options={files.map((file) => ({
          key: file.id,
          value: file.id,
          text: file.name,
        }))}
      />
    </Form.Field>
  );
};
