//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useDataloaderStore } from "../../DataloaderStore";
import { useGraphNodeHandlers } from "../../graph-modeller/GraphProvider";
import { FileObject } from "../../types";

export const useFileAndNodeModifiers = () => {
  const { updateNode, getNode } = useGraphNodeHandlers();
  const { getDeploymentDataloaderState, updateNodeMappings } = useDataloaderStore();
  const { nodeMappings, node: storedNode } = getDeploymentDataloaderState();

  /**
   *
   * This function removes the file mappings from the global store for the node with the specific id
   *
   * @param {string} id
   * @returns {void}
   */
  const removeNodeMappingsFromGlobalStore = () => {
    const { id } = getNode() || {};
    if (!id) return;
    delete nodeMappings[id];
  };
  /**
   *
   * This function returns the File details what is attached to the provided Node.
   *
   * @param {string} id
   * @returns
   */
  const getExistingMap = (id: string) => {
    return nodeMappings[id] || {};
  };

  /**
   *
   * This function returns the primary key that was assigned to the selected node. This fetches the details from the Dataloader Store and returns it as a string.
   *
   * @returns {string}
   */
  const getPrimaryKeyAssignedToNodeFromGlobalStore = () => {
    const { id } = storedNode || {};

    const existingAttachedFile = getExistingMap(String(id));
    const { primaryKey } = existingAttachedFile;
    return primaryKey;
  };

  /**
   *
   * This function returns the assigned fields to the Node which is obtained from the CSV file.
   *
   * @returns {string[]}
   */
  const getFieldsAssignedToNodeFromGlobalStore = () => {
    const { id } = storedNode || {};

    const existingAttachedFile = getExistingMap(String(id));
    const { selectedFields } = existingAttachedFile;
    return selectedFields;
  };

  /**
   *
   * A Node can be assigned the headers from the CSV file.
   * This is basically a map of the Header and the value from the first row. This function sets the selected fields(key-value pairs from the file) and stores them in the Dataloader store.
   * The format recommended is <Header><ValueFromTheFirstRowForThisHeader>.
   *
   * @param {string} field
   */
  const assignFileHeadersToNodeInGlobalStore = (field: string) => {
    const { id } = storedNode || {};
    const existingAttachedFile = getExistingMap(String(id));
    const existingFields = getFieldsAssignedToNodeFromGlobalStore() || [];
    const updatedMap = {
      ...nodeMappings,
      [String(id)]: {
        ...existingAttachedFile,
        selectedFields: [...existingFields, field],
      },
    };
    updateNodeMappings(updatedMap);
    updateNode(storedNode || {}, updatedMap);
  };

  /**
   * This function will help remove a Header-value field for the node which is assigned in the Dataloader Store.
   * @param {string} fieldToRemove
   */

  const removeFileHeaderFromNodeInGlobalStore = (fieldToRemove: string) => {
    const { id } = storedNode || {};
    const existingAttachedFile = getExistingMap(String(id));
    const updateNodeMappings = useDataloaderStore.getState().updateNodeMappings;
    const existingFields = getFieldsAssignedToNodeFromGlobalStore() || [];
    const updatedMap = {
      ...nodeMappings,
      [String(id)]: {
        ...existingAttachedFile,
        selectedFields: existingFields.filter((str) => str !== fieldToRemove),
      },
    };
    updateNodeMappings(updatedMap);
    updateNode(storedNode || {}, updatedMap);
  };

  /**
   *
   * This function returns the file data as a FileObject that is assigned to the node. If not found, it returns undefined.
   *
   * @returns {FileObject} file
   */
  const getAssignedFileToNodeFromGlobalStore = () => {
    const { id } = storedNode || {};
    const existingAttachedFile = getExistingMap(String(id));
    const { attachedFile } = existingAttachedFile;
    return attachedFile;
  };

  /**
   * Whenever a file is selected from the Node editor panel, this function will store that file data against the node ID in the Dataloader Store and then visually change the node attributes to represent that a file is attached.
   *
   * @param {FileObject} file
   *
   */
  const assignFileToNodeInGlobalStore = (file: FileObject) => {
    const { id } = storedNode || {};
    const updateNodeMappings = useDataloaderStore.getState().updateNodeMappings;
    const existingAttachedFile = getExistingMap(String(id));

    const updatedMapping = {
      ...nodeMappings,
      [String(id)]: {
        ...existingAttachedFile,
        attachedFile: file,
        selectedFields: undefined,
        primaryKey: undefined,
      },
    };
    updateNodeMappings(updatedMapping);
    updateNode(storedNode || {}, nodeMappings);
  };

  /**
   *
   * A primary key helps identify the entry point to a file which is attached to a Node.
   * Having attached a file, once a primary key is selected from the Node editor panel, this function will add the set key to the node id object that is created in the Dataloader store.
   *
   * @param {string} primaryKey
   *
   */
  const assignPrimaryKeyToNodeInGlobalStore = (primaryKey: string) => {
    const { id } = storedNode || {};
    const updateNodeMappings = useDataloaderStore.getState().updateNodeMappings;
    const existingAttachedFile = getExistingMap(String(id));
    const updatedMapping = {
      ...nodeMappings,
      [String(id)]: {
        ...existingAttachedFile,
        primaryKey,
      },
    };
    updateNodeMappings(updatedMapping);
    updateNode(storedNode || {}, updatedMapping);
  };

  return {
    getExistingMap,
    assignFileToNodeInGlobalStore,
    assignPrimaryKeyToNodeInGlobalStore,
    assignFileHeadersToNodeInGlobalStore,
    removeFileHeaderFromNodeInGlobalStore,
    getFieldsAssignedToNodeFromGlobalStore,
    getPrimaryKeyAssignedToNodeFromGlobalStore,
    getAssignedFileToNodeFromGlobalStore,
    removeNodeMappingsFromGlobalStore,
  };
};
