//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Button, List, Loader, Modal, Menu, Icon } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ConnectDriverInstructions as ApiConnectDriverInstructions } from "../../api/lib";
import { LoaderBox } from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";

interface IConnectDriverInstructionsViewArgs {
  instructions?: ApiConnectDriverInstructions;
  onActiveDriverChanged: (activeIndex: number) => void;
  activeDriverIndex: number;
}

const titleToLanguage = {
  Go: "go",
  Java: "java",
  Springdata: "java",
  "Node.js": "javascript",
  PHP: "php",
  Python: "python",
  "C#": "csharp",
};

const ConnectDriverInstructionsView = ({ ...args }: IConnectDriverInstructionsViewArgs) => {
  if (args.instructions && args.instructions.drivers) {
    const drivers = args.instructions.drivers || [];
    const activeDriver = args.activeDriverIndex < drivers.length ? drivers[args.activeDriverIndex] : {};
    const code = activeDriver.code ? activeDriver.code.join("\n") : undefined;
    const remarks = activeDriver.remarks;
    const driver_url = activeDriver.driver_url;
    const driverName = activeDriver.name as keyof typeof titleToLanguage;
    const language = activeDriver.name ? titleToLanguage[driverName] || "text" : "text";
    return (
      <div>
        <Menu
          pointing
          items={drivers.map((x, i) => {
            return {
              name: x.name || "?",
              content: x.name || "?",
              onClick: () => {
                args.onActiveDriverChanged(i);
              },
              active: i === args.activeDriverIndex,
            };
          })}
        />
        {driver_url && (
          <a href={driver_url} target="_blank" className="text-link" style={{ float: "right", marginTop: "1em" }} rel="noreferrer">
            driver <Icon name="external alternate" />
          </a>
        )}
        <h3 className="heading-3">Code</h3>
        <SyntaxHighlighter language={language}>{code}</SyntaxHighlighter>
        {remarks && (
          <div>
            <h3 className="heading-3">Remarks</h3>
            <List
              items={remarks.map((x) => {
                return {
                  content: x,
                };
              })}
            />
          </div>
        )}
      </div>
    );
  }

  return <div>retrieving</div>;
};

// Interface decribing the properties of the ConnectDriverInstructions component
interface IConnectDriverInstructionsProps extends IWithRefreshProps {
  deploymentId: string;
  disabled?: boolean;
}

// Interface decribing the state of the CACertificate component
interface IConnectDriverInstructionsState {
  instructions?: ApiConnectDriverInstructions;
  activeDriverIndex: number;
  codeCopied: boolean;
  selectedCode?: string[];
}

class ConnectDriverInstructions extends Component<IConnectDriverInstructionsProps, IConnectDriverInstructionsState> {
  state = {
    caCertificate: undefined,
    codeCopied: false,
    selectedCode: undefined,
    activeDriverIndex: 0,
  } as IConnectDriverInstructionsState;

  reloadInstructions = async (deploymentId: string) => {
    const idOptions = { id: deploymentId } as ApiIDOptions;
    const instructions = await apiClients.dataClient.GetConnectDriverInstructions(idOptions);
    this.setState(
      {
        instructions: instructions,
        selectedCode: instructions && instructions.drivers ? instructions.drivers[0].code : undefined,
      },
      () => {
        this.onActiveDriverChanged(0);
      }
    );
  };

  componentDidMount() {
    if (this.props.deploymentId) {
      this.props.refreshNow && this.props.refreshNow(() => this.reloadInstructions(this.props.deploymentId));
    }
  }

  onCopiedCode = () => {
    this.setState({ codeCopied: true });
  };

  resetCopiedCode = () => {
    this.setState({ codeCopied: false });
  };

  onActiveDriverChanged = (activeIndex: number) => {
    const instructions = this.state.instructions;
    if (instructions && instructions.drivers && activeIndex < instructions.drivers.length) {
      this.setState({
        selectedCode: instructions.drivers[activeIndex].code,
        activeDriverIndex: activeIndex,
        codeCopied: false,
      });
    } else {
      this.setState({
        selectedCode: undefined,
        activeDriverIndex: activeIndex,
        codeCopied: false,
      });
    }
  };

  render() {
    if (!this.props.deploymentId) {
      return <div />;
    }
    const code = this.state.selectedCode ? this.state.selectedCode.join("\n") : undefined;

    return (
      <Modal trigger={<Button size="medium" content="Connecting drivers" icon="linkify" disabled={this.props.disabled} labelPosition="right" />} size="large">
        <Modal.Header>Connecting a driver to your deployment</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <LoaderBox>
              <Loader size="mini" active={this.props.loading} inline />
            </LoaderBox>
            <ConnectDriverInstructionsView
              instructions={this.state.instructions}
              onActiveDriverChanged={this.onActiveDriverChanged}
              activeDriverIndex={this.state.activeDriverIndex}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CopyToClipboard text={code} onCopy={this.onCopiedCode}>
            <Button basic disabled={!code} icon={this.state.codeCopied ? "checkmark" : "copy"} labelPosition="right" content="Copy to clipboard" />
          </CopyToClipboard>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRefresh()(ConnectDriverInstructions);
