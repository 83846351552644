//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { DropdownProps, Form, Icon, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { Version as ApiVersion, VersionList as ApiVersionList, ListVersionsRequest as ApiListVersionsRequest } from "../../api/data/v1/data";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import styled from "@emotion/styled";
import { formatVersion } from "../../ui/lib";

const FloatRight = styled("div")`
  float: right;
`;

interface ISelectVersionViewArgs extends ISelectVersionState {
  disableVersionSelection?: boolean;
  updateFormDropdownChange: (e: any, args: DropdownProps) => void;
}

const SelectVersionView = ({ ...args }: ISelectVersionViewArgs) =>
  args.versions && args.versions.items ? (
    <Form.Dropdown
      disabled={args.disableVersionSelection}
      required
      fluid
      selection
      placeholder="Select Version"
      name="version"
      value={args.selectedVersion && args.selectedVersion.version}
      onChange={args.updateFormDropdownChange}
      options={args.versions.items.map((v) => {
        return { key: v.version, text: formatVersion(v), value: v.version };
      })}
    />
  ) : (
    <Form.Dropdown required fluid selection placeholder="Select Version" name="version" loading={true} />
  );

// Interface decribing the properties of the SelectVersion component
interface ISelectVersionProps extends IWithRefreshProps {
  organizationId: string;
  version?: ApiVersion;
  originalVersion?: string;
  onVersionUpdated: (version?: ApiVersion) => void;
  customImageShowing: boolean;
  canShowCustomImage: boolean;
  disableVersionSelection?: boolean;
  onShowCustomImageClicked: () => void;
}

// Interface decribing the state of the SelectVersion component
interface ISelectVersionState {
  versions?: ApiVersionList;
  defaultVersion?: ApiVersion;
  selectedVersion?: ApiVersion;
}

// Component to select a version
class SelectVersion extends Component<ISelectVersionProps, ISelectVersionState> {
  state = {
    versions: undefined,
    defaultVersion: undefined,
    selectedVersion: this.props.version,
  } as ISelectVersionState;

  updateFormDropdownChange = (e: any, args: DropdownProps) => {
    switch (args.name) {
      case "version":
        const versions = this.state.versions;
        const selectedVersion = versions && versions.items && versions.items.find((v) => v.version == args.value);
        this.setState({ selectedVersion: selectedVersion });
        this.props.onVersionUpdated(selectedVersion);
        break;
    }
  };

  reloadVersions = async () => {
    let listOptions = {
      organization_id: this.props.organizationId,
    } as ApiListVersionsRequest;
    if (this.props.originalVersion) {
      listOptions.current_version = this.props.originalVersion;
    }
    const versions = await apiClients.dataClient.ListVersions(listOptions);
    const defaultVersion = await apiClients.dataClient.GetDefaultVersion();
    let selectedVersion = this.state.selectedVersion;
    const version = selectedVersion;
    if (version) {
      if (versions.items && !versions.items.find((v) => v.version == version.version)) {
        //not available anymore
        selectedVersion = undefined;
      }
    }
    if (!selectedVersion) {
      selectedVersion = defaultVersion;
    }
    this.setState({
      versions: versions,
      defaultVersion: defaultVersion,
      selectedVersion: selectedVersion,
    });
    this.props.onVersionUpdated(selectedVersion);
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadVersions);
  }

  render() {
    return (
      <Form.Field required>
        <label>
          {this.props.canShowCustomImage && (
            <FloatRight>
              <Popup
                trigger={<Icon name={this.props.customImageShowing ? "compress" : "expand"} onClick={this.props.onShowCustomImageClicked} />}
                content="Custom image"
              />
            </FloatRight>
          )}
          DB Version
        </label>
        <SelectVersionView
          {...this.state}
          updateFormDropdownChange={this.updateFormDropdownChange}
          disableVersionSelection={this.props.disableVersionSelection}
        />
      </Form.Field>
    );
  }
}

export default withRefresh()(SelectVersion);
