//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState, useContext, ReactNode, useEffect } from "react";
import { Divider, Form, Message, Modal, Checkbox, CheckboxProps } from "semantic-ui-react";
import { isEmpty } from "lodash";

import { FlexBox } from "../../ui/_flex";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { TextLink } from "../../ui/_textlink";
import { HelpUrl } from "../../HelpUrl";
import { IWizardContext, WizardContext } from "../wizard/Wizard";
import { textToStrings } from "../../api/_util";
import { ErrorMessage } from "../../ui/_errorMessage";
import { FormActionButtonCancel, FormActionButtonSave } from "../../ui/_buttons";
import { isValidDomain } from "../../util/domainValidation";
import { Box } from "../../ui/_box";

interface DNSDataType {
  alternateDNSNames: string[];
  enablePrivateDNS: boolean;
}

export interface CustomDNSArgs {
  alternateDNSNames: string[];
  enablePrivateDNS: boolean;
  info: string | ReactNode;
  isPrivateDNSEditable: boolean;
  setIsValid?: (state: boolean) => void;
  onEdit?: (data: DNSDataType) => void;
}

export const CustomDNSView = ({ alternateDNSNames, enablePrivateDNS, info, isPrivateDNSEditable, setIsValid, onEdit }: CustomDNSArgs) => {
  const [customDNS, setCustomDNS] = useState<boolean>(!enablePrivateDNS || alternateDNSNames.length > 0);
  const [dnsNames, setDNSNames] = useState<string[]>(alternateDNSNames);
  const deploymentData = useDeploymentStore();
  const context = useContext<IWizardContext>(WizardContext);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);

  const handleCustomDNSChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const isCustomDNS = !!data.checked;
    setCustomDNS(isCustomDNS);
    const { updateWizardState } = context;
    updateWizardState?.({ isValid: isValueValid, data: { alternateDNSNames: [], enablePrivateDNS: !isCustomDNS } });
    onEdit?.({
      alternateDNSNames: [],
      enablePrivateDNS: isPrivateDNSEditable ? !isCustomDNS : enablePrivateDNS,
    });
  };

  const handleDNSNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target;
    const names = textToStrings(value);
    setDNSNames(names);
    onEdit?.({ alternateDNSNames: names, enablePrivateDNS: isPrivateDNSEditable ? !customDNS : enablePrivateDNS });
  };

  const isFormValid = (names: string[]) => {
    if (!isEmpty(names)) {
      return !names.find((dnsName) => !isValidDomain(dnsName));
    }
    return true;
  };

  const handleBlur = () => {
    const isValid = isFormValid(dnsNames);
    setIsValueValid(isValid);
    setIsValid?.(isValid);
    const { updateWizardState } = context;
    const validDNSNames = dnsNames.filter((name) => !!name);
    updateWizardState?.({ isValid: isValid, data: { alternateDNSNames: validDNSNames, enablePrivateDNS: !customDNS } });
    onEdit?.({ alternateDNSNames: validDNSNames, enablePrivateDNS: isPrivateDNSEditable ? !customDNS : enablePrivateDNS });
  };

  useEffect(() => {
    const { existingState, updateWizardState } = context;
    const { data = {} } = existingState || {
      data: {
        alternateDNSNames: alternateDNSNames,
        enablePrivateDNS: true,
      },
    };
    const isCustomDNS = !data.enablePrivateDNS || data.alternateDNSNames.length > 0;
    setDNSNames(data.alternateDNSNames);
    setCustomDNS(isCustomDNS);
    updateWizardState?.({ isValid: true, data: { alternateDNSNames: data.alternateDNSNames, enablePrivateDNS: !isCustomDNS } });
  }, []);

  const endpoint = deploymentData.deployment.status?.endpoint_default?.replace(".", "-pe.");
  return (
    <div>
      <>
        <Message>
          <FlexBox justify="space-between" padding="5px">
            <Box width="80%">
              <b data-testid="privateDNSCheckboxMessage">Custom DNS</b>
              <p className="para">
                By default, your private endpoint will be available to all VPCs that connect to the endpoint at <b>{endpoint}</b>. You can can instead enable
                custom DNS, where you will be responsible for the DNS for your private endpoints yourself.
              </p>
              <p className="para">
                Enabling custom DNS will also change the functionality provided by Private Endpoints, as you can read in our{" "}
                <TextLink
                  label="documentation"
                  onClick={() => {
                    window.open(HelpUrl.arango_private_endpoints, "_blank");
                  }}
                />
                , and should only be used in very specific circumstances.
              </p>
            </Box>
            <Checkbox data-testid="privateDNSCheckbox" checked={customDNS} toggle onChange={handleCustomDNSChange} />
          </FlexBox>
        </Message>
      </>
      {customDNS && (
        <>
          <Message data-testid="alternateDNSInfo">{info}</Message>
          <Divider hidden />
          <Form>
            <Form.TextArea
              error={!isValueValid}
              data-testid="alternateDNSNamesField"
              label="Alternate DNS (1 per line)"
              placeholder="Enter Alternate DNS names"
              onChange={handleDNSNameChange}
              value={dnsNames.join("\n")}
              onBlur={handleBlur}
            />
            <Form.TextArea
              data-testid="alternateDNSExample"
              label="Example for Alternate DNS names"
              value={["test.example.com", "test2.example.com"].join("\n")}
            />
          </Form>
        </>
      )}
    </div>
  );
};

interface CustomDNSNamesModalViewArgs extends CustomDNSArgs {
  onClose: () => void;
  onSave: (data: DNSDataType) => Promise<{ error: unknown } | undefined>;
}

export const CustomDNSModalView = ({ alternateDNSNames, enablePrivateDNS, info, isPrivateDNSEditable, onClose, onSave }: CustomDNSNamesModalViewArgs) => {
  const [dnsNames, setDNSNames] = useState<string[]>(alternateDNSNames);
  const [privateDNS, setPrivateDNS] = useState<boolean>(!!enablePrivateDNS);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  const handleChange = (data: DNSDataType) => {
    setDNSNames(data.alternateDNSNames);
    setPrivateDNS(data.enablePrivateDNS);
  };

  const handleSave = async () => {
    setLoading(true);
    const { error } =
      (await onSave({
        alternateDNSNames: dnsNames,
        enablePrivateDNS: privateDNS,
      })) || {};
    setLoading(false);
    !!error && setError(error);
    if (!error && !loading) onClose();
  };

  return (
    <Modal open onClose={onClose} data-testid="privateNetworkDNSNamesModal">
      <Modal.Header>Custom DNS names</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ErrorMessage active={!!error} message={error} onDismiss={() => setError(undefined)} data-testid="alternateDNSModalError" />
          <CustomDNSView
            onEdit={handleChange}
            alternateDNSNames={dnsNames}
            enablePrivateDNS={enablePrivateDNS}
            isPrivateDNSEditable={isPrivateDNSEditable}
            info={info}
            setIsValid={setIsValid}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCancel onClick={onClose} data-testid="alternateDNSModalCloseAction" />
        <FormActionButtonSave primary onClick={handleSave} icon="save" disabled={!isValid} loading={loading} data-testid="alternateDNSModalSaveAction" />
      </Modal.Actions>
    </Modal>
  );
};
