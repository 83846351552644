//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { Modal } from "semantic-ui-react";
import { TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { FormActionButtonCreate, StyledLink } from "../../ui/lib";
import copy from "copy-to-clipboard";
import moment from "moment";
import styled from "@emotion/styled";

export interface ITermsAndConditionsModalArgs {
  termsAndConditions?: ApiTermsAndConditions;
}

const StyledCaption = styled.div`
  font-size: 0.8rem !important;
  color: var(--gray-600);
`;

export const TermsAndConditionsModal = ({ ...args }: ITermsAndConditionsModalArgs) => {
  const termsAndConditions = args.termsAndConditions || {};
  const ref = useRef(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const exportPDF = () => {
    import("jspdf").then(({ jsPDF }) => {
      const pdf = new jsPDF();
      const el = document.getElementById("termsAndConditionsView") as HTMLElement;
      pdf.setFontSize(10);
      const PAGE_CHAR_SIZE = 5500;
      const iterations = Math.floor(el.innerHTML.length / PAGE_CHAR_SIZE);
      for (let i = 0; i <= iterations; i++) {
        const start = i * PAGE_CHAR_SIZE;
        const end = start + PAGE_CHAR_SIZE - 1;
        pdf.text(el.innerText.substring(start, end), 10, 20, { maxWidth: 190 });
        if (end < el.innerHTML.length) {
          pdf.addPage();
        }
      }
      pdf.save("terms-and-conditions.pdf");
    });
  };

  return (
    <Modal
      trigger={
        <StyledLink className="text-link" onClick={(e) => e.stopPropagation()}>
          Terms &amp; Conditions
        </StyledLink>
      }
      closeIcon
    >
      <Modal.Header>
        Terms &amp; Conditions
        <StyledCaption>Created on {moment(termsAndConditions.created_at).format("MMM Do YYYY")}</StyledCaption>
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <div id="termsAndConditionsView" ref={ref}>
            <Markdown
              children={termsAndConditions.content || ""}
              components={{
                p: ({ children }) => <p className="para">{children}</p>,
                ol: ({ children }) => <ol className="ordered-list">{children}</ol>,
                ul: ({ children }) => <ul className="unordered-list">{children}</ul>,
                h1: ({ children }) => <h1 className="heading-1">{children}</h1>,
                h2: ({ children }) => <h2 className="heading-2">{children}</h2>,
                h3: ({ children }) => <h3 className="heading-3">{children}</h3>,
                h4: ({ children }) => <h4 className="heading-4">{children}</h4>,
                h5: ({ children }) => <h5 className="heading-5">{children}</h5>,
              }}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FormActionButtonCreate primary onClick={exportPDF} icon="file pdf" title="Export PDF" />
        <FormActionButtonCreate
          title={copied ? "Copied" : "Copy"}
          icon={copied ? "check" : "copy"}
          onClick={() => {
            copy(termsAndConditions.content || "");
            setCopied(true);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
