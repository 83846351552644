//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { TopMenuBar } from "../../../components/TopMenuBar";
import { useDocumentTitle } from "../../../components/useDocumentTitle";
import { Routes } from "../../../routes";
import projectIcon from "../../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/project.svg";

import { useDashboardContext } from "../../DashboardContextProvider";
import EditableTitle from "../../title/EditableTitle";
import { BreadCrumbItem } from "../../TopMenuInfo";
import { useProjectDetailsContext } from "../ProjectDetailsContextProvider";

export const ProjectPolicyTopMenu = () => {
  const { topMenuInfo, selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const breadcrumbs = [
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_projects),
  ];

  useDocumentTitle(`Project: ${project.name} | Policy`);
  return (
    <TopMenuBar
      breadcrumbs={breadcrumbs}
      icon={projectIcon}
      title={<EditableTitle title={topMenuInfo.getTitle()} save={topMenuInfo.getSaveTitles()} subTitle={topMenuInfo.getSubTitle()} />}
    />
  );
};
