//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import styled from "@emotion/styled";
import React from "react";
import { Button, Grid, Modal, Segment } from "semantic-ui-react";
import { Token as ApiToken } from "../../../api/lib";
import { IWithRefreshProps } from "../../../util/WithRefresh";
import CustomCodeBlock from "../../example/CustomCodeBlock";

import addDataSource from "./adddatasource.png";
import importDashboard from "./import.png";

const RightFloatedImage = styled("img")`
  float: right;
  box-shadow: 10px 10px 5px grey;
`;

const StyledActionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  .button {
    margin-bottom: 16px;
  }
`;

const StyledActionContainer = styled("span")`
  display: inline-grid;
`;

interface IMetricsTokenSummaryViewArgs extends IWithRefreshProps {
  loading: boolean;
  tokens: ApiToken[];
  endpoint?: string;
}

export const MetricsTokenSummaryView = ({ ...args }: IMetricsTokenSummaryViewArgs) => {
  const activeTokens = _.filter(args.tokens, (x) => !x.is_deleted && !x.is_revoked && !x.is_expired);
  const hasActiveTokens = !_.isEmpty(activeTokens);
  const endpoint = args.endpoint || "";

  return (
    <Segment>
      <Grid>
        <Grid.Row columns="16">
          <Grid.Column width="10">
            <SummaryHelpTextView {...args} hasActiveTokens={hasActiveTokens} />
          </Grid.Column>
          <Grid.Column width="6" textAlign="center" verticalAlign="middle">
            <SummaryActionsView {...args} hasActiveTokens={hasActiveTokens} endpoint={endpoint} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

interface ISummaryHelpTextViewArgs {
  loading: boolean;
  hasActiveTokens: boolean;
}

const SummaryHelpTextView = ({ ...args }: ISummaryHelpTextViewArgs) => (
  <div>
    <p className="para">
      ArangoGraph provides (optional) metrics for each deployment in a{" "}
      <a href="https://prometheus.io/" target="_blank" className="text-link" rel="noreferrer">
        Prometheus
      </a>{" "}
      compatible format.
    </p>
    <p className="para">You can use these metrics to gather detailed insights into the current (and previous) workings of your deployment.</p>
    <p className="para">
      Once metrics are scraped by Prometheus you can inspect them using tools such as{" "}
      <a href="https://grafana.com/oss/grafana" target="_blank" className="text-link" rel="noreferrer">
        Grafana
      </a>
      .
    </p>
    {!args.hasActiveTokens && !args.loading && (
      <p className="para">
        Metrics support is currently not available for this deployment.
        <br />
        Create one or more metrics token below to activate metrics support.
      </p>
    )}
  </div>
);

interface ISummaryActionsViewArgs extends IWithRefreshProps {
  hasActiveTokens: boolean;
  endpoint: string;
}

const SummaryActionsView = ({ ...args }: ISummaryActionsViewArgs) => (
  <StyledActionsContainer>
    <ConnectPrometheusView {...args} />
    <ConnectGrafanaView {...args} />
  </StyledActionsContainer>
);

interface IConnectPrometheusViewArgs extends IWithRefreshProps {
  hasActiveTokens: boolean;
  endpoint: string;
}

const ConnectPrometheusView = ({ ...args }: IConnectPrometheusViewArgs) => {
  const [open, setOpen] = React.useState(false);
  const promCfg = createPrometheusConfig(args.endpoint);
  return (
    <StyledActionContainer>
      <Button
        content="Connect Prometheus"
        disabled={!args.hasActiveTokens}
        size="medium"
        labelPosition="right"
        icon="cogs"
        primary
        onClick={() => setOpen(true)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Connect Prometheus</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <h3 className="heading-3">Instructions</h3>
            <p className="para">Create a prometheus.yml file with the following content.</p>
            <CustomCodeBlock value={promCfg} />
            <p className="para">Then start prometheus with the following command.</p>
            <CustomCodeBlock language="bash" value={prometheusDockerCmd} />
            <p className="para">Note: this command also opens a port 3000 for Grafana. In a production setting this is not needed, nor recommended.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </StyledActionContainer>
  );
};

const prometheusDockerCmd = `docker run -d \\
  -p 9090:9090 -p 3000:3000 --name prometheus \\
  -v $(pwd)/prometheus.yml:/etc/prometheus/prometheus.yml:ro \\
  prom/prometheus`;

const createPrometheusConfig = (endpoint: string) => {
  // eslint-disable-next-line no-useless-escape
  const endpointSplitter = /(.*):\/\/(.*)\:(\d+).*/g;
  const hostPort = endpoint.replace(endpointSplitter, "$2:$3");
  return `global:
  scrape_interval: 60s
scrape_configs:
  - job_name: 'deployment'
    bearer_token: '<fill-your-metrics-token-here>'
    scheme: 'https'
    static_configs:
      - targets: ['${hostPort}']
    tls_config:
      insecure_skip_verify: true`;
};

interface IConnectGrafanaViewArgs extends IWithRefreshProps {
  hasActiveTokens: boolean;
}

const ConnectGrafanaView = ({ ...args }: IConnectGrafanaViewArgs) => {
  const [open, setOpen] = React.useState(false);
  return (
    <StyledActionContainer>
      <Button content="Connect Grafana" disabled={!args.hasActiveTokens} size="medium" labelPosition="right" icon="dashboard" onClick={() => setOpen(true)} />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Connect Grafana</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <h3 className="heading-3">Start Grafana</h3>
            <p className="para">Start grafana with the following command.</p>
            <CustomCodeBlock language="bash" value={grafanaDockerCmd} />
            <p className="para">
              Go to{" "}
              <a href="localhost:3000" target="_blank" className="text-link" rel="noreferrer">
                localhost:3000
              </a>{" "}
              and login (initial login is admin:admin).
            </p>
            <p className="para">After login, change password.</p>

            <h3 className="heading-3">Add data source</h3>
            <RightFloatedImage src={addDataSource} />
            <p className="para">Click on "Add your first data source".</p>
            <p className="para">Select Prometheus.</p>
            <p className="para">In the HTTP URL field, enter:</p>
            <CustomCodeBlock {...args} value="http://localhost:9090" />
            <p className="para">Click on Save &amp; Test, it should turn green. Then click on Back.</p>

            <h3 className="heading-3">Add dashboard</h3>
            <RightFloatedImage src={importDashboard} />
            <p className="para">Click on "+" in the menu and then "Import".</p>
            <p className="para">
              <a href="https://github.com/arangodb-managed/grafana-dashboards" target="_blank" className="text-link" rel="noreferrer">
                Download the dashboard
              </a>{" "}
              and copy the content into "Import via panel json"
            </p>
            <p className="para">Click "Load".</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </StyledActionContainer>
  );
};

const grafanaDockerCmd = `docker run -d \\
  --network container:prometheus \\
  grafana/grafana`;
