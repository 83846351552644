import { SemanticICONS } from "semantic-ui-react";
import {
  PrivateEndpointService,
  PrivateEndpointService_AwsPrincipals as ApiPrivateEndpointServiceAWSPrincipals,
  PrivateEndpointService_AwsPrivateEndpointConnectionStatus as ApiPrivateEndpointService_AwsPrivateEndpointConnectionStatus,
  PrivateEndpointService_GcpPrivateEndpointConnectionStatus as ApiPrivateEndpointService_GcpPrivateEndpointConnectionStatus,
} from "../../api/network/v1/network";

export type AWSPrincipal = Required<Pick<ApiPrivateEndpointServiceAWSPrincipals, "account_id">> &
  Pick<ApiPrivateEndpointServiceAWSPrincipals, "user_names" | "role_names">;

export interface IPrincipalsViewArgs {
  disableChange?: boolean;
  statusMessage?: string | React.ReactNode;
  principals: AWSPrincipal[];
  onChange?: (principals: AWSPrincipal[]) => void;
  onUpdateFieldValidity?: (state: boolean) => void;
}

export interface IPrincipalList {
  awsPrincipals: AWSPrincipal[];
  disableChange?: boolean;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

export interface IPrincipalAdditionView {
  accountId: string;
  usernames: string;
  roleNames: string;
  onAccountIDChange: (accountId: string) => void;
  onUsernamesChange: (usernames: string) => void;
  onRoleNamesChange: (rolesNames: string) => void;
}

export type IPrincipalsAdditionModalView = {
  isEditMode: boolean;
  principalToEdit: AWSPrincipal;
  onCancel: () => void;
  onAdd: (principal: AWSPrincipal) => void;
  onSave: (principal: AWSPrincipal) => void;
};

export interface IPrincipalViewHeader {
  disableChange: boolean;
  statusMessage: React.ReactNode;
  onAddPrincipal: () => void;
}

export const REGIONS = {
  aws: "aws",
  aks: "aks",
  gcp: "gcp",
};

export interface IServiceNameArgs {
  onClose?: () => void;
  onServiceNameCopy?: (value: string) => void;
  initialValue?: string;
}

export interface IPrivateNetworkHelpView {
  onClose: () => void;
  onSupportClick: (() => void) | undefined;
}

export interface IPrincipalsSummaryModalViewArgs {
  awsPrincipals: AWSPrincipal[];
  disableChange?: boolean;
  statusMessage?: string | React.ReactNode;
  onClose: () => void;
  onSave: (principals: AWSPrincipal[]) => Promise<{ error: unknown } | undefined>;
}

export interface ISummaryItem {
  labelIcon?: SemanticICONS | undefined;
  actionIcon?: SemanticICONS | undefined;
  mainLabel?: string;
  actionLabel?: string;
  handleClick?: () => void;
  actionClassName?: string;
  noAction?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  testID?: string;
}

export interface IPrivateNetworkSummary {
  privateNetworkDetail: PrivateEndpointService;
  provider: RegionProviders;
  onSubscriptionIDChange: (subscriptionIDs: string[]) => Promise<{ error: unknown } | undefined>;
  onAlternateDNSNamesChange: (data: { alternateDNSNames: string[]; enablePrivateDNS?: boolean }) => Promise<{ error: unknown } | undefined>;
  onAWSPrincipalsChange: (awsPrincipals: AWSPrincipal[]) => Promise<{ error: unknown } | undefined>;
  onProjectNamesChange: (projectNames: string[]) => Promise<{ error: unknown } | undefined>;
  onSupportClick?: () => void;
}

export type AWSSummary = Omit<IPrivateNetworkSummary, "onSubscriptionIDChange" | "provider" | "onProjectNamesChange">;
export type GCPSummary = Omit<IPrivateNetworkSummary, "onSubscriptionIDChange" | "provider" | "onAWSPrincipalsChange">;
export interface IAWSAvailabilityZonesArgs {
  onClose: () => void;
  availabilityZones: string[];
}

type regionTypes = typeof REGIONS;
export type RegionProviders = keyof regionTypes;

export interface IAWSPrivateEndpointConnectionsViewArgs {
  connections: ApiPrivateEndpointService_AwsPrivateEndpointConnectionStatus[];
}

export interface IAWSPrivateEndpointConnectionsViewModalArgs extends IAWSPrivateEndpointConnectionsViewArgs {
  onClose: () => void;
}

// GCP types
export interface IProjectNamesView {
  onChange?: (projectNames: string[]) => void;
  onUpdateFieldValidity?: (state: boolean) => void;
  disableChange?: boolean;
  statusMessage?: string | React.ReactNode;
  projects: string[];
}

export interface IProjectNamesModalViewArgs extends IProjectNamesView {
  onClose: () => void;
  onSave: (projects: string[]) => Promise<{ error: unknown } | undefined>;
}

export interface IServiceAttachmentArgs {
  onClose?: () => void;
  onServiceAttachmentCopy?: (value: string) => void;
  initialValue?: string;
}

export interface IGCPConnectionsViewArgs {
  connections: ApiPrivateEndpointService_GcpPrivateEndpointConnectionStatus[];
}

export interface IGCPConnectionsModalViewArgs extends IGCPConnectionsViewArgs {
  onClose: () => void;
}
