//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

// Do not import from this file directly.
// Import from `lib.tsx` instead.

import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import styled from "@emotion/styled";

const StyledIcon = styled(Icon)`
  margin-left: 8px !important;
  color: var(--green-700);
`;

export const Locked = () => {
  return (
    <Popup
      content="Locked"
      trigger={
        <span>
          <StyledIcon name="lock" />
        </span>
      }
    />
  );
};
