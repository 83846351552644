import React from "react";
import { ContentActionButtonNew, Section, SectionButtons, SectionContent, SectionHead, SectionHeader } from "../../ui/lib";
import { useDashboardContext } from "../DashboardContextProvider";
import OrganizationMemberList from "../member/OrganizationMemberList";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { useWithRefresh } from "../../util/WithRefreshContext";

export const OrganizationMembers = () => {
  const { selectedOrganization } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const is_org_deleted = !!selectedOrganization.is_deleted;
  const has_create_invite = !!checkOrgPermission("resourcemanager.organization-invite.create");
  const history = useHistory();
  const onInviteCreate = () => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_organizationInvites_createWithId(selectedOrganization.id));
  };
  const { subscribeUrl, unsubscribe, refreshNow, loading, hasPermissionByUrl } = useWithRefresh();
  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Members"
          help={
            <div>
              <p className="para">Members of the organization are users that are allowed to access this organization.</p>
              <p className="para">What the members are allowed to access (and modify) within the organization is defined in the Policy.</p>
              <p className="para">
                To add a member to this organization, invite them using <b>Invite new member</b>.
              </p>
            </div>
          }
        />
        <SectionButtons>
          {has_create_invite && !is_org_deleted && (
            <ContentActionButtonNew
              primary
              content="Invite new member"
              onClick={onInviteCreate}
              disabled={!!selectedOrganization.authentication_providers?.enable_sso}
            />
          )}
        </SectionButtons>
      </SectionHead>
      <SectionContent>
        <OrganizationMemberList
          organization={selectedOrganization}
          loading={loading}
          refreshNow={refreshNow}
          subscribeUrl={subscribeUrl}
          unsubscribe={unsubscribe}
          hasPermissionByUrl={hasPermissionByUrl}
        />
      </SectionContent>
    </Section>
  );
};
