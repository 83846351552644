//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Icon, Header, Button } from "semantic-ui-react";
import { FlexBox } from "../../../../ui/_flex";
import { FileDropBoxViewArgs } from "../types";
import FileErrorView from "./FileErrorView";
import { StyledFileDrop } from "./Styles";

const FileDropInteractionView = ({ isDragActive, hasFiles, isDragReject, error, openFileDialog, description }: FileDropBoxViewArgs) => {
  return (
    <>
      {!isDragReject && isDragActive && (
        <FlexBox direction="column" justify="center" height="100%" align="center" padding="20px" color="var(--gray-600)">
          <>
            <StyledFileDrop>
              <Icon name="file" size="huge" />
              <Icon name="file alternate outline" size="huge" />
            </StyledFileDrop>
            <Header>Just drop it in!</Header>
          </>
        </FlexBox>
      )}
      {isDragReject && (
        <FlexBox direction="column" justify="center" height="100%" align="center" padding="20px" color="var(--gray-600)">
          <FileErrorView error={error || "The file type is not supported. Please choose a CSV file"} />
        </FlexBox>
      )}
      {!hasFiles && !isDragReject && !isDragActive && (
        <FlexBox direction="column" justify="center" height="100%" align="center" padding="20px" color="var(--gray-600)">
          <>
            <Icon name="cloud upload" size="huge" />
            <Header>Drag &amp; Drop</Header>
            <p className="para">{description || "Please drag and drop your CSV files here to start working with the modeller."}</p>
            <p className="para">Or</p>
            <Button onClick={openFileDialog}>Browse files</Button>
          </>
        </FlexBox>
      )}
    </>
  );
};

export default FileDropInteractionView;
