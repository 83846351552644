//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { BreadCrumbView } from "../dashboard/BreadCrumbView";
import { useDashboardContext } from "../dashboard/DashboardContextProvider";
import { DashboardTopMenuUserDropdowns } from "../dashboard/topMenu/DashboardTopMenuUserDropdowns";
import { BreadCrumbItem } from "../dashboard/TopMenuInfo";
import { NotificationBar } from "./NotificationBar";

export const TopMenuBar = ({
  breadcrumbs,
  title,
  subheading,
  children,
  icon,
}: {
  breadcrumbs: BreadCrumbItem[];
  title: string | React.ReactNode;
  subheading?: React.ReactNode;
  children?: React.ReactNode;
  icon?: string;
}) => {
  const { selectedOrganization } = useDashboardContext();
  const { notifications } = selectedOrganization;

  return (
    <Box backgroundColor="white" zIndex={500} paddingBottom="2">
      <Flex direction="column">
        <NotificationBar notifications={notifications} />
        <BreadCrumbView marginRight="20px" marginLeft="80px" items={breadcrumbs} />
        <Flex marginTop="3" marginLeft="3" alignItems="center">
          <Image width="14" height="14" src={icon} alt="Deployment" />
          <Box marginLeft="4" maxWidth="50%">
            <Heading as="h1" fontSize="4xl" noOfLines={1} title={typeof title === "string" ? title : ""}>
              {title}
            </Heading>
            {subheading && <Text color="gray.500">{subheading}</Text>}
          </Box>
          <DashboardTopMenuUserDropdowns />
        </Flex>
      </Flex>
      {children}
    </Box>
  );
};
