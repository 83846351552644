//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Loader } from "semantic-ui-react";
import {
  IAMProvider as ApiIAMProvider,
  IAMProviderType,
  IDOptions as ApiIDOptions,
  Organization as ApiOrganization,
  Project as ApiProject,
} from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  CommonContentAction,
  CommonContentActions,
  CommonKeyValuePair,
  CommonKeyValuePairs,
  CommonKV_Key,
  CommonKV_Value,
  Confirm,
  ConfirmInfo,
  ContentActionButtonDelete,
  ContentActionButtonEdit,
  ContentSegment,
  ErrorMessage,
  Loading,
  MainContent,
  Processing,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { GeneralView } from "./GeneralViews";
import { LdapView } from "./LdapViews";
import apiClients from "../../api/apiclients";
interface IIAMProviderDetailsViewArgs extends IWithRefreshProps, RouteComponentProps {
  iamprovider: ApiIAMProvider;
}

const IAMProviderDetailsView = ({ ...args }: IIAMProviderDetailsViewArgs) => (
  <div>
    <CommonKeyValuePairs>
      <CommonKeyValuePair>
        <CommonKV_Key>Created</CommonKV_Key>
        <CommonKV_Value>{args.iamprovider.created_at ? moment(args.iamprovider.created_at).fromNow() : "-"}</CommonKV_Value>
      </CommonKeyValuePair>
      {args.iamprovider.is_deleted && (
        <CommonKeyValuePair>
          <CommonKV_Key>Deleting</CommonKV_Key>
          <CommonKV_Value>{args.iamprovider.deleted_at ? moment(args.iamprovider.deleted_at).fromNow() : "-"}</CommonKV_Value>
        </CommonKeyValuePair>
      )}
    </CommonKeyValuePairs>
    <MainContent>
      <Form onSubmit={() => {}}>
        <GeneralView {...args} editable={false} canChangeType={false} onUpdate={() => {}} />
        {args.iamprovider.type == IAMProviderType.Ldap && <LdapView {...args} editable={false} onUpdate={() => {}} />}
      </Form>
    </MainContent>
  </div>
);

interface IIAMProviderDetailsProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  project: ApiProject;
  onIAMProviderDeleted: (iamproviderId: string) => void;
}

interface IIAMProviderDetailsState {
  errorMessage?: string;
  processing: boolean;
  confirmInfo?: ConfirmInfo;
  iamproviderId?: string;
  iamprovider?: ApiIAMProvider;
}

class IAMProviderDetails extends Component<IIAMProviderDetailsProps, IIAMProviderDetailsState> {
  state = {
    errorMessage: undefined,
    processing: false,
    confirmInfo: undefined,
    iamproviderId: undefined,
    iamprovider: undefined,
  } as IIAMProviderDetailsState;

  reloadIAMProviderInfo = async () => {
    const idOptions = { id: this.state.iamproviderId } as ApiIDOptions;
    const iamprovider = await apiClients.securityClient.GetIAMProvider(idOptions);
    if (!this.state.iamprovider) {
      // First time: subscribe (during the componentDidMount we did not have the complete url)
      if (this.props.subscribeUrl) this.props.subscribeUrl(this.reloadIAMProviderInfo, iamprovider.url);
    }
    this.setState({
      iamprovider: iamprovider,
    });
  };

  getIAMProviderName = () => {
    if (this.state.iamprovider) {
      return this.state.iamprovider.name;
    }
    return this.state.iamproviderId;
  };

  onClickDelete = async () => {
    const name = this.getIAMProviderName();
    const confirmInfo = {
      header: "Delete IAM provider",
      content: `Are you sure you want to delete IAM provider '${name}'?`,
      invertPositiveNegative: true,
      onConfirm: () => this.onClickDeleteConfirmed(),
      onDenied: () => this.setState({ confirmInfo: undefined }),
    } as ConfirmInfo;

    this.setState({ confirmInfo: confirmInfo });
  };

  onClickDeleteConfirmed = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined, confirmInfo: undefined });
      const idOptions = { id: this.state.iamproviderId } as ApiIDOptions;
      await apiClients.securityClient.DeleteIAMProvider(idOptions);
      this.props.onIAMProviderDeleted(this.state.iamproviderId || "");
    } catch (e) {
      this.setState({ errorMessage: `IAM provider deletion failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    const iamproviderId = (this.props.match.params as any).iamproviderId;
    this.setState(
      {
        iamproviderId: iamproviderId,
      },
      () => {
        //notice inside the reloadRoleInfo we use the groupId
        this.props.refreshNow && this.props.refreshNow(this.reloadIAMProviderInfo);
        this.updateTopMenu();
      }
    );
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Projects", Routes.dashboard_organization_projectsWithId(this.props.organization.id || "")),
      new BreadCrumbItem(this.props.project.name || "", Routes.dashboard_projectWithId(this.props.project.id || "")),
      new BreadCrumbItem("IAM Providers", Routes.dashboard_project_iamprovidersWithId(this.props.project.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("iamprovider");
    const iamprovider = this.state.iamprovider || {};
    this.props.topMenuInfo.setTitles(iamprovider.name || "", iamprovider.description || "");
  };

  render() {
    const iamprovider = this.state.iamprovider;

    if (iamprovider) {
      return (
        <ContentSegment>
          <Confirm confirmInfo={this.state.confirmInfo} />
          <Processing active={this.state.processing} message="Deleting IAM provider, please wait..." />
          <ErrorMessage active={!!this.state.errorMessage} onDismiss={this.handleDismissError} message={this.state.errorMessage} />
          <CommonContentActions>
            <CommonContentAction>
              {this.props.hasPermissionByUrl &&
                this.props.hasPermissionByUrl(iamprovider.url || "", ResourceType.IAMProvider, "security.iamprovider.update") && (
                  <ContentActionButtonEdit
                    disabled={this.state.processing}
                    onClick={() => {
                      HistoryHelper.push(
                        this.props.history,
                        Routes.dashboard_project_iamprovider_editWithId(iamprovider.project_id || "", iamprovider.id || ""),
                        this.props.topMenuInfo.getTitle()
                      );
                    }}
                  />
                )}
            </CommonContentAction>
            <CommonContentAction>
              {this.props.hasPermissionByUrl &&
                this.props.hasPermissionByUrl(iamprovider.url || "", ResourceType.IAMProvider, "security.iamprovider.delete") && (
                  <ContentActionButtonDelete disabled={this.state.processing} onClick={this.onClickDelete} />
                )}
            </CommonContentAction>
            <Loader size="mini" active={this.props.loading} inline />
          </CommonContentActions>
          <IAMProviderDetailsView {...this.props} {...this.state} iamprovider={iamprovider} />
        </ContentSegment>
      );
    }

    return <Loading />;
  }
}

export default withRefresh()(IAMProviderDetails);
