//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { TopMenuBar } from "../../../components/TopMenuBar";
import { useDocumentTitle } from "../../../components/useDocumentTitle";
import { Routes } from "../../../routes";
import roleBindingIcon from "../../../semantic/themes/arangodb-oasis-default/assets/images/icons/primary/role-binding.svg";

import { useDashboardContext } from "../../DashboardContextProvider";
import { useFetchDeployment } from "../../editDeployment/useFetchDeployment";
import { useProjectDetailsContext } from "../../project/ProjectDetailsContextProvider";
import { BreadCrumbItem } from "../../TopMenuInfo";

export const CreateDeploymentRoleBindingsTopMenu = () => {
  const { selectedOrganization } = useDashboardContext();
  const { project } = useProjectDetailsContext();
  const { params } = useRouteMatch<{ deploymentId: string }>();
  const { deploymentId } = params;
  const { data: deployment } = useFetchDeployment({ deploymentId });
  const breadcrumbs = [
    new BreadCrumbItem(selectedOrganization.name || "", Routes.dashboard_organization_detailsWithId(selectedOrganization.id || "")),
    new BreadCrumbItem("Projects", Routes.dashboard_projects),
    new BreadCrumbItem(project.name || "", Routes.dashboard_project_detailsWithId(project.id || "")),
    new BreadCrumbItem("Deployments", Routes.dashboard_project_deploymentsWithId(project.id || "")),
    new BreadCrumbItem(deployment?.name || "", Routes.dashboard_project_deployment_detailsWithId(project.id || "", deployment?.id || "")),
  ];
  useDocumentTitle(`Deployment: ${deployment?.name} | New role binding`);
  return <TopMenuBar breadcrumbs={breadcrumbs} icon={roleBindingIcon} title={"New role binding"} />;
};
