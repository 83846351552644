//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  CACertificate as ApiCACertificate,
  IPAllowlist as ApiIPAllowlist,
  PrepaidDeployment as ApiPrepaidDeployment,
  Organization as ApiOrganization,
  Project as ApiProject,
  CreateDeploymentRequest as ApiCreateDeploymentRequest,
  Version as ApiVersion,
} from "../../api/lib";
import { ErrorMessage, Processing } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import SelectVersion from "../deployment/SelectVersion";
import SelectCACertificate from "../deployment/SelectCACertificate";
import SelectIPAllowlist from "../deployment/SelectIPAllowlist";
import TermsAndConditionsAcceptance from "../tandc/TermsAndConditionsAcceptance";

interface ICreateDeploymentModalViewArgs extends IWithRefreshProps {
  organization: ApiOrganization;
  project: ApiProject;

  version?: ApiVersion;
  onVersionUpdated: (version?: ApiVersion) => void;

  caCertificate?: ApiCACertificate;
  onCACertificateUpdated: (caCertificate?: ApiCACertificate) => void;

  ipallowlist?: ApiIPAllowlist;
  onIPAllowlistUpdated: (ipallowlist?: ApiIPAllowlist) => void;

  onAcceptedTermsAndConditionsChanged: (termsAndConditionsID: string, accepted: boolean, required: boolean) => void;

  canCreate: boolean;
  onClickCreate: () => void;
  processingCreate: boolean;
  onClose: () => void;

  errorMessage?: string;
  onDismissError: () => void;
}

const CreateDeploymentModalView = ({ ...args }: ICreateDeploymentModalViewArgs) => {
  return (
    <Modal open onClose={args.onClose}>
      <Modal.Header>Create a deployment</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Processing active={args.processingCreate} message="Creating deployment... please wait" />
          <ErrorMessage message={args.errorMessage} active={!!args.errorMessage} onDismiss={args.onDismissError} />
          <Message>
            <p className="para">Most of the settings for your deployment are already configured in the prepaid deployment and cannot be changed.</p>
            <p className="para">Select the ArangoDB version you want to use, your CA certificate and optionally an IP allow list.</p>
          </Message>
          <Form>
            <SelectVersion
              {...args}
              organizationId={args.project.organization_id || ""}
              customImageShowing={false}
              canShowCustomImage={false}
              onVersionUpdated={args.onVersionUpdated}
              onShowCustomImageClicked={() => {}}
            />
            <SelectCACertificate {...args} />
            <SelectIPAllowlist {...args} />
            <TermsAndConditionsAcceptance {...args} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={args.onClickCreate} disabled={!args.canCreate} icon="save" labelPosition="right" content="Create" />
        <Button onClick={args.onClose} icon="cancel" labelPosition="right" content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
};

interface ICreateDeploymentModalProps extends IWithRefreshProps {
  item: ApiPrepaidDeployment;
  organization: ApiOrganization;
  project: ApiProject;
  onDeploymentCreated: (deploymentID: string) => void;
  onClose: () => void;
}

interface ICreateDeploymentModalState {
  version?: ApiVersion;
  caCertificate?: ApiCACertificate;
  ipallowlist?: ApiIPAllowlist;
  processingCreate: boolean;
  errorMessage?: string;
  acceptedTermsAndConditionsID?: string;
  acceptedTermsAndConditions: boolean;
}

class CreateDeploymentModal extends Component<ICreateDeploymentModalProps, ICreateDeploymentModalState> {
  state = {
    version: undefined,
    caCertificate: undefined,
    ipallowlist: undefined,
    processingCreate: false,
    errorMessage: undefined,
    acceptedTermsAndConditionsID: undefined,
    acceptedTermsAndConditions: false,
  } as ICreateDeploymentModalState;

  onClickCreate = () => {
    this.setState({ processingCreate: true, errorMessage: undefined }, async () => {
      try {
        const version = this.state.version || {};
        const caCertificate = this.state.caCertificate || {};
        const ipAllowList = this.state.ipallowlist || {};
        const req = {
          prepaid_deployment_id: this.props.item.id,
          project_id: this.props.project.id,
          version: version.version,
          certificates: {
            ca_certificate_id: caCertificate.id,
          },
          ipallowlist_id: ipAllowList.id,
          accepted_terms_and_conditions_id: this.state.acceptedTermsAndConditionsID,
          is_platform_authentication_enabled: true,
          drop_vst_support: true,
        } as ApiCreateDeploymentRequest;
        const depl = await apiClients.prepaidClient.CreateDeployment(req);
        this.props.onDeploymentCreated(depl.id || "");
      } catch (e) {
        this.setState({ errorMessage: `Failed to create deployment: ${e}` });
      } finally {
        this.setState({ processingCreate: false });
      }
    });
  };

  onDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  onVersionUpdated = (version?: ApiVersion) => {
    this.setState({ version: version });
  };

  onCACertificateUpdated = (caCertificate?: ApiCACertificate) => {
    this.setState({ caCertificate: caCertificate });
  };

  onIPAllowlistUpdated = (ipallowlist?: ApiIPAllowlist) => {
    this.setState({ ipallowlist: ipallowlist });
  };

  onAcceptedTermsAndConditionsChanged = (termsAndConditionsID: string, accepted: boolean, required: boolean) => {
    this.setState({
      acceptedTermsAndConditionsID: accepted ? termsAndConditionsID : undefined,
      acceptedTermsAndConditions: accepted || !required,
    });
  };

  render() {
    const canCreate = !!this.state.version && !!this.state.caCertificate && this.state.acceptedTermsAndConditions;
    return <CreateDeploymentModalView {...this.props} {...this.state} {...this} canCreate={canCreate} />;
  }
}

export default withRefresh()(CreateDeploymentModal);
