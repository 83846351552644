//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { CACertificate as ApiCACertificate, CACertificateList as ApiCACertificateList } from "../../api/crypto/v1/crypto";
import { Project as ApiProject } from "../../api/resourcemanager/v1/resourcemanager";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";

interface ISelectCACertificateViewArgs extends ISelectCACertificateState {
  selectedCACertificate?: ApiCACertificate;
  allowAdditions: boolean;
  disabled?: boolean;
  onAddItem: (e: any, data: DropdownProps) => void;
  updateFormDropdownChange: (e: any, data: DropdownProps) => void;
}

const SelectCACertificateView = ({ ...args }: ISelectCACertificateViewArgs) => {
  const caCertificates = args.caCertificates;
  if (args.creatingCertificate || !caCertificates) {
    return <Form.Dropdown required fluid selection label="Certificate" name="caCertificate" loading={true} />;
  }
  const allCerts = caCertificates.items || [];
  const currentID = (args.selectedCACertificate || {}).id;
  const availableCerts = _.filter(allCerts, (x) => x.id == currentID || !x.is_expired);
  return (
    <Form.Dropdown
      required
      fluid
      selection
      label="Certificate"
      placeholder="Select Certificate"
      name="caCertificate"
      value={args.selectedCACertificate && args.selectedCACertificate.name}
      onChange={args.updateFormDropdownChange}
      search
      noResultsMessage="No (valid) certificates found. Enter name to create one."
      disabled={args.disabled}
      allowAdditions={args.allowAdditions}
      onAddItem={args.onAddItem}
      additionLabel="Create new: "
      options={availableCerts.map((c) => {
        const isExpired = !!c.is_expired;
        const expiresSoon = !!c.will_expire_soon;
        return { key: c.id, text: c.name, value: c.name, icon: isExpired ? "warning sign" : expiresSoon ? "warning" : undefined };
      })}
    />
  );
};

// Interface decribing the properties of the SelectCACertificate component
interface ISelectCACertificateProps extends IWithRefreshProps {
  allowAdditions?: boolean;
  disabled?: boolean;
  project: ApiProject;
  caCertificate?: ApiCACertificate;
  onCACertificateUpdated: (caCertificate?: ApiCACertificate) => void;
}

// Interface decribing the state of the SelectCACertificate component
interface ISelectCACertificateState {
  caCertificates?: ApiCACertificateList;
  selectedCACertificate?: ApiCACertificate;
  creatingCertificate: boolean;
  refreshNeeded: boolean;
}

// Component to select a certificate
class SelectCACertificate extends Component<ISelectCACertificateProps, ISelectCACertificateState> {
  state = {
    caCertificates: undefined,
    selectedCACertificate: this.props.caCertificate,
    creatingCertificate: false,
    refreshNeeded: true,
  } as ISelectCACertificateState;

  componentDidMount() {
    this.refreshCACertificates();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshCACertificates);
    }
  }

  reloadCACertificates = async () => {
    const listOptions = { context_id: this.props.project.id } as ApiListOptions;
    const caCertificates = await apiClients.cryptoClient.ListCACertificates(listOptions);
    this.setState(
      (old) => {
        const current = old.selectedCACertificate;
        const hasCurrent = !!current;
        const fromProps = this.props.caCertificate;
        const hasFromProps = !!fromProps;
        const defaultCACertificate = _.find(caCertificates.items || [], (x) => !!x.is_default);
        return {
          caCertificates: caCertificates,
          selectedCACertificate: hasCurrent ? current : hasFromProps ? fromProps : defaultCACertificate,
        };
      },
      () => this.props.onCACertificateUpdated(this.state.selectedCACertificate)
    );
  };

  refreshCACertificates = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadCACertificates);
  };

  updateFormDropdownChange = (e: any, data: DropdownProps) => {
    switch (data.name) {
      case "caCertificate":
        const caCertificates = this.state.caCertificates;
        const selectedCACertificate = caCertificates && caCertificates.items && caCertificates.items.find((c) => c.name == data.value);
        this.setState({ selectedCACertificate: selectedCACertificate });
        if (selectedCACertificate) {
          this.props.onCACertificateUpdated(selectedCACertificate);
        }
        break;
    }
  };

  onAddItem = (e: any, data: DropdownProps) => {
    switch (data.name) {
      case "caCertificate":
        this.props.refreshNow && this.props.refreshNow(() => this.addCertificateAndSelect(data.value as string));
    }
  };

  addCertificateAndSelect = async (name: string) => {
    this.setState({ creatingCertificate: true });
    const caCertificate = { project_id: this.props.project.id, name: name } as ApiCACertificate;
    const newCACertificate = await apiClients.cryptoClient.CreateCACertificate(caCertificate);

    const caCertificates = this.state.caCertificates;
    if (caCertificates) {
      if (!caCertificates.items) {
        caCertificates.items = new Array<ApiCACertificate>();
      }
      caCertificates.items.push(newCACertificate);
    }

    this.setState({ creatingCertificate: false, selectedCACertificate: newCACertificate });
    this.props.onCACertificateUpdated(newCACertificate);
  };

  render() {
    return (
      <SelectCACertificateView
        {...this.props}
        {...this.state}
        allowAdditions={!!this.props.allowAdditions}
        updateFormDropdownChange={this.updateFormDropdownChange}
        onAddItem={this.onAddItem}
      />
    );
  }
}

export default withRefresh()(SelectCACertificate);
