//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { Loading } from "../../ui/_loading";
import { Section, SectionHead, SectionHeader } from "../../ui/_section";
import NotificationDetail from "./NotificationDetails";
import NotificationItemList from "./NotificationItemList";
import styled from "@emotion/styled";
import usePagination, { Page } from "../../ui/hooks/usePagination";
import { Notification } from "../../api/notification/v1/notification";
import { PagingButtons } from "../../ui/_paging";

const NotificationCard = styled(Segment)`
  padding: 0 !important;
`;

interface INotificationListViewArgs {
  notificationList: Notification[];
  areNotificationsLoading: boolean;
  activeNotification: Notification | null;
  notificationContent: string;
  recipients: string[];
  pageSize: number;
  handlePageChange: (page: Page) => void;
  handleNotificationClick: (notification: Notification) => void;
  handleNotificationDetailModalClose: () => void;
  onClickSupportRequest: () => void;
}

const NotificationListView = ({
  notificationList,
  areNotificationsLoading,
  activeNotification,
  notificationContent,
  recipients,
  pageSize,
  handleNotificationDetailModalClose,
  handleNotificationClick,
  handlePageChange,
  onClickSupportRequest,
}: INotificationListViewArgs) => {
  const { paginationObject, setNextPage, setPreviousPage } = usePagination();
  const [title, setTitle] = useState("");
  const { page } = paginationObject;
  const { created_at: createdAt = "" } = activeNotification || {};

  useEffect(() => {
    handlePageChange(paginationObject);
  }, [page]);

  const handleItemClick = (notification: Notification) => {
    const { title = "" } = notification;
    setTitle(title);
    handleNotificationClick(notification);
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeader
          title="Notifications"
          children={
            <PagingButtons
              testId="pagingButtons"
              page={page}
              count={notificationList.length}
              onNextPage={setNextPage}
              onPreviousPage={setPreviousPage}
              size="mini"
              pageSize={pageSize}
            />
          }
        ></SectionHeader>
      </SectionHead>
      <NotificationCard>
        {areNotificationsLoading ? (
          <Loading message="Loading notifications... " />
        ) : (
          <>
            <NotificationItemList onItemClick={handleItemClick} list={notificationList} />
            <NotificationDetail
              title={title}
              createdAt={createdAt as string}
              onClickSupportRequest={onClickSupportRequest}
              onClose={handleNotificationDetailModalClose}
              showModal={!!activeNotification}
              content={notificationContent}
              recipients={recipients.map((recipient) => recipient.slice(1, recipient.length - 1))}
            />
          </>
        )}
      </NotificationCard>
    </Section>
  );
};

export default NotificationListView;
