import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { useWithRefresh } from "../../util/WithRefreshContext";
import { useDashboardContext } from "../DashboardContextProvider";
import OrganizationInvites from "../organization-invites/OrganizationInvites";
import { useCheckPermissionByUrl } from "../sidebar/useCheckPermissionByUrl";

export const OrganizationInvitesWrap = () => {
  const { selectedOrganization, topMenuInfo, reloadOrganizationInvites } = useDashboardContext();
  const { checkOrgPermission } = useCheckPermissionByUrl();
  const has_create_invite = !!checkOrgPermission("resourcemanager.organization-invite.create");
  const history = useHistory();
  const onInviteCreate = () => {
    if (!selectedOrganization?.id) return;
    history.push(Routes.dashboard_organization_organizationInvites_createWithId(selectedOrganization.id));
  };

  const { subscribeUrl, unsubscribe, refreshNow, loading, hasPermissionByUrl } = useWithRefresh();
  return (
    <OrganizationInvites
      has_create_invite={has_create_invite}
      topMenuInfo={topMenuInfo}
      loading={loading}
      organization={selectedOrganization}
      refreshNow={refreshNow}
      subscribeUrl={subscribeUrl}
      hasPermissionByUrl={hasPermissionByUrl}
      onOrganizationInviteDeleted={reloadOrganizationInvites}
      onInviteCreate={onInviteCreate}
      unsubscribe={unsubscribe}
    />
  );
};
