//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useFetchDeploymentForEdit } from "../editDeployment/useFetchDeploymentForEdit";
import { NewDeploymentFormValues } from "./newDeploymentForm.types";

export const useReinitializeNumberOfNodes = () => {
  const { values, setFieldValue } = useFormikContext<NewDeploymentFormValues>();
  const { isExisting, data: deployment } = useFetchDeploymentForEdit();

  useEffect(() => {
    if (isExisting) {
      // if the modelId is the same as the current deployment's modelId, set the node count to deployment value
      if (values.modelId === deployment?.model?.model) {
        setFieldValue("numberOfNodes", deployment?.model?.node_count);
        return;
      }
    }
    if (values.modelId === "developer" || values.modelId === "free") {
      setFieldValue("numberOfNodes", 1);
      return;
    }
    setFieldValue("numberOfNodes", 3);
  }, [values.modelId]);
};
