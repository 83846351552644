//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
// Ewout Prangsma
//

import moment from "moment";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Form, Modal, Segment, Tab } from "semantic-ui-react";
import { CACertificate as ApiCACertificate, CACertificateInstructions as ApiCACertificateInstructions, IDOptions as ApiIDOptions } from "../../api/lib";
import {
  FormActionButtonCancel,
  FormActionButtonCopy,
  FormActionButtonEdit,
  FormContentAction,
  FormContentActions,
  humanizeDuration,
  Loading,
  MainContent,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
  Statistic,
  Statistics3,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import apiClients from "../../api/apiclients";

interface ICACertificateDetailsModalViewArgs {
  loading: boolean;
  caCertificate: ApiCACertificate;
  instructions: ApiCACertificateInstructions;
  installStepsCopied: boolean;
  uninstallStepsCopied: boolean;
  onCopiedInstallSteps: () => void;
  onCopiedUninstallSteps: () => void;
  onEdit?: () => void;
  onCancel: () => void;
  copiedKey: boolean;
  onCopiedKey: () => void;
}

const CACertificateDetailsModalView = ({ ...args }: ICACertificateDetailsModalViewArgs) => {
  const canEdit = !!args.onEdit;
  return (
    <Modal open onClose={args.onCancel} size="large">
      <Modal.Header>Certificate details</Modal.Header>
      <Modal.Content>
        {args.loading && <Loading />}
        {!args.loading && (
          <MainContent>
            <Form>
              <Section>
                <SectionHead>
                  <SectionHeader title="Installation instructions" />
                </SectionHead>
                <SectionContent>
                  <Tab
                    menu={{ secondary: true, pointing: true, stackable: true }}
                    panes={
                      args.instructions.platforms
                        ? args.instructions.platforms.map((x, i) => {
                            const installSteps = x.install_steps ? x.install_steps.join("\n") : undefined;
                            const uninstallSteps = x.uninstall_steps ? x.uninstall_steps.join("\n") : undefined;
                            return {
                              menuItem: x.platform || "?",
                              render: () => (
                                <div>
                                  <h3 className="heading-3">Installation</h3>
                                  <pre style={{ overflowX: "auto" }}>{installSteps}</pre>
                                  <CopyToClipboard text={installSteps} onCopy={args.onCopiedInstallSteps}>
                                    <Button basic icon={args.installStepsCopied ? "checkmark" : "copy"} labelPosition="right" content="Copy to clipboard" />
                                  </CopyToClipboard>
                                  <h3 className="heading-3">De-installation</h3>
                                  <pre style={{ overflowX: "auto" }}>{uninstallSteps}</pre>
                                  <CopyToClipboard text={uninstallSteps} onCopy={args.onCopiedUninstallSteps}>
                                    <Button basic icon={args.uninstallStepsCopied ? "checkmark" : "copy"} labelPosition="right" content="Copy to clipboard" />
                                  </CopyToClipboard>
                                </div>
                              ),
                            };
                          })
                        : undefined
                    }
                  />
                </SectionContent>
              </Section>
              <Section>
                <SectionHead>
                  <SectionHeader title="Statistics" />
                </SectionHead>
                <SectionContent>
                  <Segment>
                    <Statistics3>
                      <Statistic label="created" value={args.caCertificate.created_at ? moment(args.caCertificate.created_at).fromNow() : "-"} />
                      <Statistic label="expires" value={args.caCertificate.expires_at ? moment(args.caCertificate.expires_at).fromNow() : "?"} />
                      <Statistic label="lifetime" value={args.caCertificate.lifetime ? humanizeDuration(args.caCertificate.lifetime) : "?"} />
                      {args.caCertificate.is_deleted && (
                        <Statistic label="deleted" value={args.caCertificate.deleted_at ? moment(args.caCertificate.deleted_at).fromNow() : "-"} />
                      )}
                    </Statistics3>
                  </Segment>
                </SectionContent>
              </Section>
              <FormContentActions>
                {canEdit && (
                  <FormContentAction>
                    <FormActionButtonEdit primary onClick={args.onEdit} />
                  </FormContentAction>
                )}
                <FormContentAction>
                  <CopyToClipboard text={args.caCertificate.certificate_pem || ""} onCopy={args.onCopiedKey}>
                    <FormActionButtonCopy icon={args.copiedKey ? "check" : "copy"} title="Copy public key" />
                  </CopyToClipboard>
                </FormContentAction>
                <FormContentAction>
                  <FormActionButtonCancel onClick={args.onCancel} />
                </FormContentAction>
              </FormContentActions>
            </Form>
          </MainContent>
        )}
      </Modal.Content>
    </Modal>
  );
};

// Interface decribing the properties of the CACertificate details component
interface ICACertificateDetailsModalProps extends IWithRefreshProps {
  caCertificateId: string;
  onEdit?: () => void;
  onClose: () => void;
}

// Interface decribing the state of the CACertificate details component
interface ICACertificateDetailsModalState {
  caCertificate?: ApiCACertificate;
  instructions?: ApiCACertificateInstructions;
  certificateCopied: boolean;
  installStepsCopied: boolean;
  uninstallStepsCopied: boolean;
  copiedKey: boolean;
}

class CACertificateDetailsModal extends Component<ICACertificateDetailsModalProps, ICACertificateDetailsModalState> {
  state = {
    caCertificate: undefined,
    instructions: undefined,
    certificateCopied: false,
    installStepsCopied: false,
    uninstallStepsCopied: false,
    copiedKey: false,
  } as ICACertificateDetailsModalState;

  reloadCACertificateInfo = async () => {
    const idOptions = { id: this.props.caCertificateId } as ApiIDOptions;
    const caCertificate = await apiClients.cryptoClient.GetCACertificate(idOptions);
    const instructions = await apiClients.cryptoClient.GetCACertificateInstructions(idOptions);
    this.setState({
      caCertificate: caCertificate,
      instructions: instructions,
    });
  };

  onCopiedCertificate = () => {
    this.setState({ certificateCopied: true }, () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ certificateCopied: false }), 2000));
  };

  onCopiedInstallSteps = () => {
    this.setState({ installStepsCopied: true }, () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ installStepsCopied: false }), 2000));
  };

  onCopiedUninstallSteps = () => {
    this.setState(
      { uninstallStepsCopied: true },
      () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ uninstallStepsCopied: false }), 2000)
    );
  };

  onCopiedKey = () => {
    this.setState({ copiedKey: true }, () => this.props.setTimeout && this.props.setTimeout(() => this.setState({ copiedKey: false }), 2000));
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadCACertificateInfo);
  }

  render() {
    const loading = !this.state.caCertificate || !this.state.instructions;
    const caCertificate = this.state.caCertificate || {};
    const instructions = this.state.instructions || {};
    const canEdit =
      !loading &&
      !!this.props.onEdit &&
      this.props.hasPermissionByUrl &&
      this.props.hasPermissionByUrl(caCertificate.url || "", ResourceType.CACertificate, "crypto.cacertificate.update");

    return (
      <CACertificateDetailsModalView
        {...this.props}
        {...this.state}
        {...this}
        caCertificate={caCertificate}
        instructions={instructions}
        onCancel={this.props.onClose}
        onEdit={canEdit ? this.props.onEdit : undefined}
      />
    );
  }
}

export default withRefresh()(CACertificateDetailsModal);
