//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import moment from "moment";
import { Icon, Statistic, Popup } from "semantic-ui-react";
import { StatisticsContentGroup, StyledStatsSegment } from "../../../ui/lib";
import { AppLinkButton } from "../../../ui/_link";
import { isEmpty } from "lodash";

import { Status } from "../../../api/ml/v1/ml";

interface IMLSummaryTableView extends Status {
  openServicesModal: () => void;
  deploymentIsRunning: boolean;
}

const MLSummaryTableView = ({ deploymentIsRunning, openServicesModal, ...serviceStatusData }: IMLSummaryTableView) => {
  let { services = [], last_updated_at, message, phase } = serviceStatusData;

  const noData = isEmpty(serviceStatusData);

  const totalServices = services.length || 0;
  const availableServices = services.filter(({ available }) => available).length || 0;

  const lasUpdateMessage = last_updated_at ? moment(last_updated_at).fromNow() : "Never";
  const totalServicesMessage = totalServices ? `${availableServices} out of ${totalServices} services available` : "None";

  const calculateNoDataMessage = (message?: string) => (noData || !message ? "-" : message);

  return (
    <StyledStatsSegment>
      <StatisticsContentGroup>
        <Statistic.Group widths="3">
          <Statistic size="mini">
            <Statistic.Label>
              Status
              {message && (
                <Popup
                  content={message}
                  trigger={<Icon name={phase === "Error" ? "warning sign" : "info circle"} className={phase === "Error" ? "red-text" : "primary-text"} />}
                />
              )}
            </Statistic.Label>
            <Statistic.Value>{calculateNoDataMessage(phase)}</Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>
              <Icon name="calendar check" className="primary-text" /> Last Update
            </Statistic.Label>
            <Statistic.Value>{calculateNoDataMessage(lasUpdateMessage)}</Statistic.Value>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Label>Total services</Statistic.Label>
            {availableServices ? (
              <Statistic.Value>
                <AppLinkButton label={totalServicesMessage} handleClick={openServicesModal} />
              </Statistic.Value>
            ) : (
              <Statistic.Value>{calculateNoDataMessage(totalServicesMessage)}</Statistic.Value>
            )}
          </Statistic>
        </Statistic.Group>
      </StatisticsContentGroup>
    </StyledStatsSegment>
  );
};

export default MLSummaryTableView;
