//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Alert, AlertIcon, Text } from "@chakra-ui/react";
import React from "react";
import { Duration } from "../../api/googleTypes";
import { humanizeDuration } from "../../ui/_duration";

export const DiskRateLimitMessage = ({ diskRateLimitActive, target }: { diskRateLimitActive: Duration | undefined; target: string }) => {
  if (!diskRateLimitActive) {
    return null;
  }
  return (
    <Alert status="warning" marginBottom="4">
      <AlertIcon />
      <Text>
        {target} change is currently disabled due to cloud provider rate limiting. Next change is possible in{" "}
        <Text as="span" fontWeight="bold">
          {humanizeDuration(diskRateLimitActive)}
        </Text>
        .
      </Text>
    </Alert>
  );
};
