//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { useQuery } from "@tanstack/react-query";
import apiClients from "../../api/apiclients";
import { useProjectDetailsContext } from "../project/ProjectDetailsContextProvider";

export const useFetchCACertificates = () => {
  const { project } = useProjectDetailsContext();
  const fetchCACertificates = async () => {
    const listOptions = { context_id: project.id };
    return await apiClients.cryptoClient.ListCACertificates(listOptions);
  };
  return useQuery([project.id, "caCertificates"], fetchCACertificates);
};
