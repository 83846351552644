//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import {
  IDOptions as ApiIDOptions,
  ListOptions as ApiListOptions,
  OrganizationInvite as ApiOrganizationInvite,
  OrganizationInviteList as ApiOrganizationInviteList,
  User as ApiUser,
} from "../../api/lib";
import { Loading, ContentSegment, ErrorMessage, Processing, OnboardingName, OnboardingDescription, CenteredContentForm, OnboardingLogo } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { ITracking } from "../../tracking/api";
import DocumentTitle from "react-document-title";
import _ from "lodash";

import homeImage from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { reportError } from "../../errors/reporting";

interface IOrganizationInviteViewArgs {
  invite: ApiOrganizationInvite;
  onAccept: () => void;
  onReject: () => void;
}

const OrganizationInviteView = ({ ...args }: IOrganizationInviteViewArgs) => {
  return (
    <Grid.Row columns={16}>
      <Grid.Column width={4} />
      <Grid.Column width={4}>You are being invited to join the {args.invite.organization_name} organization.</Grid.Column>
      <Grid.Column width={4}>
        <Button content="Accept" onClick={args.onAccept} />
        <Button content="Reject" onClick={args.onReject} />
      </Grid.Column>
    </Grid.Row>
  );
};

interface IOrganizationInvitesViewArgs extends IOrganizationInvitesState {
  loading: boolean;
  processingAccept: boolean;
  processingReject: boolean;
  invites: ApiOrganizationInvite[];
  onAccept: (inviteID: string) => void;
  onReject: (inviteID: string) => void;
  handleDismissError: () => void;
}

const OrganizationInvitesView = ({ ...args }: IOrganizationInvitesViewArgs) => {
  const multipleInvites = args.invites.length > 1;
  const onbName = !multipleInvites
    ? "You're being invited to join an ArangoGraph Insights Platform organization"
    : "You're being invited to join these ArangoGraph Insights Platform organizations";
  const onbDescription = !multipleInvites ? "Please accept or reject this invite." : "Please accept or reject these invites.";
  return (
    <ContentSegment>
      <Processing active={args.processingAccept} message="Accepting organization invite, please wait..." />
      <Processing active={args.processingReject} message="Rejecting organization invite, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <OnboardingLogo>
        <img style={{ width: "100%", height: "100%" }} src={homeImage} alt="logo" />
      </OnboardingLogo>
      <OnboardingName>{onbName}</OnboardingName>
      <OnboardingDescription>{onbDescription}</OnboardingDescription>
      <CenteredContentForm>
        {args.loading && <Loading />}
        <Grid>
          {args.invites.map((x, i) => {
            return (
              <OrganizationInviteView key={`invite_${i}`} invite={x} onAccept={() => args.onAccept(x.id || "")} onReject={() => args.onReject(x.id || "")} />
            );
          })}
        </Grid>
      </CenteredContentForm>
    </ContentSegment>
  );
};

interface IOrganizationInvitesProps extends IWithRefreshProps, RouteComponentProps {
  tracking: ITracking;
  onInviteAccepted: (organizationId: string) => void;
  onInviteRejected: (organizationId: string) => void;
  onNoOpenInvites: () => void;
}

interface IOrganizationInvitesState {
  user?: ApiUser;
  organizationInvites?: ApiOrganizationInviteList;
  errorMessage?: string;
  processingAccept: boolean;
  processingReject: boolean;
}

// Component to accept/reject invites to an organization during the onboarding process
class OrganizationInvites extends Component<IOrganizationInvitesProps, IOrganizationInvitesState> {
  state = {
    errorMessage: undefined,
    processingAccept: false,
    processingReject: false,
  } as IOrganizationInvitesState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  reloadInvites = async () => {
    // Load organization invites
    const listOptions = {} as ApiListOptions;
    const organizationInvites = await apiClients.resourceManagerClient.ListMyOrganizationInvites(listOptions);
    this.setState({
      organizationInvites: organizationInvites,
    });
    const allInvites = organizationInvites.items || [];
    const openInvites = _.filter(allInvites, (x) => !x.accepted && !x.rejected);
    if (_.isEmpty(openInvites)) {
      this.props.onNoOpenInvites();
    }
  };

  refreshInvites = () => {
    this.props.refreshNow && this.props.refreshNow(this.reloadInvites);
  };

  onAccept = async (inviteID: string) => {
    try {
      this.setState({ processingAccept: true });
      const idOptions = { id: inviteID } as ApiIDOptions;
      await apiClients.resourceManagerClient.AcceptOrganizationInvite(idOptions);
      this.refreshInvites();
      this.props.onInviteAccepted(inviteID);
    } catch (e) {
      this.setState({ errorMessage: `Accept organization invite failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingAccept: false });
  };

  onReject = async (inviteID: string) => {
    try {
      this.setState({ processingReject: true });
      const idOptions = { id: inviteID } as ApiIDOptions;
      await apiClients.resourceManagerClient.RejectOrganizationInvite(idOptions);
      this.refreshInvites();
      this.props.onInviteRejected(inviteID);
    } catch (e) {
      this.setState({ errorMessage: `Reject organization invite failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingReject: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
    this.refreshInvites();
  }

  render() {
    const loading = !this.state.organizationInvites;
    const organizationInvites = this.state.organizationInvites || {};
    const allInvites = organizationInvites.items || [];
    const openInvites = _.filter(allInvites, (x) => !x.accepted && !x.rejected);

    return (
      <DocumentTitle title="Onboarding: Your organization invites">
        <OrganizationInvitesView
          {...this.state}
          loading={loading}
          invites={openInvites}
          handleDismissError={this.handleDismissError}
          onAccept={this.onAccept}
          onReject={this.onReject}
        />
      </DocumentTitle>
    );
  }
}

export default withRefresh()(OrganizationInvites);
