//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Button, Popup } from "semantic-ui-react";
import { Timestamp } from "../../../api/googleTypes";
import moment, { DurationInputArg1, DurationInputArg2 } from "moment";
import { RANGES, StyledButtonGroup } from "./MetricsView";
import { DATE_FORMAT } from "./useMetricsUsageApi";

const TimeFrameSelector = ({
  loading,
  selectedRange,
  setStartDate,
  onChangeRange,
}: {
  loading: boolean;
  selectedRange: number;
  setStartDate: React.Dispatch<React.SetStateAction<Timestamp>>;
  onChangeRange: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const handleSelectDateRange = (amount: DurationInputArg1, unit: DurationInputArg2, range: number) => {
    const newStartDate = new Date(moment().subtract(amount, unit).format(DATE_FORMAT));

    onChangeRange(range);
    setStartDate(newStartDate);
  };

  return (
    <StyledButtonGroup>
      <Popup
        position="top center"
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedRange === RANGES.MINUTES_10}
            onClick={() => {
              handleSelectDateRange(10, "minutes", RANGES.MINUTES_10);
            }}
            className={selectedRange === RANGES.MINUTES_10 ? "active" : ""}
          >
            10 minutes
          </Button>
        }
        content="This will show the servers usage for the last 10 minutes."
      />
      <Popup
        position="top center"
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedRange === RANGES.HOURS_1}
            onClick={() => {
              handleSelectDateRange(1, "hour", RANGES.HOURS_1);
            }}
            className={selectedRange === RANGES.HOURS_1 ? "active" : ""}
          >
            1 hour
          </Button>
        }
        content="This will show the servers usage for the last hour."
      />
      <Popup
        position="top center"
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedRange === RANGES.DAYS_1}
            onClick={() => {
              handleSelectDateRange(1, "day", RANGES.DAYS_1);
            }}
            className={selectedRange === RANGES.DAYS_1 ? "active" : ""}
          >
            1 day
          </Button>
        }
        content="This will show the servers usage for the last day."
      />
      <Popup
        position="top center"
        trigger={
          <Button
            disabled={loading}
            loading={loading && selectedRange === RANGES.WEEKS_1}
            onClick={() => {
              handleSelectDateRange(1, "week", RANGES.WEEKS_1);
            }}
            className={selectedRange === RANGES.WEEKS_1 ? "active" : ""}
          >
            1 week
          </Button>
        }
        content="This will show the servers usage for the last week."
      />
    </StyledButtonGroup>
  );
};

export default TimeFrameSelector;
