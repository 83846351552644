//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, InputOnChangeData, Grid, DropdownProps, CheckboxProps, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { User as ApiUser } from "../../api/iam/v1/iam";
import {
  Loading,
  ContentSegment,
  Processing,
  ErrorMessage,
  OnboardingLogo,
  OnboardingName,
  OnboardingDescription,
  OnboardingButton,
  CenteredContentForm,
  LeftAligned,
  TextLink,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { ITracking } from "../../tracking/api";
import DocumentTitle from "react-document-title";

import homeImage from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-auth.svg";
import { reportError } from "../../errors/reporting";
import styled from "@emotion/styled";

interface IEnterNamesViewArgs extends IEnterNamesState {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  user?: ApiUser;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  updateEducationalStatus: (hasEducationalStatus: boolean) => void;
  updateOtherDBs: (e: any, args: DropdownProps) => void;
  updateUser: () => void;
  handleAddedItem: (e: any, args: DropdownProps) => void;
}

export const StyledFieldDropdown = styled(Form.Dropdown)`
  & .ui.fluid.dropdown {
    border: 1px solid var(--gray-300);
    padding: 10px 15px;
  }
  & .ui.fluid.dropdown .dropdown.icon {
    top: 3px;
  }

  & .visible.menu.transition {
    width: 100%;
  }
`;

export const educationalRoleOptions = [
  {
    key: "student",
    text: "Student",
    value: "student",
  },
  {
    key: "teacher",
    text: "Teacher",
    value: "teacher",
  },
  {
    key: "other",
    text: "Other",
    value: "other",
  },
];

export const experienceOptions = [
  { text: "I'm new to Graph", value: "none", key: "none" },
  { text: "I've used other Graph technologies", value: "other", key: "other" },
  { text: "I'm using ArangoDB already", value: "adb", key: "adb" },
];

export const otherDBOptions = [
  { text: "Neo4j", value: "neo4j", key: "neo4j" },
  { text: "Amazon Neptune", value: "neptune", key: "neptune" },
  { text: "Azure CosmosDB", value: "cosmos", key: "cosmos" },
  { text: "TigerGraph", value: "tigergraph", key: "tigergraph" },
];

const EnterNamesView = ({ ...args }: IEnterNamesViewArgs) => {
  return (
    <ContentSegment>
      <Processing active={args.processing} message="Updating mobile phone number, please wait..." />
      <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
      <OnboardingLogo>
        <img style={{ width: "100%", height: "100%" }} src={homeImage} alt="logo" />
      </OnboardingLogo>
      <OnboardingName>Almost there!</OnboardingName>
      <OnboardingDescription>We just need a few details, these can always be changed later</OnboardingDescription>
      {args.user ? (
        <CenteredContentForm>
          <Grid stackable>
            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <Form.Input
                    autoFocus
                    label="Given name"
                    placeholder="Given name"
                    name="given_name"
                    value={args.given_name}
                    onChange={args.updateFormInputChange}
                    required
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <Form.Input
                    label="Family name"
                    placeholder="Family name"
                    name="family_name"
                    value={args.family_name}
                    onChange={args.updateFormInputChange}
                    required
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <Form.Checkbox
                    label="This is an educational account"
                    name="hasEducationalStatus"
                    checked={args.hasEducationalStatus}
                    onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                      return args.updateEducationalStatus(!!data.checked);
                    }}
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>
            {args.hasEducationalStatus && (
              <Grid.Row columns={16}>
                <Grid.Column width={4} only="computer tablet" />
                <Grid.Column width={8}>
                  <LeftAligned>
                    <StyledFieldDropdown
                      label="Education Role"
                      placeholder="Select your role"
                      name="educationRole"
                      required
                      fluid
                      options={educationalRoleOptions}
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        args.updateFormInputChange(null, {
                          name: "educationRole",
                          value: data.value as string,
                        });
                      }}
                    />
                  </LeftAligned>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <Form.Input
                    label={args.hasEducationalStatus ? "University name" : "Company name"}
                    placeholder={args.hasEducationalStatus ? "University name" : "Company name"}
                    name="company_name"
                    value={args.company_name}
                    onChange={args.updateFormInputChange}
                    required
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <StyledFieldDropdown
                    label="Have you used Graph technology before?"
                    placeholder="Select your experience"
                    name="experience"
                    required
                    fluid
                    hideAdditions={false}
                    forceSelect={false}
                    options={experienceOptions}
                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                      args.updateFormInputChange(null, {
                        name: "experience",
                        value: data.value as string,
                      });
                    }}
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>
            {args.experience === "other" && (
              <Grid.Row columns={16}>
                <Grid.Column width={4} only="computer tablet" />
                <Grid.Column width={8}>
                  <LeftAligned>
                    <StyledFieldDropdown
                      allowAdditions
                      hideAdditions={false}
                      label="Which technologies have you used?"
                      placeholder="Type to add new options"
                      name="technologies"
                      required
                      search
                      multiple
                      selection
                      options={otherDBOptions}
                      onChange={args.updateOtherDBs}
                      onAddItem={args.handleAddedItem}
                      fluid
                    />
                  </LeftAligned>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={16}>
              <Grid.Column width={4} only="computer tablet" />
              <Grid.Column width={8}>
                <LeftAligned>
                  <Form.Input
                    label={
                      <label>
                        Slack name (
                        <TextLink
                          onClick={() => {
                            window.open("https://slack.arangodb.com");
                          }}
                        >
                          join our community
                        </TextLink>
                        )
                      </label>
                    }
                    placeholder="Account name in arangodb-community slack"
                    name="slack_name"
                    value={args.slack_name}
                    onChange={args.updateFormInputChange}
                  />
                </LeftAligned>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <OnboardingButton>
            {!!args.given_name &&
            !!args.family_name &&
            !!args.company_name &&
            !!args.experience &&
            !(args.experience === "other" && args.otherDBs.length === 0) ? (
              <Form.Button primary size="big" content="Get Started" onClick={args.updateUser} />
            ) : (
              <Popup
                position="bottom center"
                content={"All required(*) fields must be filled out"}
                trigger={
                  <span>
                    <Form.Button primary size="big" disabled content="Get Started" onClick={args.updateUser} />
                  </span>
                }
              />
            )}
          </OnboardingButton>
        </CenteredContentForm>
      ) : (
        <Loading />
      )}
    </ContentSegment>
  );
};

// Interface decribing the properties of the EnterName component
interface IEnterNamesProps extends IWithRefreshProps, RouteComponentProps {
  onNamesUpdated: (userId: string) => void;
  tracking: ITracking;
}

export type EducationRoles = "student" | "teacher" | "other";
export type Experience = "none" | "other" | "adb";

// Interface decribing the state of the EnterName component
interface IEnterNamesState {
  user?: ApiUser;
  errorMessage?: string;
  processing: boolean;
  given_name: string;
  family_name: string;
  company_name: string;
  slack_name: string;
  hasEducationalStatus?: boolean;
  educationRole?: EducationRoles;
  experience?: Experience;
  otherDBs: string[];
}

// Component to update the (given, family and company) names of the active user.
class EnterNames extends Component<IEnterNamesProps, IEnterNamesState> {
  state: IEnterNamesState = {
    errorMessage: undefined,
    processing: false,
    user: undefined,
    given_name: "",
    family_name: "",
    company_name: "",
    slack_name: "",
    otherDBs: [],
  };

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
      given_name: user.given_name || "",
      family_name: user.family_name || "",
      company_name: user.company_name || "",
      slack_name: user.slack_name || "",
      hasEducationalStatus: user.has_educational_status,
      educationRole: user.educational_role as EducationRoles,
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    type ActionObjectType = {
      [action: string]: () => void;
    };

    const actionObject: ActionObjectType = {
      given_name: () => this.setState({ given_name: args.value }),
      family_name: () => this.setState({ family_name: args.value }),
      company_name: () => this.setState({ company_name: args.value }),
      slack_name: () => this.setState({ slack_name: args.value }),
      educationRole: () => this.setState({ educationRole: args.value as EducationRoles }),
      experience: () => this.setState({ experience: args.value as Experience }),
    };

    actionObject[args.name]();
  };

  handleAddedItem = (e: any, args: DropdownProps) => {
    if (!args || !args.value) return;
    const value = args.value.toString();
    const sanitizedValue = value.toLowerCase().replaceAll(" ", "");
    otherDBOptions.push({ text: value, value: sanitizedValue, key: sanitizedValue });
  };

  updateOtherDBs = (e: any, args: DropdownProps) => {
    this.setState({ otherDBs: args.value as string[] });
  };

  updateUser = async () => {
    try {
      this.setState({ processing: true, errorMessage: undefined });
      const user = this.state.user;
      if (user) {
        user.given_name = this.state.given_name;
        user.family_name = this.state.family_name;
        user.company_name = this.state.company_name;
        user.slack_name = this.state.slack_name;
        user.educational_role = this.state.educationRole;
        user.has_educational_status = this.state.hasEducationalStatus;
        user.experience = this.state.experience;
        user.other_dbs = this.state.otherDBs;

        if (!user.name) {
          user.name = `${this.state.given_name} ${this.state.family_name}`;
        }
        const updatedUser = await apiClients.iamClient.UpdateUser(user);

        this.props.tracking.trackUserNamesEntered(updatedUser);
        if (this.state.hasEducationalStatus) {
          this.props.tracking.trackEducationalSignup(updatedUser);
        }
        this.props.onNamesUpdated(updatedUser.id || "");
      }
    } catch (e) {
      this.setState({ errorMessage: `User update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processing: false });
  };

  updateEducationalStatus = (hasEducationalStatus: boolean) => {
    this.setState({ hasEducationalStatus, user: { ...this.state.user, has_educational_status: !!hasEducationalStatus } }, () => {
      if (!hasEducationalStatus) {
        this.setState({ educationRole: undefined, user: { ...this.state.user, educational_role: undefined } });
      }
    });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
  }

  render() {
    return (
      <DocumentTitle title="Onboarding: User information">
        <EnterNamesView
          {...this.state}
          active={!this.state.processing}
          handleDismissError={this.handleDismissError}
          updateFormInputChange={this.updateFormInputChange}
          updateUser={this.updateUser}
          updateEducationalStatus={this.updateEducationalStatus}
          updateOtherDBs={this.updateOtherDBs}
          handleAddedItem={this.handleAddedItem}
        />
      </DocumentTitle>
    );
  }
}

export default withRefresh()(EnterNames);
