//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
// Ewout Prangsma
//

import React, { Component } from "react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, Organization as ApiOrganization, TermsAndConditions as ApiTermsAndConditions } from "../../api/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { TermsAndConditionsAcceptanceView } from "./TermsAndConditionsAcceptanceView";

interface ITermsAndConditionsAcceptanceProps extends IWithRefreshProps {
  organization: ApiOrganization;
  onAcceptedTermsAndConditionsChanged: (termsAndConditionsID: string, accepted: boolean, required: boolean) => void;
}

interface ITermsAndConditionsAcceptanceState {
  errorMessage?: string;
  processing: boolean;

  termsAndConditions?: ApiTermsAndConditions;
  acceptedTermsAndConditions: boolean;

  prevOrganizationID?: string;
  refreshNeeded: boolean;
}

// Component to load current terms&conditions and asks the user for an acceptance.
class TermsAndConditionsAcceptance extends Component<ITermsAndConditionsAcceptanceProps, ITermsAndConditionsAcceptanceState> {
  state = {
    errorMessage: undefined,
    processing: false,
    termsAndConditions: undefined,
    acceptedTermsAndConditions: false,
    prevOrganizationID: undefined,
    refreshNeeded: false,
  } as ITermsAndConditionsAcceptanceState;

  notifyAcceptedTermsAndConditionsChanged = () => {
    const required = this.requiresAcceptanceOfTermsAndConditions();
    const termsAndConditions = this.state.termsAndConditions || {};
    this.props.onAcceptedTermsAndConditionsChanged(termsAndConditions.id || "", this.state.acceptedTermsAndConditions, required);
  };

  reloadCurrentTermsAndConditions = async () => {
    this.notifyAcceptedTermsAndConditionsChanged();
    const req = {
      id: this.props.organization.id || "",
    } as ApiIDOptions;
    const termsAndConditions = await apiClients.resourceManagerClient.GetCurrentTermsAndConditions(req);
    this.setState({ termsAndConditions: termsAndConditions }, this.notifyAcceptedTermsAndConditionsChanged);
  };

  refreshCurrentTermsAndConditions = () => {
    if (this.requiresAcceptanceOfTermsAndConditions()) {
      this.props.refreshNow && this.props.refreshNow(this.reloadCurrentTermsAndConditions);
    } else {
      this.notifyAcceptedTermsAndConditionsChanged();
    }
  };

  static getDerivedStateFromProps(props: ITermsAndConditionsAcceptanceProps, state: ITermsAndConditionsAcceptanceState) {
    if (props.organization.id != state.prevOrganizationID) {
      return {
        prevOrganizationID: props.organization.id,
        acceptedTermsAndConditions: false,
        refreshNeeded: true,
      };
    }
    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.refreshCurrentTermsAndConditions();
  }

  componentDidUpdate() {
    if (this.state.refreshNeeded) {
      this.setState({ refreshNeeded: false }, this.refreshCurrentTermsAndConditions);
    }
  }

  requiresAcceptanceOfTermsAndConditions = () => {
    const tier = this.props.organization.tier || {};
    return !!tier.requires_terms_and_conditions;
  };

  acceptedTermsAndConditionsChanged = (accepted: boolean) => {
    this.setState({ acceptedTermsAndConditions: accepted }, this.notifyAcceptedTermsAndConditionsChanged);
  };

  render() {
    if (this.requiresAcceptanceOfTermsAndConditions()) {
      return <TermsAndConditionsAcceptanceView {...this.props} {...this.state} {...this} />;
    }
    return <span />;
  }
}

export default withRefresh()(TermsAndConditionsAcceptance);
