//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import React from "react";
import { Segment, Dimmer, Loader, Placeholder, Grid } from "semantic-ui-react";

const StyledContentHolder = styled.div`
  width: 100%;
  position: absolute;
  top: 57px;
  right: 0;
  padding-left: 30px;
`;

const StyledPageLoader = styled.div`
  position: absolute;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledHeaderPlaceholder = styled.div`
  position: relative;
  top: -20px;
`;

const Callback = () => {
  return (
    <div>
      <StyledContentHolder>
        <StyledHeaderPlaceholder>
          <Grid column={16}>
            <Grid.Row>
              <Grid.Column width={2}>
                <Placeholder fluid>
                  <Placeholder.Header rectangular>
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={13}>
                <Placeholder fluid>
                  <Placeholder.Header rectangular>
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledHeaderPlaceholder>

        <StyledPageLoader>
          <Segment>
            <Dimmer inverted active>
              <Loader inverted>{"Loading..."}</Loader>
            </Dimmer>
            <div style={{ minHeight: "3em" }} />
          </Segment>
        </StyledPageLoader>
      </StyledContentHolder>
    </div>
  );
};

export default Callback;
