//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { pluralize } from "../../../api/_util";
import { FlexBox } from "../../../ui/_flex";
import { Section, SectionHeader } from "../../../ui/_section";
import { StyledStatsSegment } from "../../../ui/_style";
import GCPPrivateNetworkHelpView from "./PrivateNetworkHelpView";
import { GCPSummary } from "../types";
import { SummaryItem } from "../SummaryItemTemplate";
import { PrivateEndpointService_GcpPrivateEndpointConnectionStatus as ApiGcpPrivateEndpointConnectionStatus } from "../../../api/network/v1/network";
import { GCPPrivateEndpointConnectionsModalView } from "./ConnectionsView";
import { GCPServiceAttachmentView } from "./ServiceAttachment";
import { ProjectNamesModalView } from "./ProjectsView";
import { CustomDNSModalView } from "../CustomDNSView";

const GCPSummaryView = ({ privateNetworkDetail, onAlternateDNSNamesChange, onProjectNamesChange, onSupportClick }: GCPSummary) => {
  const { alternate_dns_names: dnsNames = [], status = {}, gcp = {}, enable_private_dns: enablePrivateDNS } = privateNetworkDetail;
  const { needs_attention: isAttentionRequired = false, message = "", gcp: gcpStatus = {} } = status;
  const { projects = [] } = gcp;
  const { service_attachment: serviceAttachment, private_endpoint_connections: gcpConnections = [] } = gcpStatus;

  const [showDNSModalView, toggleDNSModalView] = useState<boolean>(false);
  const [showHelpView, toggleHelpView] = useState<boolean>(false);
  const [showGCPConnectionsModal, toggleGCPConnectionsModal] = useState<boolean>(false);
  const [showServiceAttachmentModal, toggleServiceAttachmentModal] = useState<boolean>(false);
  const [showProjectsModal, toggleProjectsModal] = useState<boolean>(false);

  const hasFailedConnections = (connections: ApiGcpPrivateEndpointConnectionStatus[]) => !!connections.find((connection) => connection.state === "failed");

  return (
    <Section>
      {showDNSModalView && (
        <CustomDNSModalView
          alternateDNSNames={dnsNames}
          enablePrivateDNS={!!enablePrivateDNS}
          onClose={() => toggleDNSModalView(false)}
          onSave={onAlternateDNSNamesChange}
          isPrivateDNSEditable={false}
          info={
            <>
              <div>A Private Endpoint will result in IP addresses in your VPC.</div>
              <div>We strongly recommend mapping those IP addresses to one or more private DNS names.</div>
              <div>Pass these DNS names here to include them as alternate names in the TLS certificate of your deployment.</div>
            </>
          }
        />
      )}

      {showHelpView && <GCPPrivateNetworkHelpView onClose={() => toggleHelpView(false)} onSupportClick={onSupportClick} />}

      {showGCPConnectionsModal && <GCPPrivateEndpointConnectionsModalView onClose={() => toggleGCPConnectionsModal(false)} connections={gcpConnections} />}
      {showServiceAttachmentModal && <GCPServiceAttachmentView onClose={() => toggleServiceAttachmentModal(false)} initialValue={serviceAttachment} />}
      {showProjectsModal && (
        <ProjectNamesModalView
          onClose={() => toggleProjectsModal(false)}
          onSave={onProjectNamesChange}
          projects={projects}
          disableChange={!!gcpConnections.length}
          statusMessage={<span>Projects cannot be modified once a connection has been established.</span>}
        />
      )}

      <SectionHeader
        testID="privateNetworkSummaryHeader"
        title={
          <span>
            Private Endpoint{" "}
            {isAttentionRequired && (
              <Popup
                trigger={<Icon name="warning circle" color="red" data-testid="networkErrorIcon" />}
                content={message}
                data-testid="gcpSummaryHeaderErrorMessage"
              />
            )}
          </span>
        }
        actionableText="Help"
        onActionTextClick={() => toggleHelpView(true)}
      />

      <StyledStatsSegment>
        <FlexBox justify="space-between" margin="0 40px">
          <SummaryItem
            labelIcon="cloud"
            actionIcon={gcpConnections.length > 0 ? "eye" : "edit"}
            mainLabel="GCP Project"
            actionLabel={`${projects.length ? projects.length : "No"} ${pluralize("project", projects.length)} configured`}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleProjectsModal(true)}
            testID="gcpProjectSummaryItem"
          />

          <SummaryItem
            labelIcon="server"
            actionIcon="edit"
            mainLabel="Custom DNS names"
            handleClick={() => toggleDNSModalView(true)}
            actionLabel={`${dnsNames.length ? dnsNames.length : "No"} custom DNS ${pluralize("name", dnsNames.length)} configured.`}
            actionClassName="private-link-stat-item"
            testID="alternateDNSNamesSummaryItem"
          />

          <SummaryItem
            labelIcon="paperclip"
            mainLabel="Service Attachment"
            actionLabel={serviceAttachment ? serviceAttachment : "No service attached"}
            actionClassName="private-link-stat-item"
            handleClick={() => toggleServiceAttachmentModal(true)}
            actionIcon="eye"
            noAction={!serviceAttachment}
            testID="serviceAttachmentSummaryItem"
          />

          <SummaryItem
            labelIcon="linkify"
            mainLabel="Connections"
            actionLabel={`${gcpConnections.length ? gcpConnections.length : "No"} established ${pluralize("connection", gcpConnections.length)}`}
            actionClassName="private-link-stat-item"
            actionIcon="eye"
            handleClick={() => toggleGCPConnectionsModal(true)}
            noAction={!gcpConnections.length}
            hasError={hasFailedConnections(gcpConnections)}
            errorMessage="Something went wrong in establishing a connection"
            testID="privateConnectionsSummaryItem"
          />
        </FlexBox>
      </StyledStatsSegment>
    </Section>
  );
};

export default GCPSummaryView;
