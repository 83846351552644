//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import { Link, LinkProps } from "@chakra-ui/react";
import React from "react";

export const LinkText = (
  props: LinkProps & {
    // HACK: This is a hack to allow the `to` prop to be passed when using `as` prop
    to?: string;
  }
) => {
  return (
    <Link
      fontSize="sm"
      color="blue.600"
      textDecoration="underline"
      _hover={{
        // HACK: semantic-ui overrides this so needs !important
        color: "blue.700 !important",
        textDecoration: "underline",
      }}
      {...props}
    />
  );
};
