import { Location } from "history";
import { Routes } from "../routes";
import { CacheItem } from "./PermissionCache";

//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export class PersistentState {
  private static selectedOrganizationId = "selectedOrganizationId";
  private static permissionCache = "permissionCache";
  private static featureFlagsStorageKey = "featureFlags";
  private static requestedURL = "requestedURL";
  private static urlListForSelfRedirection: Array<string> = [Routes.requestHelp, Routes.signup, Routes.directSignup, Routes.login];

  public static saveSelectedOrganizationId = (id: string): void => {
    localStorage.setItem(PersistentState.selectedOrganizationId, id);
  };

  public static removeSelectedOrganizationId = (): void => {
    localStorage.removeItem(PersistentState.selectedOrganizationId);
  };

  public static retrieveSelectedOrganizationId = (): string | null => {
    return localStorage.getItem(PersistentState.selectedOrganizationId);
  };

  public static savePermissionCache = (cache: Map<string, CacheItem>): number => {
    let obj = Object.create(null);
    cache.forEach((cacheItem: CacheItem, url) => {
      if (!cacheItem.isOutdated()) {
        obj[url] = JSON.stringify(cacheItem);
      }
    });
    const json = JSON.stringify(obj);
    localStorage.setItem(PersistentState.permissionCache, json);

    return json.length;
  };

  public static retrievePermissionCache = (id: number): Map<string, CacheItem> => {
    let map = new Map<string, CacheItem>();
    const permissionCache = localStorage.getItem(PersistentState.permissionCache);
    if (permissionCache) {
      try {
        const obj = JSON.parse(permissionCache);
        for (let key of Object.keys(obj)) {
          const val = obj[key];
          const parsedVal = JSON.parse(val);
          const permissions = parsedVal["permissions"] as string[];
          const createDate = parsedVal["createDate"] as number;
          if (permissions && createDate) {
            map.set(key, new CacheItem(id, permissions, createDate));
          }
        }
      } catch {
        console.warn("Cannot deserialize permissionCache state: " + permissionCache);
        // return a new array
      }
    }
    return map;
  };

  public static saveFeatureFlags = (flags: string[]) => {
    localStorage.setItem(PersistentState.featureFlagsStorageKey, JSON.stringify(flags));
  };

  public static retrieveFeatureFlags = (): string[] => {
    const fFlags = localStorage.getItem(PersistentState.featureFlagsStorageKey) || "[]";
    return JSON.parse(fFlags) as string[];
  };

  public static getRequestedURL = (): string | null => {
    return localStorage.getItem(PersistentState.requestedURL);
  };

  public static enableURLRedirectionToItself = (location: Location): void => {
    if (!location) {
      return;
    }

    const { pathname } = location;
    localStorage.setItem(PersistentState.requestedURL, pathname);
  };

  public static removeRequestedURL = () => {
    localStorage.removeItem(PersistentState.requestedURL);
  };

  public static doesURLNeedsRedirectionToItself = (location: Location): boolean => {
    const { pathname } = location;
    return PersistentState.urlListForSelfRedirection.includes(pathname);
  };

  public static isRequestRedirectionPending = (route: string): boolean => {
    return PersistentState.getRequestedURL() === route;
  };

  public static removeRedirection = () => {
    PersistentState.removeRequestedURL();
  };

  public static isUserLoggedIn = () => {
    return localStorage.getItem("Auth.isLoggedIn") === "true";
  };
}
