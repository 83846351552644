//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { useState } from "react";
import { Section, SectionContent, SectionHead, SectionHeader } from "../../../ui/_section";
import { useCheckPermissionByUrl } from "../../sidebar/useCheckPermissionByUrl";
import { FlexBox } from "../../../ui/_flex";
import { Card, Icon } from "semantic-ui-react";
import styled from "@emotion/styled";

import ImportDataToCloudInstructions from "./ImportDataToCloudInstructions";
import DataLoaderInstructions from "./DataLoaderInstructions";

export const StyledHeader = styled.h3`
  margin-bottom: 32px;
`;

export const StyledCard = styled(Card)`
  &&& {
    min-height: 200px;
    margin: 10px 0 24px 0;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const StyledContent = styled(Card.Content)`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledCardHeader = styled(Card.Header)`
  margin-bottom: 16px;
`;

const MigrationUseCaseSelector = () => {
  const [selected, setSelected] = useState<string>("");
  const backToUseCaseSelection = () => setSelected("");

  if (selected === "data-loader") return <DataLoaderInstructions onBack={backToUseCaseSelection} />;
  if (selected === "import-data-to-cloud") return <ImportDataToCloudInstructions onBack={backToUseCaseSelection} />;

  return (
    <>
      <FlexBox margin="0 0 24px 0" direction="column">
        <StyledHeader>Select your migration use case:</StyledHeader>
      </FlexBox>
      <FlexBox justify="center" direction="column" minHeight="5px" backgroundColor="var(--gray-100)" borderRadius="12px" padding="22px 32px">
        <StyledCard fluid centered link onClick={() => setSelected("data-loader")}>
          <StyledContent>
            <StyledCardHeader textAlign="center">
              <Icon className="dark-green-text" name="cloudsmith" size="big" />
              <span>Transform data into a graph</span>
            </StyledCardHeader>
            <Card.Description>
              Provide your data in CSV format files and, using a no-code editor, model the schema and relationships of your data and transform it into
              richly-connected graph structures, without needing to write any code or deploy any infrastructure.
            </Card.Description>
          </StyledContent>
        </StyledCard>

        <Card.Group itemsPerRow={1}>
          <StyledCard centered link onClick={() => setSelected("import-data-to-cloud")}>
            <StyledContent>
              <StyledCardHeader textAlign="center">
                <Icon className="dark-green-text" name="file alternate outline" size="big" />
                <span>Import data to cloud</span>
              </StyledCardHeader>
              <Card.Description>Import data from various files into collections without creating a graph.</Card.Description>
            </StyledContent>
          </StyledCard>
        </Card.Group>
      </FlexBox>
    </>
  );
};

const MigrationWizard = () => {
  const { checkDeploymentPermission } = useCheckPermissionByUrl();
  const hasDeploymentPermissions = checkDeploymentPermission("data.deployment.get");

  if (!hasDeploymentPermissions)
    return (
      <Section>
        <SectionHead>
          <SectionHeader title="Load data into your deployment" />
        </SectionHead>
        <SectionContent>
          <p className="para">Looks like you don't have enough permissions for this deployment.</p>
        </SectionContent>
      </Section>
    );

  return <MigrationUseCaseSelector />;
};

export default MigrationWizard;
