import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { SupportOrganizationID } from "../../constants";
import { Routes } from "../../routes";
import arangoGraphLogo from "../../semantic/themes/arangodb-oasis-default/assets/images/logos/arangograph-logo-white.svg";
import { Box } from "../../ui/_box";
import { IOrganizationViewArgs } from "../MainView";

const TopLogo = () => {
  return (
    <Box margin="16px 0 8px 0">
      <Link to={Routes.dashboard}>
        <Box padding="0 16px">
          <img src={arangoGraphLogo} alt="home" width="85%" />
        </Box>
      </Link>
    </Box>
  );
};

export const SidebarRightWrap = ({
  children,
  onOpenInternalDashboard,
  organizationsList,
  onClickSupportRequest,
}: {
  children: React.ReactNode;
  onOpenInternalDashboard: () => void;
  organizationsList: IOrganizationViewArgs["organizations"];
  onClickSupportRequest: () => void;
}) => {
  const organizations = organizationsList?.items || [];
  const isMemberOfSupportOrganization = organizations.find((org) => org.id == SupportOrganizationID);

  return (
    <Box display="flex" direction="column" width="256px" backgroundColor="var(--gray-950)">
      <TopLogo />
      {children}
      <Box color="white" margin="auto 0 30px 0" height="auto" display="flex" justify="center" direction="column">
        {isMemberOfSupportOrganization && (
          <Box margin="10px 0 0 0" justify="center" onClick={onOpenInternalDashboard} cursor="pointer" display="flex" align="center">
            <Box borderBottom="1px solid var(--gray-500)">Internal Dashboard</Box>
            <Box margin="0 0 0 4px">
              <Icon name="external alternate" />
            </Box>
          </Box>
        )}
        <Box
          justify="center"
          fontSize="16px"
          margin="12px 0 0 0"
          borderTop="1px solid var(--gray-500)"
          height="40px"
          display="flex"
          align="center"
          onClick={onClickSupportRequest}
          cursor="pointer"
        >
          <Box margin="0 0 0 4px">
            <Icon name="question circle outline" />
          </Box>
          <div>Request help</div>
        </Box>
      </Box>
    </Box>
  );
};
