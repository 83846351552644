//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Header, InputOnChangeData, Segment } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { isBadRequest, User as ApiUser, VerifyUserMobilePhoneRequest as ApiVerifyUserMobilePhoneRequest } from "../../api/lib";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import { ContentSegment, ErrorMessage, Loading, MainContent, Processing } from "../../ui/lib";
import { IWithRefreshProps, withRefresh } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";

interface IPhoneNumberVerificationCodeViewArgs extends IPhoneNumberVerificationCodeState {
  active: boolean;
  processing: boolean;
  errorMessage?: string;
  handleDismissError: () => void;
  user?: ApiUser;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  verifyCode: () => void;
}

const PhoneNumberVerificationCodeView = ({ ...args }: IPhoneNumberVerificationCodeViewArgs) => (
  <ContentSegment>
    <Processing active={args.processing} message="Verifying code, please wait..." />
    <ErrorMessage active={!!args.errorMessage} onDismiss={args.handleDismissError} message={args.errorMessage} />
    <MainContent>
      {args.user ? (
        <Form>
          <Segment>
            <Header sub>Verification</Header>
            <Form.Group>
              <Form.Input
                autoFocus
                label="Verification code"
                placeholder="Verification code"
                name="code"
                value={args.code}
                onChange={args.updateFormInputChange}
              />
            </Form.Group>
          </Segment>
          <Form.Button disabled={!args.active} content="Verify" onClick={args.verifyCode} />
        </Form>
      ) : (
        <Loading />
      )}
    </MainContent>
  </ContentSegment>
);

// Interface decribing the properties of the PhoneNumberVerificationCode component
interface IPhoneNumberVerificationCodeProps extends IWithRefreshProps, RouteComponentProps {
  topMenuInfo: TopMenuInfo;
  onVerified: (userId: string) => void;
}

// Interface decribing the state of the PhoneNumberVerificationCode component
interface IPhoneNumberVerificationCodeState {
  user?: ApiUser;
  errorMessage?: string;
  processing: boolean;
  code: string;
}

// Component to enter a phone number verification code
class PhoneNumberVerificationCode extends Component<IPhoneNumberVerificationCodeProps, IPhoneNumberVerificationCodeState> {
  state = {
    errorMessage: undefined,
    processing: false,
    user: undefined,
    code: "",
  } as IPhoneNumberVerificationCodeState;

  reloadUserInfo = async () => {
    const user = await apiClients.iamClient.GetThisUser();
    this.setState({
      user: user,
    });
  };

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "code":
        this.setState({ code: args.value });
        break;
    }
  };

  verifyCode = async () => {
    try {
      const user = await apiClients.iamClient.GetThisUser();
      const userID = user ? user.id || "" : "";
      if (userID) {
        this.setState({ processing: true, errorMessage: undefined });
        const req = {
          code: this.state.code,
        } as ApiVerifyUserMobilePhoneRequest;
        await apiClients.iamClient.VerifyUserMobilePhone(req);
        this.props.onVerified(userID);
      }
    } catch (e) {
      this.setState({ errorMessage: `User update failed: ${e}` });
      if (!isBadRequest(e)) {
        reportError(e);
      }
    }
    this.setState({ processing: false });
  };

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidMount() {
    this.props.refreshNow && this.props.refreshNow(this.reloadUserInfo);
    this.updateTopMenu();
  }

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem("User", Routes.dashboard_user_details),
      new BreadCrumbItem((this.state.user && this.state.user.name) || "", Routes.dashboard_user_details)
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setTitles("Verify mobile phone number", "");
  };

  render() {
    return (
      <PhoneNumberVerificationCodeView
        {...this.state}
        active={!this.state.processing}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        verifyCode={this.verifyCode}
      />
    );
  }
}

export default withRefresh()(PhoneNumberVerificationCode);
